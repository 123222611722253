import React from "react";
import { Controller } from "react-hook-form";
import InputCreatableSelect from "shared/components/Form/InputCreatableSelect";
import I18n from "shared/lib/I18n";

const ISRCSelect = ({ control, name, error, options, location, disabled }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputCreatableSelect
                    innerRef={ref}
                    name={name}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.isrcs.placeholder"
                    )}
                    options={
                        options?.map((isrc) => ({
                            value: isrc,
                            label: isrc,
                        })) ?? []
                    }
                    value={
                        value?.map((isrc) => ({
                            value: isrc,
                            label: isrc,
                        })) ?? []
                    }
                    onChange={(opt, action) => {
                        if (
                            action.action === "select-option" ||
                            action.action === "create-option"
                        ) {
                            onChange([...value, action.option.value]);
                        } else if (action.action === "remove-value") {
                            onChange(
                                value.filter(
                                    (isrc) => isrc != action.removedValue.value
                                )
                            );
                        } else if (action.action === "clear") {
                            onChange([]);
                        }
                    }}
                    hideSelectedOptions={false}
                    isDisabled={disabled}
                    isMulti
                    isClearable
                    enablePortal
                    error={error}
                />
            )}
        />
    );
};

export default ISRCSelect;
