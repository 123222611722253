export const orderByReleaseDate = (data) => {
    return data.sort((a, b) => {
        if (a.release_date === b.release_date) {
            return 0;
        }
        if (!a.release_date) {
            return 1;
        }
        if (!b.release_date) {
            return -1;
        }
        return b.release_date.localeCompare(a.release_date);
    });
};
