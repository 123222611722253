import React, { useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import {
    DiscographyContext,
    RELEASE_DEL,
} from "pages/ArtistManagement/Discography";
import Delete from "./Delete";

const EnhancedDelete = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const navigate = useNavigate();
    const { locale } = useParams();
    const { dispatchDiscography } = useContext(DiscographyContext);
    const { selectedTab, artistId, albumId, releaseId } = useParams();

    const handleDelete = (force = 0) => {
        api.delete(`artist/${artistId}/album/${albumId}/release/${releaseId}`, {
            force,
            locale,
        })
            .then((response) => {
                if (response?.warnings) {
                    swal({
                        title: I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.basic.delete.warning.title"
                        ),
                        text: I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.basic.delete.warning.text",
                            response.warnings
                        ),
                        icon: "warning",
                        dangerMode: true,
                        buttons: {
                            cancel: {
                                text: I18n.getTranslation(
                                    location,
                                    "utils.sweetalert.cancel"
                                ),
                                visible: true,
                                closeModal: true,
                            },
                            confirm: {
                                text: I18n.getTranslation(
                                    location,
                                    "utils.sweetalert.confirm"
                                ),
                                closeModal: true,
                            },
                        },
                    }).then((isConfirm) => {
                        if (!isConfirm) {
                            return;
                        }
                        handleDelete(1);
                    });
                } else {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.basic.delete.success"
                        )
                    );
                    dispatchDiscography({
                        type: RELEASE_DEL,
                        data: parseInt(releaseId, 10),
                    });
                    navigate(
                        `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}`
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.release.basic.delete.error",
                        error.message
                    )
                );
            });
    };

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.basic.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.basic.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleDelete();
        });
    };

    return <Delete {...props} onClick={onClick} location={location} />;
};

export default EnhancedDelete;
