import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import LabelUploadModal from "shared/components/LabelUploadModal";

const Action = ({ artistId, showUpload, toggleUpload, onUploadSuccess }) => {
    return (
        <>
            <Button variant="primary" onClick={toggleUpload}>
                <FontAwesomeIcon icon={faImage} className="mr-2" />
                <I18n t="pictures.label.gallery.add" />
            </Button>

            <LabelUploadModal
                artistId={artistId}
                show={showUpload}
                handleHide={toggleUpload}
                onSuccess={onUploadSuccess}
            />
        </>
    );
};

export default Action;
