import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "routes.conf";
import Root from "layout/Root";
import Layout from "layout/Layout";
import Login from "layout/Auth/Login";
import Register from "layout/Auth/Register";
import Request from "layout/Auth/Resetting/Request";
import Reset from "layout/Auth/Resetting/Reset";
import TermsOfService from "layout/Auth/TermsOfService";
import PrivacyPolicy from "layout/Auth/PrivacyPolicy";

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Root />,
            children: [
                {
                    path: "/",
                    element: <Layout />,
                    children: routes,
                },
                {
                    path: ":locale/login/:token?",
                    element: <Login />,
                },
                {
                    path: ":locale/resetting/request",
                    element: <Request />,
                },
                {
                    path: ":locale/resetting/reset/:token",
                    element: <Reset />,
                },
                {
                    path: ":locale/register",
                    element: <Register />,
                },
                {
                    path: ":locale/terms",
                    element: <TermsOfService />,
                },
                {
                    path: ":locale/privacy",
                    element: <PrivacyPolicy />,
                },
            ],
        },
    ],
    { basename: process.env.PUBLIC_URL }
);

const App = () => {
    return <RouterProvider router={router} />;
};

export default App;
