import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router";
import Artists from "./Artists";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import ArtistListAction from "shared/components/ArtistListAction";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";

const EnhancedArtists = (props) => {
    const [state, setState] = useState({
        artists: [],
        isLoading: false,
    });
    const location = useLocation();
    const { locale } = useParams();
    const { id_genre } = useIntParams();
    const { api } = useAPI();

    const getArtists = useCallback(() => {
        if (id_genre) {
            setState((previousState) => ({
                ...previousState,
                isLoading: true,
            }));
            api.get(`genre/${id_genre}/artists`, { locale })
                .then((response) => {
                    setState((previousState) => ({
                        ...previousState,
                        artists: response,
                        isLoading: false,
                    }));
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState((previousState) => ({
                        ...previousState,
                        isLoading: false,
                    }));
                });
        } else {
            setState({
                artists: [],
                isLoading: false,
            });
        }
    }, [id_genre, locale]);

    useEffect(getArtists, [getArtists]);

    const columns = [
        {
            dataField: "position",
            text: I18n.getTranslation(location, "genre.artist.position"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.position"),
            headerStyle: { width: "5rem" },
            searchable: false,
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "genre.artist.name"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.name"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            filterValue: normalizeTitle,
            title: true,
            sort: true,
        },
        {
            dataField: "complement",
            text: I18n.getTranslation(location, "genre.artist.complement"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.complement"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            filterValue: normalizeTitle,
            title: true,
            sort: true,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "genre.artist.type"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.type"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
        },
        {
            dataField: "country",
            text: I18n.getTranslation(location, "genre.artist.country"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.country"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            filterValue: normalizeTitle,
            title: true,
            sort: true,
        },
        {
            dataField: "role",
            text: I18n.getTranslation(location, "genre.artist.role"),
            headerTitle: () =>
                I18n.getTranslation(location, "genre.artist.role"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            filterValue: normalizeTitle,
            title: true,
            sort: true,
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(location, "genre.artist.actions"),
            formatter: (cell, row) => {
                return (
                    <ArtistListAction
                        msArtist={{ id: row.id, name: row.name }}
                    />
                );
            },
            align: "center",
            searchable: false,
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        totalSize: state.artists.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const defaultSorted = [
        {
            dataField: "position",
            order: "asc",
        },
    ];

    return (
        <Artists
            {...props}
            {...state}
            columns={columns}
            pagination={pagination}
            location={location}
            onColumnMatch={onColumnMatchNormalized}
            defaultSorted={defaultSorted}
        />
    );
};

export default EnhancedArtists;
