import React, { useState } from "react";
import Profile from "./Profile";
import { useLocation } from "react-router";

const EnhancedProfile = (props) => {
    const [selectedTab, setSelectedTab] = useState("personal-details");
    const location = useLocation();

    const onSelectTab = (k) => setSelectedTab(k);

    return (
        <Profile
            {...props}
            location={location}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
        />
    );
};

export default EnhancedProfile;
