import { createSelector } from "reselect";
import I18n from "shared/lib/I18n";

export const getFilterOptions = createSelector(
    [(args) => args.tasks, (args) => args.location],
    (tasks, location) =>
        tasks.reduce(
            (acc, curr) => {
                const { category, classification, subtasks } = curr;
                if (category) {
                    acc["category"] = [
                        ...acc.category,
                        { label: category, value: category },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }
                if (classification) {
                    acc["classification"] = [
                        ...acc.classification,
                        { label: classification, value: classification },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }
                if (subtasks) {
                    acc["status"] = [
                        ...acc.status,
                        ...subtasks.map((subtask) => {
                            let status = subtask.status;
                            if (subtask.assignement === null) {
                                status = "UNASSIGNED";
                            }
                            return {
                                label:
                                    I18n.getTranslation(
                                        location,
                                        `plannings.view.tasks.subtasks.status.${status}`
                                    ) || status,
                                value: status,
                            };
                        }),
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );

                    acc["assignement"] = [
                        ...acc.assignement,
                        ...subtasks
                            .filter((subtask) => subtask.assignement !== null)
                            .map((subtask) => {
                                return {
                                    label: subtask.assignement.name,
                                    value: subtask.assignement.id,
                                };
                            }),
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );

                    acc["tasks"] = [
                        ...acc.tasks,
                        ...subtasks.map((subtask) => {
                            return { label: subtask.type, value: subtask.type };
                        }),
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }
                return acc;
            },
            {
                status: [],
                category: [],
                assignement: [],
                classification: [],
                tasks: [],
            }
        )
);

export const getFilteredTasks = createSelector(
    [(args) => args.tasks, (args) => args.selectedFilterOptions],
    (tasks, selectedFilterOptions) => {
        let filteredTasks = tasks;

        if (selectedFilterOptions.artist) {
            filteredTasks = filteredTasks.filter((t) => {
                if (t.ms_artist) {
                    return t.ms_artist.name
                        .toLowerCase()
                        .includes(selectedFilterOptions.artist.toLowerCase());
                }
                if (t.artist_name) {
                    return t.artist_name
                        .toLowerCase()
                        .includes(selectedFilterOptions.artist.toLowerCase());
                }
            });
        }

        if (selectedFilterOptions.category) {
            filteredTasks = filteredTasks.filter(
                (t) => t.category === selectedFilterOptions.category
            );
        }

        if (selectedFilterOptions.classification) {
            filteredTasks = filteredTasks.filter(
                (t) => t.classification === selectedFilterOptions.classification
            );
        }

        if (selectedFilterOptions.status) {
            filteredTasks = filteredTasks.filter((t) =>
                selectedFilterOptions.status === "UNASSIGNED"
                    ? t.subtasks.some((subtask) => subtask.assignement === null)
                    : t.subtasks.some(
                          (subtask) =>
                              subtask.status === selectedFilterOptions.status
                      )
            );
        }

        if (selectedFilterOptions.assignement) {
            filteredTasks = filteredTasks.filter((t) =>
                t.subtasks.some(
                    (subtask) =>
                        subtask.assignement !== null &&
                        subtask.assignement.id ===
                            selectedFilterOptions.assignement
                )
            );
        }

        if (selectedFilterOptions.tasks) {
            filteredTasks = filteredTasks.filter((t) =>
                t.subtasks.some(
                    (subtask) => subtask.type === selectedFilterOptions.tasks
                )
            );
        }

        return filteredTasks;
    }
);
