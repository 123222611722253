import React from "react";
import PartnerTops from "./PartnerTops";
import { useLocation } from "react-router";

const EnhancedPartnerTops = (props) => {
    const location = useLocation();
    return <PartnerTops {...props} location={location} />;
};

export default EnhancedPartnerTops;
