import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const License = ({ show, handleClose, handleShow, location }) => {
    return (
        <>
            <Button
                variant="secondary"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "common.pictureInformationModal.licenseInformation.head"
                )}
            >
                <FontAwesomeIcon icon={faInfoCircle} />
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="common.pictureInformationModal.licenseInformation.head" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.2.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.2.body" />
                    </p>
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.3.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.3.body" />
                    </p>
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.4.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.4.body" />
                    </p>
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.8.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.8.body" />
                    </p>
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.10.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.10.body" />
                    </p>
                    <h5>
                        <I18n t="common.pictureInformationModal.licenseInformation.11.title" />
                    </h5>
                    <p>
                        <I18n t="common.pictureInformationModal.licenseInformation.11.body" />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <I18n t="utils.button.close" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default License;
