import React, { useState, useCallback, useEffect } from "react";
import Year from "./Year";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedYear = (props) => {
    const { api } = useAPI();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { type, id_pf_or_year, id_year, chartType, locale } = useParams();
    const navigate = useNavigate();

    const getData = useCallback(() => {
        let cancelled = false;
        if (id_pf_or_year) {
            setIsLoading(true);
            api.get(`cnm/charts/platform/${id_pf_or_year}/year`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        setData(response);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                });
        } else {
            if (!cancelled) {
                setData([]);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [id_pf_or_year]);

    useEffect(getData, [getData]);

    const columns = [
        {
            dataField: "year",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.year.name"
            ),
            formatter: (cell, row) => (
                <>
                    {cell} (
                    <I18n t={`cnm.charts.deduplicated.year.type.${row.type}`} />
                    )
                </>
            ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/cnm/charts/${type}/${id_pf_or_year}/${row.path}`
                );
            }
        },
        selected: [`${id_year}/${chartType}`],
    };

    return (
        <Year
            data={data}
            columns={columns}
            isLoading={isLoading}
            selectRow={selectRow}
            {...props}
        />
    );
};

export default EnhancedYear;
