import React from "react";
import RecordingSelect from "shared/components/RecordingSelect";
import ReleaseSelect from "shared/components/ReleaseSelect";
import Tooltip from "./Tooltip";
import SubTask from "./SubTask";
import { SUBTASK_STATUS_COLOR } from "shared/constants/Planning/SubTask";
import { Badge } from "react-bootstrap";

const Task = ({
    task,
    handleStatusChanged,
    setAlbum,
    setRecording,
    locale,
}) => {
    return (
        <tr>
            <td>
                {task.ms_artist ? (
                    <a
                        href={`/${locale}/artist/${task.ms_artist.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.ms_artist.name}
                    </a>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.album_title ? (
                    <Tooltip
                        value={task.album_title}
                        content={
                            <ReleaseSelect
                                onMatch={(id) => setAlbum(task.id, id)}
                                onUnmatch={() => setAlbum(task.id, null)}
                                artistIds={
                                    task.ms_artist !== null
                                        ? [task.ms_artist.id]
                                        : []
                                }
                                locale={locale}
                                id={task.ms_album?.id}
                                title={task.ms_album?.name}
                            />
                        }
                        btnColor={
                            task.ms_album != null ? "success" : "secondary"
                        }
                    />
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.track_title ? (
                    <Tooltip
                        value={task.track_title}
                        content={
                            <RecordingSelect
                                onMatch={(id) => setRecording(task.id, id)}
                                onUnmatch={() => setRecording(task.id, null)}
                                artistIds={
                                    task.ms_artist !== null
                                        ? [task.ms_artist.id]
                                        : []
                                }
                                locale={locale}
                                id={task.ms_track?.id}
                                title={task.ms_track?.name}
                            />
                        }
                        btnColor={
                            task.ms_track != null ? "success" : "secondary"
                        }
                    />
                ) : (
                    "-"
                )}
            </td>
            <td>{task.ms_artist.genre ?? "-"}</td>
            <td>{task.ms_artist.country ?? "-"}</td>
            <td>
                {task.category || task.reason ? (
                    <span className="badge badge-info">
                        {[task.category, task.reason]
                            .filter(Boolean)
                            .join(" - ")}
                    </span>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.classification ? (
                    <span className="badge badge-info">
                        {task.classification}
                    </span>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.subtasks.map((subtask) => (
                    <SubTask
                        key={subtask.id}
                        task={task}
                        subtask={subtask}
                        handleStatusChanged={handleStatusChanged}
                    />
                ))}
            </td>
            <td>
                {task.related_tasks
                    ? task.related_tasks.map((related_task) => (
                          <span key={related_task.id} className="mx-1">
                              <Badge
                                  variant={
                                      SUBTASK_STATUS_COLOR[related_task.status]
                                  }
                                  className="badge-lg"
                              >
                                  {related_task.type}
                              </Badge>
                          </span>
                      ))
                    : "-"}
            </td>
        </tr>
    );
};

export default Task;
