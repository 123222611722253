import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";
import Filter from "./Filter";

const SampleDetail = ({
    lyrics,
    isLoading,
    columns,
    defaultSorted,
    onColumnMatch,
    pagination,
    rowClasses,
    ...props
}) => {
    return (
        <ToolkitProvider
            keyField="id"
            data={lyrics}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title="apple.sample.management.lyrics.head"
                            className="vertical-shrink mb-0 pb-0 h-100"
                            actions={
                                !isLoading && (
                                    <div className="col flex-grow-0">
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                )
                            }
                        >
                            <Widget.Body>
                                <Filter
                                    {...props}
                                    toolkitprops={toolkitprops}
                                    disabled={paginationProps.page !== 1}
                                />
                            </Widget.Body>
                            <Widget.Body
                                className="widget-table-overflow overflow-auto h-100"
                                style={{
                                    margin: "10px -20px 0px -20px",
                                }}
                            >
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        striped
                                        bordered={false}
                                        noDataIndication={
                                            <I18n t="apple.sample.management.lyrics.empty" />
                                        }
                                        classes="table-header-fixed table-truncate"
                                        defaultSorted={defaultSorted}
                                        rowClasses={rowClasses}
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-2 mb-2 mr-0">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={lyrics.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default SampleDetail;
