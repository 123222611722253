import React from "react";
import I18n from "shared/lib/I18n";
import Tippy from "@tippyjs/react";
import ImageLoader from "shared/components/ImageLoader";
import { Nav, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import clsx from "clsx";
import Information from "./Profile/Information";
import Names from "./Profile/Names";
import Members from "./Profile/Members";
import Group from "./Profile/Group";
import ExternalLink from "./Profile/ExternalLink";
import PartnerLink from "./Profile/PartnerLink";
import Univers from "./Univers";
import Credits from "./Credits";
import Charts from "./Charts";
import Images from "./Images";
import Disco from "./Disco";
import Biography from "./Biography";
import Popularity from "./Popularity";
import { TYPE_BAND } from "shared/constants/cms/artist";
import InputSwitch from "shared/components/Form/InputSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import placeholder from "assets/static/images/logo_placeholder.png";
import { Helmet } from "react-helmet";
import "./ArtistView.css";

const ArtistView = ({
    artist,
    headerObserverRef,
    isHeaderSticky,
    selectedTab,
    onSelectTab,
    location,
    containerRef,
    containerTopOffset,
    redirectToEdit,
    user,
}) => {
    return (
        <div className="content-wrap artist-view">
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="artist.view.breadcrumb" />
                    </li>
                </ol>
                <div id="header-observer" ref={headerObserverRef}></div>
                {artist?.id && (
                    <div
                        id="header"
                        className={clsx(
                            "d-flex header",
                            isHeaderSticky && "header-sticky"
                        )}
                    >
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Popover id={`popover-positioned-top`}>
                                    <Popover.Content className="bg-black rounded-sm">
                                        <ImageLoader
                                            src={artist?.picture_version_url}
                                            fallbackSrc={placeholder}
                                            style={{
                                                width: "400px",
                                                height: "400px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <span>
                                <ImageLoader
                                    src={artist?.picture_version_url}
                                    fallbackSrc={placeholder}
                                    fallbackClassName="placeholder-img"
                                    className="header-img border"
                                />
                            </span>
                        </OverlayTrigger>
                        <div className="header-data d-flex flex-column justify-content-around flex-grow-1">
                            <h4 className="mb-0">
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "artist.view.id"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{artist.id}</span>
                                </Tippy>
                            </h4>
                            <h2 className="mb-0">
                                <strong>{artist?.nom}</strong>
                            </h2>
                            <h4 className="mb-0">
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "artist.view.type"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{artist.locale_type}</span>
                                </Tippy>
                                <span className="mx-2">-</span>
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "artist.view.mainGenre"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{artist.locale_main_genre}</span>
                                </Tippy>
                                <span className="mx-2">-</span>
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "artist.view.country"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{artist.locale_country}</span>
                                </Tippy>
                                {artist?.complement && (
                                    <>
                                        <span className="mx-2">-</span>
                                        <Tippy
                                            content={I18n.getTranslation(
                                                location,
                                                "artist.view.complementary"
                                            )}
                                            trigger="mouseenter"
                                        >
                                            <span>{artist.complement}</span>
                                        </Tippy>
                                    </>
                                )}
                            </h4>
                        </div>
                        {user.hasRight("artist.manage.information") && (
                            <div
                                className={clsx(
                                    "align-self-center d-flex align-items-center",
                                    isHeaderSticky && "mr-2"
                                )}
                            >
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                                <InputSwitch
                                    checked={false}
                                    onChange={redirectToEdit}
                                />
                                <FontAwesomeIcon icon={faPen} fixedWidth />
                            </div>
                        )}
                    </div>
                )}
                <Tab.Container
                    id="artist-view-tab"
                    activeKey={selectedTab}
                    defaultActiveKey="profile"
                    onSelect={onSelectTab}
                    mountOnEnter
                    unmountOnExit
                >
                    <Nav
                        variant="pills"
                        className={clsx(
                            "artist-view-nav",
                            isHeaderSticky && "nav-sticky"
                        )}
                        justify
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="profile">
                                <I18n t="artist.view.profile" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="univers">
                                <I18n t="artist.view.univers" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="popularity">
                                <I18n t="artist.view.popularity" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="biography">
                                <I18n t="artist.view.biography" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="images">
                                <I18n t="artist.view.images" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="charts">
                                <I18n t="artist.view.charts" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="disco">
                                <I18n t="artist.view.disco" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="credits">
                                <I18n t="artist.view.credits" />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content
                        ref={containerRef}
                        className={clsx(
                            "artist-view-nav-content nav-content overflow-hidden"
                        )}
                        style={{
                            marginTop: isHeaderSticky
                                ? `${containerTopOffset}px`
                                : "1.2rem",
                        }}
                    >
                        <Tab.Pane eventKey="profile" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.profile"
                                )}`}</title>
                            </Helmet>
                            <div className="row">
                                <div className="col-9">
                                    <Information artist={artist} />
                                    <div className="row">
                                        <div className="col">
                                            <Names artist={artist} />
                                        </div>
                                        <div className="col">
                                            {artist.type === TYPE_BAND ? (
                                                <Members />
                                            ) : (
                                                <Group />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <ExternalLink artist={artist} />
                                    <PartnerLink />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="univers" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.univers"
                                )}`}</title>
                            </Helmet>
                            <Univers />
                        </Tab.Pane>
                        <Tab.Pane eventKey="popularity" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.popularity"
                                )}`}</title>
                            </Helmet>
                            <Popularity />
                        </Tab.Pane>
                        <Tab.Pane eventKey="biography" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.biography"
                                )}`}</title>
                            </Helmet>
                            <Biography />
                        </Tab.Pane>
                        <Tab.Pane eventKey="images" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.images"
                                )}`}</title>
                            </Helmet>
                            <Images />
                        </Tab.Pane>
                        <Tab.Pane eventKey="charts" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.charts"
                                )}`}</title>
                            </Helmet>
                            <Charts />
                        </Tab.Pane>
                        <Tab.Pane eventKey="disco" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.disco"
                                )}`}</title>
                            </Helmet>
                            <Disco />
                        </Tab.Pane>
                        <Tab.Pane eventKey="credits" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "artist.view.credits"
                                )}`}</title>
                            </Helmet>
                            <Credits />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default ArtistView;
