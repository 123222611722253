import { createSelector } from "reselect";

export const getLabels = createSelector(
    [
        (args) => args.selectedCharts,
        (args) => args.entries,
        (args) => args.dynamicDate,
    ],
    (selectedCharts, entries, dynamicDate) => {
        return [
            ...new Set(
                entries
                    .filter((entrie) => {
                        return selectedCharts.some(
                            ({ id }) => id === entrie.chart.value
                        );
                    })
                    .filter((entrie) => (dynamicDate ? entrie.rank : true))
                    .map((entrie) => entrie.date)
                    .sort()
            ),
        ];
    }
);

export const getData = createSelector(
    [(args) => args.chart, (args) => args.entries, (args) => args.dynamicDate],
    (chart, entries, dynamicDate) => {
        return entries
            .filter((entrie) => chart.id === entrie.chart.value)
            .map((entrie) => ({
                x: entrie.date,
                y: entrie.rank,
            }))
            .filter((entrie) => (dynamicDate ? entrie.y : true))
            .sort((a, b) => (a.x > b.x ? 1 : -1));
    }
);

export const getDatasets = createSelector(
    [
        (args) => args.selectedCharts,
        (args) => args.entries,
        (args) => args.dynamicDate,
    ],
    (selectedCharts, entries, dynamicDate) => {
        return selectedCharts.map((chart) => {
            return {
                label: chart.title,
                data: getData({ chart, entries, dynamicDate }),
                tension: 0.1,
                cubicInterpolationMode: "monotone",
            };
        });
    }
);
