import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";

const TrackTranslationsForm = ({
    location,
    register,
    artistNames,
    recordingsAlbums,
    recordings,
    isSubmitting,
    isDirty,
    errors,
    onSubmit,
    onReset,
}) => {
    return (
        <Form className="m-3" onSubmit={onSubmit}>
            <Row className="mb-3">
                <Col sm={3}>
                    <h5>
                        <I18n t="common.charts.charts.trackTranslationsForm.artist" />
                    </h5>
                    <InputText
                        {...register("artistName")}
                        placeholder={I18n.getTranslation(
                            location,
                            "common.charts.charts.trackTranslationsForm.translatedName"
                        )}
                        error={
                            errors.artistName && [
                                I18n.getTranslation(
                                    location,
                                    errors.artistName.message
                                ),
                            ]
                        }
                    />
                    {artistNames.length > 1 ? (
                        <Form.Text className="text-danger">
                            <I18n t="common.charts.charts.trackTranslationsForm.multipleNamesWarning" />
                            <ul className="mb-0">
                                {artistNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>
                        </Form.Text>
                    ) : undefined}
                </Col>
                <Col sm={5} className="border-left">
                    <h5>
                        <I18n t="common.charts.charts.trackTranslationsForm.albums" />
                    </h5>
                    {recordingsAlbums.map(({ id, title, format, type }) => (
                        <Form.Row key={id} className="align-items-center mb-2">
                            <Col sm={7}>
                                {title}
                                <span className="ml-2 text-muted">
                                    <I18n
                                        t={`common.charts.charts.trackTranslationsForm.albumFormat.${format.toLowerCase()}`}
                                    />
                                    {" - "}
                                    <I18n
                                        t={`common.charts.charts.trackTranslationsForm.albumType.${type.toLowerCase()}`}
                                    />
                                </span>
                            </Col>
                            <Col sm={5}>
                                <InputText
                                    {...register(`albumTitles.id_${id}`)}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "common.charts.charts.trackTranslationsForm.translatedTitle"
                                    )}
                                    error={
                                        errors.albumTitles?.[`id_${id}`] && [
                                            I18n.getTranslation(
                                                location,
                                                errors.albumTitles[`id_${id}`]
                                                    .message
                                            ),
                                        ]
                                    }
                                />
                            </Col>
                        </Form.Row>
                    ))}
                </Col>
                <Col sm={4} className="border-left">
                    <h5>
                        <I18n t="common.charts.charts.trackTranslationsForm.recordings" />
                    </h5>
                    <Form.Row>
                        <Col sm={6}>
                            <InputText
                                {...register("recordingsTitle")}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "common.charts.charts.trackTranslationsForm.translatedTitle"
                                )}
                                error={
                                    errors.recordingsTitle && [
                                        I18n.getTranslation(
                                            location,
                                            errors.recordingsTitle.message
                                        ),
                                    ]
                                }
                            />
                        </Col>
                        <Col sm={6}>
                            <InputText
                                {...register("recordingsSubtitle")}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "common.charts.charts.trackTranslationsForm.translatedSubtitle"
                                )}
                                error={
                                    errors.recordingsSubtitle && [
                                        I18n.getTranslation(
                                            location,
                                            errors.recordingSubtitle.message
                                        ),
                                    ]
                                }
                            />
                        </Col>
                    </Form.Row>
                    {recordings.length > 1 ? (
                        <Form.Text className="text-danger">
                            <I18n t="common.charts.charts.trackTranslationsForm.multipleTitlesWarning" />
                            <ul className="mb-0">
                                {recordings.map(
                                    (
                                        { translatedTitle, translatedSubtitle },
                                        index
                                    ) => (
                                        <li key={index}>
                                            {translatedTitle === null &&
                                            translatedSubtitle === null ? (
                                                <span className="font-italic">
                                                    <I18n t="common.charts.charts.trackTranslationsForm.noTranslation" />
                                                </span>
                                            ) : (
                                                <>
                                                    {translatedTitle ===
                                                    null ? (
                                                        <span className="font-italic">
                                                            <I18n t="common.charts.charts.trackTranslationsForm.noTitle" />
                                                        </span>
                                                    ) : (
                                                        translatedTitle
                                                    )}
                                                    {" — "}
                                                    {translatedSubtitle ===
                                                    null ? (
                                                        <span className="font-italic">
                                                            <I18n t="common.charts.charts.trackTranslationsForm.noSubtitle" />
                                                        </span>
                                                    ) : (
                                                        translatedSubtitle
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    )
                                )}
                            </ul>
                        </Form.Text>
                    ) : undefined}
                </Col>
            </Row>
            <Row className="justify-content-end">
                <Col className="col-auto">
                    <Button
                        variant="secondary mr-2"
                        disabled={isSubmitting || !isDirty}
                        onClick={onReset}
                        title={I18n.getTranslation(
                            location,
                            "common.charts.charts.trackTranslationsForm.reset"
                        )}
                    >
                        <I18n t="utils.button.reset" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        type="submit"
                        title={I18n.getTranslation(
                            location,
                            "common.charts.charts.trackTranslationsForm.save.tooltip"
                        )}
                    >
                        <I18n t="utils.button.save" />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default TrackTranslationsForm;
