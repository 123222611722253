import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import moment from "moment";
import InputText from "shared/components/Form/InputText";
import InputDate from "shared/components/Form/InputDate";
import InputSelect from "shared/components/Form/InputSelect";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";

const ImportModal = ({
    chartOptions,
    show,
    onHide,
    control,
    register,
    errors,
    isSubmitting,
    onSubmit,
    location,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="snep.file.fileList.import.head" />
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="snep.file.fileList.import.name" />
                        </Form.Label>
                        <InputText {...register("name")} disabled />
                    </Form.Group>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="snep.file.fileList.import.startDate" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <InputDate
                                            selected={
                                                value ? new Date(value) : null
                                            }
                                            onChange={(date) => {
                                                onChange(
                                                    date
                                                        ? moment(date).format(
                                                              "YYYY-MM-DD"
                                                          )
                                                        : null
                                                );
                                            }}
                                            error={
                                                errors.startDate && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.startDate.message
                                                    ),
                                                ]
                                            }
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="snep.file.fileList.import.endDate" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <InputDate
                                            selected={
                                                value ? new Date(value) : null
                                            }
                                            onChange={(date) => {
                                                onChange(
                                                    date
                                                        ? moment(date).format(
                                                              "YYYY-MM-DD"
                                                          )
                                                        : null
                                                );
                                            }}
                                            error={
                                                errors.endDate && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.endDate.message
                                                    ),
                                                ]
                                            }
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="snep.file.fileList.import.chart.label" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="chart"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "snep.file.fileList.import.chart.placeholder"
                                    )}
                                    options={chartOptions}
                                    onChange={(opt) => onChange(opt ?? null)}
                                    value={value}
                                    error={
                                        errors.chart && [
                                            I18n.getTranslation(
                                                location,
                                                errors.chart.message
                                            ),
                                        ]
                                    }
                                    isClearable={false}
                                />
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        <I18n t="utils.button.close" />
                    </Button>
                    <Button
                        variant="success"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t="utils.button.add" />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ImportModal;
