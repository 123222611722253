import React from "react";
import { useLocation } from "react-router";
import CreatableSelect from "react-select/creatable";
import I18n from "shared/lib/I18n";
import Error from "shared/components/Form/Error";

const InputCreatableSelect = ({
    error,
    placeholder,
    styles,
    enablePortal,
    ...props
}) => {
    const location = useLocation();
    const defaultStyle = {
        option: (provided, state) => ({
            ...provided,
            color: state.isDisabled
                ? "#6c757d"
                : state.isSelected
                ? "#fff"
                : "#000",
            cursor: state.isDisabled ? "default" : "pointer",
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor:
                error && error.length !== 0 ? "#dc3545" : provided.borderColor,
            borderRadius: ".25em",
            cursor: state.isDisabled ? "default" : "pointer",
        }),
        clearIndicator: (provided) => ({
            ...provided,
            color: error && error.length !== 0 ? "#dc3545" : provided.color,
            padding: "0 4px",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0 4px",
        }),
        loadingIndicator: (provided) => ({
            ...provided,
            padding: "0 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            margin: "5px 0",
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "0 11px",
            lineHeight: "1.5",
            fontSize: "1rem",
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 999,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 1999,
        }),
        ...styles,
    };

    return (
        <>
            <CreatableSelect
                menuPortalTarget={enablePortal && document.body}
                styles={defaultStyle}
                placeholder={
                    placeholder ??
                    I18n.getTranslation(location, "utils.select.placeholder")
                }
                noOptionsMessage={() => <I18n t="utils.select.noOptions" />}
                theme={(provided) => {
                    return {
                        ...provided,
                        spacing: {
                            baseUnit: 3,
                            controlHeight: 29,
                            menuGutter: 10,
                        },
                    };
                }}
                components={{
                    ...props.components,
                }}
                {...props}
            />

            <Error error={error} className="mt-3" />
        </>
    );
};

export default InputCreatableSelect;
