import React, { useCallback, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import EditMatchingsForm from "./EditMatchingsForm";

const EnhancedEditMatchingsForm = ({
    artistId,
    onChange,
    fetchCandidates,
    locale,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);

    const getCandidates = useCallback(() => {
        let cancelled = false;
        setIsLoading(true);
        fetchCandidates(artistId)
            .then((candidates) => {
                if (!cancelled) {
                    setCandidates(candidates);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setCandidates([]);
                    setIsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [fetchCandidates, artistId]);

    useEffect(() => {
        getCandidates();
    }, [getCandidates]);

    const changeState = useCallback(
        (candidateId, state) => {
            setIsLoading(true);
            onChange(artistId, candidateId, state)
                .then((candidates) => {
                    setCandidates(candidates);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [onChange, artistId]
    );

    const status = useMemo(() => {
        const matched = candidates.filter((row) => row.isMatching).length;

        if (matched == 0) {
            return "not_found";
        } else {
            return "matched";
        }
    }, [candidates]);

    return (
        <EditMatchingsForm
            isLoading={isLoading}
            status={status}
            candidates={candidates}
            onChange={changeState}
            onMatch={(candidateId) => changeState(candidateId, "VALID")}
            locale={locale}
        />
    );
};

export default EnhancedEditMatchingsForm;
