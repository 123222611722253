import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Row from "./Row";
import { useLocation } from "react-router";

const History = ({ isLoading, filteredRows, defaultLocale, handleSearch }) => {
    const location = useLocation();

    let tableBody = (
        <tr>
            <td colSpan="2" style={{ textAlign: "center" }}>
                <I18n t="plannings.view.history.empty" />
            </td>
        </tr>
    );

    if (filteredRows.length > 0) {
        tableBody = filteredRows.map((row) => (
            <Row
                key={`${row.type}-${row.id}`}
                type={row.type}
                action={row.action}
                task={row.task}
                subtask={row.subtask}
                userName={row.user.name}
                data={row.data}
                createdAt={row.created_at}
                defaultLocale={defaultLocale}
            />
        ));
    }

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <input
                className="form-control"
                type="text"
                placeholder={I18n.getTranslation(
                    location,
                    "plannings.view.history.search"
                )}
                style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "15px",
                }}
                onChange={handleSearch}
            />
            <table
                className="table table-striped table-header-fixed"
                style={{ marginBottom: "0px", color: "white" }}
            >
                <tbody>{tableBody}</tbody>
            </table>
        </>
    );
};

export default History;
