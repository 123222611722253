import React from "react";
import { Modal, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import TaskForm from "pages/PlanningsManagement/Form/TaskForm";

const Create = ({
    planningId,
    partnerId,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
    isPlanningLock,
}) => {
    return (
        <>
            <Button
                variant="success"
                onClick={handleShow}
                disabled={isPlanningLock}
                title={I18n.getTranslation(
                    location,
                    "plannings.view.tasks.action.create.title"
                )}
            >
                <I18n t="plannings.view.tasks.action.create.title" />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t={`plannings.view.tasks.action.create.title`} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskForm
                        partnerId={partnerId}
                        planningId={planningId}
                        onSubmitSuccess={() => {
                            handleClose();
                            onSuccess?.();
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Create;
