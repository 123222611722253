import React from "react";

import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import MultiArtistSelect from "./MultiArtistSelect";

const EnhancedMultiArtistSelect = ({
    onMatch,
    onUnmatch,
    onClear,
    locale,
    ...props
}) => {
    const { api } = useAPI();
    const search = useDebouncedCallback((term, callback) => {
        api.get(`searchengine/artist`, {
            term,
            onlyCompleted: true,
        })
            .then((response) => {
                callback(
                    response.map((row) => {
                        const name = [
                            row.name,
                            row.complement,
                            row.type?.langs?.[locale],
                            row.country?.langs?.[locale],
                            row.role?.langs?.[locale],
                            row.genre?.langs?.[locale],
                        ];
                        return {
                            id: row.id,
                            name: row.name,
                            label: name.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onChange = (_, action) => {
        if (action.action === "select-option") {
            if (onMatch !== undefined) {
                onMatch(action.option.id, action.option.name);
            }
        } else if (action.action === "remove-value") {
            if (onUnmatch !== undefined) {
                onUnmatch(action.removedValue.id, action.removedValue.name);
            }
        } else if (action.action === "clear") {
            if (onClear !== undefined) {
                onClear();
            }
        }
    };

    return (
        <MultiArtistSelect
            loadOptions={search}
            onChange={onChange}
            {...props}
        />
    );
};

export default EnhancedMultiArtistSelect;
