import React from "react";
import I18n from "shared/lib/I18n";
import Releases from "./Releases";
import Isrcs from "./Isrcs";
import Tracklist from "./Tracklist";

const Profile = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="album.view.noData.profile" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <Isrcs setNoData={setNoData} />
                </div>
                <div className="col">
                    <Releases setNoData={setNoData} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Tracklist />
                </div>
            </div>
        </>
    );
};

export default Profile;
