import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import { PARTNERS } from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";

const PartnerFilter = ({
    disabled,
    partnerFilter,
    total,
    selected,
    onPartnerChange,
    setAllFilters,
    isCollapsed,
    setIsCollapsed,
}) => {
    const location = useLocation();

    return (
        <>
            <div className="form-row">
                <div className="col text-nowrap">
                    <h5
                        className="cursor-pointer align-baseline"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <FontAwesomeIcon
                            icon={isCollapsed ? faChevronRight : faChevronDown}
                            size="xs"
                            fixedWidth
                            className="mr-1"
                        />
                        <I18n t="artist.management.disco.sandbox.filters.partners" />
                        <span className="badge badge-primary align-top ml-1">
                            {selected} / {total}
                        </span>
                    </h5>
                </div>
                <div className="col flex-grow-0 text-nowrap">
                    <button
                        className="btn btn-link p-0 align-middle mr-1"
                        onClick={() => setAllFilters(true)}
                        disabled={disabled}
                    >
                        <I18n t="utils.filters.all" />
                    </button>
                    <span className="align-middle">/</span>
                    <button
                        className="btn btn-link p-0 align-middle ml-1"
                        onClick={() => setAllFilters(false)}
                        disabled={disabled}
                    >
                        <I18n t="utils.filters.none" />
                    </button>
                </div>
            </div>
            <div className={`form-row mb-3 ${isCollapsed && "d-none"}`}>
                {PARTNERS.map((id) => (
                    <div key={id} className="col-6 col-xl-3 text-nowrap">
                        <input
                            className="align-middle"
                            type="checkbox"
                            id={`filter-partner-${id}`}
                            name={id}
                            checked={partnerFilter[id]}
                            onChange={onPartnerChange}
                            disabled={disabled}
                        />
                        <label
                            className="align-middle ml-1"
                            title={I18n.getTranslation(
                                location,
                                `common.partners.${id}`
                            )}
                            htmlFor={`filter-partner-${id}`}
                        >
                            {id}
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PartnerFilter;
