import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import {
    TRACKS_SET,
    DiscographyContext,
} from "pages/ArtistManagement/Discography";
import TracklistComparisonModal from "./TracklistComparisonModal";
import swal from "@sweetalert/with-react";

const EnhancedTracklistComparisonModal = ({
    partnerAbbreviatedName,
    partnerId,
    onClose,
    ...props
}) => {
    const { api } = useAPI();
    const { tracksLoading, tracks, dispatchDiscography } =
        useContext(DiscographyContext);
    const location = useLocation();
    const { selectedTab, artistId, albumId, releaseId, trackId, locale } =
        useParams();
    const [partnerTracks, setPartnerTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [isSubmittingReplace, setIsSubmittingReplace] = useState(false);

    const getPartnerTracks = useCallback(() => {
        if (partnerAbbreviatedName !== null && partnerId !== null) {
            setIsLoading(true);
            api.get(
                `disco/tracklist/${partnerAbbreviatedName.toLowerCase()}/${partnerId}`
            )
                .then((response) => {
                    setPartnerTracks(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setPartnerTracks([]);
                    setIsLoading(false);
                });
        }
    }, [partnerAbbreviatedName, partnerId]);

    useEffect(getPartnerTracks, [getPartnerTracks]);

    const handleReplace = () => {
        setIsSubmittingReplace(true);
        let recordingControlOption = 0;

        const sendRequest = () => {
            api.post(
                `artist/${artistId}/album/${albumId}/release/${releaseId}/tracks/from_partner`,
                { locale, recordingControlOption },
                {
                    partner: partnerAbbreviatedName,
                    id: partnerId,
                }
            )
                .then(onRequestSuccess)
                .catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            toast.success(
                I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.tracklistComparison.replace.success"
                )
            );
            dispatchDiscography({
                type: TRACKS_SET,
                data: response,
            });
            if (trackId) {
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}`
                );
            }
            onClose();
            setIsSubmittingReplace(false);
        };

        const onDuplicateIsrc = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.tracklistComparison.replace.warningIsrc.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.sandbox.tracklistComparison.replace.warningIsrc.text"
                        )}
                        <ul className="list-group border-bottom">
                            {error.body.recordings.map((row) => (
                                <li
                                    key={row.id_recording}
                                    className="list-group-item"
                                >
                                    <a
                                        href={`/${locale}/artist/${row.id_artist}/edit/discography/album/${row.id_album}/release/${row.id_release}/track/${row.id_track}/recording/${row.id_recording}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.isrc} - {row.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    create: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.create"
                        ),
                        value: 1,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                    merge: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.merge"
                        ),
                        value: 2,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmittingReplace(false);
                    return;
                }

                recordingControlOption = isConfirm;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_isrc") {
                onDuplicateIsrc(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.tracklistComparison.replace.error",
                        error.message
                    )
                );
                setIsSubmittingReplace(false);
            }
        };

        sendRequest();
    };

    const onReplace = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.replace.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.replace.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleReplace();
        });
    };

    return (
        <TracklistComparisonModal
            isLoading={isLoading || tracksLoading}
            isSubmittingReplace={isSubmittingReplace}
            tracks={tracks}
            partnerTracks={partnerTracks}
            onReplace={onReplace}
            onClose={onClose}
            {...props}
        />
    );
};

export default EnhancedTracklistComparisonModal;
