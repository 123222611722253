import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import useAPI from "shared/hooks/useApi";
import CSAArtists from "./CSAArtists";

const EnhancedCSAArtists = ({ year, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: true,
        artists: [],
    });

    const fetchArtists = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            artists: [],
        });
        api.get(`cnm/repository/csa_artists/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        artists: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        artists: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchArtists, [fetchArtists]);

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.csa_artists.name"
            ),
            filterValue: normalizeTitle,
        },
    ];

    return (
        <CSAArtists
            columns={columns}
            onColumnMatch={onColumnMatchNormalized}
            {...state}
            {...props}
        />
    );
};

export default EnhancedCSAArtists;
