import React, { useState } from "react";
import Action from "./Action";

const EnhancedAction = ({ onSuccess, ...props }) => {
    const [showTags, setShowTags] = useState(false);
    const handleCloseTags = () => setShowTags(false);
    const handleShowTags = () => setShowTags(true);
    const onSuccesTags = () => {
        setShowTags(false);
        setShowCrop(true);
        onSuccess?.();
    };

    const [showCrop, setShowCrop] = useState(false);
    const handleCloseCrop = () => setShowCrop(false);
    const handleShowCrop = () => setShowCrop(true);
    const onSuccesCrop = () => {
        setShowCrop(false);
        onSuccess?.();
        setShowTagsVersion(true);
    };

    const [showTagsVersion, setShowTagsVersion] = useState(false);
    const handleCloseTagsVersion = () => setShowTagsVersion(false);
    const handleShowTagsVersion = () => setShowTagsVersion(true);
    const onSuccesTagsVersion = () => {
        setShowTagsVersion(false);
    };

    return (
        <Action
            {...props}
            showTags={showTags}
            handleCloseTags={handleCloseTags}
            handleShowTags={handleShowTags}
            onSuccesTags={onSuccesTags}
            showCrop={showCrop}
            handleCloseCrop={handleCloseCrop}
            handleShowCrop={handleShowCrop}
            onSuccesCrop={onSuccesCrop}
            showTagsVersion={showTagsVersion}
            handleCloseTagsVersion={handleCloseTagsVersion}
            handleShowTagsVersion={handleShowTagsVersion}
            onSuccesTagsVersion={onSuccesTagsVersion}
        />
    );
};

export default EnhancedAction;
