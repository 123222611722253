import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import MoodmediaRecommendation from "./MoodmediaRecommendation";

const EnhancedMoodmediaRecommendation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState({ tracks: [] });

    const onSearch = (
        msTrackIds,
        msArtistIds,
        mmTrackIds,
        filterNo,
        catalog,
        minAccuracy
    ) => {
        setIsLoading(true);
        api.get("moodmedia/recommendation", {
            locale,
            msTrackIds,
            msArtistIds,
            mmTrackIds,
            filterNo,
            catalog,
            minAccuracy,
        })
            .then((response) => {
                setResults(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    };

    return (
        <MoodmediaRecommendation
            isLoading={isLoading}
            results={results}
            onSearch={onSearch}
            location={location}
            {...props}
        />
    );
};

export default EnhancedMoodmediaRecommendation;
