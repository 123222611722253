import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { useLocation } from "react-router";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ProcessingGallery = ({ gallery, columns, isLoading }) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <section className="widget pb-0">
            <ToolkitProvider
                keyField="id_picture"
                data={gallery}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <>
                        <header className="d-flex justify-content-between">
                            <h4>
                                <I18n t="pictures.label.validation.processingHead" />
                            </h4>
                        </header>
                        <div className="widget-body">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "pictures.label.validation.search"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="widget-body widget-table-overflow">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    striped
                                    hover
                                    keyField="id_picture"
                                    data={gallery}
                                    columns={columns}
                                    noDataIndication={
                                        <I18n t="pictures.label.validation.empty" />
                                    }
                                />
                            )}
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </section>
    );
};

export default ProcessingGallery;
