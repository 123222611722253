import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Filter from "./Filter";
import { getFilterOptions, getFilteredPlannings } from "./Filter.selector";

const EnhancedFilter = (props) => {
    const { plannings, setFilteredPlannings } = props;
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({
        status: null,
        startDate: null,
        endDate: null,
    });
    const location = useLocation();
    const filterOptions = getFilterOptions({ plannings, location });

    useEffect(() => {
        setFilteredPlannings(
            getFilteredPlannings({ plannings, selectedFilterOptions })
        );
    }, [plannings, setFilteredPlannings, selectedFilterOptions]);

    return (
        <Filter
            {...props}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            filterOptions={filterOptions}
        />
    );
};

export default EnhancedFilter;
