import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import ArtistMatcher from "./ArtistMatcher";

const EnhancedArtistMatcher = ({
    entryId,
    locale,
    language,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const onMatch = (msArtistId) => {
        api.post(
            `matching/top/entry/${entryId}/artist`,
            {
                locale,
                language,
            },
            {
                msArtistId,
            }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return <ArtistMatcher onMatch={onMatch} locale={locale} {...props} />;
};

export default EnhancedArtistMatcher;
