import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import InputSelect from "shared/components/Form/InputSelect";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import CopyMatchingsButton from "./CopyMatchingsButton";
import MatchSelectionButton from "./MatchSelectionButton";

const MatchingList = ({
    editable,
    columns,
    onColumnMatch,
    isLoading,
    matchings,
    fetchGenres,
    setMatchings,
    msGenreOptions,
    cnmGenreOptions,
    cnmGenreFilterOptions,
    filters,
    setFilters,
    selectRow,
    selectedMatchingIds,
    location,
    year,
}) => {
    return (
        <ToolkitProvider
            keyField="msId"
            data={matchings}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <Widget
                    title="cnm.repositories.cnm_genres.matchings.title"
                    className="vertical-shrink mb-0 pb-0"
                    actions={
                        editable && (
                            <CopyMatchingsButton
                                yearDst={year}
                                onSuccess={fetchGenres}
                            />
                        )
                    }
                >
                    <Widget.Body>
                        <div className="mb-3">
                            <Search.SearchBar
                                {...toolkitprops.searchProps}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "cnm.repositories.cnm_genres.matchings.search"
                                )}
                            />
                        </div>
                    </Widget.Body>
                    <Widget.Body className="d-flex">
                        <InputSelect
                            options={msGenreOptions}
                            value={msGenreOptions.find(
                                ({ value }) => value == filters.msMainGenre
                            )}
                            onChange={(opt, action) =>
                                setFilters({
                                    msMainGenre:
                                        action.action === "select-option"
                                            ? opt.value
                                            : null,
                                })
                            }
                            placeholder={I18n.getTranslation(
                                location,
                                "cnm.repositories.cnm_genres.matchings.filter.msMainGenre"
                            )}
                            className="flex-grow-1 mr-3"
                            enablePortal
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                        <InputSelect
                            options={cnmGenreFilterOptions}
                            value={cnmGenreFilterOptions.find(
                                ({ value }) => value == filters.cnmGenre
                            )}
                            onChange={(opt, action) =>
                                setFilters({
                                    cnmGenre:
                                        action.action === "select-option"
                                            ? opt.value
                                            : null,
                                })
                            }
                            placeholder={I18n.getTranslation(
                                location,
                                "cnm.repositories.cnm_genres.matchings.filter.cnmGenre"
                            )}
                            className="flex-grow-1 mr-3"
                            enablePortal
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                        {editable && (
                            <MatchSelectionButton
                                genreOptions={cnmGenreOptions}
                                matchings={matchings}
                                selectedMatchingIds={selectedMatchingIds}
                                setMatchings={setMatchings}
                            />
                        )}
                    </Widget.Body>

                    <Widget.Body
                        className="widget-table-overflow overflow-auto"
                        style={{
                            margin: "10px -20px 0px -20px",
                        }}
                    >
                        {isLoading ? (
                            <Loader
                                className="h-100 align-items-center"
                                size="3x"
                            />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                selectRow={selectRow}
                                bootstrap4
                                striped
                                hover
                                bordered={false}
                                noDataIndication={
                                    <I18n t="cnm.repositories.cnm_genres.matchings.empty" />
                                }
                                classes="table-header-fixed"
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default MatchingList;
