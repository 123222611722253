import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import Chart from "./Chart";

const EnhancedChart = (props) => {
    const { api } = useAPI();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const {
        id_pf_or_year: id_platform,
        id_year,
        chartType,
        locale,
    } = useParams();

    const fetchData = useCallback(() => {
        setIsLoading(true);
        let cancelled = false;
        if (id_year) {
            api.get(
                `cnm/charts/deduplicated/${id_platform}/${id_year}/${chartType}`,
                {
                    locale,
                }
            )
                .then((response) => {
                    if (!cancelled) {
                        setData(response.chart);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                });
        } else {
            if (!cancelled) {
                setData([]);
                setIsLoading(false);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [id_year, chartType]);

    useEffect(fetchData, [fetchData]);

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.chart.position"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.deduplicated.chart.position"
                ),
            headerStyle: { width: "4em" },
            searchable: false,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.chart.artist"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.deduplicated.chart.artist"
                ),
            title: true,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.chart.title"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.deduplicated.chart.title"
                ),
            title: true,
        },
        {
            dataField: "isrcs",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.chart.isrc"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.deduplicated.chart.isrc"
                ),
            headerStyle: { width: "8em" },
            formatter: (cell) =>
                cell && cell.length > 0
                    ? cell.map((isrc) => (
                          <span
                              key={isrc}
                              className="badge badge-secondary ml-1 mt-1"
                          >
                              {isrc}
                          </span>
                      ))
                    : "-",
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.chart.streams"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.deduplicated.chart.streams"
                ),
            classes: "text-right",
            headerStyle: { width: "7em" },
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 1000,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Chart
            data={data}
            columns={columns}
            isLoading={isLoading}
            location={location}
            pagination={pagination}
            {...props}
        />
    );
};

export default EnhancedChart;
