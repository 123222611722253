import React from "react";
import Tippy from "@tippyjs/react";
import I18n from "shared/lib/I18n";
import { Badge } from "react-bootstrap";

const Explicit = ({ explicit, ...props }) => {
    if (!explicit) {
        return;
    }

    return (
        <Tippy content={<I18n t="common.explicit.long" />} trigger="mouseenter">
            <Badge variant="secondary" {...props}>
                <I18n t="common.explicit.short" />
            </Badge>
        </Tippy>
    );
};

export default Explicit;
