import React from "react";
import Albums from "./Albums";
import Releases from "./Releases";
import Sandbox from "./Sandbox";
import Tracks from "./Tracks";
import Objects from "./Objects";

const Discography = ({ covers, setCovers }) => {
    return (
        <div className="artist-management-discography">
            <div className="row">
                <div className="col-4">
                    <Albums />
                    <Releases />
                    <Tracks />
                </div>
                <div className="col-5">
                    <Objects covers={covers} setCovers={setCovers} />
                </div>
                <div className="col-3">
                    <Sandbox covers={covers} setCovers={setCovers} />
                </div>
            </div>
        </div>
    );
};

export default Discography;
