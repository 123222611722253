import React, { useState, useEffect, useCallback } from "react";
import Group from "./Group";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import {
    orderByStartDate,
    groupByRole,
    getCurrent,
    getOld,
} from "./Group.pure";

const EnhancedGroup = (props) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        currentGroup: [],
        oldGroup: [],
        roleOptions: [],
        isLoading: true,
    });

    const getGroup = useCallback(() => {
        return api
            .get(`artist/${artistId}/group`)
            .then((response) => {
                const data = orderByStartDate(groupByRole(response));
                setState((prev) => ({
                    ...prev,
                    currentGroup: getCurrent(data),
                    oldGroup: getOld(data),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getRole = useCallback(() => {
        return api
            .get(`role/${locale}`)
            .then((response) => {
                setState((prev) => ({ ...prev, roleOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getGroup(), getRole()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getGroup, getRole]);

    return <Group {...props} {...state} locale={locale} />;
};

export default EnhancedGroup;
