import React from "react";
import I18n from "shared/lib/I18n";
import BiographyGallery from "./BiographyGallery";

const Biography = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="artist.view.noData.biography" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col">
                <BiographyGallery setNoData={setNoData} />
            </div>
        </div>
    );
};

export default Biography;
