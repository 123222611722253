import React from "react";
import SnepFileManagement from "./SnepFileManagement";
import { useLocation } from "react-router";

const EnhancedSnepFileManagement = (props) => {
    const location = useLocation();
    return <SnepFileManagement {...props} location={location} />;
};

export default EnhancedSnepFileManagement;
