import { createSelector } from "reselect";
import moment from "moment";

export const getFilterOptions = createSelector(
    [(args) => args.charts],
    (charts) =>
        charts.reduce(
            (acc, curr) => {
                const currYear = moment(curr.startDate).format("YYYY");
                const currMonth = moment(curr.startDate).format("MM");

                if (currYear) {
                    acc["year"] = [
                        ...acc.year,
                        {
                            label: currYear,
                            value: currYear,
                        },
                    ]
                        .filter(
                            (row, index, self) =>
                                self.findIndex((t) => t.value === row.value) ===
                                index
                        )
                        .sort((a, b) => (a.value < b.value ? 1 : -1));
                }

                if (currMonth) {
                    acc["month"] = [
                        ...acc.month,
                        {
                            label: currMonth,
                            value: currMonth,
                        },
                    ]
                        .filter(
                            (row, index, self) =>
                                self.findIndex((t) => t.value === row.value) ===
                                index
                        )
                        .sort((a, b) => (a.value > b.value ? 1 : -1));
                }

                return acc;
            },
            {
                year: [],
                month: [],
            }
        )
);

export const getFilteredCharts = createSelector(
    [(args) => args.charts, (args) => args.filter],
    (charts, filter) => {
        let filteredCharts = charts;

        if (filter.year) {
            filteredCharts = filteredCharts.filter((p) => {
                const year = moment(p.startDate).format("YYYY");
                return year === filter.year.value;
            });
        }

        if (filter.month) {
            filteredCharts = filteredCharts.filter((p) => {
                const month = moment(p.startDate).format("MM");
                return month === filter.month.value;
            });
        }

        return filteredCharts;
    }
);
