import React from "react";
import Country from "./Country";

const Geographies = ({ geographies, showPopover }) => {
    return geographies.map(({ geography, country, hovered }) => (
        <Country
            key={geography.properties.code}
            geography={geography}
            charts={country?.charts ?? 0}
            score={country?.score ?? 0}
            hovered={hovered}
            onMouseEnter={(marker) =>
                showPopover(geography.properties.code, country, marker)
            }
        />
    ));
};

export default Geographies;
