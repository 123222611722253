import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import "./PlanningsStatistic.css";
import MetabaseView from "shared/components/MetabaseView";
import { Helmet } from "react-helmet";

const PlanningsStatistic = ({ location }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.statistic.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="plannings.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="plannings.statistic.breadcrumb" />
                    </li>
                </ol>
                <Tabs
                    defaultActiveKey="tracking_period"
                    id="planning_stat_metabase"
                    variant="pills"
                    mountOnEnter={true}
                >
                    <Tab
                        eventKey="tracking_period"
                        title={<I18n t="plannings.statistic.tracking_period" />}
                    >
                        <MetabaseView
                            id="26"
                            frameBorder="0"
                            width="100%"
                            height="2450"
                        />
                    </Tab>
                    <Tab
                        eventKey="chart_tracking"
                        title={<I18n t="plannings.statistic.chart_tracking" />}
                    >
                        <MetabaseView
                            id="29"
                            frameBorder="0"
                            width="100%"
                            height="1400"
                        />
                    </Tab>
                    <Tab
                        eventKey="ressources_tracking"
                        title={
                            <I18n t="plannings.statistic.ressources_tracking" />
                        }
                    >
                        <MetabaseView
                            id="30"
                            frameBorder="0"
                            width="100%"
                            height="1400"
                        />
                    </Tab>
                    <Tab
                        eventKey="planning_annual"
                        title={<I18n t="plannings.statistic.planning_annual" />}
                    >
                        <MetabaseView
                            id="27"
                            frameBorder="0"
                            width="100%"
                            height="6000"
                        />
                    </Tab>
                    <Tab
                        eventKey="charts_annual_tracking"
                        title={
                            <I18n t="plannings.statistic.charts_annual_tracking" />
                        }
                    >
                        <MetabaseView
                            id="28"
                            frameBorder="0"
                            width="100%"
                            height="2050"
                        />
                    </Tab>
                    <Tab
                        eventKey="resources_annual_tracking"
                        title={
                            <I18n t="plannings.statistic.resources_annual_tracking" />
                        }
                    >
                        <MetabaseView
                            id="31"
                            frameBorder="0"
                            width="100%"
                            height="2050"
                        />
                    </Tab>
                </Tabs>
            </main>
        </div>
    );
};

export default PlanningsStatistic;
