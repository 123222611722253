import React from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import APIDownloadLink from "./APIDownloadLink";

const EnhancedAPIDownloadLink = ({ route, filename, ...props }) => {
    const { api } = useAPI();

    const onClick = (e) => {
        e.preventDefault();
        toast.promise(
            api
                .getRaw(route)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.style.display = "none";
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);
                }),
            {
                pending: {
                    render() {
                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    pulse={true}
                                />
                                <span className="ml-2">
                                    <I18n t="common.apiDownloadButton.pending" />
                                </span>
                            </>
                        );
                    },
                },
                success: {
                    render() {
                        return I18n.getTranslation(
                            location,
                            "common.apiDownloadButton.success"
                        );
                    },
                },
                error: {
                    render({ data }) {
                        console.error(data);
                        return data.message;
                    },
                },
            }
        );
    };

    return <APIDownloadLink onClick={onClick} {...props} />;
};

export default EnhancedAPIDownloadLink;
