import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import TopList from "./TopList";

const EnhancedTopList = ({ partner, date, selectedTop, ...props }) => {
    const { api } = useAPI();
    const [isLoading, setIsLoading] = useState(true);
    const [tops, setTops] = useState([]);

    const fetchTops = useCallback(() => {
        let cancelled = false;
        setIsLoading(true);
        api.get(`partner_tops/${partner}/${date}`)
            .then((response) => {
                if (!cancelled) {
                    setTops(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setTops([]);
                    setIsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            // This will be called by useEffect before the next call to
            // fetchTops. This "cancels" the current fetchTops call, to make
            // sure that it does not trigger a rerendering of the component
            // with stale data.
            cancelled = true;
        };
    }, [partner, date]);

    useEffect(() => {
        fetchTops();
    }, [fetchTops]);

    return (
        <TopList
            isLoading={isLoading}
            tops={tops}
            partner={partner}
            date={date}
            selectedTop={selectedTop}
            {...props}
        />
    );
};

export default EnhancedTopList;
