import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UPLOAD_MAX_SIZE } from "shared/constants/file";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import UploadModal from "./UploadModal";
import { reset } from "mousetrap";

const defaultValues = {
    file: null,
};

const schema = yup.object().shape({
    file: yup
        .mixed()
        .test("fileSize", "utils.form.upload_max_size", (value) => {
            return value.size <= UPLOAD_MAX_SIZE;
        })
        .required("utils.form.required"),
});

const EnhancedUploadModal = ({ show, onHide, onSuccess, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const {
        handleSubmit,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [show]);

    const onSubmit = (data) => {
        return api
            .postFile("snep/file", { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "snep.file.fileList.upload.success"
                    )
                );
                onHide();
                onSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <UploadModal
            show={show}
            onHide={onHide}
            control={control}
            errors={errors}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
            {...props}
        />
    );
};

export default EnhancedUploadModal;
