import React, { useMemo } from "react";
import { useGeographies } from "react-simple-maps";
import Geographies from "./Geographies";

const EnhancedGeographies = React.memo(
    ({ countries, hoveredCountry, ...props }) => {
        const { geographies: countryGeographies } = useGeographies({
            geography: "/static/map/countries.json",
        });
        // We use a second map files for "tiny countries" that are displayed as
        // dots on the map (Hong Kong, Monaco, ...)
        const { geographies: tinyCountryGeographies } = useGeographies({
            geography: "/static/map/tiny-countries.json",
        });

        const geographies = useMemo(
            () =>
                [
                    ...countryGeographies.map((geography) => ({
                        geography,
                        tiny: false,
                    })),
                    ...tinyCountryGeographies.map((geography) => ({
                        geography,
                        tiny: true,
                    })),
                ]
                    .map(({ geography, tiny }) => ({
                        geography,
                        tiny,
                        country:
                            countries.find(
                                (country) =>
                                    country.code === geography.properties.code
                            ) ?? null,
                        hovered: geography.properties.code === hoveredCountry,
                        order: tiny
                            ? 3 // Draw tiny countries on top
                            : geography.properties.code === hoveredCountry
                            ? 2 // Then hovered country (to prevent the other countries from overlapping its border)
                            : 1, // And the other countries below
                    }))
                    // Hide tiny countries with no data
                    .filter(
                        ({ tiny, country }) =>
                            !tiny ||
                            (country !== null && country?.rank !== null)
                    )
                    .sort((a, b) => a.order - b.order),
            [
                countryGeographies,
                tinyCountryGeographies,
                countries,
                hoveredCountry,
            ]
        );

        return <Geographies geographies={geographies} {...props} />;
    }
);

export default EnhancedGeographies;
