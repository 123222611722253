import React, { useState } from "react";
import Data from "./Data";
import I18n from "shared/lib/I18n";
import { ProgressBar } from "react-bootstrap";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import { displayAsString } from "shared/functions/Date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router";
import { getFilterOptions, getFilteredRecordings } from "./Data.pure";
import clsx from "clsx";
import moment from "moment";
import RecordingLink from "shared/components/RecordingLink";
import { MAIN_CHARTS, EDITORS_PICK, HIT } from "../RecordingCharts.constants";

const defaultFilter = {
    year: null,
    mode: "=",
    chart: null,
    country: null,
};

const defaultSort = {
    dataField: "rn",
    order: "asc",
};

const EnhancedData = ({ type, recordings, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const [selectedFilterOptions, setSelectedFilterOptions] =
        useState(defaultFilter);
    const [sortOption, setSortOption] = useState(defaultSort);
    const [expanded, setExpanded] = useState([]);
    const filterOptions = getFilterOptions({ type, recordings });

    const reset = () => {
        setSelectedFilterOptions(defaultFilter);
        setSortOption(defaultSort);
    };

    const columns = [
        {
            dataField: "cover",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.cover"
            ),
            formatter: (cell) => (
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Popover id={`popover-positioned-top`}>
                            <Popover.Content className="bg-black rounded-sm">
                                <ImageLoader
                                    src={cell}
                                    style={{
                                        width: "400px",
                                        height: "400px",
                                        objectFit: "contain",
                                    }}
                                />
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <span>
                        <ImageLoader src={cell} className="img-40" />
                    </span>
                </OverlayTrigger>
            ),
            headerStyle: { width: "7rem" },
            searchable: false,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.title"
            ),
            formatter: (cell, { id }) => (
                <RecordingLink recordingId={id} content={cell} />
            ),
            headerStyle: {
                width: [HIT, EDITORS_PICK].includes(type) ? "25rem" : "35rem",
            },
        },
        type === HIT && {
            dataField: "hit",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.star"
            ),
            formatter: (cell) => (
                <div style={{ width: "60px" }}>
                    <ProgressBar
                        now={(parseInt(cell, 10) / 5) * 100}
                        variant="purple-ms"
                        style={{
                            height: "8px",
                        }}
                    />
                </div>
            ),
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            headerStyle: { width: "10rem" },
            sort: true,
            searchable: false,
        },
        type === EDITORS_PICK && {
            dataField: "editorial_pick",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.star"
            ),
            formatter: (cell) => (
                <div style={{ width: "60px" }}>
                    <ProgressBar
                        now={(parseInt(cell, 10) / 5) * 100}
                        variant="purple-ms"
                        style={{
                            height: "8px",
                        }}
                    />
                </div>
            ),
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            headerStyle: { width: "10rem" },
            sort: true,
            searchable: false,
        },
        {
            dataField: "peak_rank",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.peak_rank"
            ),
            formatter: (
                cell,
                { id, main_charts, all_charts },
                rowIndex,
                { expanded }
            ) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                const expandButton = expanded.includes(id) ? (
                    <FontAwesomeIcon
                        onClick={() => {
                            setExpanded((curr) => curr.filter((x) => x !== id));
                        }}
                        icon={faChevronUp}
                        className="cursor-pointer"
                        fixedWidth
                    />
                ) : (
                    <FontAwesomeIcon
                        onClick={() => {
                            setExpanded((curr) => [...curr, id]);
                        }}
                        icon={faChevronDown}
                        className="cursor-pointer"
                        fixedWidth
                    />
                );

                return (
                    <div className="d-flex justify-content-between align-items-center">
                        <span>{charts[0]?.rank ?? "-"}</span>
                        {charts.length > 1 && expandButton}
                    </div>
                );
            },
            formatExtraData: {
                expanded,
            },
            sortValue: (cell, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                return charts[0]?.rank ?? "-";
            },
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            headerStyle: { width: "12rem" },
            isDummyField: true,
            sort: true,
            searchable: false,
        },
        {
            dataField: "chart",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.chart"
            ),
            formatter: (cell, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                if (charts.length > 1) {
                    return (
                        <I18n t="artist.view.chartsRecordingCharts.multi_charts" />
                    );
                } else {
                    return charts[0]?.chart.name ?? "-";
                }
            },
            headerStyle: { width: "30rem" },
            isDummyField: true,
            searchable: false,
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.country"
            ),
            formatter: (cell, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                if (charts.length > 1) {
                    return (
                        <I18n t="artist.view.chartsRecordingCharts.multi_country" />
                    );
                } else {
                    return charts[0]?.country.name ?? "-";
                }
            },
            headerStyle: { width: "20rem" },
            isDummyField: true,
            searchable: false,
        },
        {
            dataField: "year",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.entry_date"
            ),
            formatter: (cell, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                return charts[0]?.date
                    ? displayAsString(charts[0]?.date, locale)
                    : "-";
            },
            sortValue: (cell, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                return charts[0]?.date ?? "-";
            },
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            headerStyle: { width: "15rem" },
            isDummyField: true,
            sort: true,
            searchable: false,
        },
        {
            dataField: "trend",
            text: I18n.getTranslation(
                location,
                "artist.view.chartsRecordingCharts.trend"
            ),
            formatter: (cell, { id, all_charts }) => {
                if (all_charts.length > 0) {
                    return (
                        <RecordingLink
                            recordingId={id}
                            content={
                                <Button
                                    variant="light"
                                    className="border"
                                    title={I18n.getTranslation(
                                        location,
                                        "artist.view.chartsRecordingCharts.trend"
                                    )}
                                >
                                    <FontAwesomeIcon
                                        icon={faChartLine}
                                        fixedWidth
                                    />
                                </Button>
                            }
                            part="charts"
                        />
                    );
                } else {
                    return "-";
                }
            },
            isDummyField: true,
            searchable: false,
        },
    ].filter(Boolean);

    const expandRow = {
        className: "expanding-charts",
        renderer: ({ main_charts, all_charts }) => {
            const charts = type === MAIN_CHARTS ? main_charts : all_charts;
            const rows = charts
                .map(({ chart, country, date, ...rest }) => {
                    const year = moment(date).format("YYYY");
                    const yearExistInFilter =
                        selectedFilterOptions.year &&
                        ((selectedFilterOptions.mode === "=" &&
                            selectedFilterOptions.year === year) ||
                            (selectedFilterOptions.mode === "<" &&
                                year < selectedFilterOptions.year) ||
                            (selectedFilterOptions.mode === ">" &&
                                year > selectedFilterOptions.year));
                    return {
                        ...rest,
                        chart,
                        country,
                        date,
                        warning:
                            yearExistInFilter ||
                            selectedFilterOptions.chart?.value === chart.id ||
                            selectedFilterOptions.country?.value === country.id,
                    };
                })
                .sort((a, b) => {
                    const first = a.warning ? -1 : 1;
                    if (a.warning === b.warning) {
                        return a.rank > b.rank;
                    }
                    return first || a.rank > b.rank;
                })
                .map(({ rank, chart, country, date, warning }) => {
                    return (
                        <tr
                            key={`${rank}-${chart.id}-${date}`}
                            className={clsx(warning && "bg-warning-light")}
                        >
                            <td style={{ width: "42rem" }}></td>
                            <td style={{ width: "12rem" }}>{rank}</td>
                            <td style={{ width: "30rem" }}>{chart.name}</td>
                            <td style={{ width: "20rem" }}>{country.name}</td>
                            <td>{displayAsString(date, locale)}</td>
                        </tr>
                    );
                });

            return (
                <table className="table table-sm mb-0">
                    <tbody>{rows}</tbody>
                </table>
            );
        },
        expandByColumnOnly: true,
        expanded,
    };

    return (
        <Data
            {...props}
            type={type}
            recordings={getFilteredRecordings({
                type,
                recordings,
                selectedFilterOptions,
            })}
            columns={columns}
            expandRow={expandRow}
            sortOption={sortOption}
            location={location}
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            reset={reset}
        />
    );
};

export default EnhancedData;
