import React from "react";
import { Button } from "react-bootstrap";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Tracks from "./Tracks";

const Tracklist = ({
    isLoading,
    isSubmitting,
    isDirty,
    isValid,
    tracks,
    errors,
    dirtyCells,
    onSubmit,
    setTrackField,
    resetTracks,
    location,
}) => {
    return (
        <Widget
            title="artist.management.disco.objects.release.tracklist.head"
            enableCollapse
        >
            <Widget.Body className="widget-table-overflow border-top flex-grow-1">
                {isLoading ? (
                    <Loader className="h-100 align-items-center" size="3x" />
                ) : (
                    <div
                        className="mx-0 overflow-auto"
                        style={{ maxHeight: "350px" }}
                    >
                        <Tracks
                            tracks={tracks}
                            errors={errors}
                            dirtyCells={dirtyCells}
                            setTrackField={setTrackField}
                        />
                    </div>
                )}
                <div
                    className="d-flex flex-row-reverse py-3"
                    style={{ marginRight: "20px" }}
                >
                    <Button
                        variant="secondary ml-2"
                        disabled={isSubmitting || isLoading}
                        title={I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.tracklist.reset.title"
                        )}
                        onClick={resetTracks}
                    >
                        <I18n t="utils.button.reset" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={
                            isSubmitting || isLoading || !isDirty || !isValid
                        }
                        title={I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.release.tracklist.update.title`
                        )}
                        onClick={onSubmit}
                    >
                        <I18n t={`utils.button.edit`} />
                    </Button>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Tracklist;
