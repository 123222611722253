import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const RequestAction = ({ requested, onClick, location }) => {
    return (
        <Button
            title={I18n.getTranslation(
                location,
                requested
                    ? "matching.track.track.request.button.disabled"
                    : "matching.track.track.request.button.enabled"
            )}
            variant={requested ? "success" : "primary"}
            disabled={requested}
            onClick={onClick}
            className="p-2"
        >
            <FontAwesomeIcon
                icon={requested ? faThumbsUp : faEnvelope}
                size="lg"
                fixedWidth
            />
        </Button>
    );
};

export default RequestAction;
