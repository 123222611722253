import React from "react";
import { useLocation } from "react-router";
import swal from "sweetalert";
import { toast } from "react-toastify";
import useUser from "shared/hooks/useUser";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import PictureCheckButton from "./PictureCheckButton";

const EnhancedPictureCheckButton = ({
    artistId,
    comment,
    onCheck,
    ...props
}) => {
    const location = useLocation();
    const { api } = useAPI();
    const { user } = useUser();

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.pictureCheckButton.confirmModal.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.pictureCheckButton.confirmModal.text"
            ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            var textarea = document.createElement("textarea");
            textarea.rows = 6;
            textarea.className = "swal-content__textarea";

            if (comment) {
                textarea.value = comment;
            }

            swal({
                title: I18n.getTranslation(
                    location,
                    "common.pictureCheckButton.commentModal.title"
                ),
                content: textarea,
                buttons: {
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.validate"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isValidated) => {
                if (!isValidated) {
                    return;
                }

                const newComment = textarea.value || null;
                api.post(
                    `artist_picture_check/${artistId}`,
                    {},
                    { comment: newComment }
                )
                    .then((response) => {
                        if (onCheck !== undefined) {
                            onCheck(newComment);
                        }
                        toast.success(
                            I18n.getTranslation(
                                location,
                                "common.pictureCheckButton.commentModal.success"
                            )
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(
                            I18n.getTranslation(
                                location,
                                "common.pictureCheckButton.commentModal.error",
                                error.message
                            )
                        );
                    });
            });
        });
    };

    return (
        <PictureCheckButton
            isDisabled={!user.hasRight("artist.manage.picture.getty")}
            onClick={onClick}
            {...props}
        />
    );
};

export default EnhancedPictureCheckButton;
