import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const Tracks = ({ tracks, columns, defaultSorted }) => {
    return (
        <BootstrapTable
            keyField="id_track"
            data={tracks}
            columns={columns}
            bootstrap4
            striped
            hover
            condensed
            bordered={false}
            noDataIndication={<I18n t="artist.management.disco.tracks.empty" />}
            classes="table-truncate mb-0"
            defaultSorted={defaultSorted}
        />
    );
};

export default Tracks;
