import React from "react";
import Action from "./Action";
import { useLocation } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";

const EnhancedAction = ({ id_picture, comment, onSuccess, ...props }) => {
    const location = useLocation();
    const { api } = useAPI();

    const setStatus = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "pictures.label.rejected.retrieve.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "pictures.label.rejected.retrieve.alert.text"
            ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.put(
                `picture/label/${id_picture}/status`,
                {},
                { status: "AWAITING" }
            )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "pictures.label.rejected.retrieve.success"
                        )
                    );
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "pictures.label.rejected.retrieve.error",
                            error.message
                        )
                    );
                });
        });
    };

    const editMotif = () => {
        var textarea = document.createElement("textarea");
        textarea.rows = 6;
        textarea.className = "swal-content__textarea";
        textarea.value = comment;

        swal({
            title: I18n.getTranslation(
                location,
                "pictures.label.rejected.edit.title"
            ),
            content: textarea,
            buttons: {
                validate: {
                    text: I18n.getTranslation(
                        location,
                        "utils.button.validate"
                    ),
                    closeModal: true,
                },
            },
        }).then((isValidated) => {
            if (!isValidated) {
                return;
            }

            const newComment = textarea.value || null;
            api.put(
                `picture/label/${id_picture}/motif`,
                {},
                { motif: newComment }
            )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "pictures.label.rejected.edit.success"
                        )
                    );
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "pictures.label.rejected.edit.error",
                            error.message
                        )
                    );
                });
        });
    };

    return (
        <Action
            {...props}
            editMotif={editMotif}
            setStatus={setStatus}
            location={location}
        />
    );
};

export default EnhancedAction;
