import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PopularityMap from "./PopularityMap";

const defaultState = {
    gold: [],
    current: [],
    isLoading: false,
};

const EnhancedPopularityMap = ({ id_recording, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);

    const [popover, setPopover] = useState({
        show: false,
        id: null,
        country: null,
        target: null,
    });
    const showPopover = (id, country, target) =>
        setPopover({ show: true, id, country, target });
    const hidePopover = () => setPopover((prev) => ({ ...prev, show: false }));

    const getPopularity = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_recording) {
            return api
                .get(`recording/${id_recording}/popularity/countries`, {
                    locale,
                })
                .then((response) => {
                    setState({
                        ...response,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                });
        }
    }, [id_recording, locale]);

    useEffect(() => {
        getPopularity();
    }, [getPopularity]);

    return (
        <PopularityMap
            {...props}
            {...state}
            popover={popover}
            showPopover={showPopover}
            hidePopover={hidePopover}
            location={location}
        />
    );
};

export default EnhancedPopularityMap;
