import React from "react";
import I18n from "shared/lib/I18n";
import MetabaseView from "shared/components/MetabaseView";
import InputSelect from "shared/components/Form/InputSelect";
import "./Progress.css";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const Progress = ({ planning, users, setUsers, usersOptions, partnerId }) => {
    return (
        <>
            <div className="planning-stats-filter">
                <div className="row">
                    <div className="col-3">
                        <InputSelect
                            placeholder={
                                <I18n t="plannings.view.progress.username" />
                            }
                            options={usersOptions}
                            onChange={(opt, action) => {
                                if (
                                    action.action === "select-option" ||
                                    action.action === "create-option"
                                ) {
                                    setUsers([...users, action.option.label]);
                                } else if (action.action === "remove-value") {
                                    setUsers(
                                        users.filter(
                                            (label) =>
                                                label !=
                                                action.removedValue.label
                                        )
                                    );
                                } else if (action.action === "clear") {
                                    setUsers([]);
                                }
                            }}
                            isMulti
                        />
                    </div>
                </div>
            </div>
            <MetabaseView
                id="51"
                frameBorder="0"
                width="100%"
                height="710"
                params={{
                    date: `${planning.start_date}~${planning.end_date}`,
                    username: users,
                    planning_partner:
                        partnerId === PLANNING_PARTNER.ALL ? [] : [partnerId],
                }}
            />
        </>
    );
};

export default Progress;
