import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import MultiRecordingSelect from "./MultiRecordingSelect";

const EnhancedMultiRecordingSelect = ({ artistIds, locale, ...props }) => {
    const { api } = useAPI();
    const [globalSearch, setGlobalSearch] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const hasArtistIds =
        artistIds !== null && artistIds !== undefined && artistIds.length > 0;

    const search = useDebouncedCallback((term, callback) => {
        api.get(
            `searchengine/recording`,
            !globalSearch && hasArtistIds
                ? {
                      term,
                      idArtists: artistIds.join(","),
                  }
                : { term }
        )
            .then((response) => {
                callback(
                    response.map((row) => {
                        const name = [
                            row.id,
                            row.title,
                            row.subtitle,
                            row.duration,
                            row.artists.map(({ name }) => name).join(", "),
                            row.album_name,
                            row.album_format,
                            row.album_type,
                        ];
                        return {
                            id: row.id,
                            title: row.title,
                            label: name.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 750);

    return (
        <MultiRecordingSelect
            loadOptions={search}
            hasArtistIds={hasArtistIds}
            globalSearch={globalSearch || !hasArtistIds}
            setGlobalSearch={setGlobalSearch}
            inputValue={inputValue}
            setInputValue={setInputValue}
            {...props}
        />
    );
};

export default EnhancedMultiRecordingSelect;
