import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

const CreateAction = ({ created, artistId, onAddCreated, onDelCreated }) => {
    return (
        <Button
            variant="primary"
            onClick={created ? onDelCreated : onAddCreated}
            disabled={artistId === null}
            className="p-2"
        >
            <FontAwesomeIcon
                icon={created ? faToggleOn : faToggleOff}
                size="lg"
                fixedWidth
            />
        </Button>
    );
};

export default CreateAction;
