import React, { useState, useEffect } from "react";
import ImageUploadModal from "./ImageUploadModal";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { UPLOAD_MAX_SIZE } from "shared/constants/file";
import "./ImageUploadModal.css";

const schema = yup.object().shape({
    link: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim()
        .url("utils.form.url")
        .when("file", {
            is: (file) => !file,
            then: () =>
                yup
                    .string()
                    .nullable()
                    .transform((value) => value || null)
                    .required("utils.form.required"),
        }),
    file: yup
        .mixed()
        .nullable()
        .test("fileSize", "utils.form.upload_max_size", (value) => {
            return !(value?.size > UPLOAD_MAX_SIZE);
        }),
});

const EnhancedImageUploadModal = ({
    show,
    handleHide,
    onSuccess,
    creationParams,
    gettyPicture,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const [imageDimensions, setImageDimensions] = useState({
        width: 0,
        height: 0,
    });
    const [isDisabled, setIsDisabled] = useState(false);
    const [isGettingUpscale, setIsGettingUpscale] = useState(false);
    const [isGettingDownscale, setIsGettingDownscale] = useState(false);
    const [isSubmittingGetty, setIsSubmittingGetty] = useState(false);

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            link: null,
            file: null,
        },
    });

    useEffect(() => {
        if (!show) {
            reset({
                link: null,
                file: null,
            });
        }
    }, [show]);

    const onSubmit = (data) => {
        return api
            .postFile(
                `picture/curation`,
                { ...creationParams, locale },
                Object.entries(data).reduce(
                    (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
                    {}
                )
            )
            .then(({ id_picture, id_picture_original }) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.imageUploadModal.success"
                    )
                );
                onSuccess?.(id_picture, id_picture_original);
                setImageDimensions({
                    width: 0,
                    height: 0,
                });
                setIsDisabled(false);
                setIsGettingUpscale(false);
                setIsGettingDownscale(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const linkWatch = watch("link");
    const fileWatch = watch("file");

    useEffect(() => {
        if (linkWatch) {
            setValue("file", null);
            onGetSize({
                link: linkWatch,
            });
        }
    }, [linkWatch]);

    useEffect(() => {
        if (fileWatch) {
            setValue("link", null);
            onGetSize({
                file: fileWatch,
            });
        }
    }, [fileWatch]);

    const onGetSize = (data) => {
        if (data.link || data.file) {
            return api
                .postFile(
                    `picture/getSize`,
                    { ...creationParams, locale },
                    Object.entries(data).reduce(
                        (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
                        {}
                    )
                )
                .then((response) => {
                    setImageDimensions({
                        width: response.width,
                        height: response.height,
                    });
                    if (response.width < 500 || response.height < 500) {
                        setIsDisabled(true);
                    } else {
                        setIsDisabled(false);
                        if (
                            (response.width >= 500 && response.width < 1000) ||
                            (response.height >= 500 && response.height < 1000)
                        ) {
                            setIsGettingDownscale(false);
                            setIsGettingUpscale(true);
                        } else if (
                            response.width >= 3500 &&
                            response.height >= 3500
                        ) {
                            setIsGettingUpscale(false);
                            setIsGettingDownscale(true);
                        } else {
                            setIsGettingUpscale(false);
                            setIsGettingDownscale(false);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (gettyPicture) {
            if (
                gettyPicture.max_dimensions.width >= 3500 &&
                gettyPicture.max_dimensions.height >= 3500
            ) {
                setIsGettingDownscale(true);
            } else {
                setIsGettingDownscale(false);
            }
        }
    }, [gettyPicture]);

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.imageUploadModal.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.imageUploadModal.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleHide();
            reset({
                link: null,
                file: null,
            });
            setImageDimensions({ width: 0, height: 0 });
            setIsDisabled(false);
            setIsGettingUpscale(false);
            setIsGettingDownscale(false);
        });
    };

    const onSubmitGetty = () => {
        setIsSubmittingGetty(true);
        api.post(`picture/getty/${gettyPicture.id}`, creationParams)
            .then(({ id_picture, id_picture_original }) => {
                onSuccess?.(id_picture, id_picture_original);
                toast.success(
                    I18n.getTranslation(
                        location,
                        `common.picture.getty.bas.action.detail.success`
                    )
                );
                setIsSubmittingGetty(false);
            })
            .catch((error) => {
                console.error(error);
                setIsSubmittingGetty(false);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `common.picture.getty.bas.action.detail.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <ImageUploadModal
            {...props}
            show={show}
            locale={locale}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isSubmittingGetty={isSubmittingGetty}
            isDirty={isDirty}
            isDisabled={isDisabled}
            isGettingUpscale={isGettingUpscale}
            isGettingDownscale={isGettingDownscale}
            onSubmit={handleSubmit(onSubmit)}
            onSubmitGetty={onSubmitGetty}
            handleHide={handleHide}
            handleDelete={handleDelete}
            fileWatch={fileWatch}
            linkWatch={linkWatch}
            imageDimensions={imageDimensions}
            location={location}
            gettyPicture={gettyPicture}
        />
    );
};

export default EnhancedImageUploadModal;
