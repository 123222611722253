import React from "react";
import InputSwitch from "shared/components/Form/InputSwitch";

const CreationSwitch = ({ checked, msArtist, onChecked }) => {
    return (
        <InputSwitch
            checked={checked}
            disabled={msArtist === null || !msArtist.isNew}
            onChange={(e) => onChecked(e.target.checked)}
        />
    );
};

export default CreationSwitch;
