import { ARTIST_TYPE_SET } from "./Profile.constants";

export const ProfileReducer = (state, action) => {
    switch (action.type) {
        case ARTIST_TYPE_SET:
            return {
                ...state,
                artistType: action.data,
            };
    }
};
