import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import RecordingMatcher from "./RecordingMatcher";

const EnhancedRecordingMatcher = ({ trackId, onChange, locale, ...props }) => {
    const { api } = useAPI();

    const onMatchingChange = (value, action) => {
        if (
            action.action !== "select-option" &&
            action.action != "remove-value"
        ) {
            return;
        }

        api.post(
            "matching/track/linemusic/track",
            {
                locale,
            },
            {
                partnerId: trackId,
                msId:
                    action.action === "select-option"
                        ? action.option.id
                        : action.removedValue.id,
                state: action.action === "select-option" ? "VALID" : "INVALID",
            }
        )
            .then((response) => {
                onChange(trackId, response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <RecordingMatcher
            onMatchingChange={onMatchingChange}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedRecordingMatcher;
