import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import CNMPlatformIcon from "shared/components/CNMPlatformIcon";
import { getFilteredRows } from "./CNMTitleList.selector";
import CNMTitleList from "./CNMTitleList";
import DiscardButton from "./DiscardButton";

const EnhancedCNMTitleList = ({ rows, onCheck, onDiscard, ...props }) => {
    const location = useLocation();
    const { locale, chartType } = useParams();
    const { year, cnmTitleId } = useIntParams();
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        missingPlatforms: [],
        numberOfMatchedPlatforms: [],
        status: null,
    });

    const columns = [
        {
            dataField: "position",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.position"
            ),
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.artist"
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.title"
            ),
            sort: true,
        },
        {
            dataField: "platforms",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.platforms"
            ),
            formatter: (cell) => (
                <div className="d-flex">
                    {cell.map(({ id, name, matched }) => (
                        <span
                            key={id}
                            className={`badge badge-lg badge-${
                                matched ? "success" : "secondary"
                            } mr-1 px-1`}
                            title={name}
                        >
                            <CNMPlatformIcon name={name} />
                        </span>
                    ))}
                </div>
            ),
            searchable: false,
            sort: true,
            sortValue: (cell, row) =>
                cell.filter(({ matched }) => matched).length,
            headerStyle: { width: "4em" },
        },
        {
            dataField: "isrcs",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.isrcs"
            ),
            searchable: false,
            sort: true,
            headerStyle: { width: "4.5em" },
        },
        {
            dataField: "duplicate",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.duplicate"
            ),
            formatter: (cell) => (cell ? `${cell} %` : "-"),
            searchable: false,
            sort: true,
            headerStyle: { width: "4.5em" },
        },
        {
            dataField: "mixed",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.mixed"
            ),
            formatter: (cell) => (cell ? `${cell} %` : "-"),
            searchable: false,
            sort: true,
            headerStyle: { width: "4.5em" },
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.streams"
            ),
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
            sort: true,
            headerStyle: { width: "7em" },
        },
        {
            dataField: "checked",
            text: "",
            formatter: (cell, row, rowIndex, data) => (
                <Button
                    variant={cell ? "outline-danger" : "outline-success"}
                    size="sm"
                    className="p-1"
                    title={I18n.getTranslation(
                        data.location,
                        cell
                            ? "cnm.aggregation.cnmTitleList.uncheck"
                            : "cnm.aggregation.cnmTitleList.check"
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCheck(row);
                    }}
                >
                    <FontAwesomeIcon
                        icon={cell ? faTimes : faCheck}
                        fixedWidth
                        size="lg"
                    />
                </Button>
            ),
            formatExtraData: {
                location,
            },
            headerStyle: {
                width: "3em",
            },
            classes: () => "py-0",
        },
        {
            dataField: "discarded",
            text: "",
            formatter: (cell, row) => (
                <DiscardButton
                    year={year}
                    titleId={row.id}
                    discarded={cell}
                    comment={row.comment}
                    onDiscard={(discarded, comment) =>
                        onDiscard({ ...row, discarded, comment })
                    }
                />
            ),
            headerStyle: {
                width: "4em",
            },
            classes: () => "py-0",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const defaultSorted = [
        {
            dataField: "streams",
            order: "desc",
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ id }, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/cnm/aggregation/${year}/${chartType}/${id}`
                );
            }
        },
        selected: [cnmTitleId],
    };

    const rowClasses = ({ checked, discarded }) =>
        discarded ? "table-secondary" : checked ? "table-success" : undefined;

    return (
        <CNMTitleList
            rows={getFilteredRows({ rows, filter, cnmTitleId })}
            columns={columns}
            pagination={pagination}
            defaultSorted={defaultSorted}
            selectRow={selectRow}
            rowClasses={rowClasses}
            filter={filter}
            setFilter={setFilter}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCNMTitleList;
