import React, { useState, useEffect, useCallback } from "react";
import Planning from "./Planning";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { getFilteredTasks, getCategoryOptions } from "./Planning.selector";
import { status } from "./Planning.constants";

const EnhancedPlanning = ({ planning, isLoading, setIsLoading, ...props }) => {
    const { api } = useAPI();
    const [tasks, setTasks] = useState([]);
    const { locale } = useParams();
    const location = useLocation();
    const planningId = planning?.id;
    const [filter, setFilter] = useState({
        category: null,
    });

    const getTasks = useCallback(() => {
        if (planningId) {
            api.get(`plannings/${planningId}/tasks`, {
                locale,
                mode: "client",
            })
                .then((response) => {
                    setTasks(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, planningId, setIsLoading]);

    useEffect(() => {
        getTasks();
    }, [getTasks]);

    const columns = [
        {
            dataField: "ms_artist",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.artist"
            ),
            formatter: (cell) => {
                return cell ? (
                    <ArtistLink artistId={cell.id} content={cell.name} blank />
                ) : (
                    "-"
                );
            },
            filterValue: (cell) => cell?.name,
        },
        {
            dataField: "album_title",
            text: I18n.getTranslation(location, "plannings.client.tasks.album"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "track_title",
            text: I18n.getTranslation(location, "plannings.client.tasks.track"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "category",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.category"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "status",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_artist"
            ),
            formatter: (cell) => {
                return (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                );
            },
            searchable: false,
        },
        {
            dataField: "status_bio_fr",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_bio_fr"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_bio_en",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_bio_en"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_bio_de",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_bio_de"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_bio_ja",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_bio_ja"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_review",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_review"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_picture",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_picture"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "status_curation",
            text: I18n.getTranslation(
                location,
                "plannings.client.tasks.status_curation"
            ),
            formatter: (cell) => {
                return status[cell] ? (
                    <span className={`badge badge-${status[cell].color}`}>
                        <I18n t={status[cell].translation} />
                    </span>
                ) : (
                    "-"
                );
            },
            searchable: false,
        },
    ];

    return (
        <Planning
            {...props}
            planning={planning}
            tasks={getFilteredTasks({ tasks, filter })}
            categoryOptions={getCategoryOptions({ tasks })}
            isLoading={isLoading}
            columns={columns}
            location={location}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedPlanning;
