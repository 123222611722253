import React from "react";
import AddButton from "shared/components/Picture/Action/Label/AddButton";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import Widget from "shared/components/Widget";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import "./Label.css";

const Label = ({ isLoading, getPictures, gallery, columns, expandRow }) => {
    return (
        <div className="artist-management-label">
            <div className="row mb-3">
                <div className="col">
                    <AddButton onSuccess={getPictures} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Widget
                        title={`artist.management.imageLabel.head`}
                        enableCollapse
                    >
                        <Widget.Body className="border-top widget-table-overflow">
                            {isLoading ? (
                                <Loader className="my-4" />
                            ) : (
                                <BootstrapTable
                                    keyField="id"
                                    data={gallery}
                                    columns={columns}
                                    bootstrap4
                                    striped
                                    noDataIndication={
                                        <I18n t="artist.management.imageLabel.empty" />
                                    }
                                    expandRow={expandRow}
                                    classes="mb-0"
                                    headerClasses="hide"
                                    rowClasses="font-weight-bold"
                                />
                            )}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default Label;
