import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import useUser from "shared/hooks/useUser";
import CNMConsolidation from "./CNMConsolidation";

const EnhancedCNMConsolidation = (props) => {
    const { api } = useAPI();
    const { user } = useUser();
    const location = useLocation();
    const { locale, platformId, chartType } = useParams();
    const { year, titleId } = useIntParams();
    const [state, setState] = useState({
        chart: [],
        yearStatus: null,
        isLoading: false,
    });

    const editable =
        user.hasRight("cnm.report.consolidate.manage") &&
        state.yearStatus === "IN_PROGRESS";

    const fetchChart = useCallback(() => {
        if (!year || !chartType || platformId === undefined) {
            setState({
                chart: [],
                yearStatus: null,
                isLoading: false,
            });
            return;
        }

        let cancelled = false;
        setState({
            chart: [],
            yearStatus: null,
            isLoading: true,
        });
        api.get(`cnm/consolidation/charts/${year}/${chartType}/${platformId}`, {
            locale,
        })
            .then(({ chart, yearStatus }) => {
                if (!cancelled) {
                    setState({
                        chart,
                        yearStatus,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        chart: [],
                        yearStatus: null,
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year, chartType, platformId]);

    useEffect(fetchChart, [fetchChart]);

    const onSuccess = (id, data) => {
        setState((prev) => ({
            ...prev,
            chart: prev.chart.map((row) =>
                row.id === id ? { ...row, ...data } : row
            ),
        }));
    };

    return (
        <CNMConsolidation
            year={year}
            chartType={chartType}
            platformId={platformId}
            titleId={titleId}
            onSuccess={onSuccess}
            editable={editable}
            location={location}
            {...state}
            {...props}
        />
    );
};

export default EnhancedCNMConsolidation;
