import React from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    password: yup
        .string()
        .required("utils.form.required")
        .matches(/^(?=.*[A-Z]).+$/, "utils.form.must_contain_uppercase")
        .matches(/^(?=.*[a-z]).+$/, "utils.form.must_contain_lowercase")
        .matches(/^.{8,}$/, "utils.form.must_contain_8_characters")
        .matches(/^(?=.*\d).+$/, "utils.form.must_contain_digit")
        .matches(/^(?=.*[a-zA-Z]).+$/, "utils.form.must_contain_letter"),
});

const EnhancedChangePasswordModal = ({
    handleClose,
    user,
    onSuccess,
    rolesOptions,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            password: null,
        },
    });

    const onSubmit = (data) => {
        api.put(`users/${user.id}/changepassword`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `user.manage.change_password.success`
                    )
                );
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `user.manage.change_password.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <ChangePasswordModal
            {...props}
            user={user}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedChangePasswordModal;
