import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useUser from "shared/hooks/useUser";
import useAPI from "shared/hooks/useApi";
import CNMArtistsTitles from "./CNMArtistsTitles";

const EnhancedCNMArtistsTitles = (props) => {
    const { user } = useUser();
    const { api } = useAPI();
    const { locale } = useParams();

    const [titles, setTitles] = useState([]);
    const [isTitlesLoading, setIsTitlesLoading] = useState(false);

    const fetchTitles = useCallback(() => {
        let cancelled = false;
        setIsTitlesLoading(true);
        api.get("cnm/title", { locale })
            .then((response) => {
                if (!cancelled) {
                    setIsTitlesLoading(false);
                    setTitles(response);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setIsTitlesLoading(false);
                    setTitles([]);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);
    useEffect(fetchTitles, [fetchTitles]);

    const onTitleUpdate = ({ id, title, msRecordings }) => {
        setTitles((titles) =>
            titles.map((row) =>
                row.id === id ? { ...row, title, msRecordings } : row
            )
        );
    };

    const onTitleDelete = ({ id }) => {
        setTitles((titles) => titles.filter((row) => row.id !== id));
    };

    const [selectedArtist, setSelectedArtist] = useState(null);
    const [artists, setArtists] = useState([]);
    const [isArtistsLoading, setIsArtistsLoading] = useState(false);

    const fetchArtists = useCallback(() => {
        let cancelled = false;
        setIsArtistsLoading(true);
        api.get("cnm/artist", { locale })
            .then((response) => {
                if (!cancelled) {
                    setSelectedArtist(null);
                    setArtists([
                        { id: 0, name: "no_artist", msArtists: [] },
                        ...response,
                    ]);
                    setIsArtistsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setSelectedArtist(null);
                    setArtists([]);
                    setIsArtistsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);
    useEffect(fetchArtists, [fetchArtists]);

    const onArtistUpdate = ({ id, name, msArtists }) => {
        setArtists((artists) =>
            artists.map((row) =>
                row.id === id ? { id, name, msArtists } : row
            )
        );
        setTitles((titles) =>
            titles.map((row) =>
                row.artist.id === id
                    ? { ...row, artist: { id, name, msArtists } }
                    : row
            )
        );
    };

    const onArtistDelete = ({ id }) => {
        setArtists((artists) => artists.filter((row) => row.id !== id));
        setTitles((titles) => titles.filter((row) => row.artist.id !== id));
    };

    const filteredTitles = useMemo(
        () =>
            selectedArtist !== null
                ? titles.filter(({ artist }) => artist.id == selectedArtist.id)
                : titles,
        [titles, selectedArtist]
    );

    return (
        <CNMArtistsTitles
            editable={user.hasRight("cnm.repository.cnm_artists_titles.manage")}
            isArtistsLoading={isArtistsLoading}
            artists={artists}
            onArtistUpdate={onArtistUpdate}
            onArtistDelete={onArtistDelete}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            isTitlesLoading={isTitlesLoading}
            titles={filteredTitles}
            onTitleUpdate={onTitleUpdate}
            onTitleDelete={onTitleDelete}
            {...props}
        />
    );
};

export default EnhancedCNMArtistsTitles;
