import React from "react";
import { useParams } from "react-router";
import MapPopover from "./MapPopover";

const EnhancedMapPopover = (props) => {
    const { locale } = useParams();

    return <MapPopover locale={locale} {...props} />;
};

export default EnhancedMapPopover;
