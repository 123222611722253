import React from "react";
import Volume from "./Volume";
import Time from "./Time";
import Action from "./Action";
import Authorization from "./Authorization";
import Cover from "./Cover";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import "./ApplePlayer.css";

const ApplePlayer = ({ setMainState, playerLoading, playerError }) => {
    return (
        <div className="apple__sample__assessment__player__fixed shadow pt-2 pb-2">
            <div className="d-flex">
                <Cover />
                <div className="d-flex flex-column w-100">
                    <div className="d-flex justify-content-around">
                        <Action
                            setMainState={setMainState}
                            disable={playerLoading || playerError}
                        />
                        <Volume />
                    </div>
                    <Time />
                </div>
                <Authorization />
            </div>
            {playerLoading && (
                <div className="apple__sample__assessment__player__fixed__shadow">
                    <Loader size="1x" />
                </div>
            )}
            {playerError && (
                <div className="apple__sample__assessment__player__fixed__shadow">
                    <I18n t={playerError} />
                </div>
            )}
        </div>
    );
};

export default ApplePlayer;
