import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import PartnerSelect from "pages/ArtistManagement/Matching/PartnerSelect";
import { STATUS_COLOR } from "./MatchV2.constants";
import EditMatchingsTable from "shared/components/EditMatchingsTable";
import clsx from "clsx";

const MatchV2 = ({
    title,
    partner,
    isLoading,
    status,
    candidates,
    onMatchingChange,
}) => {
    return (
        <Widget title={title} enableCollapse>
            <Widget.Body className="widget-middle-overflow">
                {!isLoading && (
                    <table className="table table-bordered table-sm border-left-0 border-right-0 mb-0">
                        <tbody>
                            <tr
                                className={`table-${
                                    STATUS_COLOR[status] || "danger"
                                }`}
                            >
                                <td className="text-center border-left-0 border-right-0">
                                    <span>
                                        <strong>
                                            <I18n t="artist.management.match.status" />
                                        </strong>
                                        <span className="ml-1 mr-1">:</span>
                                        {status ? (
                                            <I18n
                                                t={`common.matching.status.${status}`}
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </Widget.Body>
            <Widget.Body
                className={clsx(
                    "widget-middle-overflow",
                    "p-2",
                    isLoading && "border-top"
                )}
            >
                <PartnerSelect
                    partner={partner}
                    onSelect={(candidateId) =>
                        onMatchingChange(candidateId, "VALID")
                    }
                    isDisabled={isLoading}
                />
            </Widget.Body>
            <Widget.Body
                className="widget-table-overflow border-top"
                style={{ overflowY: "auto", height: "229px" }}
            >
                {isLoading ? (
                    <Loader className="mt-4" />
                ) : (
                    <EditMatchingsTable
                        status={status}
                        candidates={candidates}
                        onChangeState={onMatchingChange}
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default MatchV2;
