import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import { formatDuration } from "./LyricfindMatcher.pure";

const LyricfindMatcher = ({ lyricfind, loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={lyricfind}
            loadOptions={loadOptions}
            onChange={onChange}
            getOptionValue={({ id }) => id}
            getOptionLabel={({
                id,
                title,
                artist_name,
                score,
                duration,
                type_match = null,
            }) =>
                [
                    id,
                    title,
                    artist_name,
                    type_match,
                    score,
                    formatDuration(duration),
                ]
                    .filter(Boolean)
                    .join(" - ")
            }
            {...props}
        />
    );
};

export default LyricfindMatcher;
