import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import UniversalArtist from "./UniversalArtist";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistMatcher from "./ArtistMatcher";
import MarkAction from "./Action/MarkAction";
import UnmarkAction from "./Action/UnmarkAction";
import CreationAction from "./Action/CreationAction";
import { getFilteredData } from "./UniversalArtist.selector";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./UniversalArtist.module.css";
import clsx from "clsx";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedUniversalArtist = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
        skiped: null,
    });

    const fetchUniversalArtist = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/artist/${partnerId}/list/${listId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchUniversalArtist();
    }, [fetchUniversalArtist]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.artist.artist.id"),
            sort: true,
        },
        {
            dataField: "firstname",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.firstname"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "lastname",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.lastname"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "complementary",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.complementary"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "discography",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.discography"
            ),
            formatter: (cell) => {
                return (
                    <Tippy
                        content={
                            <ul className={clsx("mb-0", styles.disco)}>
                                {cell.map((c) => (
                                    <li key={c.code_barre}>
                                        {c.code_barre}
                                        {c.name && ` - ${c.name}`}
                                        {c.release_date &&
                                            ` - ${c.release_date}`}
                                    </li>
                                ))}
                            </ul>
                        }
                        placement="left"
                        trigger="click"
                    >
                        <span className="cursor-pointer">
                            <FontAwesomeIcon
                                icon={faFileAlt}
                                size="2x"
                                fixedWidth
                            />
                        </span>
                    </Tippy>
                );
            },
            searchable: false,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.artist"
            ),
            formatter: (cell, { id }, rowIndex, { locale }) => {
                return (
                    <ArtistMatcher
                        listId={listId}
                        rowId={id}
                        locale={locale}
                        artist={cell}
                        onChange={(data) => updateRow(id, data)}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
            headerStyle: {
                width: "25rem",
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "action",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.action"
            ),
            formatter: (
                cell,
                { id, skiped, comment, artist_created, artist }
            ) => {
                return (
                    <div className="btn-group btn-group-sm">
                        {skiped ? (
                            <UnmarkAction
                                listId={listId}
                                rowId={id}
                                comment={comment}
                                onSuccess={(data) => updateRow(id, data)}
                            />
                        ) : (
                            <MarkAction
                                listId={listId}
                                rowId={id}
                                disabled={!!artist}
                                onSuccess={(data) => updateRow(id, data)}
                            />
                        )}
                        <CreationAction
                            listId={listId}
                            rowId={id}
                            artistId={artist?.id}
                            disabled={!artist}
                            artist_created={artist_created}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    </div>
                );
            },
            filterValue: (cell, { artist }) => artist?.name,
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ artist, warning, skiped }) => {
        let classes = null;

        if (warning) {
            return "table-warning";
        }

        if (artist) {
            return "table-success";
        }

        if (skiped) {
            return "table-secondary";
        }

        return classes;
    };

    return (
        <UniversalArtist
            {...props}
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            location={location}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedUniversalArtist;
