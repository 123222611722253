import React from "react";
import ReleaseSelect from "shared/components/ReleaseSelect";

const AlbumMatcher = ({ msArtist, msAlbum, locale, onMatch }) => {
    return (
        <ReleaseSelect
            artistIds={msArtist !== null ? [msArtist.id] : []}
            isDisabled={msArtist === null}
            id={msAlbum?.id}
            title={msAlbum?.title}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={() => onMatch(null)}
            menuPlacement="auto"
        />
    );
};

export default AlbumMatcher;
