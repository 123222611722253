import React, { useState } from "react";
import { useLocation } from "react-router";
import DuplicatesModalButton from "./DuplicatesModalButton";

const EnhancedDuplicatesModalButton = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const onShow = () => setShow(true);
    const onHide = () => setShow(false);

    return (
        <DuplicatesModalButton
            show={show}
            onShow={onShow}
            onHide={onHide}
            location={location}
            {...props}
        />
    );
};

export default EnhancedDuplicatesModalButton;
