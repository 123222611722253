import React, { useState, useEffect, useCallback, useRef } from "react";
import ArtistView from "./ArtistView";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import useUser from "shared/hooks/useUser";

const EnhancedArtistView = (props) => {
    const { user } = useUser();
    const { api } = useAPI();
    const { locale, selectedTab } = useParams();
    const location = useLocation();
    const { artistId } = useIntParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        artist: {},
    });

    const onSelectTab = (k) => {
        navigate(`/${locale}/artist/${artistId}/view/${k}`);
    };

    const { ref: headerObserverRef, inView: headerObserverInView } = useInView({
        threshold: 1,
    });

    const containerRef = useRef(null);
    const [containerTopOffset, setContainerTopOffset] = useState();

    useEffect(() => {
        if (containerRef) {
            setContainerTopOffset(
                containerRef.current.getBoundingClientRect().top
            );
        }
    }, [containerRef, headerObserverInView, setContainerTopOffset]);

    const getArtist = useCallback(() => {
        api.get(`artist/${artistId}`, { locale })
            .then((response) => {
                setState({ artist: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(getArtist, [getArtist]);

    const redirectToEdit = () => {
        navigate(`/${locale}/artist/${artistId}/edit`);
    };

    return (
        <ArtistView
            {...props}
            {...state}
            headerObserverRef={headerObserverRef}
            isHeaderSticky={!headerObserverInView}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            location={location}
            containerRef={containerRef}
            containerTopOffset={containerTopOffset}
            redirectToEdit={redirectToEdit}
            user={user}
        />
    );
};

export default EnhancedArtistView;
