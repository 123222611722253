import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import {
    SUBTASK_STATUS_ICON,
    SUBTASK_STATUS_COLOR,
    SUBTASK_STATUSES,
} from "shared/constants/Planning/SubTask";
import I18n from "shared/lib/I18n";

const SubTask = ({ task, subtask, handleStatusChanged, showComment }) => {
    return (
        <span className="dropdown mr-1 mt-1">
            <div className="btn-group">
                <div className="btn-group">
                    <button
                        className={`btn btn-${
                            SUBTASK_STATUS_COLOR[subtask.status]
                        } dropdown-toggle`}
                        data-toggle="dropdown"
                    >
                        <FontAwesomeIcon
                            className="mr-2"
                            icon={SUBTASK_STATUS_ICON[subtask.status]}
                            fixedWidth
                        />
                        {subtask.type}
                    </button>
                    <div className="dropdown-menu">
                        <h6 className="dropdown-header">
                            <I18n t="plannings.production.tasks.subtasks.change_status" />
                        </h6>
                        {SUBTASK_STATUSES.map((status) => {
                            return (
                                <button
                                    key={status}
                                    className={`dropdown-item active-${
                                        SUBTASK_STATUS_COLOR[status]
                                    } ${
                                        subtask.status === status &&
                                        `active disabled`
                                    } ${
                                        [
                                            "TODO",
                                            "TO_BE_CORRECTED",
                                            "VALIDATED_WITHOUT_CONTROL",
                                            "VERIFIED_AND_VALIDATED",
                                            "ERROR",
                                        ].includes(status)
                                            ? "disabled"
                                            : [
                                                  "VALIDATED_WITHOUT_CONTROL",
                                                  "VERIFIED_AND_VALIDATED",
                                                  "ERROR",
                                              ].includes(subtask.status) &&
                                              "disabled"
                                    }`}
                                    onClick={() =>
                                        handleStatusChanged(
                                            task,
                                            subtask,
                                            status
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={SUBTASK_STATUS_ICON[status]}
                                        fixedWidth
                                    />
                                    <I18n
                                        t={`plannings.production.tasks.subtasks.status.${status}`}
                                    />
                                </button>
                            );
                        })}
                    </div>
                </div>
                {subtask.comment && (
                    <button
                        type="button"
                        className={`btn btn-${
                            SUBTASK_STATUS_COLOR[subtask.status]
                        } border-left`}
                        onClick={() => showComment(subtask.comment)}
                    >
                        <FontAwesomeIcon icon={faComment} fixedWidth />
                    </button>
                )}
            </div>
        </span>
    );
};

export default SubTask;
