import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputText from "shared/components/Form/InputText";
import { icons } from "./Social.constants";
import DuplicatesModalButton from "./DuplicatesModalButton";
import clsx from "clsx";

const Social = ({
    getSocial,
    isLoading,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    fields,
    location,
}) => {
    return (
        <Widget title={`artist.management.social.head`} enableCollapse>
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader className="m-2" />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row">
                            {fields.map((item, index) => (
                                <Form.Group key={item.id} className="col-12">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon
                                                    icon={
                                                        icons[item.id_partner]
                                                    }
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <InputText
                                            {...register(`social.${index}.url`)}
                                            defaultValue={item.url}
                                            showError={false}
                                            error={
                                                errors?.social?.[index]
                                                    ?.url && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.social[index].url
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                        <InputGroup.Append>
                                            <DuplicatesModalButton
                                                duplicates={item.duplicates}
                                            />
                                            <a
                                                href={item.url}
                                                className={clsx(
                                                    "btn",
                                                    "btn-outline-primary",
                                                    item.url ?? "disabled"
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faDesktop}
                                                    fixedWidth
                                                />
                                            </a>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            ))}
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? getSocial
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.social.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.social.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Social;
