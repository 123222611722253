import React from "react";
import I18n from "shared/lib/I18n";
import SearchForm from "./SearchForm";
import Artists from "./Artists";
import Albums from "./Albums";
import Recordings from "./Recordings";
import { Nav, Tab } from "react-bootstrap";
import MultiSearch from "shared/components/MultiSearch";
import logo from "assets/static/images/MusicStory_Symbol_RGB.png";
import { Helmet } from "react-helmet";
import "./Search.css";

const Search = ({
    artists,
    albums,
    recordings,
    isLoading,
    hasSearch,
    setState,
    selectedTab,
    onSelectTab,
    location,
}) => {
    return (
        <div className="content-wrap search">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "search.breadcrumb"
                )}`}</title>
            </Helmet>
            <main
                id="content"
                className="content"
                role="main"
                style={!hasSearch ? { height: "100%" } : undefined}
            >
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="search.breadcrumb" />
                    </li>
                </ol>
                {hasSearch ? (
                    <>
                        <SearchForm setState={setState} />
                        <Tab.Container
                            id="search-view-tab"
                            activeKey={selectedTab}
                            defaultActiveKey="all"
                            onSelect={onSelectTab}
                        >
                            <Nav
                                variant="pills"
                                className="search-view-nav mb-4"
                                justify
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="all">
                                        <I18n t="search.all" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="artists">
                                        <I18n t="search.artists" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="albums">
                                        <I18n t="search.albums" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="recordings">
                                        <I18n t="search.recordings" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="search-nav-content nav-content overflow-hidden">
                                <Tab.Pane eventKey="all" className="nav-pane">
                                    <div className="row">
                                        <div className="col">
                                            <Artists
                                                artists={artists}
                                                isLoading={isLoading}
                                                limit={4}
                                            />
                                        </div>
                                        <div className="col">
                                            <Albums
                                                albums={albums}
                                                isLoading={isLoading}
                                                limit={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Recordings
                                                recordings={recordings}
                                                isLoading={isLoading}
                                                limit={7}
                                            />
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="artists"
                                    className="nav-pane"
                                >
                                    <Artists
                                        artists={artists}
                                        isLoading={isLoading}
                                    />
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="albums"
                                    className="nav-pane"
                                >
                                    <Albums
                                        albums={albums}
                                        isLoading={isLoading}
                                    />
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="recordings"
                                    className="nav-pane"
                                >
                                    <Recordings
                                        recordings={recordings}
                                        isLoading={isLoading}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </>
                ) : (
                    <div
                        className="row align-items-center"
                        style={{ height: "calc(100% - 1.5rem - 18px)" }}
                    >
                        <div className="col-6 offset-3 py-5">
                            <MultiSearch larger withExplanation />
                            <div
                                className="d-flex flex-column align-items-center"
                                style={{ marginTop: "13rem" }}
                            >
                                <div>
                                    <img
                                        style={{ width: "250px" }}
                                        src={logo}
                                        alt="logo"
                                    />
                                </div>
                                <div>
                                    <h2 className="mt-3 text-center">
                                        <strong>
                                            <I18n t="logo" />
                                        </strong>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default Search;
