import React, { memo } from "react";

const PanelRenderer = memo(({ component, ...rest }) => component(rest));

export const PanelRoot = memo(({ panels }) => (
    <React.Fragment>
        {Object.keys(panels).map((key) => (
            <PanelRenderer key={key} component={panels[key]} />
        ))}
    </React.Fragment>
));
