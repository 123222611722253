import React, { useState, useEffect } from "react";
import Charts from "./Charts";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    recordingCharts: true,
};

const EnhancedCharts = (props) => {
    const { artistId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [artistId]);

    return <Charts {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedCharts;
