import React from "react";
import I18n from "shared/lib/I18n";
import { NavLink } from "shared/lib/I18n";
import Genre from "./Genre";
import { Helmet } from "react-helmet";

const GenreManagement = ({ location }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "genre.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "genre.manage.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to="/genre/view">
                            <I18n t="genre.breadcrumb" />
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="genre.manage.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <Genre />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default GenreManagement;
