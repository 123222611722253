import React from "react";
import I18n from "shared/lib/I18n";
import AsyncCreatableSelect from "react-select/async-creatable";
import Error from "shared/components/Form/Error";

const InputAsyncCreatableSelect = React.forwardRef(
    (
        {
            error,
            value,
            onChange,
            loadOptions,
            placeholder,
            styles,
            defaultValue,
            isDisabled,
            innerRef,
            enablePortal,
            isClearable,
            showError = true,
            ...props
        },
        ref
    ) => {
        const defaultIsClearable = isClearable ?? true;
        const defaultPlaceholder =
            placeholder ??
            I18n.getTranslation(location, "utils.select.placeholder");
        const defaultStyle = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "#fff" : "#000",
                cursor: state.isDisabled ? "default" : "pointer",
            }),
            control: (provided, state) => ({
                ...provided,
                borderColor:
                    error && error.length !== 0
                        ? "#dc3545"
                        : provided.borderColor,
                borderRadius: ".25em",
                cursor: state.isDisabled ? "default" : "pointer",
            }),
            clearIndicator: (provided) => ({
                ...provided,
                color: error && error.length !== 0 ? "#dc3545" : provided.color,
                padding: "0 4px",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                padding: "0 4px",
            }),
            loadingIndicator: (provided) => ({
                ...provided,
                padding: "0 4px",
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                margin: "5px 0",
            }),
            valueContainer: (provided) => ({
                ...provided,
                padding: "0 11px",
                lineHeight: "1.5",
                fontSize: "1rem",
                height: "calc(1.5em + .75rem)",
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 999,
            }),
            menuPortal: (provided) => ({
                ...provided,
                zIndex: 1999,
            }),
            ...styles,
        };

        return (
            <>
                <AsyncCreatableSelect
                    isClearable={defaultIsClearable}
                    menuPortalTarget={enablePortal && document.body}
                    placeholder={defaultPlaceholder}
                    loadingMessage={() => <I18n t="utils.select.loading" />}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    loadOptions={loadOptions}
                    isDisabled={isDisabled}
                    formatCreateLabel={(inputValue) => (
                        <span className="h4 font-weight-bold">
                            <I18n t="utils.select.create" /> "{inputValue}"
                        </span>
                    )}
                    noOptionsMessage={() => <I18n t="utils.select.empty" />}
                    theme={(provided) => {
                        return {
                            ...provided,
                            spacing: {
                                baseUnit: 3,
                                controlHeight: 29,
                                menuGutter: 10,
                            },
                        };
                    }}
                    styles={defaultStyle}
                    innerRef={innerRef}
                    ref={ref}
                    {...props}
                />

                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputAsyncCreatableSelect;
