import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import PictureMoveModal from "shared/components/PictureMoveModal";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";

const MoveButton = ({
    pictureId,
    artistId,
    showMove,
    toggleMove,
    onMoveSuccess,
    location,
}) => {
    return (
        <>
            <Tippy
                content={I18n.getTranslation(
                    location,
                    "common.picture.action.move"
                )}
                trigger="mouseenter"
            >
                <button
                    className={clsx("btn bg-transparent border-0")}
                    onClick={toggleMove}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} fixedWidth />
                </button>
            </Tippy>

            <PictureMoveModal
                id_picture={pictureId}
                id_artist={artistId}
                show={showMove}
                handleHide={toggleMove}
                onSuccess={onMoveSuccess}
            />
        </>
    );
};

export default MoveButton;
