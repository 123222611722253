import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import LabelInformationModal from "shared/components/LabelInformationModal";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";

const EditButton = ({
    pictureId,
    showInformation,
    toggleInformation,
    onInformationSuccess,
    location,
}) => {
    return (
        <>
            <Tippy
                content={I18n.getTranslation(
                    location,
                    "common.picture.action.edit"
                )}
                trigger="mouseenter"
            >
                <button
                    className={clsx("btn bg-transparent border-0")}
                    onClick={toggleInformation}
                >
                    <FontAwesomeIcon icon={faListAlt} fixedWidth />
                </button>
            </Tippy>

            <LabelInformationModal
                id_picture={pictureId}
                show={showInformation}
                handleHide={toggleInformation}
                onSuccess={onInformationSuccess}
            />
        </>
    );
};

export default EditButton;
