import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import InputSwitch from "shared/components/Form/InputSwitch";
import InputPassword from "shared/components/Form/InputPassword";
import { Form, Button } from "react-bootstrap";
import Layout from "layout/Auth/Layout";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import Error from "shared/components/Form/Error";
import ReCaptcha from "shared/components/ReCaptcha";
import InputAsyncCreatableSelect from "shared/components/Form/InputAsyncCreatableSelect";
import SidePanel from "layout/Auth/Layout/SidePanel";

const Register = ({
    handleRegister,
    globalError,
    errors,
    register,
    isSubmitting,
    isDirty,
    control,
    location,
    accountTypeOptions,
    accountRoleOptions,
    accountLanguageOptions,
    isClientWatch,
    accountTypeWatch,
    loadLabelOptions,
    onVerifiedCaptcha,
}) => {
    return (
        <Layout>
            <div
                className="row justify-content-center"
                style={{ minHeight: "610px" }}
            >
                <div className="col-lg-6">
                    <SidePanel />
                </div>
                <div className="col-lg-6">
                    <div className="p-lg-5 p-4">
                        <Form onSubmit={!isSubmitting ? handleRegister : null}>
                            <div>
                                <h3 className="text-secondary">
                                    <I18n t={`security.register.title`} />
                                </h3>
                            </div>

                            <hr />

                            <div className="text-muted text-justify mb-3">
                                <I18n t={`security.register.subtitle.trial`} />
                            </div>

                            <Form.Group>
                                <InputSwitch
                                    {...register("registration_is_client")}
                                    label={I18n.getTranslation(
                                        location,
                                        "security.register.is_client"
                                    )}
                                />
                            </Form.Group>

                            {isClientWatch && (
                                <div className="text-muted text-justify">
                                    <I18n
                                        t={`security.register.subtitle.client`}
                                    />
                                </div>
                            )}

                            <hr />

                            <div className="mt-4">
                                {globalError && (
                                    <Error
                                        error={[
                                            I18n.getTranslation(
                                                location,
                                                globalError
                                            ),
                                        ]}
                                    />
                                )}
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t="security.register.account_type.title" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="registration_type"
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <InputSelect
                                                innerRef={ref}
                                                value={
                                                    value !== null
                                                        ? {
                                                              value,
                                                              label: accountTypeOptions.find(
                                                                  (a) =>
                                                                      value ===
                                                                      a.value
                                                              ).label,
                                                          }
                                                        : null
                                                }
                                                className="text-left"
                                                options={accountTypeOptions}
                                                onChange={(opt) => {
                                                    onChange(
                                                        opt ? opt.value : null
                                                    );
                                                }}
                                                isClearable={false}
                                                error={
                                                    errors.registration_type && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors
                                                                .registration_type
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                showError={false}
                                                enablePortal
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <div className="row">
                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label>
                                                <I18n
                                                    t={`security.register.firstname`}
                                                />
                                            </Form.Label>
                                            <InputText
                                                {...register("firstname")}
                                                className={clsx("form-control")}
                                                error={
                                                    errors.firstname && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.firstname
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                showError={false}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label>
                                                <I18n
                                                    t={`security.register.lastname`}
                                                />
                                            </Form.Label>
                                            <InputText
                                                {...register("lastname")}
                                                className={clsx("form-control")}
                                                error={
                                                    errors.lastname && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.lastname
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                showError={false}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t={`security.register.email`} />
                                    </Form.Label>
                                    <InputText
                                        {...register("email")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.email && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.email.message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <I18n
                                            t={`security.register.password`}
                                        />
                                    </Form.Label>
                                    <InputPassword
                                        {...register("password")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.password && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.password.message
                                                ),
                                            ]
                                        }
                                        showEye
                                    />
                                </Form.Group>
                                <div className="row">
                                    {accountTypeWatch === "artist_manager" && (
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Label>
                                                    <I18n
                                                        t={`security.register.artist`}
                                                    />
                                                </Form.Label>
                                                <InputText
                                                    {...register(
                                                        "registration_artist"
                                                    )}
                                                    className={clsx(
                                                        "form-control"
                                                    )}
                                                    error={
                                                        errors.registration_artist && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .registration_artist
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    showError={false}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {accountTypeWatch ===
                                        "label_distributor" && (
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Label>
                                                    <I18n
                                                        t={`security.register.label`}
                                                    />
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="registration_label"
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputAsyncCreatableSelect
                                                            innerRef={ref}
                                                            className={clsx(
                                                                "text-left"
                                                            )}
                                                            value={
                                                                value
                                                                    ? {
                                                                          value: value,
                                                                          label: value,
                                                                      }
                                                                    : undefined
                                                            }
                                                            loadOptions={
                                                                loadLabelOptions
                                                            }
                                                            onChange={(
                                                                opt,
                                                                action
                                                            ) => {
                                                                if (
                                                                    action.action ===
                                                                    "select-option"
                                                                ) {
                                                                    onChange(
                                                                        opt.label
                                                                    );
                                                                } else if (
                                                                    action.action ===
                                                                    "create-option"
                                                                ) {
                                                                    onChange(
                                                                        opt.label
                                                                    );
                                                                } else {
                                                                    onChange();
                                                                }
                                                            }}
                                                            allowCreateWhileLoading={
                                                                false
                                                            }
                                                            createOptionPosition="first"
                                                            isClearable={false}
                                                            error={
                                                                errors.registration_label && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .registration_label
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                            showError={false}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {[
                                        "music_service",
                                        "cmo",
                                        "label_distributor",
                                    ].includes(accountTypeWatch) && (
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Label>
                                                    <I18n
                                                        t={`security.register.company`}
                                                    />
                                                </Form.Label>
                                                <InputText
                                                    {...register(
                                                        "registration_company"
                                                    )}
                                                    className={clsx(
                                                        "form-control"
                                                    )}
                                                    error={
                                                        errors.registration_company && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .registration_company
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    showError={false}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                </div>
                                {[
                                    "music_service",
                                    "cmo",
                                    "label_distributor",
                                ].includes(accountTypeWatch) && (
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Label>
                                                    <I18n t="security.register.role.title" />
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="registration_role"
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputSelect
                                                            innerRef={ref}
                                                            value={
                                                                value !== null
                                                                    ? {
                                                                          value,
                                                                          label: accountRoleOptions[
                                                                              accountTypeWatch
                                                                          ]?.find(
                                                                              (
                                                                                  a
                                                                              ) =>
                                                                                  value ===
                                                                                  a.value
                                                                          )
                                                                              ?.label,
                                                                      }
                                                                    : null
                                                            }
                                                            className="text-left"
                                                            options={
                                                                accountRoleOptions[
                                                                    accountTypeWatch
                                                                ]
                                                            }
                                                            onChange={(opt) => {
                                                                onChange(
                                                                    opt
                                                                        ? opt.value
                                                                        : null
                                                                );
                                                            }}
                                                            isClearable={false}
                                                            error={
                                                                errors.registration_role && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .registration_role
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                            showError={false}
                                                            enablePortal
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Label>
                                                    <I18n
                                                        t={`security.register.describe`}
                                                    />
                                                </Form.Label>
                                                <InputText
                                                    {...register(
                                                        "registration_describe"
                                                    )}
                                                    className={clsx(
                                                        "form-control"
                                                    )}
                                                    error={
                                                        errors.registration_describe && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .registration_describe
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    showError={false}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t="security.register.language" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="registration_language"
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <InputSelect
                                                innerRef={ref}
                                                value={
                                                    value !== null
                                                        ? {
                                                              value,
                                                              label: accountLanguageOptions.find(
                                                                  (a) =>
                                                                      value ===
                                                                      a.value
                                                              ).label,
                                                          }
                                                        : null
                                                }
                                                className="text-left"
                                                options={accountLanguageOptions}
                                                onChange={(opt) => {
                                                    onChange(
                                                        opt ? opt.value : null
                                                    );
                                                }}
                                                isClearable={false}
                                                error={
                                                    errors.registration_language && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors
                                                                .registration_language
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                showError={false}
                                                enablePortal
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <ReCaptcha
                                    onVerifiedCaptcha={onVerifiedCaptcha}
                                    action="register"
                                />

                                <div className="mt-4">
                                    <Button
                                        variant="inverse"
                                        disabled={isSubmitting || !isDirty}
                                        type="submit"
                                        size="lg"
                                        block
                                    >
                                        <I18n t={`security.register.button`} />
                                    </Button>
                                </div>
                                <div className="mt-2">
                                    <p className="mb-0 fs-12 text-muted fst-italic">
                                        <I18n
                                            t={`security.register.agreeBefore`}
                                        />
                                        <NavLink
                                            to={`/terms`}
                                            className="text-primary text-decoration-underline mx-2"
                                        >
                                            <I18n
                                                t={`security.register.termsOfService`}
                                            />
                                        </NavLink>
                                        <I18n
                                            t={`security.register.agreeAnd`}
                                        />
                                        <NavLink
                                            to={`/privacy`}
                                            className="text-primary text-decoration-underline ml-2"
                                        >
                                            <I18n
                                                t={`security.register.privacyPolicy`}
                                            />
                                        </NavLink>
                                    </p>
                                </div>
                            </div>

                            <hr className="my-4" />

                            <div className="text-center">
                                <p className="mb-0">
                                    <I18n
                                        t={`security.register.allreadyAccount`}
                                    />
                                    <NavLink
                                        to={`/login`}
                                        className="text-primary text-decoration-underline ml-2"
                                    >
                                        <I18n t={`security.login.title`} />
                                    </NavLink>
                                </p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Register;
