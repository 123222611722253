import React from "react";
import Artists from "./Artists";
import Albums from "./Albums";
import Recordings from "./Recordings";
import Loader from "shared/components/Loader";
import clsx from "clsx";
import I18n from "shared/lib/I18n";
import styles from "shared/components/MultiSearch/MultiSearch.module.css";

const Data = ({
    artists,
    albums,
    recordings,
    isLoading,
    isOpen,
    isTouched,
    dataRef,
    q,
    ...props
}) => {
    return (
        <div className={clsx(styles.data, !isOpen && "d-none")} ref={dataRef}>
            {isLoading ? (
                <div className="py-3">
                    <Loader />
                </div>
            ) : q !== "" && isTouched ? (
                <>
                    <Artists {...props} q={q} artists={artists} />
                    <Albums {...props} q={q} albums={albums} />
                    <Recordings {...props} q={q} recordings={recordings} />
                </>
            ) : (
                <div className="py-3 text-center">
                    <h4 className="mb-0">
                        <I18n t="common.multiSearch.search" />
                    </h4>
                </div>
            )}
        </div>
    );
};

export default Data;
