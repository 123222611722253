import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import MetabaseView from "shared/components/MetabaseView";
import { Helmet } from "react-helmet";
import "./GettyFollowup.css";

const GettyFollowup = ({ location }) => {
    return (
        <div className="content-wrap getty-followup">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.pictures.getty.followup"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="base.nav.pictures.getty.followup" />
                    </li>
                </ol>
                <Widget className="dashboard mb-0">
                    <Widget.Body className="mt-0 h-100">
                        <MetabaseView
                            id="34"
                            frameBorder="0"
                            width="100%"
                            height="100%"
                        />
                    </Widget.Body>
                </Widget>
            </main>
        </div>
    );
};

export default GettyFollowup;
