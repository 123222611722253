import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUndoAlt,
    faBackward,
    faPause,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import "./Action.css";

const Action = ({ isPlaying, play, pause, previous, back10 }) => {
    return (
        <div className="apple__player__action">
            <OverlayTrigger
                key="apple-player-action-previous"
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-apple-player-action-previous`}>
                        <I18n t="apple.sample.assessment.player.action.previous" />
                    </Tooltip>
                }
            >
                <span onClick={previous}>
                    <FontAwesomeIcon icon={faBackward} />
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                key="apple-player-action-back10"
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-apple-player-action-back10`}>
                        <I18n t="apple.sample.assessment.player.action.back10" />
                    </Tooltip>
                }
            >
                <span onClick={back10}>
                    <FontAwesomeIcon icon={faUndoAlt} />
                </span>
            </OverlayTrigger>
            {isPlaying ? (
                <OverlayTrigger
                    key="apple-player-action-pause"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-apple-player-action-pause`}>
                            <I18n t="apple.sample.assessment.player.action.pause" />
                        </Tooltip>
                    }
                >
                    <span onClick={pause}>
                        <FontAwesomeIcon icon={faPause} />
                    </span>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    key="apple-player-action-play"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-apple-player-action-play`}>
                            <I18n t="apple.sample.assessment.player.action.play" />
                        </Tooltip>
                    }
                >
                    <span onClick={play}>
                        <FontAwesomeIcon icon={faPlay} />
                    </span>
                </OverlayTrigger>
            )}
        </div>
    );
};

export default Action;
