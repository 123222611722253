import React from "react";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import { ButtonGroup, Button, Form, InputGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Controller } from "react-hook-form";

const SearchForm = ({
    showAdvancedForm,
    toggleShowAdvancedForm,
    errors,
    register,
    control,
    isSubmitting,
    handleSubmit,
    onSubmit,
    location,
    searchTypeOptions,
}) => {
    return (
        <>
            <div className="py-4">
                <div className="d-flex">
                    <ButtonGroup className="flex-grow-1">
                        <InputText
                            {...register("search")}
                            error={
                                errors.search && [
                                    I18n.getTranslation(
                                        location,
                                        errors.search.message
                                    ),
                                ]
                            }
                            showError={false}
                        />
                        <Button
                            variant="outline-primary"
                            onClick={toggleShowAdvancedForm}
                        >
                            <FontAwesomeIcon
                                icon={
                                    showAdvancedForm ? faCaretUp : faCaretDown
                                }
                                fixedWidth
                            />
                        </Button>
                        <Button
                            variant="primary"
                            disabled={isSubmitting}
                            onClick={
                                !isSubmitting ? handleSubmit(onSubmit) : null
                            }
                        >
                            <I18n t="utils.button.search" />
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className={clsx(!showAdvancedForm && "hide")}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>
                            <I18n t="common.picture.getty.bas.filters.search_type.title" />
                        </Form.Label>
                        <InputGroup>
                            <Controller
                                control={control}
                                name="search_type"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <InputSelect
                                        className="w-25"
                                        innerRef={ref}
                                        value={
                                            value !== null
                                                ? {
                                                      value,
                                                      label: searchTypeOptions.find(
                                                          (row) =>
                                                              String(
                                                                  row.value
                                                              ) ===
                                                              String(value)
                                                      )?.label,
                                                  }
                                                : null
                                        }
                                        options={searchTypeOptions}
                                        onChange={(opt) => {
                                            onChange(opt ? opt.value : null);
                                        }}
                                        isClearable={false}
                                        enablePortal
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
            </div>
        </>
    );
};

export default SearchForm;
