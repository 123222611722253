import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import PartnerSelect from "pages/ArtistManagement/Matching/PartnerSelect";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const MatchV1 = ({
    title,
    partner,
    isLoading,
    matchings,
    columns,
    onMatch,
    filterOption,
}) => {
    return (
        <Widget title={title} enableCollapse>
            <Widget.Body className="widget-middle-overflow p-2 border-top">
                <PartnerSelect
                    partner={partner}
                    onSelect={onMatch}
                    isDisabled={isLoading}
                    filterOption={filterOption}
                />
            </Widget.Body>
            <Widget.Body
                className="widget-table-overflow border-top"
                style={{ overflowY: "auto", height: "258px" }}
            >
                {isLoading ? (
                    <Loader className="mt-4" />
                ) : (
                    <BootstrapTable
                        keyField="id"
                        data={matchings}
                        columns={columns}
                        classes="table-header-fixed mb-0"
                        bootstrap4
                        hover
                        bordered={false}
                        noDataIndication={
                            <I18n t="artist.management.match.empty" />
                        }
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default MatchV1;
