import { useEffect } from "react";
import useUser from "shared/hooks/useUser";
import { appendScript } from "shared/functions/ScriptManagement";

const Chatwoot = () => {
    const { user } = useUser();

    useEffect(() => {
        if (user.chatwoot_token === null || window.$chatwoot) {
            return;
        }

        window.chatwootSettings = {
            position: "left",
            locale: "en",
            type: "expanded_bubble",
            launcherTitle: "Need help?",
            showPopoutButton: true,
        };

        window.addEventListener("chatwoot:ready", function () {
            window.$chatwoot.setUser(user.id, {
                email: user.email,
                name: user.name,
                identifier_hash: user.chatwoot_hash,
            });
        });

        appendScript("https://chatwoot.music-story.com/packs/js/sdk.js").then(
            () => {
                window.chatwootSDK.run({
                    websiteToken: user.chatwoot_token,
                    baseUrl: "https://chatwoot.music-story.com",
                });
            }
        );
    }, [
        user.id,
        user.email,
        user.name,
        user.chatwoot_token,
        user.chatwoot_hash,
    ]);
};

export default Chatwoot;
