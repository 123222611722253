import React, { useCallback, useEffect, useState } from "react";
import CDJournal from "./CDJournal";
import { useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedCDJournal = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [biography, setBiography] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { artistId } = useIntParams();

    const getBiography = useCallback(() => {
        let cancelled = false;

        api.get(`artist/${artistId}/biography/cd_journal`)
            .then((response) => {
                if (!cancelled) {
                    setBiography(response?.biography ?? null);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getBiography, [getBiography]);

    return (
        <CDJournal
            {...props}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            value={biography}
            location={location}
        />
    );
};

export default EnhancedCDJournal;
