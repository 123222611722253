import React from "react";

export const AlbumViewContext = React.createContext({
    dispatchAlbum: () => {},
    profileSelection: {
        releaseId: null,
        isrc: null,
    },
    profileHighlights: {
        releases: [],
        isrcs: [],
    },
});
