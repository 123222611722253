import React from "react";
import I18n from "shared/lib/I18n";
import Months from "./Months";
import Loader from "shared/components/Loader";
import { Helmet } from "react-helmet";

const PlanningsBilling = ({
    isLoading,
    billings,
    selected,
    setSelected,
    location,
}) => {
    let billingContent = (
        <div className="row">
            <div className="col-12">
                <div className="widget">
                    <div className="widget-body ">
                        <h2 className="text-center">
                            <I18n t="plannings.billing.empty" />
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );

    if (billings.length > 0) {
        billingContent = (
            <div className="row">
                <div className="col-3">
                    <div className="nav flex-column nav-pills">
                        {billings.map(({ id, name }) => (
                            <a
                                key={id}
                                className={`nav-link ${
                                    selected === id ? "active" : undefined
                                }`}
                                href={`#v-pills-${id}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelected(id);
                                }}
                            >
                                {name}
                            </a>
                        ))}
                    </div>
                </div>
                <div className="col-9">
                    <section className="widget">
                        <Months userId={selected} />
                    </section>
                </div>
            </div>
        );
    }

    if (isLoading) {
        billingContent = <Loader />;
    }

    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.billing.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="plannings.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="plannings.billing.breadcrumb" />
                    </li>
                </ol>
                {billingContent}
            </main>
        </div>
    );
};

export default PlanningsBilling;
