import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Tabs, Tab } from "react-bootstrap";
import Data from "./Data";
import Tippy from "@tippyjs/react";
import {
    MAIN_CHARTS,
    ALL_CHARTS,
    EDITORS_PICK,
    HIT,
} from "./RecordingCharts.constants";
import "./RecordingCharts.css";

const RecordingCharts = ({
    mainCharts,
    allCharts,
    hit,
    editorsPick,
    isLoading,
    location,
    selectedTab,
    onSelectTab,
}) => {
    return (
        <Widget
            className="artist-view-recording-charts py-0"
            isLoading={isLoading}
        >
            <Widget.Body className="widget-table-overflow mb-0">
                <Tabs
                    id="artist-view-recording-charts-tabs"
                    activeKey={selectedTab}
                    onSelect={onSelectTab}
                    mountOnEnter
                >
                    <Tab
                        eventKey={MAIN_CHARTS}
                        title={
                            <I18n t="artist.view.chartsRecordingCharts.mainCharts" />
                        }
                    >
                        <Data type={MAIN_CHARTS} recordings={mainCharts} />
                    </Tab>
                    <Tab
                        eventKey={ALL_CHARTS}
                        title={
                            <I18n t="artist.view.chartsRecordingCharts.allCharts" />
                        }
                    >
                        <Data type={ALL_CHARTS} recordings={allCharts} />
                    </Tab>
                    <Tab
                        eventKey={HIT}
                        title={
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "artist.view.chartsRecordingCharts.hit.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span>
                                    <I18n t="artist.view.chartsRecordingCharts.hit.title" />
                                </span>
                            </Tippy>
                        }
                    >
                        <Data type={HIT} recordings={hit} />
                    </Tab>
                    <Tab
                        eventKey={EDITORS_PICK}
                        title={
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "artist.view.chartsRecordingCharts.editorial_pick.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span>
                                    <I18n t="artist.view.chartsRecordingCharts.editorial_pick.title" />
                                </span>
                            </Tippy>
                        }
                    >
                        <Data type={EDITORS_PICK} recordings={editorsPick} />
                    </Tab>
                </Tabs>
            </Widget.Body>
        </Widget>
    );
};

export default RecordingCharts;
