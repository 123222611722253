import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import YearList from "./YearList";

const EnhancedYearList = ({ repository, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const { locale } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        isLoading: true,
        years: [],
    });

    const fetchYears = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            years: [],
        });
        api.get(`cnm/repository/${repository}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        years: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        years: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [repository]);

    useEffect(fetchYears, [fetchYears]);

    const onUploadSuccess = (year) => {
        fetchYears();
        navigate(`/${locale}/cnm/repositories/${repository}/${year}`);
    };

    return (
        <YearList
            editable={user.hasRight("cnm.repository.csa_artists.manage")}
            repository={repository}
            onUploadSuccess={onUploadSuccess}
            {...state}
            {...props}
        />
    );
};

export default EnhancedYearList;
