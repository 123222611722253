import React from "react";

import { useParams } from "react-router-dom";

const newToPath = (to, locale) =>
    to[0] === "/" ? `/${locale}${to}` : `${locale}/${to}`;

const newToObject = (to, locale) => {
    const { pathname } = to;

    if (typeof pathname !== "string") {
        return to;
    }

    const toObj = { ...to, pathname: newToPath(pathname, locale) };

    return toObj;
};

const newToFunction = (to, locale) => (location) => {
    const result = to(location);

    return typeof result === "string"
        ? newToPath(result, locale)
        : newToObject(result, locale);
};

const newTo = (to) => {
    let { locale } = useParams();

    if (!locale?.match(/^en|fr|ja|de|ko$/g)) {
        locale = "en";
        to = "/";
    }

    const type = typeof to;

    if (type === "string") {
        return newToPath(to, locale);
    }
    if (type === "function") {
        return newToFunction(to, locale);
    }

    return newToObject(to, locale);
};

const withLocale = (WrappedComponent) => {
    const NewComponent = ({ to, ignoreLocale = false, ...rest }) => {
        if (ignoreLocale) {
            return <WrappedComponent to={to} {...rest} />;
        }

        return <WrappedComponent to={newTo(to)} {...rest} />;
    };

    return NewComponent;
};

export default withLocale;
