import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Filter from "./Filter";
import { getFilterOptions, getFilteredTasks } from "./Filter.selector";

const EnhancedFilter = (props) => {
    const { tasks, setFilteredTasks } = props;
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({
        artist: "",
        status: null,
        category: null,
        assignement: null,
        classification: null,
        tasks: null,
    });
    const location = useLocation();
    const filterOptions = getFilterOptions({ tasks, location });

    useEffect(() => {
        setFilteredTasks(getFilteredTasks({ tasks, selectedFilterOptions }));
    }, [tasks, setFilteredTasks, selectedFilterOptions]);

    return (
        <Filter
            {...props}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            filterOptions={filterOptions}
        />
    );
};

export default EnhancedFilter;
