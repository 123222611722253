import React from "react";
import Row from "./Row";
import { useParams } from "react-router";

const EnhancedRow = (props) => {
    const { planningId } = useParams();
    return <Row {...props} selected={parseInt(planningId, 10)} />;
};

export default EnhancedRow;
