import React from "react";
import UserManagement from "./UserManagement";
import { useLocation } from "react-router";

const EnhancedUserManagement = (props) => {
    const location = useLocation();
    return <UserManagement {...props} location={location} />;
};

export default EnhancedUserManagement;
