import React, { useState, useEffect } from "react";
import Subtasks from "./Subtasks";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedSubtasks = ({ userId, month, ...props }) => {
    const [subtasks, setMonths] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { api } = useAPI();

    useEffect(() => {
        if (month) {
            setIsLoading(true);
            api.get(`plannings/billing/${userId}/${month}`)
                .then((response) => {
                    setMonths(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        } else {
            setIsLoading(false);
        }
    }, [userId, month]);

    return <Subtasks subtasks={subtasks} isLoading={isLoading} {...props} />;
};

export default EnhancedSubtasks;
