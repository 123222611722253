import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";
import InputText from "shared/components/Form/InputText";
import { useLocation } from "react-router";
import Filters from "./Filters";

const Matching = ({
    data,
    columns,
    isLoading,
    pagination,
    filters,
    setFilters,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <ToolkitProvider keyField="id" data={data} columns={columns} search>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title={`cnm.charts.platform.matching.head`}
                            actions={
                                !isLoading && (
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                )
                            }
                        >
                            <Widget.Body>
                                <div className="row mb-3">
                                    <div className="col">
                                        {paginationProps.page !== 1 ? (
                                            <InputText
                                                placeholder={I18n.getTranslation(
                                                    location,
                                                    "cnm.charts.platform.matching.search"
                                                )}
                                                disabled={true}
                                            />
                                        ) : (
                                            <SearchBar
                                                {...toolkitprops.searchProps}
                                                placeholder={I18n.getTranslation(
                                                    location,
                                                    "cnm.charts.platform.matching.search"
                                                )}
                                            />
                                        )}
                                    </div>
                                    <Filters
                                        filters={filters}
                                        setFilters={setFilters}
                                        disabled={paginationProps.page !== 1}
                                    />
                                </div>
                            </Widget.Body>
                            <Widget.Body className="widget-table-overflow mb-3">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        noDataIndication={
                                            <I18n t="cnm.charts.platform.matching.empty" />
                                        }
                                        classes="table-bordered-header-fixed"
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={data.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default Matching;
