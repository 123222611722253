import React, { useEffect } from "react";
import RatingCard from "./RatingCard";

const EnhancedRatingCard = ({ activeLines, ...props }) => {
    useEffect(() => {
        if (activeLines.length > 0) {
            const line = document.getElementsByClassName(
                "apple__sample__assessment__ratings__active"
            );
            line[0]?.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
    }, [activeLines]);

    return <RatingCard activeLines={activeLines} {...props} />;
};

export default EnhancedRatingCard;
