import React from "react";
import clsx from "clsx";

const InputRange = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <>
            <input
                type="range"
                className={clsx("form-control-range", className)}
                ref={ref}
                {...props}
            />
        </>
    );
});

export default InputRange;
