import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Button } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import InputSelect from "shared/components/Form/InputSelect";
import InputDate from "shared/components/Form/InputDate";
import moment from "moment";
import "./Albums.css";

const Albums = ({
    albums,
    isLoading,
    columns,
    rowStyle,
    sortOption,
    location,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
    reset,
}) => {
    const { SearchBar } = Search;
    return (
        <Widget
            title="artist.view.discoAlbums.head"
            className="artist-view-albums pb-0"
            isLoading={isLoading}
        >
            <Widget.Body className="widget-table-overflow mb-0 border-top">
                <ToolkitProvider
                    keyField="id"
                    data={albums}
                    columns={columns}
                    search
                >
                    {(toolkitprops) => (
                        <>
                            <div className="row mx-0 my-3">
                                <div className="col-3">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "artist.view.discoAlbums.title"
                                        )}
                                    />
                                </div>
                                <div className="col-3">
                                    <InputSelect
                                        value={selectedFilterOptions.type}
                                        onChange={(opt) => {
                                            setSelectedFilterOptions(
                                                (curr) => ({
                                                    ...curr,
                                                    type: opt ?? null,
                                                })
                                            );
                                        }}
                                        placeholder={
                                            <I18n t="artist.view.discoAlbums.type.head" />
                                        }
                                        options={filterOptions.types}
                                        enablePortal
                                    />
                                </div>
                                <div className="col-2">
                                    <InputSelect
                                        value={selectedFilterOptions.format}
                                        onChange={(opt) => {
                                            setSelectedFilterOptions(
                                                (curr) => ({
                                                    ...curr,
                                                    format: opt ?? null,
                                                })
                                            );
                                        }}
                                        placeholder={
                                            <I18n t="artist.view.discoAlbums.format.head" />
                                        }
                                        options={filterOptions.formats}
                                        enablePortal
                                    />
                                </div>
                                <div className="col-2">
                                    <div className="d-flex">
                                        <InputDate
                                            placeholder="artist.view.discoAlbums.year"
                                            selected={
                                                selectedFilterOptions.year
                                                    ? new Date(
                                                          selectedFilterOptions.year,
                                                          0
                                                      )
                                                    : null
                                            }
                                            onChange={(date) => {
                                                setSelectedFilterOptions(
                                                    (curr) => ({
                                                        ...curr,
                                                        year: date
                                                            ? moment(
                                                                  date
                                                              ).format("YYYY")
                                                            : null,
                                                    })
                                                );
                                            }}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            isClearable
                                        />
                                        <InputSelect
                                            className="ml-2 w-50"
                                            value={{
                                                label: selectedFilterOptions.mode,
                                                value: selectedFilterOptions.mode,
                                            }}
                                            onChange={(opt) => {
                                                setSelectedFilterOptions(
                                                    (curr) => ({
                                                        ...curr,
                                                        mode: opt
                                                            ? opt.value
                                                            : null,
                                                    })
                                                );
                                            }}
                                            options={[
                                                { label: "<", value: "<" },
                                                { label: "=", value: "=" },
                                                { label: ">", value: ">" },
                                            ]}
                                            isClearable={false}
                                            enablePortal
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <Button
                                        variant="secondary"
                                        className="w-100"
                                        onClick={async () => {
                                            await toolkitprops.searchProps.onClear();
                                            reset();
                                        }}
                                    >
                                        <I18n t="artist.view.discoAlbums.reset" />
                                    </Button>
                                </div>
                            </div>

                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                condensed
                                hover
                                bordered={false}
                                noDataIndication={
                                    <I18n t="artist.view.discoAlbums.empty" />
                                }
                                classes="table-layout-auto mb-0"
                                sort={sortOption}
                                rowStyle={rowStyle}
                            />
                        </>
                    )}
                </ToolkitProvider>
            </Widget.Body>
        </Widget>
    );
};

export default Albums;
