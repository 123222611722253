import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faCopy } from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import { addToClipboard } from "shared/functions/AddToClipboard";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { getFilteredData } from "./LineMusicTrack.selector";
import LineMusicTrack from "./LineMusicTrack";
import RecordingMatcher from "./RecordingMatcher";
import ArtistMatcher from "./ArtistMatcher";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedLineMusicTrack = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
    });

    const fetchLineMusicTrack = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/track/${partnerId}/list/${listId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchLineMusicTrack();
    }, [fetchLineMusicTrack]);

    const onMatchArtist = (artistId, data) => {
        setState((prev) => {
            console.log(
                artistId,
                prev.data[0].artist.id,
                artistId === prev.data[0].artist.id,
                data
            );
            return {
                ...prev,
                data: prev.data.map((row) =>
                    row.artist.id === artistId ? { ...row, ...data } : row
                ),
            };
        });
    };
    const onMatchTrack = (trackId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.track.id === trackId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(location, "matching.track.track.rank"),
            sort: true,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "matching.track.track.linemusic_artist"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell !== null ? (
                    <>
                        <a
                            href={`https://music.line.me/webapp/artist/${cell.id}`}
                        >
                            {cell.name}
                        </a>
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => addToClipboard(cell.name, locale)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                    </>
                ) : (
                    "-"
                );
            },
            formatExtraData: {
                locale,
            },
            filterValue: (cell) => [cell.id, cell.name],
        },
        {
            dataField: "track",
            text: I18n.getTranslation(location, "matching.track.track.title"),
            formatter: (cell) => {
                return cell !== null ? (
                    <>
                        <a
                            href={`https://music.line.me/webapp/track/${cell.id}`}
                        >
                            {cell.title}
                        </a>
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => addToClipboard(cell.title, locale)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell) => [cell.id, cell.title],
        },
        {
            dataField: "msArtistBest",
            text: I18n.getTranslation(location, "matching.track.track.artist"),
            formatter: (cell, { artist }, rowIndex, { locale }) => {
                return (
                    <ArtistMatcher
                        artist={artist}
                        msArtist={cell}
                        onChange={onMatchArtist}
                        locale={locale}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "msRecordings",
            text: I18n.getTranslation(
                location,
                "matching.track.track.recording"
            ),
            formatter: (cell, { track, msArtists }, rowIndex, { locale }) => {
                return (
                    <RecordingMatcher
                        trackId={track.id}
                        msArtists={msArtists ?? []}
                        msRecordings={cell ?? []}
                        locale={locale}
                        onChange={onMatchTrack}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "rank",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ msArtistBest, msRecordings }) => {
        if (msArtistBest !== null && msRecordings?.length > 0) {
            return "table-success";
        } else {
            return null;
        }
    };

    return (
        <LineMusicTrack
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedLineMusicTrack;
