import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const Filter = ({
    selectedFilterOptions,
    setSelectedFilterOptions,
    filterOptions,
}) => {
    return (
        <div className="widget-body">
            <div className="row mb-2">
                <div className="col-md-2">
                    <InputSelect
                        onChange={(opt) => {
                            setSelectedFilterOptions({
                                ...selectedFilterOptions,
                                status: opt ? opt.value : null,
                            });
                        }}
                        placeholder={
                            <I18n t="plannings.picture.tasks.status" />
                        }
                        options={filterOptions.status}
                    />
                </div>
            </div>
        </div>
    );
};

export default Filter;
