import React, { useState, useEffect, useCallback } from "react";
import Right from "./Right";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import Delete from "./Button/Delete";
import useAPI from "shared/hooks/useApi";

const defaultState = {
    right: [],
    isLoading: true,
};

const EnhancedRight = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState(defaultState);
    const location = useLocation();

    const getRight = useCallback(() => {
        return api
            .get("users/rights")
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    right: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultState);
            });
    }, []);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getRight()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getRight]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "user.right.manage.right.id"),
            sort: true,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "reference",
            text: I18n.getTranslation(
                location,
                "user.right.manage.right.reference"
            ),
            sort: true,
            headerStyle: {
                width: "50rem",
            },
        },
        {
            dataField: "libelle",
            text: I18n.getTranslation(
                location,
                "user.right.manage.right.libelle"
            ),
            sort: true,
            headerStyle: {
                width: "50rem",
            },
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            text: I18n.getTranslation(
                location,
                "user.right.manage.right.action"
            ),
            formatter: (cell, row) => {
                return (
                    <ButtonGroup>
                        <Update right={row} onSuccess={getRight} />
                        <Delete id={row.id} onSuccess={getRight} />
                    </ButtonGroup>
                );
            },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    const defaultSorted = [
        {
            dataField: "id",
            order: "desc",
        },
    ];

    return (
        <Right
            {...props}
            {...state}
            columns={columns}
            options={options}
            getRight={getRight}
            defaultSorted={defaultSorted}
            location={location}
        />
    );
};

export default EnhancedRight;
