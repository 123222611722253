import React from "react";
import I18n from "shared/lib/I18n";
import SearchForm from "./SearchForm";
import ResultsList from "./ResultsList";
import { Helmet } from "react-helmet";

const Recommendation = ({
    isLoading,
    results,
    onSearch,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "recommendation.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="recommendation.breadcrumb" />
                    </li>
                </ol>
                <SearchForm isLoading={isLoading} onSearch={onSearch} />
                <ResultsList isLoading={isLoading} results={results} />
            </main>
        </div>
    );
};

export default Recommendation;
