import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import InputDate from "shared/components/Form/InputDate";
import MultiArtistSelect from "shared/components/MultiArtistSelect";
import I18n from "shared/lib/I18n";
import { Accordion, Card, Modal, Button, Badge, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputCreatableSelect from "shared/components/Form/InputCreatableSelect";
import InputTextArea from "shared/components/Form/InputTextArea";
import Loader from "shared/components/Loader";
import ImageLoader from "shared/components/ImageLoader";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faTimes,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import "./PictureTagsModal.css";
import Stepper from "shared/components/Stepper";

const PictureTagsModal = ({
    picture,
    show,
    handleHide,
    handleDelete,
    tags,
    errors,
    isDirty,
    control,
    showEnvironmentWarning,
    showCropWarning,
    showColourWarning,
    setShowEnvironmentWarning,
    setShowCropWarning,
    setShowColourWarning,
    tagsGetty,
    isSubmitting,
    onSubmit,
    isLoading,
    locale,
    location,
    isVersion,
    isEdit,
    returnToInformation,
    returnToCrop,
}) => {
    const CustomMultiValueRemove = () => null;
    const steps = [
        {
            title: I18n.getTranslation(
                location,
                "common.pictureTagsModal.upload"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureTagsModal.legal"
            ),
            onClick: () => {
                returnToInformation();
                setShowEnvironmentWarning(0);
                setShowCropWarning(0);
                setShowColourWarning(0);
            },
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureTagsModal.cropping"
            ),
            onClick: () => {
                returnToCrop();
                setShowEnvironmentWarning(0);
                setShowCropWarning(0);
                setShowColourWarning(0);
            },
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureTagsModal.tagging"
            ),
        },
    ];
    const activeStep = 3;

    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            className="image-tag-modal modal-add-image"
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-start w-100 custom-stepper">
                    <Stepper steps={steps} activeStep={activeStep} />
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={
                            isDirty
                                ? handleDelete
                                : () => {
                                      handleHide();
                                      setShowEnvironmentWarning(0);
                                      setShowCropWarning(0);
                                      setShowColourWarning(0);
                                  }
                        }
                        fixedWidth
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="modal-add-image">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ImageLoader
                                    src={
                                        picture?.url_version +
                                        "?r=" +
                                        Math.random()
                                    }
                                    className="display-block mx-auto mw-100 sticky-top"
                                    style={{
                                        maxHeight: "450px",
                                        maxWidth: "500px",
                                    }}
                                />
                            </div>
                            <div className="col">
                                <Accordion
                                    defaultActiveKey="tags"
                                    className="border"
                                >
                                    <Card className="border-bottom">
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="text-link cursor-pointer"
                                            eventKey="metas"
                                        >
                                            <h4 className="mb-0 font-weight-bold">
                                                <I18n t="common.pictureTagsModal.metas" />
                                            </h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="metas">
                                            <Card.Body>
                                                <table className="table table-bordered table-sm table-hover table-striped">
                                                    <tbody>
                                                        {picture?.metas.map(
                                                            (
                                                                { key, value },
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {key}
                                                                    </td>
                                                                    <td>
                                                                        {value}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="text-link cursor-pointer"
                                            eventKey="tags"
                                        >
                                            <h4 className="mb-0 font-weight-bold">
                                                <I18n t="common.pictureTagsModal.tags" />
                                            </h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="tags">
                                            <Card.Body className="pb-2">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        {tags.map(
                                                            ({
                                                                group: {
                                                                    id,
                                                                    name,
                                                                },
                                                                tags: options,
                                                            }) => (
                                                                <div
                                                                    key={id}
                                                                    className="row"
                                                                >
                                                                    <div className="col-10">
                                                                        <Form.Group>
                                                                            <Form.Label>
                                                                                {
                                                                                    name
                                                                                }
                                                                                {[
                                                                                    2,
                                                                                    7,
                                                                                    4,
                                                                                    5,
                                                                                    6,
                                                                                ].includes(
                                                                                    id
                                                                                ) && (
                                                                                    <Tippy
                                                                                        content={I18n.getTranslation(
                                                                                            location,
                                                                                            `common.pictureTagsModal.description.${id}`
                                                                                        )}
                                                                                        trigger="mouseenter"
                                                                                    >
                                                                                        <span className="ml-1">
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faInfoCircle
                                                                                                }
                                                                                                fixedWidth
                                                                                            />
                                                                                        </span>
                                                                                    </Tippy>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Controller
                                                                                control={
                                                                                    control
                                                                                }
                                                                                name={`${id}`}
                                                                                render={({
                                                                                    field: {
                                                                                        onChange,
                                                                                        value,
                                                                                        ref,
                                                                                    },
                                                                                }) => (
                                                                                    <div
                                                                                        className={`${
                                                                                            [
                                                                                                showEnvironmentWarning,
                                                                                                showCropWarning,
                                                                                                showColourWarning,
                                                                                            ].includes(
                                                                                                id
                                                                                            )
                                                                                                ? "d-flex align-items-center"
                                                                                                : ""
                                                                                        }`}
                                                                                    >
                                                                                        <InputSelect
                                                                                            innerRef={
                                                                                                ref
                                                                                            }
                                                                                            value={
                                                                                                value !==
                                                                                                null
                                                                                                    ? options.find(
                                                                                                          ({
                                                                                                              value: v,
                                                                                                          }) =>
                                                                                                              v ===
                                                                                                              value
                                                                                                      )
                                                                                                    : null
                                                                                            }
                                                                                            options={options.filter(
                                                                                                ({
                                                                                                    enabled,
                                                                                                }) =>
                                                                                                    enabled
                                                                                            )}
                                                                                            onChange={(
                                                                                                opt
                                                                                            ) => {
                                                                                                onChange(
                                                                                                    opt
                                                                                                        ? opt.value
                                                                                                        : null
                                                                                                );
                                                                                            }}
                                                                                            isClearable={
                                                                                                false
                                                                                            }
                                                                                            error={
                                                                                                errors[
                                                                                                    id
                                                                                                ] && [
                                                                                                    I18n.getTranslation(
                                                                                                        location,
                                                                                                        errors[
                                                                                                            id
                                                                                                        ]
                                                                                                            .message
                                                                                                    ),
                                                                                                ]
                                                                                            }
                                                                                            isDisabled={
                                                                                                !isVersion &&
                                                                                                isEdit &&
                                                                                                value !==
                                                                                                    null &&
                                                                                                ![
                                                                                                    1,
                                                                                                    5,
                                                                                                    6,
                                                                                                ].includes(
                                                                                                    id
                                                                                                )
                                                                                            }
                                                                                            enablePortal
                                                                                        />
                                                                                        {[
                                                                                            showEnvironmentWarning,
                                                                                            showCropWarning,
                                                                                            showColourWarning,
                                                                                        ].includes(
                                                                                            id
                                                                                        ) && (
                                                                                            <Tippy
                                                                                                content={I18n.getTranslation(
                                                                                                    location,
                                                                                                    `common.pictureTagsModal.warning`
                                                                                                )}
                                                                                                trigger="mouseenter"
                                                                                            >
                                                                                                <span className="ml-1">
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faExclamationTriangle
                                                                                                        }
                                                                                                        size="2x"
                                                                                                        fixedWidth
                                                                                                    />
                                                                                                </span>
                                                                                            </Tippy>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        <I18n t="common.pictureTagsModal.shotDate" />
                                                                    </Form.Label>
                                                                    <Controller
                                                                        control={
                                                                            control
                                                                        }
                                                                        name="shot_date"
                                                                        render={({
                                                                            field: {
                                                                                onChange,
                                                                                value,
                                                                            },
                                                                        }) => (
                                                                            <InputDate
                                                                                selected={
                                                                                    value
                                                                                        ? new Date(
                                                                                              value
                                                                                          )
                                                                                        : null
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) => {
                                                                                    onChange(
                                                                                        date
                                                                                            ? moment(
                                                                                                  date
                                                                                              ).format(
                                                                                                  "YYYY-MM-DD"
                                                                                              )
                                                                                            : null
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    errors.shot_date && [
                                                                                        I18n.getTranslation(
                                                                                            location,
                                                                                            errors
                                                                                                .shot_date
                                                                                                .message
                                                                                        ),
                                                                                    ]
                                                                                }
                                                                                disabled={
                                                                                    !isVersion &&
                                                                                    isEdit
                                                                                }
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                isClearable={
                                                                                    isVersion ||
                                                                                    !isEdit
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        <I18n t="common.pictureTagsModal.msArtists" />
                                                                    </Form.Label>
                                                                    <Controller
                                                                        control={
                                                                            control
                                                                        }
                                                                        name="ms_artists"
                                                                        render={({
                                                                            field: {
                                                                                onChange,
                                                                                value,
                                                                                name,
                                                                                ref,
                                                                            },
                                                                        }) => (
                                                                            <MultiArtistSelect
                                                                                innerRef={
                                                                                    ref
                                                                                }
                                                                                name={
                                                                                    name
                                                                                }
                                                                                selected={
                                                                                    value
                                                                                }
                                                                                locale={
                                                                                    locale
                                                                                }
                                                                                onMatch={(
                                                                                    id,
                                                                                    name
                                                                                ) =>
                                                                                    onChange(
                                                                                        [
                                                                                            ...value,
                                                                                            {
                                                                                                id,
                                                                                                name,
                                                                                            },
                                                                                        ]
                                                                                    )
                                                                                }
                                                                                onUnmatch={(
                                                                                    id
                                                                                ) =>
                                                                                    onChange(
                                                                                        value.filter(
                                                                                            (
                                                                                                artist
                                                                                            ) =>
                                                                                                artist.id !==
                                                                                                id
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onClear={() =>
                                                                                    onChange(
                                                                                        []
                                                                                    )
                                                                                }
                                                                                placeholder={I18n.getTranslation(
                                                                                    location,
                                                                                    "common.pictureTagsModal.msArtistsPlaceholder"
                                                                                )}
                                                                                isDisabled={
                                                                                    !isVersion &&
                                                                                    isEdit
                                                                                }
                                                                                enablePortal
                                                                                menuPlacement="auto"
                                                                                menuPosition="fixed"
                                                                                styles={{
                                                                                    valueContainer:
                                                                                        (
                                                                                            provided
                                                                                        ) => ({
                                                                                            ...provided,
                                                                                            padding:
                                                                                                "0 3px 0 6px",
                                                                                            lineHeight:
                                                                                                "1.5",
                                                                                            fontSize:
                                                                                                "1rem",
                                                                                            maxHeight:
                                                                                                "13vh",
                                                                                            overflowY:
                                                                                                "auto",
                                                                                        }),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        <I18n t="common.pictureTagsModal.open_tags.title" />
                                                                    </Form.Label>
                                                                    <Controller
                                                                        control={
                                                                            control
                                                                        }
                                                                        name={
                                                                            "open_tags"
                                                                        }
                                                                        render={({
                                                                            field: {
                                                                                onChange,
                                                                                value,
                                                                                ref,
                                                                            },
                                                                        }) => (
                                                                            <InputCreatableSelect
                                                                                innerRef={
                                                                                    ref
                                                                                }
                                                                                name={
                                                                                    "open_tags"
                                                                                }
                                                                                placeholder={I18n.getTranslation(
                                                                                    location,
                                                                                    "common.pictureTagsModal.open_tags.placeholder"
                                                                                )}
                                                                                value={
                                                                                    value?.map(
                                                                                        (
                                                                                            tag
                                                                                        ) => ({
                                                                                            value: tag,
                                                                                            label: tag,
                                                                                        })
                                                                                    ) ??
                                                                                    []
                                                                                }
                                                                                onChange={(
                                                                                    opt,
                                                                                    action
                                                                                ) => {
                                                                                    if (
                                                                                        action.action ===
                                                                                            "select-option" ||
                                                                                        action.action ===
                                                                                            "create-option"
                                                                                    ) {
                                                                                        onChange(
                                                                                            [
                                                                                                ...value,
                                                                                                action
                                                                                                    .option
                                                                                                    .value,
                                                                                            ]
                                                                                        );
                                                                                    } else if (
                                                                                        action.action ===
                                                                                        "remove-value"
                                                                                    ) {
                                                                                        onChange(
                                                                                            value.filter(
                                                                                                (
                                                                                                    tag
                                                                                                ) =>
                                                                                                    tag !=
                                                                                                    action
                                                                                                        .removedValue
                                                                                                        .value
                                                                                            )
                                                                                        );
                                                                                    } else if (
                                                                                        action.action ===
                                                                                        "clear"
                                                                                    ) {
                                                                                        onChange(
                                                                                            []
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                hideSelectedOptions={
                                                                                    false
                                                                                }
                                                                                isDisabled={
                                                                                    false
                                                                                }
                                                                                isMulti
                                                                                isClearable
                                                                                enablePortal
                                                                                error={
                                                                                    errors.open_tags && [
                                                                                        I18n.getTranslation(
                                                                                            location,
                                                                                            errors
                                                                                                .open_tags
                                                                                                .message
                                                                                        ),
                                                                                    ]
                                                                                }
                                                                                styles={{
                                                                                    valueContainer:
                                                                                        (
                                                                                            provided
                                                                                        ) => ({
                                                                                            ...provided,
                                                                                            padding:
                                                                                                "0 3px 0 6px",
                                                                                            lineHeight:
                                                                                                "1.5",
                                                                                            fontSize:
                                                                                                "1rem",
                                                                                            maxHeight:
                                                                                                "13vh",
                                                                                            overflowY:
                                                                                                "auto",
                                                                                        }),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                {tagsGetty &&
                                                                    tagsGetty.length >
                                                                        0 && (
                                                                        <>
                                                                            <I18n t="common.pictureTagsModal.tags_getty" />
                                                                            <InputCreatableSelect
                                                                                name={
                                                                                    "tags_getty"
                                                                                }
                                                                                value={
                                                                                    tagsGetty.map(
                                                                                        (
                                                                                            tag
                                                                                        ) => ({
                                                                                            value: tag,
                                                                                            label: tag,
                                                                                        })
                                                                                    ) ??
                                                                                    []
                                                                                }
                                                                                hideSelectedOptions={
                                                                                    false
                                                                                }
                                                                                isDisabled={
                                                                                    false
                                                                                }
                                                                                isMulti
                                                                                styles={{
                                                                                    valueContainer:
                                                                                        (
                                                                                            provided
                                                                                        ) => ({
                                                                                            ...provided,
                                                                                            padding:
                                                                                                "0 3px 0 6px",
                                                                                            lineHeight:
                                                                                                "1.5",
                                                                                            fontSize:
                                                                                                "1rem",
                                                                                            maxHeight:
                                                                                                "13vh",
                                                                                            overflowY:
                                                                                                "auto",
                                                                                        }),
                                                                                }}
                                                                                components={{
                                                                                    MultiValueRemove:
                                                                                        CustomMultiValueRemove,
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={isSubmitting || isLoading}
                    onClick={() => {
                        if (!isSubmitting && !isLoading && isDirty) {
                            onSubmit("previous");
                        } else {
                            returnToCrop();
                            setShowEnvironmentWarning(0);
                            setShowCropWarning(0);
                            setShowColourWarning(0);
                        }
                    }}
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t="utils.button.previous" />
                    )}
                </Button>
                <Button
                    variant="success"
                    disabled={isEdit ? false : isSubmitting || isLoading}
                    onClick={() => {
                        if ((!isSubmitting && !isLoading) || isEdit) {
                            onSubmit("next");
                        }
                    }}
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t={`utils.button.validate`} />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PictureTagsModal;
