import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { TYPE_PERSON } from "shared/constants/cms/artist";

const Names = ({
    artist,
    alias,
    aliasTypeOptions,
    names,
    langIsoOptions,
    isLoading,
}) => {
    return (
        <Widget title="artist.view.profileNames.head" isLoading={isLoading}>
            <Widget.Body>
                {artist.type === TYPE_PERSON && (
                    <>
                        <hr className="my-2" />
                        <div className="row">
                            <div className="col">
                                <strong>
                                    <I18n t="artist.view.profileNames.realname" />
                                </strong>
                                <span className="mx-1">:</span>
                                {artist?.nom_prenom_reel}
                            </div>
                        </div>
                    </>
                )}
                <hr className="my-2" />
                <div className="row">
                    <div className="col border-right">
                        <strong>
                            <I18n t="artist.view.profileNames.alias" />
                        </strong>
                        <hr className="my-2" />
                        {alias.map(({ id, alias, type }) => {
                            return (
                                <p key={id} className="mb-1">
                                    {`${alias} - ${
                                        aliasTypeOptions.find(
                                            ({ id }) => id === type
                                        )?.name ?? type
                                    }`}
                                </p>
                            );
                        })}
                    </div>
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.profileNames.names" />
                        </strong>
                        <hr className="my-2" />
                        {names.map(({ id, name, language }) => {
                            return (
                                <p key={id} className="mb-1">
                                    {`${name} - ${
                                        langIsoOptions.find(
                                            ({ id }) => id === language
                                        )?.name ?? language
                                    }`}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Names;
