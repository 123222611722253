import React from "react";
import I18n from "shared/lib/I18n";
import Artist from "./Artist";
import Album from "./Album";

const ResultsListItem = ({ score, title, id, isrc, artists, albums }) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <section className="widget no-padding-bottom">
                    <header>
                        <h4>
                            <b>
                                ({(score * 100).toFixed(2)}%) {title}
                            </b>
                            {" - "}
                            <I18n
                                t="artistTitle.resultsList.recordingId"
                                args={id}
                            />
                            {" - "}
                            <I18n
                                t="artistTitle.resultsList.isrc"
                                args={isrc}
                            />
                        </h4>
                    </header>
                    <div className="row">
                        <div className="col-md-6 windget-padding-md">
                            <section className="widget no-padding-bottom">
                                <header className="bg-musicstory">
                                    <h5 className="text-md-center">
                                        <I18n t="artistTitle.resultsList.artist.head" />
                                    </h5>
                                </header>
                                <div className="widget-body bg-gray-lighter no-padding">
                                    <ul className="news-list stretchable">
                                        {artists.map((artist) => (
                                            <Artist
                                                key={artist.id}
                                                {...artist}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-6 windget-padding-md">
                            <section className="widget no-padding-bottom">
                                <header className="bg-musicstory">
                                    <h5 className="text-md-center">
                                        <I18n t="artistTitle.resultsList.album.head" />
                                    </h5>
                                </header>
                                <div className="widget-body bg-gray-lighter no-padding">
                                    <ul className="news-list stretchable">
                                        {albums.map((album) => (
                                            <Album key={album.id} {...album} />
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ResultsListItem;
