import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import CNMPlatformIcon from "shared/components/CNMPlatformIcon";
import Search from "./Search";

const EnhancedSearch = ({ onSearch, ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { locale, chartType } = useParams();
    const { year, cnmTitleId, similarCnmTitleId } = useIntParams();

    const columns = [
        {
            dataField: "sameIsrc",
            text: "",
            formatter: (cell) =>
                cell ? (
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="mx-2"
                        size="lg"
                        fixedWidth
                        title={I18n.getTranslation(
                            location,
                            "cnm.aggregation.search.sameIsrc"
                        )}
                    />
                ) : undefined,
            headerStyle: { width: 0, padding: 0 },
            classes: "px-0",
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.search.artist"
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "cnm.aggregation.search.title"),
            sort: true,
        },
        {
            dataField: "platforms",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.search.platforms"
            ),
            formatter: (cell) => (
                <div className="d-flex">
                    {cell.map(({ id, name, matched }) => (
                        <span
                            key={id}
                            className={`badge badge-lg badge-${
                                matched ? "success" : "secondary"
                            } mr-1 px-1`}
                            title={name}
                        >
                            <CNMPlatformIcon name={name} />
                        </span>
                    ))}
                </div>
            ),
            searchable: false,
            sort: true,
            sortValue: (cell, row) =>
                cell.filter(({ matched }) => matched).length,
            headerStyle: { width: "4em" },
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.search.streams"
            ),
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            sort: true,
            headerStyle: { width: "7em" },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ id }, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/cnm/aggregation/${year}/${chartType}/${cnmTitleId}/${id}`
                );
            }
        },
        selected: [similarCnmTitleId],
    };

    const rowClasses = ({ checked, discarded }) =>
        discarded ? "table-secondary" : checked ? "table-success" : undefined;

    return (
        <Search
            columns={columns}
            selectRow={selectRow}
            rowClasses={rowClasses}
            onSearch={onSearch}
            disabled={cnmTitleId === undefined}
            {...props}
        />
    );
};

export default EnhancedSearch;
