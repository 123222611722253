import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import AlbumMatcher from "./AlbumMatcher";

const EnhancedAlbumMatcher = ({
    entryId,
    locale,
    language,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const onMatch = (msReleaseId) => {
        api.post(
            `matching/top/entry/${entryId}/album`,
            {
                locale,
                language,
            },
            {
                msReleaseId,
            }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return <AlbumMatcher locale={locale} onMatch={onMatch} {...props} />;
};

export default EnhancedAlbumMatcher;
