import React from "react";
import Year from "./Year";
import PF from "./PF";
import Chart from "./Chart";

const Deduplicated = () => {
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <PF />
                </div>
                <div className="col-6">
                    <Year />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Chart />
                </div>
            </div>
        </>
    );
};

export default Deduplicated;
