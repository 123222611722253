import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";

const CreateOrUpdateModal = ({
    role,
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    errors,
    isSubmitting,
    control,
    rightsOptions,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`user.role.manage.${
                            role ? "update" : "create"
                        }.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.role.manage.role.libelle" />
                        </Form.Label>
                        <InputText
                            {...register("libelle")}
                            error={
                                errors.libelle && [
                                    I18n.getTranslation(
                                        location,
                                        errors.libelle.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.role.manage.role.rights" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="role_rights"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value
                                            ? value.map(({ id, libelle }) => {
                                                  return {
                                                      value: id,
                                                      label: libelle,
                                                  };
                                              })
                                            : []
                                    }
                                    options={rightsOptions}
                                    onChange={(opt) => {
                                        onChange(
                                            opt
                                                ? opt.map((row) => {
                                                      return {
                                                          id: row.value,
                                                          libelle: row.label,
                                                      };
                                                  })
                                                : []
                                        );
                                    }}
                                    isOptionSelected={(e, s) =>
                                        s.find((a) => a.value === e.value)
                                    }
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    isMulti
                                    error={
                                        errors.role_rights && [
                                            I18n.getTranslation(
                                                location,
                                                errors.role_rights.message
                                            ),
                                        ]
                                    }
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
