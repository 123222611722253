import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import EditorialInformation from "./EditorialInformation";
import I18n from "shared/lib/I18n";
import { isValidDate, isValidDateRange } from "shared/functions/Date";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ArtistManagementContext } from "pages/ArtistManagement";

const schema = yup.object().shape({
    born: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .test("is-a-valid-date", "utils.form.date_format", (date) =>
            date ? isValidDate(date) : true
        )
        .when("died", {
            is: (died) => died,
            then: () =>
                yup
                    .string()
                    .trim()
                    .required("utils.form.required")
                    .test("is-a-valid-date", "utils.form.date_format", (date) =>
                        isValidDate(date)
                    )
                    .test(
                        "is-greater-than-start",
                        "utils.form.end_date_greater_than_start",
                        (value, context) =>
                            context.parent.died && value
                                ? isValidDateRange(value, context.parent.died)
                                : true
                    ),
        }),
    debut_date: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .test("is-a-valid-date", "utils.form.date_format", (date) =>
            date ? isValidDate(date) : true
        ),
    id_birth_country: yup.number().integer().nullable(),
    birth_area: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    birth_city: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    activity_period: yup
        .array()
        .of(
            yup.object().shape({
                start_date: yup
                    .string()
                    .trim()
                    .required("utils.form.required")
                    .test("is-a-valid-date", "utils.form.date_format", (date) =>
                        date ? isValidDate(date) : true
                    )
                    .test(
                        "is-greater-than-start",
                        "utils.form.end_date_greater_than_start",
                        (value, context) =>
                            context.parent.end_date && value
                                ? isValidDateRange(
                                      value,
                                      context.parent.end_date
                                  )
                                : true
                    ),
                end_date: yup
                    .string()
                    .nullable()
                    .trim()
                    .transform((value) => value || null)
                    .test("is-a-valid-date", "utils.form.date_format", (date) =>
                        date ? isValidDate(date) : true
                    )
                    .test(
                        "is-greater-than-start",
                        "utils.form.end_date_greater_than_start",
                        function (endDate, context) {
                            const startDate = context.parent.start_date;
                            return startDate && endDate
                                ? isValidDateRange(startDate, endDate)
                                : true;
                        }
                    ),
            })
        )
        .ensure(),
    died: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .test("is-a-valid-date", "utils.form.date_format", (date) =>
            date ? isValidDate(date) : true
        )
        .test(
            "is-greater-than-start",
            "utils.form.end_date_greater_than_start",
            function (endDate, context) {
                const startDate = context.parent.born;
                return endDate ? isValidDateRange(startDate, endDate) : true;
            }
        ),
    id_death_country: yup.number().integer().nullable(),
    death_area: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    death_city: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
});

const EnhancedEditorialInformation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artist } = useContext(ArtistManagementContext);
    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            born: null,
            debut_date: null,
            id_birth_country: null,
            birth_area: null,
            birth_city: null,
            activity_period: [],
            died: null,
            id_death_country: null,
            death_area: null,
            death_city: null,
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "activity_period",
    });

    const getEditorialInformation = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/editorial/information`, { locale })
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getEditorialInformation, [getEditorialInformation]);

    const [countryOptions, setCountryOptions] = useState([]);
    const getCountry = useCallback(() => {
        api.get(`country/${locale}`)
            .then((response) => {
                setCountryOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getCountry, [getCountry]);

    const onSubmit = async (data) => {
        await api
            .put(`artist/${artistId}/editorial/information`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.editorialInformation.update.success`
                    )
                );
                reset(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.editorialInformation.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <EditorialInformation
            {...props}
            getEditorialInformation={getEditorialInformation}
            countryOptions={countryOptions}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            artistType={artist.type}
            fields={fields}
            append={append}
            remove={remove}
        />
    );
};

export default EnhancedEditorialInformation;
