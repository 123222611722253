import React, { useState, useEffect } from "react";
import Panel from "./Panel";

const EnhancedPanel = (props) => {
    const [right, setRight] = useState("-350px");

    useEffect(() => {
        setRight("0px");
    }, [setRight]);

    return <Panel {...props} right={right} setRight={setRight} />;
};

export default EnhancedPanel;
