import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";

const Artists = ({
    artists,
    isLoading,
    columns,
    location,
    pagination,
    onColumnMatch,
    defaultSorted,
}) => {
    const { SearchBar } = Search;
    return (
        <ToolkitProvider
            keyField="id"
            data={artists}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title={`genre.artist.head`}
                            className="vertical-shrink mb-0 pb-0 h-100 artist"
                            isLoading={isLoading}
                        >
                            <Widget.Body>
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "genre.search"
                                    )}
                                />
                            </Widget.Body>
                            <Widget.Body
                                className="widget-table-overflow overflow-auto h-100"
                                style={{
                                    margin: "10px -20px 0px -20px",
                                }}
                            >
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    bootstrap4
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={<I18n t="genre.empty" />}
                                    classes="table-bordered-header-fixed table-truncate table-layout-auto mb-0"
                                    defaultSorted={defaultSorted}
                                />
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-2 mb-2 mr-0">
                                    <div className="col">
                                        <PaginationTotalStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                    <div className="col">
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default Artists;
