import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";

export const useCNMTitles = (year, chartType, locale) => {
    const { api } = useAPI();
    const [{ rows, platforms, isLoading }, setState] = useState({
        rows: [],
        platforms: [],
        isLoading: false,
    });

    const getTitles = useCallback(() => {
        if (!year || !chartType) {
            setState({
                rows: [],
                platforms: [],
                isLoading: false,
            });
            return;
        }

        let cancelled = false;
        setState({
            rows: [],
            platforms: [],
            isLoading: true,
        });
        api.get(`cnm/aggregation/titles/${year}/${chartType}`, {
            locale,
        })
            .then(({ titles, platforms }) => {
                if (!cancelled) {
                    setState({
                        rows: titles,
                        platforms,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        rows: [],
                        platforms: [],
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year, chartType]);
    useEffect(getTitles, [getTitles]);

    const update = (titles) => {
        setState((prev) => ({
            ...prev,
            rows: [
                // Update existing rows
                ...prev.rows.map(
                    (row) => titles.find(({ id }) => id === row.id) || row
                ),
                // Add new rows
                ...titles.filter(
                    ({ id }) => !prev.rows.find((row) => row.id === id)
                ),
            ],
        }));
    };

    return {
        rows,
        platforms,
        isLoading,
        update,
    };
};

export const useSearch = (year, chartType, cnmTitleId, locale) => {
    const { api } = useAPI();
    const [{ rows, isLoading }, setState] = useState({
        rows: [],
        isLoading: false,
    });

    const onSearch = useCallback(
        async (search) => {
            if (!year || !chartType || !cnmTitleId || !search) {
                setState({
                    rows: [],
                    isLoading: false,
                });
                return;
            }

            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            await api
                .get(
                    `cnm/aggregation/search/${year}/${chartType}/${cnmTitleId}`,
                    {
                        locale,
                        search,
                    }
                )
                .then((rows) => {
                    setState({
                        rows,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        rows: [],
                        isLoading: false,
                    });
                });
        },
        [year, chartType, cnmTitleId]
    );

    const update = (titles) => {
        setState((prev) => ({
            ...prev,
            rows: prev.rows.map(
                (row) => titles.find(({ id }) => id === row.id) || row
            ),
        }));
    };

    return { rows, isLoading, onSearch, update };
};

export const usePlatformTitles = (year, chartType, cnmTitleId, locale) => {
    const { api } = useAPI();
    const [{ rows, selected, isLoading }, setState] = useState({
        rows: [],
        selected: [],
        isLoading: false,
    });

    const getRows = useCallback(() => {
        let cancelled = false;
        if (!year || !chartType || !cnmTitleId) {
            setState({
                rows: [],
                selected: [],
                isLoading: false,
            });
            return;
        }

        setState({
            rows: [],
            selected: [],
            isLoading: true,
        });
        api.get(
            `cnm/aggregation/platform_titles/${year}/${chartType}/${cnmTitleId}`,
            {
                locale,
            }
        )
            .then((rows) => {
                if (!cancelled) {
                    setState({
                        rows,
                        selected: [],
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        rows: [],
                        selected: [],
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year, chartType, cnmTitleId]);
    useEffect(getRows, [getRows]);

    const remove = (ids) =>
        setState((prev) => ({
            ...prev,
            rows: prev.rows.filter((row) => !ids.includes(row.id)),
            selected: prev.selected.filter((row) => !ids.includes(row.id)),
        }));
    const add = (row) =>
        setState((prev) => ({
            ...prev,
            rows: [...prev.rows, row],
        }));
    const onSelect = (row, isSelect) =>
        setState((prev) => ({
            ...prev,
            selected: isSelect
                ? [...prev.selected, row]
                : prev.selected.filter(({ id }) => id !== row.id),
        }));

    return { rows, selected, isLoading, remove, add, onSelect };
};
