import React from "react";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    artist: yup
        .string()
        .trim()
        .when("title", {
            is: (val) => val === "",
            then: () => yup.string().required("utils.form.required"),
        }),
    title: yup.string().trim(),
});

const EnhancedSearchForm = ({ onSearch, ...props }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            artist: "",
            title: "",
        },
    });

    const onSubmit = handleSubmit((data) => onSearch(data.artist, data.title));

    return (
        <SearchForm
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            {...props}
        />
    );
};

export default EnhancedSearchForm;
