import React, { useState } from "react";
import Time from "./Time";
import { useMK } from "shared/hooks/useMK";
import moment from "moment";

const EnhancedTime = ({ ...props }) => {
    const [state, setState] = useState({
        isScrubbing: false,
        scrubbingPosition: null,
    });
    const mk = useMK({
        playbackTime: window.MusicKit.Events.playbackTimeDidChange,
    });

    const getTime = (ms) => {
        if (!ms || isNaN(ms)) {
            ms = 0;
        }

        const duration = moment.duration(ms);
        return `${String(duration.minutes()).padStart(2, "0")}:${String(
            duration.seconds()
        ).padStart(2, "0")}`;
    };

    const getCurrentPlaybackDuration = () => {
        return getTime(mk.instance.currentPlaybackTimeRemaining * 1000);
    };

    const getCurrentPlaybackTime = () => {
        return getTime(mk.instance.currentPlaybackTime * 1000);
    };

    const getDuration = () => {
        if (!mk.instance.nowPlayingItem) {
            return 0;
        }

        return mk.instance.isAuthorized
            ? mk.instance.nowPlayingItem.playbackDuration / 1000
            : 30;
    };

    const onScrub = (e) => {
        setState({
            ...state,
            scrubbingPosition: e.target.value,
        });
    };

    const onStartScrubbing = (e) => {
        setState({
            ...state,
            isScrubbing: true,
            scrubbingPosition: e.target.value,
        });
    };

    const onEndScrubbing = async (e) => {
        const element = e.target;
        element.blur();
        await mk.instance.seekToTime(element.value);

        setState({
            ...state,
            isScrubbing: false,
            scrubbingPosition: null,
        });
    };

    const getScrubberValue = () => {
        if (state.isScrubbing) {
            return state.scrubbingPosition;
        }
        if (mk.playbackTime) {
            return mk.playbackTime.currentPlaybackTime;
        }

        return 0;
    };

    return (
        <Time
            getCurrentPlaybackTime={getCurrentPlaybackTime}
            getCurrentPlaybackDuration={getCurrentPlaybackDuration}
            getScrubberValue={getScrubberValue}
            onScrub={onScrub}
            onStartScrubbing={onStartScrubbing}
            onEndScrubbing={onEndScrubbing}
            duration={getDuration()}
            {...props}
        />
    );
};

export default EnhancedTime;
