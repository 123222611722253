import React from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import UploadModal from "./UploadModal";
import ImportModal from "./ImportModal";

const FileList = ({
    files,
    isLoading,
    columns,
    pagination,
    rowClasses,
    uploadModal,
    onUpload,
    importModal,
    updateFile,
    location,
}) => {
    return (
        <>
            <UploadModal {...uploadModal} onSuccess={onUpload} />
            <ImportModal {...importModal} onSuccess={updateFile} />

            <ToolkitProvider
                keyField="id"
                data={files}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <PaginationProvider pagination={pagination}>
                        {({ paginationProps, paginationTableProps }) => (
                            <Widget
                                title="snep.file.fileList.head"
                                className="vertical-shrink mb-0"
                                isLoading={isLoading}
                                actions={
                                    <Button
                                        variant="success"
                                        onClick={uploadModal.onShow}
                                    >
                                        <I18n t="snep.file.fileList.upload.button" />
                                    </Button>
                                }
                            >
                                <Widget.Body className="mb-3">
                                    <Search.SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "snep.file.fileList.search"
                                        )}
                                    />
                                </Widget.Body>
                                <Widget.Body className="widget-table-overflow overflow-auto mb-0">
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        striped
                                        hover
                                        noDataIndication={
                                            <I18n t="snep.file.fileList.empty" />
                                        }
                                        classes="table-header-fixed table-layout-auto"
                                        bordered={false}
                                        rowClasses={rowClasses}
                                    />
                                </Widget.Body>
                                <Widget.Body>
                                    <div className="row align-items-center my-2 mr-0">
                                        {!isLoading && (
                                            <>
                                                <div className="col">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                        dataSize={files.length}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Widget.Body>
                            </Widget>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        </>
    );
};

export default FileList;
