import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faDesktop } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputText from "shared/components/Form/InputText";
import clsx from "clsx";
import Names from "./Names";

const Wikipedia = ({
    names,
    show,
    toggleShow,
    getWikipedia,
    isLoading,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    fields,
    append,
    remove,
    location,
}) => {
    return (
        <>
            <Form
                onSubmit={
                    !isSubmitting && !isLoading && isDirty ? onSubmit : null
                }
            >
                <Widget
                    title={`artist.management.wikipedia.head`}
                    actions={
                        <Button
                            variant="primary"
                            onClick={() =>
                                append({
                                    url: "",
                                })
                            }
                        >
                            <I18n t={`utils.button.add`} />
                        </Button>
                    }
                    enableCollapse
                >
                    <Widget.Body className="widget-table-overflow mb-0 border-top border-bottom">
                        {isLoading ? (
                            <Loader className="m-2" />
                        ) : (
                            <table
                                className="table table-striped table-sm table-header-fixed mb-0"
                                style={{ tableLayout: "fixed" }}
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <I18n t="artist.management.wikipedia.url" />
                                        </th>
                                        <th
                                            style={{
                                                width: "calc(1.25em + 2px + 2.6rem)",
                                            }}
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.length > 0 ? (
                                        fields.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <InputGroup>
                                                        <InputText
                                                            {...register(
                                                                `wikipedia.${index}.url`
                                                            )}
                                                            defaultValue={
                                                                item.url
                                                            }
                                                            showError={false}
                                                            error={
                                                                errors
                                                                    ?.wikipedia?.[
                                                                    index
                                                                ]?.url && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .wikipedia[
                                                                            index
                                                                        ].url
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                        />
                                                        <InputGroup.Append>
                                                            <a
                                                                href={item.url}
                                                                className={clsx(
                                                                    "btn",
                                                                    "btn-outline-primary",
                                                                    item.url ===
                                                                        "" &&
                                                                        "disabled"
                                                                )}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faDesktop
                                                                    }
                                                                    fixedWidth
                                                                />
                                                            </a>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            fixedWidth
                                                        />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="2"
                                                className="text-center"
                                            >
                                                <I18n t="artist.management.wikipedia.empty" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Widget.Body>
                    <Widget.Body className="mt-3">
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? getWikipedia
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.wikipedia.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.wikipedia.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Widget.Body>
                </Widget>
            </Form>
            <Names names={names} show={show} toggleShow={toggleShow} />
        </>
    );
};

export default Wikipedia;
