import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "pages/UserManagement/User/Form/ChangePasswordModal";
import I18n from "shared/lib/I18n";

const ChangePassword = ({
    user,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "user.manage.change_password.title"
                )}
                disabled={!user.enabled}
            >
                <FontAwesomeIcon icon={faKey} />
            </Button>

            <ChangePasswordModal
                user={user}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
            />
        </>
    );
};

export default ChangePassword;
