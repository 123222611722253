import React, { useState, useCallback, useEffect, useRef } from "react";
import PictureCropModal from "./PictureCropModal";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useApi from "shared/hooks/useApi";

const EnhancedPictureCropModal = ({
    id_picture,
    id_version,
    show,
    onSuccess,
    ...props
}) => {
    const { api } = useApi();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const cropperRef = useRef(null);
    const [cropData, setCropData] = useState({
        width: 1500,
        height: 1500,
    });
    const [isDisabled, setIsDisabled] = useState(false);

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(
                `picture/${id_picture}`,
                id_version && { versionId: id_version }
            )
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, id_picture]);

    useEffect(getPicture, [getPicture]);

    useEffect(() => {
        if (isSubmitLoading) {
            const cropper = cropperRef?.current?.cropper;

            api.put(
                `picture/${id_picture}/crop`,
                id_version ? { locale, versionId: id_version } : { locale },
                {
                    cropData: cropper.getData(true),
                }
            )
                .then(({ id_version }) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "common.pictureCropModal.success"
                        )
                    );
                    setSubmitLoading(false);
                    onSuccess?.(id_version);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setSubmitLoading(false);
                });
        }
    }, [id_picture, isSubmitLoading]);

    const handleSubmit = () => setSubmitLoading(true);

    const onCrop = () => {
        const cropper = cropperRef?.current?.cropper;
        const data = cropper.getData(true);
        setCropData(data);
        if (data.width < 1500 || data.height < 1500) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const onReady = useCallback(() => {
        const cropper = cropperRef?.current?.cropper;

        if (cropper) {
            cropper.enable();
            cropper.crop();
            cropper.setAspectRatio(1);
            cropper.setData(
                state.picture?.crop_data || {
                    width: 1500,
                    height: 1500,
                }
            );
        }
    }, [state.picture]);

    const rotateLeft = () => {
        const cropper = cropperRef?.current?.cropper;
        cropper.rotate(-90);
    };

    const rotateRight = () => {
        const cropper = cropperRef?.current?.cropper;
        cropper.rotate(90);
    };

    return (
        <PictureCropModal
            {...props}
            {...state}
            show={show}
            handleSubmit={handleSubmit}
            isSubmitLoading={isSubmitLoading}
            cropperRef={cropperRef}
            onCrop={onCrop}
            onReady={onReady}
            rotateLeft={rotateLeft}
            rotateRight={rotateRight}
            cropData={cropData}
            isDisabled={isDisabled}
            location={location}
        />
    );
};

export default EnhancedPictureCropModal;
