import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";

const SearchResult = ({
    artists,
    isLoading,
    columns,
    pagination,
    rowClasses,
    hasSearch,
}) => {
    return (
        <ToolkitProvider keyField="id" data={artists} columns={columns}>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget className="vertical-shrink mb-0 pb-0 h-100 search-result">
                            <Widget.Body className="mt-0">
                                <div className="row align-items-center mr-0">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                            <Widget.Body
                                className="widget-table-overflow overflow-auto h-100"
                                style={{
                                    margin: "10px -20px 0px -20px",
                                }}
                            >
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        striped
                                        bordered={false}
                                        noDataIndication={
                                            hasSearch ? (
                                                <I18n t="artist.search.empty" />
                                            ) : (
                                                <I18n t="artist.search.init" />
                                            )
                                        }
                                        classes="table-header-fixed table-truncate table-layout-auto"
                                        rowClasses={rowClasses}
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-2 mb-2 mr-0">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default SearchResult;
