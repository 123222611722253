import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import TextareaSwalButton from "shared/components/TextareaSwalButton";

const DiscardAction = ({
    discarded,
    comment,
    matched,
    onDiscard,
    onRecover,
    location,
}) => {
    return discarded ? (
        <Button
            title={comment}
            variant="primary"
            onClick={onRecover}
            className="p-2"
        >
            <FontAwesomeIcon icon={faThumbsUp} size="lg" fixedWidth />
        </Button>
    ) : (
        <TextareaSwalButton
            className="btn btn-primary p-2"
            title={I18n.getTranslation(
                location,
                "matching.track.track.discard.button.title"
            )}
            disabled={matched}
            swalOptions={{
                title: I18n.getTranslation(
                    location,
                    "matching.track.track.discard.modal.title"
                ),
                text: I18n.getTranslation(
                    location,
                    "matching.track.track.discard.modal.text"
                ),
            }}
            placeholder={I18n.getTranslation(
                location,
                "matching.track.track.discard.modal.placeholder"
            )}
            onConfirm={onDiscard}
            required
        >
            <FontAwesomeIcon icon={faThumbsDown} size="lg" fixedWidth />
        </TextareaSwalButton>
    );
};

export default DiscardAction;
