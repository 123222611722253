import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";

const ProfileInformation = ({
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    typeOptions,
}) => {
    return (
        <Widget title={`misc_entity.management.main`} enableCollapse>
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row">
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="misc_entity.management.misc_entity.name" />
                                </Form.Label>
                                <InputText
                                    {...register("name")}
                                    error={
                                        errors.name && [
                                            I18n.getTranslation(
                                                location,
                                                errors.name.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="misc_entity.management.misc_entity.type" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            ref={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: typeOptions.find(
                                                              ({ id }) =>
                                                                  id === value
                                                          )?.name,
                                                      }
                                                    : null
                                            }
                                            options={typeOptions.map(
                                                ({ id, name }) => {
                                                    return {
                                                        value: id,
                                                        label: name,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                            isClearable={false}
                                            error={
                                                errors.type && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.type.message
                                                    ),
                                                ]
                                            }
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="misc_entity.management.misc_entity.description" />
                                </Form.Label>
                                <InputText
                                    {...register("description")}
                                    error={
                                        errors.description && [
                                            I18n.getTranslation(
                                                location,
                                                errors.description.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="misc_entity.management.misc_entity.date" />
                                </Form.Label>
                                <InputText
                                    {...register("date")}
                                    error={
                                        errors.date && [
                                            I18n.getTranslation(
                                                location,
                                                errors.date.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `misc_entity.management.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default ProfileInformation;
