import React, { useState } from "react";
import Update from "./Update";
import { useParams, useLocation } from "react-router";
import { usePlanningsManagementContext } from "pages/PlanningsManagement/PlanningsManagement.context";

const EnhancedUpdate = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { lock } = usePlanningsManagementContext();
    const { planningId, partnerId } = useParams();

    return (
        <Update
            {...props}
            planningId={parseInt(planningId, 10)}
            partnerId={parseInt(partnerId, 10)}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            location={location}
            isPlanningLock={lock}
        />
    );
};

export default EnhancedUpdate;
