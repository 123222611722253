import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import PopularityMap from "./PopularityMap";

const defaultState = {
    gold: [],
    current: [],
    isLoading: false,
};

const EnhancedPopularityMap = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);

    const [popover, setPopover] = useState({
        show: false,
        id: null,
        country: null,
        target: null,
    });
    const showPopover = (id, country, target) =>
        setPopover({ show: true, id, country, target });
    const hidePopover = () => setPopover((prev) => ({ ...prev, show: false }));

    const getPopularity = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        return api
            .get(`artist/${artistId}/popularity/countries`, { locale })
            .then((response) => {
                setState({
                    ...response,
                    isLoading: false,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultState);
            });
    }, [artistId, locale]);

    useEffect(() => {
        getPopularity();
    }, [getPopularity]);

    return (
        <PopularityMap
            {...props}
            {...state}
            popover={popover}
            showPopover={showPopover}
            hidePopover={hidePopover}
            location={location}
        />
    );
};

export default EnhancedPopularityMap;
