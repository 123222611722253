import React from "react";
import I18n from "shared/lib/I18n";
import { PICTURE_STATUS_COLOR } from "shared/constants/cms/picture";

const Status = ({ status }) => {
    return (
        <span
            className={`text-${PICTURE_STATUS_COLOR[status]} font-weight-bold`}
        >
            <I18n t="pictures.label.status.formatedStatus" args={status} />
        </span>
    );
};

export default Status;
