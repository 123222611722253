import { createSelector } from "reselect";

export const getFilteredRows = createSelector(
    [
        (args) => args.rows,
        (args) => args.filter,
        (args) => args.chartType,
        (args) => args.treatment,
    ],
    (rows, filter, chartType, treatment) => {
        let filteredRows = rows;

        if (filter.matched !== null) {
            filteredRows = filteredRows.filter(
                (p) =>
                    (p.msArtist !== null &&
                        ((chartType == "ALBUM" && p.msAlbum !== null) ||
                            (chartType == "TRACK" &&
                                p.msRecordings.length > 0))) ===
                    filter.matched.value
            );
        }

        if (filter.newEntry !== null) {
            filteredRows = filteredRows.filter(
                (p) => (p.previousRank === null) === filter.newEntry.value
            );
        }

        if (filter.toBeTreated) {
            filteredRows = filteredRows.filter((p) => p.rank <= treatment);
        }

        if (filter.discarded !== null) {
            filteredRows = filteredRows.filter(
                (p) => p.discarded === filter.discarded.value
            );
        }

        return filteredRows;
    }
);
