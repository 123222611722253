import React, { useState, useEffect, useCallback } from "react";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import Featuring from "./Featuring";

const EnhancedFeaturing = (props) => {
    const { api } = useAPI();
    const { albumId } = useIntParams();
    const [state, setState] = useState({
        featurings: [],
        isLoading: true,
    });

    const getFeaturing = useCallback(() => {
        return api
            .get(`album/${albumId}/featurings`)
            .then((response) => {
                setState((prev) => ({ ...prev, featurings: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getFeaturing()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getFeaturing]);

    return <Featuring {...props} {...state} />;
};

export default EnhancedFeaturing;
