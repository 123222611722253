import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";

const CreateOrUpdateButton = ({
    show,
    cnmName,
    action,
    typeOptions,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onShow,
    onHide,
    onSubmit,
    ...props
}) => {
    return (
        <>
            <Button onClick={onShow} {...props} />

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n
                            t={`cnm.repositories.cnm_labels.createOrUpdate.${action}.title`}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        {cnmName !== null && (
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.repositories.cnm_labels.createOrUpdate.cnmName" />
                                </Form.Label>
                                <InputText disabled defaultValue={cnmName} />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.cnm_labels.createOrUpdate.name" />
                            </Form.Label>
                            <InputText
                                {...register("name")}
                                error={
                                    errors.name && [
                                        I18n.getTranslation(
                                            location,
                                            errors.name.message
                                        ),
                                    ]
                                }
                                defaultValue={cnmName}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.cnm_labels.createOrUpdate.type.label" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="type"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        name={name}
                                        options={typeOptions}
                                        value={
                                            value !== null
                                                ? typeOptions.find(
                                                      (opt) =>
                                                          opt.value == value
                                                  )
                                                : null
                                        }
                                        onChange={(opt, action) =>
                                            onChange(
                                                action.action ===
                                                    "select-option"
                                                    ? opt.value
                                                    : null
                                            )
                                        }
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "cnm.repositories.cnm_labels.createOrUpdate.type.placeholder"
                                        )}
                                        error={
                                            errors.type && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.type.message
                                                ),
                                            ]
                                        }
                                        enablePortal
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        <I18n
                            t={`cnm.repositories.cnm_labels.createOrUpdate.${action}.submit`}
                        />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateOrUpdateButton;
