import React, { useState, useCallback, useEffect } from "react";
import Matching from "./Matching";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import Isrcs from "./Isrcs";
import { ListGroup } from "react-bootstrap";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";

const EnhancedMatching = (props) => {
    const { api } = useAPI();
    const [data, setData] = useState({
        all: [],
        filtered: [],
        yearStatus: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        charts: null,
    });
    const location = useLocation();
    const { id_pf_or_year, id_year, chartType, locale } = useParams();

    const filterData = (data) =>
        data.filter(
            (row) =>
                filters.charts === null ||
                (filters.charts === "initial" && !row.complement) ||
                (filters.charts === "complement" && row.complement)
        );

    useEffect(() => {
        setData(({ all, ...state }) => ({
            ...state,
            all: all,
            filtered: filterData(all),
        }));
    }, [filters]);

    const getData = useCallback(() => {
        setIsLoading(true);
        let cancelled = false;
        if (id_year) {
            api.get(
                `cnm/charts/platform/${id_pf_or_year}/year/${id_year}/type/${chartType}`,
                {
                    locale,
                }
            )
                .then(({ chart, yearStatus }) => {
                    if (!cancelled) {
                        setData({
                            all: chart,
                            filtered: filterData(chart),
                            yearStatus,
                        });
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                });
        } else {
            if (!cancelled) {
                setData({
                    all: [],
                    filtered: [],
                    yearStatus: null,
                });
                setIsLoading(false);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [id_pf_or_year, id_year, chartType]);

    useEffect(getData, [getData]);

    const columns = [
        {
            dataField: "position",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.position"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.position"
                ),
            headerStyle: { width: "4em" },
            searchable: false,
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.title"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.title"
                ),
            formatExtraData: {
                locale,
            },
            title: true,
        },
        {
            dataField: "displayArtist",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.artist"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.artist"
                ),
            formatter: (cell, row, rowIndex, data) => (
                <ListGroup variant="flush">
                    <ListGroup.Item className="bg-transparent p-1">
                        {cell}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent p-1">
                        {row.artists &&
                            row.artists.length > 0 &&
                            row.artists.map(({ id, name }) => (
                                <span
                                    key={id}
                                    className="badge badge-secondary mr-1 mb-1"
                                >
                                    {id} - {name}
                                </span>
                            ))}
                    </ListGroup.Item>
                </ListGroup>
            ),
            formatExtraData: {
                locale,
            },
            title: true,
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.isrc"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.isrc"
                ),
            headerStyle: { width: "8em" },
            formatter: (cell, { alternateIsrcs }) => {
                return <Isrcs isrc={cell} alternates={alternateIsrcs} />;
            },
            filterValue: (cell, { alternateIsrcs }) => [
                cell,
                ...alternateIsrcs,
            ],
        },
        {
            dataField: "cnmTitle",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.cnm_title"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.cnm_title"
                ),
            formatter: (cell) => (cell ? `${cell.title} (${cell.id})` : "-"),
            searchable: false,
        },
        {
            dataField: "cnmArtist",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.cnm_artist"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.cnm_artist"
                ),
            formatter: (cell) =>
                cell && cell.id
                    ? `${cell.name} (${cell.id})`
                    : cell
                    ? cell.name
                    : "-",
            searchable: false,
        },
        {
            dataField: "streamsTotal",
            text: I18n.getTranslation(
                location,
                "cnm.charts.platform.matching.streams"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.platform.matching.streams"
                ),
            classes: "text-right",
            headerStyle: { width: "7em" },
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 1000,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Matching
            data={data.filtered}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            filters={filters}
            setFilters={setFilters}
            {...props}
        />
    );
};

export default EnhancedMatching;
