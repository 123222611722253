import { createSelector } from "reselect";

export const getFilterOptions = createSelector(
    [(args) => args.charts],
    (charts) =>
        charts.reduce(
            (acc, curr) => {
                if (curr.source) {
                    acc["source"] = [
                        ...acc.source,
                        {
                            label: curr.source,
                            value: curr.source,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (curr.area) {
                    acc["area"] = [
                        ...acc.area,
                        {
                            label: curr.area,
                            value: curr.area,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (curr.format) {
                    acc["format"] = [
                        ...acc.format,
                        {
                            label: curr.format,
                            value: curr.format,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (curr.country) {
                    acc["country"] = [
                        ...acc.country,
                        {
                            label: curr.country,
                            value: curr.country,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (curr.origin) {
                    acc["origin"] = [
                        ...acc.origin,
                        {
                            label: curr.origin,
                            value: curr.origin,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                return acc;
            },
            {
                source: [],
                area: [],
                format: [],
                country: [],
                origin: [],
            }
        )
);

export const getFilteredCharts = createSelector(
    [(args) => args.charts, (args) => args.selectedFilterOptions],
    (charts, selectedFilterOptions) => {
        let filteredCharts = charts;

        if (selectedFilterOptions.source) {
            filteredCharts = filteredCharts.filter(
                (p) => p.source === selectedFilterOptions.source
            );
        }

        if (selectedFilterOptions.area) {
            filteredCharts = filteredCharts.filter(
                (p) => p.area === selectedFilterOptions.area
            );
        }

        if (selectedFilterOptions.format) {
            filteredCharts = filteredCharts.filter(
                (p) => p.format === selectedFilterOptions.format
            );
        }

        if (selectedFilterOptions.country) {
            filteredCharts = filteredCharts.filter(
                (p) => p.country === selectedFilterOptions.country
            );
        }

        if (selectedFilterOptions.origin) {
            filteredCharts = filteredCharts.filter(
                (p) => p.origin === selectedFilterOptions.origin
            );
        }

        if (selectedFilterOptions.date_out) {
            filteredCharts = filteredCharts.filter(
                (p) => p.date_out < selectedFilterOptions.date_out
            );
        }

        return filteredCharts;
    }
);
