import React, { useState, useEffect } from "react";
import Work from "./Work";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    work: true,
};

const EnhancedWork = (props) => {
    const { recordingId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [recordingId]);

    return <Work {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedWork;
