import React from "react";
import { useParams } from "react-router";
import AlbumLink from "./AlbumLink";

const EnhancedAlbumLink = (props) => {
    const { locale } = useParams();
    return <AlbumLink {...props} locale={locale} />;
};

export default EnhancedAlbumLink;
