import React, { useState, useEffect } from "react";
import Lyrics from "./Lyrics";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    lyricsInformation: true,
};

const EnhancedLyrics = (props) => {
    const { recordingId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [recordingId]);

    return <Lyrics {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedLyrics;
