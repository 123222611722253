import {
    faExclamation,
    faClipboard,
    faClipboardCheck,
    faTimes,
    faCheck,
    faUserCheck,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

export const SUBTASK_STATUSES = [
    "TODO",
    "COMPLETED",
    "TO_BE_CORRECTED",
    "VALIDATED_WITHOUT_CONTROL",
    "VERIFIED_AND_VALIDATED",
    "ERROR",
];

export const SUBTASK_STATUS_ICON = {
    UNASSIGNED: faExclamation,
    TODO: faClipboard,
    COMPLETED: faClipboardCheck,
    TO_BE_CORRECTED: faTimes,
    VALIDATED_WITHOUT_CONTROL: faCheck,
    VERIFIED_AND_VALIDATED: faUserCheck,
    ERROR: faExclamationTriangle,
};

export const SUBTASK_STATUS_COLOR = {
    UNASSIGNED: "danger",
    TODO: "secondary",
    COMPLETED: "primary",
    TO_BE_CORRECTED: "warning",
    VALIDATED_WITHOUT_CONTROL: "success-auto",
    VERIFIED_AND_VALIDATED: "success",
    ERROR: "danger",
};
