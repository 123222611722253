import React, { useEffect } from "react";
import InputSwitch from "./InputSwitch";

const EnhancedInputSwitch = React.forwardRef(({ onLoad, ...props }, ref) => {
    useEffect(() => {
        onLoad?.();
    }, []);
    return <InputSwitch ref={ref} {...props} />;
});

export default EnhancedInputSwitch;
