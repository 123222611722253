import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";

const Filters = ({
    platformOptions,
    numberOfMatchedPlatformsOptions,
    statusOptions,
    filter,
    setFilter,
}) => {
    return (
        <>
            <div className="col-3">
                <InputSelect
                    value={filter.missingPlatforms}
                    onChange={(value) =>
                        setFilter((prev) => ({
                            ...prev,
                            missingPlatforms: value,
                        }))
                    }
                    placeholder={
                        <I18n t="cnm.aggregation.cnmTitleList.filters.missingPlatforms" />
                    }
                    options={platformOptions}
                    isMulti
                />
            </div>
            <div className="col-3">
                <InputSelect
                    value={filter.numberOfMatchedPlatforms}
                    onChange={(value) =>
                        setFilter((prev) => ({
                            ...prev,
                            numberOfMatchedPlatforms: value,
                        }))
                    }
                    placeholder={
                        <I18n t="cnm.aggregation.cnmTitleList.filters.numberOfMatchedPlatforms" />
                    }
                    options={numberOfMatchedPlatformsOptions}
                    isMulti
                />
            </div>
            <div className="col-3">
                <InputSelect
                    value={statusOptions.find(
                        ({ value }) => value == filter.status
                    )}
                    onChange={(option) =>
                        setFilter((prev) => ({
                            ...prev,
                            status: option?.value ?? null,
                        }))
                    }
                    placeholder={
                        <I18n t="cnm.aggregation.cnmTitleList.filters.status.placeholder" />
                    }
                    options={statusOptions}
                />
            </div>
        </>
    );
};

export default Filters;
