import React from "react";
import MatchedFilter from "./MatchedFilter";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const EnhancedMatchedFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.track.track.filter.matched.true"
            ),
            value: true,
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.track.track.filter.matched.false"
            ),
            value: false,
        },
    ];

    return <MatchedFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedMatchedFilter;
