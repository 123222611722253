import React from "react";
import { usePanel } from "layout/Panel";
import Panel from "shared/components/Panel";
import I18n from "shared/lib/I18n";
import TaskForm from "pages/PlanningsManagement/Form/TaskForm";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import AddToPlanningButton from "./AddToPlanningButton";

const EnhancedAddToPlanningButton = ({
    chart,
    msArtist,
    albumTitle,
    trackTitle,
    rank,
    locale,
    ...props
}) => {
    const [showPanel, hidePanel] = usePanel(() => (
        <Panel
            title={I18n.getTranslation(
                location,
                "common.charts.charts.addToPlanningButton.title"
            )}
            hidePanel={hidePanel}
        >
            <div className="m-3">
                <TaskForm
                    ms_artist={msArtist}
                    album_title={albumTitle}
                    track_title={trackTitle}
                    category="Chart"
                    reason={`${chart.title} - ${chart.country} - (${chart.startDate} - ${chart.endDate}) - ${rank}`}
                    top_id={chart.id}
                    onSubmitSuccess={hidePanel}
                    defaultLocale={locale}
                    partnerId={PLANNING_PARTNER.EDITORIAL}
                />
            </div>
        </Panel>
    ));

    return <AddToPlanningButton onClick={showPanel} {...props} />;
};

export default EnhancedAddToPlanningButton;
