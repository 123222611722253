import React from "react";
import { components } from "react-select";
import I18n from "shared/lib/I18n";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const MenuList = (props) => {
    const {
        hasArtistIds,
        globalSearch,
        setGlobalSearch,
        inputValue,
        onInputChange,
    } = props.selectProps;

    return (
        <components.MenuList {...props}>
            <label
                className="d-block px-3 border-bottom"
                style={{
                    borderColor: "hsla(0, 0%, 0%, 0.1)",
                    paddingBottom: "9px",
                    paddingTop: "6px",
                }}
                onClick={(e) => {
                    // This ensure that the label does not steal the focus from
                    // the Select's input, preventing the Select from closing
                    // when the label is clicked.
                    e.preventDefault();

                    if (hasArtistIds) {
                        setGlobalSearch(!globalSearch);

                        // Simulate a change in the input to reload the options.
                        onInputChange(inputValue, {
                            action: "input-change",
                            prevInputValue: inputValue,
                        });
                    }
                }}
            >
                <input
                    className="align-middle"
                    type="checkbox"
                    disabled={!hasArtistIds}
                    checked={globalSearch}
                    onChange={(e) => {
                        setGlobalSearch(e.target.checked);

                        // Simulate a change in the input to reload the options.
                        onInputChange(inputValue, {
                            action: "input-change",
                            prevInputValue: inputValue,
                        });
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the label
                />
                <span className="align-middle ml-2">
                    <I18n t="common.multiRecordingSelect.globalSearch" />
                </span>
            </label>
            {props.children}
        </components.MenuList>
    );
};

const MultiRecordingSelect = ({
    selected,
    loadOptions,
    hasArtistIds,
    globalSearch,
    setGlobalSearch,
    inputValue,
    setInputValue,
    ...props
}) => {
    return (
        <InputAsyncSelect
            value={selected.map(({ id, title, subtitle, duration }) => ({
                id,
                title,
                subtitle,
                label: `${[title, subtitle, duration]
                    .filter(Boolean)
                    .join(" — ")} (${id})`,
            }))}
            getOptionValue={({ id }) => id}
            loadOptions={loadOptions}
            components={{ MenuList }}
            hasArtistIds={hasArtistIds}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            isMulti
            closeMenuOnSelect={false}
            inputValue={inputValue}
            onInputChange={(newValue, action) => {
                if (action.action === "set-value") {
                    // Keep the input value (the search term) unchanged when a
                    // value is selected, to allow the user to select multiple
                    // recordings without retyping the search.
                    return inputValue;
                }

                setInputValue(newValue);
                return newValue;
            }}
            {...props}
        />
    );
};

export default MultiRecordingSelect;
