import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";

const CreateModal = ({
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    control,
    errors,
    isSubmitting,
    typeOptions,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t={`misc_entity.management.create.title`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <div className="row">
                        <Form.Group className="col-12">
                            <Form.Label>
                                <I18n t="misc_entity.management.misc_entity.name" />
                            </Form.Label>
                            <InputText
                                {...register("name")}
                                error={
                                    errors.name && [
                                        I18n.getTranslation(
                                            location,
                                            errors.name.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group className="col-12">
                            <Form.Label>
                                <I18n t="misc_entity.management.misc_entity.type" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="type"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <InputSelect
                                        className="w-100"
                                        ref={ref}
                                        value={
                                            value !== null
                                                ? {
                                                      value,
                                                      label: typeOptions.find(
                                                          ({ id }) =>
                                                              id === value
                                                      )?.name,
                                                  }
                                                : null
                                        }
                                        options={typeOptions.map(
                                            ({ id, name }) => {
                                                return {
                                                    value: id,
                                                    label: name,
                                                };
                                            }
                                        )}
                                        onChange={(opt) => {
                                            onChange(opt ? opt.value : null);
                                        }}
                                        isClearable={false}
                                        error={
                                            errors.type && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.type.message
                                                ),
                                            ]
                                        }
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="col-12">
                            <Form.Label>
                                <I18n t="misc_entity.management.misc_entity.description" />
                            </Form.Label>
                            <InputText
                                {...register("description")}
                                error={
                                    errors.description && [
                                        I18n.getTranslation(
                                            location,
                                            errors.description.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group className="col-12">
                            <Form.Label>
                                <I18n t="misc_entity.management.misc_entity.date" />
                            </Form.Label>
                            <InputText
                                {...register("date")}
                                error={
                                    errors.date && [
                                        I18n.getTranslation(
                                            location,
                                            errors.date.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateModal;
