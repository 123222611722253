import React from "react";
import MatchingArtist from "./MatchingArtist";
import { useLocation, useParams } from "react-router";

const EnhancedMatchingArtist = (props) => {
    const { partnerId, listId } = useParams();
    const location = useLocation();

    return (
        <MatchingArtist
            {...props}
            partnerId={partnerId}
            listId={listId}
            location={location}
        />
    );
};

export default EnhancedMatchingArtist;
