import React, { useCallback, useState, useMemo } from "react";
import { PanelContext } from "./Panel.context";
import { PanelRoot } from "./Panel.root";

export const PanelProvider = ({ children }) => {
    const [panels, setPanels] = useState({});
    const showPanel = useCallback(
        (key, panel) =>
            setPanels((panels) => ({
                ...panels,
                [key]: panel,
            })),
        []
    );
    const hidePanel = useCallback(
        (key) =>
            setPanels((panels) => {
                const newPanels = { ...panels };
                delete newPanels[key];
                return newPanels;
            }),
        []
    );
    const contextValue = useMemo(() => ({ showPanel, hidePanel }), [
        showPanel,
        hidePanel,
    ]);

    return (
        <PanelContext.Provider value={contextValue}>
            <React.Fragment>
                {children}
                <PanelRoot panels={panels} />
            </React.Fragment>
        </PanelContext.Provider>
    );
};
