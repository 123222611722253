import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faEdit } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Action = ({ editMotif, setStatus, location }) => {
    return (
        <div className={`btn-group btn-group-sm`}>
            <button
                className={`btn btn-primary`}
                onClick={editMotif}
                title={I18n.getTranslation(
                    location,
                    "pictures.label.rejected.edit.title"
                )}
            >
                <FontAwesomeIcon icon={faEdit} size="lg" />
            </button>
            <button
                className={`btn btn-success`}
                onClick={setStatus}
                title={I18n.getTranslation(
                    location,
                    "pictures.label.rejected.retrieve.title"
                )}
            >
                <FontAwesomeIcon icon={faUndo} size="lg" />
            </button>
        </div>
    );
};

export default Action;
