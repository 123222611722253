import React, { useState, useCallback, useEffect } from "react";
import AppleSampleAssessment from "./AppleSampleAssessment";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { appendScript } from "shared/functions/ScriptManagement";

const EnhancedAppleSampleAssessment = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        lyric: {},
        isLoading: true,
        queue: [],
        playerLoading: true,
        playerError: null,
    });
    const [isInit, setIsInit] = useState(false);
    const { lyricSampleId, locale } = useParams();
    const location = useLocation();

    const getLyric = useCallback(() => {
        let cancelled = false;
        api.get(`apple/sample/lyric/${lyricSampleId}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState((curr) => ({
                        ...curr,
                        lyric: response,
                        queue: response.appleIdExtended.map(String),
                        isLoading: false,
                    }));
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState((curr) => ({
                        ...curr,
                        lyric: {},
                        queue: [],
                        isLoading: false,
                    }));
                }
            });

        return () => {
            cancelled = true;
        };
    }, [lyricSampleId]);

    useEffect(getLyric, [getLyric]);

    const initSDK = async function () {
        const token = await api.get(`apple/sample/token`);
        await window.MusicKit.configure({
            developerToken: token,
            app: {
                name: "Music-Story backoffice",
                build: "1.0.0",
            },
            bitrate: window.MusicKit.PlaybackBitrate.HIGH,
        });
        setIsInit(true);
    };

    useEffect(() => {
        (async function init() {
            await appendScript(
                "https://js-cdn.music.apple.com/musickit/v3/musickit.js"
            );
            await initSDK();
        })();
    }, []);

    useEffect(() => {
        if (isInit && state.queue) {
            const music = window.MusicKit.getInstance();

            music.setQueue({ songs: state.queue }).then(
                (result) => {
                    if (result.items.length > 1) {
                        setState((curr) => ({
                            ...curr,
                            queue: [result.items[0].id],
                            playerLoading: false,
                        }));
                    } else {
                        setState((curr) => ({
                            ...curr,
                            playerLoading: false,
                        }));
                    }
                },
                (rejected) => {
                    if (rejected.errorCode === "CONTENT_UNAVAILABLE") {
                        setState((curr) => ({
                            ...curr,
                            playerLoading: false,
                            playerError:
                                "apple.sample.assessment.player.playerError.unavailable",
                        }));
                    }

                    if (rejected.errorCode === "REQUEST_ERROR") {
                        setState((curr) => ({
                            ...curr,
                            queue: curr.queue.slice(0, -1),
                        }));
                    }

                    console.error({ rejected });
                }
            );
        }

        return () => {
            if (isInit) {
                const music = window.MusicKit.getInstance();
                if (music.isPlaying) {
                    music.stop();
                }
            }
        };
    }, [isInit, state.queue]);

    return (
        <AppleSampleAssessment
            isInit={isInit}
            location={location}
            setMainState={setState}
            {...state}
            {...props}
        />
    );
};

export default EnhancedAppleSampleAssessment;
