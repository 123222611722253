import React, { useMemo } from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Filter from "./Filter";

const EnhancedFilter = ({ filter, ...props }) => {
    const location = useLocation();

    const firstLetterOptions = [
        { value: "#", label: "#" },
        { value: "A", label: "A" },
        { value: "B", label: "B" },
        { value: "C", label: "C" },
        { value: "D", label: "D" },
        { value: "E", label: "E" },
        { value: "F", label: "F" },
        { value: "G", label: "G" },
        { value: "H", label: "H" },
        { value: "I", label: "I" },
        { value: "J", label: "J" },
        { value: "K", label: "K" },
        { value: "L", label: "L" },
        { value: "M", label: "M" },
        { value: "N", label: "N" },
        { value: "O", label: "O" },
        { value: "P", label: "P" },
        { value: "Q", label: "Q" },
        { value: "R", label: "R" },
        { value: "S", label: "S" },
        { value: "T", label: "T" },
        { value: "U", label: "U" },
        { value: "V", label: "V" },
        { value: "W", label: "W" },
        { value: "X", label: "X" },
        { value: "Y", label: "Y" },
        { value: "Z", label: "Z" },
    ];

    const selectedFirstLetterOption = useMemo(
        () =>
            firstLetterOptions.find(
                ({ value }) => value === filter.firstLetter
            ),
        [filter.firstLetter]
    );

    const statusOptions = [
        {
            value: "NONE",
            label: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.filter.status.none"
            ),
        },
        {
            value: "VALIDATED",
            label: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.filter.status.validated"
            ),
        },
        {
            value: "DISCARDED",
            label: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.filter.status.discarded"
            ),
        },
        {
            value: "REPORTED",
            label: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.filter.status.reported"
            ),
        },
    ];

    const selectedStatusOption = useMemo(
        () => statusOptions.find(({ value }) => value === filter.status),
        [statusOptions, filter.status]
    );

    return (
        <Filter
            location={location}
            statusOptions={statusOptions}
            selectedStatusOption={selectedStatusOption}
            firstLetterOptions={firstLetterOptions}
            selectedFirstLetterOption={selectedFirstLetterOption}
            {...props}
        />
    );
};

export default EnhancedFilter;
