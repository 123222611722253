import React, { useState, useEffect } from "react";
import Audio from "./Audio";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    audioDescription: true,
};

const EnhancedAudio = (props) => {
    const { recordingId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [recordingId]);

    return <Audio {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedAudio;
