import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import YearList from "./YearList";
import Indicators from "./Indicators";
import { Helmet } from "react-helmet";

const CNMReport = ({ year, chartType, location }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.report"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/cnm/report">
                            <I18n t="base.nav.cnm.report" />
                        </NavLink>
                    </li>
                </ol>

                <div className="row">
                    <div className="col-md-6">
                        <YearList year={year} chartType={chartType} />
                    </div>
                </div>

                {year !== undefined && chartType !== undefined && (
                    <Indicators year={year} chartType={chartType} />
                )}
            </main>
        </div>
    );
};

export default CNMReport;
