import React from "react";
import Tracks from "./Tracks";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { ListGroup } from "react-bootstrap";

const EnhancedTracks = (props) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "mm_track_id",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.mm_track_id"
            ),
            headerStyle: {
                width: "10rem",
            },
            sort: true,
        },
        {
            dataField: "ms_track_id",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.ms_track_id"
            ),
            headerStyle: {
                width: "10rem",
            },
            sort: true,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.title"
            ),
            sort: true,
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.subtitle"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "genre",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.genre"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "original_album",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.original_album"
            ),
            formatter: ({ id, title }) => {
                const values = [title, `(${id})`];
                return values.filter(Boolean).join(" - ");
            },
            filterValue: (cell) => [cell.id, cell.title],
            sort: true,
            sortValue: (cell) => cell.title,
        },
        {
            dataField: "original_release_date",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.original_release_date"
            ),
            headerStyle: {
                width: "13rem",
            },
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "artists",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.artists"
            ),
            formatter: (cell) => {
                if (cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(({ id, name }) => {
                                const values = [id, name];
                                return (
                                    <ListGroup.Item
                                        key={id}
                                        className="bg-transparent p-1"
                                    >
                                        {values.filter(Boolean).join(" - ")}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    );
                }
                return "-";
            },
            filterValue: (cell) => [
                ...cell.map(({ id }) => id),
                ...cell.map(({ mbid }) => mbid),
                ...cell.map(({ name }) => name),
            ],
        },
        {
            dataField: "match",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.match"
            ),
            headerStyle: {
                width: "8rem",
            },
            formatter: (cell) => {
                return cell.toFixed(5);
            },
            sort: true,
        },
        {
            dataField: "popularity",
            text: I18n.getTranslation(
                location,
                "moodmedia.recommendation.popularity"
            ),
            headerStyle: {
                width: "10rem",
            },
            formatter: (cell) => {
                return cell.toFixed(5);
            },
            sort: true,
        },
    ];

    const defaultSorted = [
        {
            dataField: "match",
            order: "desc",
        },
    ];

    const options = {
        sizePerPage: 100,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Tracks
            columns={columns}
            options={options}
            defaultSorted={defaultSorted}
            {...props}
        />
    );
};

export default EnhancedTracks;
