import React from "react";
import Planning from "./Planning";
import Picture from "./Picture";
import Log from "./Log";
import "./Tracking.css";

const Tracking = ({ user }) => {
    return (
        <div className="artist-management-tracking">
            {user.hasRight("artist.tracking.plannings") && (
                <div className="row">
                    <div className="col">
                        <Planning />
                    </div>
                </div>
            )}
            {user.hasRight("artist.tracking.pictures") && (
                <div className="row">
                    <div className="col">
                        <Picture />
                    </div>
                </div>
            )}
            {user.hasRight("artist.tracking.logs") && (
                <div className="row">
                    <div className="col">
                        <Log />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tracking;
