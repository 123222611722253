import React, { useState, useEffect, useCallback } from "react";
import Member from "./Member";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isValidDate, isValidDateRange } from "shared/functions/Date";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";

const schema = yup.object().shape({
    member: yup
        .array()
        .of(
            yup.object().shape({
                artist: yup
                    .object()
                    .shape({
                        id: yup.number().required("utils.form.required"),
                        name: yup.string().required("utils.form.required"),
                    })
                    .required("utils.form.required"),
                start_date: yup
                    .string()
                    .trim()
                    .nullable()
                    .transform((value) => value || null)
                    .test("is-a-valid-date", "utils.form.date_format", (date) =>
                        date ? isValidDate(date) : true
                    )
                    .when("end_date", {
                        is: (end_date) => end_date,
                        then: () =>
                            yup
                                .string()
                                .trim()
                                .required("utils.form.required")
                                .test(
                                    "is-a-valid-date",
                                    "utils.form.date_format",
                                    (date) => isValidDate(date)
                                )
                                .test(
                                    "is-greater-than-start",
                                    "utils.form.end_date_greater_than_start",
                                    (value, context) =>
                                        context.parent.end_date && value
                                            ? isValidDateRange(
                                                  value,
                                                  context.parent.end_date
                                              )
                                            : true
                                ),
                    }),
                end_date: yup
                    .string()
                    .trim()
                    .nullable()
                    .transform((value) => value || null)
                    .test("is-a-valid-date", "utils.form.date_format", (date) =>
                        date ? isValidDate(date) : true
                    )
                    .test(
                        "is-greater-than-start",
                        "utils.form.end_date_greater_than_start",
                        (value, context) =>
                            context.parent.start_date && value
                                ? isValidDateRange(
                                      context.parent.start_date,
                                      value
                                  )
                                : true
                    ),
                id_role: yup.number().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedMember = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            member: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "member",
    });

    const getMember = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/member`)
            .then((response) => {
                if (!cancelled) {
                    reset({ member: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getMember, [getMember]);

    const [roleOptions, setRoleOptions] = useState([]);
    const getRole = useCallback(() => {
        api.get(`role/${locale}`)
            .then((response) => {
                setRoleOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getRole, [getRole]);

    const onSubmit = (data) => {
        return api
            .post(`artist/${artistId}/member`, { locale }, data.member)
            .then((response) => {
                reset({ member: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.member.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.member.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Member
            {...props}
            getMember={getMember}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
            locale={locale}
            roleOptions={roleOptions}
        />
    );
};

export default EnhancedMember;
