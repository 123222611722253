import React from "react";
import { NavLink } from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import UploadButton from "./UploadButton";

const YearList = ({
    repository,
    year,
    editable,
    isLoading,
    years,
    onUploadSuccess,
}) => {
    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="cnm.repositories.years.empty" />
            </td>
        </tr>
    );
    if (years.length !== 0) {
        rows = years.map((rowYear) => (
            <tr
                key={rowYear}
                className={rowYear === year ? "table-info" : undefined}
            >
                <td>
                    <NavLink to={`/cnm/repositories/${repository}/${rowYear}`}>
                        {rowYear}
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <Widget
            title="cnm.repositories.years.title"
            actions={
                editable && (
                    <UploadButton
                        repository={repository}
                        onSuccess={onUploadSuccess}
                    />
                )
            }
            className="align-self-start pb-0"
        >
            <Widget.Body
                className="widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped"
                        style={{ marginBottom: "0px" }}
                    >
                        <tbody>{rows}</tbody>
                    </table>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default YearList;
