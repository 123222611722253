import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import AppleSampleManagement from "./AppleSampleManagement";

const EnhancedAppleSampleManagement = (props) => {
    const { locale, selectedTab } = useParams();
    const { sampleId } = useIntParams();
    const navigate = useNavigate();
    const [sampleDate, setSampleDate] = useState();
    const location = useLocation();

    const onSelectTab = (k) => {
        if (sampleId) {
            navigate(`/${locale}/apple/sample/management/${sampleId}/${k}`);
        } else {
            navigate(`/${locale}/apple/sample/management`);
        }
    };

    return (
        <AppleSampleManagement
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            sampleDate={sampleDate}
            setSampleDate={setSampleDate}
            location={location}
            {...props}
        />
    );
};

export default EnhancedAppleSampleManagement;
