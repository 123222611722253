import React from "react";
import { useParams } from "react-router";
import I18n from "shared/lib/I18n";
import moment from "moment";
import Loader from "shared/components/Loader";
import ArtistLink from "shared/components/ArtistLink";

const Subtasks = ({ subtasks, isLoading }) => {
    const { locale } = useParams();

    let subtaskList = (
        <h3 className="text-center border-secondary border-top pt-3">
            <I18n t="plannings.billing.empty" />
        </h3>
    );

    if (subtasks.length > 0) {
        subtaskList = subtasks.map(({ type, artists }) => {
            return (
                <div key={type} className="card border border-secondary mb-4">
                    <div className="card-header">
                        <h4>
                            {type}
                            <span className="badge badge-secondary ml-2">
                                {artists.length}
                            </span>
                        </h4>
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th width="25%">
                                        <I18n t="plannings.billing.subtasks.artist" />
                                    </th>
                                    <th width="25%">
                                        <I18n t="plannings.billing.subtasks.album" />
                                    </th>
                                    <th width="25%">
                                        <I18n t="plannings.billing.subtasks.track" />
                                    </th>
                                    <th width="25%">
                                        <I18n t="plannings.billing.subtasks.validated_at" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {artists.map(
                                    (
                                        { artist, album, track, validated_at },
                                        key
                                    ) => (
                                        <tr key={key}>
                                            <td>
                                                <ArtistLink
                                                    artistId={artist.id}
                                                    content={artist.name}
                                                    blank
                                                />
                                            </td>
                                            <td>{album}</td>
                                            <td>{track}</td>
                                            <td>
                                                {moment(validated_at).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        });
    }

    if (isLoading) {
        return <Loader />;
    }

    return subtaskList;
};

export default Subtasks;
