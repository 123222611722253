import React from "react";
import InputSwitch from "shared/components/Form/InputSwitch";

const MatchedSwitch = ({ checked, disabled, onChecked }) => {
    return (
        <InputSwitch
            checked={checked}
            disabled={disabled}
            onChange={(e) => onChecked(e.target.checked)}
        />
    );
};

export default MatchedSwitch;
