import React, { useState, useEffect, useCallback, useContext } from "react";
import CreateOrUpdate from "./CreateOrUpdate";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import swal from "@sweetalert/with-react";
import {
    DiscographyContext,
    TRACK_BASIC_UP_TO_DATE,
    TRACK_ADD,
    TRACK_UPD,
} from "pages/ArtistManagement/Discography";
import { TRACK_HIT, TRACK_DISCOVERY } from "shared/constants/cms/track";

const schema = yup.object().shape({
    title: yup.string().nullable().trim().required("utils.form.required"),
    no_piste: yup
        .number()
        .typeError("utils.form.required")
        .required("utils.form.required"),
    no_cd: yup
        .number()
        .typeError("utils.form.required")
        .required("utils.form.required"),
    subtitle: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    isrc: yup
        .string()
        .uppercase()
        .transform((value) => value?.replace(/[\s-]/g, ""))
        .matches(/^[A-Z]{2}[A-Z0-9]{3}\d{7}$/, "utils.form.isrc_format")
        .transform((value) => value || null)
        .nullable(),
    duration: yup
        .string()
        .nullable()
        .trim()
        .required("utils.form.required")
        .matches("^[0-9]{2}:[0-9]{2}:[0-9]{2}$", "utils.form.duration_format"),
    hit: yup
        .string()
        .nullable()
        .test(
            "notBothAtTheSameTime",
            "utils.form.not_hit_discovery_at_same_time",
            (value, context) => {
                return !(value && context.parent.discovery);
            }
        ),
    discovery: yup
        .string()
        .nullable()
        .test(
            "notBothAtTheSameTime",
            "utils.form.not_hit_discovery_at_same_time",
            (value, context) => {
                return !(value && context.parent.hit);
            }
        ),
});

const defaultValues = {
    no_cd: null,
    no_piste: null,
    title: null,
    subtitle: null,
    isrc: null,
    duration: null,
    hit: null,
    discovery: null,
    id_language: null,
    id_dialect: null,
    genre: null,
};

const EnhancedCreateOrUpdate = ({
    artistId,
    albumId,
    releaseId,
    trackId,
    recordingId,
    onSuccess,
    ...props
}) => {
    const { dispatchDiscography, trackBasicUpToDate } =
        useContext(DiscographyContext);
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();
    const { api } = useAPI();
    const location = useLocation();
    const {
        handleSubmit,
        register,
        control,
        watch,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });
    const id_language = watch("id_language");

    const initForm = useCallback(() => {
        if (trackId) {
            setIsLoading(true);
            api.get(`get_track/${trackId}`, { locale })
                .then((response) => {
                    reset(response);
                    dispatchDiscography({
                        type: TRACK_BASIC_UP_TO_DATE,
                        data: true,
                    });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}`, { locale })
                .then((response) => {
                    reset({
                        ...defaultValues,
                        genre:
                            response.genre !== null
                                ? {
                                      ...response.genre,
                                      protected: false,
                                  }
                                : null,
                    });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else {
            reset();
        }
    }, [albumId, trackId, locale, reset]);

    useEffect(initForm, [initForm]);

    useEffect(() => {
        if (!trackBasicUpToDate) {
            initForm();
        }
    }, [trackBasicUpToDate]);

    const onSubmit = (data) => {
        let recordingControlOption = 0;

        const sendRequest = () => {
            const apiRequest = trackId
                ? api.put(
                      `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}`,
                      { locale, recordingControlOption },
                      data
                  )
                : api.post(
                      `artist/${artistId}/album/${albumId}/release/${releaseId}/track`,
                      { locale, recordingControlOption },
                      data
                  );

            apiRequest.then(onRequestSuccess).catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            toast.success(
                I18n.getTranslation(
                    location,
                    `artist.management.disco.objects.track.basic.${
                        trackId ? "update" : "create"
                    }.success`
                )
            );
            reset(response);
            dispatchDiscography({
                type: trackId ? TRACK_UPD : TRACK_ADD,
                data: response,
            });
            onSuccess?.(response.id_track, response.id_recording);
        };

        const onDuplicateIsrc = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    `artist.management.disco.objects.track.basic.${
                        trackId ? "update" : "create"
                    }.warningIsrc.title`
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.track.basic.${
                                trackId ? "update" : "create"
                            }.warningIsrc.text`
                        )}
                        <br />
                        <a
                            href={`/${locale}/artist/${error.body.recording.id_artist}/edit/discography/album/${error.body.recording.id_album}/release/${error.body.recording.id_release}/track/${error.body.recording.id_track}/recording/${error.body.recording.id_recording}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {error.body.recording.isrc} -{" "}
                            {error.body.recording.title}
                        </a>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    create: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.create"
                        ),
                        value: 1,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                    merge: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.merge"
                        ),
                        value: 2,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    return;
                }

                recordingControlOption = isConfirm;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_isrc") {
                onDuplicateIsrc(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.track.basic.${
                            trackId ? "update" : "create"
                        }.error`,
                        error.message
                    )
                );
            }
        };

        sendRequest();
    };

    const hitOptions = TRACK_HIT.map((k) => {
        return { value: k, label: k };
    });

    const discoveryOptions = TRACK_DISCOVERY.map((k) => {
        return { value: k, label: k };
    });

    const [languageOptions, setLanguageOptions] = useState([]);
    const getLanguageOptions = useCallback(() => {
        let cancelled = false;

        api.get("languages", { locale })
            .then((response) => {
                if (!cancelled) {
                    setLanguageOptions(
                        response.map((current) => ({
                            value: current.id,
                            label: current.name,
                        }))
                    );
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setLanguageOptions([]);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [locale]);

    useEffect(getLanguageOptions, [getLanguageOptions]);

    const [dialectOptions, setDialectOptions] = useState([]);
    const getDialectOptions = useCallback(() => {
        let cancelled = false;

        if (id_language) {
            api.get("dialects", { locale, id_language })
                .then((response) => {
                    if (!cancelled) {
                        setDialectOptions(
                            response.map((current) => ({
                                value: current.id,
                                label: current.name,
                            }))
                        );
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setDialectOptions([]);
                    }
                });
        } else {
            setDialectOptions([]);
        }

        return () => {
            cancelled = true;
        };
    }, [locale, id_language]);

    useEffect(getDialectOptions, [getDialectOptions]);

    return (
        <CreateOrUpdate
            artistId={artistId}
            albumId={albumId}
            releaseId={releaseId}
            trackId={trackId}
            recordingId={recordingId}
            initForm={initForm}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            hitOptions={hitOptions}
            discoveryOptions={discoveryOptions}
            languageOptions={languageOptions}
            dialectOptions={dialectOptions}
            location={location}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedCreateOrUpdate;
