import React, { useState, useEffect, useCallback } from "react";
import InternationalsCharts from "./InternationalsCharts";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";

const EnhancedInternationalsCharts = (props) => {
    const [charts, setCharts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const getCharts = useCallback(() => {
        api.get("data/external/internationals/charts", { locale })
            .then((response) => {
                setCharts(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [locale]);

    useEffect(getCharts, [getCharts]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.charts.id"
            ),
            isDummyField: true,
            headerStyle: { width: "3.6rem" },
            searchable: false,
        },
        {
            dataField: "link",
            text: "",
            isDummyField: true,
            formatter: (cell, { country }) => country,
            classes: () => {
                return "font-weight-bold";
            },
            filterValue: (cell, { country }) => country,
            headerStyle: { width: "40rem" },
            sort: true,
            sortValue: (cell, { country }) => country,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.charts.name"
            ),
            isDummyField: true,
            headerStyle: { width: "30rem" },
            searchable: false,
        },
        {
            dataField: "positions",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.charts.positions"
            ),
            isDummyField: true,
            headerStyle: { width: "20rem" },
            searchable: false,
        },
        {
            dataField: "treatments",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.charts.treatments"
            ),
            isDummyField: true,
            searchable: false,
        },
    ];

    const rowClasses = "table-secondary";

    const expandRow = {
        renderer: ({ charts: rowCharts }) => {
            const rows = rowCharts.map(
                ({ id, link, title, positions, treatment }) => {
                    return (
                        <tr key={id}>
                            <td style={{ width: "6rem", textAlign: "right" }}>
                                {id}
                            </td>
                            <td style={{ width: "40rem" }}>
                                <a href={link} target="_blank">
                                    {link}
                                </a>
                            </td>
                            <td style={{ width: "30rem" }}>{title}</td>
                            <td style={{ width: "20rem" }}>{positions}</td>
                            <td>{treatment}</td>
                        </tr>
                    );
                }
            );

            return (
                <Table>
                    <tbody>{rows}</tbody>
                </Table>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expanded: charts.map(({ id }) => id),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <FontAwesomeIcon icon={faSearchMinus} size="lg" />;
            }
            return <FontAwesomeIcon icon={faSearchPlus} size="lg" />;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <FontAwesomeIcon icon={faSearchMinus} size="lg" />;
            }
            return <FontAwesomeIcon icon={faSearchPlus} size="lg" />;
        },
    };

    return (
        <InternationalsCharts
            {...props}
            charts={charts}
            columns={columns}
            isLoading={isLoading}
            rowClasses={rowClasses}
            expandRow={expandRow}
        />
    );
};

export default EnhancedInternationalsCharts;
