import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const DeleteButton = ({
    show,
    isLoading,
    matchings,
    onShow,
    onHide,
    onConfirm,
}) => {
    return (
        <>
            <Button
                variant="danger"
                onClick={onShow}
                title={I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_artists_titles.titles.delete.tooltip"
                )}
            >
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
            </Button>
            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.cnm_artists_titles.titles.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <Loader />
                    ) : matchings.length === 0 ? (
                        <I18n t="cnm.repositories.cnm_artists_titles.titles.delete.text" />
                    ) : (
                        <>
                            <p>
                                <I18n t="cnm.repositories.cnm_artists_titles.titles.delete.matchings" />
                            </p>
                            <ul className="mb-0">
                                {matchings.map(
                                    (
                                        {
                                            year,
                                            platformId,
                                            platformName,
                                            type,
                                            positions,
                                        },
                                        index
                                    ) => (
                                        <li key={index}>
                                            <Link
                                                to={`/cnm/charts/platform/${platformId}/${year}/${type.toLowerCase()}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {year} - {platformName} (
                                                <I18n
                                                    t={`cnm.repositories.cnm_artists_titles.artists.delete.type.${type}`}
                                                />
                                                )
                                            </Link>
                                            {" - "}
                                            <I18n
                                                t="cnm.repositories.cnm_artists_titles.titles.delete.position"
                                                args={positions.join(", ")}
                                            />
                                        </li>
                                    )
                                )}
                            </ul>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        disabled={isLoading || matchings.length !== 0}
                        onClick={onConfirm}
                    >
                        <I18n t="cnm.repositories.cnm_artists_titles.titles.delete.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteButton;
