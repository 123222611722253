import { useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import {
    PICTURES_LABEL_AWAITING_SET,
    SidebarNotificationContext,
} from "layout/SidebarNotification";

export const useSidebarPicturesLabelAwaiting = () => {
    const { api } = useAPI();
    const { user } = useUser();
    const { picturesLabelAwaiting } = useContext(SidebarNotificationContext);

    const getPictures = useCallback(() => {
        if (user.hasRight("pictures.label.validation")) {
            api.get("picture/label/validation")
                .then((response) => {
                    picturesLabelAwaiting.dispatch({
                        type: PICTURES_LABEL_AWAITING_SET,
                        data: response.filter(
                            ({ status }) => status === "AWAITING"
                        ).length,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, []);

    useEffect(getPictures, [getPictures]);

    return { nbPicturesLabelAwaiting: picturesLabelAwaiting.count };
};
