import React from "react";
import I18n from "shared/lib/I18n";
import { Modal, Button } from "react-bootstrap";
import Loader from "shared/components/Loader";
import ImageLoader from "shared/components/ImageLoader";
import DownloadButton from "shared/components/DownloadButton";
import { isJson } from "shared/functions/String";
import { getPrincipalMetas, getSecondaryMetas } from "./DetailModal.pure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const DetailModal = ({
    picture,
    show,
    handleHide,
    isLoading,
    tags,
    more,
    setMore,
}) => {
    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.view.imagesGallery.detail.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col border-right">
                                <ImageLoader
                                    src={picture?.url_version}
                                    className="display-block mx-auto mw-100 sticky-top"
                                    style={{ maxHeight: "calc(100vh - 15rem)" }}
                                />
                            </div>
                            <div className="col">
                                <div className="d-flex justify-content-between">
                                    <DownloadButton
                                        variant="outline-primary"
                                        href={picture?.url_version}
                                        size="lg"
                                    >
                                        <I18n t="utils.button.download" />
                                    </DownloadButton>
                                    <Button
                                        variant="outline-primary"
                                        href={picture?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        size="lg"
                                    >
                                        <I18n t="artist.view.imagesGallery.detail.show" />
                                    </Button>
                                </div>
                                <hr />
                                <div>
                                    <strong>
                                        <I18n t="artist.view.imagesGallery.detail.source" />
                                        <span className="mx-1">:</span>
                                    </strong>
                                    {picture?.source}
                                </div>
                                <div>
                                    <strong>
                                        <I18n t="artist.view.imagesGallery.detail.copyright" />
                                        <span className="mx-1">:</span>
                                    </strong>
                                    {picture?.copyright}
                                </div>
                                <hr />
                                <strong className="text-center d-block">
                                    <I18n t="artist.view.imagesGallery.detail.tags" />
                                </strong>
                                <hr />
                                <div className="row">
                                    {tags.map(({ group: g, tags: t }) => (
                                        <div key={g.id} className="col">
                                            <strong>{g.name}</strong>
                                            <br />
                                            {t.find((row) => {
                                                return Object.values(
                                                    picture?.tags
                                                ).includes(row.value);
                                            })?.label ?? "-"}
                                        </div>
                                    ))}
                                </div>
                                <hr />
                                <strong className="text-center d-block">
                                    <I18n t="artist.view.imagesGallery.detail.metas" />
                                </strong>
                                <hr />
                                {picture?.metas &&
                                    picture.metas.length !== 0 && (
                                        <div>
                                            {getPrincipalMetas(
                                                picture.metas
                                            ).map(({ key, value }, index) => {
                                                let formatedValue = value;
                                                if (isJson(value)) {
                                                    if (key === "tags") {
                                                        formatedValue =
                                                            value.map((row) => (
                                                                <span
                                                                    key={row}
                                                                    className="badge badge-light border m-1"
                                                                >
                                                                    {row}
                                                                </span>
                                                            ));
                                                    } else if (
                                                        key ===
                                                        "referral_destinations"
                                                    ) {
                                                        const parsedValue =
                                                            JSON.parse(value);
                                                        formatedValue = (
                                                            <a
                                                                href={
                                                                    parsedValue.uri
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {
                                                                    parsedValue.uri
                                                                }
                                                            </a>
                                                        );
                                                    }
                                                }
                                                return (
                                                    <div
                                                        key={`${key}-${index}`}
                                                    >
                                                        <strong>{key}</strong>
                                                        <span className="mx-1">
                                                            :
                                                        </span>
                                                        {formatedValue}
                                                    </div>
                                                );
                                            })}
                                            {more
                                                ? getSecondaryMetas(
                                                      picture.metas
                                                  ).map(
                                                      (
                                                          { key, value },
                                                          index
                                                      ) => (
                                                          <div
                                                              key={`${key}-${index}`}
                                                          >
                                                              <strong>
                                                                  {key}
                                                              </strong>
                                                              <span className="mx-1">
                                                                  :
                                                              </span>
                                                              {value}
                                                          </div>
                                                      )
                                                  )
                                                : getSecondaryMetas(
                                                      picture.metas
                                                  ).length > 0 && (
                                                      <div className="text-center">
                                                          <Button
                                                              variant="transparent"
                                                              onClick={() =>
                                                                  setMore(true)
                                                              }
                                                          >
                                                              <FontAwesomeIcon
                                                                  icon={
                                                                      faChevronDown
                                                                  }
                                                                  size="2x"
                                                                  fixedWidth
                                                              />
                                                          </Button>
                                                      </div>
                                                  )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    <I18n t="utils.button.close" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DetailModal;
