import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";

const PublicationDateReference = ({
    publicationDates,
    selectedPublicationDate,
    onSelect,
    location,
    disabled,
}) => {
    return (
        <div className="form-control reference-list">
            {publicationDates?.map(({ date, platform }, index) => (
                <Button
                    key={index}
                    variant={
                        date == selectedPublicationDate
                            ? platform_color(platform)
                            : `outline-${platform_color(platform)}`
                    }
                    title={I18n.getTranslation(
                        location,
                        date == selectedPublicationDate
                            ? "cnm.report.consolidate.edit.publicationDate.unselect"
                            : "cnm.report.consolidate.edit.publicationDate.select"
                    )}
                    onClick={() => {
                        onSelect(date == selectedPublicationDate ? null : date);
                    }}
                    disabled={disabled || !date.match(/^\d{4}-\d{2}-\d{2}$/)}
                >
                    {date}
                </Button>
            ))}
        </div>
    );
};

export default PublicationDateReference;
