import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext,
} from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { MiscEntityManagementContext } from "pages/MiscEntityManagement";
import Getty from "./Getty";

const defaultState = {
    isLoading: false,
    pictures: [],
};

const EnhancedGetty = (props) => {
    const { api } = useApi();
    const { miscEntityId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);

    const getPictures = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`miscentity/${miscEntityId}/picture/getty`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        pictures: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [miscEntityId, locale]);

    useEffect(getPictures, [getPictures]);

    const { miscEntity } = useContext(MiscEntityManagementContext);
    const defaultSearch = useMemo(
        () =>
            Object.entries(miscEntity).length > 0
                ? `"${miscEntity.name}"`
                : null,
        [miscEntity]
    );

    const [selectedPicture, setSelectedPicture] = useState({
        pictureId: null,
        versionId: null,
    });
    const [gettyPicture, setGettyPicture] = useState();
    const [currentTab, setCurrentTab] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const onAdd = (gettyPicture) => {
        setSelectedPicture({
            pictureId: null,
            versionId: null,
        });
        setIsEdit(false);
        setGettyPicture(gettyPicture);
        setCurrentTab("Upload");
    };

    const onEdit = (picture) => {
        setSelectedPicture({
            pictureId: picture.id_picture,
            versionId: picture.id_version,
        });
        setIsEdit(true);
        setCurrentTab("Legal");
    };

    return (
        <Getty
            {...props}
            {...state}
            selectedPicture={selectedPicture}
            setSelectedPicture={setSelectedPicture}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            gettyPicture={gettyPicture}
            onEdit={onEdit}
            onAdd={onAdd}
            defaultSearch={defaultSearch}
            getPictures={getPictures}
            miscEntityId={miscEntityId}
            isEdit={isEdit}
        />
    );
};

export default EnhancedGetty;
