import React, { useState, useEffect } from "react";
import Progress from "./Progress";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";

const EnhancedProgress = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [users, setUsers] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);
    const { partnerId } = useIntParams();

    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/tasks/assignement`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setUsersOptions(
                        response.map(({ id, name }) => {
                            return { label: name, value: id };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, setUsersOptions]);

    return (
        <Progress
            {...props}
            users={users}
            setUsers={setUsers}
            usersOptions={usersOptions}
            partnerId={partnerId}
        />
    );
};

export default EnhancedProgress;
