import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import { NavLink } from "shared/lib/I18n";
import { Button, Form, InputGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Controller } from "react-hook-form";

const SearchForm = ({
    user,
    showAdvancedForm,
    toggleShowAdvancedForm,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    handleSubmit,
    onSubmit,
    onReset,
    location,
    countryOptions,
    typeOptions,
    genderOptions,
    roleOptions,
    genreOptions,
    filterOptions,
}) => {
    return (
        <Form
            onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
            className="search-form"
        >
            <Widget>
                <Widget.Body className="mt-0">
                    <div className="d-flex">
                        <InputGroup>
                            <InputText
                                {...register("search")}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "artist.search.placeholder"
                                )}
                                error={
                                    errors.search && [
                                        I18n.getTranslation(
                                            location,
                                            errors.search.message
                                        ),
                                    ]
                                }
                                showError={false}
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="outline-primary"
                                    onClick={toggleShowAdvancedForm}
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            showAdvancedForm
                                                ? faCaretUp
                                                : faCaretDown
                                        }
                                        fixedWidth
                                    />
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting || !isDirty}
                                    onClick={
                                        !isSubmitting && isDirty
                                            ? handleSubmit(onSubmit)
                                            : null
                                    }
                                    type="submit"
                                >
                                    <I18n t="utils.button.search" />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        {user.hasRight("artist.create") && (
                            <NavLink
                                to={`/artist/create`}
                                className="btn btn-success ml-4"
                                style={{ width: "15rem" }}
                            >
                                <I18n t="artist.search.add" />
                            </NavLink>
                        )}
                    </div>
                </Widget.Body>
                <Widget.Body className={clsx(!showAdvancedForm && "hide")}>
                    <hr />
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.mainGenre" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="main_genre"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: genreOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={[
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.majorGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent === 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.otherGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent !== 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                            ]}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.principalGenre" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="principal_genre"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: genreOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={[
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.majorGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent === 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.otherGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent !== 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                            ]}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.secondaryGenre" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="secondary_genre"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: genreOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={[
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.majorGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent === 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                                {
                                                    label: I18n.getTranslation(
                                                        location,
                                                        "artist.search.otherGenreGroup"
                                                    ),
                                                    options: genreOptions
                                                        .filter(
                                                            ({ id_parent }) =>
                                                                id_parent !== 0
                                                        )
                                                        .map(({ id, nom }) => {
                                                            return {
                                                                value: id,
                                                                label: nom,
                                                            };
                                                        }),
                                                },
                                            ]}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.editorialized.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="editorialized"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "editorialized"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions(
                                                "editorialized"
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.country" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="country"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: countryOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={countryOptions.map(
                                                ({ id, nom }) => {
                                                    return {
                                                        value: id,
                                                        label: nom,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.type" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: typeOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.name,
                                                      }
                                                    : null
                                            }
                                            options={typeOptions.map(
                                                ({ id, name }) => {
                                                    return {
                                                        value: id,
                                                        label: name,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.gender" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="gender"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: genderOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.name,
                                                      }
                                                    : null
                                            }
                                            options={genderOptions.map(
                                                ({ id, name }) => {
                                                    return {
                                                        value: id,
                                                        label: name,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.role" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="role"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: roleOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={roleOptions.map(
                                                ({ id, nom }) => {
                                                    return {
                                                        value: id,
                                                        label: nom,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.bio_en.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="bio_en"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "bio_en"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("bio_en")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.bio_fr.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="bio_fr"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "bio_fr"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("bio_fr")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.bio_de.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="bio_de"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "bio_de"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("bio_de")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.bio_pt.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="bio_pt"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "bio_pt"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("bio_pt")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.bio_es.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="bio_es"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "bio_es"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("bio_es")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.curation.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="curation"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "curation"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("curation")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.getty.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="getty"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "getty"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("getty")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <I18n t="artist.search.filters.label.title" />
                            </Form.Label>
                            <InputGroup>
                                <Controller
                                    control={control}
                                    name="label"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? filterOptions(
                                                          "label"
                                                      ).find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={filterOptions("label")}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group
                            as={Col}
                            className="ml-auto text-right mb-0"
                        >
                            <Button variant="link" onClick={onReset}>
                                <I18n t="artist.search.reset" />
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default SearchForm;
