import React from "react";
import CurationFollowup from "./CurationFollowup";
import { useLocation } from "react-router";

const EnhancedCurationFollowup = (props) => {
    const location = useLocation();
    return <CurationFollowup {...props} location={location} />;
};

export default EnhancedCurationFollowup;
