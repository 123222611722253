import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PlanningsProduction from "./PlanningsProduction";

const EnhancedPlanningsProduction = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        plannings: [],
        isLoading: true,
    });

    const getPlannings = useCallback(() => {
        let cancelled = false;

        api.get(`plannings`, { mode: "production" })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        plannings: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(getPlannings, [getPlannings]);

    return <PlanningsProduction {...props} {...state} />;
};

export default EnhancedPlanningsProduction;
