import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import ArtistLink from "shared/components/ArtistLink";
import musoaiIcon from "assets/static/images/musoai.png";

const CreditsData = ({ credits, isLoading }) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-end mb-3">
                <div className="mr-4 text-right">
                    <p className="mb-0">
                        <strong>
                            <I18n t="album.view.creditsCreditsData.licence" />
                        </strong>
                    </p>
                </div>
                <a
                    href="https://www.muso.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={musoaiIcon} width="160px" alt="Musoai" />
                </a>
            </div>
            <div className="row">
                <div className="col pl-0">
                    <Widget
                        title="album.view.creditsCreditsData.musician"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Musician"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.management"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Management"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.producers"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit ===
                                        "Producers & Arrangers"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.other"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Other"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                </div>
                <div className="col pr-0">
                    <Widget
                        title="album.view.creditsCreditsData.songwriter"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Songwriter"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.engineer"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Engineer"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.organization"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Organization"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                    <Widget
                        title="album.view.creditsCreditsData.visual"
                        isLoading={isLoading}
                    >
                        <Widget.Body>
                            <hr />
                            {credits
                                .find(
                                    ({ parent_credit }) =>
                                        parent_credit === "Visual"
                                )
                                ?.credits.map((child) => {
                                    return (
                                        <React.Fragment
                                            key={child.child_credit}
                                        >
                                            <strong>
                                                {child.child_credit}
                                            </strong>
                                            <ul>
                                                {child.credits.map((e) => (
                                                    <li key={e.name}>
                                                        <ArtistLink
                                                            artistId={e.id_ms}
                                                            content={e.name}
                                                            view
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
        </>
    );
};

export default CreditsData;
