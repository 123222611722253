import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import filterFactory from "@musicstory/react-bootstrap-table2-filter";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "@musicstory/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

const Gallery = ({
    label,
    gallery,
    columns,
    defaultSorted,
    paginationOptions,
    isLoading,
    showStatusDefinition,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <section className="widget pb-0">
            <ToolkitProvider
                keyField="id_picture"
                data={gallery}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <>
                        <header className="d-flex justify-content-between">
                            <h2 className="mr-auto">
                                <I18n
                                    t="pictures.label.status.head"
                                    args={label}
                                />
                            </h2>
                            <ul className="mb-0 list-unstyled mr-3">
                                <li className="font-weight-bold">
                                    <i className="fas fa-circle flex-last ml-auto text-secondary mr-1"></i>
                                    AWAITING
                                </li>

                                <li className="font-weight-bold">
                                    <i className="fas fa-circle flex-last ml-auto text-primary mr-1"></i>
                                    AWAITING FOR PROCESSING
                                </li>
                            </ul>
                            <ul className="mb-0 list-unstyled mr-3">
                                <li className="font-weight-bold">
                                    <i className="fas fa-circle flex-last ml-auto text-success mr-1"></i>
                                    DOCUMENTED
                                </li>
                                <li className="font-weight-bold">
                                    <i className="fas fa-circle flex-last ml-auto text-danger mr-1"></i>
                                    REJECTED
                                </li>
                            </ul>
                            <button
                                type="button"
                                className="btn btn-info"
                                onClick={showStatusDefinition}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    size="lg"
                                />
                            </button>
                        </header>
                        <div className="widget-body">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "pictures.label.status.search"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="widget-body widget-table-overflow">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    striped
                                    hover
                                    keyField="id_picture"
                                    data={gallery}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    noDataIndication={
                                        <I18n t="pictures.label.status.empty" />
                                    }
                                    filter={filterFactory()}
                                    pagination={paginationFactory(
                                        paginationOptions
                                    )}
                                />
                            )}
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </section>
    );
};

export default Gallery;
