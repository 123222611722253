import { useEffect, useState } from "react";

export const useMK = (bindings = {}) => {
    const [events, setEvents] = useState({});

    function handleEventChange(key, e) {
        setEvents((previousEvents) => ({
            ...previousEvents,
            [key]: e,
        }));
    }

    useEffect(() => {
        const bindingFunctions = {};

        for (const [key, eventName] of Object.entries(bindings)) {
            const handler = (e) => handleEventChange(key, e);

            bindingFunctions[eventName] = handler;
            window.MusicKit.getInstance().addEventListener(eventName, handler);
        }

        return () => {
            for (const [eventName, func] of Object.entries(bindingFunctions)) {
                window.MusicKit.getInstance().removeEventListener(
                    eventName,
                    func
                );
                delete bindingFunctions[eventName];
            }
        };
    }, []);

    return {
        instance: window.MusicKit.getInstance(),
        ...events,
    };
};
