import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Search from "./Search";
import { useDebouncedCallback } from "use-debounce";

const EnhancedSearch = ({ onSearch, onGlobalSearch, ...props }) => {
    const [query, setQuery] = useState("");

    const debouncedOnSearch = useDebouncedCallback(onSearch, 250);

    // Reset search query when the artist changes
    const { artistId } = useParams();
    useEffect(() => {
        setQuery("");
        debouncedOnSearch("");
    }, [artistId]);

    return (
        <Search
            query={query}
            onQueryChange={setQuery}
            onSearch={debouncedOnSearch}
            onGlobalSearch={onGlobalSearch}
            {...props}
        />
    );
};

export default EnhancedSearch;
