import React from "react";
import styles from "./Layout.module.css";
import clsx from "clsx";

const Layout = ({ children }) => {
    return (
        <div
            className={clsx(
                "pt-4 d-flex justify-content-center align-items-center min-vh-100",
                styles.authBgCover
            )}
        >
            <div
                className={clsx(
                    "overflow-hidden pt-lg-2",
                    styles.authPageContent
                )}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card overflow-hidden border-0 m-0">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className={clsx("row", styles.footer)}>
                        <div className="col-lg-12">
                            <div className="text-center">
                                <p className="mb-0">
                                    {new Date().getFullYear()} ©
                                    <a
                                        href="https://www.music-story.com"
                                        className="ml-2 text-reset"
                                    >
                                        Music-Story
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
