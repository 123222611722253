import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const PictureCheckButton = ({ isDisabled, className, onClick, title }) => {
    const location = useLocation();

    return (
        <div className={`btn-group btn-group-sm ${className}`}>
            <button
                className={`btn btn-primary ${isDisabled ? "disabled" : ""}`}
                onClick={onClick}
                title={I18n.getTranslation(
                    location,
                    title || "common.pictureCheckButton.title"
                )}
                style={{ width: "30px" }}
            >
                <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
            </button>
        </div>
    );
};

export default PictureCheckButton;
