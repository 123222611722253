import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import { Form, Button } from "react-bootstrap";
import Layout from "layout/Auth/Layout";
import clsx from "clsx";
import SidePanel from "layout/Auth/Layout/SidePanel";

const Request = ({
    handleRequest,
    isSend,
    errors,
    register,
    isSubmitting,
    isDirty,
    location,
}) => {
    return (
        <Layout>
            <div
                className="row justify-content-center"
                style={{ minHeight: "610px" }}
            >
                <div className="col-lg-6">
                    <SidePanel withoutSlider />
                </div>
                <div className="col-lg-6">
                    <div className="p-lg-5 p-4">
                        <div>
                            <h3 className="text-secondary">
                                <I18n t={`security.request.title`} />
                            </h3>
                        </div>

                        <hr />

                        <div className="mt-4">
                            {isSend ? (
                                <>
                                    <p>
                                        <I18n t={`security.request.message`} />
                                    </p>
                                    <NavLink
                                        to={`/login`}
                                        className="text-primary text-decoration-underline float-right"
                                    >
                                        <I18n t={`security.request.login`} />
                                    </NavLink>
                                </>
                            ) : (
                                <Form
                                    onSubmit={
                                        !isSubmitting ? handleRequest : null
                                    }
                                >
                                    <Form.Group>
                                        <Form.Label>
                                            <I18n
                                                t={`security.request.username`}
                                            />
                                        </Form.Label>
                                        <InputText
                                            {...register("username")}
                                            className={clsx("form-control")}
                                            error={
                                                errors.username && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.username.message
                                                    ),
                                                ]
                                            }
                                            showError={false}
                                        />
                                    </Form.Group>

                                    <div className="mt-4">
                                        <Button
                                            variant="inverse"
                                            disabled={isSubmitting || !isDirty}
                                            type="submit"
                                            size="lg"
                                            block
                                        >
                                            <I18n
                                                t={`security.request.button`}
                                            />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Request;
