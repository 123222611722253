import React from "react";
import Sidebar from "layout/Sidebar";
import Navbar from "layout/Navbar";
import Auth from "layout/Auth/Auth";
import { Outlet } from "react-router-dom";
import { PanelProvider } from "./Panel";
import { SidebarNotificationProvider } from "./SidebarNotification";
import MatomoProvider from "./Matomo";
import ErrorBoundaryProvider from "./ErrorBoundary";
import { Navigate } from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import "assets/static/styles/ReactToastify.css";
import Chatwoot from "./Chatwoot";

const Layout = () => {
    const { locale } = useParams();
    const location = useLocation();

    if (!locale?.match(/^en|fr|ja|de|ko$/g)) {
        return <Navigate to={location.pathname} replace />;
    }

    return (
        <ErrorBoundaryProvider>
            <Auth>
                <MatomoProvider>
                    <SidebarNotificationProvider>
                        <PanelProvider>
                            <Sidebar />
                            <Navbar />

                            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}

                            <Outlet />
                        </PanelProvider>
                    </SidebarNotificationProvider>
                </MatomoProvider>
                <Chatwoot />
            </Auth>
        </ErrorBoundaryProvider>
    );
};

export default Layout;
