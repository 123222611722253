import React from "react";
import I18n from "shared/lib/I18n";
import Author from "./Author";
import { Helmet } from "react-helmet";

const AuthorManagement = ({ location }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "author.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "author.manage.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="author.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="author.manage.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <Author />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AuthorManagement;
