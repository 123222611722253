import React, { useState, useEffect, useCallback } from "react";
import Plannings from "./Plannings";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";

const EnhancedPlannings = (props) => {
    const [plannings, setPlannings] = useState({
        data: [],
        isLoading: true,
    });
    const { locale } = useParams();
    const { api } = useAPI();
    const location = useLocation();

    const getPlannings = useCallback(() => {
        api.get("plannings", { locale })
            .then((response) => {
                setPlannings({
                    data: response,
                    isLoading: false,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(getPlannings, [getPlannings]);

    return (
        <Plannings
            {...props}
            plannings={plannings.data}
            isLoading={plannings.isLoading}
            getPlannings={getPlannings}
            location={location}
        />
    );
};

export default EnhancedPlannings;
