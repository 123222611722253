import moment from "moment";

export const formatDuration = (duration) => {
    if (duration !== undefined && duration !== null) {
        const momentDuration = moment.duration(duration, "seconds");
        return `${String(momentDuration.minutes()).padStart(2, "0")}:${String(
            momentDuration.seconds()
        ).padStart(2, "0")}`;
    }
    return null;
};
