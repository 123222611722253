import React, { useCallback, useEffect, useState } from "react";
import TopEmerging from "./TopEmerging";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import ArtistLink from "shared/components/ArtistLink";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import placeholder from "assets/static/images/logo_placeholder.png";
import styles from "./TopEmerging.module.css";
import clsx from "clsx";

const EnhancedTopEmerging = ({ id_country, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        artists: [],
    });

    const getTopEmerging = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_country) {
            api.get(`statistics/industry/${id_country}/top-emerging`)
                .then((response) => {
                    setState({
                        isLoading: false,
                        artists: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        artists: [],
                    });
                });
        }
    }, [id_country]);

    useEffect(getTopEmerging, [getTopEmerging]);

    const columns = [
        {
            dataField: "cover",
            formatter: (cell) => (
                <span>
                    <ImageLoader
                        src={cell}
                        fallbackSrc={placeholder}
                        fallbackClassName={clsx(styles.placeholderImg)}
                        className="img"
                        width="30px"
                    />
                </span>
            ),
        },
        {
            dataField: "name",
            formatter: (cell, { id }) => (
                <ArtistLink artistId={id} content={cell} />
            ),
        },
    ];

    return (
        <TopEmerging
            {...props}
            {...state}
            id_country={id_country}
            columns={columns}
        />
    );
};

export default EnhancedTopEmerging;
