import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLink } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import {
    getFilteredCategories,
    getFilterOptions,
} from "./CategoryList.selector";
import CategoryList from "./CategoryList";

const EnhancedCategoryList = ({ categoryId, onSelect, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [{ isLoading, categories }, setState] = useState({
        isLoading: true,
        categories: [],
    });
    const [filter, setFilter] = useState({
        source: null,
        area: null,
        country: null,
    });

    const getCategories = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get("matching/top/category", { locale, active: true })
            .then((response) => {
                setState({ isLoading: false, categories: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState({ isLoading: false, categories: [] });
            });
    }, [locale]);

    useEffect(getCategories, [getCategories]);

    const columns = [
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.title"
            ),
            formatter: (cell) => cell || "-",
        },
        {
            dataField: "source",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.source"
            ),
            formatter: (cell) => cell || "-",
        },
        {
            dataField: "area",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.area"
            ),
            formatter: (cell) => cell || "-",
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.country"
            ),
            formatter: (cell) => cell || "-",
        },
        {
            dataField: "main",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.main"
            ),
            formatter: (cell) =>
                cell ? <FontAwesomeIcon icon={faCheck} /> : "-",
        },
        {
            dataField: "format",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.format"
            ),
            formatter: (cell) => cell || "-",
        },
        {
            dataField: "link",
            text: I18n.getTranslation(
                location,
                "common.charts.categoryList.link"
            ),
            formatter: (cell) =>
                cell ? (
                    <a href={cell} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLink} />
                    </a>
                ) : (
                    "-"
                ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                onSelect(row.id);
            }
        },
        selected: [categoryId],
    };

    return (
        <CategoryList
            isLoading={isLoading}
            columns={columns}
            selectRow={selectRow}
            filterOptions={getFilterOptions({ categories, filter })}
            filter={filter}
            setFilter={setFilter}
            categories={getFilteredCategories({ categories, filter })}
            {...props}
        />
    );
};

export default EnhancedCategoryList;
