import React, { useState, useEffect, useCallback } from "react";
import Genre from "./Genre";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { OverlayTrigger, Tooltip, ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import Delete from "./Button/Delete";
import FlagIcon from "shared/functions/FlagIcon";

const EnhancedGenre = (props) => {
    const [genre, setGenre] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { locale } = useParams();
    const location = useLocation();
    const { api } = useAPI();

    const getGenre = useCallback(() => {
        api.get("genre")
            .then((response) => {
                setGenre(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getGenre, [getGenre]);

    const getGenreOptions = () => {
        return genre.map(({ id, id_parent, langs }) => {
            return {
                id_parent,
                value: id,
                label: langs.find(({ lang }) => lang === locale.toUpperCase())
                    ?.name,
            };
        });
    };

    const [weightTypeOptions, setWeightTypeOptions] = useState([]);
    const getWeightType = useCallback(() => {
        api.get(`genre/related/weight-type/${locale}`)
            .then((response) => {
                setWeightTypeOptions(
                    response.map(({ id, name }) => {
                        return {
                            value: parseInt(id, 10),
                            label: `${id} - ${name}`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getWeightType, [getWeightType]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "genre.manage.genre.id"),
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "genre.manage.genre.name"),
            sort: true,
        },
        {
            dataField: "description",
            text: I18n.getTranslation(
                location,
                "genre.manage.genre.description"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "genre_fr",
            isDummyField: true,
            text: I18n.getTranslation(location, "genre.manage.genre.genre_fr"),
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "FR")?.name;
                return name ? (
                    <>
                        <FlagIcon code="fr" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "FR")?.name,
        },
        {
            dataField: "genre_en",
            isDummyField: true,
            text: I18n.getTranslation(location, "genre.manage.genre.genre_en"),
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "EN")?.name;
                return name ? (
                    <>
                        <FlagIcon code="gb" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "EN")?.name,
        },
        {
            dataField: "other_translations",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "genre.manage.genre.other_translations"
            ),
            formatter: (cell, row) => {
                const country = {
                    JA: "jp",
                    KO: "kr",
                };
                const langs = row.langs.filter(
                    ({ lang }) => !["FR", "EN"].includes(lang)
                );

                if (langs.length !== 0) {
                    return langs.map(({ lang, name }) => (
                        <OverlayTrigger
                            key={lang}
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-${lang}`}>{name}</Tooltip>
                            }
                        >
                            <span>
                                <FlagIcon
                                    key={lang}
                                    code={country[lang] ?? lang.toLowerCase()}
                                    className="mr-1"
                                />
                            </span>
                        </OverlayTrigger>
                    ));
                }

                return "-";
            },
            searchable: false,
        },
        {
            dataField: "parent_genre",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "genre.manage.genre.parent_genre"
            ),
            formatter: (cell, row) => {
                return (
                    genre.find(({ id }) => id === row.id_parent)?.name ?? "-"
                );
            },
            searchable: false,
        },
        {
            dataField: "created_at",
            text: I18n.getTranslation(
                location,
                "genre.manage.genre.created_at"
            ),
            searchable: false,
            sort: true,
        },
        {
            dataField: "updated_at",
            text: I18n.getTranslation(
                location,
                "genre.manage.genre.updated_at"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
            sort: true,
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            align: "center",
            text: I18n.getTranslation(location, "genre.manage.genre.action"),
            formatter: (cell, row) => {
                return (
                    <ButtonGroup>
                        <Update
                            genre={row}
                            genreOptions={getGenreOptions()}
                            weightTypeOptions={weightTypeOptions}
                            onSuccess={getGenre}
                        />
                        <Delete id={row.id} onSuccess={getGenre} />
                    </ButtonGroup>
                );
            },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Genre
            {...props}
            genre={genre}
            columns={columns}
            isLoading={isLoading}
            options={options}
            getGenre={getGenre}
            genreOptions={getGenreOptions()}
            weightTypeOptions={weightTypeOptions}
        />
    );
};

export default EnhancedGenre;
