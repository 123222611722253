import React from "react";
import ArtistList from "./ArtistList";
import TitleList from "./TitleList";

const CNMArtistsTitles = ({
    editable,
    isArtistsLoading,
    artists,
    onArtistUpdate,
    onArtistDelete,
    selectedArtist,
    setSelectedArtist,
    isTitlesLoading,
    titles,
    onTitleUpdate,
    onTitleDelete,
}) => {
    return (
        <>
            <ArtistList
                editable={editable}
                isLoading={isArtistsLoading}
                artists={artists}
                selectedArtist={selectedArtist}
                setSelectedArtist={setSelectedArtist}
                onUpdate={onArtistUpdate}
                onDelete={onArtistDelete}
            />
            <TitleList
                editable={editable}
                isLoading={isTitlesLoading}
                titles={titles}
                selectedArtist={selectedArtist}
                onUpdate={onTitleUpdate}
                onDelete={onTitleDelete}
            />
        </>
    );
};

export default CNMArtistsTitles;
