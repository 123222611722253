import React from "react";
import Action from "./Action";
import { useParams, useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import { usePlanningsManagementContext } from "pages/PlanningsManagement/PlanningsManagement.context";

const EnhancedAction = ({ tasks, ...props }) => {
    const { planningId, locale } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { api } = useAPI();
    const isCompleted = tasks.every((task) =>
        task.subtasks.every(({ status }) =>
            [
                "COMPLETED",
                "VALIDATED_WITHOUT_CONTROL",
                "VERIFIED_AND_VALIDATED",
                "ERROR",
            ].includes(status)
        )
    );
    const hasTasks = tasks.length !== 0;
    const { lock } = usePlanningsManagementContext();

    const handleValidate = () => {
        swal({
            title: !isCompleted
                ? I18n.getTranslation(
                      location,
                      "plannings.view.action.validate.swal.notCompleted"
                  )
                : I18n.getTranslation(
                      location,
                      "plannings.view.action.validate.swal.title"
                  ),
            text:
                isCompleted &&
                I18n.getTranslation(
                    location,
                    "plannings.view.action.validate.swal.text"
                ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: !isCompleted
                        ? I18n.getTranslation(
                              location,
                              "utils.sweetalert.close"
                          )
                        : I18n.getTranslation(
                              location,
                              "utils.sweetalert.cancel"
                          ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    visible: isCompleted,
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                api.put(`plannings/${planningId}/completed`, { locale })
                    .then((response) => {
                        toast.success(response.message);
                        navigate(`/${locale}/plannings`);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message);
                    });
            }
        });
    };

    const handleDelete = () => {
        swal({
            title: hasTasks
                ? I18n.getTranslation(
                      location,
                      "plannings.view.action.deleted.swal.tasksExist"
                  )
                : I18n.getTranslation(
                      location,
                      "plannings.view.action.deleted.swal.title"
                  ),
            text:
                !hasTasks &&
                I18n.getTranslation(
                    location,
                    "plannings.view.action.deleted.swal.text"
                ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: hasTasks
                        ? I18n.getTranslation(
                              location,
                              "utils.sweetalert.close"
                          )
                        : I18n.getTranslation(
                              location,
                              "utils.sweetalert.cancel"
                          ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    visible: !hasTasks,
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                api.delete(`plannings/${planningId}`, {
                    locale,
                })
                    .then((response) => {
                        toast.success(response.message);
                        navigate(`/${locale}/plannings`);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message);
                    });
            }
        });
    };

    return (
        <Action
            {...props}
            handleValidate={handleValidate}
            handleDelete={handleDelete}
            isPlanningLock={lock}
        />
    );
};

export default EnhancedAction;
