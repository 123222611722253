import React from "react";
import UserRightManagement from "./UserRightManagement";
import { useLocation } from "react-router";

const EnhancedUserRightManagement = (props) => {
    const location = useLocation();
    return <UserRightManagement {...props} location={location} />;
};

export default EnhancedUserRightManagement;
