import React from "react";
import RecordingSelect from "shared/components/RecordingSelect";

const RecordingMatcher = ({
    artist,
    recording,
    locale,
    defaultSearch,
    onMatch,
    onUnmatch,
}) => {
    return (
        <RecordingSelect
            id={recording?.id}
            title={recording?.name}
            artistIds={[artist?.id]}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={onUnmatch}
            menuPlacement="auto"
            enablePortal
            isDisabled={!artist && !recording}
            defaultSearch={defaultSearch}
        />
    );
};

export default RecordingMatcher;
