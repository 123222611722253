import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import CategoryFilter from "./Filter/CategoryFilter";
import clsx from "clsx";
import styles from "./Planning.module.css";

const Planning = ({
    planning,
    tasks,
    categoryOptions,
    isLoading,
    columns,
    location,
    filter,
    setFilter,
}) => {
    const { SearchBar } = Search;
    return (
        <ToolkitProvider keyField="id" data={tasks} columns={columns} search>
            {(toolkitprops) => (
                <Widget
                    title={`plannings.client.status`}
                    titleArgs={{ status: planning.status }}
                    isLoading={isLoading}
                >
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-3">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "utils.select.placeholder"
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <CategoryFilter
                                    value={filter.category}
                                    setFilter={setFilter}
                                    filterOptions={categoryOptions}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body
                        className={clsx(
                            "widget-table-overflow overflow-auto border-top",
                            styles.body
                        )}
                    >
                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            hover
                            striped
                            bordered={false}
                            noDataIndication={
                                <I18n t="plannings.client.tasks.empty" />
                            }
                            classes="table-header-fixed table-layout-auto mb-0"
                        />
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Planning;
