import React from "react";
import I18n from "shared/lib/I18n";
import { Button } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import InputSelect from "shared/components/Form/InputSelect";
import InputDate from "shared/components/Form/InputDate";
import moment from "moment";
import {
    MAIN_CHARTS,
    ALL_CHARTS,
    EDITORS_PICK,
    HIT,
} from "../RecordingCharts.constants";
import "./Data.css";

const Data = ({
    type,
    recordings,
    columns,
    expandRow,
    sortOption,
    location,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
    reset,
}) => {
    const { SearchBar } = Search;
    return (
        <div className="artist-view-recording-charts-data">
            <ToolkitProvider
                keyField="id"
                data={recordings}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <>
                        <div className="row mx-0 my-3">
                            {[MAIN_CHARTS, ALL_CHARTS].includes(type) && (
                                <>
                                    <div className="col-3">
                                        <SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "artist.view.chartsRecordingCharts.title"
                                            )}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <InputSelect
                                            value={selectedFilterOptions.chart}
                                            onChange={(opt) => {
                                                setSelectedFilterOptions(
                                                    (curr) => ({
                                                        ...curr,
                                                        chart: opt ?? null,
                                                    })
                                                );
                                            }}
                                            placeholder={
                                                <I18n t="artist.view.chartsRecordingCharts.chart" />
                                            }
                                            options={filterOptions.charts}
                                            enablePortal
                                        />
                                    </div>
                                    <div className="col-2">
                                        <InputSelect
                                            value={
                                                selectedFilterOptions.country
                                            }
                                            onChange={(opt) => {
                                                setSelectedFilterOptions(
                                                    (curr) => ({
                                                        ...curr,
                                                        country: opt ?? null,
                                                    })
                                                );
                                            }}
                                            placeholder={
                                                <I18n t="artist.view.chartsRecordingCharts.country" />
                                            }
                                            options={filterOptions.country}
                                            enablePortal
                                        />
                                    </div>
                                    <div className="col-2">
                                        <div className="d-flex">
                                            <InputDate
                                                placeholder="artist.view.chartsRecordingCharts.year"
                                                selected={
                                                    selectedFilterOptions.year
                                                        ? new Date(
                                                              selectedFilterOptions.year,
                                                              0
                                                          )
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    setSelectedFilterOptions(
                                                        (curr) => ({
                                                            ...curr,
                                                            year: date
                                                                ? moment(
                                                                      date
                                                                  ).format(
                                                                      "YYYY"
                                                                  )
                                                                : null,
                                                        })
                                                    );
                                                }}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                isClearable
                                            />
                                            <InputSelect
                                                className="ml-2 w-50"
                                                value={{
                                                    label: selectedFilterOptions.mode,
                                                    value: selectedFilterOptions.mode,
                                                }}
                                                onChange={(opt) => {
                                                    setSelectedFilterOptions(
                                                        (curr) => ({
                                                            ...curr,
                                                            mode: opt
                                                                ? opt.value
                                                                : null,
                                                        })
                                                    );
                                                }}
                                                options={[
                                                    {
                                                        label: "<",
                                                        value: "<",
                                                    },
                                                    {
                                                        label: "=",
                                                        value: "=",
                                                    },
                                                    {
                                                        label: ">",
                                                        value: ">",
                                                    },
                                                ]}
                                                isClearable={false}
                                                enablePortal
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {[HIT].includes(type) && (
                                <div className="col-10">
                                    <strong>
                                        <I18n t="artist.view.chartsRecordingCharts.hit.title" />
                                        <span className="mx-1">:</span>
                                    </strong>
                                    <I18n t="artist.view.chartsRecordingCharts.hit.description" />
                                </div>
                            )}
                            {[EDITORS_PICK].includes(type) && (
                                <div className="col-10">
                                    <strong>
                                        <I18n t="artist.view.chartsRecordingCharts.editorial_pick.title" />
                                        <span className="mx-1">:</span>
                                    </strong>
                                    <I18n t="artist.view.chartsRecordingCharts.editorial_pick.description" />
                                </div>
                            )}
                            <div className="col-2">
                                <Button
                                    variant="secondary"
                                    className="w-100"
                                    onClick={async () => {
                                        await toolkitprops.searchProps.onClear();
                                        reset();
                                    }}
                                >
                                    {[MAIN_CHARTS, ALL_CHARTS].includes(
                                        type
                                    ) ? (
                                        <I18n t="artist.view.chartsRecordingCharts.resetCharts" />
                                    ) : (
                                        <I18n t="artist.view.chartsRecordingCharts.resetEditorsPick" />
                                    )}
                                </Button>
                            </div>
                        </div>

                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            condensed
                            hover
                            bordered={false}
                            noDataIndication={
                                <I18n t="artist.view.chartsRecordingCharts.empty" />
                            }
                            classes="table-layout-auto mb-0"
                            expandRow={expandRow}
                            sort={sortOption}
                        />
                    </>
                )}
            </ToolkitProvider>
        </div>
    );
};

export default Data;
