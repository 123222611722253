import React, { useState, useEffect, useCallback } from "react";
import LyricsInformation from "./LyricsInformation";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedLyricsInformation = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { recordingId } = useIntParams();
    const [state, setState] = useState({
        lyric: {},
        isLoading: true,
    });

    const getLyric = useCallback(() => {
        return api
            .get(`recording/${recordingId}/lyric`)
            .then((response) => {
                setState((prev) => ({ ...prev, lyric: response }));

                if (Object.values(response).length === 0) {
                    setNoData("lyricsInformation");
                } else if (process.env.NODE_ENV === "production") {
                    const territory = `${
                        navigator.language || navigator.userLanguage || "fr-FR"
                    }`
                        .split("-")
                        .pop()
                        .toUpperCase();

                    api.post(
                        "report/lyricfind/usage",
                        {},
                        {
                            territory:
                                territory.length === 2 ? territory : "FR",
                            trackid: `lfid:${
                                response.lfid
                            },trackname:${response.title?.replace(
                                /,|;/gi,
                                " "
                            )},artistname:${response.artist?.replace(
                                /,|;/gi,
                                " "
                            )}`,
                        }
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [recordingId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getLyric()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getLyric]);

    return <LyricsInformation {...props} {...state} />;
};

export default EnhancedLyricsInformation;
