const dateRegex = /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/;

export const getYear = (date) => {
    if (!date) {
        return;
    }

    return date.split("-")[0];
};

export const formatAndCleanDate = (date) => {
    if (!date) {
        return;
    }

    return date
        .split("-")
        .filter((r) => r !== "00")
        .join("-");
};

export const displayAsString = (date, locale) => {
    if (!date) {
        return;
    }

    const newDateFiltered = date.split("-").filter((r) => r !== "00");
    const newDateJoined = newDateFiltered.join("-");

    let options = { year: "numeric", timeZone: "utc" };

    if (newDateFiltered.length >= 2) {
        options.month = "long";
    }

    if (newDateFiltered.length >= 3) {
        options.day = "numeric";
    }

    return new Date(newDateJoined).toLocaleDateString(locale, options);
};

export const isValidDate = (date) => {
    if (date) {
        if (!date.match(dateRegex)) return false;

        const splitDate = date.split("-");
        if (splitDate.length != 3) return false;
        const [year, month, day] = splitDate;

        if (year < 1000) return false;
        if (month > 12) return false;
        if (day > 31) return false;

        if (month == 0 || day == 0) {
            if (month == 0 && day != 0) return false;
        } else {
            const dateObj = new Date(date);
            if (isNaN(dateObj.getTime())) return false;
            if (dateObj.getUTCFullYear() !== parseInt(year)) return false;
            if (dateObj.getUTCMonth() + 1 !== parseInt(month)) return false;
            if (dateObj.getUTCDate() !== parseInt(day)) return false;
        }

        return true;
    }
    return false;
};

export const isValidDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
        if (!isValidDate(startDate) || !isValidDate(endDate)) return false;

        const splitStartDate = startDate.split("-");
        const splitEndDate = endDate.split("-");

        if (splitStartDate[0] > splitEndDate[0]) return false;
        if (
            splitStartDate[0] === splitEndDate[0] &&
            splitStartDate[1] > splitEndDate[1]
        ) {
            return false;
        }
        if (
            splitStartDate[0] === splitEndDate[0] &&
            splitStartDate[1] === splitEndDate[1] &&
            splitStartDate[2] > splitEndDate[2]
        ) {
            return false;
        }

        return true;
    }
    return false;
};
