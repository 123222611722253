import React from "react";
import ArtistSelect from "shared/components/ArtistSelect";

const ArtistMatcher = ({
    artist,
    locale,
    defaultSearch,
    onMatch,
    onUnmatch,
}) => {
    return (
        <ArtistSelect
            id={artist?.id}
            name={artist?.name}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={onUnmatch}
            menuPlacement="auto"
            enablePortal
            defaultSearch={defaultSearch}
        />
    );
};

export default ArtistMatcher;
