import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";

const Tracks = ({ tracks, columns }) => {
    return (
        <Widget title="moodmedia.search.results">
            <Widget.Body className="widget-table-overflow">
                <BootstrapTable
                    keyField="ms_track_id"
                    data={tracks}
                    columns={columns}
                    bootstrap4
                    hover
                />
            </Widget.Body>
        </Widget>
    );
};

export default Tracks;
