import React from "react";
import { NavLink } from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

const RecordingListAction = ({
    user,
    artistId,
    albumId,
    releaseId,
    trackId,
    recordingId,
}) => {
    return (
        <>
            <NavLink
                to={`/recording/${recordingId}/view`}
                className="btn btn-primary btn-sm mr-1"
            >
                <FontAwesomeIcon icon={faEye} size="lg" fixedWidth />
            </NavLink>
            {user.hasRight("artist.manage.information") && (
                <NavLink
                    to={`/artist/${artistId}/edit/discography/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}`}
                    className="btn btn-primary btn-sm mr-1"
                >
                    <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
                </NavLink>
            )}
        </>
    );
};

export default RecordingListAction;
