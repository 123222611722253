import React from "react";
import InputWysiwyg from "./InputWysiwyg";
import I18n from "shared/lib/I18n";

const EnhancedInputWysiwyg = ({ location, ...props }) => {
    const config = {
        display: ["INLINE_STYLE_BUTTONS", "HISTORY_BUTTONS"],
        INLINE_STYLE_BUTTONS: [
            {
                label: I18n.getTranslation(location, `utils.form.wysiwyg.bold`),
                style: "BOLD",
            },
            {
                label: I18n.getTranslation(
                    location,
                    `utils.form.wysiwyg.italic`
                ),
                style: "ITALIC",
            },
            {
                label: I18n.getTranslation(
                    location,
                    `utils.form.wysiwyg.underline`
                ),
                style: "UNDERLINE",
            },
            {
                label: I18n.getTranslation(
                    location,
                    `utils.form.wysiwyg.strikethrough`
                ),
                style: "STRIKETHROUGH",
            },
        ],
    };

    return <InputWysiwyg config={config} {...props} />;
};

export default EnhancedInputWysiwyg;
