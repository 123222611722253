import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import CNMArtistSelect from "./CNMArtistSelect";

const EnhancedCNMArtistSelect = ({ year, locale, ...props }) => {
    const { api } = useAPI();
    const loadOptions = useDebouncedCallback((term, callback) => {
        api.get(`cnm/consolidation/artist/${year}`, {
            term,
            locale,
        })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    return <CNMArtistSelect loadOptions={loadOptions} {...props} />;
};

export default EnhancedCNMArtistSelect;
