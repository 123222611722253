import React from "react";
import Error from "shared/components/Form/Error";
import { InputGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import styles from "./InputPassword.module.css";

const InputPassword = React.forwardRef(
    (
        {
            className,
            error,
            type,
            switchType,
            showEye = false,
            showError = true,
            ...props
        },
        ref
    ) => {
        return (
            <>
                <InputGroup>
                    <input
                        type={type}
                        className={`form-control form-control ${
                            error && "is-invalid"
                        } ${className}`}
                        ref={ref}
                        {...props}
                    />
                    {showEye && (
                        <InputGroup.Append>
                            <Button
                                className={clsx(styles.eye)}
                                variant="outline-secondary"
                                type="button"
                                onClick={switchType}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        type === "password" ? faEyeSlash : faEye
                                    }
                                    fixedWidth
                                />
                            </Button>
                        </InputGroup.Append>
                    )}
                </InputGroup>

                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputPassword;
