import React from "react";
import clsx from "clsx";
import Error from "shared/components/Form/Error";
import "./InputSwitch.css";

const InputSwitch = React.forwardRef(
    ({ label, error, disabled, showError = true, ...props }, ref) => {
        return (
            <>
                <div
                    className={clsx(
                        "custom-control custom-switch custom-switch-md",
                        disabled && "disabled"
                    )}
                >
                    <label>
                        <input
                            className={clsx(
                                "custom-control-input",
                                error && "is-invalid"
                            )}
                            type="checkbox"
                            ref={ref}
                            disabled={disabled}
                            {...props}
                        />
                        <span className="custom-control-label">{label}</span>
                    </label>
                </div>
                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputSwitch;
