import React from "react";
import { Modal, Button, Form, InputGroup, Col } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import InputTextArea from "shared/components/Form/InputTextArea";
import FlagIcon from "shared/functions/FlagIcon";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const CreateOrUpdateModal = ({
    genre,
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    errors,
    isSubmitting,
    control,
    genreOptions,
    weightTypeOptions,
    fields,
    append,
    remove,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`genre.manage.${genre ? "update" : "create"}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="genre.manage.genre.parent_genre" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="parent_genre"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? genreOptions.find(
                                                  ({ value: v }) => v === value
                                              )
                                            : null
                                    }
                                    options={genreOptions.filter(
                                        ({ id_parent }) => id_parent === 0
                                    )}
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : "");
                                    }}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "genre.manage.genre.parent_genre_placeholder"
                                    )}
                                    error={
                                        errors.parent_genre && [
                                            I18n.getTranslation(
                                                location,
                                                errors.parent_genre.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="genre.manage.genre.description" />
                        </Form.Label>
                        <InputTextArea
                            {...register("description")}
                            className="mt-1"
                            error={
                                errors.description && [
                                    I18n.getTranslation(
                                        location,
                                        errors.description.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="fr" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_fr")}
                                error={
                                    errors.genre_fr && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_fr.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="gb" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_en")}
                                error={
                                    errors.genre_en && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_en.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="jp" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_ja")}
                                error={
                                    errors.genre_ja && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_ja.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="de" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_de")}
                                error={
                                    errors.genre_de && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_de.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="kr" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_ko")}
                                error={
                                    errors.genre_ko && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_ko.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="pt" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_pt")}
                                error={
                                    errors.genre_pt && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_pt.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="es" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_es")}
                                error={
                                    errors.genre_es && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_es.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="us" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("genre_us")}
                                error={
                                    errors.genre_us && [
                                        I18n.getTranslation(
                                            location,
                                            errors.genre_us.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <hr />
                    <Form.Group>
                        <div className="d-flex justify-content-between">
                            <Form.Label>
                                <I18n t="genre.manage.genre.related" />
                            </Form.Label>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    append({
                                        id_related_genre: null,
                                        weight: null,
                                    })
                                }
                            >
                                <I18n t={`utils.button.add`} />
                            </Button>
                        </div>
                        {fields.map((item, index) => (
                            <Form.Row key={item.id} className="mt-2">
                                <div className="col">
                                    <Controller
                                        control={control}
                                        name={`related.${index}.id_related_genre`}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <InputSelect
                                                innerRef={ref}
                                                value={
                                                    value !== null
                                                        ? genreOptions.find(
                                                              ({ value: v }) =>
                                                                  v === value
                                                          )
                                                        : null
                                                }
                                                options={genreOptions}
                                                onChange={(opt) => {
                                                    onChange(
                                                        opt ? opt.value : null
                                                    );
                                                }}
                                                placeholder={
                                                    <I18n t="genre.manage.genre.genre" />
                                                }
                                                error={
                                                    errors?.related?.[index]
                                                        ?.id_related_genre && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.related[
                                                                index
                                                            ].id_related_genre
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                isClearable={false}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        control={control}
                                        name={`related.${index}.weight`}
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <InputSelect
                                                innerRef={ref}
                                                value={
                                                    value !== null
                                                        ? weightTypeOptions.find(
                                                              ({ value: v }) =>
                                                                  v === value
                                                          )
                                                        : null
                                                }
                                                options={weightTypeOptions}
                                                onChange={(opt) => {
                                                    onChange(
                                                        opt ? opt.value : null
                                                    );
                                                }}
                                                placeholder={
                                                    <I18n t="genre.manage.genre.type" />
                                                }
                                                error={
                                                    errors?.related?.[index]
                                                        ?.weight && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.related[
                                                                index
                                                            ].weight.message
                                                        ),
                                                    ]
                                                }
                                                isClearable={false}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-auto">
                                    <Button
                                        variant="danger"
                                        onClick={() => remove(index)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            fixedWidth
                                        />
                                    </Button>
                                </div>
                            </Form.Row>
                        ))}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
