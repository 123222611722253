import React from "react";
import ChangePassword from "./ChangePassword";
import useAPI from "shared/hooks/useApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const schema = yup.object().shape({
    old_password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    new_password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required")
        .matches(/^(?=.*[A-Z]).+$/, "utils.form.must_contain_uppercase")
        .matches(/^(?=.*[a-z]).+$/, "utils.form.must_contain_lowercase")
        .matches(/^.{8,}$/, "utils.form.must_contain_8_characters")
        .matches(/^(?=.*\d).+$/, "utils.form.must_contain_digit")
        .matches(/^(?=.*[a-zA-Z]).+$/, "utils.form.must_contain_letter"),
    confirm_password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required")
        .oneOf(
            [yup.ref("new_password"), null],
            "utils.form.passwords_must_match"
        ),
});

const defaultValues = {
    old_password: null,
    new_password: null,
    confirm_password: null,
};

const EnhancedChangePassword = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const {
        handleSubmit,
        register,
        control,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onSubmit = (data) => {
        return api
            .put(`user/changepassword`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "profile.change_password.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <ChangePassword
            {...props}
            location={location}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
        />
    );
};

export default EnhancedChangePassword;
