import React from "react";
import Lyric from "./Lyric";
import Ratings from "./Ratings";
import Summary from "./Summary";
import I18n from "shared/lib/I18n";
import "./Assessment.css";

const Assessment = ({
    assessments,
    getAssessments,
    lineFilter,
    setLineFilter,
    activeLines,
    setActiveLines,
    ...props
}) => {
    const has_global_error = {
        assessment: assessments.assessment.summary.some(
            ({ is_global, value }) => is_global && value === 0
        ),
        counter_assessment: assessments.counter_assessment.summary.some(
            ({ is_global, value }) => is_global && value === 0
        ),
    };
    const variant = {
        assessment: has_global_error.assessment ? "danger" : "info",
        counter_assessment: has_global_error.counter_assessment
            ? "warning"
            : "primary",
    };

    return (
        <>
            <div className="apple__sample__assessment__summary__container">
                {assessments.counter_assessment.summary.length > 0 && (
                    <Summary
                        has_global_error={has_global_error.counter_assessment}
                        variant={variant.counter_assessment}
                        summary={assessments.counter_assessment.summary}
                    />
                )}
                {assessments.assessment.summary.length > 0 && (
                    <Summary
                        has_global_error={has_global_error.assessment}
                        variant={variant.assessment}
                        summary={assessments.assessment.summary}
                    />
                )}
            </div>
            {assessments.counter_assessment.lines.length > 0 && (
                <Ratings
                    title={
                        <I18n t="apple.sample.assessment.ratingType.counterAssessment" />
                    }
                    variant={variant.counter_assessment}
                    assessor={assessments.counter_assessment.assessor}
                    assessments={assessments.counter_assessment.lines}
                    getAssessments={getAssessments}
                    lineFilter={lineFilter}
                    setLineFilter={setLineFilter}
                    activeLines={activeLines}
                    gridPosition="left"
                />
            )}
            <Lyric
                assessments={[
                    ...assessments.assessment.lines,
                    ...assessments.counter_assessment.lines,
                ]}
                colGrid={
                    assessments.counter_assessment.lines.length > 0 &&
                    assessments.assessment.lines.length > 0
                        ? "3"
                        : assessments.counter_assessment.lines.length > 0 &&
                          assessments.assessment.lines.length === 0
                        ? "2-left"
                        : assessments.counter_assessment.lines.length === 0 &&
                          assessments.assessment.lines.length > 0
                        ? "2-right"
                        : "1"
                }
                getAssessments={getAssessments}
                setLineFilter={setLineFilter}
                activeLines={activeLines}
                setActiveLines={setActiveLines}
                {...props}
            />
            {assessments.assessment.lines.length > 0 && (
                <Ratings
                    title={
                        <I18n t="apple.sample.assessment.ratingType.assessment" />
                    }
                    variant={variant.assessment}
                    assessor={assessments.assessment.assessor}
                    assessments={assessments.assessment.lines}
                    getAssessments={getAssessments}
                    lineFilter={lineFilter}
                    setLineFilter={setLineFilter}
                    activeLines={activeLines}
                    gridPosition="right"
                />
            )}
        </>
    );
};

export default Assessment;
