import React, { useRef } from "react";
import InputAsyncSelect from "./InputAsyncSelect";

const EnhancedInputAsyncSelect = ({ defaultSearch, ...props }) => {
    const selectRef = useRef(null);
    const onFocus = () => {
        if (
            defaultSearch &&
            selectRef.current !== null &&
            !selectRef.current.commonProps.hasValue
        ) {
            selectRef.current.onInputChange(defaultSearch, {
                prevInputValue: "",
                action: "set-value",
            });
        }
    };

    return (
        <InputAsyncSelect selectRef={selectRef} onFocus={onFocus} {...props} />
    );
};

export default EnhancedInputAsyncSelect;
