import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import PartnerTracks from "./PartnerTracks";

const EnhancedPartnerTracks = (props) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "disc_number",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.partnerTracks.no_cd"
            ),
            headerStyle: { width: "2em" },
        },
        {
            dataField: "track_number",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.partnerTracks.no_piste"
            ),
            // Sort by disc and track number
            sort: true,
            sortValue: (cell, row) => ({
                disc: row.disc_number,
                track: row.track_number,
            }),
            sortFunc: (a, b, order, dataField) =>
                a.disc === b.disc
                    ? (order === "asc" ? 1 : -1) * (a.track - b.track)
                    : (order === "asc" ? 1 : -1) * (a.disc - b.disc),
            headerStyle: { width: "3em" },
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.partnerTracks.isrc"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
            headerStyle: { width: "75%" },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.partnerTracks.title"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
            headerStyle: { width: "100%" },
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.partnerTracks.duration"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: { width: "5em" },
        },
    ];

    const defaultSorted = [
        {
            dataField: "track_number",
            order: "asc",
        },
    ];

    return (
        <PartnerTracks
            {...props}
            columns={columns}
            defaultSorted={defaultSorted}
        />
    );
};

export default EnhancedPartnerTracks;
