import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import PictureTagsModal from "shared/components/PictureTagsModal";
import PictureCropModal from "shared/components/PictureCropModal";

const Action = ({
    id_picture,
    id_picture_original,
    id_version,
    hasTags,
    showTags,
    handleCloseTags,
    handleShowTags,
    onSuccesTags,
    showCrop,
    handleCloseCrop,
    handleShowCrop,
    onSuccesCrop,
    showTagsVersion,
    handleCloseTagsVersion,
    onSuccesTagsVersion,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={hasTags ? handleShowCrop : handleShowTags}
            >
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </Button>

            <PictureTagsModal
                id_picture={id_picture}
                id_picture_version={id_picture_original}
                show={showTags}
                handleHide={handleCloseTags}
                onSuccess={onSuccesTags}
            />

            <PictureCropModal
                id_picture={id_picture}
                show={showCrop}
                handleHide={handleCloseCrop}
                onSuccess={onSuccesCrop}
            />

            <PictureTagsModal
                id_picture={id_picture}
                id_picture_version={id_version}
                show={showTagsVersion}
                handleHide={handleCloseTagsVersion}
                onSuccess={onSuccesTagsVersion}
                isVersion
            />
        </>
    );
};

export default Action;
