import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Show = ({ users, onClick, location }) => {
    return (
        <>
            <Button
                variant={users.length !== 0 ? "primary" : "outline-primary"}
                onClick={onClick}
                title={I18n.getTranslation(
                    location,
                    "labelPromo.manage.show.title"
                )}
            >
                <FontAwesomeIcon icon={faEye} size="lg" />
            </Button>
        </>
    );
};

export default Show;
