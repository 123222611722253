import React from "react";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const DiscardReasonSelect = ({
    control,
    name,
    error,
    options,
    disabled,
    location,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                    innerRef={ref}
                    name={name}
                    error={error}
                    isDisabled={disabled}
                    options={options}
                    value={
                        value
                            ? {
                                  value: value,
                                  label: value,
                              }
                            : null
                    }
                    onChange={(option, action) => {
                        if (action.action === "select-option") {
                            onChange(option.value);
                        }
                    }}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.discardReason.placeholder"
                    )}
                    menuPlacement="auto"
                    isClearable={false}
                    enablePortal
                />
            )}
        />
    );
};

export default DiscardReasonSelect;
