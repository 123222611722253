import React, { useState, useEffect, useCallback, useContext } from "react";
import Names from "./Names";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import {
    ArtistManagementContext,
    NAMES_UP_TO_DATE,
} from "pages/ArtistManagement";

const schema = yup.object().shape({
    names: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().trim().required("utils.form.required"),
                language: yup.string().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedNames = (props) => {
    const { dispatchArtist, namesUpToDate } = useContext(
        ArtistManagementContext
    );
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            names: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "names",
    });

    const getNames = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/names`)
            .then((response) => {
                if (!cancelled) {
                    reset({ names: response });
                    dispatchArtist({
                        type: NAMES_UP_TO_DATE,
                        data: true,
                    });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getNames, [getNames]);

    useEffect(() => {
        if (!namesUpToDate) {
            getNames();
        }
    }, [namesUpToDate]);

    const [langIsoOptions, setLangIsoOptions] = useState([]);
    const getLangIso = useCallback(() => {
        api.get(`lang-iso`, { locale })
            .then((response) => {
                setLangIsoOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getLangIso, [getLangIso]);

    const onSubmit = (data) => {
        return api
            .post(`artist/${artistId}/names`, { locale }, data.names)
            .then((response) => {
                reset({ names: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.names.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.names.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Names
            {...props}
            getNames={getNames}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
            langIsoOptions={langIsoOptions}
        />
    );
};

export default EnhancedNames;
