import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import CreateOrUpdateModal from "pages/AuthorManagement/Author/Form/CreateOrUpdateModal";

const Create = ({ show, handleClose, handleShow, onSuccess, location }) => {
    return (
        <>
            <Button
                variant="success"
                block
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "author.manage.create.title"
                )}
            >
                <I18n t="utils.button.add" />
            </Button>

            <CreateOrUpdateModal
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
            />
        </>
    );
};

export default Create;
