import React from "react";
import ProcessingGallery from "./ProcessingGallery";
import PictureCard from "shared/components/PictureCard";
import ArtistLink from "shared/components/ArtistLink";
import I18n from "shared/lib/I18n";
import Action from "./Action";
import { useLocation } from "react-router";

const EnhancedProcessingGallery = ({ getGalleries, ...props }) => {
    const location = useLocation();
    const columns = [
        {
            dataField: "url",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.picture"
            ),
            formatter: (cell) => {
                return <PictureCard url={cell} />;
            },
            headerStyle: {
                width: "13rem",
            },
            searchable: false,
        },
        {
            dataField: "ms_artist",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.artist"
            ),
            formatter: (cell) => {
                return (
                    <ArtistLink artistId={cell.id} content={cell.name} blank />
                );
            },
            filterValue: (cell) => cell.name,
        },
        {
            dataField: "label",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.label"
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.title"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "copyright",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.copyright"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "date_takedown",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.date_takedown"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "action",
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.action"
            ),
            formatter: (cell, row) => {
                return (
                    <Action
                        id_picture={row.id_picture}
                        id_picture_original={row.id_picture_original}
                        id_version={row.id_version}
                        hasTags={row.has_tags}
                        onSuccess={getGalleries}
                    />
                );
            },
        },
    ];

    return <ProcessingGallery {...props} columns={columns} />;
};

export default EnhancedProcessingGallery;
