import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router";
import Bio from "./Bio";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RichTextEditor from "react-rte";
import swal from "sweetalert";

const schema = yup.object().shape({
    id_author: yup.number().nullable().required("utils.form.required"),
});

const EnhancedBio = ({ lang, updateBiographyLangStatus, ...props }) => {
    const { api } = useAPI();
    const [biography, setBiography] = useState(
        RichTextEditor.createEmptyValue()
    );
    const [isReference, setIsReference] = useState(false);
    const [isOfficial, setIsOfficial] = useState(false);
    const [isTranslation, setIsTranslation] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [toTranslate, setToTranslate] = useState(false);
    const { locale } = useParams();
    const { artistId } = useIntParams();
    const location = useLocation();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            id_author: null,
        },
    });

    const updateBiography = (biography, isTranslation = 0) => {
        if (biography) {
            setBiography(
                RichTextEditor.createValueFromString(biography, "html")
            );
            setIsTranslation(isTranslation);
        } else {
            setBiography(RichTextEditor.createEmptyValue());
        }
    };

    const getBiography = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/biography/${lang}`)
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsReference(response.is_reference || false);
                    setIsOfficial(response.is_official || false);
                    updateBiography(response.biography);
                    updateBiographyLangStatus?.({
                        lang,
                        hasBiography: !!response.biography,
                        isReference: response.is_reference || false,
                        isOfficial: response.is_official || false,
                    });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, lang]);

    useEffect(getBiography, [getBiography]);

    const [authorOptions, setAuthorOptions] = useState([]);
    const getBiographyAuthor = useCallback(() => {
        api.get(`artist/${artistId}/biography/${lang}/author`)
            .then((response) => {
                setAuthorOptions(
                    response.map(
                        ({ id, firstname, lastname, signature, date_maj }) => {
                            const fullName = [firstname, lastname].filter(
                                Boolean
                            );
                            const label = [
                                fullName.join(" "),
                                signature,
                                date_maj,
                            ].filter(Boolean);

                            return {
                                value: id,
                                label: label.join(" - "),
                            };
                        }
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, lang]);

    useEffect(getBiographyAuthor, [getBiographyAuthor]);

    const onSubmit = (data) => {
        const convertedBiography = biography.toString("html");
        const expandedData = {
            ...data,
            biography:
                convertedBiography !== "<p><br></p>"
                    ? convertedBiography
                    : null,
        };
        const postData = () => {
            return api
                .post(
                    `artist/${artistId}/biography/${lang}`,
                    { locale, isTranslation },
                    expandedData
                )
                .then((response) => {
                    reset(response);
                    setIsReference(response.is_reference || false);
                    setIsOfficial(response.is_official || false);
                    updateBiographyLangStatus?.({
                        lang,
                        hasBiography: !!response?.biography,
                        isReference: response.is_reference || false,
                        isOfficial: response.is_official || false,
                    });
                    getBiographyAuthor();
                    setIsTranslation(0);
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.management.bio.update.success`
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.management.bio.update.error`,
                            error.message
                        )
                    );
                });
        };

        if (data.biography && !isTranslation && !isReference && !isOfficial) {
            return swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.bio.update.alert.title"
                ),
                text: I18n.getTranslation(
                    location,
                    "artist.management.bio.update.alert.text"
                ),
                icon: "warning",
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    return;
                }

                return postData();
            });
        } else {
            return postData();
        }
    };

    return (
        <Bio
            biography={biography}
            setBiography={setBiography}
            getBiography={getBiography}
            lang={lang}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            authorOptions={authorOptions}
            location={location}
            getValues={getValues}
            updateBiography={updateBiography}
            isReference={isReference}
            isOfficial={isOfficial}
            toTranslate={toTranslate}
            setToTranslate={setToTranslate}
            {...props}
        />
    );
};

export default EnhancedBio;
