import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputTextArea from "shared/components/Form/InputTextArea";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputSwitch from "shared/components/Form/InputSwitch";
import I18n from "shared/lib/I18n";
import ISRCReference from "./ISRCReference";
import ISRCSelect from "./ISRCSelect";
import GenreSelect from "./GenreSelect";
import GenreReference from "./GenreReference";
import LeadGenderSelect from "./LeadGenderSelect";
import VoiceSelect from "./VoiceSelect";
import AgeType from "./AgeType";
import PublicationDateReference from "./PublicationDateReference";
import LabelSelect from "./LabelSelect";
import LabelReference from "./LabelReference";
import DistributorSelect from "./DistributorSelect";
import DistributorReference from "./DistributorReference";
import CSAArtistSelect from "./CSAArtistSelect";
import LevelOfDevelopment from "./LevelOfDevelopment";
import LanguageSelect from "./LanguageSelect";
import LyricfindLanguageReference from "./LyricfindLanguageReference";
import PlatformsLegend from "./PlatformsLegend";
import PlatformTitles from "./PlatformTitles";
import CNMArtistSelect from "./CNMArtistSelect";
import MsArtistsReference from "./MsArtistsReference/MsArtistsReference";
import DiscardReasonSelect from "./DiscardReasonSelect";
import "./Edit.css";
import CountrySelect from "./CountrySelect";

const Edit = ({
    isLoading,
    reference,
    year,
    chartType,
    titleId,
    errors,
    register,
    control,
    setValue,
    isSubmitting,
    onReset,
    onSubmit,
    location,
    locale,
    disabled,
    artistDisabled,
    onCnmArtistChange,
    showAggregationLink,
    reported,
    discarded,
}) => {
    return (
        <Form className="vertical-shrink" onSubmit={onSubmit}>
            <Widget
                title="cnm.report.consolidate.edit.head"
                className="vertical-shrink mb-0"
                actions={<PlatformsLegend platforms={reference.platforms} />}
            >
                <Widget.Body className="cnm-consolidation-form overflow-auto">
                    {isLoading && (
                        <div className="overlay">
                            <Loader
                                className="h-100 align-items-center"
                                size="3x"
                            />
                        </div>
                    )}

                    <header className="d-flex justify-content-between mb-0">
                        <I18n t="cnm.report.consolidate.edit.header.platforms" />
                        {showAggregationLink && (
                            <Link
                                to={`/cnm/aggregation/${year}/${chartType}/${titleId}`}
                                className="ml-2"
                            >
                                <I18n t="cnm.report.consolidate.edit.header.openInAggregation" />
                            </Link>
                        )}
                    </header>
                    <div className="widget-table-overflow mb-0">
                        <PlatformTitles
                            titles={reference?.platformTitles ?? []}
                        />
                    </div>

                    <header className="d-flex justify-content-between">
                        <I18n t="cnm.report.consolidate.edit.header.artist" />
                        {!disabled && (
                            <span className="ml-2 font-italic">
                                <I18n t="cnm.report.consolidate.edit.header.artist_warning" />
                            </span>
                        )}
                    </header>
                    <div className="form-row">
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.cnmArtist.label" />
                                    <CNMArtistSelect
                                        year={year}
                                        defaultName={
                                            reference?.artistName ?? ""
                                        }
                                        control={control}
                                        setValue={setValue}
                                        onCnmArtistChange={onCnmArtistChange}
                                        name="artist.cnmArtist"
                                        error={
                                            errors?.artist?.cnmArtist && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.artist.cnmArtist
                                                        .message
                                                ),
                                            ]
                                        }
                                        locale={locale}
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.msArtists.label" />
                                    <MsArtistsReference
                                        artists={reference?.msArtists ?? []}
                                        locale={locale}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.csaArtist.label" />
                                    <CSAArtistSelect
                                        year={year}
                                        control={control}
                                        name="artist.csaArtist"
                                        locale={locale}
                                        location={location}
                                        disabled={artistDisabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.levelOfDevelopment.label" />
                                    <LevelOfDevelopment
                                        control={control}
                                        location={location}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-2">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.leadGender.label" />
                                    <LeadGenderSelect
                                        control={control}
                                        name="artist.leadGender"
                                        error={
                                            errors?.artist?.leadGender && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.artist.leadGender
                                                        .message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={artistDisabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.countries.label" />
                                    <CountrySelect
                                        control={control}
                                        name="artist.countries"
                                        error={
                                            errors?.artist?.countries && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.artist.countries
                                                        .message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={artistDisabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <header>
                        <I18n t="cnm.report.consolidate.edit.header.title" />
                    </header>
                    <div className="form-row">
                        <div className="col-lg-4">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.title.label" />
                                    <InputText
                                        {...register("title.title")}
                                        error={
                                            errors?.title?.title && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.title.message
                                                ),
                                            ]
                                        }
                                        readOnly={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.isrcs.label" />
                                    <ISRCSelect
                                        control={control}
                                        name="title.isrcs"
                                        options={[
                                            ...new Set(
                                                reference?.isrcs?.map(
                                                    ({ isrc }) => isrc
                                                )
                                            ),
                                        ]}
                                        error={
                                            errors?.title?.isrcs && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.isrcs.message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.isrcs.reference" />
                                    <ISRCReference
                                        control={control}
                                        name="title.isrcs"
                                        isrcs={reference?.isrcs}
                                        onSelect={(value) =>
                                            setValue("title.isrcs", value, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            })
                                        }
                                        location={location}
                                        locale={locale}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-4">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.genre.label" />
                                    <GenreSelect
                                        year={year}
                                        control={control}
                                        name="title.genre"
                                        error={
                                            errors?.title?.genre && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.genre.message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-8">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.genre.reference" />
                                    <GenreReference
                                        control={control}
                                        name="title.genre"
                                        genres={reference?.genres}
                                        onSelect={(value) =>
                                            setValue("title.genre", value, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            })
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-lg-2">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.publicationDate.label" />
                                    <InputText
                                        {...register("title.publicationDate")}
                                        error={
                                            errors?.title?.publicationDate && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.publicationDate
                                                        .message
                                                ),
                                            ]
                                        }
                                        readOnly={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.ageType.label" />
                                    <AgeType
                                        year={year}
                                        control={control}
                                        name="title.publicationDate"
                                        location={location}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-8">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.publicationDate.reference" />
                                    <PublicationDateReference
                                        control={control}
                                        name="title.publicationDate"
                                        publicationDates={
                                            reference?.publicationDates
                                        }
                                        onSelect={(value) =>
                                            setValue(
                                                "title.publicationDate",
                                                value,
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                }
                                            )
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-4">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.label.label" />
                                    <LabelSelect
                                        year={year}
                                        control={control}
                                        name="title.label"
                                        error={
                                            errors?.title?.label && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.label.message
                                                ),
                                            ]
                                        }
                                        locale={locale}
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-8">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.label.reference" />
                                    <LabelReference
                                        labels={reference?.labels}
                                        onSelect={(value) =>
                                            setValue("title.label", value, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            })
                                        }
                                        location={location}
                                        locale={locale}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-4">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.distributor.label" />
                                    <DistributorSelect
                                        year={year}
                                        control={control}
                                        name="title.distributor"
                                        error={
                                            errors?.title?.distributor && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.distributor
                                                        .message
                                                ),
                                            ]
                                        }
                                        locale={locale}
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-8">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.distributor.reference" />
                                    <DistributorReference
                                        distributors={reference?.distributors}
                                        onSelect={(value) =>
                                            setValue(
                                                "title.distributor",
                                                value,
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                }
                                            )
                                        }
                                        location={location}
                                        locale={locale}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <header>
                        <I18n t="cnm.report.consolidate.edit.header.vocals" />
                    </header>

                    <div className="form-row">
                        <div className="col-lg-2">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.language.label" />
                                    <LanguageSelect
                                        control={control}
                                        name="title.language"
                                        error={
                                            errors?.title?.language && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.language
                                                        .message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.voice.label" />
                                    <VoiceSelect
                                        control={control}
                                        name="title.voice"
                                        error={
                                            errors?.title?.voice && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.voice.message
                                                ),
                                            ]
                                        }
                                        location={location}
                                        disabled={disabled}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-8">
                            <Form.Group>
                                <Form.Label as="span">
                                    <I18n t="cnm.report.consolidate.edit.language.lyricfindReference" />
                                    <LyricfindLanguageReference
                                        languages={
                                            reference?.lyricfindLanguages ??
                                            null
                                        }
                                        control={control}
                                        onSelect={(value) => {
                                            setValue("title.language", value, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                            if (value === "INSTRUMENTAL") {
                                                setValue("title.voice", value, {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                });
                                            }
                                        }}
                                        location={location}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <header>
                        <I18n t="cnm.report.consolidate.edit.header.problems" />
                    </header>
                    <div className="row mb-3">
                        <div className="col-lg-6">
                            <Form.Group>
                                <InputSwitch
                                    {...register("title.discarded")}
                                    label={I18n.getTranslation(
                                        location,
                                        "cnm.report.consolidate.edit.discard.head"
                                    )}
                                    error={
                                        errors?.title?.discarded && [
                                            I18n.getTranslation(
                                                location,
                                                errors.title.discarded.message
                                            ),
                                        ]
                                    }
                                    disabled={
                                        disabled || (reported && !discarded)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-0">
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.discardReason.head" />
                                    <DiscardReasonSelect
                                        control={control}
                                        name={"title.discardReason"}
                                        error={
                                            errors?.title?.discardReason && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.title.discardReason
                                                        .message
                                                ),
                                            ]
                                        }
                                        disabled={disabled || !discarded}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 border-left">
                            <Form.Group>
                                <InputSwitch
                                    {...register("title.reported")}
                                    label={I18n.getTranslation(
                                        location,
                                        "cnm.report.consolidate.edit.report.head"
                                    )}
                                    error={
                                        errors?.title?.reported && [
                                            I18n.getTranslation(
                                                location,
                                                errors.title.reported.message
                                            ),
                                        ]
                                    }
                                    disabled={
                                        disabled || (discarded && !reported)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-0">
                                <Form.Label>
                                    <I18n t="cnm.report.consolidate.edit.reportReason" />
                                    <InputTextArea
                                        {...register("title.reportReason")}
                                        readOnly={disabled || !reported}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Widget.Body>

                <Widget.Body className="cnm-consolidation-form-footer d-flex align-items-center border-top pt-3">
                    <div className="flex-grow-1">
                        <InputSwitch
                            {...register("title.validated")}
                            label={I18n.getTranslation(
                                location,
                                "cnm.report.consolidate.edit.validate"
                            )}
                            disabled={disabled}
                        />
                    </div>
                    <Button
                        variant="secondary"
                        disabled={disabled || isSubmitting || isLoading}
                        onClick={
                            !disabled && !isSubmitting && !isLoading
                                ? onReset
                                : null
                        }
                    >
                        <I18n t="utils.button.reset" />
                    </Button>
                    <Button
                        variant="success"
                        className="ml-2"
                        disabled={disabled || isSubmitting || isLoading}
                        type="submit"
                    >
                        <I18n t="utils.button.save" />
                    </Button>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default Edit;
