import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";

const Matching = ({ releasesMatching, columns, isLoading, defaultSorted }) => {
    return (
        <Widget
            title="artist.management.disco.objects.release.matching.head"
            enableCollapse
        >
            <Widget.Body
                className="widget-table-overflow"
                style={{
                    height: "250px",
                    overflow: "auto",
                    borderTop: "1px solid #dee2e6",
                }}
            >
                {isLoading ? (
                    <Loader className="h-100 align-items-center" size="3x" />
                ) : (
                    <BootstrapTable
                        keyField="id"
                        data={releasesMatching}
                        columns={columns}
                        bootstrap4
                        striped
                        hover
                        bordered={false}
                        noDataIndication={
                            <I18n t="artist.management.disco.objects.release.matching.empty" />
                        }
                        classes="table-header-fixed"
                        defaultSorted={defaultSorted}
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Matching;
