import React, { useState } from "react";
import Widget from "./Widget";
import Body from "./Body";

const EnhancedWidget = ({ children, collapse = false, ...props }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapse);
    const childrenArray = React.Children.toArray(children);
    const newChildren = childrenArray.map((child) => {
        return React.cloneElement(child, { hidden: isCollapsed });
    });
    return (
        <Widget
            {...props}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            newChildren={newChildren}
        />
    );
};

EnhancedWidget.Body = Body;

export default EnhancedWidget;
