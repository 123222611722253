import React, { useState, useCallback, useEffect } from "react";
import useAPI from "shared/hooks/useApi";
import Artist from "./Artist";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";

const EnhancedArtist = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { artistId, albumId, locale } = useParams();
    const location = useLocation();
    const { api } = useAPI();
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            artist1: null,
            artist2: null,
            artist3: null,
        },
    });

    const initForm = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}/artists`)
                .then((response) => {
                    reset(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        }
    }, [artistId, albumId, reset]);

    useEffect(initForm, [initForm]);

    const onSubmit = (data) => {
        api.put(`album/${albumId}/artists`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.artist.update.success`
                    )
                );
                reset(data);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.artist.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Artist
            initForm={initForm}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedArtist;
