import React from "react";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import BadgeGroup from "shared/components/BadgeGroup";
import SubTasks from "./SubTasks";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import Loader from "shared/components/Loader/Loader";
import ArtistSelect from "shared/components/ArtistSelect";

const TaskForm = ({
    handleSubmit,
    planningId,
    partnerId,
    taskId,
    isLoading,
    isSubmitting,
    task,
    setTask,
    errors,
    planningsOptions,
    categoriesOptions,
    classificationOptions,
    tasksOptions,
    assignementOptions,
    checkArtistSelected,
    artistErrors,
    artistInformations,
    locale,
}) => {
    const addSubtask = () => {
        setTask((task) => ({
            ...task,
            subtasks: [
                ...task.subtasks,
                {
                    id: null,
                    type: null,
                    assignement: null,
                    status: null,
                    key: task.nextSubtaskKey,
                },
            ],
            nextSubtaskKey: task.nextSubtaskKey + 1,
        }));
    };
    const removeSubtask = (removeKey) => {
        setTask((task) => ({
            ...task,
            subtasks: task.subtasks.filter(
                (subtask) => subtask.key !== removeKey
            ),
        }));
    };
    const editSubtask = (editKey, newFields) => {
        setTask((task) => ({
            ...task,
            subtasks: task.subtasks.map((subtask) =>
                subtask.key === editKey
                    ? {
                          ...subtask,
                          ...newFields,
                      }
                    : subtask
            ),
        }));
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <form autoComplete="off">
            {!planningId || taskId ? (
                <div className="form-group">
                    <label htmlFor="planning">
                        <I18n t="plannings.tasks.planning" />
                    </label>
                    <InputSelect
                        value={
                            task.id_planning !== null
                                ? {
                                      value: task.id_planning,
                                      label: planningsOptions.reduce(
                                          (acc, curr) => {
                                              const planning =
                                                  curr.options.find(
                                                      ({ value }) =>
                                                          task.id_planning ===
                                                          value
                                                  );
                                              if (planning && acc === "") {
                                                  acc = planning.label;
                                              }
                                              return acc;
                                          },
                                          ""
                                      ),
                                  }
                                : null
                        }
                        options={planningsOptions}
                        onChange={(opt) => {
                            setTask({
                                ...task,
                                id_planning: opt?.value,
                            });
                            checkArtistSelected(opt?.value, task.ms_artist?.id);
                        }}
                        error={errors.id_planning}
                    />
                </div>
            ) : undefined}
            {partnerId === PLANNING_PARTNER.EDITORIAL && (
                <div className="form-group">
                    <label htmlFor="artist">
                        <I18n t="plannings.tasks.artist" />
                    </label>
                    <ArtistSelect
                        id={task.ms_artist?.id}
                        name={task.ms_artist?.name}
                        locale={locale}
                        onMatch={(id, name) => {
                            setTask((task) => ({
                                ...task,
                                ms_artist: { id, name },
                            }));
                            checkArtistSelected(
                                !planningId ? task.id_planning : planningId,
                                id
                            );
                        }}
                        onUnmatch={() => {
                            setTask((task) => ({
                                ...task,
                                ms_artist: null,
                            }));
                            checkArtistSelected(
                                !planningId ? task.id_planning : planningId,
                                null
                            );
                        }}
                        isDisabled={!planningId && !task.id_planning}
                        error={[...errors.ms_artist, ...artistErrors]}
                    />
                    <BadgeGroup datas={artistInformations} />
                </div>
            )}
            {partnerId !== PLANNING_PARTNER.EDITORIAL && (
                <div className="form-group">
                    <label htmlFor="artist">
                        <I18n t="plannings.tasks.artist" />
                    </label>
                    <InputText
                        value={task.artist_name ?? ""}
                        onChange={(e) => {
                            setTask({
                                ...task,
                                artist_name:
                                    e.target.value !== ""
                                        ? e.target.value
                                        : null,
                            });
                        }}
                        error={errors.artist_name}
                    />
                </div>
            )}
            <div className="form-group">
                <label htmlFor="album">
                    <I18n t="plannings.tasks.album" />
                </label>
                <InputText
                    value={task.album_title ?? ""}
                    onChange={(e) => {
                        setTask({
                            ...task,
                            album_title:
                                e.target.value !== "" ? e.target.value : null,
                        });
                    }}
                    error={errors.album_title}
                />
            </div>
            <div className="form-group">
                <label htmlFor="track">
                    <I18n t="plannings.tasks.track" />
                </label>
                <InputText
                    value={task.track_title ?? ""}
                    onChange={(e) => {
                        setTask({
                            ...task,
                            track_title:
                                e.target.value !== "" ? e.target.value : null,
                        });
                    }}
                    error={errors.track_title}
                />
            </div>
            <div className="form-group">
                <label htmlFor="category">
                    <I18n t="plannings.tasks.category" />
                </label>
                <InputSelect
                    value={
                        task.category !== null
                            ? {
                                  value: task.category,
                                  label: task.category,
                              }
                            : null
                    }
                    options={categoriesOptions}
                    onChange={(opt) => {
                        setTask({
                            ...task,
                            category: opt?.value ?? null,
                        });
                    }}
                    error={errors.category}
                />
            </div>
            <div className="form-group">
                <label htmlFor="reason">
                    <I18n t="plannings.tasks.reason" />
                </label>
                <InputText
                    value={task.reason ?? ""}
                    onChange={(e) => {
                        setTask({
                            ...task,
                            reason:
                                e.target.value !== "" ? e.target.value : null,
                        });
                    }}
                />
            </div>
            {partnerId === PLANNING_PARTNER.EDITORIAL && (
                <div className="form-group">
                    <label htmlFor="classification">
                        <I18n t="plannings.tasks.classification" />
                    </label>
                    <InputSelect
                        value={
                            task.classification !== null
                                ? {
                                      value: task.classification,
                                      label: task.classification,
                                  }
                                : null
                        }
                        options={classificationOptions}
                        onChange={(opt) => {
                            setTask({
                                ...task,
                                classification: opt?.value ?? null,
                            });
                        }}
                        error={errors.classification}
                    />
                </div>
            )}
            <div className="form-group">
                <label htmlFor="tasks">
                    <I18n t="plannings.tasks.subtasks.title" />
                </label>
                <SubTasks
                    subtasks={task.subtasks}
                    assignementOptions={assignementOptions}
                    tasksOptions={tasksOptions}
                    errors={errors.subtasks}
                    addSubtask={addSubtask}
                    removeSubtask={removeSubtask}
                    editSubtask={editSubtask}
                />
            </div>
            <div className="form-group row">
                <div className="col-md-4 offset-md-8">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        className="btn btn-success btn-block p-2"
                    >
                        {taskId ? (
                            <I18n t="utils.button.edit" />
                        ) : (
                            <I18n t="utils.button.add" />
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default TaskForm;
