import React from "react";
import { useLocation, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlay } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";
import CNMPlatformIcon from "shared/components/CNMPlatformIcon";
import { addToClipboard } from "shared/functions/AddToClipboard";
import PlatformTitles from "./PlatformTitles";

const EnhancedPlatformTitles = (props) => {
    const location = useLocation();
    const { locale } = useParams();

    const columns = [
        {
            dataField: "platform",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.platform"
            ),
            formatter: (cell, row) => (
                <>
                    <CNMPlatformIcon
                        name={cell}
                        size="lg"
                        className={`text-${platform_color(
                            row.platformId
                        )} mr-2`}
                    />
                    {cell}
                </>
            ),
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.artist"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <>
                    {cell}
                    {row.otherArtists ? ` (${row.otherArtists})` : ""}
                    <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => addToClipboard(cell, data.locale)}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                </>
            ),
            formatExtraData: { locale },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.title"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <>
                    {cell}
                    {row.subtitle ? (
                        <span className="font-italic ml-2">{row.subtitle}</span>
                    ) : (
                        ""
                    )}
                    <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => addToClipboard(cell, data.locale)}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                </>
            ),
            formatExtraData: { locale },
        },
        {
            dataField: "isrcs",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.isrcs"
            ),
            formatter: (cell) => (
                <>
                    {cell.map((isrc, index) => (
                        <React.Fragment key={isrc}>
                            {index !== 0 ? ", " : ""}
                            <a
                                href={`https://isrcsearch.ifpi.org/?tab="code"&isrcCode="${isrc}"`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {isrc}
                            </a>
                        </React.Fragment>
                    ))}
                </>
            ),
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.duration"
            ),
            formatter: (cell) => cell ?? "-",
            searchable: false,
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.edit.platformTitles.streams"
            ),
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
            headerStyle: {
                width: "8em",
            },
        },
        {
            dataField: "url",
            text: "",
            formatter: (cell) =>
                cell ? (
                    <a
                        className="btn btn-link"
                        href={cell}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faPlay} />
                    </a>
                ) : (
                    ""
                ),
            classes: () => "py-0",
        },
    ];

    return <PlatformTitles columns={columns} {...props} />;
};

export default EnhancedPlatformTitles;
