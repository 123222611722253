import React from "react";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputSwitch from "shared/components/Form/InputSwitch";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const CreateOrUpdate = ({
    releaseId,
    initForm,
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    supportOptions,
}) => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <div className="row">
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.barcode" />
                    </Form.Label>
                    <InputText
                        {...register("barcode")}
                        error={
                            errors.barcode && [
                                I18n.getTranslation(
                                    location,
                                    errors.barcode.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.commentary" />
                    </Form.Label>
                    <InputText
                        {...register("commentary")}
                        error={
                            errors.commentary && [
                                I18n.getTranslation(
                                    location,
                                    errors.commentary.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.support" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="support"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                isClearable={false}
                                innerRef={ref}
                                value={
                                    value !== null
                                        ? {
                                              value: value,
                                              label: value,
                                          }
                                        : null
                                }
                                options={supportOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                error={
                                    errors.support && [
                                        I18n.getTranslation(
                                            location,
                                            errors.support.message
                                        ),
                                    ]
                                }
                                enablePortal={releaseId}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.release_date" />
                    </Form.Label>
                    <InputText
                        placeholder={I18n.getTranslation(
                            location,
                            "utils.form.date_placeholder"
                        )}
                        {...register("release_date")}
                        error={
                            errors.release_date && [
                                I18n.getTranslation(
                                    location,
                                    errors.release_date.message
                                ),
                            ]
                        }
                    />
                </Form.Group>

                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.editor" />
                    </Form.Label>
                    <InputText
                        {...register("editor")}
                        error={
                            errors.editor && [
                                I18n.getTranslation(
                                    location,
                                    errors.reference.message
                                ),
                            ]
                        }
                    />
                </Form.Group>

                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.release.basic.reference" />
                    </Form.Label>
                    <InputText
                        {...register("reference")}
                        error={
                            errors.reference && [
                                I18n.getTranslation(
                                    location,
                                    errors.reference.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
            </div>
            <div className="d-flex flex-row-reverse">
                <Button
                    variant="secondary ml-2"
                    disabled={isSubmitting || isLoading}
                    onClick={!isSubmitting && !isLoading ? initForm : null}
                    title={I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.release.basic.reset.title"
                    )}
                >
                    <I18n t="utils.button.reset" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || isLoading || !isDirty}
                    onClick={
                        !isSubmitting && !isLoading && isDirty
                            ? handleSubmit(onSubmit)
                            : null
                    }
                    title={I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.release.basic.${
                            releaseId ? "update" : "create"
                        }.title`
                    )}
                >
                    <I18n t={`utils.button.${releaseId ? "edit" : "add"}`} />
                </Button>
            </div>
        </Form>
    );
};

export default CreateOrUpdate;
