import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import RecordingsLink from "./RecordingsLink";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Albums.css";

const Albums = ({
    albums,
    isLoading,
    columns,
    rowStyle,
    modalState,
    setModalState,
}) => {
    return (
        <Widget
            title="recording.view.profileAlbum.head"
            className="recording-view-albums pb-0"
            isLoading={isLoading}
        >
            <Widget.Body className="widget-table-overflow mb-0 border-top">
                <BootstrapTable
                    keyField="id"
                    data={albums}
                    columns={columns}
                    bootstrap4
                    condensed
                    hover
                    bordered={false}
                    noDataIndication={
                        <I18n t="recording.view.profileAlbum.empty" />
                    }
                    classes="table-layout-auto mb-0"
                    rowStyle={rowStyle}
                />
            </Widget.Body>
            <RecordingsLink
                {...modalState}
                handleClose={() => setModalState({ show: false, links: [] })}
            />
        </Widget>
    );
};

export default Albums;
