import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const DateList = ({ isLoading, dates, partner, selectedDate }) => {
    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="partner_tops.dates.empty" />
            </td>
        </tr>
    );
    if (dates.length !== 0) {
        rows = dates.map((date) => (
            <tr
                key={date}
                className={selectedDate === date ? "table-info" : undefined}
            >
                <td>
                    <NavLink to={`/matching/partner_tops/${partner}/${date}`}>
                        {date}
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <section className="widget">
            <header>
                <h6>
                    <I18n t="partner_tops.dates.title" />
                </h6>
            </header>
            <div
                className="widget-body widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped"
                        style={{ marginBottom: "0px" }}
                    >
                        <tbody>{rows}</tbody>
                    </table>
                )}
            </div>
        </section>
    );
};

export default DateList;
