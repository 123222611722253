import React from "react";
import { Tab, Nav } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import SampleList from "./SampleList";
import MetabaseView from "shared/components/MetabaseView";
import { Helmet } from "react-helmet";
import "./AppleSampleReport.css";

const AppleSampleReport = ({
    selectedTab,
    onSelectTab,
    sampleDate,
    setSampleDate,
    location,
}) => {
    return (
        <div className="content-wrap apple-sample-report">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.sample.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.sample.report"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.apple.title" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.apple.sample.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="base.nav.apple.sample.report" />
                    </li>
                </ol>
                <SampleList setSampleDate={setSampleDate} />
                <Tab.Container
                    id="apple-sample-report-tab"
                    activeKey={selectedTab}
                    onSelect={onSelectTab}
                >
                    <Nav variant="pills" justify>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="scorecard_provider"
                                disabled={!sampleDate}
                            >
                                <I18n t="apple.sample.report.scorecard_provider" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="scorecard_apple_user"
                                disabled={!sampleDate}
                            >
                                <I18n t="apple.sample.report.scorecard_apple_user" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="ranking_language_genre"
                                disabled={!sampleDate}
                            >
                                <I18n t="apple.sample.report.ranking_language_genre" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="explorer"
                                disabled={!sampleDate}
                            >
                                <I18n t="apple.sample.report.explorer" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="error" disabled={!sampleDate}>
                                <I18n t="apple.sample.report.error" />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="nav-content overflow-hidden">
                        <Tab.Pane
                            eventKey="scorecard_provider"
                            className="nav-pane h-100"
                        >
                            {sampleDate && (
                                <MetabaseView
                                    id="75"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    params={{
                                        date: sampleDate,
                                    }}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="scorecard_apple_user"
                            className="nav-pane h-100"
                        >
                            {sampleDate && (
                                <MetabaseView
                                    id="72"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    params={{
                                        date: sampleDate,
                                    }}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="ranking_language_genre"
                            className="nav-pane h-100"
                        >
                            {sampleDate && (
                                <MetabaseView
                                    id="73"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    params={{
                                        date: sampleDate,
                                    }}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="explorer"
                            className="nav-pane h-100"
                        >
                            {sampleDate && (
                                <MetabaseView
                                    id="71"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    params={{
                                        date: sampleDate,
                                    }}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="error" className="nav-pane h-100">
                            {sampleDate && (
                                <MetabaseView
                                    id="92"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    params={{
                                        date: sampleDate,
                                    }}
                                />
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default AppleSampleReport;
