import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import ja from "date-fns/locale/ja";
import de from "date-fns/locale/de";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import I18n from "shared/lib/I18n";
import Error from "shared/components/Form/Error";
import { useParams, useLocation } from "react-router";
registerLocale("fr", fr);
registerLocale("ja", ja);
registerLocale("de", de);
registerLocale("ko", ko);

const InputDate = (props) => {
    const { locale } = useParams();
    const location = useLocation();
    const { error, placeholder, disabled = false, readonly = false } = props;

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            className={`form-control form-control datepicker ${
                error && "is-invalid"
            }`}
            onClick={onClick}
            ref={ref}
            defaultValue={value}
            placeholder={I18n.getTranslation(
                location,
                placeholder ?? `utils.form.date_placeholder`
            )}
            readOnly={readonly}
            disabled={disabled}
            style={{
                backgroundColor: disabled ? "hsl(0, 0%, 95%)" : "inherit",
                borderColor: disabled ? "hsl(0, 0%, 90%)" : "#ced4da",
            }}
        />
    ));

    return (
        <>
            <DatePicker
                dateFormat="yyyy-MM-dd"
                showWeekNumbers
                locale={locale}
                customInput={<CustomInput />}
                disabled={disabled}
                {...props}
            />

            <Error error={error} className="mt-3" />
        </>
    );
};

export default InputDate;
