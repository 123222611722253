import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import YearSelect from "./YearSelect";

const EnhancedYearSelect = (props) => {
    const { api } = useAPI();
    const { locale, chartType } = useParams();
    const { year } = useIntParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [{ isLoading, options }, setState] = useState({
        isLoading: true,
        options: [],
    });

    const getOptions = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get("cnm/aggregation/years", { locale })
            .then((response) => {
                setState({
                    isLoading: false,
                    options: response,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState({ isLoading: false, options: [] });
            });
    }, []);

    useEffect(getOptions, [getOptions]);

    const selectedOption = useMemo(
        () =>
            options !== undefined && year !== undefined
                ? options.filter(
                      (option) =>
                          option.year === year &&
                          option.type.toLowerCase() === chartType
                  )[0]
                : undefined,
        [options, year, chartType]
    );

    const onChange = (opt) => {
        navigate(
            `/${locale}/cnm/aggregation/${opt.year}/${opt.type.toLowerCase()}`
        );
    };

    useEffect(() => {
        if (options.length > 0 && selectedOption === undefined) {
            onChange(options[0]);
        }
    }, [selectedOption, options]);

    const getOptionValue = ({ year, type }) => `${year}/${type}`;
    const getOptionLabel = ({ year, type }) => {
        const translatedType = I18n.getTranslation(
            location,
            `cnm.aggregation.yearSelect.type.${type}`
        );
        return `${year} (${translatedType})`;
    };

    return (
        <YearSelect
            options={options}
            selectedOption={selectedOption}
            isLoading={isLoading}
            onChange={onChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            {...props}
        />
    );
};

export default EnhancedYearSelect;
