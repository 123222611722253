import React from "react";
import ArtistLink from "shared/components/ArtistLink";

const MsArtistsReference = ({ artists, locale }) => {
    return (
        <div className="input-group">
            <div className="form-control reference-list">
                {artists.map(({ id, name }, index) => (
                    <React.Fragment key={id}>
                        {index !== 0 && <span className="mr-1">,</span>}
                        <ArtistLink artistId={id} content={name} view />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default MsArtistsReference;
