import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const DeleteButton = ({ show, onShow, onHide, onConfirm, disabled }) => {
    return (
        <>
            <Button
                variant="danger"
                onClick={onShow}
                title={I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_artists_titles.artists.delete.tooltip"
                )}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.cnm_artists_titles.artists.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <I18n t="cnm.repositories.cnm_artists_titles.artists.delete.text" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onConfirm}>
                        <I18n t="cnm.repositories.cnm_artists_titles.artists.delete.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteButton;
