import React, { useState, useEffect, useCallback } from "react";
import Gallery from "./Gallery";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import swal from "@sweetalert/with-react";
import InputSwitch from "shared/components/Form/InputSwitch";
import I18n from "shared/lib/I18n";
import Historic from "./Historic";
import Status from "./Status";
import ArtistLink from "shared/components/ArtistLink";
import PictureCard from "shared/components/PictureCard";
import {
    customFilter,
    FILTER_TYPES,
    Comparator,
} from "@musicstory/react-bootstrap-table2-filter";
import "./Gallery.css";

const EnhancedGallery = (props) => {
    const [label, setLabel] = useState("-");
    const [gallery, setGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pendingTracking, setPendingTracking] = useState(false);
    const location = useLocation();
    const { api } = useAPI();

    const getGallery = useCallback(() => {
        api.get("picture/label/status")
            .then((response) => {
                setLabel(response.label);
                setGallery(response.gallery);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getGallery, [getGallery]);

    const columns = [
        {
            dataField: "url",
            text: I18n.getTranslation(
                location,
                "pictures.label.status.picture"
            ),
            formatter: (cell) => {
                return <PictureCard url={cell} />;
            },
            headerStyle: {
                width: "13rem",
            },
            searchable: false,
        },
        {
            dataField: "ms_artist",
            text: I18n.getTranslation(location, "pictures.label.status.artist"),
            formatter: (cell) => {
                return (
                    <ArtistLink artistId={cell.id} content={cell.name} blank />
                );
            },
            filterValue: (cell) => cell.name,
        },
        {
            dataField: "status",
            text: I18n.getTranslation(location, "pictures.label.status.status"),
            sort: true,
            searchable: false,
            formatter: (cell) => {
                return <Status status={cell} />;
            },
            headerStyle: {
                width: "28%",
            },
            sortFunc: (a, b, order) => {
                const sortOrder = {
                    AWAITING: {
                        asc: 1,
                        desc: 4,
                    },
                    AWAITING_FOR_PROCESSING: {
                        asc: 2,
                        desc: 3,
                    },
                    DOCUMENTED: {
                        asc: 3,
                        desc: 2,
                    },
                    REJECTED: {
                        asc: 4,
                        desc: 1,
                    },
                };
                return sortOrder[a][order] < sortOrder[b][order] ? 1 : -1;
            },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                comparator: Comparator.EQ,
            }),
            filterRenderer: (onFilter) => (
                <div
                    className="status-filter"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <span className="status-filter-pending">
                        <I18n t="pictures.label.status.filterStatusLabel" />
                    </span>
                    <InputSwitch
                        onClick={() => {
                            onFilter(
                                pendingTracking
                                    ? ["AWAITING", "AWAITING_FOR_PROCESSING"]
                                    : []
                            );
                            setPendingTracking(!pendingTracking);
                        }}
                        onLoad={() =>
                            onFilter(["AWAITING", "AWAITING_FOR_PROCESSING"])
                        }
                        value={pendingTracking}
                    />
                </div>
            ),
        },
        {
            dataField: "historic",
            searchable: false,
            text: I18n.getTranslation(
                location,
                "pictures.label.status.historic"
            ),
            headerStyle: {
                width: "30%",
            },
            sort: true,
            formatter: (cell, row) => {
                return <Historic createdAt={row.created_at} historic={cell} />;
            },
            sortValue: (cell, row) => row.created_at,
        },
    ];

    const defaultSorted = [
        {
            dataField: "historic",
            order: "desc",
        },
    ];

    const paginationOptions = {
        sizePerPage: 15,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    const statusDefinition = {
        AWAITING: I18n.getTranslation(
            location,
            "pictures.label.status.definition.AWAITING"
        ),
        AWAITING_FOR_PROCESSING: I18n.getTranslation(
            location,
            "pictures.label.status.definition.AWAITING_FOR_PROCESSING"
        ),
        DOCUMENTED: I18n.getTranslation(
            location,
            "pictures.label.status.definition.DOCUMENTED"
        ),
        REJECTED: I18n.getTranslation(
            location,
            "pictures.label.status.definition.REJECTED"
        ),
    };

    const showStatusDefinition = () => {
        swal(
            <div className="text-justify">
                <p>
                    <span className={`text-secondary font-weight-bold`}>
                        AWAITING
                    </span>
                    : {statusDefinition.AWAITING}
                </p>
                <p>
                    <span className={`text-primary font-weight-bold`}>
                        AWAITING FOR PROCESSING
                    </span>
                    : {statusDefinition.AWAITING_FOR_PROCESSING}
                </p>
                <p>
                    <span className={`text-success font-weight-bold`}>
                        DOCUMENTED
                    </span>
                    : {statusDefinition.DOCUMENTED}
                </p>
                <p>
                    <span className={`text-danger font-weight-bold`}>
                        REJECTED
                    </span>
                    : {statusDefinition.REJECTED}
                </p>
            </div>,
            {
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.close"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                },
            }
        );
    };

    return (
        <Gallery
            {...props}
            label={label}
            gallery={gallery}
            columns={columns}
            defaultSorted={defaultSorted}
            paginationOptions={paginationOptions}
            isLoading={isLoading}
            showStatusDefinition={showStatusDefinition}
        />
    );
};

export default EnhancedGallery;
