import React from "react";
import I18n from "shared/lib/I18n";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputNumber from "shared/components/Form/InputNumber";
import InputSelect from "shared/components/Form/InputSelect";
import { useLocation } from "react-router";

const SearchForm = ({
    isLoading,
    onSubmit,
    register,
    control,
    catalogOptions,
    errors,
}) => {
    const location = useLocation();
    return (
        <div className="row">
            <div className="col-md-12">
                <section className="widget">
                    <div className="widget-body">
                        <form onSubmit={onSubmit}>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.recommendation.ms_track_ids" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("msTrackIds")}
                                            error={
                                                errors.msTrackIds && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.msTrackIds
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.recommendation.ms_artist_ids" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("msArtistIds")}
                                            error={
                                                errors.msArtistIds && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.msArtistIds
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.recommendation.mm_track_ids" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("mmTrackIds")}
                                            error={
                                                errors.mmTrackIds && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.mmTrackIds
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <label className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <I18n t="moodmedia.recommendation.filter_no" />
                                                    </span>
                                                </div>
                                                <InputText
                                                    {...register("filterNo")}
                                                    error={
                                                        errors.filterNo && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.filterNo
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-5">
                                            <Controller
                                                control={control}
                                                name="catalog"
                                                render={({
                                                    field: {
                                                        onChange,
                                                        value,
                                                        ref,
                                                    },
                                                }) => (
                                                    <InputSelect
                                                        innerRef={ref}
                                                        placeholder={I18n.getTranslation(
                                                            location,
                                                            "moodmedia.recommendation.catalog"
                                                        )}
                                                        options={catalogOptions}
                                                        onChange={(opt) => {
                                                            onChange(
                                                                opt
                                                                    ? opt.value
                                                                    : null
                                                            );
                                                        }}
                                                        value={
                                                            value
                                                                ? {
                                                                      value,
                                                                      label: value,
                                                                  }
                                                                : {
                                                                      value: "db1",
                                                                      label: "db1",
                                                                  }
                                                        }
                                                        error={
                                                            errors.catalog && [
                                                                I18n.getTranslation(
                                                                    location,
                                                                    errors
                                                                        .catalog
                                                                        .message
                                                                ),
                                                            ]
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.recommendation.min_accuracy" />
                                            </span>
                                        </div>
                                        <InputNumber
                                            {...register("minAccuracy")}
                                            error={
                                                errors.minAccuracy && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.minAccuracy
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-block btn-info"
                                            disabled={isLoading}
                                        >
                                            <I18n t="utils.button.search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SearchForm;
