import React, { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import DeleteButton from "./DeleteButton";

const EnhancedDeleteButton = ({ id, onDelete, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);

    const onConfirm = () => {
        api.delete(`cnm/artist/${id}`, { locale })
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.cnm_artists_titles.artists.delete.success"
                    )
                );
                onHide();
                onDelete({ id });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <DeleteButton
            show={show}
            onShow={onShow}
            onHide={onHide}
            onConfirm={onConfirm}
            {...props}
        />
    );
};

export default EnhancedDeleteButton;
