import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import SearchForm from "./SearchForm";

const Search = ({
    rows,
    columns,
    selectRow,
    rowClasses,
    isLoading,
    onSearch,
    defaultSearch,
    disabled,
}) => {
    return (
        <Widget
            title="cnm.aggregation.search.head"
            className="vertical-shrink pb-0"
            isLoading={isLoading}
        >
            <Widget.Body>
                <SearchForm
                    onSubmit={onSearch}
                    defaultSearch={defaultSearch}
                    disabled={disabled}
                />
            </Widget.Body>
            <Widget.Body className="widget-table-overflow overflow-auto mb-0">
                <BootstrapTable
                    keyField="id"
                    columns={columns}
                    data={rows}
                    selectRow={selectRow}
                    rowClasses={rowClasses}
                    classes="table-header-fixed table-layout-auto table-selectable"
                    bordered={false}
                    bootstrap4
                    striped
                    hover
                    noDataIndication={<I18n t="cnm.aggregation.search.empty" />}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Search;
