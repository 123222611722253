import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PopularityOverview from "./PopularityOverview";

const EnhancedPopularityOverview = ({ setNoData, id_recording, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        popularity: {},
        isLoading: true,
    });

    const getPopularity = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        if (id_recording) {
            return api
                .get(`recording/${id_recording}/popularity/overview`, {
                    locale,
                })
                .then((response) => {
                    setState({ popularity: response, isLoading: false });
                    if (Object.values(response).length === 0) {
                        setNoData("overview");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({ popularity: {}, isLoading: false });
                });
        }
    }, [id_recording]);

    useEffect(() => {
        getPopularity();
    }, [getPopularity]);

    return <PopularityOverview {...props} {...state} location={location} />;
};

export default EnhancedPopularityOverview;
