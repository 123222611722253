import React from "react";
import Error from "shared/components/Form/Error";

const InputNumber = React.forwardRef(
    ({ className, error, showError = true, ...props }, ref) => {
        return (
            <>
                <input
                    type="number"
                    className={`form-control ${
                        error && "is-invalid"
                    } ${className}`}
                    title={error && error.join(", ")}
                    ref={ref}
                    {...props}
                />
                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputNumber;
