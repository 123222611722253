import { useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import {
    LABEL_PROMO_DISABLE_SET,
    SidebarNotificationContext,
} from "layout/SidebarNotification";

export const useSidebarLabelPromoDisable = () => {
    const { api } = useAPI();
    const { user } = useUser();
    const { labelPromoDisable } = useContext(SidebarNotificationContext);

    const getLabelPromo = useCallback(() => {
        if (user.hasRight("label.promo.manage")) {
            api.get("label-promo/disable")
                .then((response) => {
                    labelPromoDisable.dispatch({
                        type: LABEL_PROMO_DISABLE_SET,
                        data: response.length,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, []);

    useEffect(getLabelPromo, [getLabelPromo]);

    return {
        nbLabelPromoDisable: labelPromoDisable.count,
    };
};
