export const capitalizeAll = (string, delimiter = " ") => {
    if (typeof string !== "string") {
        return string;
    }
    return string
        .split(delimiter)
        .map((el) => {
            return el.charAt(0).toUpperCase() + el.slice(1);
        })
        .join(delimiter);
};

export const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
    } else {
        hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
};

export const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    return typeof item === "object" && item !== null;
};

export const formatIswc = (iswc) => {
    if (!iswc || iswc.length !== 11 || !iswc.startsWith("T")) {
        return iswc;
    }

    const prefix = iswc[0];
    const part1 = iswc.slice(1, 4);
    const part2 = iswc.slice(4, 7);
    const part3 = iswc.slice(7, 10);
    const checkDigit = iswc[10];

    return `${prefix}-${part1}.${part2}.${part3}.${checkDigit}`;
};

export const formatIPI = (ipi) => {
    if (!ipi || isNaN(ipi)) {
        return ipi;
    }

    const paddedIPI = String(ipi).padStart(11, "0");

    const part1 = paddedIPI.slice(0, 5);
    const part2 = paddedIPI.slice(5, 7);
    const part3 = paddedIPI.slice(7, 9);
    const part4 = paddedIPI.slice(9, 11);

    return `${part1} ${part2} ${part3} ${part4}`;
};
