import React from "react";
import I18n from "shared/lib/I18n";
import IndustryStatistics from "./IndustryStatistics";
import MsStatistics from "./MsStatistics";
import { Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./LandingStatistics.css";
import clsx from "clsx";

const LandingStatistics = ({
    headerObserverRef,
    isHeaderSticky,
    selectedTab,
    onSelectTab,
    location,
    containerRef,
    containerTopOffset,
}) => {
    return (
        <>
            <div id="header-observer" ref={headerObserverRef}></div>
            <Tab.Container
                id="landing-statistics-tab"
                activeKey={selectedTab}
                defaultActiveKey="industry"
                onSelect={onSelectTab}
                mountOnEnter
                unmountOnExit
            >
                <Nav
                    variant="pills"
                    className={clsx(
                        "landing-statistics-nav",
                        isHeaderSticky && "nav-sticky"
                    )}
                    justify
                >
                    <Nav.Item>
                        <Nav.Link eventKey="industry">
                            <I18n t="landing.industry" />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="ms">
                            <I18n t="landing.ms" />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content
                    ref={containerRef}
                    className={clsx(
                        "landing-statistics-nav-content nav-content overflow-hidden"
                    )}
                    style={{
                        marginTop: isHeaderSticky
                            ? `${containerTopOffset}px`
                            : "1.2rem",
                    }}
                >
                    <Tab.Pane eventKey="industry" className="nav-pane">
                        <Helmet>
                            <title>{`${I18n.getTranslation(
                                location,
                                "dashboard"
                            )} - ${I18n.getTranslation(
                                location,
                                "landing.breadcrumb"
                            )} - ${I18n.getTranslation(
                                location,
                                "landing.industry"
                            )}`}</title>
                        </Helmet>
                        <IndustryStatistics />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ms" className="nav-pane">
                        <Helmet>
                            <title>{`${I18n.getTranslation(
                                location,
                                "dashboard"
                            )} - ${I18n.getTranslation(
                                location,
                                "landing.breadcrumb"
                            )} - ${I18n.getTranslation(
                                location,
                                "landing.ms"
                            )}`}</title>
                        </Helmet>
                        <MsStatistics />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default LandingStatistics;
