import React from "react";
import swal from "sweetalert";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import PicturesCell from "./PicturesCell";

const EnhancedPicturesCell = (props) => {
    const location = useLocation();

    const showComment = (e, comment) => {
        e.preventDefault();
        swal(comment, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };

    return <PicturesCell showComment={showComment} {...props} />;
};

export default EnhancedPicturesCell;
