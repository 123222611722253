import React from "react";
import CountryManagement from "./CountryManagement";
import { useLocation } from "react-router";

const EnhancedCountryManagement = (props) => {
    const location = useLocation();
    return <CountryManagement {...props} location={location} />;
};

export default EnhancedCountryManagement;
