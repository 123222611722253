import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import ImageLoader from "shared/components/ImageLoader";
import placeholder from "assets/static/images/logo_placeholder.png";
import styles from "shared/components/MultiSearch/MultiSearch.module.css";

const Artists = ({ q, handleReset, artists, locale }) => {
    return (
        <>
            <div className={styles.head}>
                <div className="d-flex justify-content-between">
                    <div className={styles.head__title}>
                        <I18n t="common.multiSearch.artist" />
                    </div>
                    <div>
                        <NavLink
                            to={`/search/artists?q=${q}`}
                            onClick={handleReset}
                        >
                            <I18n t="common.multiSearch.more" />
                        </NavLink>
                    </div>
                </div>
            </div>
            <div>
                {artists.length !== 0 ? (
                    artists.map((artist) => (
                        <NavLink
                            key={artist.id}
                            to={`/artist/${artist.artist_id}/view`}
                            onClick={handleReset}
                            className={styles.content__a}
                        >
                            <div className={styles.content__row}>
                                <div>
                                    <ImageLoader
                                        src={artist.cover}
                                        fallbackSrc={placeholder}
                                        fallbackClassName={
                                            styles.cover__placeholder
                                        }
                                        className={styles.cover__artist}
                                    />
                                </div>
                                <div className={styles.content__information}>
                                    <div className={styles.content__title}>
                                        <span>{artist.artist_id}</span>
                                        <span className="mx-1">-</span>
                                        <span>{artist.name}</span>
                                    </div>
                                    <div className={styles.content__subtitle}>
                                        {artist.genre && (
                                            <span>
                                                {artist.genre.langs[locale]}
                                            </span>
                                        )}
                                        {artist.country && (
                                            <>
                                                <span className="mx-1">-</span>
                                                <span>
                                                    {
                                                        artist.country.langs[
                                                            locale
                                                        ]
                                                    }
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    ))
                ) : (
                    <div className={styles.content__row__empty}>
                        <I18n t="common.multiSearch.empty" />
                    </div>
                )}
            </div>
        </>
    );
};

export default Artists;
