import React from "react";
import { Controller } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputSelect from "shared/components/Form/InputSelect";
import { GENRE_TYPE_MAIN } from "shared/constants/cms/artist";

const Genre = ({
    getGenre,
    isLoading,
    handleSubmit,
    errors,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    fields,
    append,
    remove,
    location,
    genreOptions,
    getGenreOptionsByIndex,
    genreTypeOptions,
}) => {
    return (
        <Form
            onSubmit={!isSubmitting && !isLoading && isDirty ? onSubmit : null}
        >
            <Widget
                title={`artist.management.genre.head`}
                actions={
                    <Button
                        variant="primary"
                        onClick={() =>
                            append({
                                id_genre: null,
                                type_relation: null,
                            })
                        }
                    >
                        <I18n t={`utils.button.add`} />
                    </Button>
                }
                enableCollapse
            >
                <Widget.Body
                    className="widget-table-overflow mb-0 border-top border-bottom"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                >
                    {isLoading ? (
                        <Loader className="m-2" />
                    ) : (
                        <table
                            className="table table-striped table-sm table-header-fixed mb-0"
                            style={{ tableLayout: "fixed" }}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        <I18n t="artist.management.genre.type" />
                                    </th>
                                    <th>
                                        <I18n t="artist.management.genre.genre" />
                                    </th>
                                    <th
                                        style={{
                                            width: "calc(1.25em + 2px + 2.6rem)",
                                        }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.length > 0 ? (
                                    fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`genre.${index}.type_relation`}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => {
                                                        return value ===
                                                            GENRE_TYPE_MAIN ? (
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        "14px",
                                                                }}
                                                            >
                                                                {
                                                                    genreTypeOptions.find(
                                                                        ({
                                                                            id,
                                                                        }) =>
                                                                            String(
                                                                                id
                                                                            ) ===
                                                                            String(
                                                                                value
                                                                            )
                                                                    )?.name
                                                                }
                                                            </span>
                                                        ) : (
                                                            <InputSelect
                                                                ref={ref}
                                                                value={
                                                                    value !==
                                                                    null
                                                                        ? {
                                                                              value,
                                                                              label: genreTypeOptions.find(
                                                                                  ({
                                                                                      id,
                                                                                  }) =>
                                                                                      String(
                                                                                          id
                                                                                      ) ===
                                                                                      String(
                                                                                          value
                                                                                      )
                                                                              )
                                                                                  ?.name,
                                                                          }
                                                                        : null
                                                                }
                                                                options={genreTypeOptions
                                                                    .filter(
                                                                        ({
                                                                            id,
                                                                        }) =>
                                                                            id !==
                                                                            GENRE_TYPE_MAIN
                                                                    )
                                                                    .map(
                                                                        ({
                                                                            id,
                                                                            name,
                                                                        }) => {
                                                                            return {
                                                                                value: id,
                                                                                label: name,
                                                                            };
                                                                        }
                                                                    )}
                                                                onChange={(
                                                                    opt
                                                                ) => {
                                                                    onChange(
                                                                        opt
                                                                            ? opt.value
                                                                            : null
                                                                    );
                                                                }}
                                                                isClearable={
                                                                    false
                                                                }
                                                                showError={
                                                                    false
                                                                }
                                                                isDisabled={
                                                                    item.type_relation ===
                                                                    GENRE_TYPE_MAIN
                                                                }
                                                                error={
                                                                    errors
                                                                        ?.genre?.[
                                                                        index
                                                                    ]
                                                                        ?.type_relation && [
                                                                        I18n.getTranslation(
                                                                            location,
                                                                            errors
                                                                                .genre[
                                                                                index
                                                                            ]
                                                                                .type_relation
                                                                                .message
                                                                        ),
                                                                    ]
                                                                }
                                                                enablePortal
                                                            />
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`genre.${index}.id_genre`}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputSelect
                                                            ref={ref}
                                                            value={
                                                                value !== null
                                                                    ? {
                                                                          value,
                                                                          label: genreOptions.find(
                                                                              ({
                                                                                  id,
                                                                              }) =>
                                                                                  String(
                                                                                      id
                                                                                  ) ===
                                                                                  String(
                                                                                      value
                                                                                  )
                                                                          )
                                                                              ?.nom,
                                                                      }
                                                                    : null
                                                            }
                                                            options={getGenreOptionsByIndex(
                                                                index
                                                            )}
                                                            onChange={(opt) => {
                                                                onChange(
                                                                    opt
                                                                        ? opt.value
                                                                        : null
                                                                );
                                                            }}
                                                            isClearable={false}
                                                            showError={false}
                                                            error={
                                                                errors?.genre?.[
                                                                    index
                                                                ]?.id_genre && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .genre[
                                                                            index
                                                                        ]
                                                                            .id_genre
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                            enablePortal
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                {item.type_relation !==
                                                    GENRE_TYPE_MAIN && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            fixedWidth
                                                        />
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            <I18n t="artist.management.genre.empty" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </Widget.Body>
                <Widget.Body className="mt-3">
                    <div className="d-flex flex-row-reverse">
                        <Button
                            variant="secondary ml-2"
                            disabled={isSubmitting || isLoading}
                            onClick={
                                !isSubmitting && !isLoading ? getGenre : null
                            }
                            title={I18n.getTranslation(
                                location,
                                "artist.management.genre.reset.title"
                            )}
                        >
                            <I18n t="utils.button.reset" />
                        </Button>
                        <Button
                            variant="success"
                            disabled={isSubmitting || isLoading || !isDirty}
                            onClick={
                                !isSubmitting && !isLoading && isDirty
                                    ? handleSubmit(onSubmit)
                                    : null
                            }
                            title={I18n.getTranslation(
                                location,
                                `artist.management.genre.update.title`
                            )}
                        >
                            {isSubmitting ? (
                                <Loader size="1x" />
                            ) : (
                                <I18n t={`utils.button.edit`} />
                            )}
                        </Button>
                    </div>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default Genre;
