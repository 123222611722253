import React from "react";
import { useLocation } from "react-router";
import swal from "sweetalert";
import SwalButton from "./SwalButton";
import I18n from "shared/lib/I18n";

const EnhancedSwalButton = ({ swalOptions, onConfirm, onCancel, ...props }) => {
    const location = useLocation();

    const onClick = () => {
        swal({
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
            ...swalOptions,
        }).then((isConfirmed) => {
            if (isConfirmed && onConfirm) {
                onConfirm();
            } else if (!isConfirmed && onCancel) {
                onCancel();
            }
        });
    };

    return <SwalButton onClick={onClick} {...props} />;
};

export default EnhancedSwalButton;
