import { useState } from "react";
import moment from "moment";

const FORMAT = "YYYY-MM-DD";

const useWeekPeriod = () => {
    const currentDate = moment();

    const [startDate, setStartDate] = useState(
        moment(currentDate).day(1).format(FORMAT)
    );
    const [endDate, setEndDate] = useState(
        moment(currentDate).day(7).format(FORMAT)
    );

    const previous = () => {
        setStartDate(moment(startDate).day(-6).format(FORMAT));
        setEndDate(moment(endDate).day(-7).format(FORMAT));
    };

    const next = () => {
        if (hasNext()) {
            setStartDate(
                moment(startDate)
                    .day(+8)
                    .format(FORMAT)
            );
            setEndDate(
                moment(endDate)
                    .day(+7)
                    .format(FORMAT)
            );
        }
    };

    const hasNext = () => {
        return !(
            moment(currentDate).day(1).format(FORMAT) ===
            moment(startDate).day(1).format(FORMAT)
        );
    };

    return [startDate, endDate, previous, next, hasNext];
};

export default useWeekPeriod;
