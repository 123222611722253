import React from "react";
import Charts from "./Charts";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";

const EnhancedCharts = ({
    charts,
    selectedCharts,
    setSelectedCharts,
    ...props
}) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "recording.view.chartsRecordingCharts.charts.title"
            ),
            title: true,
            filterValue: normalizeTitle,
        },
        {
            dataField: "country",
            hidden: true,
            filterValue: ({ label }) => label,
            filterValue: normalizeTitle,
        },
        {
            dataField: "source",
            hidden: true,
            filterValue: normalizeTitle,
        },
    ];

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ id, title }, isSelect) =>
            setSelectedCharts((prev) =>
                isSelect
                    ? [...prev, { id, title }]
                    : prev.filter((c) => c.id !== id)
            ),
        selected: selectedCharts.map((e) => e.id),
        nonSelectable:
            selectedCharts.length >= 7
                ? charts
                      .filter(
                          ({ id }) => !selectedCharts.some((e) => e.id === id)
                      )
                      .map(({ id }) => id)
                : [],
        nonSelectableClasses: "table-disabled",
    };

    return (
        <Charts
            {...props}
            charts={charts}
            columns={columns}
            selectRow={selectRow}
            onColumnMatch={onColumnMatchNormalized}
            location={location}
        />
    );
};

export default EnhancedCharts;
