import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import MatchingList from "./MatchingList";

const EnhancedMatchingList = ({
    cnmGenres,
    msMainGenres,
    matchings,
    filters,
    setFilters,
    setMatchings,
    editable,
    ...props
}) => {
    const location = useLocation();
    const [selectedMatchingIds, setSelectedMatchingIds] = useState([]);

    // Reset selection when a filter is changed
    useEffect(
        () => setSelectedMatchingIds([]),
        [filters.cnmGenre, filters.msMainGenre]
    );

    const filteredMatchings = useMemo(
        () =>
            matchings.filter(
                (matching) =>
                    (filters.msMainGenre === null ||
                        matching.msMainId == filters.msMainGenre) &&
                    (filters.cnmGenre === null ||
                        matching.cnmId == filters.cnmGenre ||
                        (filters.cnmGenre == 0 && matching.cnmId === null))
            ),
        [matchings, filters.msMainGenre, filters.cnmGenre]
    );

    const msGenreOptions = useMemo(
        () =>
            msMainGenres.map(({ id, name }) => ({
                value: id,
                label: name,
            })),
        [msMainGenres]
    );
    const { cnmGenreOptions, cnmGenreFilterOptions } = useMemo(() => {
        const cnmGenreOptions = cnmGenres.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
        const cnmGenreFilterOptions = [
            {
                value: 0,
                label: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_genres.matchings.filter.notMatched"
                ),
            },
            ...cnmGenreOptions,
        ];

        return { cnmGenreOptions, cnmGenreFilterOptions };
    }, [cnmGenres, location]);

    const onSelect = (row, isSelect) => {
        setSelectedMatchingIds((selectedMatchingIds) =>
            isSelect
                ? [...selectedMatchingIds, row.msId]
                : selectedMatchingIds.filter((msId) => msId !== row.msId)
        );
    };
    const onSelectAll = (isSelect, rows) => {
        setSelectedMatchingIds(isSelect ? rows.map(({ msId }) => msId) : []);
    };

    const columns = [
        {
            dataField: "msName",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_genres.matchings.msGenre"
            ),
            filterValue: normalizeTitle,
        },
        {
            dataField: "dummyCNM",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_genres.matchings.cnmGenre"
            ),
            isDummyField: true,
            formatter: editable
                ? (cell, row, rowIndex, data) => (
                      <InputSelect
                          value={
                              row.cnmId !== null
                                  ? {
                                        value: row.cnmId,
                                        label: row.cnmName,
                                    }
                                  : null
                          }
                          options={data.cnmGenreOptions}
                          onChange={(opt, action) => {
                              if (action.action === "select-option") {
                                  setMatchings([row.msId], opt.value);
                              } else {
                                  setMatchings([row.msId], null);
                              }
                          }}
                          isClearable
                          placeholder={data.placeholder}
                          enablePortal
                          menuPlacement="auto"
                          menuPosition="fixed"
                      />
                  )
                : (cell, row) => row.cnmName ?? "-",
            formatExtraData: {
                cnmGenreOptions,
                placeholder: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_genres.matchings.cnmGenrePlaceholder"
                ),
            },
        },
    ];

    return (
        <MatchingList
            editable={editable}
            columns={columns}
            onColumnMatch={onColumnMatchNormalized}
            matchings={filteredMatchings}
            setMatchings={setMatchings}
            msGenreOptions={msGenreOptions}
            cnmGenreOptions={cnmGenreOptions}
            cnmGenreFilterOptions={cnmGenreFilterOptions}
            filters={filters}
            setFilters={setFilters}
            selectRow={
                editable
                    ? {
                          mode: "checkbox",
                          headerColumnStyle: {
                              paddingLeft: "2em",
                              paddingRight: "2em",
                          },
                          selectColumnStyle: {
                              paddingLeft: "2em",
                              paddingRight: "2em",
                          },
                          selected: selectedMatchingIds,
                          onSelect: onSelect,
                          onSelectAll: onSelectAll,
                      }
                    : undefined
            }
            selectedMatchingIds={selectedMatchingIds}
            location={location}
            {...props}
        />
    );
};

export default EnhancedMatchingList;
