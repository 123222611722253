import React, { useState, useEffect, useCallback } from "react";
import CurrentCatalog from "./CurrentCatalog";
import TopEmerging from "./TopEmerging";
import SexeRepartition from "./SexeRepartition";
import GenreRepartition from "./GenreRepartition";
import LanguageRepartition from "./LanguageRepartition";
import TopArtist from "./TopArtist";
import TopProgression from "./TopProgression";
import TopTitle from "./TopTitle";
import IndustryStatistics from "./IndustryStatistics";
import { useInView } from "react-intersection-observer";
import useAPI from "shared/hooks/useApi";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const generateWidget = (
    id,
    selectedCountry,
    countryOptions,
    location,
    locale
) => {
    switch (id) {
        case "top_title":
            return (
                <TopTitle
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        case "top_progression":
            return (
                <TopProgression
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        case "top_artist":
            return (
                <TopArtist
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        case "top_emerging":
            return (
                <TopEmerging
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        case "sexe_repartition":
            return (
                <SexeRepartition
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        case "genre_repartition":
            return (
                <GenreRepartition
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    locale={locale}
                    location={location}
                />
            );
        case "language_repartition":
            return (
                <LanguageRepartition
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    locale={locale}
                    location={location}
                />
            );
        case "current_catalog":
            return (
                <CurrentCatalog
                    id_country={selectedCountry}
                    countryOptions={countryOptions}
                    location={location}
                />
            );
        default:
            return null;
    }
};

const EnhancedIndustryStatistics = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();

    const location = useLocation();

    const { ref: headerObserverRef, inView: headerObserverInView } = useInView({
        threshold: 1,
    });

    const handleDragAndDrop = (results) => {
        const { source, destination, type } = results;

        if (!destination) return;

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        )
            return;

        if (type === "group") {
            const reorderedColumns = [...columns];

            const columnSourceIndex = source.index;
            const columnDestinatonIndex = destination.index;

            const [removedColumn] = reorderedColumns.splice(
                columnSourceIndex,
                1
            );
            reorderedColumns.splice(columnDestinatonIndex, 0, removedColumn);

            return setColumns(reorderedColumns);
        }
        const itemSourceIndex = source.index;
        const itemDestinationIndex = destination.index;

        const columnSourceIndex = columns.findIndex(
            (column) => column.id === source.droppableId
        );
        const columnDestinationIndex = columns.findIndex(
            (column) => column.id === destination.droppableId
        );

        const newSourceItems = [...columns[columnSourceIndex].items];
        const newDestinationItems =
            source.droppableId !== destination.droppableId
                ? [...columns[columnDestinationIndex].items]
                : newSourceItems;

        const [deletedItem] = newSourceItems.splice(itemSourceIndex, 1);
        newDestinationItems.splice(itemDestinationIndex, 0, deletedItem);

        const newColumns = [...columns];

        newColumns[columnSourceIndex] = {
            ...columns[columnSourceIndex],
            items: newSourceItems,
        };
        newColumns[columnDestinationIndex] = {
            ...columns[columnDestinationIndex],
            items: newDestinationItems,
        };

        setColumns(newColumns);

        const savedWidgetsPosition = newColumns.map((widgetGroup) => ({
            id: widgetGroup.id,
            items: widgetGroup.items.map((item) => ({
                id: item.id,
            })),
        }));
        localStorage.setItem(
            "widgetsPosition",
            JSON.stringify(savedWidgetsPosition)
        );
    };

    const [countryOptions, setCountryOptions] = useState([]);
    const getCountry = useCallback(() => {
        api.get(`statistics/industry/country/${locale}`)
            .then((response) => {
                setCountryOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getCountry, [getCountry]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    useEffect(() => {
        if (countryOptions.length > 0) {
            let defaultCountry;
            const storedCountry = localStorage.getItem("selectedCountry");
            if (storedCountry) {
                defaultCountry = parseInt(storedCountry, 10);
            } else {
                switch (locale) {
                    case "fr":
                        defaultCountry = 77;
                        break;
                    case "en":
                        defaultCountry = 71;
                        break;
                    case "de":
                        defaultCountry = 5;
                        break;
                    case "ja":
                        defaultCountry = 112;
                        break;
                    case "ko":
                        defaultCountry = 54;
                        break;
                }
            }
            if (defaultCountry !== null) {
                setSelectedCountry(defaultCountry);
            }
        }
    }, [countryOptions]);

    const [mainChart, setMainChart] = useState({
        top: "",
        start_date: null,
        end_date: null,
    });
    const getMainChart = useCallback(() => {
        if (selectedCountry) {
            api.get(`statistics/industry/${selectedCountry}/main-chart`)
                .then((response) => {
                    setMainChart(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [selectedCountry]);

    useEffect(getMainChart, [getMainChart]);

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        let updatedWidgets;
        const widgetsPositionString = localStorage.getItem("widgetsPosition");
        const widgetsPosition = widgetsPositionString
            ? JSON.parse(widgetsPositionString)
            : [];

        if (widgetsPosition.length > 0) {
            updatedWidgets = widgetsPosition;
        } else {
            updatedWidgets = [
                {
                    id: "wid_1",
                    items: [{ id: "top_title" }, { id: "top_progression" }],
                },
                {
                    id: "wid_2",
                    items: [
                        { id: "top_artist" },
                        { id: "top_emerging" },
                        { id: "sexe_repartition" },
                    ],
                },
                {
                    id: "wid_3",
                    items: [
                        { id: "genre_repartition" },
                        { id: "language_repartition" },
                        { id: "current_catalog" },
                    ],
                },
            ];
        }
        const widgetsColumn = updatedWidgets.map((widgetGroup) => ({
            id: widgetGroup.id,
            items: widgetGroup.items.map((item) => ({
                id: item.id,
                widget: generateWidget(
                    item.id,
                    selectedCountry,
                    countryOptions,
                    location,
                    locale
                ),
            })),
        }));
        setColumns(widgetsColumn);
    }, [selectedCountry]);

    return (
        <IndustryStatistics
            {...props}
            headerObserverRef={headerObserverRef}
            isHeaderSticky={!headerObserverInView}
            columns={columns}
            handleDragAndDrop={handleDragAndDrop}
            countryOptions={countryOptions}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            mainChart={mainChart}
            locale={locale}
            location={location}
        />
    );
};

export default EnhancedIndustryStatistics;
