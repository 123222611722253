import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import Sandbox from "./Sandbox";
import { PARTNERS, STATUSES } from "./Sandbox.constants";
import { usePartnerAlbums } from "./Table/Table.hooks";

const EnhancedSandbox = (props) => {
    const [popoverState, setPopoverState] = useState({
        show: false,
        row: {},
        target: null,
    });

    const [partnerFilter, setPartnerFilter] = useState(() =>
        PARTNERS.reduce(
            (partnerFilter, id) => ({
                ...partnerFilter,
                [id]: true,
            }),
            {}
        )
    );

    const [statusFilter, setStatusFilter] = useState(() =>
        STATUSES.reduce(
            (statusFilter, status) => ({
                ...statusFilter,
                [status]: true,
            }),
            {}
        )
    );

    const { artistId } = useParams();
    const [searchQuery, setSearchQuery] = useState(null);

    // Reset search query when the artist changes
    useEffect(() => {
        setSearchQuery(null);
    }, [artistId]);

    const { isLoading, albums } = usePartnerAlbums(artistId, searchQuery);

    const showPopover = useCallback(
        (row, target) =>
            setPopoverState({
                show: true,
                row: row,
                target: target,
            }),
        []
    );
    const hidePopover = useCallback(
        () =>
            setPopoverState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const [matchingsState, setMatchingsState] = useState({
        show: false,
        matchings: [],
    });

    const showMatchings = useCallback(
        (matchings) =>
            setMatchingsState({
                show: true,
                matchings: matchings,
            }),
        []
    );
    const hideMatchings = useCallback(
        () =>
            setMatchingsState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const [tracklistComparisonState, setTracklistComparisonState] = useState({
        show: false,
        partnerAbbreviatedName: null,
        partnerId: null,
    });

    const showTracklistComparison = useCallback(
        (partnerAbbreviatedName, partnerId) =>
            setTracklistComparisonState({
                show: true,
                partnerAbbreviatedName: partnerAbbreviatedName,
                partnerId: partnerId,
            }),
        []
    );
    const hideTracklistComparison = useCallback(
        () =>
            setTracklistComparisonState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const getSameBarcodeAlbums = (albums, product) => {
        const normalizeBarcode = (barcode) => {
            if (typeof barcode === "string") {
                return barcode.replace(/^0+/, "");
            }
            return barcode;
        };

        const productBarcode = normalizeBarcode(product.barcode);

        if (!productBarcode) {
            return [];
        }

        return albums.filter(
            (album) =>
                normalizeBarcode(album.barcode) === productBarcode &&
                album.id !== product.id
        );
    };

    const [copyProductState, setCopyProductState] = useState({
        show: false,
        product: null,
        sameBarcodeProduct: [],
    });

    const showCopyProduct = useCallback((product, albums) => {
        const sameBarcodeAlbums = getSameBarcodeAlbums(albums, product);

        setCopyProductState({
            show: true,
            product: product,
            sameBarcodeProduct: sameBarcodeAlbums,
        });
    }, []);
    const hideCopyProduct = useCallback(
        () =>
            setCopyProductState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    return (
        <>
            <Sandbox
                {...props}
                popoverState={popoverState}
                showPopover={showPopover}
                hidePopover={hidePopover}
                matchingsState={matchingsState}
                showMatchings={showMatchings}
                hideMatchings={hideMatchings}
                tracklistComparisonState={tracklistComparisonState}
                showTracklistComparison={showTracklistComparison}
                hideTracklistComparison={hideTracklistComparison}
                copyProductState={copyProductState}
                showCopyProduct={showCopyProduct}
                hideCopyProduct={hideCopyProduct}
                partnerFilter={partnerFilter}
                setPartnerFilter={setPartnerFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                albums={albums}
                isLoading={isLoading}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
        </>
    );
};

export default EnhancedSandbox;
