import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReleasesLink from "./ReleasesLink";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Releases = ({
    releases,
    isLoading,
    columns,
    selectRow,
    rowClasses,
    defaultSorted,
    modalState,
    setModalState,
    location,
}) => {
    return (
        <>
            <Widget
                title="album.view.profileReleases.head"
                subtitle={<I18n t="album.view.profileReleases.subtitle" />}
                isLoading={isLoading}
                actions={
                    <Tippy
                        content={I18n.getTranslation(
                            location,
                            "album.view.profileReleases.description"
                        )}
                        trigger="mouseenter"
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="2x"
                                fixedWidth
                            />
                        </span>
                    </Tippy>
                }
            >
                <Widget.Body
                    className="widget-table-overflow border-top"
                    style={{ height: "24rem", overflow: "auto" }}
                >
                    <BootstrapTable
                        keyField="id"
                        data={releases}
                        columns={columns}
                        bootstrap4
                        condensed
                        hover
                        bordered={false}
                        noDataIndication={
                            <I18n t="album.view.profileReleases.empty" />
                        }
                        classes="table-header-fixed table-layout-auto table-selectable mb-0 table-truncate"
                        selectRow={selectRow}
                        rowClasses={rowClasses}
                        defaultSorted={defaultSorted}
                    />
                </Widget.Body>
            </Widget>
            <ReleasesLink
                {...modalState}
                handleClose={() => setModalState({ show: false, links: [] })}
            />
        </>
    );
};

export default Releases;
