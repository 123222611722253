import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputFile from "shared/components/Form/InputFile";
import LabelPromoSelect from "shared/components/LabelPromoSelect";
import InputDate from "shared/components/Form/InputDate";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import moment from "moment";

const LabelUploadModal = ({
    show,
    handleHide,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    withLabel,
}) => {
    return (
        <Modal show={show} onHide={handleHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="common.labelUploadModal.head" />
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body>
                    <Form.Group>
                        <Controller
                            control={control}
                            name="file"
                            render={({
                                field: { onChange, value, name, ref },
                            }) => (
                                <InputFile
                                    name={name}
                                    ref={ref}
                                    dataBrowse={I18n.getTranslation(
                                        location,
                                        "common.labelUploadModal.browse"
                                    )}
                                    onChange={(e) =>
                                        onChange(e.target.files[0])
                                    }
                                    label={
                                        value !== null
                                            ? value.name
                                            : I18n.getTranslation(
                                                  location,
                                                  "common.labelUploadModal.file"
                                              )
                                    }
                                    error={
                                        errors.file && [
                                            I18n.getTranslation(
                                                location,
                                                errors.file.message
                                            ),
                                        ]
                                    }
                                    accept="image/jpeg,image/png,.jpg,.jpeg,.png"
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="common.labelUploadModal.title" />
                        </Form.Label>
                        <InputText
                            {...register("title")}
                            error={
                                errors.title && [
                                    I18n.getTranslation(
                                        location,
                                        errors.title.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="common.labelUploadModal.copyright" />
                        </Form.Label>
                        <InputText
                            {...register("copyright")}
                            error={
                                errors.copyright && [
                                    I18n.getTranslation(
                                        location,
                                        errors.copyright.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="common.labelUploadModal.takedown" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="date_takedown"
                            render={({ field: { onChange, value } }) => (
                                <InputDate
                                    selected={value ? new Date(value) : null}
                                    onChange={(date) => {
                                        onChange(
                                            date
                                                ? moment(date).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : null
                                        );
                                    }}
                                    error={
                                        errors.date_takedown && [
                                            I18n.getTranslation(
                                                location,
                                                errors.date_takedown.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                    {withLabel && (
                        <Form.Group>
                            <Form.Label>
                                <I18n t="common.labelUploadModal.label" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="label"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <LabelPromoSelect
                                        innerRef={ref}
                                        value={
                                            value !== null
                                                ? {
                                                      value: value.id,
                                                      label: value.name,
                                                  }
                                                : null
                                        }
                                        onMatch={(id, label) => {
                                            onChange({
                                                id: id,
                                                name: label,
                                            });
                                        }}
                                        onUnmatch={() => {
                                            onChange(null);
                                        }}
                                        defaultOptions
                                    />
                                )}
                            />
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        <I18n t="utils.button.close" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting && isDirty ? onSubmit : null}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t={`utils.button.upload`} />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LabelUploadModal;
