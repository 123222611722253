import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faUserTimes,
    faAt,
    faCompactDisc,
    faUndoAlt,
    faCheck,
    faTimes,
    faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import EditMatchingsTable from "./EditMatchingsTable";
import { useLocation } from "react-router-dom";

const EnhancedEditMatchingsTable = ({ status, onChangeState, ...props }) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "common.editMatchingsTable.id"),
            headerStyle: {
                width: "20%",
            },
            classes: "text-truncate",
            formatter: (cell, row) => (
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "common.editMatchingsTable.name"
            ),
            formatter: (cell, row) => (
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: "dummy1",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "common.editMatchingsTable.type.title"
            ),
            classes: "text-nowrap",
            headerStyle: {
                width: "5rem",
            },
            formatter: (cell, row) => (
                <>
                    {row.isDiscarded && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.type.discarded"
                            )}
                            className="text-danger"
                        >
                            <FontAwesomeIcon icon={faThumbsDown} fixedWidth />
                        </span>
                    )}
                    {row.manualState == "VALID" && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.type.valid"
                            )}
                            className="text-success"
                        >
                            <FontAwesomeIcon icon={faUser} fixedWidth />
                        </span>
                    )}
                    {row.manualState == "INVALID" && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.type.invalid"
                            )}
                            className="text-danger"
                        >
                            <FontAwesomeIcon icon={faUserTimes} fixedWidth />
                        </span>
                    )}
                    {row.byName && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.type.matchedByName"
                            )}
                        >
                            <FontAwesomeIcon icon={faAt} fixedWidth />
                        </span>
                    )}
                    {row.byDiscography && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.type.matchedByDiscography"
                            )}
                        >
                            <FontAwesomeIcon icon={faCompactDisc} fixedWidth />
                        </span>
                    )}
                </>
            ),
        },
        {
            dataField: "representativity",
            text: I18n.getTranslation(
                location,
                "common.editMatchingsTable.representativity"
            ),
            headerStyle: {
                width: "5rem",
            },
            formatter: (cell) => (
                <span
                    className={`font-weight-bold text-${
                        cell > 0.1 ? "success" : "danger"
                    }`}
                >
                    {Math.round(cell * 100)} %
                </span>
            ),
        },
        {
            dataField: "dummy2",
            isDummy: true,
            text: "",
            classes: "text-nowrap text-right",
            headerStyle: {
                width: "5rem",
            },
            formatter: (cell, row) => (
                <>
                    {row.manualState == "INVALID" ? (
                        <button
                            className="btn bg-transparent p-0 border-0 text-secondary"
                            onClick={() => onChangeState(row.id, "NONE")}
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.actions.cancelInvalidation"
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faUndoAlt}
                                size="lg"
                                fixedWidth
                            />
                        </button>
                    ) : (
                        <button
                            className="btn bg-transparent p-0 border-0 text-danger"
                            onClick={() => onChangeState(row.id, "INVALID")}
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.actions.invalidate"
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                size="lg"
                                fixedWidth
                            />
                        </button>
                    )}
                    {row.manualState == "VALID" ? (
                        <button
                            className="btn bg-transparent p-0 border-0 text-secondary"
                            onClick={() => onChangeState(row.id, "NONE")}
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.actions.cancelValidation"
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faUndoAlt}
                                size="lg"
                                fixedWidth
                            />
                        </button>
                    ) : (
                        <button
                            className="btn bg-transparent p-0 border-0 text-success"
                            onClick={() => onChangeState(row.id, "VALID")}
                            title={I18n.getTranslation(
                                location,
                                "common.editMatchingsTable.actions.validate"
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                size="lg"
                                fixedWidth
                            />
                        </button>
                    )}
                </>
            ),
        },
    ];

    const rowClasses = (row) => {
        if (row.manualState == "INVALID" || row.isDiscarded) {
            return "table-secondary";
        }

        if (row.isMatching) {
            if (status == "duplicates") {
                return "table-primary";
            }
            return "table-success";
        }

        return null;
    };

    return (
        <EditMatchingsTable
            {...props}
            columns={columns}
            rowClasses={rowClasses}
        />
    );
};

export default EnhancedEditMatchingsTable;
