import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Widget from "shared/components/Widget/Widget.container";
import Create from "pages/ArtistManagement/Discography/Actions/Track/Create/Create.container";

const Tracks = ({
    tracks,
    columns,
    isLoading,
    selectRow,
    defaultSorted,
    onColumnMatch,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <ToolkitProvider
            keyField="id_track"
            data={tracks}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <Widget
                    title="artist.management.disco.tracks.head"
                    actions={<Create />}
                >
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-12">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "artist.management.disco.tracks.search"
                                    )}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body
                        className="widget-table-overflow"
                        style={{
                            height: "250px",
                            overflow: "auto",
                            borderTop: "1px solid #dee2e6",
                        }}
                    >
                        {isLoading ? (
                            <Loader
                                className="h-100 align-items-center"
                                size="3x"
                            />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                striped
                                hover
                                bordered={false}
                                condensed
                                noDataIndication={
                                    <I18n t="artist.management.disco.tracks.empty" />
                                }
                                classes="table-header-fixed table-truncate mb-0 table-selectable"
                                selectRow={selectRow}
                                defaultSorted={defaultSorted}
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Tracks;
