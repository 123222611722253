import React from "react";
import I18n from "shared/lib/I18n";
import { Overlay, Popover, Table } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import "./ProductPopover.css";

const ProductPopover = ({
    show,
    row: {
        id,
        title,
        barcode,
        label,
        releaseDate,
        coverArtUrl,
        artistId,
        artistName,
    },
    target,
}) => {
    return (
        <Overlay show={show} target={target} placement="bottom">
            <Popover
                className="disco-sandbox-popover"
                id="disco-sandbox-popover"
            >
                <Popover.Content>
                    <Table borderless className="mb-0">
                        <tbody>
                            {id != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.id" />
                                    </th>
                                    <td>{id}</td>
                                </tr>
                            )}
                            {title != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.title" />
                                    </th>
                                    <td>{title}</td>
                                </tr>
                            )}
                            {artistId != null && artistName != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.artist" />
                                    </th>
                                    <td>
                                        {artistName} ({artistId})
                                    </td>
                                </tr>
                            )}
                            {barcode != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.barcode" />
                                    </th>
                                    <td>{barcode}</td>
                                </tr>
                            )}
                            {label != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.label" />
                                    </th>
                                    <td>{label}</td>
                                </tr>
                            )}
                            {releaseDate != null && (
                                <tr>
                                    <th scope="row">
                                        <I18n t="artist.management.disco.sandbox.popover.release_date" />
                                    </th>
                                    <td>{releaseDate}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {coverArtUrl != null && (
                        <ImageLoader
                            className="cover-art mt-2"
                            src={coverArtUrl}
                            errorMessage={
                                <I18n t="artist.management.disco.sandbox.popover.cover_art_error" />
                            }
                            alt="cover art"
                        />
                    )}
                </Popover.Content>
            </Popover>
        </Overlay>
    );
};

export default ProductPopover;
