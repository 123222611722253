import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader/Loader";
import ResultsListItem from "./ResultsListItem";

const ResultsList = ({ isLoading, results }) => {
    if (isLoading) {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Loader />
                </div>
            </div>
        );
    }

    if (results === null) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <p>
                        <I18n
                            t="artistTitle.resultsList.count"
                            args={results.totalHits}
                        />
                    </p>
                </div>
            </div>

            {results.hits.map((item, index) => (
                <ResultsListItem key={index} {...item} />
            ))}
        </>
    );
};

export default ResultsList;
