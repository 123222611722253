import React from "react";
import I18n from "shared/lib/I18n";
import { Form, Modal, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ArtistSelect from "shared/components/ArtistSelect";
import Loader from "shared/components/Loader";

const PictureMoveModal = ({
    id_artist,
    show,
    handleHide,
    errors,
    isDirty,
    control,
    isSubmitting,
    onSubmit,
    locale,
}) => {
    return (
        <Modal show={show} onHide={handleHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="common.pictureMoveModal.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>
                        <I18n t="common.pictureMoveModal.artist" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="artist"
                        render={({ field: { onChange, value, ref } }) => (
                            <ArtistSelect
                                innerRef={ref}
                                id={value?.id}
                                name={value?.name}
                                onMatch={(id, name) => {
                                    onChange({
                                        id: id,
                                        name: name,
                                    });
                                }}
                                onUnmatch={() => {
                                    onChange(null);
                                }}
                                error={
                                    errors.artist && [
                                        I18n.getTranslation(
                                            location,
                                            errors.artist.message
                                        ),
                                    ]
                                }
                                filterOption={({ value: { id } }) =>
                                    id !== id_artist
                                }
                                isClearable={false}
                                locale={locale}
                            />
                        )}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || !isDirty}
                    onClick={!isSubmitting && isDirty ? onSubmit : null}
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t={`utils.button.transfer`} />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PictureMoveModal;
