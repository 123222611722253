import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Planning from "./Planning";
import logo from "assets/static/images/MusicStory_Symbol_RGB.png";
import Loader from "shared/components/Loader";
import { Helmet } from "react-helmet";

const PlanningsClient = ({
    isLoading,
    planning,
    first,
    last,
    next,
    previous,
    isPlanningLoading,
    setIsPlanningLoading,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.plannings.overview"
                )}`}</title>
            </Helmet>
            <main
                id="content"
                className="content"
                role="main"
                style={{ height: "100%" }}
            >
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="plannings.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="base.nav.plannings.overview" />
                    </li>
                </ol>
                {isLoading ? (
                    <Loader />
                ) : planning ? (
                    <>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <button
                                    type="button"
                                    onClick={previous}
                                    className="btn btn-primary btn-lg"
                                    disabled={
                                        first === planning?.id
                                            ? "disabled"
                                            : undefined
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        size="lg"
                                    />
                                    <span className="ml-3">
                                        <I18n t="utils.button.previous" />
                                    </span>
                                </button>
                                <h3
                                    className="text-center"
                                    style={{ margin: "0 20px" }}
                                >
                                    <I18n t="plannings.client.title" />
                                    <br />
                                    <I18n
                                        t="plannings.client.sub_title"
                                        args={{
                                            title: planning.title,
                                            start_date: planning.start_date,
                                            end_date: planning.end_date,
                                        }}
                                    />
                                </h3>
                                <button
                                    type="button"
                                    onClick={next}
                                    className="btn btn-primary btn-lg"
                                    disabled={
                                        last === planning?.id
                                            ? "disabled"
                                            : undefined
                                    }
                                >
                                    <span className="mr-3">
                                        <I18n t="utils.button.next" />
                                    </span>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="lg"
                                    />
                                </button>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <Planning
                                    planning={planning}
                                    isLoading={isPlanningLoading}
                                    setIsLoading={setIsPlanningLoading}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "calc(100% - 1.5rem - 18px)" }}
                    >
                        <div>
                            <div>
                                <img
                                    style={{ width: "325px" }}
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                            <div>
                                <h2 className="mt-3 text-center">
                                    <strong>
                                        <I18n t="logo" />
                                    </strong>
                                </h2>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default PlanningsClient;
