import React from "react";
import { ProgressBar } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import "./PopularityOverview.css";

const PopularityOverview = ({ popularity, isLoading, location }) => {
    return (
        <Widget
            className="recording-view-popularity-overview"
            title="recording.view.popularityOverview.head"
            isLoading={isLoading}
        >
            <Widget.Body>
                <hr className="my-2" />
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex justify-content-between">
                            <div>
                                <strong>
                                    <I18n t="recording.view.popularityOverview.global.title" />
                                </strong>
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "recording.view.popularityOverview.global.description"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span className="ml-1">
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            fixedWidth
                                        />
                                    </span>
                                </Tippy>
                            </div>
                            <span>
                                {Math.round(popularity.global?.score ?? 0)} %
                                {popularity.global?.rank && (
                                    <>
                                        {" ("}
                                        <I18n
                                            t="utils.nth"
                                            args={popularity.global.rank}
                                        />
                                        {")"}
                                    </>
                                )}
                            </span>
                        </div>
                        <ProgressBar
                            variant="purple-ms"
                            now={popularity.global?.score ?? 0}
                        />
                        <hr className="my-2" />
                        <div className="row">
                            <div className="col-4">
                                <strong className="mr-1">
                                    <I18n t="recording.view.popularityOverview.global.weeks" />
                                </strong>
                                <span>{popularity.global?.weeks ?? 0}</span>
                            </div>
                            <div className="col-4">
                                <strong className="mr-1">
                                    <I18n t="recording.view.popularityOverview.type.label" />
                                </strong>
                                <span>
                                    {popularity.type ? (
                                        <I18n
                                            t={`recording.view.popularityOverview.type.${popularity.type}`}
                                        />
                                    ) : undefined}
                                </span>
                            </div>
                            <div className="col-4">
                                <strong className="mr-1">
                                    <I18n t="recording.view.popularityOverview.peak" />
                                </strong>
                                <span>{popularity.peak ?? ""}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 border-left">
                        <div className="d-flex justify-content-between">
                            <div>
                                <strong>
                                    <I18n t="recording.view.popularityOverview.current.title" />
                                </strong>
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "recording.view.popularityOverview.current.description"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span className="ml-1">
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            fixedWidth
                                        />
                                    </span>
                                </Tippy>
                            </div>
                            <span>
                                {Math.round(popularity.current?.score ?? 0)} %
                                {popularity.current?.rank && (
                                    <>
                                        {" ("}
                                        <I18n
                                            t="utils.nth"
                                            args={popularity.current.rank}
                                        />
                                        {")"}
                                    </>
                                )}
                            </span>
                        </div>
                        <ProgressBar
                            className="progress-bar-muted"
                            now={popularity.current?.score ?? 0}
                        />
                        <hr className="my-2" />
                        <div>
                            <strong className="mr-1">
                                <I18n t="recording.view.popularityOverview.current.weeks" />
                            </strong>
                            <span>{popularity.current?.weeks ?? 0}</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <strong>
                                    <I18n t="recording.view.popularityOverview.gold.title" />
                                </strong>
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "recording.view.popularityOverview.gold.description"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span className="ml-1">
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            fixedWidth
                                        />
                                    </span>
                                </Tippy>
                            </div>
                            <span>
                                {Math.round(popularity.gold?.score ?? 0)} %
                                {popularity.gold?.rank && (
                                    <>
                                        {" ("}
                                        <I18n
                                            t="utils.nth"
                                            args={popularity.gold.rank}
                                        />
                                        {")"}
                                    </>
                                )}
                            </span>
                        </div>
                        <ProgressBar
                            className="progress-bar-muted"
                            now={popularity.gold?.score ?? 0}
                        />
                        <hr className="my-2" />
                        <div>
                            <strong className="mr-1">
                                <I18n t="recording.view.popularityOverview.gold.weeks" />
                            </strong>
                            <span>{popularity.gold?.weeks ?? 0}</span>
                        </div>
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default PopularityOverview;
