import React, { useCallback, useEffect, useState } from "react";
import PlanningsBilling from "./PlanningsBilling";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const EnhancedPlanningsBilling = (props) => {
    const [billings, setBillings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState();
    const { api } = useAPI();
    const location = useLocation();

    const getBillings = useCallback(() => {
        api.get(`plannings/billing`)
            .then((response) => {
                setBillings(response);
                setSelected(response[0]?.id);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getBillings();
    }, [getBillings]);

    return (
        <PlanningsBilling
            {...props}
            isLoading={isLoading}
            billings={billings}
            selected={selected}
            setSelected={setSelected}
            location={location}
        />
    );
};

export default EnhancedPlanningsBilling;
