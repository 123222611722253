import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Promote = ({ user, handleClick, location }) => {
    return (
        <>
            <Button
                variant="success"
                onClick={handleClick}
                title={I18n.getTranslation(
                    location,
                    "user.manage.promote.title"
                )}
                disabled={!user.enabled}
            >
                <FontAwesomeIcon icon={faThumbsUp} />
            </Button>
        </>
    );
};

export default Promote;
