import React, { useContext } from "react";
import Delete from "./Delete";
import { useLocation, useParams, useNavigate } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";
import {
    DiscographyContext,
    TRACK_DEL,
} from "pages/ArtistManagement/Discography";

const EnhancedDelete = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { locale } = useParams();
    const { api } = useAPI();
    const { dispatchDiscography } = useContext(DiscographyContext);
    const { selectedTab, artistId, albumId, releaseId, trackId, recordingId } =
        useParams();

    const handleDelete = () => {
        api.delete(
            `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}`,
            { locale }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.basic.delete.success"
                    )
                );
                dispatchDiscography({
                    type: TRACK_DEL,
                    data: trackId,
                });
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}`
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.basic.delete.error",
                        error.message
                    )
                );
            });
    };

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.disco.objects.track.basic.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.track.basic.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleDelete();
        });
    };

    return <Delete {...props} onClick={onClick} location={location} />;
};

export default EnhancedDelete;
