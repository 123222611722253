import React, { useCallback, useState } from "react";
import useAPI from "shared/hooks/useApi";
import ArtistMatcher from "./ArtistMatcher";

const EnhancedArtistMatcher = ({ onChange, locale, ...props }) => {
    const { api } = useAPI();
    const [show, setShow] = useState(false);

    const fetchCandidates = useCallback(
        (artistId) =>
            api.get(`matching/lm/ms/${artistId}`, {
                locale,
            }),
        [locale]
    );

    const onMatchingChange = useCallback(
        (artistId, candidateId, state) =>
            api
                .post(
                    "matching/track/linemusic/artist",
                    {
                        locale,
                    },
                    {
                        partnerId: artistId,
                        msId: candidateId,
                        state,
                    }
                )
                .then((response) => {
                    onChange(artistId, response.data);
                    return response.candidates;
                }),
        [locale]
    );

    return (
        <ArtistMatcher
            show={show}
            setShow={setShow}
            fetchCandidates={fetchCandidates}
            onMatchingChange={onMatchingChange}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedArtistMatcher;
