import React, { useEffect } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    firstname: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    lastname: yup.string().trim().required("utils.form.required"),
    signature: yup.string().trim().required("utils.form.required"),
});

const EnhancedCreateOrUpdateModal = ({
    show,
    handleClose,
    author,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            firstname: null,
            lastname: null,
            signature: null,
        },
    });

    useEffect(() => {
        reset({
            firstname: author?.firstname || null,
            lastname: author?.lastname || null,
            signature: author?.signature || null,
        });
    }, [reset, author, show]);

    const onSubmit = (data) => {
        let apiRequest;
        if (author?.id) {
            apiRequest = api.put(`author/${author.id}`, { locale }, data);
        } else {
            apiRequest = api.post(`author`, {}, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `author.manage.${
                            author?.id ? "update" : "create"
                        }.success`
                    )
                );
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `author.manage.${
                            author?.id ? "update" : "create"
                        }.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <CreateOrUpdateModal
            {...props}
            show={show}
            author={author}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
