import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import Loader from "shared/components/Loader";

const YearSelect = ({
    isLoading,
    options,
    selectedOption,
    onChange,
    getOptionValue,
    getOptionLabel,
}) => {
    if (isLoading) {
        return <Loader size="1x" className="d-inline-block" />;
    }

    return (
        <InputSelect
            options={options}
            value={selectedOption}
            onChange={onChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isClearable={false}
            className="d-inline-block"
        />
    );
};

export default YearSelect;
