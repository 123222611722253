import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const Filter = ({
    location,
    statusOptions,
    selectedStatusOption,
    firstLetterOptions,
    selectedFirstLetterOption,
    setFilter,
}) => {
    return (
        <>
            <div className="col-4">
                <InputSelect
                    options={statusOptions}
                    value={selectedStatusOption}
                    onChange={(opt) =>
                        setFilter((prev) => ({
                            ...prev,
                            status: opt?.value ?? null,
                        }))
                    }
                    styles={{
                        valueContainer: (provided) => ({
                            ...provided,
                            padding: "0 3px 0 6px",
                            lineHeight: "1.5",
                            fontSize: "1rem",
                        }),
                    }}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.chart.filter.status.placeholder"
                    )}
                />
            </div>
            <div className="col-3">
                <InputSelect
                    options={firstLetterOptions}
                    value={selectedFirstLetterOption}
                    onChange={(opt) =>
                        setFilter((prev) => ({
                            ...prev,
                            firstLetter: opt?.value ?? null,
                        }))
                    }
                    styles={{
                        valueContainer: (provided) => ({
                            ...provided,
                            padding: "0 3px 0 6px",
                            lineHeight: "1.5",
                            fontSize: "1rem",
                        }),
                    }}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.chart.filter.firstLetter"
                    )}
                />
            </div>
        </>
    );
};

export default Filter;
