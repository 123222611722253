import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import * as yup from "yup";
import I18n from "shared/lib/I18n";

const schema = {
    no_cd: yup.number().required().positive().integer(),
    no_piste: yup.number().required().positive().integer(),
    title: yup.string().ensure().required().trim(),
    subtitle: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    isrc: yup
        .string()
        .uppercase()
        .transform((value) => value?.replace(/[\s-]/g, ""))
        .matches(/^[A-Z]{2}[A-Z0-9]{3}\d{7}$/, "utils.form.isrc_format")
        .transform((value) => value || null)
        .nullable(),
    duration: yup
        .string()
        .ensure()
        .required()
        .trim()
        .matches(/^(?:(\d{1,2}):|)(\d{1,2}):(\d{2})$/)
        // Convert M:SS, MM:SS and H:MM:SS to HH:MM:SS
        .transform((value) => {
            const match = value.match(/^(?:(\d{1,2}):|)(\d{1,2}):(\d{2})$/);
            if (!match) {
                return value;
            }
            return [
                (match[1] ?? "").padStart(2, "0"),
                match[2].padStart(2, "0"),
                match[3].padStart(2, "0"),
            ].join(":");
        }),
    hit: yup
        .string()
        .transform((value) => (value !== "-" ? value : null))
        .nullable(),
    discovery: yup
        .string()
        .transform((value) => (value !== "-" ? value : null))
        .nullable(),
};

const findDuplicates = (tracks, compareFunction) =>
    [...tracks].sort(compareFunction).reduce(
        (duplicates, track, i, sortedTracks) => ({
            ...duplicates,
            [track.id_track]:
                (i > 0 && compareFunction(track, sortedTracks[i - 1]) === 0) ||
                (i + 1 < sortedTracks.length &&
                    compareFunction(track, sortedTracks[i + 1]) === 0),
        }),
        {}
    );

// Returns an object with the following keys:
// - tracks: an array of tracks
// - errors: an object containing the errors that occured when validating the
//   tracks. errors[id][column] is the error that occured when validating a column
//   of the track whose id is `id` (or null if there was no error).
// - isValid: true if there was no validation errors
// - dirtyCells: an object containing booleans indicating if a cell as be
//   modified. dirtyCells[id][column] is true if the column `column` of the track
//   with id `id` has been modified.
// - isDirty: true if at least one cell has been modified.
// - resetTracks(): a function that resets the tracks to there original values.
// - setTrackField(id, column, value): a function that sets the value of a
//   tracks's field, given the track id, the name of the column, and the new
//   value.
export const useTracks = (defaultTracks) => {
    const location = useLocation();

    const validateTrackField = useCallback(
        (column, value) => {
            let error = null;

            if (schema[column]) {
                try {
                    value = schema[column].validateSync(value);
                } catch (e) {
                    if (e instanceof yup.ValidationError) {
                        error =
                            I18n.getTranslation(
                                location,
                                `artist.management.disco.objects.release.tracklist.tracks.validation.${column}`
                            ) || e.errors.join(", ");
                    } else {
                        throw e;
                    }
                }
            }

            return {
                validatedValue: value,
                error,
            };
        },
        [location]
    );

    const validateTrack = useCallback(
        (track) =>
            Object.entries(track).reduce(
                ({ validatedTrack, errors }, [column, value]) => {
                    const { validatedValue, error } = validateTrackField(
                        column,
                        value
                    );

                    return {
                        validatedTrack: {
                            ...validatedTrack,
                            [column]: validatedValue,
                        },
                        errors: { ...errors, [column]: error },
                    };
                },
                { validatedTrack: {}, errors: {} }
            ),
        [validateTrackField]
    );

    const { validatedTracks: validatedDefaultTracks, errors: defaultErrors } =
        useMemo(() => {
            return defaultTracks.reduce(
                ({ validatedTracks, errors }, track) => {
                    const { validatedTrack, errors: trackErrors } =
                        validateTrack(track);

                    return {
                        validatedTracks: [...validatedTracks, validatedTrack],
                        errors: { ...errors, [track.id_track]: trackErrors },
                    };
                },
                {
                    validatedTracks: [],
                    errors: {},
                }
            );
        }, [validateTrack, defaultTracks]);

    // Store the tracks, schema errors and default tracks in the same state,
    // because they need to be consistent with each other.
    const [{ tracks, schemaErrors, defaultTracksById }, setTracksState] =
        useState({
            tracks: [],
            schemaErrors: {},
            defaultTracksById: [],
        });

    const resetTracks = useCallback(() => {
        setTracksState({
            tracks: validatedDefaultTracks,
            schemaErrors: defaultErrors,
            defaultTracksById: validatedDefaultTracks.reduce(
                (defaultTracksById, track) => ({
                    ...defaultTracksById,
                    [track.id_track]: track,
                }),
                {}
            ),
        });
    }, [validatedDefaultTracks]);

    // Reset the tracks when the default tracks change (meaning that the
    // selected release has changed)
    useEffect(resetTracks, [resetTracks]);

    const setTrackField = (id, column, value) => {
        setTracksState(({ tracks, schemaErrors, defaultTracksById }) => {
            const { validatedValue, error } = validateTrackField(column, value);

            return {
                tracks: tracks.map((track) =>
                    track.id_track === id
                        ? {
                              ...track,
                              [column]: validatedValue,
                          }
                        : track
                ),
                schemaErrors: {
                    ...schemaErrors,
                    [id]: {
                        ...schemaErrors[id],
                        [column]: error,
                    },
                },
                defaultTracksById: defaultTracksById,
            };
        });
    };

    // Add errors to tracks with duplicate ISRC or duplicate disc and track number.
    const errors = useMemo(() => {
        const duplicatePosition = findDuplicates(tracks, (a, b) =>
            a.no_cd === b.no_cd ? a.no_piste - b.no_piste : a.no_cd - b.no_cd
        );
        const duplicateIsrc = findDuplicates(
            tracks.filter((track) => track.isrc !== null),
            (a, b) => (a.isrc < b.isrc ? -1 : a.isrc > b.isrc ? 1 : 0)
        );
        return Object.entries(schemaErrors).reduce(
            (errors, [id, trackErrors]) => ({
                ...errors,
                [id]: {
                    ...trackErrors,
                    no_cd:
                        trackErrors.no_cd === null &&
                        trackErrors.no_piste === null &&
                        duplicatePosition[id]
                            ? I18n.getTranslation(
                                  location,
                                  "artist.management.disco.objects.release.tracklist.tracks.validation.duplicatePosition"
                              )
                            : trackErrors.no_cd,
                    no_piste:
                        trackErrors.no_cd === null &&
                        trackErrors.no_piste === null &&
                        duplicatePosition[id]
                            ? I18n.getTranslation(
                                  location,
                                  "artist.management.disco.objects.release.tracklist.tracks.validation.duplicatePosition"
                              )
                            : trackErrors.no_piste,
                    isrc:
                        trackErrors.isrc === null && duplicateIsrc[id]
                            ? I18n.getTranslation(
                                  location,
                                  "artist.management.disco.objects.release.tracklist.tracks.validation.duplicateIsrc"
                              )
                            : trackErrors.isrc,
                },
            }),
            {}
        );
    }, [location, tracks, schemaErrors]);

    const isValid = useMemo(
        () =>
            Object.values(errors).every((trackErrors) =>
                Object.values(trackErrors).every(
                    (columnError) => columnError === null
                )
            ),
        [errors]
    );

    const dirtyCells = useMemo(
        () =>
            tracks.reduce(
                (dirtyCells, track) => ({
                    ...dirtyCells,
                    [track.id_track]: Object.entries(track).reduce(
                        (trackDirty, [column, value]) => ({
                            ...trackDirty,
                            [column]:
                                value !==
                                defaultTracksById[track.id_track][column],
                        }),
                        {}
                    ),
                }),
                {}
            ),
        [tracks, defaultTracksById]
    );

    const isDirty = useMemo(
        () =>
            Object.values(dirtyCells).some((trackDirty) =>
                Object.values(trackDirty).some((cellDirty) => cellDirty)
            ),
        [dirtyCells]
    );

    return {
        tracks,
        errors,
        isValid,
        dirtyCells,
        isDirty,
        resetTracks,
        setTrackField,
    };
};
