import React, { useState } from "react";
import { PARTNERS } from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";
import PartnerFilter from "./PartnerFilter";

const EnhancedPartnerFilter = ({
    partnerFilter,
    setPartnerFilter,
    ...props
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const onPartnerChange = (event) => {
        const id = event.target.name;

        setPartnerFilter((partnerFilter) => ({
            ...partnerFilter,
            [id]: !partnerFilter[id],
        }));
    };

    const setAllFilters = (val) =>
        setPartnerFilter(
            PARTNERS.reduce(
                (partnerFilter, id) => ({
                    ...partnerFilter,
                    [id]: val,
                }),
                {}
            )
        );

    const total = PARTNERS.length;
    const selected = PARTNERS.filter((id) => partnerFilter[id]).length;

    return (
        <PartnerFilter
            partnerFilter={partnerFilter}
            total={total}
            selected={selected}
            onPartnerChange={onPartnerChange}
            setAllFilters={setAllFilters}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            {...props}
        />
    );
};

export default EnhancedPartnerFilter;
