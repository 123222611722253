import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import clsx from "clsx";
import styles from "./List.module.css";

const List = ({ list, isLoading, columns, defaultSorted, selectRow }) => {
    return (
        <Widget title={`matching.artist.list.head`} isLoading={isLoading}>
            <Widget.Body
                className={clsx(
                    "widget-table-overflow overflow-auto border-top",
                    styles.body
                )}
            >
                <BootstrapTable
                    keyField="id"
                    data={list}
                    columns={columns}
                    bootstrap4
                    hover
                    striped
                    bordered={false}
                    noDataIndication={<I18n t="matching.artist.list.empty" />}
                    classes="table-header-fixed table-selectable mb-0"
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                />
            </Widget.Body>
        </Widget>
    );
};

export default List;
