import React from "react";
import I18n from "shared/lib/I18n";
import CreateForm from "./CreateForm";
import CreateResult from "./CreateResult";
import { Helmet } from "react-helmet";
import "./ArtistCreate.css";

const ArtistCreate = ({
    artists,
    isLoading,
    hasSearch,
    setState,
    location,
}) => {
    return (
        <div className="content-wrap artist-create">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "artist.create.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="artist.create.breadcrumb" />
                    </li>
                </ol>
                <CreateForm hasSearch={hasSearch} setState={setState} />
                <CreateResult
                    artists={artists}
                    isLoading={isLoading}
                    hasSearch={hasSearch}
                />
            </main>
        </div>
    );
};

export default ArtistCreate;
