import React from "react";
import I18n from "shared/lib/I18n";
import { Modal, Button } from "react-bootstrap";
import Loader from "shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./PictureCropModal.css";

const PictureCropModal = ({
    show,
    handleHide,
    handleSubmit,
    isSubmitLoading,
    cropperRef,
    onCrop,
    onReady,
    rotateLeft,
    rotateRight,
    cropData,
    isDisabled,
    isLoading,
    picture,
    location,
}) => {
    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            dialogClassName="modal-full"
            className="picture-crop-modal"
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="common.pictureCropModal.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8">
                                <div className="img-container">
                                    <Cropper
                                        style={{ width: "100%" }}
                                        preview=".img-preview"
                                        src={picture?.url}
                                        viewMode={1}
                                        dragMode="move"
                                        crop={onCrop}
                                        ready={onReady}
                                        ref={cropperRef}
                                        autoCrop={false}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="container-preview mb-3">
                                    <div className="img-preview"></div>
                                </div>
                                <div className="form-group">
                                    <div
                                        className={`input-group mb-3 ${
                                            isDisabled && "has-error"
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <label
                                                className="input-group-text control-label"
                                                htmlFor="crop_width"
                                            >
                                                <I18n t="common.pictureCropModal.width" />
                                            </label>
                                        </div>
                                        <input
                                            className="form-control"
                                            readOnly="readonly"
                                            placeholder={cropData.width}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                px
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={`input-group ${
                                            isDisabled && "has-error"
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <label
                                                className="input-group-text control-label"
                                                htmlFor="crop_height"
                                            >
                                                <I18n t="common.pictureCropModal.height" />
                                            </label>
                                        </div>
                                        <input
                                            type="number"
                                            className="form-control"
                                            readOnly="readonly"
                                            placeholder={cropData.height}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                px
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="btn-group" role="group">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={rotateLeft}
                                            title={I18n.getTranslation(
                                                location,
                                                "common.pictureCropModal.rotateLeft"
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faUndo} />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={rotateRight}
                                            title={I18n.getTranslation(
                                                location,
                                                "common.pictureCropModal.rotateRight"
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faRedo} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isDisabled || isSubmitLoading || !picture?.url}
                    onClick={
                        !isSubmitLoading && !isDisabled && picture?.url
                            ? handleSubmit
                            : null
                    }
                >
                    {isSubmitLoading ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t="utils.button.save" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PictureCropModal;
