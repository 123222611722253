import React from "react";
import Chart from "./Chart";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router-dom";

const EnhancedChart = React.memo(
    ({ sexe_repartition, setPopover, ...props }) => {
        const location = useLocation();

        const data = {
            labels: [
                I18n.getTranslation(location, "landing.industryStats.person"),
                I18n.getTranslation(location, "landing.industryStats.band"),
            ],
            datasets: [
                {
                    label: I18n.getTranslation(
                        location,
                        "landing.industryStats.man"
                    ),
                    data: [
                        sexe_repartition.find((item) => item.sexe === "Homme")
                            ?.count,
                        0,
                    ],
                    backgroundColor: "#000000",
                    borderRadius: 2,
                },
                {
                    label: I18n.getTranslation(
                        location,
                        "landing.industryStats.woman"
                    ),
                    data: [
                        sexe_repartition.find((item) => item.sexe === "Femme")
                            ?.count,
                        0,
                    ],
                    backgroundColor: "#FF00FF",
                    borderRadius: 2,
                },
                {
                    label: I18n.getTranslation(
                        location,
                        "landing.industryStats.other"
                    ),
                    data: [
                        sexe_repartition.find((item) => item.sexe === "Autre")
                            ?.count,
                        0,
                    ],
                    backgroundColor: "#8866E5",
                    borderRadius: 2,
                },
                {
                    label: I18n.getTranslation(
                        location,
                        "landing.industryStats.band"
                    ),
                    data: [
                        0,
                        sexe_repartition.find((item) => item.sexe === null)
                            ?.count,
                    ],
                    backgroundColor: "#3900D4",
                    borderRadius: 2,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            animation: false,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
            plugins: {
                legend: {
                    position: "top",
                },
                tooltip: {
                    enabled: false,
                    external: ({ chart, tooltip }) => {
                        const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
                        const datasetIndex =
                            tooltip.dataPoints?.[0]?.datasetIndex;
                        let selectedRepartition = null;

                        if (
                            datasetIndex !== undefined &&
                            dataIndex !== undefined
                        ) {
                            const datasetLabel =
                                chart.data.datasets[datasetIndex].label;
                            if (
                                datasetLabel ===
                                I18n.getTranslation(
                                    location,
                                    "landing.industryStats.man"
                                )
                            ) {
                                selectedRepartition = sexe_repartition.find(
                                    (item) => item.sexe === "Homme"
                                );
                            } else if (
                                datasetLabel ===
                                I18n.getTranslation(
                                    location,
                                    "landing.industryStats.woman"
                                )
                            ) {
                                selectedRepartition = sexe_repartition.find(
                                    (item) => item.sexe === "Femme"
                                );
                            } else if (
                                datasetLabel ===
                                I18n.getTranslation(
                                    location,
                                    "landing.industryStats.other"
                                )
                            ) {
                                selectedRepartition = sexe_repartition.find(
                                    (item) => item.sexe === "Autre"
                                );
                            } else if (
                                datasetLabel ===
                                I18n.getTranslation(
                                    location,
                                    "landing.industryStats.band"
                                )
                            ) {
                                selectedRepartition = sexe_repartition.find(
                                    (item) => item.sexe === null
                                );
                            }
                        }

                        setPopover({
                            show: tooltip.opacity > 0,
                            left: chart.canvas.offsetLeft + tooltip.caretX,
                            top: chart.canvas.offsetTop + tooltip.caretY,
                            sexe_repartition: selectedRepartition,
                        });
                    },
                },
            },
        };

        return <Chart {...props} data={data} options={options} />;
    }
);

export default EnhancedChart;
