import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light-border.css";
import "./Tooltip.style.css";

const Tooltip = ({
    value,
    content,
    visible,
    show,
    hide,
    btnColor = "secondary",
}) => {
    return (
        <Tippy
            content={<div className="matching-tooltip">{content}</div>}
            visible={visible}
            theme="light-border"
            arrow={false}
            interactive={true}
            duration="100"
            onClickOutside={hide}
        >
            <button
                className={`btn btn-${btnColor} btn-sm`}
                onClick={visible ? hide : show}
            >
                {value}
            </button>
        </Tippy>
    );
};

export default Tooltip;
