import React, { useReducer, useMemo } from "react";
import { ArtistManagementContext } from "./ArtistManagement.context";
import { ArtistManagementReducer } from "./ArtistManagement.reducer";

const initialState = {
    artist: {},
    namesUpToDate: true,
    basUpToDate: true,
};

export const ArtistManagementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ArtistManagementReducer, initialState);
    const contextValue = useMemo(
        () => ({
            dispatchArtist: dispatch,
            artist: state.artist,
            namesUpToDate: state.namesUpToDate,
            basUpToDate: state.basUpToDate,
        }),
        [state, dispatch]
    );

    return (
        <ArtistManagementContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </ArtistManagementContext.Provider>
    );
};
