import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import { CHARTS_MODE } from "shared/constants/Charts";
import ChartsMatching from "./ChartsMatching";

const EnhancedChartsMatching = (props) => {
    const navigate = useNavigate();
    const { locale } = useParams();
    const { categoryId, chartId } = useIntParams();
    const location = useLocation();

    const onSelectCategory = (newCategoryId) => {
        navigate(`/${locale}/matching/tops/${newCategoryId}`);
    };
    const onSelectChart = (newChartId) => {
        navigate(`/${locale}/matching/tops/${categoryId}/${newChartId}`);
    };

    return (
        <ChartsMatching
            categoryId={categoryId}
            chartId={chartId}
            mode={CHARTS_MODE.MATCHING}
            onSelectCategory={onSelectCategory}
            onSelectChart={onSelectChart}
            location={location}
            {...props}
        />
    );
};

export default EnhancedChartsMatching;
