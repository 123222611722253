import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import { ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import CreateOrUpdateButton from "./CreateOrUpdateButton";
import DeleteButton from "./DeleteButton";
import CNMDistributors from "./CNMDistributors";

const EnhancedCNMDistributors = ({ year, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const { user } = useUser();

    const [state, setState] = useState({
        isLoading: true,
        distributors: [],
        yearStatus: null,
    });

    const editable =
        user.hasRight("cnm.repository.cnm_distributors.manage") &&
        state.yearStatus === "IN_PROGRESS";

    const fetchDistributors = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            distributors: [],
            yearStatus: null,
        });
        api.get(`cnm/repository/cnm_distributors/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        ...response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        distributors: [],
                        yearStatus: null,
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchDistributors, [fetchDistributors]);

    const onCreate = (distributor) => {
        setState((state) => ({
            ...state,
            distributors: [...state.distributors, distributor],
        }));
    };

    const onUpdate = ({ id, cnmName, name, type }) => {
        setState((state) => ({
            ...state,
            distributors: state.distributors.map((distributor) =>
                distributor.id === id
                    ? { id, cnmName, name, type }
                    : distributor
            ),
        }));
    };

    const onDelete = ({ id }) => {
        setState((state) => ({
            ...state,
            distributors: state.distributors.filter(
                (distributor) => distributor.id !== id
            ),
        }));
    };

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_distributors.name"
            ),
            filterValue: normalizeTitle,
            sort: true,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_distributors.type"
            ),
            formatter: (cell, row, rowIndex, data) =>
                editable ? (
                    <div className="d-flex align-items-center">
                        <span className="flex-grow-1">{cell}</span>
                        <ButtonGroup>
                            <CreateOrUpdateButton
                                variant="primary"
                                title={data.editTooltip}
                                year={year}
                                distributor={row}
                                onSuccess={onUpdate}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </CreateOrUpdateButton>
                            <DeleteButton
                                year={year}
                                id={row.id}
                                onDelete={onDelete}
                            />
                        </ButtonGroup>
                    </div>
                ) : (
                    cell
                ),
            formatExtraData: {
                editTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_distributors.edit.tooltip"
                ),
                deleteTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_distributors.delete.tooltip"
                ),
            },
            filterValue: normalizeTitle,
            sort: true,
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    return (
        <CNMDistributors
            year={year}
            columns={columns}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
            editable={editable}
            location={location}
            onCreate={onCreate}
            {...state}
            {...props}
        />
    );
};

export default EnhancedCNMDistributors;
