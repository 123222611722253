import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";

const Languages = ({ principal, secondary, isLoading }) => {
    return (
        <Widget
            title="artist.view.universLanguages.head"
            isLoading={isLoading}
            style={{ height: "40rem", overflow: "auto" }}
            headerClassName="sticky-top bg-white"
            headerStyle={{ top: "-15px" }}
        >
            <Widget.Body>
                <hr className="my-2 sticky-top" style={{ top: "37px" }} />
                <div className="row">
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.universLanguages.principal" />
                        </strong>
                        <span className="mx-1">:</span>
                        {principal}
                    </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                    <div className="col d-flex">
                        <strong style={{ minWidth: "fit-content" }}>
                            <I18n t="artist.view.universLanguages.secondary" />
                            <span className="mx-1">:</span>
                        </strong>
                        <div>
                            {secondary.map((row) => {
                                return (
                                    <p key={row} className="mb-1">
                                        {row}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Languages;
