import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { icons } from "./ExternalLink.constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import clsx from "clsx";
import { extractHostname } from "shared/functions/String";
import "./ExternalLink.css";

const ExternalLink = ({ artist, social, site, isLoading }) => {
    return (
        <Widget
            title="artist.view.profileExternalLink.head"
            isLoading={isLoading}
            className="aritst-view-profile-external-link"
        >
            <Widget.Body>
                <hr className="my-2" />
                <div className="row">
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.profileExternalLink.isni" />
                        </strong>
                        <span className="mx-1">:</span>
                        {artist?.isni && (
                            <a
                                href={`https://isni.org/isni/${artist.isni}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {artist.isni}
                            </a>
                        )}
                    </div>
                </div>
                {social.length !== 0 && (
                    <>
                        <hr className="my-2" />
                        <div className="d-flex flex-wrap">
                            {social.map(({ url, id_partner, id_social }) => (
                                <Tippy
                                    key={id_social}
                                    content={url}
                                    trigger="mouseenter"
                                >
                                    <div className={clsx("m-1", id_partner)}>
                                        <a
                                            href={url}
                                            className="text-reset"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                icon={icons[id_partner]}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </a>
                                    </div>
                                </Tippy>
                            ))}
                        </div>
                    </>
                )}
                <hr className="my-2" />
                <div className="row">
                    <div className="col d-flex">
                        <strong>
                            <I18n t="artist.view.profileExternalLink.site" />
                            <span className="mx-1">:</span>
                        </strong>
                        <div>
                            {site.map(({ url }) => {
                                return (
                                    <p key={url} className="mb-1">
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {extractHostname(url)}
                                        </a>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default ExternalLink;
