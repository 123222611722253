import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { TYPE_BAND, TYPE_PERSON } from "shared/constants/cms/artist";
import { displayAsString } from "shared/functions/Date";

const Information = ({ artist, locale }) => {
    return (
        <Widget title="artist.view.profileInformation.head">
            <Widget.Body>
                {artist.type === TYPE_PERSON && (
                    <>
                        <hr className="my-2" />
                        <div className="row my-2">
                            <div className="col border-right">
                                <strong>
                                    <I18n t="artist.view.profileInformation.role" />
                                    <span className="mx-1">:</span>
                                </strong>
                                {artist.locale_role}
                            </div>
                            <div className="col">
                                <strong>
                                    <I18n t="artist.view.profileInformation.gender" />
                                    <span className="mx-1">:</span>
                                </strong>
                                {artist.locale_gender}
                            </div>
                        </div>
                    </>
                )}
                {artist?.id && (
                    <>
                        <hr className="my-2" />
                        <div className="row my-2">
                            <div className="col border-right">
                                <div className="row my-2">
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.born`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {displayAsString(artist.born, locale)}
                                    </div>
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.birth_country`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.birth_country}
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.birth_area`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.birth_area}
                                    </div>
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.birth_city`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.birth_city}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row my-2">
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.died`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {displayAsString(artist.died, locale)}
                                    </div>
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.death_country`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.death_country}
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.death_area`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.death_area}
                                    </div>
                                    <div className="col">
                                        <strong>
                                            <I18n
                                                t={`artist.view.profileInformation.${artist.type}.death_city`}
                                            />
                                            <span className="mx-1">:</span>
                                        </strong>
                                        {artist.death_city}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <hr className="my-2" />
                <div className="row">
                    <div className="col border-right d-flex">
                        <strong style={{ minWidth: "fit-content" }}>
                            <I18n t="artist.view.profileInformation.famousFor" />
                            <span className="mx-1">:</span>
                        </strong>
                        <span align="justify">{artist.famous_for}</span>
                    </div>
                    <div className="col d-flex">
                        <strong>
                            <I18n t="artist.view.profileInformation.awards" />
                            <span className="mx-1">:</span>
                        </strong>
                        <div align="justify">{artist?.awards}</div>
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Information;
