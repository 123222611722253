import React, { useState, useEffect, useCallback } from "react";
import Charts from "./Charts";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { getFilterOptions, getFilteredCharts } from "./Charts.selector";

const defaultState = {
    isLoading: false,
    charts: [],
};

const EnhancedCharts = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const location = useLocation();
    const { locale } = useParams();
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({
        source: null,
        area: null,
        format: null,
        country: null,
        origin: null,
        date_out: null,
    });

    const getCharts = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/charts`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        charts: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getCharts, [getCharts]);

    const columns = [
        {
            dataField: "track_title",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.track_title"
            ),
            sort: true,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.title"
            ),
            sort: true,
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.country"
            ),
            sort: true,
            headerStyle: { width: "13rem" },
        },
        {
            dataField: "date_in",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.date_in"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "date_out",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.date_out"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "nb_week",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.nb_week"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "rank_in",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.rank_in"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "max_rank",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.max_rank"
            ),
            sort: true,
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "rank_out",
            text: I18n.getTranslation(
                location,
                "artist.management.chartsList.rank_out"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Charts
            {...props}
            isLoading={state.isLoading}
            charts={getFilteredCharts({
                charts: state.charts,
                selectedFilterOptions,
            })}
            columns={columns}
            options={options}
            filterOptions={getFilterOptions(state)}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
        />
    );
};

export default EnhancedCharts;
