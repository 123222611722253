import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import "./Cover.css";

const Cover = ({ coverArt }) => {
    return (
        <div className="apple__player__cover">
            <OverlayTrigger
                placement="top"
                overlay={
                    <Popover id={`popover-positioned-top`}>
                        <Popover.Content className="bg-black rounded-sm">
                            <ImageLoader
                                src={coverArt}
                                width="400px"
                                alt="cover art"
                            />
                        </Popover.Content>
                    </Popover>
                }
            >
                <div>
                    <img src={coverArt} width="60" />
                </div>
            </OverlayTrigger>
        </div>
    );
};

export default Cover;
