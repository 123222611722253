import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import useAPI from "shared/hooks/useApi";
import Request from "./Request";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    username: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
});

const defaultValues = {
    username: null,
};

const EnhancedRequest = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [isSend, setIsSend] = useState(false);

    const {
        handleSubmit,
        register,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const handleRequest = (data) => {
        api.request(data);
        setIsSend(true);
    };

    return (
        <Request
            handleRequest={handleSubmit(handleRequest)}
            isSend={isSend}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            location={location}
            {...props}
        />
    );
};

export default EnhancedRequest;
