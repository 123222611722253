import React from "react";
import { useParams } from "react-router";
import GenreLink from "./GenreLink";

const EnhancedGenreLink = (props) => {
    const { locale } = useParams();
    return <GenreLink {...props} locale={locale} />;
};

export default EnhancedGenreLink;
