import React from "react";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    TimeScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

ChartJS.register(
    LinearScale,
    TimeScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const Chart = ({ data, options }) => {
    return <Line data={data} options={options} />;
};

export default Chart;
