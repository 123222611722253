import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import InputTextArea from "shared/components/Form/InputTextArea";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import "./RatingForm.css";
import clsx from "clsx";

const RatingForm = ({
    criteriaOptions,
    errorTypeOptions,
    criteria,
    errorType,
    setValue,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    handleSubmit,
    onSubmit,
    location,
}) => {
    return (
        <Form
            className="m-3"
            onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
        >
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <I18n t="apple.sample.assessment.lyric.rating.criteria" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="criteria"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value,
                                                  label: criteriaOptions.find(
                                                      ({ id }) => value === id
                                                  ).name,
                                              }
                                            : null
                                    }
                                    options={criteriaOptions.map(
                                        ({ id, name }) => {
                                            return { value: id, label: name };
                                        }
                                    )}
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : null);
                                        setValue("errorType", null, {
                                            shouldDirty: false,
                                        });
                                        setValue("comment", null, {
                                            shouldDirty: false,
                                        });
                                    }}
                                    isClearable={false}
                                    error={
                                        errors.criteria && [
                                            I18n.getTranslation(
                                                location,
                                                errors.criteria.message
                                            ),
                                        ]
                                    }
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <I18n t="apple.sample.assessment.lyric.rating.errorType" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="errorType"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value,
                                                  label: errorTypeOptions.find(
                                                      ({ id }) => value === id
                                                  ).name,
                                              }
                                            : null
                                    }
                                    options={errorTypeOptions
                                        .filter(
                                            ({ criteria_id }) =>
                                                criteria_id === criteria
                                        )
                                        .map(({ id, name }) => {
                                            return { value: id, label: name };
                                        })}
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : null);
                                        setValue("comment", null, {
                                            shouldDirty: false,
                                        });
                                    }}
                                    isClearable={false}
                                    isDisabled={!criteria}
                                    error={
                                        errors.errorType && [
                                            I18n.getTranslation(
                                                location,
                                                errors.errorType.message
                                            ),
                                        ]
                                    }
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
                <Form.Group as={Col} className="mb-0">
                    <Form.Label>
                        <I18n t="apple.sample.assessment.lyric.rating.comment" />
                    </Form.Label>
                    <InputTextArea
                        {...register("comment")}
                        className={clsx(
                            "apple__sample__assessment__lyric__form__textarea",
                            !errorType && "disabled"
                        )}
                        disabled={!errorType}
                        error={
                            errors.comment && [
                                I18n.getTranslation(
                                    location,
                                    errors.comment.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col>
                    <Button
                        variant="success"
                        className="float-right"
                        disabled={isSubmitting || !isDirty}
                        onClick={
                            !isSubmitting && isDirty
                                ? handleSubmit(onSubmit)
                                : null
                        }
                        title={I18n.getTranslation(
                            location,
                            `apple.sample.assessment.lyric.rating.create.title`
                        )}
                    >
                        <I18n t={`utils.button.add`} />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default RatingForm;
