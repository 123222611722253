import HubbardArtist from "./Artist/HubbardArtist";
import SeatgeekArtist from "./Artist/SeatgeekArtist";
import UniversalArtist from "./Artist/UniversalArtist";

export const PARTNERS = [
    { id: "hubbard", name: "Hubbard" },
    { id: "seatgeek", name: "Seatgeek" },
    { id: "universal", name: "Universal" },
];

export const PARTNERS_ARTIST_COMPONENT = {
    hubbard: HubbardArtist,
    seatgeek: SeatgeekArtist,
    universal: UniversalArtist,
};
