import React, { useState } from "react";
import CreateResult from "./CreateResult";
import { useLocation, useParams, useNavigate } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import { ListGroup, Button } from "react-bootstrap";
import ArtistLink from "shared/components/ArtistLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagicWandSparkles,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

const EnhancedCreateResult = ({ artists, hasSearch, ...props }) => {
    const location = useLocation();
    const { api } = useAPI();
    const navigate = useNavigate();
    const { locale } = useParams();
    const [loadingId, setLoadingId] = useState();

    const onCreate = (partnerName, partnerId, name) => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.create.create.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.create.create.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            setLoadingId(partnerName + partnerId);

            api.post(
                `artist/create`,
                { locale },
                { search: name, partnerName, partnerId }
            )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.create.create.success`
                        )
                    );

                    navigate(`/${locale}/artist/${response.id_artist}/edit`);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.create.create.error`,
                            error.message
                        )
                    );
                    setLoadingId();
                });
        });
    };

    const columns = [
        {
            dataField: "source",
            text: I18n.getTranslation(location, "artist.create.source"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ? <strong>{cell}</strong> : "-";
            },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "artist.create.name"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "complement",
            text: I18n.getTranslation(location, "artist.create.complement"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "artist.create.type"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "gender",
            text: I18n.getTranslation(location, "artist.create.gender"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "country",
            text: I18n.getTranslation(location, "artist.create.country"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "real_name",
            text: I18n.getTranslation(location, "artist.create.real_name"),
            headerTitle: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "alias",
            text: I18n.getTranslation(location, "artist.create.alias"),
            headerTitle: true,
            formatter: (cell) => {
                if (cell && cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(({ alias }, rowIndex) => {
                                return (
                                    <ListGroup.Item
                                        key={rowIndex}
                                        className="bg-transparent p-1"
                                    >
                                        {alias}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "member",
            text: I18n.getTranslation(location, "artist.create.member"),
            headerTitle: true,
            formatter: (cell) => {
                if (cell && cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(
                                (
                                    {
                                        artist,
                                        date_fin,
                                        date_debut,
                                        id_musicstory,
                                    },
                                    rowIndex
                                ) => {
                                    const date = [date_fin, date_debut];
                                    return (
                                        <ListGroup.Item
                                            key={rowIndex}
                                            className="bg-transparent p-1"
                                        >
                                            <ArtistLink
                                                artistId={id_musicstory}
                                                content={artist}
                                                blank
                                            />
                                            {date.filter(Boolean).length >
                                                0 && (
                                                <>
                                                    <br />
                                                    {date.join(" > ")}
                                                </>
                                            )}
                                        </ListGroup.Item>
                                    );
                                }
                            )}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "band",
            text: I18n.getTranslation(location, "artist.create.band"),
            headerTitle: true,
            formatter: (cell) => {
                if (cell && cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(
                                (
                                    {
                                        artist,
                                        date_fin,
                                        date_debut,
                                        id_musicstory,
                                    },
                                    rowIndex
                                ) => {
                                    const date = [date_fin, date_debut];
                                    return (
                                        <ListGroup.Item
                                            key={rowIndex}
                                            className="bg-transparent p-1"
                                        >
                                            <ArtistLink
                                                artistId={id_musicstory}
                                                content={artist}
                                                blank
                                            />
                                            {date.filter(Boolean).length >
                                                0 && (
                                                <>
                                                    <br />
                                                    {date.join(" > ")}
                                                </>
                                            )}
                                        </ListGroup.Item>
                                    );
                                }
                            )}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "site",
            text: I18n.getTranslation(location, "artist.create.site"),
            headerTitle: true,
            formatter: (cell) => {
                if (cell && cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(({ url, commentaire }, rowIndex) => {
                                return (
                                    <ListGroup.Item
                                        key={rowIndex}
                                        className="bg-transparent p-1"
                                    >
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {url.split("/")[2] || url}
                                        </a>
                                        {commentaire && ` - ${commentaire}`}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "social",
            text: I18n.getTranslation(location, "artist.create.social"),
            headerTitle: true,
            formatter: (cell) => {
                if (cell && cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(({ url }, rowIndex) => {
                                return (
                                    <ListGroup.Item
                                        key={rowIndex}
                                        className="bg-transparent p-1"
                                    >
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {url.split("/")[2] || url}
                                        </a>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(location, "artist.create.actions"),
            formatter: (cell, row, rowIndex, data) => {
                const isLoading = data.loadingId === row.uniqid;
                return (
                    <Button
                        variant="success"
                        disabled={isLoading}
                        onClick={
                            !isLoading
                                ? () => onCreate(row.source, row.id, row.name)
                                : null
                        }
                        type="button"
                    >
                        {isLoading ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                pulse={true}
                                fixedWidth
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faMagicWandSparkles}
                                fixedWidth
                            />
                        )}
                    </Button>
                );
            },
            formatExtraData: {
                loadingId,
            },
            align: "center",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        totalSize: artists.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <CreateResult
            {...props}
            artists={artists}
            columns={columns}
            pagination={pagination}
            location={location}
            hasSearch={hasSearch}
        />
    );
};

export default EnhancedCreateResult;
