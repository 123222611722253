import React from "react";
import I18n from "shared/lib/I18n";
import Task from "./Task";
import Filter from "./Filter";
import Loader from "shared/components/Loader";

const Tasks = ({
    tasks,
    filteredTasks,
    setFilteredTasks,
    isLoading,
    onCheck,
}) => {
    let tasksList = (
        <tr>
            <td colSpan="42" style={{ textAlign: "center" }}>
                <I18n t="plannings.picture.tasks.empty" />
            </td>
        </tr>
    );

    if (filteredTasks.length !== 0) {
        tasksList = filteredTasks.map((task) => (
            <Task key={task.id} task={task} onCheck={onCheck} />
        ));
    }

    return (
        <section className="widget">
            <header>
                <h4>
                    <I18n t="plannings.picture.tasks.title" />
                </h4>
            </header>
            <div className="widget-body" id="matching-form">
                <div className="row">
                    <div className="col-md-12">
                        <Filter
                            tasks={tasks}
                            setFilteredTasks={setFilteredTasks}
                        />
                    </div>
                </div>
            </div>
            <div
                className="widget-body widget-table-overflow"
                style={{
                    maxHeight: "500px",
                    overflow: "auto",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped table-header-fixed"
                        style={{ marginBottom: "0px" }}
                    >
                        <thead>
                            <tr>
                                <th>
                                    <I18n t="plannings.picture.tasks.artist" />
                                </th>
                                <th>
                                    <I18n t="plannings.picture.tasks.album" />
                                </th>
                                <th>
                                    <I18n t="plannings.picture.tasks.track" />
                                </th>
                                <th>
                                    <I18n t="plannings.picture.tasks.latest_added_date" />
                                </th>
                                <th>
                                    <I18n t="plannings.picture.tasks.status" />
                                </th>
                                <th>
                                    <I18n t="plannings.picture.tasks.information" />
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{tasksList}</tbody>
                    </table>
                )}
            </div>
            <footer className="mt-3">
                <I18n
                    t="plannings.picture.tasks.itemsCount"
                    args={filteredTasks.length}
                />
            </footer>
        </section>
    );
};

export default Tasks;
