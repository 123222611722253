import React from "react";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import clsx from "clsx";
import styles from "./Partner.module.css";

const Partner = ({ partners, columns, defaultSorted, selectRow }) => {
    return (
        <Widget title={`matching.artist.partner.head`}>
            <Widget.Body
                className={clsx(
                    "widget-table-overflow overflow-auto border-top",
                    styles.body
                )}
            >
                <BootstrapTable
                    keyField="id"
                    data={partners}
                    columns={columns}
                    bootstrap4
                    hover
                    striped
                    bordered={false}
                    classes="table-header-fixed table-selectable mb-0"
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Partner;
