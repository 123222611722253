import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import ISNISearchModal from "./ISNISearchModal";

const schema = yup.object().shape({
    term: yup.string().trim().required("utils.form.required"),
});

const EnhancedISNISearchModal = ({
    show,
    onHide,
    setISNI,
    getName,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [results, setResults] = useState([]);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: {
            term: "",
        },
    });

    const onSubmit = handleSubmit(async ({ term }) => {
        await api
            .get(`isni/search`, { locale, term })
            .then((response) => {
                setResults(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.profileInformation.isniSearch.error`,
                        error.message
                    )
                );
            });
    });

    useEffect(() => {
        if (show) {
            reset({ term: getName() });
            onSubmit();
        }
    }, [show]);

    const columns = [
        {
            dataField: "isni",
            text: I18n.getTranslation(
                location,
                "artist.management.profileInformation.isniSearch.isni"
            ),
            formatter: (cell, { url }) => {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        {cell}
                    </a>
                );
            },
            headerStyle: {
                width: "11em",
            },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "artist.management.profileInformation.isniSearch.name"
            ),
            formatter: (cell, { url, complement, date }) => {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        {cell}
                        {complement && date
                            ? `(${complement}, ${date})`
                            : complement
                            ? `(${complement})`
                            : date
                            ? `(${date})`
                            : null}
                    </a>
                );
            },
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "",
            formatter: (cell, { isni }) => {
                return (
                    <Button
                        size="sm"
                        variant="success"
                        title={I18n.getTranslation(
                            location,
                            "artist.management.profileInformation.isniSearch.select"
                        )}
                        onClick={() => {
                            setISNI(isni);
                            onHide();
                        }}
                    >
                        <FontAwesomeIcon fixedWidth size="lg" icon={faCheck} />
                    </Button>
                );
            },
            headerStyle: {
                width: "calc(1.25em + 2px + 2.6rem)",
            },
        },
    ];

    return (
        <ISNISearchModal
            location={location}
            show={show}
            onHide={onHide}
            register={register}
            isSubmitting={isSubmitting}
            errors={errors}
            onSubmit={onSubmit}
            columns={columns}
            results={results}
            {...props}
        />
    );
};

export default EnhancedISNISearchModal;
