import React from "react";
import LazyLoad from "react-lazyload";
import Loader from "shared/components/Loader";
import ImageLoader from "shared/components/ImageLoader";

const PictureCard = ({ url }) => {
    return (
        <div className="card">
            <LazyLoad
                height="100%"
                placeholder={<Loader />}
                debounce={200}
                once
            >
                <a href={url} target="_blank">
                    <ImageLoader src={url} className="card-img-top" />
                </a>
            </LazyLoad>
        </div>
    );
};

export default PictureCard;
