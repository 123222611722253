import React from "react";
import I18n from "shared/lib/I18n";
import ValidationGallery from "./ValidationGallery";
import ProcessingGallery from "./ProcessingGallery";
import { Helmet } from "react-helmet";

const PicturesLabelValidation = ({
    validationGallery,
    processingGallery,
    getGalleries,
    isLoading,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "pictures.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "pictures.label.validation.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="pictures.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="pictures.label.validation.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <ValidationGallery
                            gallery={validationGallery}
                            getGalleries={getGalleries}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ProcessingGallery
                            gallery={processingGallery}
                            getGalleries={getGalleries}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PicturesLabelValidation;
