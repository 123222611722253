import React from "react";
import PlanningsProduction from "pages/PlanningsProduction";
import I18n, { Navigate } from "shared/lib/I18n";
import useUser from "shared/hooks/useUser";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import LandingStatistics from "pages/LandingStatistics";

const Home = () => {
    const { user } = useUser();
    const location = useLocation();

    if (!user.hasRight("homepage.show")) {
        return <Navigate to="/search" replace />;
    }

    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(location, "dashboard")}`}</title>
            </Helmet>
            {user.hasRight("plannings.production") ? (
                <PlanningsProduction />
            ) : (
                <main
                    id="content"
                    className="content"
                    role="main"
                    style={{ height: "100% !important" }}
                >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <I18n t="dashboard" />
                        </li>
                    </ol>
                    <LandingStatistics />
                </main>
            )}
        </div>
    );
};

export default Home;
