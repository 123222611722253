import React from "react";
import RatingLine from "./RatingLine";
import { Card, ListGroup } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import "./RatingCard.css";

const RatingCard = ({ line_number, ratings, activeLines, ...props }) => {
    return (
        <Card
            className={clsx(
                "mb-3",
                activeLines.includes(line_number) &&
                    "apple__sample__assessment__ratings__active"
            )}
        >
            <Card.Header>
                <strong>
                    <I18n
                        t="apple.sample.assessment.rating.line"
                        args={line_number}
                    />
                </strong>
            </Card.Header>
            <ListGroup className="list-group-flush">
                {ratings.map((rating) => (
                    <RatingLine key={rating.id} {...rating} {...props} />
                ))}
            </ListGroup>
        </Card>
    );
};

export default RatingCard;
