import React from "react";
import I18n from "shared/lib/I18n";
import SearchForm from "./SearchForm";
import SearchResult from "./SearchResult";
import { Helmet } from "react-helmet";
import "./ArtistSearch.css";

const ArtistSearch = ({ artists, isLoading, setState, location }) => {
    return (
        <div className="content-wrap artist-search">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "artist.search.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="artist.search.breadcrumb" />
                    </li>
                </ol>
                <SearchForm setState={setState} />
                <SearchResult artists={artists} isLoading={isLoading} />
            </main>
        </div>
    );
};

export default ArtistSearch;
