import React, { useState, useCallback, useEffect, useMemo } from "react";
import PF from "./PF";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedPF = (props) => {
    const { api } = useAPI();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDisabled, setShowDisabled] = useState(false);
    const location = useLocation();
    const { type, id_pf_or_year, locale } = useParams();
    const navigate = useNavigate();

    const getData = useCallback(() => {
        let cancelled = false;
        setIsLoading(true);
        api.get(`cnm/platform`)
            .then((response) => {
                if (!cancelled) {
                    setData(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(getData, [getData]);

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.charts.deduplicated.pf.name"
            ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(`/${locale}/cnm/charts/${type}/${row.id}`);
            }
        },
        selected: [parseInt(id_pf_or_year, 10)],
    };

    const filteredData = useMemo(
        () =>
            showDisabled ? data : data.filter((platform) => platform.enabled),
        [data, showDisabled]
    );

    const rowClasses = (row) => (row.enabled ? undefined : "table-secondary");

    return (
        <PF
            data={filteredData}
            columns={columns}
            isLoading={isLoading}
            selectRow={selectRow}
            showDisabled={showDisabled}
            setShowDisabled={setShowDisabled}
            rowClasses={rowClasses}
            {...props}
        />
    );
};

export default EnhancedPF;
