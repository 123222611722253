import moment from "moment";

export const prefill = (name, charts) => {
    const match = name.match(/^(\d{4})(\d{2}) - (.*)\.xlsx?$/);
    if (match === null) {
        return {
            name: name,
            startDate: moment().subtract(2, "week").day(5).format("YYYY-MM-DD"),
            endDate: moment().subtract(1, "week").day(4).format("YYYY-MM-DD"),
            chart: null,
        };
    }

    const year = parseInt(match[1], 10);
    const week = parseInt(match[2], 10);
    const title = match[3];

    const endDate = moment().isoWeekYear(year).isoWeek(week).isoWeekday(4);
    const startDate = moment(endDate).subtract(6, "days");

    const chart = charts.find(({ name }) => name === title);

    return {
        name: name,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        chart: chart ? { value: chart.id, label: chart.name } : null,
    };
};
