import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import TransferForm from "./TransferForm";
import { Link } from "shared/lib/I18n";

const EnhancedTransferForm = ({
    artistId,
    albumId,
    releaseId,
    onSuccess,
    ...props
}) => {
    const { locale } = useParams();
    const location = useLocation();
    const { api } = useAPI();
    const [artistIsLoading, setArtistIsLoading] = useState(true);

    const schema = yup.object().shape({
        artist: yup.object().nullable().required("utils.form.required"),
        album: yup
            .object()
            .test(
                "is-not-same-album",
                "artist.management.disco.objects.release.basic.transfer.same_album_error",
                (album) => album?.id != parseInt(albumId, 10)
            )
            .nullable()
            .required("utils.form.required"),
    });

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            artist: null,
            album: null,
        },
    });
    const selectedArtist = watch("artist")?.id;

    const getArtist = useCallback(() => {
        setArtistIsLoading(true);
        api.get(`artist/${artistId}`, {})
            .then((response) => {
                reset({
                    artist: {
                        id: parseInt(artistId, 10),
                        name: response.nom,
                    },
                    album: null,
                });
                setArtistIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setArtistIsLoading(false);
            });
    }, [artistId]);

    useEffect(getArtist, [getArtist]);

    const onSubmit = (data) => {
        api.post(
            `artist/${artistId}/album/${albumId}/release/${releaseId}/transfer`,
            { locale },
            { artist: data.artist.id, album: data.album.id }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.release.basic.transfer.success`,
                        {
                            artist: data.artist.name,
                            album: data.album.title,
                            releaseId: releaseId,
                        }
                    )
                );
                onSuccess?.(data.artist.id, data.album.id);
            })
            .catch((error) => {
                console.error(error);

                const code = error?.body?.code;

                if (code === "duplicate_album_barcode") {
                    toast.error(
                        <div>
                            <span>{error.message}: </span>
                            <Link
                                to={`/artist/${error.body.id_artist}/edit/discography/album/${error.body.id_album}/release/${error.body.id_release}/`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${error.body.artist_name} - ${error.body.album_title}`}
                            </Link>
                        </div>
                    );
                } else {
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.release.basic.transfer.error`,
                            error.message
                        )
                    );
                }
            });
    };

    return (
        <TransferForm
            selectedArtist={selectedArtist}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            setValue={setValue}
            artistIsLoading={artistIsLoading}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedTransferForm;
