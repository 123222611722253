import React, { useReducer, useMemo } from "react";
import { AlbumViewContext } from "./AlbumView.context";
import { AlbumViewReducer } from "./AlbumView.reducer";

const initialState = {
    profileSelection: {
        releaseId: null,
        isrc: null,
    },
    profileHighlights: {
        releases: [],
        isrcs: [],
    },
};

export const AlbumViewProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AlbumViewReducer, initialState);
    const contextValue = useMemo(
        () => ({
            dispatchAlbum: dispatch,
            profileSelection: {
                ...state.profileSelection,
            },
            profileHighlights: {
                ...state.profileHighlights,
            },
        }),
        [state, dispatch]
    );

    return (
        <AlbumViewContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </AlbumViewContext.Provider>
    );
};
