import React, { useEffect, useCallback, useMemo } from "react";
import SearchForm from "./SearchForm";
import { toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router";
import useAPI from "shared/hooks/useApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useQuery from "shared/hooks/useQuery";

const defaultValues = {
    q: "",
};

const schema = yup.object().shape({
    q: yup.string().trim(),
});

const EnhancedSearchForm = ({ setState, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale, selectedTab } = useParams();
    const navigate = useNavigate();
    const query = useQuery();

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onReset = () => {
        reset(defaultValues);
    };

    const queryParams = useMemo(() => {
        let params = {};

        for (let [key, value] of query.entries()) {
            params[key] = value;
        }

        return params;
    }, [query]);

    const onSubmit = (data) => {
        const filteredData = Object.entries(data).reduce(
            (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
            {}
        );

        if (Object.entries(filteredData).length !== 0) {
            const params = new URLSearchParams(filteredData).toString();
            navigate(
                `/${locale}/search${
                    selectedTab ? `/${selectedTab}` : ""
                }?${params}`
            );
        }
    };

    const fetchData = useCallback(() => {
        const params = Object.entries(queryParams);
        if (params.length > 0) {
            params.map(([key, value]) => {
                setValue(key, value, {
                    shouldDirty: true,
                });
            });

            setState((previousState) => {
                return { ...previousState, isLoading: true };
            });

            return api
                .get("searchengine/multi-search", queryParams)
                .then(({ results }) => {
                    setState((prev) => ({
                        ...prev,
                        artists: results.find((r) => r.indexUid === "artist")
                            .hits,
                        albums: results.find((r) => r.indexUid === "album")
                            .hits,
                        recordings: results.find(
                            (r) => r.indexUid === "recording"
                        ).hits,
                        isLoading: false,
                    }));
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        } else {
            onReset();
            setState({
                artists: [],
                albums: [],
                recordings: [],
                isLoading: false,
                hasSearch: false,
            });
        }
    }, [queryParams, locale]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <SearchForm
            {...props}
            register={register}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            location={location}
        />
    );
};

export default EnhancedSearchForm;
