import React from "react";
import { VARIOUS_ARTIST_ID } from "shared/constants/cms/artist";
import clsx from "clsx";

const ArtistLink = ({
    artistId,
    content,
    part,
    locale,
    className,
    view,
    edit,
    blank,
}) => {
    if (!artistId || artistId === VARIOUS_ARTIST_ID) {
        return content;
    }

    if (view && edit) {
        view = edit = null;
    }

    if (part && !view && !edit) {
        part = null;
    }

    const href =
        "/" +
        [locale, "artist", artistId, view && "view", edit && "edit", part]
            .filter(Boolean)
            .join("/");

    if (blank) {
        return (
            <a
                href={href}
                className={clsx(className)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {content}
            </a>
        );
    } else {
        return (
            <a href={href} className={clsx(className)}>
                {content}
            </a>
        );
    }
};

export default ArtistLink;
