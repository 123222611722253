import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import {
    useCNMTitles,
    useSearch,
    usePlatformTitles,
} from "./CNMAggregation.hook";
import CNMAggregation from "./CNMAggregation";

const EnhancedCNMAggregation = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale, chartType } = useParams();
    const { year, cnmTitleId, similarCnmTitleId } = useIntParams();

    const cnmTitles = useCNMTitles(year, chartType, locale);
    const search = useSearch(year, chartType, cnmTitleId, locale);
    const platformTitles = usePlatformTitles(
        year,
        chartType,
        cnmTitleId,
        locale
    );
    const similarPlatformTitles = usePlatformTitles(
        year,
        chartType,
        similarCnmTitleId,
        locale
    );

    const selectedPlatformTitles = useMemo(
        () => [...platformTitles.selected, ...similarPlatformTitles.selected],
        [platformTitles.selected, similarPlatformTitles.selected]
    );

    const defaultSearch = useMemo(() => {
        const row = cnmTitles.rows.find(({ id }) => id === cnmTitleId);
        return row === undefined ? "" : row.title;
    }, [cnmTitles.rows, cnmTitleId]);

    const onMoveLeft = (row) => {
        api.post(
            `cnm/aggregation/move/${year}/${chartType}/${row.id}/${similarCnmTitleId}/${cnmTitleId}`,
            {
                locale,
            }
        )
            .then((modifiedTitles) => {
                cnmTitles.update(modifiedTitles);
                search.update(modifiedTitles);
                platformTitles.add(row);
                similarPlatformTitles.remove([row.id]);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onMoveRight = (row) => {
        api.post(
            `cnm/aggregation/move/${year}/${chartType}/${row.id}/${cnmTitleId}/${similarCnmTitleId}`,
            {
                locale,
            }
        )
            .then((modifiedTitles) => {
                cnmTitles.update(modifiedTitles);
                search.update(modifiedTitles);
                platformTitles.remove([row.id]);
                similarPlatformTitles.add(row);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onCreate = (modifiedCnmTitles, platformTitleIds) => {
        cnmTitles.update(modifiedCnmTitles);
        search.update(modifiedCnmTitles);
        platformTitles.remove(platformTitleIds);
        similarPlatformTitles.remove(platformTitleIds);
    };

    const onCheck = (row) => {
        const checked = !row.checked;
        api.post(
            `cnm/aggregation/check/${year}/${row.id}`,
            {
                locale,
            },
            {
                checked,
            }
        )
            .then(() => {
                cnmTitles.update([{ ...row, checked }]);
                search.update([{ ...row, checked }]);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onDiscard = (row) => {
        cnmTitles.update([row]);
        search.update([row]);
    };

    return (
        <CNMAggregation
            cnmTitles={cnmTitles}
            search={search}
            platformTitles={platformTitles}
            similarPlatformTitles={similarPlatformTitles}
            defaultSearch={defaultSearch}
            selectedPlatformTitles={selectedPlatformTitles}
            onMoveLeft={similarCnmTitleId ? onMoveLeft : undefined}
            onMoveRight={similarCnmTitleId ? onMoveRight : undefined}
            onCreate={onCreate}
            onCheck={onCheck}
            onDiscard={onDiscard}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCNMAggregation;
