import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import SwalButton from "shared/components/SwalButton";
import I18n from "shared/lib/I18n";

const RecoverCell = ({ onConfirm }) => {
    const location = useLocation();

    return (
        <div className="btn-group btn-group-sm">
            <SwalButton
                className="btn btn-primary"
                style={{ width: "30px" }}
                title={I18n.getTranslation(
                    location,
                    "partner_tops.artists.recover.button.title"
                )}
                swalOptions={{
                    title: I18n.getTranslation(
                        location,
                        "partner_tops.artists.recover.modal.title"
                    ),
                    text: I18n.getTranslation(
                        location,
                        "partner_tops.artists.recover.modal.text"
                    ),
                }}
                onConfirm={onConfirm}
            >
                <FontAwesomeIcon icon={faThumbsUp} size="lg" />
            </SwalButton>
        </div>
    );
};

export default RecoverCell;
