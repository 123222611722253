import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import {
    SUBTASK_STATUS_ICON,
    SUBTASK_STATUS_COLOR,
    SUBTASK_STATUSES,
} from "shared/constants/Planning/SubTask";
import I18n from "shared/lib/I18n";

const SubTask = ({
    subtask,
    handleStatusChanged,
    showComment,
    isPlanningLock,
}) => {
    const location = useLocation();

    if (subtask.assignement === null) {
        return (
            <button
                className={`btn btn-${SUBTASK_STATUS_COLOR.UNASSIGNED} btn-sm mr-1`}
                data-toggle="tooltip"
                title={I18n.getTranslation(
                    location,
                    "plannings.view.tasks.subtasks.status.UNASSIGNED"
                )}
                disabled
            >
                <FontAwesomeIcon
                    icon={SUBTASK_STATUS_ICON.UNASSIGNED}
                    className="mr-1"
                    fixedWidth
                />
                {subtask.type}
            </button>
        );
    }

    return (
        <span className="dropdown mr-1 mb-1">
            <div className="btn-group">
                <div className="btn-group">
                    <button
                        className={`btn btn-${
                            SUBTASK_STATUS_COLOR[subtask.status]
                        } btn-sm dropdown-toggle`}
                        data-toggle="dropdown"
                    >
                        <FontAwesomeIcon
                            icon={SUBTASK_STATUS_ICON[subtask.status]}
                            className="mr-2"
                            fixedWidth
                        />
                        {subtask.type} - {subtask.assignement.name ?? "-"}
                    </button>

                    <div className="dropdown-menu">
                        <h6 className="dropdown-header">
                            <I18n t="plannings.view.tasks.subtasks.change_status" />
                        </h6>
                        {SUBTASK_STATUSES.map((status) => (
                            <button
                                key={status}
                                className={`dropdown-item active-${
                                    SUBTASK_STATUS_COLOR[status]
                                } ${
                                    subtask.status === status &&
                                    `active disabled`
                                } ${isPlanningLock && "disabled"}`}
                                onClick={() =>
                                    handleStatusChanged(subtask, status)
                                }
                            >
                                <FontAwesomeIcon
                                    icon={SUBTASK_STATUS_ICON[status]}
                                    className="mr-2"
                                    fixedWidth
                                />
                                <I18n
                                    t={`plannings.view.tasks.subtasks.status.${status}`}
                                />
                            </button>
                        ))}
                    </div>
                </div>
                {subtask.comment && (
                    <button
                        type="button"
                        className={`btn btn-${
                            SUBTASK_STATUS_COLOR[subtask.status]
                        } btn-sm border-left`}
                        onClick={() => showComment(subtask.comment)}
                    >
                        <FontAwesomeIcon icon={faComment} fixedWidth />
                    </button>
                )}
            </div>
        </span>
    );
};

export default SubTask;
