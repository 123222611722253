import React from "react";
import EditorialInformation from "./EditorialInformation";
import Collaboration from "./Collaboration";
import Contribution from "./Contribution";
import Related from "./Related";
import Highlights from "./Highlights";

const Editorial = () => {
    return (
        <div className="row">
            <div className="col">
                <EditorialInformation />
            </div>
            <div className="col-9">
                <Highlights />
                <Related />
                <Collaboration />
                <Contribution />
            </div>
        </div>
    );
};

export default Editorial;
