import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import SnepTrack from "./SnepTrack";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistMatcher from "./ArtistMatcher";
import RecordingMatcher from "./RecordingMatcher";
import SpotifyTrackMatcher from "./SpotifyTrackMatcher";
import FnacAlbumMatcher from "./FnacAlbumMatcher";
import ImageLoader from "shared/components/ImageLoader";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getFilteredData } from "./SnepTrack.selector";
import styles from "./SnepTrack.module.css";
import clsx from "clsx";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedSnepTrack = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
    });

    const fetchSnepTrack = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/track/${partnerId}/list/${listId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchSnepTrack();
    }, [fetchSnepTrack]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(location, "matching.track.track.rank"),
            sort: true,
            searchable: false,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "cover",
            text: I18n.getTranslation(location, "matching.track.track.cover"),
            headerAlign: "center",
            align: "center",
            formatter: (cell) =>
                cell ? (
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Popover id={`popover-positioned-top`}>
                                <Popover.Content className="bg-black rounded-sm">
                                    <ImageLoader
                                        src={cell}
                                        style={{
                                            width: "400px",
                                            height: "400px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span>
                            <ImageLoader
                                src={cell}
                                className={clsx(styles["img-40"])}
                            />
                        </span>
                    </OverlayTrigger>
                ) : (
                    "-"
                ),
            searchable: false,
            headerStyle: {
                width: "6rem",
            },
        },
        {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.track.track.id"),
            sort: true,
            searchable: false,
            headerStyle: {
                width: "6rem",
            },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "matching.track.track.title"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "snep_artist",
            text: I18n.getTranslation(
                location,
                "matching.track.track.snep_artist"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(location, "matching.track.track.artist"),
            formatter: (cell, { id, snep_artist }, rowIndex, { locale }) => {
                return (
                    <ArtistMatcher
                        listId={listId}
                        rowId={id}
                        locale={locale}
                        artist={cell}
                        onChange={(data) => updateRow(id, data)}
                        defaultSearch={snep_artist}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
            headerStyle: {
                width: "20rem",
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "recording",
            text: I18n.getTranslation(
                location,
                "matching.track.track.recording"
            ),
            formatter: (cell, { id, title, artist }, rowIndex, { locale }) => {
                return (
                    <RecordingMatcher
                        listId={listId}
                        rowId={id}
                        locale={locale}
                        artist={artist}
                        recording={cell}
                        onChange={(data) => updateRow(id, data)}
                        defaultSearch={title}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
            headerStyle: {
                width: "20rem",
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "id_spotify",
            text: I18n.getTranslation(
                location,
                "matching.track.track.spotify_track"
            ),
            formatter: (cell, { id, title, snep_artist, recording }) => {
                return (
                    <SpotifyTrackMatcher
                        listId={listId}
                        rowId={id}
                        spotifyId={cell}
                        defaultSearch={`${title} artist:${snep_artist}`}
                        disabled={recording === null}
                        onChange={(data) => updateRow(id, data)}
                    />
                );
            },
            headerStyle: {
                width: "20rem",
            },
        },
        {
            dataField: "id_fnac",
            text: I18n.getTranslation(
                location,
                "matching.track.track.fnac_album"
            ),
            formatter: (cell, { id, title, snep_artist, recording }) => {
                return (
                    <FnacAlbumMatcher
                        listId={listId}
                        rowId={id}
                        fnacId={cell}
                        search={`${title} ${snep_artist}`}
                        disabled={recording === null}
                        onChange={(data) => updateRow(id, data)}
                    />
                );
            },
            headerStyle: {
                width: "15rem",
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "rank",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ artist, recording }) => {
        let classes = null;

        if (artist && recording) {
            return "table-success";
        } else if (artist || recording) {
            return "table-warning";
        }

        return classes;
    };

    return (
        <SnepTrack
            {...props}
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            location={location}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedSnepTrack;
