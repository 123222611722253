import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import TextareaSwalButton from "shared/components/TextareaSwalButton";
import I18n from "shared/lib/I18n";

const DiscardButton = ({
    location,
    discarded,
    discardedComment,
    disabled,
    onRecover,
    onDiscard,
}) => {
    if (discarded) {
        return (
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                    <Tooltip>
                        <span className="pre-wrap">{discardedComment}</span>
                    </Tooltip>
                }
            >
                <button
                    className="btn btn-primary"
                    onClick={onRecover}
                    title={I18n.getTranslation(
                        location,
                        "common.charts.charts.discardButton.recoverTooltip"
                    )}
                >
                    <FontAwesomeIcon icon={faThumbsUp} />
                </button>
            </OverlayTrigger>
        );
    }

    return (
        <TextareaSwalButton
            className={clsx("btn btn-primary", disabled && "disabled")}
            title={I18n.getTranslation(
                location,
                "common.charts.charts.discardButton.discardTooltip"
            )}
            swalOptions={{
                title: I18n.getTranslation(
                    location,
                    "common.charts.charts.discardButton.modalTitle"
                ),
            }}
            onConfirm={onDiscard}
            required
        >
            <FontAwesomeIcon icon={faThumbsDown} />
        </TextareaSwalButton>
    );
};

export default DiscardButton;
