import React, { useState } from "react";
import Trend from "./Trend";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { getLabels, getDatasets } from "./Trend.pure";

const EnhancedTrend = ({ selectedCharts, entries, ...props }) => {
    const location = useLocation();
    const [{ rank: dynamicRank, date: dynamicDate }, setDynamic] = useState({
        rank: false,
        date: true,
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                labels: {
                    usePointStyle: true,
                },
            },
            colors: {
                forceOverride: true,
            },
            tooltip: {
                usePointStyle: true,
            },
            zoom: {
                pan: { enabled: true, mode: "x" },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    mode: "x",
                },
            },
        },
        scales: {
            y: {
                reverse: true,
                title: {
                    display: true,
                    text: I18n.getTranslation(
                        location,
                        "recording.view.chartsRecordingCharts.trend.y"
                    ),
                },
                min: 1,
                ...(!dynamicRank && { max: 200 }),
            },
            x: {
                title: {
                    display: true,
                    text: I18n.getTranslation(
                        location,
                        "recording.view.chartsRecordingCharts.trend.x"
                    ),
                },
            },
        },
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
    };

    return (
        <Trend
            {...props}
            data={{
                labels: getLabels({
                    selectedCharts,
                    entries,
                    dynamicDate,
                }),
                datasets: getDatasets({
                    selectedCharts,
                    entries,
                    dynamicDate,
                }),
            }}
            options={options}
            dynamicRank={dynamicRank}
            dynamicDate={dynamicDate}
            setDynamic={setDynamic}
            location={location}
        />
    );
};

export default EnhancedTrend;
