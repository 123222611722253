import React from "react";
import I18n from "shared/lib/I18n";
import MetabaseView from "shared/components/MetabaseView";
import InputSelect from "shared/components/Form/InputSelect";
import "./Budget.css";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const Budget = ({
    planning,
    users,
    setUsers,
    taskType,
    setTaskType,
    usersOptions,
    taskTypeOptions,
    partnerId,
}) => {
    return (
        <>
            <div className="planning-stats-filter">
                <div className="row">
                    <div className="col-3">
                        <InputSelect
                            placeholder={
                                <I18n t="plannings.view.budget.username" />
                            }
                            options={usersOptions}
                            onChange={(opt, action) => {
                                if (action.action === "select-option") {
                                    setUsers([...users, action.option.label]);
                                } else if (action.action === "remove-value") {
                                    setUsers(
                                        users.filter(
                                            (label) =>
                                                label !=
                                                action.removedValue.label
                                        )
                                    );
                                } else if (action.action === "clear") {
                                    setUsers([]);
                                }
                            }}
                            isMulti
                        />
                    </div>
                    <div className="col-3">
                        <InputSelect
                            placeholder={
                                <I18n t="plannings.view.budget.task_type" />
                            }
                            options={taskTypeOptions}
                            onChange={(opt, action) => {
                                if (action.action === "select-option") {
                                    setTaskType([
                                        ...taskType,
                                        action.option.label,
                                    ]);
                                } else if (action.action === "remove-value") {
                                    setTaskType(
                                        taskType.filter(
                                            (label) =>
                                                label !=
                                                action.removedValue.label
                                        )
                                    );
                                } else if (action.action === "clear") {
                                    setTaskType([]);
                                }
                            }}
                            isMulti
                        />
                    </div>
                </div>
            </div>
            <MetabaseView
                id="52"
                frameBorder="0"
                width="100%"
                height="710"
                params={{
                    date: `${planning.start_date}~${planning.end_date}`,
                    username: users,
                    task_type: taskType,
                    planning_partner:
                        partnerId === PLANNING_PARTNER.ALL ? [] : [partnerId],
                }}
            />
        </>
    );
};

export default Budget;
