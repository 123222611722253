import { createSelector } from "reselect";
import I18n from "shared/lib/I18n";

export const getFilterOptions = createSelector(
    [(args) => args.tasks, (args) => args.location],
    (tasks, location) =>
        tasks.reduce(
            (acc, curr) => {
                if (curr.information) {
                    acc["status"] = [
                        ...acc.status,
                        {
                            label:
                                I18n.getTranslation(
                                    location,
                                    `plannings.picture.tasks.${Object.keys(
                                        curr.information
                                    ).pop()}`
                                ) || Object.keys(curr.information).pop(),
                            value: Object.keys(curr.information).pop(),
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                return acc;
            },
            {
                status: [],
            }
        )
);

export const getFilteredTasks = createSelector(
    [(args) => args.tasks, (args) => args.selectedFilterOptions],
    (tasks, selectedFilterOptions) => {
        let filteredTasks = tasks;

        if (selectedFilterOptions.status) {
            filteredTasks = filteredTasks.filter(
                (p) =>
                    Object.keys(p.information).pop() ===
                    selectedFilterOptions.status
            );
        }

        return filteredTasks;
    }
);
