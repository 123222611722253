import React, { useState, useEffect, useCallback } from "react";
import Collaboration from "./Collaboration";
import { useLocation, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { orderByStartDate, groupByRole } from "./Collaboration.pure";

const EnhancedCollaboration = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const location = useLocation();
    const [state, setState] = useState({
        collaboration: [],
        roleOptions: [],
        isLoading: true,
    });

    const getCollaboration = useCallback(() => {
        return api
            .get(`artist/${artistId}/collaboration`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    collaboration: orderByStartDate(groupByRole(response)),
                }));
                if (response.length === 0) {
                    setNoData("collaboration");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getRole = useCallback(() => {
        return api
            .get(`role/${locale}`)
            .then((response) => {
                setState((prev) => ({ ...prev, roleOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getCollaboration(), getRole()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getCollaboration, getRole]);

    return (
        <Collaboration
            {...props}
            {...state}
            locale={locale}
            location={location}
        />
    );
};

export default EnhancedCollaboration;
