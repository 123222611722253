import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import DiscardButton from "./DiscardButton";

const EnhancedDiscardButton = ({
    year,
    titleId,
    discarded,
    comment,
    onDiscard,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            comment: "",
        },
    });

    const onShow = () => {
        reset({ comment });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = ({ comment }) => {
        api.post(
            `cnm/aggregation/discard/${year}/${titleId}`,
            {
                locale,
            },
            {
                discarded: true,
                comment,
            }
        )
            .then(() => {
                onHide();
                onDiscard(true, comment);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onRecover = () => {
        api.post(
            `cnm/aggregation/discard/${year}/${titleId}`,
            {
                locale,
            },
            {
                discarded: false,
            }
        )
            .then(() => {
                onHide();
                onDiscard(false, null);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <DiscardButton
            discarded={discarded}
            comment={comment}
            register={register}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onClick={discarded ? onRecover : onShow}
            onHide={onHide}
            location={location}
            {...props}
        />
    );
};

export default EnhancedDiscardButton;
