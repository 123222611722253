import React from "react";
import Basic from "./Basic";
import { useParams, useNavigate } from "react-router";

const EnhancedBasic = (props) => {
    const {
        selectedTab,
        artistId,
        albumId,
        releaseId,
        trackId,
        recordingId,
        locale,
    } = useParams();
    const navigate = useNavigate();

    const onSuccess = (trackId, recordingId) => {
        navigate(
            `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}`
        );
    };

    const redirectToView = () => {
        navigate(`/${locale}/recording/${recordingId}/view`);
    };

    return (
        <Basic
            artistId={artistId}
            albumId={albumId}
            releaseId={releaseId}
            trackId={trackId}
            recordingId={recordingId}
            onSuccess={onSuccess}
            redirectToView={redirectToView}
            {...props}
        />
    );
};

export default EnhancedBasic;
