import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";

const TitleList = ({
    isLoading,
    titles,
    selectedArtist,
    columns,
    pagination,
    onColumnMatch,
}) => {
    return (
        <ToolkitProvider
            keyField="id"
            data={titles}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title="cnm.repositories.cnm_artists_titles.titles.head"
                            titleArgs={{
                                noArtist: selectedArtist?.id === 0,
                                artistName: selectedArtist?.name,
                            }}
                            className="grid-bottom-right vertical-shrink mb-0"
                            actions={
                                !isLoading && (
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                )
                            }
                        >
                            <Widget.Body className="mb-3">
                                {paginationProps.page !== 1 ? (
                                    <InputText
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "cnm.repositories.cnm_artists_titles.titles.search"
                                        )}
                                        disabled={true}
                                    />
                                ) : (
                                    <Search.SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "cnm.repositories.cnm_artists_titles.titles.search"
                                        )}
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body className="widget-table-overflow overflow-auto flex-grow-1 mb-3">
                                {isLoading ? (
                                    <Loader
                                        className="h-100 align-items-center"
                                        size="3x"
                                    />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        noDataIndication={
                                            <I18n t="cnm.repositories.cnm_distributors.empty" />
                                        }
                                        classes="table-bordered-header-fixed"
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={titles.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default TitleList;
