import React from "react";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";
import styles from "./Status.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Status = ({ status, location, getRetryDate }) => {
    return (
        <div className="d-flex">
            <div className={clsx("card", styles.card)}>
                <div className="card-header">
                    <h5 className="d-inline">
                        <I18n t="api.monitoring.status.remaining" />
                    </h5>
                </div>
                <div className="card-body text-center">
                    <h2>
                        {status.remaining !== undefined
                            ? status.remaining.toLocaleString()
                            : "-"}
                    </h2>
                </div>
            </div>
            <div className={clsx("card ml-4", styles.card)}>
                <div className="card-header">
                    <h5 className="d-inline">
                        <I18n t="api.monitoring.status.limit" />
                    </h5>
                </div>
                <div className="card-body text-center">
                    <h2>
                        {status.limit !== undefined
                            ? status.limit.toLocaleString()
                            : "-"}
                    </h2>
                </div>
            </div>
            <div className={clsx("card ml-4", styles.card)}>
                <div className="card-header">
                    <h5 className="d-inline">
                        <I18n t="api.monitoring.status.retry" />
                    </h5>
                    <Tippy
                        content={I18n.getTranslation(
                            location,
                            "api.monitoring.status.retry_description"
                        )}
                        trigger="mouseenter"
                    >
                        <span className="ml-1">
                            <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                        </span>
                    </Tippy>
                </div>
                <div className="card-body text-center">
                    <h2>{getRetryDate(status.retry)}</h2>
                </div>
            </div>
            <div className={clsx("card ml-4", styles.card)}>
                <div className="card-header">
                    <h5 className="d-inline">
                        <I18n t="api.monitoring.status.status" />
                    </h5>
                </div>
                <div className="card-body">
                    <iframe
                        src="https://status.music-story.com/badge?theme=light"
                        width="250"
                        height="30"
                        frameBorder="0"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Status;
