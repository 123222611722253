import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { RELEASE_SUPPORTS } from "shared/constants/cms/release";
import I18n, { Link } from "shared/lib/I18n";
import { isValidDate } from "shared/functions/Date";
import useAPI from "shared/hooks/useApi";
import swal from "@sweetalert/with-react";
import {
    DiscographyContext,
    RELEASE_ADD,
    RELEASE_UPD,
} from "pages/ArtistManagement/Discography";
import CreateOrUpdate from "./CreateOrUpdate";

const schema = yup.object().shape({
    barcode: yup
        .string()
        .trim()
        .nullable()
        .matches(/^\d{12,13}$/, "utils.form.barcode_format")
        .transform((value) => (value !== "" ? value : null)),
    release_date: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable()
        .test("is-a-valid-date", "utils.form.date_format", (date) =>
            date ? isValidDate(date) : true
        ),
    editor: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    support: yup
        .string()
        .ensure()
        .required("utils.form.required")
        .oneOf(RELEASE_SUPPORTS, "utils.form.required"),
    commentary: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    reference: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
});

const EnhancedCreateOrUpdate = ({
    artistId,
    albumId,
    releaseId,
    onSuccess,
    ...props
}) => {
    const { dispatchDiscography } = useContext(DiscographyContext);
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            barcode: null,
            release_date: null,
            editor: null,
            support: null,
            commentary: null,
            reference: null,
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const initForm = useCallback(() => {
        if (releaseId) {
            setIsLoading(true);
            api.get(`release/${releaseId}`)
                .then((response) => {
                    reset(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else {
            reset();
        }
    }, [albumId, releaseId, reset]);

    useEffect(initForm, [initForm]);

    const supportOptions = RELEASE_SUPPORTS.map((support) => ({
        value: support,
        label: support,
    }));

    const onSubmit = (data) => {
        let withOptionalControl = 1;

        const sendRequest = () => {
            const apiRequest = releaseId
                ? api.put(
                      `artist/${artistId}/album/${albumId}/release/${releaseId}`,
                      { locale, withOptionalControl },
                      data
                  )
                : api.post(
                      `artist/${artistId}/album/${albumId}/release`,
                      { locale, withOptionalControl },
                      data
                  );

            apiRequest.then(onRequestSuccess).catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            toast.success(
                I18n.getTranslation(
                    location,
                    `artist.management.disco.objects.release.basic.${
                        releaseId ? "update" : "create"
                    }.success`
                )
            );
            reset(data);
            dispatchDiscography({
                type: releaseId ? RELEASE_UPD : RELEASE_ADD,
                data: {
                    id: releaseId
                        ? parseInt(releaseId, 10)
                        : response.id_release,
                    barcode: data.barcode,
                    release_date: data.release_date,
                    support: data.support,
                    commentary: data.commentary,
                    reference: data.reference,
                },
            });
            onSuccess?.(
                releaseId ? parseInt(releaseId, 10) : response.id_release
            );
        };

        const onDuplicateAlbumBarcode = (error) => {
            toast.error(
                <div>
                    <span>{error.message}: </span>
                    <Link
                        to={`/artist/${error.body.id_artist}/edit/discography/album/${error.body.id_album}/release/${error.body.id_release}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {`${error.body.artist_name} - ${error.body.album_title}`}
                    </Link>
                </div>
            );
        };

        const onDuplicateBarcode = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.release.basic.create.warning.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.basic.create.warning.text",
                            error.message
                        )}
                        <br />
                        <a
                            href={`/${locale}/artist/${error.body.id_artist}/edit/discography/album/${error.body.id_album}/release/${error.body.id_release}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {error.body.artist_name} - {error.body.album_title}
                        </a>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    return;
                }

                withOptionalControl = 0;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_album_barcode") {
                onDuplicateAlbumBarcode(error);
            } else if (code === "duplicate_barcode") {
                onDuplicateBarcode(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.release.basic.${
                            releaseId ? "update" : "create"
                        }.error`,
                        error.message
                    )
                );
            }
        };

        sendRequest();
    };

    return (
        <CreateOrUpdate
            releaseId={releaseId}
            initForm={initForm}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            supportOptions={supportOptions}
            {...props}
        />
    );
};

export default EnhancedCreateOrUpdate;
