import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import { ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import CreateOrUpdateButton from "./CreateOrUpdateButton";
import DeleteButton from "./DeleteButton";
import CNMLabels from "./CNMLabels";

const EnhancedCNMLabels = ({ year, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const { user } = useUser();

    const [state, setState] = useState({
        isLoading: true,
        labels: [],
        yearStatus: null,
    });

    const editable =
        user.hasRight("cnm.repository.cnm_labels.manage") &&
        state.yearStatus === "IN_PROGRESS";

    const fetchLabels = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            labels: [],
            yearStatus: null,
        });
        api.get(`cnm/repository/cnm_labels/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        ...response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        labels: [],
                        yearStatus: null,
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchLabels, [fetchLabels]);

    const onCreate = (label) => {
        setState((state) => ({
            ...state,
            labels: [...state.labels, label],
        }));
    };

    const onUpdate = ({ id, cnmName, name, type }) => {
        setState((state) => ({
            ...state,
            labels: state.labels.map((label) =>
                label.id === id ? { id, cnmName, name, type } : label
            ),
        }));
    };

    const onDelete = ({ id }) => {
        setState((state) => ({
            ...state,
            labels: state.labels.filter((label) => label.id !== id),
        }));
    };

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_labels.name"
            ),
            filterValue: normalizeTitle,
            sort: true,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_labels.type"
            ),
            formatter: (cell, row, rowIndex, data) =>
                editable ? (
                    <div className="d-flex align-items-center">
                        <span className="flex-grow-1">{cell}</span>
                        <ButtonGroup>
                            <CreateOrUpdateButton
                                variant="primary"
                                title={data.editTooltip}
                                year={year}
                                label={row}
                                onSuccess={onUpdate}
                            >
                                <FontAwesomeIcon icon={faPen} fixedWidth />
                            </CreateOrUpdateButton>
                            <DeleteButton
                                year={year}
                                id={row.id}
                                onDelete={onDelete}
                            />
                        </ButtonGroup>
                    </div>
                ) : (
                    cell
                ),
            formatExtraData: {
                editTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_labels.edit.tooltip"
                ),
                deleteTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_labels.delete.tooltip"
                ),
            },
            filterValue: normalizeTitle,
            sort: true,
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    return (
        <CNMLabels
            year={year}
            columns={columns}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
            editable={editable}
            onCreate={onCreate}
            location={location}
            {...state}
            {...props}
        />
    );
};

export default EnhancedCNMLabels;
