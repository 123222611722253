import React, { useRef } from "react";
import Country from "./Country";

const EnhancedCountry = (props) => {
    const markerRef = useRef(null);

    return <Country markerRef={markerRef} {...props} />;
};

export default EnhancedCountry;
