import React, { useState, useEffect, useCallback } from "react";
import ExternalLink from "./ExternalLink";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedExternalLink = (props) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        social: [],
        site: [],
        isLoading: true,
    });

    const getSocial = useCallback(() => {
        return api
            .get(`artist/${artistId}/social`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    social: response.filter(({ url }) => url !== null),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getSite = useCallback(() => {
        return api
            .get(`artist/${artistId}/site`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    site: response.map((site) => ({
                        id_partner: "site",
                        id_social: site.id,
                        url: site.url,
                        duplicates: [],
                    })),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getSocial(), getSite()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getSocial, getSite]);

    return <ExternalLink {...props} {...state} />;
};

export default EnhancedExternalLink;
