import { createSelector } from "reselect";

export const getMainCharts = createSelector(
    [(args) => args.recordings],
    (recordings) => {
        return recordings
            .filter((curr) => curr.main_charts.length !== 0)
            .map((curr, index) => ({ rn: index + 1, ...curr }));
    }
);

export const getAllCharts = createSelector(
    [(args) => args.recordings],
    (recordings) => {
        return recordings
            .filter((curr) => curr.all_charts.length !== 0)
            .map((curr, index) => ({ rn: index + 1, ...curr }));
    }
);

export const getHit = createSelector(
    [(args) => args.recordings],
    (recordings) => {
        return recordings
            .filter((curr) => curr.hit !== null && curr.hit !== "0")
            .map((curr, index) => ({ rn: index + 1, ...curr }));
    }
);

export const getEditorsPick = createSelector(
    [(args) => args.recordings],
    (recordings) => {
        return recordings
            .filter(
                (curr) =>
                    curr.editorial_pick !== null && curr.editorial_pick !== "0"
            )
            .map((curr, index) => ({ rn: index + 1, ...curr }));
    }
);
