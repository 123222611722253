import React, { useState, useEffect, useCallback } from "react";
import Gallery from "./Gallery";
import { toast } from "react-toastify";
import ArtistLink from "shared/components/ArtistLink";
import Pictures from "./Pictures";
import Action from "./Action";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import useAPI from "shared/hooks/useApi";

const EnhancedGallery = (props) => {
    const [label, setLabel] = useState("-");
    const [gallery, setGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { api } = useAPI();

    const getGallery = useCallback(() => {
        api.get("picture/label/gallery")
            .then((response) => {
                setLabel(response.label);
                setGallery(response.gallery);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getGallery, [getGallery]);

    const columns = [
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "pictures.label.gallery.artist"
            ),
            sort: true,
            formatter: (cell) => {
                return (
                    <ArtistLink
                        artistId={cell.id}
                        content={cell.name}
                        className="h4"
                        blank
                    />
                );
            },
            filterValue: (cell) => cell.name,
            sortValue: (cell) => cell.name,
        },
        {
            dataField: "action",
            text: I18n.getTranslation(
                location,
                "pictures.label.gallery.action"
            ),
            searchable: false,
            headerAlign: () => "right",
            align: "right",
            formatter: (cell, row) => {
                return (
                    <Action artistId={row.artist.id} onSuccess={getGallery} />
                );
            },
        },
    ];

    const expandRow = {
        renderer: (row) => (
            <Pictures pictures={row.pictures} getGallery={getGallery} />
        ),
        expandByColumnOnly: true,
        expanded: gallery.map(({ id }) => id),
    };

    const defaultSorted = [
        {
            dataField: "artist",
            order: "asc",
        },
    ];

    const paginationOptions = {
        sizePerPage: 15,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Gallery
            {...props}
            label={label}
            gallery={gallery}
            columns={columns}
            defaultSorted={defaultSorted}
            paginationOptions={paginationOptions}
            expandRow={expandRow}
            isLoading={isLoading}
        />
    );
};

export default EnhancedGallery;
