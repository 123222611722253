import React from "react";
import I18n from "shared/lib/I18n";
import { Button } from "react-bootstrap";

const Action = ({ handleValidate, handleDelete, isPlanningLock }) => {
    return (
        <>
            <Button
                variant="success"
                onClick={handleValidate}
                disabled={isPlanningLock}
            >
                <I18n t="plannings.view.action.validate.title" />
            </Button>
            <Button
                variant="danger"
                onClick={handleDelete}
                disabled={isPlanningLock}
            >
                <I18n t="plannings.view.action.deleted.title" />
            </Button>
        </>
    );
};

export default Action;
