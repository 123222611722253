import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";

const Artists = ({ artists, columns }) => {
    return (
        <Widget title="moodmedia.search.results">
            <Widget.Body className="widget-table-overflow">
                <BootstrapTable
                    keyField="id"
                    data={artists}
                    columns={columns}
                    bootstrap4
                    hover
                />
            </Widget.Body>
        </Widget>
    );
};

export default Artists;
