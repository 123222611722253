import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Loader from "shared/components/Loader/Loader";
import { NavLink } from "shared/lib/I18n";
import { Helmet } from "react-helmet";

const Role = ({ role, columns, isLoading, user, defaultSorted, location }) => {
    const { SearchBar } = Search;
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "role.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="role.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <section className="widget pb-0">
                            <ToolkitProvider
                                keyField="id"
                                data={role}
                                columns={columns}
                                search
                            >
                                {(toolkitprops) => (
                                    <>
                                        <header className="d-flex justify-content-between">
                                            <h4>
                                                <I18n t="role.head" />
                                            </h4>
                                        </header>
                                        <div className="widget-body">
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <SearchBar
                                                        {...toolkitprops.searchProps}
                                                        placeholder={I18n.getTranslation(
                                                            location,
                                                            "role.search"
                                                        )}
                                                    />
                                                </div>
                                                {user.hasRight(
                                                    "role.manage"
                                                ) && (
                                                    <div className="col-2 align-self-center">
                                                        <NavLink
                                                            to={`/role/manage`}
                                                            className={`btn btn-primary btn-block`}
                                                        >
                                                            <I18n t="role.button" />
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="widget-body widget-table-overflow">
                                            {isLoading ? (
                                                <Loader />
                                            ) : (
                                                <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    bootstrap4
                                                    striped
                                                    hover
                                                    noDataIndication={
                                                        <I18n t="role.empty" />
                                                    }
                                                    defaultSorted={
                                                        defaultSorted
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        </section>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Role;
