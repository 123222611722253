import React, { useState, useEffect, useCallback } from "react";
import AudioDescription from "./AudioDescription";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const EnhancedAudioDescription = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { recordingId } = useIntParams();
    const [state, setState] = useState({
        data: {},
        isLoading: true,
    });
    const [more, setMore] = useState(false);
    const location = useLocation();

    const getData = useCallback(() => {
        return api
            .get(`recording/${recordingId}/audio-description`)
            .then((response) => {
                setState((prev) => ({ ...prev, data: response }));
                if (Object.values(response).length === 0) {
                    setNoData("audioDescription");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [recordingId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getData()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getData]);

    return (
        <AudioDescription
            {...props}
            {...state}
            more={more}
            setMore={setMore}
            location={location}
        />
    );
};

export default EnhancedAudioDescription;
