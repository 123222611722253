import React, { useState, useCallback, useEffect, useContext } from "react";
import MatchV1 from "./MatchV1";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faUser,
    faRobot,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {
    ArtistManagementContext,
    BAS_UP_TO_DATE,
} from "pages/ArtistManagement";

const EnhancedMatchV1 = ({ partner, ...props }) => {
    const { dispatchArtist } = useContext(ArtistManagementContext);
    const { api } = useAPI();
    const location = useLocation();
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        isLoading: true,
        matchings: [],
    });

    const getMatchings = useCallback(() => {
        let cancelled = false;

        api.get(`matching/ms/${partner}/${artistId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        matchings: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        matchings: [],
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, partner]);

    useEffect(getMatchings, [getMatchings]);

    const filterOption = useCallback(
        (option) =>
            !state.matchings
                .reduce((acc, { id }) => [...acc, id], [])
                .map(String)
                .includes(String(option.value)),
        [state]
    );

    const onMatch = useCallback(
        (partnerID) =>
            toast.promise(
                api.post(
                    `matching/ms/${partner}/${artistId}`,
                    {},
                    {
                        partnerID,
                    }
                ),
                {
                    pending: {
                        render() {
                            return (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        pulse={true}
                                    />
                                    <span className="ml-2">
                                        <I18n t="artist.management.match.pending" />
                                    </span>
                                </>
                            );
                        },
                        delay: undefined,
                    },
                    success: {
                        render(response) {
                            getMatchings();
                            dispatchArtist({
                                type: BAS_UP_TO_DATE,
                                data: false,
                            });
                            return I18n.getTranslation(
                                location,
                                "artist.management.match.success"
                            );
                        },
                    },
                    error: {
                        render(error) {
                            console.error(error);
                            return error.message;
                        },
                    },
                },
                {
                    delay: 500,
                }
            ),
        [artistId, partner]
    );

    const onDeleteAll = useCallback(() => {
        onDelete(state.matchings.reduce((acc, { id }) => [...acc, id], []));
    }, [state]);

    const onDelete = useCallback(
        (partnerIDs) =>
            toast.promise(
                api.delete(`matching/ms/${partner}/${artistId}`, {
                    partnerIDs: partnerIDs.join(","),
                }),
                {
                    pending: {
                        render() {
                            return (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        pulse={true}
                                    />
                                    <span className="ml-2">
                                        <I18n t="artist.management.match.pending" />
                                    </span>
                                </>
                            );
                        },
                        delay: undefined,
                    },
                    success: {
                        render(response) {
                            getMatchings();
                            dispatchArtist({
                                type: BAS_UP_TO_DATE,
                                data: false,
                            });
                            return I18n.getTranslation(
                                location,
                                "artist.management.match.success"
                            );
                        },
                    },
                    error: {
                        render(error) {
                            console.error(error);
                            return error.message;
                        },
                    },
                },
                {
                    delay: 500,
                }
            ),
        [artistId, partner]
    );

    const columns = [
        {
            dataField: "id",
            headerStyle: {
                width: "6rem",
            },
            title: true,
            classes: "text-truncate",
            text: I18n.getTranslation(location, "artist.management.match.id"),
            formatter: (cell, row) => (
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "artist.management.match.name"),
            formatter: (cell, row) => (
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: "type_match",
            text: I18n.getTranslation(
                location,
                "artist.management.match.type_match"
            ),
            headerStyle: {
                width: "4rem",
            },
            formatter: (cell) => (
                <FontAwesomeIcon
                    icon={cell === "MANUAL" ? faUser : faRobot}
                    title={cell}
                    className={clsx(cell === "MANUAL" && "text-success")}
                    fixedWidth
                />
            ),
        },
        {
            dataField: "action",
            isDummy: true,
            text: "",
            headerStyle: {
                width: "3rem",
            },
            headerFormatter: (column) => (
                <button
                    className="btn bg-transparent p-0 border-0 text-danger"
                    onClick={onDeleteAll}
                    title={I18n.getTranslation(
                        location,
                        "utils.button.deleteAll"
                    )}
                    disabled={state.matchings.length === 0}
                >
                    <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
                </button>
            ),
            formatter: (cell, row) => (
                <button
                    className="btn bg-transparent p-0 border-0 text-danger"
                    onClick={() => {
                        onDelete([row.id]);
                    }}
                    title={I18n.getTranslation(location, "utils.button.delete")}
                >
                    <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
                </button>
            ),
        },
    ];

    return (
        <MatchV1
            {...props}
            {...state}
            partner={partner}
            columns={columns}
            onMatch={onMatch}
            filterOption={filterOption}
        />
    );
};

export default EnhancedMatchV1;
