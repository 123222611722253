import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const DeleteButton = ({ handleDelete, location }) => {
    return (
        <Button
            variant="danger"
            className="btn-tab-delete"
            onClick={handleDelete}
            title={I18n.getTranslation(
                location,
                "apple.sample.assessment.rating.delete.tooltip"
            )}
        >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
        </Button>
    );
};

export default DeleteButton;
