import React from "react";
import { useLocation } from "react-router-dom";
import SidePanel from "./SidePanel";

const EnhancedSidePanel = (props) => {
    const location = useLocation();

    return <SidePanel location={location} {...props} />;
};

export default EnhancedSidePanel;
