import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import { getFirstLetter, getFilteredRows } from "./Chart.pure";
import Chart from "./Chart";

const EnhancedChart = ({
    chart,
    year,
    chartType,
    platformId,
    titleId,
    ...props
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { locale } = useParams();
    const [filter, setFilter] = useState({
        firstLetter: null,
        status: null,
    });

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.rank"
            ),
            headerStyle: { width: "4em" },
            sort: true,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.artist"
            ),
            sort: true,
            sortValue: (cell, row) =>
                `${getFirstLetter(cell)} ${cell} ${row.title}`,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.report.consolidate.chart.title"
            ),
        },
    ];

    const rowClasses = ({ validated, discarded, reported }) =>
        discarded
            ? "table-secondary"
            : validated
            ? "table-success"
            : reported
            ? "table-danger"
            : undefined;

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/cnm/consolidation/${year}/${chartType}/${platformId}/${row.id}`
                );
            }
        },
        selected: [titleId],
    };

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 1000,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const defaultSorted = [
        {
            dataField: "artist",
            order: "asc",
        },
    ];

    return (
        <Chart
            chart={getFilteredRows({ chart, filter, titleId })}
            columns={columns}
            rowClasses={rowClasses}
            selectRow={selectRow}
            pagination={pagination}
            defaultSorted={defaultSorted}
            location={location}
            filter={filter}
            setFilter={setFilter}
            {...props}
        />
    );
};

export default EnhancedChart;
