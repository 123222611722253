import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useInView } from "react-intersection-observer";
import PlanningsTab from "./PlanningsTab";

const EnhancedPlanningsTab = (props) => {
    const [selectedTab, setSelectedTab] = useState("planning");
    const location = useLocation();

    const onSelectTab = (k) => setSelectedTab(k);

    const { ref: headerObserverRef, inView: headerObserverInView } = useInView({
        threshold: 1,
    });

    const containerRef = useRef(null);
    const [containerTopOffset, setContainerTopOffset] = useState();

    useEffect(() => {
        if (containerRef) {
            setContainerTopOffset(
                containerRef.current.getBoundingClientRect().top
            );
        }
    }, [containerRef, headerObserverInView, setContainerTopOffset]);

    return (
        <PlanningsTab
            {...props}
            headerObserverRef={headerObserverRef}
            isHeaderSticky={!headerObserverInView}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            location={location}
            containerRef={containerRef}
            containerTopOffset={containerTopOffset}
        />
    );
};

export default EnhancedPlanningsTab;
