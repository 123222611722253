import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { PanelContext } from "./Panel.context";

const generatePanelKey = (() => {
    let count = 0;

    return () => `${++count}`;
})();

export const usePanel = (component) => {
    const key = useMemo(generatePanelKey, []);
    const panel = useMemo(() => component, [component]);
    const context = useContext(PanelContext);
    const [isShown, setShown] = useState(false);
    const showPanel = useCallback(() => setShown(true), []);
    const hidePanel = useCallback(() => setShown(false), []);

    useEffect(() => {
        if (isShown) {
            context.showPanel(key, panel);
        } else {
            context.hidePanel(key);
        }

        return () => context.hidePanel(key);
    }, [panel, isShown, context, key]);

    return [showPanel, hidePanel];
};
