import React from "react";
import I18n from "shared/lib/I18n";
import { InputGroup, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import InputAsyncCreatableSelect from "shared/components/Form/InputAsyncCreatableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { addToClipboard } from "shared/functions/AddToClipboard";
import Loader from "shared/components/Loader";
import clsx from "clsx";

const PersonalDetails = ({
    isLoading,
    location,
    locale,
    errors,
    register,
    control,
    getValues,
    isSubmitting,
    isDirty,
    onSubmit,
    accountTypeOptions,
    accountRoleOptions,
    accountLanguageOptions,
    accountTypeWatch,
    loadLabelOptions,
    isToken,
}) => {
    return (
        <>
            {isLoading && (
                <div className="loader" style={{ zIndex: "9999999" }}>
                    <Loader size="2x" />
                </div>
            )}
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Form.Group>
                    <Form.Label>
                        <I18n
                            t={`profile.personal_details.account_type.title`}
                        />
                    </Form.Label>
                    <InputText
                        value={
                            getValues("type")
                                ? accountTypeOptions.find(
                                      (a) => getValues("type") === a.value
                                  ).label
                                : null
                        }
                        error={
                            errors.type && [
                                I18n.getTranslation(
                                    location,
                                    errors.type.message
                                ),
                            ]
                        }
                        disabled={true}
                    />
                </Form.Group>
                <div className="row">
                    <div className="col">
                        <Form.Group>
                            <Form.Label>
                                <I18n
                                    t={`profile.personal_details.firstname`}
                                />
                            </Form.Label>
                            <InputText
                                {...register("firstname")}
                                error={
                                    errors.firstname && [
                                        I18n.getTranslation(
                                            location,
                                            errors.firstname.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group>
                            <Form.Label>
                                <I18n t={`profile.personal_details.lastname`} />
                            </Form.Label>
                            <InputText
                                {...register("lastname")}
                                error={
                                    errors.lastname && [
                                        I18n.getTranslation(
                                            location,
                                            errors.lastname.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>
                        <I18n t={`profile.personal_details.email`} />
                    </Form.Label>
                    <InputText
                        {...register("email")}
                        error={
                            errors.email && [
                                I18n.getTranslation(
                                    location,
                                    errors.email.message
                                ),
                            ]
                        }
                        disabled={true}
                    />
                </Form.Group>
                <div className="row">
                    {accountTypeWatch === "artist_manager" && (
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n
                                        t={`profile.personal_details.artist`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("artist")}
                                    error={
                                        errors.artist && [
                                            I18n.getTranslation(
                                                location,
                                                errors.artist.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                    )}
                    {accountTypeWatch === "label_distributor" && (
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n
                                        t={`profile.personal_details.label`}
                                    />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="label"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputAsyncCreatableSelect
                                            innerRef={ref}
                                            className={clsx("text-left")}
                                            value={
                                                value
                                                    ? {
                                                          value: value,
                                                          label: value,
                                                      }
                                                    : undefined
                                            }
                                            loadOptions={loadLabelOptions}
                                            onChange={(opt, action) => {
                                                if (
                                                    action.action ===
                                                    "select-option"
                                                ) {
                                                    onChange(opt.label);
                                                } else if (
                                                    action.action ===
                                                    "create-option"
                                                ) {
                                                    onChange(opt.label);
                                                } else {
                                                    onChange();
                                                }
                                            }}
                                            allowCreateWhileLoading={false}
                                            createOptionPosition="first"
                                            isClearable={false}
                                            error={
                                                errors.label && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.label.message
                                                    ),
                                                ]
                                            }
                                            showError={false}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </div>
                    )}
                    {["music_service", "cmo", "label_distributor"].includes(
                        accountTypeWatch
                    ) && (
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n
                                        t={`profile.personal_details.company`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("company")}
                                    error={
                                        errors.company && [
                                            I18n.getTranslation(
                                                location,
                                                errors.company.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                    )}
                </div>
                {["music_service", "cmo", "label_distributor"].includes(
                    accountTypeWatch
                ) && (
                    <div className="row">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n
                                        t={`profile.personal_details.role.title`}
                                    />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="role"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            innerRef={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: accountRoleOptions[
                                                              accountTypeWatch
                                                          ]?.find(
                                                              (a) =>
                                                                  value ===
                                                                  a.value
                                                          )?.label,
                                                      }
                                                    : null
                                            }
                                            className="flex-grow-1"
                                            options={
                                                accountRoleOptions[
                                                    accountTypeWatch
                                                ]
                                            }
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                            isClearable={true}
                                            error={
                                                errors.role && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.role.message
                                                    ),
                                                ]
                                            }
                                            showError={false}
                                            enablePortal
                                        />
                                    )}
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n
                                        t={`profile.personal_details.describe`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("describe")}
                                    error={
                                        errors.describe && [
                                            I18n.getTranslation(
                                                location,
                                                errors.describe.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                    </div>
                )}
                <div className="row mb-3">
                    <div className="col">
                        <Form.Group>
                            <Form.Label>
                                <I18n t={`profile.personal_details.language`} />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="language"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        value={
                                            value !== null
                                                ? {
                                                      value,
                                                      label: accountLanguageOptions.find(
                                                          (a) =>
                                                              value === a.value
                                                      ).label,
                                                  }
                                                : null
                                        }
                                        className="text-left"
                                        options={accountLanguageOptions}
                                        onChange={(opt) => {
                                            onChange(opt ? opt.value : null);
                                        }}
                                        isClearable={false}
                                        error={
                                            errors.language && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.language.message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                        enablePortal
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group>
                            <Form.Label>
                                <I18n
                                    t={`profile.personal_details.expires_at`}
                                />
                            </Form.Label>
                            <InputText
                                {...register("expires_at")}
                                error={
                                    errors.expires_at && [
                                        I18n.getTranslation(
                                            location,
                                            errors.expires_at.message
                                        ),
                                    ]
                                }
                                disabled={true}
                            />
                        </Form.Group>
                    </div>
                </div>
                {isToken && (
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    disabled
                                >
                                    <I18n t="profile.personal_details.token" />
                                </Button>
                            </InputGroup.Prepend>
                            <InputText
                                defaultValue={getValues("token")}
                                disabled
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() =>
                                        addToClipboard(
                                            getValues("token"),
                                            locale
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faCopy} fixedWidth />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                )}
            </Form>
            <div className="d-flex justify-content-end">
                <Button
                    disabled={isSubmitting || isLoading || !isDirty}
                    onClick={!isSubmitting && !isLoading ? onSubmit : null}
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t={`utils.button.save`} />
                    )}
                </Button>
            </div>
        </>
    );
};

export default PersonalDetails;
