import React from "react";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import I18n from "shared/lib/I18n";
import Error from "shared/components/Form/Error";
import CreateOrUpdateButton from "./CreateOrUpdateButton";

const CNMArtistSelect = ({
    defaultName,
    control,
    setValue,
    onCnmArtistChange,
    name,
    error,
    location,
    disabled,
    loadOptions,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <>
                    <span className="d-inline-flex w-100">
                        <InputAsyncSelect
                            innerRef={ref}
                            name={name}
                            className="flex-grow-1"
                            placeholder={I18n.getTranslation(
                                location,
                                "cnm.report.consolidate.edit.cnmArtist.placeholder"
                            )}
                            loadOptions={loadOptions}
                            value={value}
                            onChange={(option, action) => {
                                if (action.action === "select-option") {
                                    onChange({
                                        id: option.id,
                                        name: option.name,
                                    });
                                    onCnmArtistChange(option);
                                } else if (action.action === "clear") {
                                    onChange(null);
                                    onCnmArtistChange(null);
                                }
                            }}
                            getOptionValue={({ id }) => id}
                            getOptionLabel={({ name }) => name}
                            isDisabled={disabled}
                            isClearable
                            enablePortal
                            error={error}
                            showError={false}
                        />
                        <CreateOrUpdateButton
                            className="ml-2"
                            artist={value}
                            defaultName={defaultName}
                            onSuccess={onChange}
                            title={I18n.getTranslation(
                                location,
                                value
                                    ? "cnm.report.consolidate.edit.cnmArtist.update.title"
                                    : "cnm.report.consolidate.edit.cnmArtist.create.title"
                            )}
                            disabled={disabled}
                        >
                            <FontAwesomeIcon
                                icon={value ? faPen : faPlus}
                                fixedWidth
                            />
                        </CreateOrUpdateButton>
                    </span>
                    {error !== null && <Error error={error} className="mt-3" />}
                </>
            )}
        />
    );
};

export default CNMArtistSelect;
