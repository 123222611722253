import React from "react";
import Show from "./Show";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import swal from "@sweetalert/with-react";
import { ListGroup } from "react-bootstrap";

const EnhancedShow = ({ users, ...props }) => {
    const location = useLocation();

    const onClick = () => {
        let message = I18n.getTranslation(
            location,
            "labelPromo.manage.show.empty"
        );

        if (users.length !== 0) {
            message = (
                <ListGroup variant="flush" className="mb-4">
                    <ListGroup.Item>
                        {I18n.getTranslation(
                            location,
                            "labelPromo.manage.show.title"
                        )}
                    </ListGroup.Item>
                    {users.map(({ id, username }) => (
                        <ListGroup.Item key={id}>{username}</ListGroup.Item>
                    ))}
                </ListGroup>
            );
        }

        swal(message, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };

    return (
        <Show {...props} users={users} onClick={onClick} location={location} />
    );
};

export default EnhancedShow;
