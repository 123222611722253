import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashAlt,
    faLink,
    faCompactDisc,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { NavLink } from "shared/lib/I18n";
import BadgeGroup from "shared/components/BadgeGroup";
import SubTask from "./SubTask";
import Update from "pages/PlanningsManagement/Button/Update";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const Task = ({
    task,
    handleStatusChanged,
    handleDeleted,
    handleTasksChange,
    informations,
    isPlanningLock,
}) => {
    const location = useLocation();
    const { locale, partnerId } = useParams();

    return (
        <tr>
            <td>
                {task?.ms_artist ? (
                    <a
                        href={`/${locale}/artist/${task.ms_artist.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.ms_artist.name}
                    </a>
                ) : (
                    task.artist_name ?? "-"
                )}
            </td>
            <td>{task.album_title ?? "-"}</td>
            <td>{task.track_title ?? "-"}</td>
            <td>
                {task.category ? (
                    <span className="badge badge-info">{task.category}</span>
                ) : (
                    "-"
                )}
            </td>
            <td>{task.reason ?? "-"}</td>
            <td>
                {task.classification ? (
                    <span className="badge badge-info">
                        {task.classification}
                    </span>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.subtasks.length
                    ? task.subtasks.map((subtask) => (
                          <SubTask
                              key={subtask.id}
                              subtask={subtask}
                              handleStatusChanged={handleStatusChanged}
                          />
                      ))
                    : "-"}
            </td>
            <td>
                {informations.length != 0 ? (
                    <BadgeGroup datas={informations} />
                ) : (
                    "-"
                )}
            </td>
            <td className="text-right">
                <div className="btn-group btn-group-sm">
                    {task.top_id && (
                        <NavLink
                            to={`/matching/tops/${task.top_type_id}/${task.top_id}`}
                            className="btn btn-primary"
                            title={I18n.getTranslation(
                                location,
                                "plannings.view.tasks.action.topLink"
                            )}
                            target="_blank"
                            style={{ width: "30px" }}
                        >
                            <FontAwesomeIcon icon={faLink} size="lg" />
                        </NavLink>
                    )}
                    {task.lyrics_sample_id && (
                        <NavLink
                            to={`/apple/sample/assessment/${task.lyrics_sample_id}`}
                            className={`btn btn-primary`}
                            title={I18n.getTranslation(
                                location,
                                "plannings.view.tasks.action.lyricsSampleLink"
                            )}
                            target="_blank"
                            style={{ width: "30px" }}
                        >
                            <FontAwesomeIcon
                                icon={faLink}
                                size="lg"
                                fixedWidth
                            />
                        </NavLink>
                    )}
                    {task?.ms_artist && (
                        <NavLink
                            to={`/artist/${task.ms_artist.id}/edit/discography`}
                            className="btn btn-primary"
                            title={I18n.getTranslation(
                                location,
                                "plannings.view.tasks.action.discoLink"
                            )}
                            target="_blank"
                            style={{ width: "30px" }}
                        >
                            <FontAwesomeIcon icon={faCompactDisc} size="lg" />
                        </NavLink>
                    )}
                    {partnerId != PLANNING_PARTNER.ALL && (
                        <>
                            <Update
                                taskId={task.id}
                                onSuccess={handleTasksChange}
                            />
                            <button
                                className={`btn btn-danger ${
                                    isPlanningLock && "disabled"
                                }`}
                                onClick={() => handleDeleted(task.id)}
                                title={I18n.getTranslation(
                                    location,
                                    "plannings.view.tasks.action.delete.title"
                                )}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    fixedWidth
                                />
                            </button>
                        </>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default Task;
