import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import MatchedSwitch from "./MatchedSwitch";

const EnhancedMatchedSwitch = ({
    entryId,
    rowId,
    locale,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const onChecked = (matched) => {
        const apiRequest = matched
            ? api.post(
                  `matching/top/matched/${entryId}`,
                  {
                      locale,
                  },
                  { rowId }
              )
            : api.delete(`matching/top/matched/${entryId}`, { locale });

        apiRequest
            .then((response) => {
                onChange({ matched });
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return <MatchedSwitch onChecked={onChecked} {...props} />;
};

export default EnhancedMatchedSwitch;
