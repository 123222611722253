import React from "react";
import { Button } from "react-bootstrap";

const DownloadButton = ({ onClick, children, ...props }) => {
    return (
        <Button {...props} onClick={onClick}>
            {children}
        </Button>
    );
};

export default DownloadButton;
