import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import MarkAction from "./MarkAction";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedMarkAction = ({ listId, rowId, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();

    const onMark = () => {
        api.post(`matching/artist/seatgeek/list/${listId}/${rowId}/mark`)
            .then((response) => {
                onSuccess(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.mark.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.mark.error",
                        error.message
                    )
                );
            });
    };

    return <MarkAction onMark={onMark} location={location} {...props} />;
};

export default EnhancedMarkAction;
