import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";

const EnhancedFilter = (props) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const [planningsOptions, setPlanningsOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        api.get("plannings", { locale })
            .then((response) => {
                if (!cancelled) {
                    setPlanningsOptions([
                        {
                            label: I18n.getTranslation(
                                location,
                                "plannings.manage.planning.building"
                            ),
                            options: response
                                .filter(({ status }) => status === "BUILDING")
                                .map((row) => {
                                    const label = [
                                        row.title,
                                        row.start_date,
                                        row.end_date,
                                        `(${row.nb_tasks_completed}/${row.nb_tasks})`,
                                    ];
                                    return {
                                        value: row.id,
                                        label: label
                                            .filter(Boolean)
                                            .join(" - "),
                                    };
                                }),
                        },
                        {
                            label: I18n.getTranslation(
                                location,
                                "plannings.manage.planning.progress"
                            ),
                            options: response
                                .filter(({ status }) => status === "PROGRESS")
                                .map((row) => {
                                    const label = [
                                        row.title,
                                        row.start_date,
                                        row.end_date,
                                        `(${row.nb_tasks_completed}/${row.nb_tasks})`,
                                    ];
                                    return {
                                        value: row.id,
                                        label: label
                                            .filter(Boolean)
                                            .join(" - "),
                                    };
                                }),
                        },
                    ]);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [setPlanningsOptions, locale, location]);

    const [assignementOptions, setAssignementOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/tasks/assignement`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setAssignementOptions(
                        response.map(({ id, name }) => {
                            return { label: name, value: id };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, setAssignementOptions]);

    return (
        <Filter
            {...props}
            planningsOptions={planningsOptions}
            assignementOptions={assignementOptions}
        />
    );
};

export default EnhancedFilter;
