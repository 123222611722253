import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";

const GenreReference = ({
    genres,
    selectedGenre,
    onSelect,
    location,
    disabled,
}) => {
    return (
        <div className="form-control reference-list">
            {genres?.map(({ name, cnmId, cnmName, platform }, index) => (
                <Button
                    key={index}
                    variant={
                        cnmId === selectedGenre?.id
                            ? platform_color(platform)
                            : `outline-${platform_color(platform)}`
                    }
                    title={I18n.getTranslation(
                        location,
                        cnmId === selectedGenre?.id
                            ? "cnm.report.consolidate.edit.genre.unselect"
                            : "cnm.report.consolidate.edit.genre.select"
                    )}
                    onClick={() => {
                        onSelect(
                            cnmId === selectedGenre?.id
                                ? null
                                : {
                                      id: cnmId,
                                      name: cnmName,
                                  }
                        );
                    }}
                    disabled={disabled || cnmId === null}
                >
                    {cnmId === null ? name : `${cnmName} (${name})`}
                </Button>
            ))}
        </div>
    );
};

export default GenreReference;
