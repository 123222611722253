import React from "react";
import I18n from "shared/lib/I18n";
import Subtasks from "./Subtasks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useParams } from "react-router";
import Loader from "shared/components/Loader";

const Months = ({ userId, month, first, last, next, previous, isLoading }) => {
    const { locale } = useParams();
    const localizedMonth = moment(month?.month).locale(locale);
    const formatedMonth = localizedMonth.format("MMMM YYYY");

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <button
                        type="button"
                        onClick={previous}
                        className="btn btn-primary btn-lg"
                        disabled={
                            first === month?.month ? "disabled" : undefined
                        }
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                        <span className="ml-3">
                            <I18n t="utils.button.previous" />
                        </span>
                    </button>
                    <div className="text-center">
                        <h2>
                            {formatedMonth.charAt(0).toUpperCase() +
                                formatedMonth.slice(1)}
                        </h2>
                        {month?.start_date && month?.end_date && (
                            <I18n
                                t="plannings.billing.month.period"
                                args={{
                                    start_date: month.start_date,
                                    end_date: month.end_date,
                                }}
                            />
                        )}
                    </div>
                    <button
                        type="button"
                        onClick={next}
                        className="btn btn-primary btn-lg"
                        disabled={
                            last === month?.month ? "disabled" : undefined
                        }
                    >
                        <span className="mr-3">
                            <I18n t="utils.button.next" />
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} size="lg" />
                    </button>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-12">
                    <Subtasks userId={userId} month={month?.month} />
                </div>
            </div>
        </>
    );
};

export default Months;
