import React, { useState } from "react";
import AddButton from "./AddButton";
import useToggle from "shared/hooks/useToggle";
import useIntParams from "shared/hooks/useIntParams";

const EnhancedAddButton = ({ onSuccess, ...props }) => {
    const { artistId } = useIntParams();
    const [pictureId, setPictureId] = useState();
    const [versionId, setVersionId] = useState();
    const [showUpload, toggleUpload] = useToggle(false);
    const onUploadSuccess = (pictureId, id_picture_original) => {
        toggleUpload();
        setPictureId(pictureId);
        setVersionId(id_picture_original);
        toggleTags();
        onSuccess?.();
    };

    const [showTags, toggleTags] = useToggle(false);
    const onTagsSuccess = () => {
        toggleTags();
        toggleCrop();
        onSuccess?.();
    };

    const [showCrop, toggleCrop] = useToggle(false);
    const onCropSuccess = (versionId) => {
        toggleCrop();
        setVersionId(versionId);
        toggleTagsVersion();
        onSuccess?.();
    };

    const [showTagsVersion, toggleTagsVersion] = useToggle(false);
    const onTagsVersionSuccess = () => {
        toggleTagsVersion();
        onSuccess?.();
    };

    return (
        <AddButton
            {...props}
            artistId={artistId}
            showUpload={showUpload}
            toggleUpload={toggleUpload}
            onUploadSuccess={onUploadSuccess}
            pictureId={pictureId}
            versionId={versionId}
            showTags={showTags}
            toggleTags={toggleTags}
            onTagsSuccess={onTagsSuccess}
            showCrop={showCrop}
            toggleCrop={toggleCrop}
            onCropSuccess={onCropSuccess}
            showTagsVersion={showTagsVersion}
            toggleTagsVersion={toggleTagsVersion}
            onTagsVersionSuccess={onTagsVersionSuccess}
        />
    );
};

export default EnhancedAddButton;
