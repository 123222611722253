import React from "react";
import Chart from "./Chart";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router-dom";

const EnhancedChart = React.memo(
    ({ title_repartition, setPopover, ...props }) => {
        const location = useLocation();

        const getColor = (type) => {
            if (type === null) {
                return "#DDDDDD";
            } else if (type === "gold") return "#000000";
            return "#FF00FF";
        };

        const data = {
            labels: title_repartition.map(({ type }) => {
                if (type === "gold") {
                    return I18n.getTranslation(
                        location,
                        "landing.industryStats.gold"
                    );
                } else if (type === "current") {
                    return I18n.getTranslation(
                        location,
                        "landing.industryStats.current"
                    );
                } else {
                    return I18n.getTranslation(
                        location,
                        "landing.industryStats.no_data"
                    );
                }
            }),
            datasets: [
                {
                    label: "current-catalog",
                    data: title_repartition.map(({ count }) => count),
                    backgroundColor: title_repartition.map(({ type }) =>
                        getColor(type)
                    ),
                    borderRadius: 2,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                legend: {
                    position: "top",
                },
                tooltip: {
                    enabled: false,
                    external: ({ chart, tooltip }) => {
                        setPopover({
                            show:
                                tooltip.opacity > 0 &&
                                tooltip.dataPoints !== undefined,
                            left: chart.canvas.offsetLeft + tooltip.caretX,
                            top: chart.canvas.offsetTop + tooltip.caretY,
                            title_repartition:
                                tooltip.dataPoints !== undefined
                                    ? title_repartition[
                                          tooltip.dataPoints[0].dataIndex
                                      ]
                                    : null,
                        });
                    },
                },
            },
        };

        return <Chart {...props} data={data} options={options} />;
    }
);

export default EnhancedChart;
