import React, { useState, useEffect, useCallback } from "react";
import Label from "./Label";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import Picture from "./Picture";

const defaultState = {
    isLoading: false,
    gallery: [],
};

const EnhancedLabel = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const { locale } = useParams();

    const getPictures = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/picture/label`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        gallery: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getPictures, [getPictures]);

    const columns = [
        {
            dataField: "label",
            text: "",
        },
    ];

    const expandRow = {
        renderer: ({ gallery }) => {
            return (
                <div className="grid m-2">
                    {gallery.map((picture) => (
                        <Picture
                            key={`${picture.id_picture}-${picture.id_version}`}
                            picture={picture}
                            getPictures={getPictures}
                        />
                    ))}
                </div>
            );
        },
        expandByColumnOnly: true,
        expanded: state.gallery.map(({ id }) => id),
    };

    return (
        <Label
            {...props}
            {...state}
            getPictures={getPictures}
            columns={columns}
            expandRow={expandRow}
        />
    );
};

export default EnhancedLabel;
