import React, { useReducer, useMemo } from "react";
import { SidebarNotificationContext } from "./SidebarNotification.context";
import { SidebarNotificationReducer } from "./SidebarNotification.reducer";

const initialState = {
    labelPromoDisable: 0,
    picturesLabelAwaiting: 0,
};

export const SidebarNotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        SidebarNotificationReducer,
        initialState
    );
    const contextValue = useMemo(
        () => ({
            labelPromoDisable: {
                count: state.labelPromoDisable,
                dispatch,
            },
            picturesLabelAwaiting: {
                count: state.picturesLabelAwaiting,
                dispatch,
            },
        }),
        [state, dispatch]
    );

    return (
        <SidebarNotificationContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </SidebarNotificationContext.Provider>
    );
};
