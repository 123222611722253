import React from "react";
import I18n from "shared/lib/I18n";
import Platform from "./Platform";
import Deduplicated from "./Deduplicated";
import Aggregate from "./Aggregate";
import { Tab, Nav } from "react-bootstrap";
import { NavLink } from "shared/lib/I18n";
import { Helmet } from "react-helmet";
import "./CNMCharts.css";

const CNMCharts = ({ selected, locale, navigate, user, location }) => {
    return (
        <div className="content-wrap cnm-charts">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.charts"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/cnm/charts">
                            <I18n t="base.nav.cnm.charts" />
                        </NavLink>
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <Tab.Container
                            id="cnm-charts-tab"
                            activeKey={selected}
                            onSelect={(k) =>
                                navigate(`/${locale}/cnm/charts/${k}`)
                            }
                            mountOnEnter
                            unmountOnExit
                        >
                            <Nav variant="pills" justify>
                                {user.hasRight("cnm.charts.platform.show") && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="platform">
                                            <I18n t="cnm.charts.platform.nav" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                {user.hasRight(
                                    "cnm.charts.deduplicated.show"
                                ) && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="deduplicated">
                                            <I18n t="cnm.charts.deduplicated.nav" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                {user.hasRight("cnm.charts.aggregate.show") && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="aggregate">
                                            <I18n t="cnm.charts.aggregate.nav" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                            <Tab.Content className="nav-content overflow-hidden">
                                {user.hasRight("cnm.charts.platform.show") && (
                                    <Tab.Pane
                                        eventKey="platform"
                                        className="nav-pane"
                                    >
                                        <Platform />
                                    </Tab.Pane>
                                )}
                                {user.hasRight(
                                    "cnm.charts.deduplicated.show"
                                ) && (
                                    <Tab.Pane
                                        eventKey="deduplicated"
                                        className="nav-pane"
                                    >
                                        <Deduplicated />
                                    </Tab.Pane>
                                )}
                                {user.hasRight("cnm.charts.aggregate.show") && (
                                    <Tab.Pane
                                        eventKey="aggregate"
                                        className="nav-pane"
                                    >
                                        <Aggregate />
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default CNMCharts;
