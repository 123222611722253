import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputDate from "shared/components/Form/InputDate";
import { Controller } from "react-hook-form";
import moment from "moment";

const CreateOrUpdateModal = ({
    planning,
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    control,
    register,
    errors,
    isSubmitting,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`plannings.manage.${
                            planning ? "update" : "create"
                        }.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="plannings.manage.planning.title" />
                        </Form.Label>
                        <InputText {...register("title")} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="plannings.manage.planning.startDate" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="start_date"
                            render={({ field: { onChange, value } }) => (
                                <InputDate
                                    selected={value ? new Date(value) : null}
                                    onChange={(date) => {
                                        onChange(
                                            date
                                                ? moment(date).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : null
                                        );
                                    }}
                                    error={
                                        errors.start_date && [
                                            I18n.getTranslation(
                                                location,
                                                errors.start_date.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="plannings.manage.planning.endDate" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="end_date"
                            render={({ field: { onChange, value } }) => (
                                <InputDate
                                    selected={value ? new Date(value) : null}
                                    onChange={(date) => {
                                        onChange(
                                            date
                                                ? moment(date).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : null
                                        );
                                    }}
                                    error={
                                        errors.end_date && [
                                            I18n.getTranslation(
                                                location,
                                                errors.end_date.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
