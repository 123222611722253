import React, { useState, useEffect, useCallback, useContext } from "react";
import Isrcs from "./Isrcs";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AlbumViewContext, ISRC_SELECTION_SET } from "pages/AlbumView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import RecordingLink from "shared/components/RecordingLink";
import Explicit from "shared/components/Explicit";

const defaultState = {
    isrcs: [],
    isLoading: true,
};

const defaultModalState = {
    show: false,
    links: [],
};

const EnhancedIsrcs = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { albumId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const [modalState, setModalState] = useState(defaultModalState);
    const { dispatchAlbum, profileSelection, profileHighlights } =
        useContext(AlbumViewContext);

    const getIsrcs = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`album/${albumId}/isrcs`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    isrcs: response,
                }));
                if (response.length === 0) {
                    setNoData("isrcs");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getIsrcs()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getIsrcs]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "album.view.profileIsrcs.id"),
            sort: true,
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(location, "album.view.profileIsrcs.isrc"),
            formatter: (cell, row) => (
                <>
                    {cell}
                    <Explicit
                        className="ml-1"
                        explicit={row.parental_advisory}
                    />
                </>
            ),
            sort: true,
            classes: "nowrap",
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "album.view.profileIsrcs.title"
            ),
            sort: true,
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "album.view.profileIsrcs.duration"
            ),
            formatter: (cell) => cell ?? "-",
            sort: true,
        },
        {
            dataField: "complementary",
            text: I18n.getTranslation(
                location,
                "album.view.profileIsrcs.complementary"
            ),
            formatter: (cell, { id, has_credits, has_lyric, has_audio }) => (
                <>
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_credits ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_credits" />
                            </span>
                        }
                        part="credits"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_lyric ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_lyric" />
                            </span>
                        }
                        part="lyrics"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_audio ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_audio" />
                            </span>
                        }
                        part="audio_description"
                    />
                </>
            ),
            isDummyField: true,
        },
        {
            dataField: "links",
            text: I18n.getTranslation(
                location,
                "album.view.profileIsrcs.links"
            ),
            formatter: (cell) => (
                <Button
                    variant={cell.length === 0 ? "secondary" : "primary"}
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalState({ show: true, links: cell });
                    }}
                    disabled={cell.length === 0}
                >
                    <FontAwesomeIcon icon={faLink} fixedWidth />
                </Button>
            ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ isrc }, isSelect) => {
            if (isSelect) {
                dispatchAlbum({
                    type: ISRC_SELECTION_SET,
                    data: isrc,
                });
            }
        },
        selected: [profileSelection.isrc],
    };

    const rowClasses = ({ isrc }) => {
        let classes = null;

        if (profileHighlights.isrcs.includes(isrc)) {
            classes = "table-warning";
        }

        return classes;
    };

    const defaultSorted = [
        {
            dataField: "title",
            order: "asc",
        },
    ];

    return (
        <Isrcs
            {...props}
            {...state}
            columns={columns}
            selectRow={selectRow}
            rowClasses={rowClasses}
            defaultSorted={defaultSorted}
            modalState={modalState}
            setModalState={setModalState}
            location={location}
        />
    );
};

export default EnhancedIsrcs;
