import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import logo from "assets/static/images/MusicStory_Logo_HOR_noTag_WHITE.png";
import styles from "./SidePanel.module.css";
import clsx from "clsx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SidePanel = ({ location, withoutSlider }) => {
    return (
        <div className={clsx("p-lg-5 p-4 h-100", styles.authOneBg)}>
            <div className="position-relative h-100 d-flex flex-column">
                <div className="mb-4 align-self-center">
                    <NavLink to={`/`} className="d-block">
                        <img
                            src={logo}
                            alt={`Music Story - ${I18n.getTranslation(
                                location,
                                "dashboard"
                            )}`}
                            height="35px"
                        />
                    </NavLink>
                    <span className={clsx("font-weight-bold", styles.subtitle)}>
                        <I18n t={`dashboard`} />
                    </span>
                </div>
                {!withoutSlider && (
                    <div className="mt-auto">
                        <div className="mb-3">
                            <Carousel
                                showThumbs={false}
                                autoPlay={true}
                                showArrows={false}
                                showStatus={false}
                                infiniteLoop={true}
                                interval={10000}
                                className="carousel"
                                renderIndicator={(
                                    onClickHandler,
                                    isSelected,
                                    index
                                ) => {
                                    return (
                                        <li
                                            className={clsx(
                                                styles.indicator,
                                                isSelected && styles.selected
                                            )}
                                            onClick={onClickHandler}
                                            onKeyDown={onClickHandler}
                                            value={index}
                                            key={index}
                                            role="button"
                                            tabIndex={0}
                                        ></li>
                                    );
                                }}
                            >
                                <div className="carousel-inner text-left text-white pb-5">
                                    <div className="item">
                                        <h3 className="font-weight-bold text-center mb-4">
                                            Explore our metadata
                                        </h3>
                                        <div>
                                            <ul>
                                                <li>
                                                    No hidden data: Explore all
                                                    the metadata available
                                                    through our API
                                                </li>
                                                <li>
                                                    Use our search engine to
                                                    navigate into :
                                                    <ul>
                                                        <li>
                                                            artists' metadata
                                                            (biographies,
                                                            images,
                                                            popularity...)
                                                        </li>
                                                        <li>
                                                            albums' metadata
                                                            (canonical album,
                                                            genre, original
                                                            release date...)
                                                        </li>
                                                        <li>
                                                            recordings' metadata
                                                            (credits, lyrics,
                                                            audio
                                                            description...)
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-inner text-left text-white pb-5">
                                    <div className="item">
                                        <h3 className="font-weight-bold text-center mb-4">
                                            Benefit from our team’s support
                                        </h3>
                                        <div>
                                            <ul>
                                                <li>
                                                    Chat online with our team
                                                </li>
                                                <li>
                                                    Get quick answers and book a
                                                    meeting
                                                </li>
                                                <li>
                                                    Share your metadata issues
                                                    so our team can provide a
                                                    solution
                                                </li>
                                                <li>
                                                    As a client, report bugs or
                                                    errors
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-inner text-left text-white pb-5">
                                    <div className="item">
                                        <h3 className="font-weight-bold text-center mb-4">
                                            Request access to our metadata
                                        </h3>
                                        <div>
                                            <ul>
                                                <li>
                                                    Request (and very soon
                                                    manage) your API access
                                                </li>
                                                <li>
                                                    Ask for samples or specific
                                                    matching exercises
                                                </li>
                                                <li>
                                                    Request extractions and
                                                    datasets
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-inner text-left text-white pb-5">
                                    <div className="item">
                                        <h3 className="font-weight-bold text-center mb-4">
                                            Request for new content
                                        </h3>
                                        <div>
                                            <ul>
                                                <li>
                                                    As a client, request to add
                                                    missing content
                                                </li>
                                                <li>
                                                    As an artist or label,
                                                    provide content to update
                                                    your profile on our partners
                                                    platforms (Deezer,
                                                    LyricFind...)
                                                </li>
                                                <li>
                                                    Provide feedback so we
                                                    design products tailored to
                                                    your needs
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SidePanel;
