import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import CreateButton from "./CreateButton";

const schema = yup.object().shape({
    artist: yup.string().ensure().trim().required("utils.form.required"),
    title: yup.string().ensure().trim().required("utils.form.required"),
});

const EnhancedCreateButton = ({
    selectedPlatformTitles,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const { year, cnmTitleId, similarCnmTitleId } = useIntParams();
    const { chartType, locale } = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const onShow = () => setShow(true);
    const onHide = () => setShow(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            artist: "",
            title: "",
        },
    });

    useEffect(() => {
        if (show) {
            reset({
                artist: selectedPlatformTitles[0].artist,
                title: selectedPlatformTitles[0].title,
            });
        }
    }, [show]);

    const onSubmit = (data) => {
        const platformTitleIds = selectedPlatformTitles.map(({ id }) => id);

        return api
            .post(
                similarCnmTitleId !== undefined
                    ? `cnm/aggregation/create/${year}/${chartType}/${cnmTitleId}/${similarCnmTitleId}`
                    : `cnm/aggregation/create/${year}/${chartType}/${cnmTitleId}`,
                { locale },
                { ...data, platformTitleIds }
            )
            .then((rows) => {
                onSuccess(rows, platformTitleIds);
                onHide();
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.aggregation.createButton.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CreateButton
            show={show}
            onShow={onShow}
            onHide={onHide}
            disabled={selectedPlatformTitles.length === 0}
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            isSubmitting={isSubmitting}
            errors={errors}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCreateButton;
