import React from "react";
import CSAArtists from "./CSAArtists";
import CNMArtistsTitles from "./CNMArtistsTitles";
import CNMDistributors from "./CNMDistributors";
import CNMGenres from "./CNMGenres";
import CNMLabels from "./CNMLabels";
import CypokLanguages from "./CypokLanguages";
import Platforms from "./Platforms";

const Repository = ({ repository, year }) => {
    switch (repository) {
        case "csa_artists":
            return year !== undefined && <CSAArtists year={year} />;
        case "cnm_artists_titles":
            return <CNMArtistsTitles />;
        case "cnm_distributors":
            return year !== undefined && <CNMDistributors year={year} />;
        case "cnm_genres":
            return year !== undefined && <CNMGenres year={year} />;
        case "cnm_labels":
            return year !== undefined && <CNMLabels year={year} />;
        case "cypok_languages":
            return year !== undefined && <CypokLanguages year={year} />;
        case "platforms":
            return <Platforms />;
        default:
            return null;
    }
};

export default Repository;
