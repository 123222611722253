import React from "react";
import { useLocation } from "react-router";
import { ButtonGroup, ListGroup } from "react-bootstrap";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
    normalizeInteger,
} from "shared/functions/normalize";
import UpdateModalButton from "./UpdateModalButton";
import DeleteButton from "./DeleteButton";
import TitleList from "./TitleList";

const EnhancedTitleList = ({ editable, onUpdate, onDelete, ...props }) => {
    const location = useLocation();
    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.titles.id"
            ),
            filterValue: normalizeInteger,
            headerStyle: { width: "5em" },
        },
        {
            dataField: "artist[name]",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.titles.artist"
            ),
            headerStyle: { width: "25%" },
            searchable: false,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.titles.title"
            ),
            headerStyle: { width: "25%" },
            filterValue: normalizeTitle,
        },
        {
            dataField: "msRecordings",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.titles.msRecordings"
            ),
            headerStyle: { width: "50%" },
            formatter: (cell, row, rowIndex, data) => (
                <div className="d-flex align-items-center">
                    {cell.length > 0 ? (
                        <div className="flex-grow-1">
                            <ListGroup variant="flush">
                                {cell.map(({ id, title, isrc }) => {
                                    return (
                                        <ListGroup.Item
                                            key={id}
                                            className="bg-transparent p-1"
                                        >
                                            {[id, title, isrc]
                                                .filter(Boolean)
                                                .join(" - ")}
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    ) : (
                        <span className="flex-grow-1">-</span>
                    )}
                    {data.editable && (
                        <ButtonGroup>
                            <UpdateModalButton
                                artist={row.artist}
                                title={row}
                                onUpdate={onUpdate}
                            />
                            <DeleteButton id={row.id} onDelete={onDelete} />
                        </ButtonGroup>
                    )}
                </div>
            ),
            formatExtraData: {
                editable,
            },
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 500,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <TitleList
            columns={columns}
            pagination={pagination}
            onColumnMatch={onColumnMatchNormalized}
            {...props}
        />
    );
};

export default EnhancedTitleList;
