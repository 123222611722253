import { createSelector } from "reselect";

export const getFilteredData = createSelector(
    [(args) => args.data, (args) => args.filter],
    (data, filter) => {
        let filteredData = data;

        if (filter.matched !== null) {
            filteredData = filteredData.filter(
                (p) =>
                    (p.msArtistBest !== null && p.msRecordings?.length > 0) ===
                    filter.matched.value
            );
        }

        return filteredData;
    }
);
