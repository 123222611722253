import React, { useState } from "react";
import License from "./License";
import { useLocation } from "react-router";

const EnhancedLicense = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <License
            {...props}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            location={location}
        />
    );
};

export default EnhancedLicense;
