import clsx from "clsx";
import React from "react";
import Error from "shared/components/Form/Error";

const InputTextArea = React.forwardRef(
    ({ className, error, ...props }, ref) => {
        return (
            <>
                <textarea
                    className={clsx(
                        "form-control",
                        className,
                        error && "is-invalid"
                    )}
                    ref={ref}
                    {...props}
                />

                <Error error={error} className="mt-3" />
            </>
        );
    }
);

export default InputTextArea;
