import React from "react";
import PictureCheckButton from "shared/components/PictureCheckButton";

const CheckAction = ({ artistId, onCheck }) => {
    return (
        <PictureCheckButton
            artistId={artistId}
            onCheck={onCheck}
            title="matching.artist.artist.check.button.title"
        />
    );
};

export default CheckAction;
