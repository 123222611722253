import React from "react";
import { Link } from "shared/lib/I18n";
import { Button, Modal, Table } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const MatchingsModal = ({ show, matchings, onClose }) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.disco.sandbox.matchings.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <I18n t="artist.management.disco.sandbox.matchings.body" />
                </p>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <I18n t="artist.management.disco.sandbox.matchings.artists" />
                            </th>
                            <th>
                                <I18n t="artist.management.disco.sandbox.matchings.album" />
                            </th>
                            <th>
                                <I18n t="artist.management.disco.sandbox.matchings.release" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchings.map(
                            ({
                                releaseId,
                                albumId,
                                albumTitle,
                                artistId,
                                artistName,
                            }) => (
                                <tr key={releaseId}>
                                    <td>
                                        {artistId == 102171 ? (
                                            `${artistName} (${artistId})`
                                        ) : (
                                            <Link
                                                to={`/artist/${artistId}/edit/discography/album/${albumId}/release/${releaseId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {artistName} ({artistId})
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        {albumTitle} ({albumId})
                                    </td>
                                    <td>{releaseId}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    <I18n t="artist.management.disco.sandbox.matchings.close" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MatchingsModal;
