import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const GenreSelect = ({ value, loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
            {...props}
        />
    );
};

export default GenreSelect;
