import React, { useState, useEffect, useCallback } from "react";
import RecordingCharts from "./RecordingCharts";
import { useLocation, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import {
    getMainCharts,
    getAllCharts,
    getHit,
    getEditorsPick,
} from "./RecordingCharts.pure";
import {
    MAIN_CHARTS,
    ALL_CHARTS,
    EDITORS_PICK,
    HIT,
} from "./RecordingCharts.constants";

const defaultState = {
    mainCharts: [],
    allCharts: [],
    hit: [],
    editorsPick: [],
    selectedTab: MAIN_CHARTS,
    isLoading: true,
};

const EnhancedRecordingCharts = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);
    const location = useLocation();

    const onSelectTab = (selectedTab) => {
        setState((prev) => ({
            ...prev,
            selectedTab,
        }));
    };

    const getRecordingCharts = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`artist/${artistId}/recording-charts`, { locale })
            .then((response) => {
                const mainCharts = getMainCharts({ recordings: response });
                const allCharts = getAllCharts({ recordings: response });
                const hit = getHit({ recordings: response });
                const editorsPick = getEditorsPick({ recordings: response });

                setState((prev) => ({
                    ...prev,
                    mainCharts,
                    allCharts,
                    hit,
                    editorsPick,
                    selectedTab:
                        mainCharts.length != 0
                            ? MAIN_CHARTS
                            : allCharts.length > hit.length
                            ? ALL_CHARTS
                            : hit.length > editorsPick.length
                            ? HIT
                            : editorsPick.length === 0
                            ? MAIN_CHARTS
                            : EDITORS_PICK,
                }));
                if (response.length === 0) {
                    setNoData("recordingCharts");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getRecordingCharts()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getRecordingCharts]);

    return (
        <RecordingCharts
            {...props}
            {...state}
            location={location}
            onSelectTab={onSelectTab}
        />
    );
};

export default EnhancedRecordingCharts;
