import { useState, useCallback } from "react";

const useModalState = (defaultProps = {}) => {
    const [state, setState] = useState({ ...defaultProps, show: false });

    const onShow = useCallback(
        (props = {}) =>
            setState({
                ...props,
                show: true,
            }),
        [setState]
    );
    const onHide = useCallback(
        () =>
            setState((prev) => ({
                ...prev,
                show: false,
            })),
        [setState]
    );

    return {
        ...state,
        onShow,
        onHide,
    };
};

export default useModalState;
