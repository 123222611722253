import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Loader from "shared/components/Loader/Loader";
import Create from "./Button/Create";
import { Helmet } from "react-helmet";

const MiscEntity = ({
    miscEntity,
    columns,
    isLoading,
    defaultSorted,
    onCreate,
    location,
}) => {
    const { SearchBar } = Search;
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "misc_entity.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="misc_entity.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <section className="widget pb-0">
                            <ToolkitProvider
                                keyField="id"
                                data={miscEntity}
                                columns={columns}
                                search
                            >
                                {(toolkitprops) => (
                                    <>
                                        <header className="d-flex justify-content-between">
                                            <h4>
                                                <I18n t="misc_entity.head" />
                                            </h4>
                                        </header>
                                        <div className="widget-body">
                                            <div className="row mb-3">
                                                <div className="col-10">
                                                    <SearchBar
                                                        {...toolkitprops.searchProps}
                                                        placeholder={I18n.getTranslation(
                                                            location,
                                                            "misc_entity.search"
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <Create
                                                        onSuccess={onCreate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-body widget-table-overflow">
                                            {isLoading ? (
                                                <Loader />
                                            ) : (
                                                <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    bootstrap4
                                                    striped
                                                    hover
                                                    noDataIndication={
                                                        <I18n t="misc_entity.empty" />
                                                    }
                                                    defaultSorted={
                                                        defaultSorted
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        </section>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MiscEntity;
