import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";

const CreateOrUpdateButton = ({
    show,
    action,
    errors,
    register,
    isSubmitting,
    onShow,
    onHide,
    onSubmit,
    ...props
}) => {
    return (
        <>
            <Button onClick={onShow} {...props} />

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n
                            t={`cnm.report.consolidate.edit.cnmArtist.${action}.title`}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.report.consolidate.edit.cnmArtist.name" />
                            </Form.Label>
                            <InputText
                                {...register("name")}
                                error={
                                    errors.name && [
                                        I18n.getTranslation(
                                            location,
                                            errors.name.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting}
                        onClick={onSubmit}
                    >
                        <I18n
                            t={
                                action === "create"
                                    ? "utils.button.create"
                                    : "utils.button.edit"
                            }
                        />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateOrUpdateButton;
