import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar as faStarFull,
    faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";

const RatingStar = ({ maxStars, value }) => {
    if (value === null || value === undefined) {
        return null;
    }

    const intValue = parseInt(value, 10);

    return (
        <div>
            {Array.from({ length: maxStars }, (_, i) => i + 1).map((i) => {
                const isHalf = i - 0.5 === value;
                return (
                    <FontAwesomeIcon
                        key={i}
                        icon={
                            i <= intValue
                                ? faStarFull
                                : isHalf
                                ? faStarHalfAlt
                                : faStarEmpty
                        }
                        fixedWidth
                    />
                );
            })}
        </div>
    );
};

export default RatingStar;
