import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import CreateOrUpdate from "pages/ArtistManagement/Discography/Forms/Album/CreateOrUpdate";

const Create = ({
    artistId,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
}) => {
    return (
        <>
            <Button
                variant="success"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.album.basic.create.title"
                )}
            >
                <I18n t="utils.button.add" />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="artist.management.disco.objects.album.basic.create.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateOrUpdate artistId={artistId} onSuccess={onSuccess} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Create;
