import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import GenreView from "./GenreView";
import useUser from "shared/hooks/useUser";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import useIntParams from "shared/hooks/useIntParams";

const EnhancedGenreView = (props) => {
    const [state, setState] = useState({
        genres: [],
        isLoading: false,
    });
    const location = useLocation();
    const { locale } = useParams();
    const { id_genre } = useIntParams();
    const navigate = useNavigate();
    const { user } = useUser();
    const { api } = useAPI();

    const getGenres = useCallback(() => {
        setState((previousState) => ({ ...previousState, isLoading: true }));
        api.get(`genre/${locale}`)
            .then((response) => {
                setState((previousState) => ({
                    ...previousState,
                    genres: response,
                    isLoading: false,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState((previousState) => ({
                    ...previousState,
                    isLoading: false,
                }));
            });
    }, [locale]);

    useEffect(getGenres, [getGenres]);

    const getGenre = (id_genre) =>
        state.genres.find(({ id }) => id === id_genre);

    const columns = [
        {
            dataField: "parent_name",
            isDirty: true,
            text: I18n.getTranslation(location, "genre.genre.parent"),
            sort: true,
            searchable: false,
            formatter: (cell, { id_parent }, rowIndex, data) => {
                return id_parent ? data.getGenre(id_parent).nom : "-";
            },
            sortValue: (cell, { id_parent }) =>
                id_parent ? getGenre(id_parent).nom : "-",
            formatExtraData: {
                getGenre,
            },
        },
        {
            dataField: "nom",
            text: I18n.getTranslation(location, "genre.genre.name"),
            sort: true,
            filterValue: normalizeTitle,
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(`/${locale}/genre/view/${row.id}`);
            }
        },
        selected: [id_genre],
    };

    return (
        <GenreView
            {...props}
            {...state}
            columns={columns}
            location={location}
            user={user}
            onColumnMatch={onColumnMatchNormalized}
            defaultSorted={defaultSorted}
            selectRow={selectRow}
        />
    );
};

export default EnhancedGenreView;
