import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";

const PlatformTitles = ({ titles, columns }) => {
    return (
        <BootstrapTable
            keyField="id"
            columns={columns}
            data={titles}
            classes="table-layout-auto mb-0"
            bordered={false}
            bootstrap4
            striped
            condensed
            noDataIndication={
                <I18n t="cnm.report.consolidate.edit.platformTitles.empty" />
            }
        />
    );
};

export default PlatformTitles;
