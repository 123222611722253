import React from "react";
import ProfileInformation from "./ProfileInformation";

const Profile = () => {
    return (
        <div className="row">
            <div className="col">
                <ProfileInformation />
            </div>
        </div>
    );
};

export default Profile;
