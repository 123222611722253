import React from "react";
import Profile from "./Profile";
import { ProfileProvider } from "./Profile.provider";

const EnhancedProfile = (props) => {
    return (
        <ProfileProvider>
            <Profile {...props} />
        </ProfileProvider>
    );
};

export default EnhancedProfile;
