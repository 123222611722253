import React from "react";
import { useLocation } from "react-router";
import swal from "sweetalert";
import TextareaSwalButton from "./TextareaSwalButton";
import I18n from "shared/lib/I18n";

const EnhancedTextareaSwalButton = ({
    swalOptions,
    value,
    placeholder,
    onConfirm,
    onCancel,
    required,
    ...props
}) => {
    const location = useLocation();

    const onClick = () => {
        var textarea = document.createElement("textarea");
        textarea.rows = 6;
        textarea.className = "swal-content__textarea";
        if (placeholder) {
            textarea.placeholder = placeholder;
        }
        if (value) {
            textarea.value = value;
        }

        const showModal = () => {
            swal({
                content: textarea,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
                ...swalOptions,
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    if (required && !textarea.value) {
                        textarea.className = "form-control is-invalid";
                        showModal();
                    } else {
                        onConfirm?.(textarea.value);
                    }
                } else {
                    onCancel?.();
                }
            });
        };

        showModal();
    };

    return <TextareaSwalButton onClick={onClick} {...props} />;
};

export default EnhancedTextareaSwalButton;
