import React from "react";
import AlbumSelect from "shared/components/AlbumSelect";

const AlbumMatcher = ({
    artist,
    album,
    locale,
    defaultSearch,
    onMatch,
    onUnmatch,
}) => {
    return (
        <AlbumSelect
            id={album?.id}
            title={album?.title}
            id_artist={artist?.id}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={onUnmatch}
            menuPlacement="auto"
            enablePortal
            isDisabled={!artist && !album}
            defaultSearch={defaultSearch}
        />
    );
};

export default AlbumMatcher;
