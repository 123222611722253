import React from "react";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const Filter = ({
    selectedFilterOptions,
    setSelectedFilterOptions,
    filterOptions,
}) => {
    const location = useLocation();
    return (
        <div className="row mb-2">
            <div className="col-md-2">
                <InputText
                    value={selectedFilterOptions.artist}
                    onChange={(e) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            artist: e.target.value ? e.target.value : "",
                        });
                    }}
                    placeholder={I18n.getTranslation(
                        location,
                        "plannings.view.tasks.artist"
                    )}
                />
            </div>
            <div className="col-md-2">
                <InputSelect
                    onChange={(opt) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            status: opt ? opt.value : null,
                        });
                    }}
                    placeholder={<I18n t="plannings.view.tasks.status" />}
                    options={filterOptions.status}
                />
            </div>
            <div className="col-md-2">
                <InputSelect
                    onChange={(opt) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            assignement: opt ? opt.value : null,
                        });
                    }}
                    placeholder={<I18n t="plannings.view.tasks.assignement" />}
                    options={filterOptions.assignement}
                />
            </div>
            <div className="col-md-2">
                <InputSelect
                    onChange={(opt) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            category: opt ? opt.value : null,
                        });
                    }}
                    placeholder={<I18n t="plannings.view.tasks.category" />}
                    options={filterOptions.category}
                />
            </div>
            <div className="col-md-2">
                <InputSelect
                    onChange={(opt) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            classification: opt ? opt.value : null,
                        });
                    }}
                    placeholder={
                        <I18n t="plannings.view.tasks.classification" />
                    }
                    options={filterOptions.classification}
                />
            </div>
            <div className="col-md-2">
                <InputSelect
                    onChange={(opt) => {
                        setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            tasks: opt ? opt.value : null,
                        });
                    }}
                    placeholder={<I18n t="plannings.view.tasks.tasks" />}
                    options={filterOptions.tasks}
                />
            </div>
        </div>
    );
};

export default Filter;
