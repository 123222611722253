import React from "react";
import I18n from "shared/lib/I18n";
import { Button } from "react-bootstrap";
import "./Authorization.css";

const Authorization = ({ hasAuthorization, login, logout }) => {
    return (
        <div className="apple__player__authorization">
            {hasAuthorization ? (
                <Button variant="outline-secondary" onClick={logout}>
                    <I18n t="apple.sample.assessment.player.logout" />
                </Button>
            ) : (
                <Button variant="outline-secondary" onClick={login}>
                    <I18n t="apple.sample.assessment.player.login" />
                </Button>
            )}
        </div>
    );
};

export default Authorization;
