import React from "react";
import Charts from "./Charts";
import Trend from "./Trend";

const RecordingCharts = ({
    entries,
    isLoading,
    filterOptions,
    charts,
    filter,
    setFilter,
    selectedCharts,
    setSelectedCharts,
}) => {
    return (
        <div className="row">
            <div className="col-2">
                <Charts
                    isLoading={isLoading}
                    charts={charts}
                    selectedCharts={selectedCharts}
                    setSelectedCharts={setSelectedCharts}
                    filterOptions={filterOptions}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            <div className="col-10">
                <div className="row">
                    <div className="col">
                        <Trend
                            selectedCharts={selectedCharts}
                            entries={entries}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecordingCharts;
