import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import InputAsyncCreatableSelect from "shared/components/Form/InputAsyncCreatableSelect";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";

const CreateForm = ({
    loadOptions,
    errors,
    control,
    isSubmitting,
    isDirty,
    hasSearch,
    handleSubmit,
    onSubmit,
    onSubmitCreate,
    location,
    redirectToArtist,
}) => {
    return (
        <Form
            onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
            className="create-form"
        >
            <Widget>
                <Widget.Body className="mt-0">
                    <InputGroup>
                        <Controller
                            control={control}
                            name="search"
                            render={({ field: { onChange, value, ref } }) => {
                                return (
                                    <InputAsyncCreatableSelect
                                        className="flex-grow-1"
                                        innerRef={ref}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "artist.create.placeholder"
                                        )}
                                        value={
                                            value
                                                ? {
                                                      value: value,
                                                      label: value,
                                                  }
                                                : undefined
                                        }
                                        loadOptions={loadOptions}
                                        onChange={(opt, action) => {
                                            if (
                                                action.action ===
                                                "select-option"
                                            ) {
                                                redirectToArtist(opt.value.id);
                                            } else if (
                                                action.action ===
                                                "create-option"
                                            ) {
                                                onChange(opt.label);
                                            } else {
                                                onChange();
                                            }
                                        }}
                                        allowCreateWhileLoading={false}
                                        createOptionPosition="first"
                                        isClearable={false}
                                        error={
                                            errors.search && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.search.message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                );
                            }}
                        />
                        <InputGroup.Append>
                            <Button
                                variant="primary"
                                disabled={isSubmitting || !isDirty}
                                onClick={
                                    !isSubmitting && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                type="submit"
                            >
                                <I18n t="utils.button.search" />
                            </Button>
                            <Button
                                variant="success"
                                title={I18n.getTranslation(
                                    location,
                                    "artist.create.create.title"
                                )}
                                disabled={
                                    isSubmitting || !isDirty || !hasSearch
                                }
                                onClick={
                                    !isSubmitting && isDirty && hasSearch
                                        ? handleSubmit(onSubmitCreate)
                                        : null
                                }
                                type="submit"
                            >
                                <I18n t="utils.button.continue" />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default CreateForm;
