import React, { useEffect } from "react";
import LabelUploadModal from "./LabelUploadModal";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { UPLOAD_MAX_SIZE } from "shared/constants/file";

const schema = yup.object().shape({
    file: yup
        .mixed()
        .test("fileSize", "utils.form.upload_max_size", (value) => {
            return value.size <= UPLOAD_MAX_SIZE;
        })
        .required("utils.form.required"),
    title: yup
        .string()
        .nullable()
        .transform((value) => value || null),
    copyright: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .required("utils.form.required"),
    date_takedown: yup
        .string()
        .nullable()
        .transform((value) => value || null),
    label: yup.object().nullable(),
});

const EnhancedLabelUploadModal = ({ artistId, show, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            file: null,
            title: null,
            copyright: null,
            date_takedown: null,
            label: null,
        },
    });

    useEffect(() => {
        if (!show) {
            reset({
                file: null,
                title: null,
                copyright: null,
                date_takedown: null,
                label: null,
            });
        }
    }, [show]);

    const onSubmit = (data) => {
        return api
            .postFile(
                `picture/label`,
                { locale, id_artist: artistId },
                Object.entries({
                    ...data,
                    label: data.label?.id || null,
                }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {})
            )
            .then(({ id_picture, id_picture_original }) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.labelUploadModal.success"
                    )
                );
                onSuccess?.(id_picture, id_picture_original);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <LabelUploadModal
            {...props}
            show={show}
            locale={locale}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
        />
    );
};

export default EnhancedLabelUploadModal;
