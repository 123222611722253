import { HTTP_UNAUTHORIZED } from "shared/constants/http";
import { useNavigate, useLocation, useParams } from "react-router";
import useUser from "./useUser";

/*
 * Error raised when an API request fails.
 *
 * If the API responds with a JSON document, it is stored in the body
 * attribute.
 *
 * Based on this example :
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
 */
class BadResponseError extends Error {
    constructor(message, status, body, ...params) {
        super(message, ...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BadResponseError);
        }
        this.name = "BadResponseError";
        this.status = status;
        this.body = body;
    }
}

class UnauthorizedError extends BadResponseError {
    constructor(...params) {
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnauthorizedError);
        }
        this.name = "UnauthorizedError";
    }
}

/*
 * checkStatus checks that the status code of the response to a fetch call is
 * valid (between 200 and 299).
 *
 * In case of error, checkStatus tries to get the error message from the
 * "message" field of the response, and throws:
 *  - an UnauthorizedError if the HTTP status code of the response is 401
 *  - a BadResponseError otherwise
 */
const checkStatus = (response) => {
    if (response.ok) {
        return response;
    }

    return response
        .json()
        .then(
            (result) => result,
            (error) => null
        )
        .then((result) => {
            if (response.status === HTTP_UNAUTHORIZED) {
                throw new UnauthorizedError(
                    result?.message ||
                        `${response.status} ${response.statusText}`,
                    response.status,
                    result
                );
            }
            throw new BadResponseError(
                result?.message || `${response.status} ${response.statusText}`,
                response.status,
                result
            );
        });
};

const checkJSON = (response) =>
    response.json().catch((error) => {
        throw new BadResponseError(error.message, response.status, null);
    });

const useAPI = () => {
    const state = useUser();
    const navigate = useNavigate();
    const { locale } = useParams();
    const { pathname, search, hash } = useLocation();

    const request = (params) => {
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/resetting/request`
        );

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: { "Content-type": "application/json" },
        });
    };

    const reset = (token, params) => {
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/resetting/reset/${token}`
        );

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: { "Content-type": "application/json" },
        }).then(checkStatus);
    };

    const register = (params) => {
        const url = new URL(`${process.env.REACT_APP_API_URL}/register`);

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: { "Content-type": "application/json" },
        }).then(checkStatus);
    };

    const validate = (token) => {
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/users/validate/${token}`
        );

        return fetch(url, {
            method: "PUT",
        }).then(checkStatus);
    };

    const searchLabel = (search) => {
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/label-promo/search`
        );
        url.search = new URLSearchParams({ term: search }).toString();

        return fetch(url).then(checkStatus);
    };

    const authenticate = (params) => {
        const url = new URL(`${process.env.REACT_APP_API_URL}/login`);

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: { "Content-type": "application/json" },
        })
            .then(checkStatus)
            .then(checkJSON)
            .then(
                (result) => {
                    state.setUser(result);
                },
                (error) => {
                    state.invalidate();
                    throw error;
                }
            );
    };

    const refreshToken = () => {
        const url = new URL(`${process.env.REACT_APP_API_URL}/token/refresh`);

        return fetch(url, {
            method: "POST",
            body: JSON.stringify({ refresh_token: state.user.refresh_token }),
            headers: { "Content-type": "application/json" },
        })
            .then(checkStatus)
            .then(checkJSON)
            .then(
                (result) => {
                    state.setUser({ ...state.user, ...result });
                },
                (error) => {
                    state.invalidate();
                    navigate(`/${locale}/login#${pathname}${search}${hash}`);
                    throw error;
                }
            );
    };

    const fetchWithToken = (url, options = {}) => {
        const optionsWithToken = {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${state.getToken()}`,
            },
        };

        return fetch(url, optionsWithToken)
            .then(checkStatus)
            .catch((error) => {
                if (error instanceof UnauthorizedError) {
                    return refreshToken().then(
                        () => fetchWithToken(url, options),
                        (error) => {
                            // Set error message to null to hide "Invalid JWT
                            // Refresh Token" errors when the refresh token is
                            // expired
                            if (error instanceof UnauthorizedError) {
                                error.message = null;
                            }
                            throw error;
                        }
                    );
                } else {
                    throw error;
                }
            });
    };

    const fetchJSONWithToken = (url, options = {}) => {
        return fetchWithToken(url, options).then(checkJSON);
    };

    const get = (route, params = {}) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        return fetchJSONWithToken(url);
    };

    const del = (route, params = {}) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        return fetchJSONWithToken(url, {
            method: "DELETE",
        });
    };

    const post = (route, params = {}, body = undefined) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        return fetchJSONWithToken(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-type": "application/json" },
        });
    };

    const postFile = (route, params = {}, body = undefined) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        let data = new FormData();
        Object.keys(body).forEach((v) => data.append(v, body[v]));

        return fetchJSONWithToken(url, {
            method: "POST",
            body: data,
        });
    };

    const put = (route, params = {}, body = undefined) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        return fetchJSONWithToken(url, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: { "Content-type": "application/json" },
        });
    };

    const getRaw = (route, params = {}) => {
        let url = new URL(`${process.env.REACT_APP_API_URL}/${route}`);
        url.search = new URLSearchParams(params).toString();

        return fetchWithToken(url);
    };

    return {
        api: {
            request,
            reset,
            register,
            validate,
            searchLabel,
            authenticate,
            get,
            delete: del,
            post,
            postFile,
            put,
            getRaw,
        },
    };
};

export default useAPI;
