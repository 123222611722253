import { toast } from "react-toastify";
import { translations } from "shared/lib/I18n";

export const addToClipboard = (text, locale) => {
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";

    document.body.appendChild(el);

    const selected =
        document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
    el.select();
    try {
        const result = document.execCommand("copy");
        if (result) {
            toast.success(translations[locale].utils.copy.success);
        }
    } catch (err) {
        toast.error(translations[locale].utils.copy.error);
    }
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};
