import React, { useState, useEffect, useCallback } from "react";
import CoPerformer from "./CoPerformer";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { orderByReleaseDate } from "./CoPerformer.pure";

const EnhancedCoPerformer = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        coPerformer: [],
        isLoading: true,
    });

    const getCoPerformer = useCallback(() => {
        return api
            .get(`artist/${artistId}/co-performer`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    coPerformer: orderByReleaseDate(response),
                }));
                if (response.length === 0) {
                    setNoData("coPerformer");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getCoPerformer()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getCoPerformer]);

    return <CoPerformer {...props} {...state} locale={locale} />;
};

export default EnhancedCoPerformer;
