import React, { useState, useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import { useParams, useLocation } from "react-router";
import Cover from "./Cover";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import {
    DiscographyContext,
    ALBUM_UPD,
    ALBUM_COVER_UP_TO_DATE,
} from "pages/ArtistManagement/Discography";

const defaultState = {
    url: "",
    partnerId: null,
    partnerName: "",
};

const EnhancedCover = ({ covers, setCovers, ...props }) => {
    const { api } = useAPI();
    const { dispatchDiscography, albumCoverUpToDate } =
        useContext(DiscographyContext);
    const [selected, setSelected] = useState(defaultState);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { albumId, releaseId, locale } = useParams();
    const location = useLocation();

    const getSortedPartners = (partners) => {
        return new Promise((resolve) => {
            if (partners && partners.length > 0) {
                let imagesProcessed = 0;
                let partnersWithSizes = [];

                const checkDimensions = function () {
                    const width = this.naturalWidth;
                    const height = this.naturalHeight;
                    const size = width * height;

                    partnersWithSizes.push({
                        ...this.partner,
                        size: size,
                    });

                    imagesProcessed++;
                    if (imagesProcessed === partners.length) {
                        partnersWithSizes.sort((a, b) => b.size - a.size);
                        resolve(partnersWithSizes);
                    }
                };

                const handleError = function () {
                    imagesProcessed++;
                    if (imagesProcessed === partners.length) {
                        partnersWithSizes.sort((a, b) => b.size - a.size);
                        resolve(partnersWithSizes);
                    }
                };

                partners.forEach((partner) => {
                    const img = new Image();
                    img.partner = partner;
                    img.addEventListener("load", checkDimensions);
                    img.addEventListener("error", handleError);
                    img.src = partner.url;
                });
            } else {
                resolve([]);
            }
        });
    };

    const getCovers = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}/covers`)
                .then((response) => {
                    getSortedPartners(response.partners).then(
                        (sortedPartners) => {
                            setCovers(() => ({
                                musicstory: response.musicstory,
                                partners: sortedPartners,
                            }));
                            dispatchDiscography({
                                type: ALBUM_COVER_UP_TO_DATE,
                                data: true,
                            });
                            setIsLoading(false);
                        }
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        }
    }, [albumId, releaseId]);

    useEffect(getCovers, [getCovers]);

    useEffect(() => {
        if (!albumCoverUpToDate) {
            getCovers();
        }
    }, [albumCoverUpToDate]);

    const onSelect = (url, partnerId, partnerName) => {
        setSelected({
            url: url === selected.url ? "" : url,
            partnerId: partnerId === selected.partnerId ? null : partnerId,
            partnerName:
                partnerName === selected.partnerName ? "" : partnerName,
        });
    };

    const onSubmit = (selected) => {
        setIsSubmitting(true);
        api.post(
            `album/${albumId}/cover`,
            { locale },
            { url: selected.url, partnerId: selected.partnerId }
        )
            .then(({ url }) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.update.success`
                    )
                );
                setSelected(defaultState);
                setIsSubmitting(false);
                onSuccess(url, selected.partnerName);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.update.error`,
                        error.message
                    )
                );
                setIsSubmitting(false);
            });
    };

    const onSuccess = (url, partnerName) => {
        setCovers((prevCovers) => ({
            ...prevCovers,
            musicstory: {
                url: `${url}?${+new Date()}`,
                origin: partnerName,
            },
        }));
        dispatchDiscography({
            type: ALBUM_UPD,
            data: {
                id: parseInt(albumId, 10),
                cover: `${url}?${+new Date()}`,
            },
        });
    };

    return (
        <Cover
            covers={covers}
            selected={selected}
            onSelect={onSelect}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            {...props}
        />
    );
};

export default EnhancedCover;
