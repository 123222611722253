import React from "react";
import MultiRecordingSelect from "shared/components/MultiRecordingSelect";

const TrackMatcher = ({ msArtist, msRecordings, locale, onMatch }) => {
    return (
        <MultiRecordingSelect
            artistIds={msArtist !== null ? [msArtist.id] : []}
            isDisabled={msArtist === null}
            selected={msRecordings}
            locale={locale}
            onChange={onMatch}
            menuPlacement="auto"
            isClearable={false}
        />
    );
};

export default TrackMatcher;
