import React from "react";

export const DiscographyContext = React.createContext({
    dispatchDiscography: () => {},
    albums: [],
    releases: [],
    tracksLoading: false,
    tracks: [],
    sandbox: {
        artistAlbums: [],
        searchResults: [],
    },
    albumHasReview: false,
    releaseMatchingUpToDate: true,
    albumCoverUpToDate: true,
    trackBasicUpToDate: true,
});
