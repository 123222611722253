import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { ProgressBar, Badge, Button } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import simbalsIcon from "assets/static/images/simbals.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import "./AudioDescription.css";

const AudioDescription = ({ data, isLoading, more, setMore, location }) => {
    return (
        <>
            <Widget
                title="recording.view.audioAudioDescription.head"
                isLoading={isLoading}
                className="recording-view-audio-audio-description"
                actions={
                    <div className="d-flex align-items-center justify-content-end my-2">
                        <div className="mr-4 text-right">
                            <p className="mb-0">
                                <strong>
                                    <I18n t="recording.view.audioAudioDescription.licence" />
                                </strong>
                            </p>
                        </div>
                        <a
                            href="https://www.simbals.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={simbalsIcon} width="40px" alt="Simbals" />
                        </a>
                    </div>
                }
            >
                <Widget.Body>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="recording.view.audioAudioDescription.moods.title" />
                            </strong>
                            <span className="ml-1">
                                (
                                <I18n t="recording.view.audioAudioDescription.moods.subtitle" />
                                )
                            </span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "recording.view.audioAudioDescription.moods.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.moods?.map(({ name, value }) => (
                                    <h4 key={name} className="mr-1">
                                        <Badge variant="secondary">
                                            {name} - {value} %
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="recording.view.audioAudioDescription.themes.title" />
                            </strong>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "recording.view.audioAudioDescription.themes.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.themes?.map((theme) => (
                                    <h4 key={theme} className="mr-1">
                                        <Badge variant="secondary">
                                            {theme}
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="recording.view.audioAudioDescription.timbres.title" />
                            </strong>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "recording.view.audioAudioDescription.timbres.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.timbres?.map((timbre) => (
                                    <h4 key={timbre} className="mr-1">
                                        <Badge variant="secondary">
                                            {timbre}
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row mb-2">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.arousal.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.arousal.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.arousal} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.arousal}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.valence.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.valence.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.valence} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.valence}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.vocal_instrumental.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.vocal_instrumental.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.vocal_instrumental} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.vocal_instrumental}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.music_speech.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.music_speech.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.music_speech} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.music_speech}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.electric_acoustic.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.electric_acoustic.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.electric_acoustic} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.electric_acoustic}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.danceability.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.danceability.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.danceability} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.danceability}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.studio_live.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.studio_live.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.studio_live} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.studio_live}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.melodicity.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.melodicity.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.melodicity} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.melodicity}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="recording.view.audioAudioDescription.dissonance.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.audioAudioDescription.dissonance.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.dissonance} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.dissonance}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <hr className="mb-2" />
                            {more && (
                                <>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <strong className="border-bottom pb-2">
                                                <I18n t="recording.view.audioAudioDescription.extra.rythm" />
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.articulation.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.articulation.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.articulation}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.rhythmic_stability.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.rhythmic_stability.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.rhythmic_stability}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.event_density.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.event_density.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.event_density}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.pulse_clarity.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.pulse_clarity.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.pulse_clarity}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.bpm.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.bpm.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.bpm}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.complexity.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.complexity.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.complexity}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.binary.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.binary.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.binary}</p>
                                        </div>
                                    </div>
                                    <hr className="mb-2" />
                                    <div className="row mb-3">
                                        <div className="col">
                                            <strong className="border-bottom pb-2">
                                                <I18n t="recording.view.audioAudioDescription.extra.timbre" />
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.roll_off.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.roll_off.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.roll_off}</p>
                                        </div>
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.brightness.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.brightness.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.brightness}</p>
                                        </div>
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.zero_cross_rate.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.zero_cross_rate.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.zero_cross_rate}</p>
                                        </div>
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.centroid.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.centroid.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.centroid}</p>
                                        </div>
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.spread.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.spread.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.spread}</p>
                                        </div>
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.flatness.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.flatness.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.flatness}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.mfcc.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.mfcc.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc01.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc02.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc03.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc04.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc05.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc06.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc07.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc08.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc09.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc10.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc11.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc12.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.mfcc13.title" />
                                                            </strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{data?.mfcc01}</td>
                                                        <td>{data?.mfcc02}</td>
                                                        <td>{data?.mfcc03}</td>
                                                        <td>{data?.mfcc04}</td>
                                                        <td>{data?.mfcc05}</td>
                                                        <td>{data?.mfcc06}</td>
                                                        <td>{data?.mfcc07}</td>
                                                        <td>{data?.mfcc08}</td>
                                                        <td>{data?.mfcc09}</td>
                                                        <td>{data?.mfcc10}</td>
                                                        <td>{data?.mfcc11}</td>
                                                        <td>{data?.mfcc12}</td>
                                                        <td>{data?.mfcc13}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <hr className="mb-2" />
                                    <div className="row mb-3">
                                        <div className="col">
                                            <strong className="border-bottom pb-2">
                                                <I18n t="recording.view.audioAudioDescription.extra.harmony" />
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.complexity_chroma.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.complexity_chroma.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.complexity_chroma}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.chroma.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.chroma.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma01.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma02.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma03.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma04.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma05.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma06.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma07.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma08.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma09.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma10.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma11.title" />
                                                            </strong>
                                                        </th>
                                                        <th>
                                                            <strong>
                                                                <I18n t="recording.view.audioAudioDescription.chroma12.title" />
                                                            </strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {data?.chroma01}
                                                        </td>
                                                        <td>
                                                            {data?.chroma02}
                                                        </td>
                                                        <td>
                                                            {data?.chroma03}
                                                        </td>
                                                        <td>
                                                            {data?.chroma04}
                                                        </td>
                                                        <td>
                                                            {data?.chroma05}
                                                        </td>
                                                        <td>
                                                            {data?.chroma06}
                                                        </td>
                                                        <td>
                                                            {data?.chroma07}
                                                        </td>
                                                        <td>
                                                            {data?.chroma08}
                                                        </td>
                                                        <td>
                                                            {data?.chroma09}
                                                        </td>
                                                        <td>
                                                            {data?.chroma10}
                                                        </td>
                                                        <td>
                                                            {data?.chroma11}
                                                        </td>
                                                        <td>
                                                            {data?.chroma12}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <hr className="mb-2" />
                                    <div className="row mb-3">
                                        <div className="col">
                                            <strong className="border-bottom pb-2">
                                                <I18n t="recording.view.audioAudioDescription.extra.energy" />
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.intensity.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.intensity.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.intensity}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.loudness.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.loudness.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.loudness}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.absolute_loudness.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.absolute_loudness.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.absolute_loudness}</p>
                                        </div>
                                        <div className="col-2">
                                            <strong>
                                                <I18n t="recording.view.audioAudioDescription.loudness_range.title" />
                                            </strong>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "recording.view.audioAudioDescription.loudness_range.description"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                            <p>{data?.loudness_range}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="text-center mt-2">
                                <Button
                                    variant="transparent"
                                    onClick={() => setMore((prev) => !prev)}
                                    className="w-100"
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            more ? faChevronUp : faChevronDown
                                        }
                                        size="2x"
                                        fixedWidth
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Widget.Body>
            </Widget>
        </>
    );
};

export default AudioDescription;
