import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import PlatformSelect from "./PlatformSelect";

const EnhancedPlatformSelect = (props) => {
    const { api } = useAPI();
    const { locale, platformId, chartType } = useParams();
    const { year } = useIntParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [{ isLoading, platforms }, setState] = useState({
        isLoading: true,
        platforms: [],
    });

    const getPlatforms = useCallback(() => {
        if (!year || !chartType) {
            setState({ isLoading: false, platforms: [] });
            return;
        }

        let cancelled = false;
        api.get(`cnm/platform/${year}/${chartType}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        platforms: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        platforms: [],
                        isLoading: false,
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year, chartType]);

    useEffect(getPlatforms, [getPlatforms]);

    const options = useMemo(
        () =>
            !year || !chartType
                ? []
                : [
                      {
                          value: "aggregate",
                          label: I18n.getTranslation(
                              location,
                              "cnm.report.consolidate.chart.platformSelect.aggregate"
                          ),
                      },
                      {
                          value: "global",
                          label: I18n.getTranslation(
                              location,
                              "cnm.report.consolidate.chart.platformSelect.global"
                          ),
                      },
                      ...platforms.map(({ id, name }) => ({
                          value: id.toString(),
                          label: name,
                      })),
                  ],
        [year, chartType, platforms, location]
    );

    const selectedOption = useMemo(
        () =>
            options !== undefined && platformId !== undefined
                ? options.filter((option) => option.value === platformId)[0]
                : undefined,
        [options, platformId]
    );

    const onChange = (opt) => {
        navigate(
            `/${locale}/cnm/consolidation/${year}/${chartType}/${opt.value}`
        );
    };

    useEffect(() => {
        if (options.length > 0 && selectedOption === undefined) {
            onChange(options[0]);
        }
    }, [selectedOption, options]);

    return (
        <PlatformSelect
            isLoading={isLoading}
            options={options}
            selectedOption={selectedOption}
            onChange={onChange}
            disabled={!year || !chartType}
            {...props}
        />
    );
};

export default EnhancedPlatformSelect;
