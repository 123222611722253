import {
    faFacebookF,
    faXTwitter,
    faSoundcloud,
    faInstagram,
    faYoutube,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons";

export const partners = {
    FACEBOOK: "facebook",
    TWITTER: "twitter",
    YOUTUBE: "youtube",
    SOUNDCLOUD: "soundcloud",
    INSTAGRAM: "instagram",
    TIKTOK: "tiktok",
};

export const icons = {
    [partners.FACEBOOK]: faFacebookF,
    [partners.TWITTER]: faXTwitter,
    [partners.YOUTUBE]: faYoutube,
    [partners.SOUNDCLOUD]: faSoundcloud,
    [partners.INSTAGRAM]: faInstagram,
    [partners.TIKTOK]: faTiktok,
};
