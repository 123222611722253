import React from "react";
import I18n from "shared/lib/I18n";
import { NavLink } from "shared/lib/I18n";
import Action from "./Action";
import Tasks from "./Tasks";
import { ButtonGroup, Nav, Tab, Button } from "react-bootstrap";
import Create from "./Button/Create";
import Stats from "./Stats";
import Progress from "./Progress";
import Budget from "./Budget";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import { Helmet } from "react-helmet";
import "./PlanningsManagement.css";

const PlanningsManagement = ({
    planning,
    tasks,
    tasksIsLoading,
    handleTasksChange,
    showHistory,
    partners,
    managerOptions,
    setManager,
    partnerId,
    onSelect,
    onSelectTab,
    selectedTab,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.manage.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.view.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="plannings.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to="/plannings">
                            <I18n t="plannings.manage.breadcrumb" />
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="plannings.view.breadcrumb" />
                    </li>
                </ol>
                <div className="row mb-3">
                    {planning?.title && (
                        <div className="col-12 text-center">
                            <h2>{planning?.title}</h2>
                        </div>
                    )}

                    <div className="col-12 text-center">
                        <h2>
                            {planning?.start_date} - {planning?.end_date}
                        </h2>
                    </div>
                </div>
                <Nav
                    id="plannings-management-nav"
                    variant="pills"
                    activeKey={partnerId}
                    onSelect={onSelect}
                    className="mb-3"
                    justify
                >
                    <Nav.Item>
                        <Nav.Link
                            eventKey={1}
                            title={<I18n t="plannings.view.partner.all" />}
                        >
                            <I18n t="plannings.view.partner.all" />
                        </Nav.Link>
                    </Nav.Item>
                    {partners.map(({ id, name }) => {
                        return (
                            <Nav.Item key={id}>
                                <Nav.Link eventKey={id} title={name}>
                                    {name}
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
                {planning && partnerId ? (
                    <Tab.Container
                        defaultActiveKey="tasks"
                        activeKey={selectedTab}
                        onSelect={onSelectTab}
                        transition={false}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Nav id="plannings-management-nav-tab" variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="tasks">
                                    <I18n t="plannings.view.tasks.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="stats">
                                    <I18n t="plannings.view.stats.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="progress">
                                    <I18n t="plannings.view.progress.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="budget">
                                    <I18n t="plannings.view.budget.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <ButtonGroup>
                                {partners.find(
                                    ({ id, can_add_task }) =>
                                        id === partnerId && can_add_task
                                ) && <Create onSuccess={handleTasksChange} />}
                                {partnerId === PLANNING_PARTNER.ALL && (
                                    <Action tasks={tasks} />
                                )}
                                <Button
                                    variant="inverse"
                                    className="border-bottom-0"
                                    onClick={showHistory}
                                >
                                    <I18n t="plannings.view.history.title" />
                                </Button>
                            </ButtonGroup>
                        </Nav>
                        <Tab.Content id="plannings-management-tab-content">
                            <Tab.Pane eventKey="tasks">
                                <Tasks
                                    isLoading={tasksIsLoading}
                                    tasks={tasks}
                                    handleTasksChange={handleTasksChange}
                                    managerOptions={managerOptions}
                                    setManager={setManager}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="stats">
                                <Stats
                                    planning={planning}
                                    managerOptions={managerOptions}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="progress">
                                <Progress planning={planning} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="budget">
                                <Budget planning={planning} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                ) : undefined}
            </main>
        </div>
    );
};

export default PlanningsManagement;
