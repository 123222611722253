import React from "react";
import Chart from "./Chart";
import Loader from "shared/components/Loader";

const Albums = ({
    isLoading,
    albums_creation,
    albums_with_cover_art,
    releases_creation,
    location,
}) => {
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div style={{ height: "500px" }}>
                    <Chart
                        albums_creation={albums_creation}
                        albums_with_cover_art={albums_with_cover_art}
                        releases_creation={releases_creation}
                        location={location}
                    />
                </div>
            )}
        </>
    );
};

export default Albums;
