import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
    CSVExport,
} from "@musicstory/react-bootstrap-table2-toolkit";
import { useLocation } from "react-router";
import Widget from "shared/components/Widget";

const Stats = ({ stats, columns, isLoading, exportName }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const location = useLocation();
    return (
        <ToolkitProvider
            keyField="id_top"
            data={stats}
            columns={columns}
            exportCSV={{
                fileName: `${exportName
                    .toLowerCase()
                    .replaceAll(" ", "_")}.csv`,
            }}
            search
        >
            {(toolkitprops) => (
                <Widget>
                    <Widget.Body className="mt-0 mb-3">
                        <div className="row">
                            <div className="col">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "data_external.internationals_charts.stats.search"
                                    )}
                                />
                            </div>
                            <div className="col-1 text-center">
                                <ExportCSVButton
                                    {...toolkitprops.csvProps}
                                    disabled={!stats.length}
                                >
                                    <I18n t="utils.button.export" />
                                </ExportCSVButton>
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body className="widget-table-overflow">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                striped
                                hover
                                noDataIndication={
                                    <I18n t="data_external.internationals_charts.stats.empty" />
                                }
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Stats;
