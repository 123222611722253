import React from "react";
import Widget from "shared/components/Widget";
import Delete from "pages/ArtistManagement/Discography/Actions/Album/Delete";
import CreateOrUpdate from "pages/ArtistManagement/Discography/Forms/Album/CreateOrUpdate";
import InputSwitch from "shared/components/Form/InputSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";

const Basic = ({ albumId, redirectToView }) => {
    if (albumId === null || typeof albumId === "undefined") {
        return null;
    }

    return (
        <Widget
            title="artist.management.disco.objects.album.basic.head"
            actions={
                <div className="d-flex">
                    <div className="align-self-center d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} fixedWidth />
                        <InputSwitch checked={true} onChange={redirectToView} />
                        <FontAwesomeIcon icon={faPen} fixedWidth />
                    </div>
                    <Delete />
                </div>
            }
            enableCollapse
        >
            <Widget.Body
                className="pt-3"
                style={{ borderTop: "1px solid #dee2e6" }}
            >
                <CreateOrUpdate albumId={albumId} />
            </Widget.Body>
        </Widget>
    );
};

export default Basic;
