import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "shared/components/Loader";

const Year = ({ data, columns, isLoading, selectRow }) => {
    return (
        <Widget title={`cnm.charts.deduplicated.year.head`}>
            <Widget.Body className="widget-table-overflow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <BootstrapTable
                        keyField="path"
                        data={data}
                        columns={columns}
                        bootstrap4
                        hover
                        noDataIndication={
                            <I18n t="cnm.charts.deduplicated.year.empty" />
                        }
                        selectRow={selectRow}
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Year;
