import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";

const Filters = ({ filters, setFilters }) => {
    const matchedOptions = [
        {
            value: true,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.matched.yes"
            ),
        },
        {
            value: false,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.matched.no"
            ),
        },
    ];

    const discardedOptions = [
        {
            value: true,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.discarded.yes"
            ),
        },
        {
            value: false,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.discarded.no"
            ),
        },
    ];

    const bioENOptions = [
        {
            value: true,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.bio_en.yes"
            ),
        },
        {
            value: false,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.bio_en.no"
            ),
        },
    ];

    const bioFROptions = [
        {
            value: true,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.bio_fr.yes"
            ),
        },
        {
            value: false,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.bio_fr.no"
            ),
        },
    ];

    const picturesOptions = [
        {
            value: 1,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.pictures.valid"
            ),
        },
        {
            value: 2,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.pictures.improve"
            ),
        },
        {
            value: 3,
            label: I18n.getTranslation(
                location,
                "partner_tops.artists.filters.pictures.missing"
            ),
        },
    ];

    return (
        <div className="row">
            <div className="col">
                <InputSelect
                    options={matchedOptions}
                    placeholder={I18n.getTranslation(
                        location,
                        "partner_tops.artists.filters.matched.title"
                    )}
                    value={matchedOptions.find(
                        (option) => option.value == filters.matched
                    )}
                    onChange={(option) =>
                        setFilters((filters) => ({
                            ...filters,
                            matched: option?.value ?? null,
                        }))
                    }
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 40,
                        }),
                    }}
                />
            </div>
            <div className="col">
                <InputSelect
                    options={discardedOptions}
                    placeholder={I18n.getTranslation(
                        location,
                        "partner_tops.artists.filters.discarded.title"
                    )}
                    value={discardedOptions.find(
                        (option) => option.value == filters.discarded
                    )}
                    onChange={(option) =>
                        setFilters((filters) => ({
                            ...filters,
                            discarded: option?.value ?? null,
                        }))
                    }
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 40,
                        }),
                    }}
                />
            </div>
            <div className="col">
                <InputSelect
                    options={bioENOptions}
                    placeholder={I18n.getTranslation(
                        location,
                        "partner_tops.artists.filters.bio_en.title"
                    )}
                    value={bioENOptions.find(
                        (option) => option.value == filters.bioEN
                    )}
                    onChange={(option) =>
                        setFilters((filters) => ({
                            ...filters,
                            bioEN: option?.value ?? null,
                        }))
                    }
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 40,
                        }),
                    }}
                />
            </div>
            <div className="col">
                <InputSelect
                    options={bioFROptions}
                    placeholder={I18n.getTranslation(
                        location,
                        "partner_tops.artists.filters.bio_fr.title"
                    )}
                    value={bioFROptions.find(
                        (option) => option.value == filters.bioFR
                    )}
                    onChange={(option) =>
                        setFilters((filters) => ({
                            ...filters,
                            bioFR: option?.value ?? null,
                        }))
                    }
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 40,
                        }),
                    }}
                />
            </div>
            <div className="col">
                <InputSelect
                    options={picturesOptions}
                    placeholder={I18n.getTranslation(
                        location,
                        "partner_tops.artists.filters.pictures.title"
                    )}
                    value={picturesOptions.find(
                        (option) => option.value == filters.pictures
                    )}
                    onChange={(option) =>
                        setFilters((filters) => ({
                            ...filters,
                            pictures: option?.value ?? null,
                        }))
                    }
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 40,
                        }),
                    }}
                />
            </div>
        </div>
    );
};

export default Filters;
