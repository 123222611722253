import React from "react";
import Delete from "./Delete";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";

const EnhancedDelete = ({ id, onSuccess, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "genre.manage.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "genre.manage.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.delete(`genre/${id}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "genre.manage.delete.success"
                        )
                    );
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "genre.manage.delete.error",
                            error.message
                        )
                    );
                });
        });
    };

    return <Delete {...props} onClick={onClick} location={location} />;
};

export default EnhancedDelete;
