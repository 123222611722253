import React from "react";
import I18n from "shared/lib/I18n";
import CreditsData from "./CreditsData";

const Credits = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="album.view.noData.credits" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col">
                <CreditsData setNoData={setNoData} />
            </div>
        </div>
    );
};

export default Credits;
