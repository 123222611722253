import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import SearchForm from "./SearchForm";

const EnhancedSearchForm = ({ onSubmit, defaultSearch, ...props }) => {
    const location = useLocation();
    const { handleSubmit, register, reset } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            search: "",
        },
    });

    useEffect(() => {
        reset({ search: defaultSearch });
        onSubmit(defaultSearch);
    }, [onSubmit, defaultSearch]);

    return (
        <SearchForm
            location={location}
            register={register}
            onSubmit={handleSubmit(
                async ({ search }) => await onSubmit(search)
            )}
            {...props}
        />
    );
};

export default EnhancedSearchForm;
