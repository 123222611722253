import React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import TextareaSwalButton from "shared/components/TextareaSwalButton";
import I18n from "shared/lib/I18n";

const DiscardCell = ({ onConfirm }) => {
    const location = useLocation();

    return (
        <div className="btn-group btn-group-sm">
            <TextareaSwalButton
                className="btn btn-primary mr-2"
                style={{ width: "30px" }}
                title={I18n.getTranslation(
                    location,
                    "partner_tops.artists.discard.button.title"
                )}
                swalOptions={{
                    title: I18n.getTranslation(
                        location,
                        "partner_tops.artists.discard.modal.title"
                    ),
                    text: I18n.getTranslation(
                        location,
                        "partner_tops.artists.discard.modal.text"
                    ),
                }}
                placeholder={I18n.getTranslation(
                    location,
                    "partner_tops.artists.discard.modal.placeholder"
                )}
                onConfirm={onConfirm}
            >
                <FontAwesomeIcon icon={faThumbsDown} size="lg" />
            </TextareaSwalButton>
        </div>
    );
};

export default DiscardCell;
