import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputTextArea from "shared/components/Form/InputTextArea";
import InputSelect from "shared/components/Form/InputSelect";
import ImageLoader from "shared/components/ImageLoader";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import License from "./License";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Stepper from "shared/components/Stepper";

const PictureInformationModal = ({
    show,
    handleHide,
    handleDelete,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    isLoading,
    picture,
    licenseOptions,
    sourceOptions,
    returnToCrop,
    returnToTagging,
}) => {
    const steps = [
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModal.upload"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModal.legal"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModal.cropping"
            ),
            onClick: returnToCrop,
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModal.tagging"
            ),
            onClick: returnToTagging,
        },
    ];
    const activeStep = 1;

    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            className="modal-add-image"
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        isCompleted={
                            picture?.url_version &&
                            Object.keys(picture?.tags).length > 0
                        }
                    />
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={isDirty ? handleDelete : handleHide}
                        fixedWidth
                    />
                </div>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body className="modal-add-image">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="container-fluid">
                            <div className="row" style={{ padding: "10px" }}>
                                <div className="col">
                                    {picture?.url && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <ImageLoader
                                                src={picture?.url}
                                                style={{
                                                    maxHeight: "450px",
                                                    maxWidth: "500px",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col">
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.source" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.source`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="source"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    innerRef={ref}
                                                    value={
                                                        value !== null
                                                            ? {
                                                                  value,
                                                                  label: sourceOptions.find(
                                                                      (a) =>
                                                                          value ===
                                                                          a.value
                                                                  ).label,
                                                              }
                                                            : null
                                                    }
                                                    className="text-left"
                                                    options={sourceOptions}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    isClearable={true}
                                                    error={
                                                        errors.source && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.source
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    showError={false}
                                                    enablePortal
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.source_url" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.source_url`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputText
                                            {...register("source_url")}
                                            error={
                                                errors.source_url && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.source_url
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.author" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.author`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputText
                                            {...register("author")}
                                            error={
                                                errors.author && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.author.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.year" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.year`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputText
                                            {...register("year")}
                                            error={
                                                errors.year && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.year.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.copyright_owner" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.copyright_owner`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputText
                                            {...register("copyright_owner")}
                                            error={
                                                errors.copyright_owner && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.copyright_owner
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.license" />
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="license"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <License />
                                                    </InputGroup.Prepend>
                                                    <InputSelect
                                                        className="flex-grow-1"
                                                        ref={ref}
                                                        value={
                                                            value !== null
                                                                ? {
                                                                      value,
                                                                      label: licenseOptions.find(
                                                                          ({
                                                                              id,
                                                                          }) =>
                                                                              String(
                                                                                  id
                                                                              ) ===
                                                                              String(
                                                                                  value
                                                                              )
                                                                      )?.name,
                                                                  }
                                                                : null
                                                        }
                                                        options={licenseOptions.map(
                                                            ({ id, name }) => {
                                                                return {
                                                                    value: id,
                                                                    label: name,
                                                                };
                                                            }
                                                        )}
                                                        onChange={(opt) => {
                                                            onChange(
                                                                opt
                                                                    ? opt.value
                                                                    : null
                                                            );
                                                        }}
                                                        error={
                                                            errors.license && [
                                                                I18n.getTranslation(
                                                                    location,
                                                                    errors
                                                                        .license
                                                                        .message
                                                                ),
                                                            ]
                                                        }
                                                        isClearable={false}
                                                    />
                                                </InputGroup>
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.copyright" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.copyright`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputText
                                            {...register("copyright")}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="common.pictureInformationModal.complementary" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModal.description.complementary`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </Form.Label>
                                        <InputTextArea
                                            {...register("complementary")}
                                            error={
                                                errors.complementary && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.complementary
                                                            .message
                                                    ),
                                                ]
                                            }
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting || isLoading}
                        onClick={!isSubmitting && !isLoading ? onSubmit : null}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t={`utils.button.next`} />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default PictureInformationModal;
