import React from "react";
import { NavLink } from "shared/lib/I18n";
import { Tab, Nav } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import Profile from "./Profile";
import Curation from "./Curation";
import Getty from "./Getty";
import { Helmet } from "react-helmet";
import "./MiscEntityManagement.css";

const MiscEntityManagement = ({ selectedTab, onSelectTab, location }) => {
    return (
        <div className="content-wrap miscentity-management">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "misc_entity.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "misc_entity.management.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb mb-2">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to={`/misc-entity`}>
                            <I18n t="misc_entity.breadcrumb" />
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="misc_entity.management.breadcrumb" />
                    </li>
                </ol>
                <Tab.Container
                    id="miscentity-management-tab"
                    activeKey={selectedTab}
                    defaultActiveKey="profile"
                    onSelect={onSelectTab}
                    mountOnEnter
                >
                    <div className="row border-bottom border-light">
                        <div className="col">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">
                                        <I18n t="misc_entity.management.profile" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="curation">
                                        <I18n t="misc_entity.management.curation" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="getty">
                                        <I18n t="misc_entity.management.getty" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className="nav-content overflow-hidden">
                        <Tab.Pane eventKey="profile" className="nav-pane">
                            <Profile />
                        </Tab.Pane>
                        <Tab.Pane eventKey="curation" className="nav-pane">
                            <Curation />
                        </Tab.Pane>
                        <Tab.Pane eventKey="getty" className="nav-pane">
                            <Getty />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default MiscEntityManagement;
