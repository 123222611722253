import React, { useState, useRef, useEffect, useCallback } from "react";
import CurrentCatalog from "./CurrentCatalog";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedCurrentCatalog = ({ id_country, top, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        title_repartition: [],
    });

    const popoverPointerRef = useRef(null);
    const containerRef = useRef(null);
    const [popover, setPopover] = useState({
        show: false,
        left: 0,
        top: 0,
        title_repartition: null,
    });

    const getCurrentCatalog = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_country) {
            api.get(`statistics/industry/${id_country}/current-catalog`)
                .then((response) => {
                    setState({
                        isLoading: false,
                        title_repartition: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        title_repartition: [],
                    });
                });
        }
    }, [id_country]);

    useEffect(getCurrentCatalog, [getCurrentCatalog]);

    return (
        <CurrentCatalog
            {...props}
            {...state}
            popoverPointerRef={popoverPointerRef}
            containerRef={containerRef}
            popover={popover}
            setPopover={setPopover}
            id_country={id_country}
        />
    );
};

export default EnhancedCurrentCatalog;
