import React from "react";
import I18n from "shared/lib/I18n";
import GenreLink from "shared/components/GenreLink";
import Loader from "shared/components/Loader";

const Genres = ({ genre, isLoading, locale, user }) => {
    return (
        <>
            {isLoading && (
                <div className="loader" style={{ zIndex: "9999999" }}>
                    <Loader size="2x" />
                </div>
            )}
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`album.view.universGenres.head`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div className="row" style={{ minHeight: "3rem" }}>
                <div className="col" style={{ fontSize: "1.3rem" }}>
                    {genre?.principal?.id &&
                        (user.hasRight("genre.show") ? (
                            <GenreLink
                                id={genre.principal.id}
                                name={genre.principal.name}
                                locale={locale}
                            />
                        ) : (
                            genre.principal.name
                        ))}
                    {genre?.secondary?.length > 0 &&
                        (user.hasRight("genre.show") ? (
                            genre.secondary.map(({ id, name }) => (
                                <span key={id}>
                                    {", "}
                                    <GenreLink
                                        id={id}
                                        name={name}
                                        locale={locale}
                                    />
                                </span>
                            ))
                        ) : (
                            <span>
                                {genre.secondary.map(({ name }) => (
                                    <span>
                                        {", "}
                                        {name}
                                    </span>
                                ))}
                            </span>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Genres;
