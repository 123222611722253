import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import Widget from "shared/components/Widget";

const Filter = ({ filterOptions, filter, setFilter }) => {
    return (
        <Widget.Body>
            <div className="row mb-2">
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                year: opt,
                            }));
                        }}
                        value={filter.year}
                        placeholder={<I18n t="common.charts.chartsList.year" />}
                        options={filterOptions.year}
                    />
                </div>
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                month: opt,
                            }));
                        }}
                        value={filter.month}
                        placeholder={
                            <I18n t="common.charts.chartsList.month" />
                        }
                        options={filterOptions.month}
                    />
                </div>
            </div>
        </Widget.Body>
    );
};

export default Filter;
