import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";

const CategoryFilter = ({ filterOptions, value, setFilter }) => {
    return (
        <InputSelect
            onChange={(opt) => {
                setFilter((prev) => ({
                    ...prev,
                    category: opt,
                }));
            }}
            value={value}
            placeholder={<I18n t="plannings.client.tasks.category" />}
            options={filterOptions}
        />
    );
};

export default CategoryFilter;
