import React from "react";
import { useParams } from "react-router";
import Information from "./Information";

const EnhancedInformation = (props) => {
    const { locale } = useParams();
    return <Information {...props} locale={locale} />;
};

export default EnhancedInformation;
