import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import FnacAlbumMatcher from "./FnacAlbumMatcher";

const EnhancedFnacAlbumMatcher = ({
    listId,
    rowId,
    fnacId,
    search,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const params = new URLSearchParams({
        Search: search,
    });
    const searchLink = `https://www.fnac.com/SearchResult/ResultList.aspx?${params}`;

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            fnacId: "",
        },
    });

    useEffect(() => {
        reset({
            fnacId: fnacId ?? "",
        });
    }, [fnacId]);

    const onMatch = (fnacId) => {
        return api
            .post(`matching/album/snep/list/${listId}/${rowId}/fnac/${fnacId}`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.match.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.match.error",
                        error.message
                    )
                );
            });
    };

    const onUnmatch = () => {
        return api
            .delete(`matching/album/snep/list/${listId}/${rowId}/fnac`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.unmatch.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.unmatch.error",
                        error.message
                    )
                );
            });
    };

    const onSubmit = ({ fnacId }) => {
        if (fnacId) {
            return onMatch(fnacId);
        } else {
            return onUnmatch();
        }
    };

    return (
        <FnacAlbumMatcher
            fnacId={fnacId}
            searchLink={searchLink}
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            {...props}
        />
    );
};

export default EnhancedFnacAlbumMatcher;
