import React from "react";
import useUser from "shared/hooks/useUser";
import ArtistListAction from "./ArtistListAction";

const EnhancedArtistListAction = (props) => {
    const { user } = useUser();

    return <ArtistListAction {...props} user={user} />;
};

export default EnhancedArtistListAction;
