import React from "react";
import Task from "./Task";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { usePlanningsManagementContext } from "pages/PlanningsManagement/PlanningsManagement.context";

const EnhancedTask = ({ task, ...props }) => {
    const location = useLocation();
    const { lock } = usePlanningsManagementContext();

    const informations = [
        task.nb_related != null && {
            badges: [
                {
                    label: task.nb_related.toString(10).padStart(2, "0"),
                    color: task.nb_related >= 10 ? "success" : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.nbRelated"
                    ),
                },
            ],
        },
        task.albums_total != null && {
            badges: [
                {
                    label:
                        task.albums_total !== 0
                            ? `${task.albums_without_genre} / ${
                                  task.albums_total
                              } (${Math.round(
                                  (100 * task.albums_without_genre) /
                                      task.albums_total
                              )} %)`
                            : "0 / 0 (0 %)",
                    color:
                        task.albums_without_genre === 0
                            ? "success"
                            : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.albumsWithoutGenre"
                    ),
                },
            ],
        },
        (task.has_bio_ja != null ||
            task.has_bio_fr != null ||
            task.has_bio_en != null ||
            task.has_bio_de != null ||
            task.has_bio_es != null ||
            task.has_bio_pt != null) && {
            badges: [
                {
                    label: "FR",
                    color: task.has_bio_fr
                        ? task.is_bio_fr_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioFR"
                    ),
                },
                {
                    label: "EN",
                    color: task.has_bio_en
                        ? task.is_bio_en_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioEN"
                    ),
                },
                {
                    label: "DE",
                    color: task.has_bio_de
                        ? task.is_bio_de_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioDE"
                    ),
                },
                {
                    label: "JA",
                    color: task.has_bio_ja
                        ? task.is_bio_ja_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioJA"
                    ),
                },
                {
                    label: "ES",
                    color: task.has_bio_es
                        ? task.is_bio_es_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioES"
                    ),
                },
                {
                    label: "PT",
                    color: task.has_bio_pt
                        ? task.is_bio_pt_reference
                            ? "success-auto"
                            : "success"
                        : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasBioPT"
                    ),
                },
            ],
        },
        task.has_review != null && {
            badges: [
                {
                    label: "Review",
                    color: task.has_review ? "success" : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasReview"
                    ),
                },
            ],
        },
        (task.has_pictures != null || task.has_curation != null) && {
            badges: [
                {
                    label: "Picture",
                    color: task.has_pictures ? "success" : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasPicture"
                    ),
                },
                {
                    label: "Curation",
                    color: task.has_curation ? "success" : "secondary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.hasCuration"
                    ),
                },
            ],
        },
        task.last_updated != null && {
            badges: [
                {
                    label: task.last_updated,
                    color: "info",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.lastUpdated"
                    ),
                },
            ],
        },
        (task.lyric_with_sync != null ||
            task.genre != null ||
            task.language != null ||
            task.country != null) && {
            badges: [
                {
                    label: "Lyric with sync",
                    color: task.lyric_with_sync ? "success" : "secondary",
                },
                {
                    label: task.genre,
                    color: "primary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.genre"
                    ),
                },
                {
                    label: task.language,
                    color: "primary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.language"
                    ),
                },
                {
                    label: task.country,
                    color: "primary",
                    tooltip: I18n.getTranslation(
                        location,
                        "plannings.view.tasks.country"
                    ),
                },
            ],
        },
    ].filter(Boolean);

    return (
        <Task
            {...props}
            task={task}
            informations={informations}
            isPlanningLock={lock}
        />
    );
};

export default EnhancedTask;
