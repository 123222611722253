import React, { useState, useEffect, useCallback } from "react";
import Picture from "./Picture";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import PictureCard from "shared/components/PictureCard";

const defaultState = {
    isLoading: false,
    pictures: [],
};

const EnhancedPicture = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const location = useLocation();

    const getPicture = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/tracking/picture`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        pictures: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getPicture, [getPicture]);

    const columns = [
        {
            dataField: "url",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPicture.picture"
            ),
            formatter: (cell) => (cell ? <PictureCard url={cell} /> : "-"),
            headerStyle: {
                width: "13rem",
            },
        },
        {
            dataField: "date",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPicture.date"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
            headerStyle: { width: "13rem" },
        },
        {
            dataField: "username",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPicture.username"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
            headerStyle: { width: "25rem" },
        },
        {
            dataField: "action",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPicture.action"
            ),
            sort: true,
            formatter: (cell, { origin }) => (
                <I18n
                    t={`artist.management.trackingPicture.${origin}_${cell.replaceAll(
                        ".",
                        "_"
                    )}`}
                />
            ),
        },
    ];

    const options = {
        sizePerPage: 20,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Picture {...props} {...state} columns={columns} options={options} />
    );
};

export default EnhancedPicture;
