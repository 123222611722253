import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import Widget from "shared/components/Widget";

const Filter = ({ filterOptions, filter, setFilter }) => {
    return (
        <Widget.Body>
            <div className="row mb-2">
                <div className="col-md-4">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                source: opt,
                            }));
                        }}
                        value={filter.source}
                        placeholder={
                            <I18n t="common.charts.categoryList.source" />
                        }
                        options={filterOptions.source}
                    />
                </div>
                <div className="col-md-4">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                area: opt,
                            }));
                        }}
                        value={filter.area}
                        placeholder={
                            <I18n t="common.charts.categoryList.area" />
                        }
                        options={filterOptions.area}
                    />
                </div>
                <div className="col-md-4">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                country: opt,
                            }));
                        }}
                        value={filter.country}
                        placeholder={
                            <I18n t="common.charts.categoryList.country" />
                        }
                        options={filterOptions.country}
                    />
                </div>
            </div>
        </Widget.Body>
    );
};

export default Filter;
