import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Objects from "./Objects";

const EnhancedObjects = (props) => {
    const [selected, setSelected] = useState();
    const { albumId, releaseId, trackId } = useParams();

    useEffect(() => {
        if (trackId) {
            setSelected("track");
        } else if (releaseId) {
            setSelected("release");
        } else if (albumId) {
            setSelected("album");
        } else {
            setSelected();
        }
    }, [albumId, releaseId, trackId]);

    return (
        <Objects
            {...props}
            selected={selected}
            setSelected={setSelected}
            albumId={albumId}
            releaseId={releaseId}
            trackId={trackId}
        />
    );
};

export default EnhancedObjects;
