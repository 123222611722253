import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";

const CreateOrUpdateModal = ({
    show,
    action,
    errors,
    register,
    isSubmitting,
    isDirty,
    onHide,
    onSubmit,
}) => {
    return (
        <Modal show={show} onHide={onHide} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`cnm.repositories.platforms.createOrUpdate.${action}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? onSubmit : null}>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="cnm.repositories.platforms.createOrUpdate.name" />
                        </Form.Label>
                        <InputText
                            {...register("name")}
                            error={
                                errors.name && [
                                    I18n.getTranslation(
                                        location,
                                        errors.name.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    disabled={isSubmitting || !isDirty}
                    onClick={!isSubmitting ? onSubmit : null}
                >
                    <I18n
                        t={`cnm.repositories.platforms.createOrUpdate.${action}.submit`}
                    />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
