import React, { useState, useEffect, useCallback } from "react";
import ApiToken from "./ApiToken";
import { useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedApiToken = (props) => {
    const { locale } = useParams();
    const { api } = useAPI();
    const [token, setToken] = useState();

    const getToken = useCallback(() => {
        return api
            .get(`user/api/token`, { locale })
            .then((response) => {
                setToken(response.token);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getToken();
    }, [getToken]);

    return <ApiToken {...props} token={token} locale={locale} />;
};

export default EnhancedApiToken;
