import React from "react";
import Ratings from "./Ratings";
import useUser from "shared/hooks/useUser";

const EnhancedRatings = (props) => {
    const { user } = useUser();
    return <Ratings user={user} {...props} />;
};

export default EnhancedRatings;
