import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const PartnerSelect = ({ loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={null}
            loadOptions={loadOptions}
            onChange={onChange}
            {...props}
        />
    );
};

export default PartnerSelect;
