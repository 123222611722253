import React from "react";
import { Form, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";

const SearchForm = ({ location, register, onSubmit, disabled }) => {
    return (
        <Form onSubmit={onSubmit}>
            <div className="form-row mb-3">
                <div className="col">
                    <InputText
                        {...register("search")}
                        placeholder={I18n.getTranslation(
                            location,
                            "cnm.aggregation.search.form.search"
                        )}
                        disabled={disabled}
                    />
                </div>
                <div className="col-auto">
                    <Button type="submit" variant="primary" disabled={disabled}>
                        <I18n t="utils.button.search" />
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default SearchForm;
