import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Biography from "./Biography";
import I18n from "shared/lib/I18n";
import FlagIcon from "shared/functions/FlagIcon";
import { LANGS } from "./Biography.constants";

const EnhancedBiography = (props) => {
    const { locale } = useParams();
    const otherLangs = ["PT", "ES"];
    const langs = {
        ja: ["JA", "EN", "FR", "DE", ...otherLangs],
        en: ["EN", "FR", "JA", "DE", ...otherLangs],
        fr: ["FR", "EN", "JA", "DE", ...otherLangs],
        de: ["DE", "EN", "FR", "JA", ...otherLangs],
        ko: ["EN", "FR", "JA", "DE", ...otherLangs],
    };
    const [biographyLangStatus, setBiographyLangStatus] = useState([]);

    const updateBiographyLangStatus = (langStatus) => {
        setBiographyLangStatus((prev) => {
            const updatedRows = prev.map((row) =>
                row.lang === langStatus.lang ? { ...row, ...langStatus } : row
            );

            const langExists = prev.some((row) => row.lang === langStatus.lang);
            return langExists ? updatedRows : [...updatedRows, langStatus];
        });
    };

    const biographyLangOptions = useCallback(
        (lang) => {
            return biographyLangStatus
                .filter((row) => {
                    if (lang) {
                        return (
                            row.hasBiography &&
                            (row.isReference || row.isOfficial) &&
                            String(row.lang) !== String(lang)
                        );
                    }

                    return (
                        row.hasBiography && (row.isReference || row.isOfficial)
                    );
                })
                .map(({ lang }) => ({
                    label: (
                        <>
                            <FlagIcon code={LANGS[lang]} className="mr-1" />
                            <I18n t={`artist.management.bio.head.${lang}`} />
                        </>
                    ),
                    value: lang,
                }));
        },
        [biographyLangStatus]
    );

    return (
        <Biography
            {...props}
            langs={langs}
            locale={locale}
            biographyLangOptions={biographyLangOptions}
            updateBiographyLangStatus={updateBiographyLangStatus}
        />
    );
};

export default EnhancedBiography;
