import React from "react";
import Tracking from "./Tracking";
import useUser from "shared/hooks/useUser";

const EnhancedTracking = (props) => {
    const { user } = useUser();

    return <Tracking {...props} user={user} />;
};

export default EnhancedTracking;
