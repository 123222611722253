import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Helmet } from "react-helmet";
import { Nav, Tab } from "react-bootstrap";
import styles from "./MsStatistics.module.css";
import Artists from "./Artists";
import Albums from "./Albums";
import Recordings from "./Recordings";
import clsx from "clsx";

const MsStatistics = ({ selectedTab, onSelectTab }) => {
    return (
        <Widget className="m-0 p-0">
            <Tab.Container
                id="msStats-tab"
                activeKey={selectedTab}
                defaultActiveKey="artists"
                onSelect={onSelectTab}
            >
                <Widget.Body>
                    <div className={clsx(styles.cardHeader)}>
                        <Nav className={clsx(styles.nav)}>
                            <Nav.Item className={clsx(styles.navItem)}>
                                <Nav.Link
                                    eventKey="artists"
                                    className={clsx(
                                        styles.navLink,
                                        selectedTab === "artists" &&
                                            styles.active
                                    )}
                                >
                                    <I18n t="landing.msStats.artists.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={clsx(styles.navItem)}>
                                <Nav.Link
                                    eventKey="albums"
                                    className={clsx(
                                        styles.navLink,
                                        selectedTab === "albums" &&
                                            styles.active
                                    )}
                                >
                                    <I18n t="landing.msStats.albums.title" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={clsx(styles.navItem)}>
                                <Nav.Link
                                    eventKey="recordings"
                                    className={clsx(
                                        styles.navLink,
                                        selectedTab === "recordings" &&
                                            styles.active
                                    )}
                                >
                                    <I18n t="landing.msStats.recordings.title" />
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Widget.Body>
                <Widget.Body className={clsx("p-4")}>
                    <Tab.Content className="overflow-hidden">
                        <Tab.Pane eventKey="artists" className="nav-pane">
                            <Artists />
                        </Tab.Pane>
                        <Tab.Pane eventKey="albums" className="nav-pane">
                            <Albums />
                        </Tab.Pane>
                        <Tab.Pane eventKey="recordings" className="nav-pane">
                            <Recordings />
                        </Tab.Pane>
                    </Tab.Content>
                </Widget.Body>
            </Tab.Container>
        </Widget>
    );
};

export default MsStatistics;
