import React from "react";
import I18n from "shared/lib/I18n";
import { NavLink } from "shared/lib/I18n";

const PartnerList = ({ partners, selectedPartner }) => {
    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="partner_tops.partners.empty" />
            </td>
        </tr>
    );
    if (partners.length !== 0) {
        rows = partners.map((partner) => (
            <tr
                key={partner}
                className={
                    selectedPartner === partner.toLowerCase()
                        ? "table-info"
                        : undefined
                }
            >
                <td>
                    <NavLink
                        to={`/matching/partner_tops/${partner.toLowerCase()}`}
                    >
                        <I18n t={`common.partners.${partner}`} />
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <section className="widget">
            <header>
                <h6>
                    <I18n t="partner_tops.partners.title" />
                </h6>
            </header>
            <div
                className="widget-body widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                <table
                    className="table table-striped"
                    style={{ marginBottom: "0px" }}
                >
                    <tbody>{rows}</tbody>
                </table>
            </div>
        </section>
    );
};

export default PartnerList;
