import React, { useCallback } from "react";
import ReCaptcha from "./ReCaptcha";
import { useParams } from "react-router";

const EnhancedReCaptcha = ({ onVerifiedCaptcha, action, ...props }) => {
    const { locale } = useParams();
    const onVerifyCaptcha = useCallback((token) => {
        if (!token) {
            return;
        }

        onVerifiedCaptcha?.(token);
    }, []);

    return (
        <ReCaptcha
            onVerifyCaptcha={onVerifyCaptcha}
            action={action}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedReCaptcha;
