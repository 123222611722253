import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAmazon,
    faApple,
    faDeezer,
    faNapster,
    faSpotify,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const iconQobuz = {
    icon: [
        128, // Viewbox width
        128, // Viewbox height
        [], // Aliases (optional)
        "", // Unicode codepoint (optional)
        "M 64 5 C 32 5 6 32 6 64 C 6 96 32 122 64 122 C 85 122 91 116 95 112.9531 L 87 105 C 82 108 80 110 64 110 C 40 110 18 88 18 64 C 18 40 40 18 64 18 C 88 18 109 40 110 64 C 110 84 106 87 100 93 L 89 82 C 87 80 84 80 82 82 C 80 84 80 87 82 89 L 114 121 L 121 114 L 108 101 C 117 91 122 84 122 64 C 122 33 96 5 64 5 z M 64 46 C 54.4 46 46 54.4 46 64 C 46 73.6 54.4 82 64 82 C 73.6 82 82 73.6 82 64 C 82 54.4 73.6 46 64 46 z M 64 58 C 67 58 70 61 70 64 C 70 67 67 70 64 70 C 61 70 58 67 58 64 C 58 61 61 58 64 58 z", // SVG path
    ],
    iconName: "ms-custom-qobuz",
    prefix: "ms-custom",
};

const CNMPlatformIcon = ({ name, size, className }) => {
    const platformIcons = {
        Amazon: faAmazon,
        Apple: faApple,
        Deezer: faDeezer,
        Napster: faNapster,
        Spotify: faSpotify,
        Youtube: faYoutube,
        Qobuz: iconQobuz,
    };

    return name in platformIcons ? (
        <FontAwesomeIcon
            icon={platformIcons[name]}
            size={size}
            className={className}
            fixedWidth
        />
    ) : (
        name.slice(0, 2)
    );
};

export default CNMPlatformIcon;
