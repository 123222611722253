import React, { useState, useEffect, useCallback } from "react";
import Gallery from "./Gallery";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { getFilterOptions, getFilteredGallery } from "./Gallery.pure";

const defaultState = {
    gallery: [],
    selectedFilterOptions: [],
    failIds: [],
    isLoading: true,
};

const defaultDetailState = {
    id_picture: null,
    id_picture_version: null,
    show: false,
};

const EnhancedGallery = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);
    const [detailState, setDetailState] = useState(defaultDetailState);

    const filteredGallery = getFilteredGallery(state);
    const filterOptions = getFilterOptions({
        gallery: filteredGallery,
    });

    const handleShow = (id_picture, id_picture_version) => {
        setDetailState({ id_picture, id_picture_version, show: true });
    };

    const handleHide = () => {
        setDetailState(defaultDetailState);
    };

    const getGallery = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`artist/${artistId}/gallery`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    gallery: response,
                }));
                if (response.length === 0) {
                    setNoData("gallery");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getGallery()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getGallery]);

    const setSelectedFilterOptions = (selectedFilterOptions) => {
        setState((prev) => ({ ...prev, selectedFilterOptions }));
    };

    const setFailId = (id_picture_version) => {
        setState((prev) => ({
            ...prev,
            failIds: [...prev.failIds, id_picture_version],
        }));
    };

    return (
        <Gallery
            {...props}
            {...state}
            filteredGallery={filteredGallery}
            filterOptions={filterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            detailState={detailState}
            handleShow={handleShow}
            handleHide={handleHide}
            setFailId={setFailId}
        />
    );
};

export default EnhancedGallery;
