import {
    GENRE_TYPE_MAIN,
    GENRE_TYPE_PRINCIPAL,
    GENRE_TYPE_SECONDARY,
    GENRE_TYPE_INFLUENCE,
} from "shared/constants/cms/artist";

export const getMain = ({ genre, genreOptions }) => {
    if (genre.length !== 0 && genreOptions.length !== 0) {
        const main = genre.find(
            ({ type_relation }) => type_relation === GENRE_TYPE_MAIN
        );
        const option = genreOptions.find(({ id }) => id === main?.id_genre);
        if (option) {
            return { id: main.id_genre, name: option.nom };
        }
    }

    return;
};

export const getPrincipal = ({ genre, genreOptions }) => {
    if (genre.length !== 0 && genreOptions.length !== 0) {
        return genre
            .filter(
                ({ type_relation }) => type_relation === GENRE_TYPE_PRINCIPAL
            )
            .map(({ id_genre }) => {
                const genre = genreOptions.find(
                    ({ id: id_option }) => id_option === id_genre
                );
                return { id: id_genre, name: genre.nom };
            });
    }
    return [];
};

export const getSecondary = ({ genre, genreOptions }) => {
    if (genre.length !== 0 && genreOptions.length !== 0) {
        return genre
            .filter(
                ({ type_relation }) => type_relation === GENRE_TYPE_SECONDARY
            )
            .map(({ id_genre }) => {
                const genre = genreOptions.find(
                    ({ id: id_option }) => id_option === id_genre
                );
                return { id: id_genre, name: genre.nom };
            });
    }
    return [];
};

export const getInfluence = ({ genre, genreOptions }) => {
    if (genre.length !== 0 && genreOptions.length !== 0) {
        return genre
            .filter(
                ({ type_relation }) => type_relation === GENRE_TYPE_INFLUENCE
            )
            .map(({ id_genre }) => {
                const genre = genreOptions.find(
                    ({ id: id_option }) => id_option === id_genre
                );
                return { id: id_genre, name: genre.nom };
            });
    }
    return [];
};
