import React from "react";
import Loader from "shared/components/Loader";
import ArtistSelect from "shared/components/ArtistSelect";
import EditMatchingsTable from "shared/components/EditMatchingsTable";
import I18n from "shared/lib/I18n";
import { STATUS_COLOR } from "./EditMatchingsForm.constants";

const EditMatchingsForm = ({
    isLoading,
    status,
    candidates,
    onChange,
    onMatch,
    locale,
}) => {
    return isLoading ? (
        <Loader />
    ) : (
        <>
            <table className="table table-bordered table-sm border-left-0 border-right-0">
                <tbody>
                    <tr className={`table-${STATUS_COLOR[status] || "danger"}`}>
                        <td className="text-center border-left-0 border-right-0">
                            <span>
                                <strong className="mr-1">
                                    <I18n t="common.editMatchingsModal.status" />
                                </strong>
                                {status ? (
                                    <I18n
                                        t={`common.editMatchingsModal.${status}`}
                                    />
                                ) : (
                                    "-"
                                )}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ArtistSelect
                onMatch={onMatch}
                locale={locale}
                className="m-4"
                enablePortal
            />
            <EditMatchingsTable
                status={status}
                candidates={candidates}
                onChangeState={onChange}
            />
        </>
    );
};

export default EditMatchingsForm;
