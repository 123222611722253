import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Filters from "./Filters";

const EnhancedFilters = (props) => {
    const location = useLocation();

    const chartsOptions = ["initial", "complement"].map((value) => ({
        value,
        label: I18n.getTranslation(
            location,
            `cnm.charts.platform.matching.filters.charts.${value}`
        ),
    }));

    return <Filters chartsOptions={chartsOptions} {...props} />;
};

export default EnhancedFilters;
