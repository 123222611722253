import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PicturesLabelValidation from "./PicturesLabelValidation";
import { useLocation } from "react-router";

const EnhancedPicturesLabelValidation = (props) => {
    const [validationGallery, setValidationGallery] = useState([]);
    const [processingGallery, setProcessingGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { api } = useAPI();
    const location = useLocation();

    const getGalleries = useCallback(() => {
        api.get("picture/label/validation")
            .then((response) => {
                setValidationGallery(
                    response.filter(({ status }) => status === "AWAITING")
                );
                setProcessingGallery(
                    response.filter(
                        ({ status }) => status === "AWAITING_FOR_PROCESSING"
                    )
                );
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getGalleries, [getGalleries]);

    return (
        <PicturesLabelValidation
            {...props}
            validationGallery={validationGallery}
            processingGallery={processingGallery}
            getGalleries={getGalleries}
            isLoading={isLoading}
            location={location}
        />
    );
};

export default EnhancedPicturesLabelValidation;
