import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Filters from "./Filters";

const EnhancedFilters = ({ platforms, ...props }) => {
    const location = useLocation();

    const platformOptions = platforms.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const numberOfMatchedPlatformsOptions = platforms.map((_, i) => ({
        value: i + 1,
        label: i + 1,
    }));

    const statusOptions = [
        {
            value: "NONE",
            label: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.filters.status.none"
            ),
        },
        {
            value: "CHECKED",
            label: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.filters.status.checked"
            ),
        },
        {
            value: "DISCARDED",
            label: I18n.getTranslation(
                location,
                "cnm.aggregation.cnmTitleList.filters.status.discarded"
            ),
        },
    ];

    return (
        <Filters
            platformOptions={platformOptions}
            numberOfMatchedPlatformsOptions={numberOfMatchedPlatformsOptions}
            statusOptions={statusOptions}
            {...props}
        />
    );
};

export default EnhancedFilters;
