import React, { useState } from "react";
import Planning from "./Planning";

const EnhancedPlanning = ({ plannings, ...props }) => {
    const [activePage, setActivePage] = useState(1);

    const getPlanningsByPage = () => {
        if (plannings.length <= 10) {
            return plannings;
        }

        const offset = (activePage - 1) * 10 + 1;

        return plannings.slice(offset - 1, offset + 9);
    };

    return (
        <Planning
            {...props}
            planningLength={plannings.length}
            activePage={activePage}
            handlePageChange={setActivePage}
            getPlanningsByPage={getPlanningsByPage}
        />
    );
};

export default EnhancedPlanning;
