import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import SpotifyAlbumMatcher from "./SpotifyAlbumMatcher";

const EnhancedSpotifyAlbumMatcher = ({
    listId,
    rowId,
    defaultSearch,
    onChange,
    ...props
}) => {
    const { api } = useAPI();

    const loadOptions = useDebouncedCallback((term, callback) => {
        api.get("matching/album/sp/search", { term: term })
            .then((response) => {
                callback(
                    response.map(({ id, name, release_date, artists }) => {
                        const artistNames = artists
                            .map(({ name }) => name)
                            .join(", ");
                        return {
                            value: id,
                            label: `${name} - ${artistNames} - ${release_date} (${id})`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onMatch = (spotifyId) => {
        api.post(
            `matching/album/snep/list/${listId}/${rowId}/spotify/${spotifyId}`
        )
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.match.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.match.error",
                        error.message
                    )
                );
            });
    };

    const onUnmatch = () => {
        api.delete(`matching/album/snep/list/${listId}/${rowId}/spotify`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.unmatch.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.album.album.unmatch.error",
                        error.message
                    )
                );
            });
    };

    const onSelectChange = (opt, action) => {
        if (action.action === "select-option") {
            onMatch(opt.value);
        } else {
            onUnmatch();
        }
    };

    return (
        <SpotifyAlbumMatcher
            defaultSearch={defaultSearch}
            loadOptions={loadOptions}
            onChange={onSelectChange}
            {...props}
        />
    );
};

export default EnhancedSpotifyAlbumMatcher;
