import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import UnmarkAction from "./UnmarkAction";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedUnmarkAction = ({ listId, rowId, gid, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();

    const onMark = () => {
        api.delete(
            `matching/artist/hubbard/list/${listId}/${rowId}/unmark/${gid}`
        )
            .then((response) => {
                onSuccess(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.unmark.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.unmark.error",
                        error.message
                    )
                );
            });
    };

    return <UnmarkAction onMark={onMark} location={location} {...props} />;
};

export default EnhancedUnmarkAction;
