import React from "react";

const APIDownloadLink = ({ onClick, children, ...props }) => {
    return (
        <a href="#" onClick={onClick} {...props}>
            {children}
        </a>
    );
};

export default APIDownloadLink;
