import React from "react";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faArrowRight,
    faEye,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import InputSwitch from "shared/components/Form/InputSwitch";

const ArtistTitle = ({ artist, handleComplete, location, redirectToView }) => {
    return (
        <>
            {artist.hasOwnProperty("completed") && !artist.completed && (
                <div
                    className="alert alert-warning d-flex justify-content-center align-items-center"
                    role="alert"
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="2x"
                        fixedWidth
                    />
                    <span className="mx-3 h3 mb-0">
                        <strong>
                            <I18n t="artist.management.action.complete.message" />
                        </strong>
                    </span>
                    <FontAwesomeIcon icon={faArrowRight} size="2x" fixedWidth />
                    <Button
                        variant="danger"
                        className="ml-3"
                        title={I18n.getTranslation(
                            location,
                            "artist.management.action.complete.button"
                        )}
                        onClick={handleComplete}
                        type="button"
                    >
                        <strong className="h4 mb-0">
                            <I18n t="artist.management.action.complete.button" />
                        </strong>
                    </Button>
                </div>
            )}
            <div className="d-flex">
                <h1 className="flex-grow-1 display-4">{artist?.nom ?? "-"}</h1>
                <div className="align-self-center d-flex align-items-center">
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                    <InputSwitch checked={true} onChange={redirectToView} />
                    <FontAwesomeIcon icon={faPen} fixedWidth />
                </div>
            </div>
            <span className="lead">{`${artist?.id ?? "-"}${
                artist?.complement ? ` - ${artist.complement}` : ""
            }`}</span>
        </>
    );
};

export default ArtistTitle;
