import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import ImageLoader from "shared/components/ImageLoader";
import placeholder from "assets/static/images/logo_placeholder.png";
import { displayAsString } from "shared/functions/Date";
import styles from "shared/components/MultiSearch/MultiSearch.module.css";

const Albums = ({ q, handleReset, albums, locale }) => {
    return (
        <>
            <div className={styles.head}>
                <div className="d-flex justify-content-between">
                    <div className={styles.head__title}>
                        <I18n t="common.multiSearch.album" />
                    </div>
                    <div>
                        <NavLink
                            to={`/search/albums?q=${q}`}
                            onClick={handleReset}
                        >
                            <I18n t="common.multiSearch.more" />
                        </NavLink>
                    </div>
                </div>
            </div>
            <div>
                {albums.length !== 0 ? (
                    albums.map((album) => {
                        const subtitle = [
                            album.type && (
                                <I18n
                                    t={`common.multiSearch.type.${album.type.toLowerCase()}`}
                                />
                            ),
                            album.format && (
                                <I18n
                                    t={`common.multiSearch.format.${album.format.toLowerCase()}`}
                                />
                            ),
                            album.release_date &&
                                displayAsString(album.release_date, locale),
                        ].filter(Boolean);

                        return (
                            <NavLink
                                key={album.id}
                                to={`/album/${album.album_id}/view`}
                                onClick={handleReset}
                                className={styles.content__a}
                            >
                                <div className={styles.content__row}>
                                    <div>
                                        <ImageLoader
                                            src={album.cover}
                                            fallbackSrc={placeholder}
                                            fallbackClassName={
                                                styles.cover__placeholder
                                            }
                                            className={styles.cover__album}
                                        />
                                    </div>
                                    <div
                                        className={styles.content__information}
                                    >
                                        <div className={styles.content__title}>
                                            <span>{album.album_id}</span>
                                            <span className="mx-1">-</span>
                                            <span>{album.title}</span>
                                        </div>
                                        {(album.artists ||
                                            subtitle.length !== 0) && (
                                            <div
                                                className={
                                                    styles.content__subtitle
                                                }
                                            >
                                                {album.artists && (
                                                    <>
                                                        {album.artists.map(
                                                            (artist, index) => (
                                                                <React.Fragment
                                                                    key={
                                                                        artist.id
                                                                    }
                                                                >
                                                                    {index !==
                                                                        0 && (
                                                                        <span className="mx-1">
                                                                            &bull;
                                                                        </span>
                                                                    )}
                                                                    {
                                                                        artist.name
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                        <span className="mx-1">
                                                            -
                                                        </span>
                                                    </>
                                                )}
                                                {subtitle.map((s, index) => (
                                                    <React.Fragment key={index}>
                                                        {index !== 0 && (
                                                            <span className="mx-1">
                                                                -
                                                            </span>
                                                        )}
                                                        <span>{s}</span>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </NavLink>
                        );
                    })
                ) : (
                    <div className={styles.content__row__empty}>
                        <I18n t="common.multiSearch.empty" />
                    </div>
                )}
            </div>
        </>
    );
};

export default Albums;
