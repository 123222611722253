import React, { useState, useEffect, useCallback } from "react";
import Names from "./Names";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedNames = (props) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        alias: [],
        aliasTypeOptions: [],
        names: [],
        langIsoOptions: [],
        isLoading: true,
    });

    const getAlias = useCallback(() => {
        return api
            .get(`artist/${artistId}/alias`)
            .then((response) => {
                setState((prev) => ({ ...prev, alias: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getAliasType = useCallback(() => {
        return api
            .get(`artist/alias-type/${locale}`)
            .then((response) => {
                setState((prev) => ({ ...prev, aliasTypeOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    const getNames = useCallback(() => {
        return api
            .get(`artist/${artistId}/names`)
            .then((response) => {
                setState((prev) => ({ ...prev, names: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getLangIso = useCallback(() => {
        return api
            .get(`lang-iso`, { locale })
            .then((response) => {
                setState((prev) => ({ ...prev, langIsoOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([
                getAlias(),
                getAliasType(),
                getNames(),
                getLangIso(),
            ]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getAlias, getNames, getAliasType, getLangIso]);

    return <Names {...props} {...state} />;
};

export default EnhancedNames;
