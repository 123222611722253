import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const SpotifyAlbumMatcher = ({
    spotifyId,
    defaultSearch,
    loadOptions,
    onChange,
    disabled,
    ...props
}) => {
    return (
        <div className="d-flex align-items-center">
            <div className="flex-grow-1">
                <InputAsyncSelect
                    value={
                        spotifyId !== null && typeof spotifyId !== "undefined"
                            ? {
                                  value: spotifyId,
                                  label: spotifyId,
                              }
                            : null
                    }
                    defaultSearch={defaultSearch}
                    loadOptions={loadOptions}
                    onChange={onChange}
                    getOptionValue={({ value }) => value}
                    isDisabled={disabled}
                    {...props}
                />
            </div>
            <div>
                {spotifyId && (
                    <a
                        href={`https://open.spotify.com/album/${spotifyId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2"
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </a>
                )}
            </div>
        </div>
    );
};

export default SpotifyAlbumMatcher;
