import React from "react";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";

const EnhancedSearchForm = ({ onSearch, ...props }) => {
    const { handleSubmit, register } = useForm({
        defaultValues: {
            name: "",
            title: "",
        },
    });

    const onSubmit = handleSubmit((data) => onSearch(data.name, data.title));

    return <SearchForm onSubmit={onSubmit} register={register} {...props} />;
};

export default EnhancedSearchForm;
