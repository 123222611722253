import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import InputTextArea from "shared/components/Form/InputTextArea";
import I18n from "shared/lib/I18n";

const DiscardButton = ({
    discarded,
    comment,
    register,
    isSubmitting,
    onSubmit,
    show,
    onClick,
    onHide,
    location,
}) => {
    const actionTitle = I18n.getTranslation(
        location,
        discarded
            ? "cnm.aggregation.cnmTitleList.discardButton.recover"
            : "cnm.aggregation.cnmTitleList.discardButton.discard"
    );
    const commentTitle =
        discarded && comment
            ? I18n.getTranslation(
                  location,
                  "cnm.aggregation.cnmTitleList.discardButton.commentTitle"
              ) + comment
            : null;

    return (
        <>
            <Button
                variant="outline-secondary"
                size="sm"
                className="p-1"
                title={
                    commentTitle
                        ? `${actionTitle}\n${commentTitle}`
                        : actionTitle
                }
                onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                }}
            >
                <FontAwesomeIcon
                    icon={discarded ? faThumbsUp : faThumbsDown}
                    fixedWidth
                    size="lg"
                />
            </Button>

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.aggregation.cnmTitleList.discardButton.head" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.aggregation.cnmTitleList.discardButton.comment" />
                            </Form.Label>
                            <InputTextArea {...register("comment")} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting}
                        onClick={onSubmit}
                    >
                        <I18n t="cnm.aggregation.cnmTitleList.discardButton.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DiscardButton;
