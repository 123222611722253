import React from "react";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const CountrySelect = ({
    isLoading,
    options,
    control,
    name,
    error,
    location,
    disabled,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                    innerRef={ref}
                    name={name}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.countries.placeholder"
                    )}
                    options={options}
                    value={value
                        .map((id) => options.find((o) => o.value === id))
                        .filter(Boolean)}
                    onChange={(option) =>
                        onChange(option ? option.map(({ value }) => value) : [])
                    }
                    isLoading={isLoading}
                    isDisabled={disabled}
                    isClearable
                    isMulti
                    enablePortal
                    error={error}
                />
            )}
        />
    );
};

export default CountrySelect;
