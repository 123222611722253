import React from "react";
import { useLocation } from "react-router";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Search = ({
    query,
    onQueryChange,
    onSearch,
    onGlobalSearch,
    disabled,
}) => {
    const location = useLocation();

    return (
        <div className="form-row mb-3">
            <div className="col">
                <input
                    type="text"
                    className="form-control"
                    placeholder={I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.search.input"
                    )}
                    value={query}
                    onChange={(event) => onQueryChange(event.target.value)}
                    onKeyUp={(event) => onSearch(event.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="col flex-grow-0">
                <Button
                    className="text-nowrap"
                    variant="primary"
                    title={I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.search.title"
                    )}
                    onClick={() => onGlobalSearch(query)}
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Button>
            </div>
        </div>
    );
};

export default Search;
