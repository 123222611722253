import React, { useCallback, useEffect, useState } from "react";
import PlanningsClient from "./PlanningsClient";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const EnhancedPlanningsClient = (props) => {
    const [plannings, setPlannings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPlanningLoading, setIsPlanningLoading] = useState(true);
    const { api } = useAPI();
    const location = useLocation();

    const getPlannings = useCallback(() => {
        api.get(`plannings`, { mode: "client" })
            .then((response) => {
                let selected = false;
                setPlannings(
                    response.map((row, index) => {
                        if (!selected) {
                            if (index === response.length - 1) {
                                selected = true;
                            }
                            return {
                                ...row,
                                selected,
                            };
                        }
                        return {
                            ...row,
                            selected: false,
                        };
                    })
                );
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getPlannings();
    }, [getPlannings]);

    const getSelectedPlanning = () => {
        return plannings.find(({ selected }) => selected);
    };

    const getFirstPlanningId = () => {
        return [...plannings].shift()?.id;
    };

    const getLastPlanningId = () => {
        return plannings[plannings.length - 1]?.id;
    };

    const setNextSelectedPlanning = () => {
        setIsPlanningLoading(true);
        setPlannings(
            plannings.map((row, index) => {
                return {
                    ...row,
                    selected:
                        index ===
                        plannings.findIndex(
                            ({ id }) => id === getSelectedPlanning()?.id
                        ) +
                            1,
                };
            })
        );
    };

    const setPreviousSelectedPlanning = () => {
        setIsPlanningLoading(true);
        setPlannings(
            plannings.map((row, index) => {
                return {
                    ...row,
                    selected:
                        index ===
                        plannings.findIndex(
                            ({ id }) => id === getSelectedPlanning()?.id
                        ) -
                            1,
                };
            })
        );
    };

    return (
        <PlanningsClient
            {...props}
            isLoading={isLoading}
            planning={getSelectedPlanning()}
            first={getFirstPlanningId()}
            last={getLastPlanningId()}
            next={setNextSelectedPlanning}
            previous={setPreviousSelectedPlanning}
            isPlanningLoading={isPlanningLoading}
            setIsPlanningLoading={setIsPlanningLoading}
            location={location}
        />
    );
};

export default EnhancedPlanningsClient;
