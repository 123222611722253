import React from "react";
import Chart from "./Chart";

const EnhancedChart = React.memo(
    ({ language_repartition, setPopover, ...props }) => {
        const getColor = (index) => {
            if (index === 0) return "#000000";
            if (index === 1) return "#3900D4";
            if (index === 2) return "#8866E5";
            if (index === 3) return "#FF00FF";
            if (index === 4) return "#FF99FF";
            return "#F2F2F2";
        };

        const data = {
            labels: language_repartition.map(({ name }) => name),
            datasets: [
                {
                    label: "language",
                    data: language_repartition.map(({ count }) => count),
                    backgroundColor: language_repartition.map(
                        ({ lang }, index) => getColor(index)
                    ),
                    borderRadius: 2,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: ({ chart, tooltip }) => {
                        setPopover({
                            show:
                                tooltip.opacity > 0 &&
                                tooltip.dataPoints !== undefined,
                            left: chart.canvas.offsetLeft + tooltip.caretX,
                            top: chart.canvas.offsetTop + tooltip.caretY,
                            language_repartition:
                                tooltip.dataPoints !== undefined
                                    ? language_repartition[
                                          tooltip.dataPoints[0].dataIndex
                                      ]
                                    : null,
                        });
                    },
                },
            },
        };

        return <Chart {...props} data={data} options={options} />;
    }
);

export default EnhancedChart;
