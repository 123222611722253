import React from "react";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const LanguageSelect = ({ control, name, error, location, disabled }) => {
    const options = ["FRENCH", "INTERNATIONAL", "INSTRUMENTAL"].map(
        (value) => ({
            value,
            label: I18n.getTranslation(
                location,
                `cnm.report.consolidate.edit.language.${value}`
            ),
        })
    );

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                    innerRef={ref}
                    name={name}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.language.placeholder"
                    )}
                    options={options}
                    value={
                        value
                            ? options.find((option) => option.value == value)
                            : null
                    }
                    onChange={(option, action) => {
                        if (action.action === "select-option") {
                            onChange(option.value);
                        } else if (action.action === "clear") {
                            onChange(null);
                        }
                    }}
                    isDisabled={disabled}
                    isClearable
                    enablePortal
                    error={error}
                />
            )}
        />
    );
};

export default LanguageSelect;
