import React, { useCallback, useEffect, useState } from "react";
import ImageLoader from "./ImageLoader";

const EnhancedImageLoader = ({
    src,
    fallbackSrc,
    fallbackClassName,
    errorMessage,
    onFail,
    ...props
}) => {
    const [currentSrc, setCurrentSrc] = useState();
    const [currentClassName, setCurrentClassName] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const img = new Image();

    useEffect(() => {
        setCurrentSrc(src);
    }, [src]);

    const onLoad = useCallback(() => {
        setLoading(false);
    }, []);

    const onError = useCallback(() => {
        if (fallbackSrc && currentSrc != fallbackSrc) {
            setCurrentSrc(fallbackSrc);
            setCurrentClassName(fallbackClassName);
        } else {
            setError(true);
            setLoading(false);
            onFail?.();
        }
    }, [currentSrc]);

    const getImage = useCallback(() => {
        if (currentSrc === undefined) {
            return;
        }
        setLoading(true);
        setError(false);

        img.addEventListener("load", onLoad);
        img.addEventListener("error", onError);
        img.src = currentSrc;

        return () => {
            img.removeEventListener("load", onLoad);
            img.removeEventListener("error", onError);
        };
    }, [currentSrc]);

    useEffect(getImage, [getImage]);

    return (
        <ImageLoader
            src={currentSrc}
            currentClassName={currentClassName}
            errorMessage={errorMessage}
            isLoading={isLoading}
            error={error}
            {...props}
        />
    );
};

export default EnhancedImageLoader;
