import React, { useState, useEffect, useCallback } from "react";
import SampleList from "./SampleList";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import moment from "moment";

const EnhancedSampleList = ({ setSampleDate, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        sample: [],
        isLoading: false,
    });
    const location = useLocation();
    const { locale, selectedTab } = useParams();
    const { sampleId } = useIntParams();
    const navigate = useNavigate();

    const getSample = useCallback(() => {
        let cancelled = false;

        setState((previous) => {
            return { ...previous, isLoading: true };
        });

        api.get("apple/sample")
            .then((response) => {
                if (!cancelled) {
                    setState({
                        sample: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                if (!cancelled) {
                    setState({
                        sample: [],
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(getSample, [getSample]);

    useEffect(() => {
        if (state.sample.length > 0) {
            if (sampleId) {
                setSampleDate(
                    state.sample.find(({ id }) => id === sampleId).sample_of
                );
            } else {
                navigate(
                    `/${locale}/apple/sample/report/${state.sample[0].id}/scorecard_provider`
                );
            }
        }
    }, [state, sampleId]);

    const columns = [
        {
            dataField: "date",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "apple.sample.report.sample.date"
            ),
            classes: "capitalize",
            formatter: (cell, row, rowIndex, data) => {
                return moment(row.sample_of)
                    .locale(data.locale)
                    .format("YYYY - MMMM");
            },
            formatExtraData: {
                locale,
            },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                if (selectedTab) {
                    navigate(
                        `/${locale}/apple/sample/report/${row.id}/${selectedTab}`
                    );
                } else {
                    navigate(`/${locale}/apple/sample/report/${row.id}`);
                }
            }
        },
        selected: [sampleId],
    };

    return (
        <SampleList
            {...props}
            {...state}
            columns={columns}
            selectRow={selectRow}
        />
    );
};

export default EnhancedSampleList;
