import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import LyricfindMatcher from "./LyricfindMatcher";
import I18n from "shared/lib/I18n";

const EnhancedLyricfindMatcher = ({
    listId,
    rowId,
    onChange,
    locale,
    ...props
}) => {
    const { api } = useAPI();
    const search = useDebouncedCallback((term, callback) => {
        api.get("matching/lf/search", { term, locale })
            .then(callback)
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onSelectChange = (opt, action) => {
        if (action.action === "select-option") {
            api.post(
                `matching/track/deezer/list/${listId}/${rowId}/lyricfind/${opt.id}`
            )
                .then((response) => {
                    onChange(response);

                    toast.success(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.match.success"
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.match.error",
                            error.message
                        )
                    );
                });
        } else {
            api.delete(
                `matching/track/deezer/list/${listId}/${rowId}/lyricfind`
            )
                .then((response) => {
                    onChange(response);

                    toast.success(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.unmatch.success"
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.unmatch.error",
                            error.message
                        )
                    );
                });
        }
    };

    return (
        <LyricfindMatcher
            loadOptions={search}
            onChange={onSelectChange}
            {...props}
        />
    );
};

export default EnhancedLyricfindMatcher;
