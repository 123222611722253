import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import I18nRelativeDate from "shared/components/I18nRelativeDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrashAlt,
    faPlus,
    faCogs,
} from "@fortawesome/free-solid-svg-icons";
import {
    SUBTASK_STATUS_ICON,
    SUBTASK_STATUS_COLOR,
} from "shared/constants/Planning/SubTask";

const actionsParameters = {
    CREATED: {
        translation: "plannings.view.history.action.created_by",
        icon: faPlus,
        colorClass: "text-success",
    },
    DELETED: {
        translation: "plannings.view.history.action.deleted_by",
        icon: faTrashAlt,
        colorClass: "text-danger",
    },
    MODIFIED: {
        translation: "plannings.view.history.action.modified_by",
        icon: faEdit,
        colorClass: "text-primary",
    },
    RELEASE_MATCHED: {
        translation: "plannings.view.history.action.release_matched_by",
        icon: faCogs,
        colorClass: "text-success",
    },
    RELEASE_UNMATCHED: {
        translation: "plannings.view.history.action.release_unmatched_by",
        icon: faCogs,
        colorClass: "text-secondary",
    },
    TRACK_MATCHED: {
        translation: "plannings.view.history.action.track_matched_by",
        icon: faCogs,
        colorClass: "text-success",
    },
    TRACK_UNMATCHED: {
        translation: "plannings.view.history.action.track_unmatched_by",
        icon: faCogs,
        colorClass: "text-secondary",
    },
};

const Row = ({
    type,
    action,
    task,
    subtask,
    userName,
    data,
    createdAt,
    defaultLocale,
}) => {
    const location = useLocation();

    let taskTitle = task?.ms_artist ? task.ms_artist.name : task.artist_name;
    if (task.album_title && task.track_title) {
        taskTitle = `${taskTitle} (${task.album_title}, ${task.track_title})`;
    } else if (task.album_title) {
        taskTitle = `${taskTitle} (${task.album_title})`;
    } else if (task.track_title) {
        taskTitle = `${taskTitle} (${task.track_title})`;
    }

    if (type === "subtask") {
        taskTitle = `${subtask.type} - ${taskTitle}`;
    }

    if (action === "STATUS_CHANGED") {
        return (
            <tr>
                <td style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                        icon={SUBTASK_STATUS_ICON[data.new_status]}
                        className={`text-${
                            SUBTASK_STATUS_COLOR[data.new_status]
                        }`}
                        size="lg"
                    />
                </td>
                <td>
                    <span style={{ fontWeight: "bold" }}>{taskTitle}</span>
                    <br />
                    <span className="mr-1">
                        <I18n
                            t="plannings.view.history.action.status_changed_by"
                            args={I18n.getTranslation(
                                location,
                                `plannings.view.tasks.subtasks.status.${data.new_status}`
                            )}
                        />
                    </span>
                    <span className="mr-1">{userName}</span>
                    <I18nRelativeDate
                        date={createdAt}
                        defaultLocale={defaultLocale}
                    />
                    {data.comment && (
                        <>
                            <br />
                            <I18n
                                t="plannings.view.history.action.status_comment"
                                args={data.comment}
                            />
                        </>
                    )}
                </td>
            </tr>
        );
    }

    let actionParameters = actionsParameters[action];
    if (!actionParameters) {
        console.error(`undefined history action: ${action}`);
        return <></>;
    }

    return (
        <tr>
            <td style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                    icon={actionParameters.icon}
                    className={actionParameters.colorClass}
                    size="lg"
                />
            </td>
            <td>
                <span style={{ fontWeight: "bold" }}>{taskTitle}</span>
                <br />
                <span className="mr-1">
                    <I18n t={actionParameters.translation} />
                </span>
                <span className="mr-1">{userName}</span>
                <I18nRelativeDate
                    date={createdAt}
                    defaultLocale={defaultLocale}
                />
            </td>
        </tr>
    );
};

export default Row;
