import React from "react";
import UserRoleManagement from "./UserRoleManagement";
import { useLocation } from "react-router";

const EnhancedUserRoleManagement = (props) => {
    const location = useLocation();
    return <UserRoleManagement {...props} location={location} />;
};

export default EnhancedUserRoleManagement;
