import React, { useState, useEffect, useCallback } from "react";
import Genre from "./Genre";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import {
    GENRE_TYPE_MAIN,
    GENRE_TYPE_PRINCIPAL,
} from "shared/constants/cms/artist";

const schema = yup.object().shape({
    genre: yup
        .array()
        .of(
            yup.object().shape({
                id_genre: yup.number().required("utils.form.required"),
                type_relation: yup.string().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedGenre = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            genre: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "genre",
    });
    const watchGenre = watch("genre");

    const getGenre = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/genre`)
            .then((response) => {
                if (!cancelled) {
                    reset({ genre: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getGenre, [getGenre]);

    const [genreOptions, setGenreOptions] = useState([]);
    const getGenreOptions = useCallback(() => {
        api.get(`genre/${locale}`)
            .then((response) => {
                setGenreOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getGenreOptions, [getGenreOptions]);

    const getGenreOptionsByIndex = useCallback(
        (index) => {
            const type = watchGenre[index]?.type_relation;
            return genreOptions
                .filter(({ id_parent }) => {
                    if (type === GENRE_TYPE_MAIN) {
                        return id_parent === 0;
                    } else if (type === GENRE_TYPE_PRINCIPAL) {
                        return id_parent !== 0;
                    }
                    return true;
                })
                .map(({ id, nom }) => {
                    return {
                        value: id,
                        label: nom,
                    };
                });
        },
        [watchGenre, genreOptions]
    );

    const [genreTypeOptions, setGenreTypeOptions] = useState([]);
    const getGenreType = useCallback(() => {
        api.get(`artist/genre-type/${locale}`)
            .then((response) => {
                setGenreTypeOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getGenreType, [getGenreType]);

    const onSubmit = (data) => {
        return api
            .post(`artist/${artistId}/genre`, { locale }, data.genre)
            .then((response) => {
                reset({ genre: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.genre.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.genre.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Genre
            {...props}
            getGenre={getGenre}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
            genreOptions={genreOptions}
            getGenreOptionsByIndex={getGenreOptionsByIndex}
            genreTypeOptions={genreTypeOptions}
        />
    );
};

export default EnhancedGenre;
