import React from "react";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Picture from "./Picture";
import EditWizard from "shared/components/EditWizard";
import BaS from "shared/components/Picture/Getty/BaS";
import "./Getty.css";

const Getty = ({
    isLoading,
    selectedPicture,
    setSelectedPicture,
    currentTab,
    setCurrentTab,
    gettyPicture,
    onEdit,
    onAdd,
    pictures,
    defaultSearch,
    getPictures,
    miscEntityId,
    isEdit,
}) => {
    return (
        <div className="misc-entity-management-getty">
            <EditWizard
                pictureId={selectedPicture.pictureId}
                versionId={selectedPicture.versionId}
                setSelectedPicture={setSelectedPicture}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                gettyPicture={gettyPicture}
                onSuccess={getPictures}
                creationParams={{ id_misc_entity: miscEntityId }}
                isEdit={isEdit}
                isShows={true}
                isGetty
            />
            <div className="row">
                <div className="col">
                    <Widget
                        title={`misc_entity.management.imageGetty.gallery.head`}
                        isLoading={isLoading}
                        enableCollapse
                    >
                        <Widget.Body className="border-top">
                            {pictures.length === 0 ? (
                                <h3 className="text-center mt-4">
                                    <I18n t="misc_entity.management.imageGetty.gallery.empty" />
                                </h3>
                            ) : (
                                <div className="grid mt-4">
                                    {pictures.map((picture) => (
                                        <Picture
                                            key={`${picture.id_picture}-${picture.id_version}`}
                                            picture={picture}
                                            onEdit={onEdit}
                                            getPictures={getPictures}
                                        />
                                    ))}
                                </div>
                            )}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <BaS defaultSearch={defaultSearch} onAdd={onAdd} />
                </div>
            </div>
        </div>
    );
};

export default Getty;
