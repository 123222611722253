import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useUser from "shared/hooks/useUser";

const MatomoProvider = ({ children }) => {
    const location = useLocation();
    const { trackPageView, pushInstruction } = useMatomo();
    const { user } = useUser();
    const [userId, setUserId] = useState();

    useEffect(() => {
        if (userId) {
            trackPageView({
                href: window.location.href,
                customDimensions: [
                    {
                        id: 2,
                        value: user.roles,
                    },
                    {
                        id: 3,
                        value: user.name,
                    },
                ],
            });
        }
    }, [location, userId]);

    useEffect(() => {
        const init = async () => {
            if (user.id !== null) {
                pushInstruction("setUserId", user.id);
                setUserId(user.id);
            }
        };
        init();
    }, [user.id]);

    return children;
};

export default MatomoProvider;
