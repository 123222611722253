import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";
import { useLocation } from "react-router";
import ExportButton from "./ExportButton";

const Chart = ({
    chart,
    year,
    chartType,
    availablePlatform,
    columns,
    isLoading,
    rowClasses,
    editable,
    pagination,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <ToolkitProvider keyField="rank" data={chart} columns={columns} search>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title={`cnm.charts.aggregate.chart.head`}
                            actions={
                                <>
                                    {editable &&
                                        year !== undefined &&
                                        chartType !== undefined && (
                                            <ExportButton
                                                year={year}
                                                chartType={chartType}
                                                availablePlatform={
                                                    availablePlatform
                                                }
                                            />
                                        )}
                                    {!isLoading && (
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    )}
                                </>
                            }
                        >
                            <Widget.Body>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "cnm.charts.aggregate.chart.search"
                                            )}
                                        />
                                    </div>
                                </div>
                            </Widget.Body>
                            <Widget.Body className="widget-table-overflow mb-3">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        noDataIndication={
                                            <I18n t="cnm.charts.aggregate.chart.empty" />
                                        }
                                        classes="table-bordered-header-fixed"
                                        rowClasses={rowClasses}
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={chart.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default Chart;
