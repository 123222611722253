import { useParams } from "react-router";

const useIntParams = (defaultRadix = 10) => {
    const params = useParams();
    return Object.keys(params).reduce((acc, currentValue) => {
        const newValue = parseInt(params[currentValue], defaultRadix);
        if (isNaN(newValue)) {
            return { ...acc, [currentValue]: params[currentValue] };
        }
        return { ...acc, [currentValue]: newValue };
    }, {});
};

export default useIntParams;
