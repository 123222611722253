import React from "react";
import GenreList from "./GenreList";
import MatchingList from "./MatchingList";

const CNMGenres = ({
    isLoading,
    cnmGenres,
    msMainGenres,
    matchings,
    filters,
    fetchGenres,
    setMatchings,
    setFilters,
    year,
    editable,
}) => {
    return (
        <>
            <GenreList
                isLoading={isLoading}
                genres={cnmGenres}
                matchings={matchings}
                setFilters={setFilters}
            />
            <MatchingList
                isLoading={isLoading}
                matchings={matchings}
                cnmGenres={cnmGenres}
                msMainGenres={msMainGenres}
                filters={filters}
                fetchGenres={fetchGenres}
                setMatchings={setMatchings}
                setFilters={setFilters}
                year={year}
                editable={editable}
            />
        </>
    );
};

export default CNMGenres;
