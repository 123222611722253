import React, { useState } from "react";
import AddToPlanningButton from "./AddToPlanningButton";

const EnhancedAddToPlanningButton = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <AddToPlanningButton
            {...props}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
        />
    );
};

export default EnhancedAddToPlanningButton;
