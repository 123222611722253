import { createSelector } from "reselect";

export const getFilterOptions = createSelector(
    [(args) => args.gallery],
    (gallery) => {
        const groups = gallery.reduce((acc, { tags }) => {
            tags.forEach(({ group }) => {
                acc = [...new Set([...acc, group])].sort();
            });

            return acc;
        }, []);
        return groups.map((g) => {
            return {
                label: g,
                options: gallery.reduce((acc, { tags }) => {
                    tags.filter(({ group }) => group === g).forEach((tag) => {
                        acc = [...acc, tag]
                            .filter(
                                (row, index, self) =>
                                    self.findIndex(
                                        (t) => t.value === row.value
                                    ) === index
                            )
                            .sort((a, b) => a.label.localeCompare(b.label));
                    });

                    return acc;
                }, []),
            };
        });
    }
);

export const getFilteredGallery = createSelector(
    [
        (args) => args.gallery,
        (args) => args.selectedFilterOptions,
        (args) => args.failIds,
    ],
    (gallery, selectedFilterOptions, failIds) => {
        let filteredGallery = gallery.filter(
            ({ id_picture_version }) => !failIds.includes(id_picture_version)
        );

        if (selectedFilterOptions.length !== 0) {
            filteredGallery = filteredGallery.filter(({ tags }) => {
                return selectedFilterOptions.every((option) =>
                    tags.some((tag) => tag.value === option.value)
                );
            });
        }

        return filteredGallery;
    }
);
