import React from "react";
import Informations from "./Informations";
import { useParams, useLocation } from "react-router";
import useUser from "shared/hooks/useUser";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";

const EnhancedInformations = (props) => {
    const { locale } = useParams();
    const { user } = useUser();
    const location = useLocation();

    const showComment = (comment) => {
        swal(comment, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };

    return (
        <Informations
            locale={locale}
            user={user}
            showComment={showComment}
            {...props}
        />
    );
};

export default EnhancedInformations;
