import React from "react";
import Year from "./Year";
import PF from "./PF";
import Matching from "./Matching";

const Platform = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <PF />
                </div>
                <div className="col-6">
                    <Year />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Matching />
                </div>
            </div>
        </>
    );
};

export default Platform;
