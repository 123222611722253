import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import GenreSelect from "./GenreSelect";

const EnhancedGenreSelect = ({ year, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({ isLoading: false, genres: [] });

    const fetchGenres = useCallback(() => {
        if (!year) {
            setState({
                isLoading: false,
                genres: [],
            });
            return;
        }

        let cancelled = false;
        setState({
            isLoading: true,
            genres: [],
        });
        api.get(`cnm/report/consolidation/genres/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        genres: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        genres: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchGenres, [fetchGenres]);

    return <GenreSelect {...props} {...state} />;
};

export default EnhancedGenreSelect;
