import React, { useState, useEffect, useCallback } from "react";
import PictureMoveModal from "./PictureMoveModal";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";

const schema = yup.object().shape({
    artist: yup.object().nullable().required("utils.form.required"),
});

const defaultValues = {
    artist: null,
};

const EnhancedPictureMoveModal = ({
    id_picture,
    show,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        if (show) {
            reset(defaultValues);
        }
    }, [reset, show]);

    const onSubmit = (data) => {
        return api
            .post(
                `picture/${id_picture}/move`,
                { locale },
                { artistId: data.artist.id }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.PictureMoveModal.success"
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <PictureMoveModal
            {...props}
            show={show}
            errors={errors}
            isDirty={isDirty}
            control={control}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            locale={locale}
        />
    );
};

export default EnhancedPictureMoveModal;
