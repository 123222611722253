import React from "react";
import styles from "./Step.module.css";
import clsx from "clsx";

const Step = ({ active, completed, onClick, title, first, isLast, index }) => {
    return (
        <div className={clsx(styles.step)}>
            <div
                className={clsx(
                    styles.circle,
                    completed && styles.completedCircle,
                    active && styles.activeCircle
                )}
            >
                {active || completed ? (
                    <a
                        onClick={onClick}
                        className={clsx(styles.index)}
                        style={{ cursor: "pointer" }}
                    >
                        {index + 1}
                    </a>
                ) : (
                    <span
                        className={clsx(styles.index)}
                        style={{ cursor: "pointer" }}
                    >
                        {index + 1}
                    </span>
                )}
            </div>
            {active || completed ? (
                <a
                    onClick={onClick}
                    className={clsx(
                        styles.title,
                        completed && styles.completedTitle,
                        active && styles.activeTitle
                    )}
                    style={{ cursor: "pointer" }}
                >
                    {title}
                </a>
            ) : (
                <div
                    className={clsx(
                        styles.title,
                        completed && styles.completedTitle,
                        active && styles.activeTitle
                    )}
                    style={{ cursor: "pointer" }}
                >
                    {title}
                </div>
            )}
            {!first && (
                <div
                    className={clsx(
                        styles.leftBar,
                        (active || completed) && styles.completedBar
                    )}
                ></div>
            )}
            {!isLast && (
                <div
                    className={clsx(
                        styles.rightBar,
                        completed && styles.completedBar
                    )}
                ></div>
            )}
        </div>
    );
};

export default Step;
