import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useMemo,
} from "react";
import MatchV2 from "./MatchV2";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    ArtistManagementContext,
    BAS_UP_TO_DATE,
} from "pages/ArtistManagement";

const EnhancedMatchV2 = ({ partner, ...props }) => {
    const { dispatchArtist } = useContext(ArtistManagementContext);
    const { api } = useAPI();
    const location = useLocation();
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        isLoading: true,
        candidates: [],
    });

    const getMatchings = useCallback(() => {
        let cancelled = false;

        api.get(`matching/ms/${partner}/${artistId}`)
            .then((candidates) => {
                if (!cancelled) {
                    setState({
                        candidates,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        candidates: [],
                        isLoading: false,
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, partner]);

    useEffect(getMatchings, [getMatchings]);

    const onMatchingChange = useCallback(
        (candidateId, state) =>
            toast.promise(
                api.post(
                    `matching/ms/${partner}/${artistId}`,
                    {},
                    {
                        partnerID: candidateId,
                        state,
                    }
                ),
                {
                    pending: {
                        render() {
                            return (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        pulse={true}
                                    />
                                    <span className="ml-2">
                                        <I18n t="artist.management.match.pending" />
                                    </span>
                                </>
                            );
                        },
                        delay: undefined,
                    },
                    success: {
                        render(response) {
                            getMatchings();
                            dispatchArtist({
                                type: BAS_UP_TO_DATE,
                                data: false,
                            });
                            return I18n.getTranslation(
                                location,
                                "artist.management.match.success"
                            );
                        },
                    },
                    error: {
                        render(error) {
                            console.error(error);
                            return error.message;
                        },
                    },
                },
                {
                    delay: 500,
                }
            ),
        [artistId, partner]
    );

    const status = useMemo(() => {
        const matched = state.candidates.filter((row) => row.isMatching).length;

        if (matched == 0) {
            return "not_found";
        } else if (matched == 1) {
            return "matched";
        } else {
            return "duplicates";
        }
    }, [state.candidates]);

    return (
        <MatchV2
            {...props}
            {...state}
            status={status}
            partner={partner}
            onMatchingChange={onMatchingChange}
        />
    );
};

export default EnhancedMatchV2;
