import React from "react";
import Widget from "shared/components/Widget";
import SearchForm from "./SearchForm";
import Gallery from "./Gallery";

const BaS = ({
    total,
    pictures,
    isLoading,
    moreLoading,
    searchParams,
    setSearchParams,
    loadMore,
    onAdd,
}) => {
    return (
        <Widget
            title={`common.picture.getty.bas.head`}
            isLoading={isLoading}
            enableCollapse
        >
            <Widget.Body className="border-top">
                <SearchForm
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            </Widget.Body>
            <Widget.Body className="border-top">
                <Gallery
                    total={total}
                    pictures={pictures}
                    moreLoading={moreLoading}
                    loadMore={loadMore}
                    onAdd={onAdd}
                />
            </Widget.Body>
        </Widget>
    );
};

export default BaS;
