import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "shared/components/Loader";

const SampleList = ({ sample, columns, isLoading, selectRow }) => {
    return (
        <Widget
            title="apple.sample.management.sample.head"
            className="grid-bottom-left vertical-shrink mb-0 pb-0"
        >
            <Widget.Body
                className="widget-table-overflow overflow-auto"
                style={{
                    margin: "10px -20px 0px -20px",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <BootstrapTable
                        keyField="id"
                        data={sample}
                        columns={columns}
                        bootstrap4
                        hover
                        striped
                        bordered={false}
                        noDataIndication={
                            <I18n t="apple.sample.management.sample.empty" />
                        }
                        selectRow={selectRow}
                        classes="table-header-fixed table-selectable"
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default SampleList;
