import React, { useState } from "react";
import useIntParams from "shared/hooks/useIntParams";
import Stats from "./Stats";

const EnhancedStats = (props) => {
    const [manager, setManager] = useState([]);
    const { partnerId } = useIntParams();

    return (
        <Stats
            {...props}
            manager={manager}
            setManager={setManager}
            partnerId={partnerId}
        />
    );
};

export default EnhancedStats;
