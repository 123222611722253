import {
    Link as RRLink,
    NavLink as RRNavLink,
    Navigate as RRNavigate,
} from "react-router-dom";
import withLocale from "./withLocale";
import createI18n from "./createI18n";
import fr from "./locale/fr";
import en from "./locale/en";
import ja from "./locale/ja";
import de from "./locale/de";
import ko from "./locale/ko";

const locales = ["en", "fr", "ja", "de", "ko"];

const translations = {
    en: en,
    fr: fr,
    ja: ja,
    de: de,
    ko: ko,
};

const I18n = createI18n(locales, translations);

const Link = withLocale(RRLink);
const NavLink = withLocale(RRNavLink);
const Navigate = withLocale(RRNavigate);

export { I18n as default, translations, Link, NavLink, Navigate };
