import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Tracks from "./Tracks";

const EnhancedTracks = ({ ...props }) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "no_cd",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.no_cd"
            ),
            title: true,
            headerStyle: { width: "2em" },
        },
        {
            dataField: "no_piste",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.no_piste"
            ),
            title: true,
            // Sort by disc and track number
            sort: true,
            sortValue: (cell, row) => ({
                disc: row.no_cd,
                track: row.no_piste,
            }),
            sortFunc: (a, b, order) =>
                a.disc === b.disc
                    ? (order === "asc" ? 1 : -1) * (a.track - b.track)
                    : (order === "asc" ? 1 : -1) * (a.disc - b.disc),
            headerStyle: { width: "3em" },
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.isrc"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
            headerStyle: { width: "75%" },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.title"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
            headerStyle: { width: "100%" },
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.subtitle"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            sort: true,
            headerStyle: { width: "50%" },
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.duration"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "5em" },
        },
        {
            dataField: "hit",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.hit"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "3em" },
        },
        {
            dataField: "discovery",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.sandbox.tracklistComparison.tracks.discovery.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.tracklistComparison.tracks.discovery.title"
                ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "4em" },
        },
    ];

    const defaultSorted = [
        {
            dataField: "no_piste",
            order: "asc",
        },
    ];

    return (
        <Tracks {...props} columns={columns} defaultSorted={defaultSorted} />
    );
};

export default EnhancedTracks;
