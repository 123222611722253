import React, { useState, useEffect } from "react";
import Images from "./Images";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    gallery: true,
};

const EnhancedImages = (props) => {
    const { artistId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [artistId]);

    return <Images {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedImages;
