import React, { useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import MatchSelectionButton from "./MatchSelectionButton";

const EnhancedMatchSelectionButton = ({
    matchings,
    setMatchings,
    ...props
}) => {
    const location = useLocation();

    const [{ show, selectedMatchings }, setState] = useState({
        show: false,
        selectedMatchings: [],
    });

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isSubmitting, isDirty },
    } = useForm({
        defaultValue: { genre: null },
    });
    const genre = watch("genre");

    const modifiedMatchings = useMemo(
        () =>
            selectedMatchings.filter(
                ({ cnmId }) => cnmId !== null && cnmId !== genre
            ),
        [selectedMatchings, genre]
    );

    const onShow = (selectedMatchingIds) => {
        const selectedMatchings = matchings.filter(({ msId }) =>
            selectedMatchingIds.includes(msId)
        );

        // If all the selected matchings have the same CNM genre, use this genre
        // as the default value.
        const genres = new Set(
            selectedMatchings.map(({ cnmId }) => cnmId).filter(Boolean)
        );
        reset({ genre: genres.size === 1 ? [...genres][0] : null });

        setState({
            show: true,
            selectedMatchings,
        });
    };
    const onHide = () => {
        setState((state) => ({ ...state, show: false }));
    };

    const onSubmit = ({ genre }) =>
        setMatchings(
            selectedMatchings.map(({ msId }) => msId),
            genre
        ).then(onHide);

    return (
        <MatchSelectionButton
            show={show}
            genre={genre}
            control={control}
            modifiedMatchings={modifiedMatchings}
            onShow={onShow}
            onHide={onHide}
            location={location}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            {...props}
        />
    );
};

export default EnhancedMatchSelectionButton;
