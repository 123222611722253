import React, { useState, useEffect } from "react";
import Authorization from "./Authorization";
import { useMK } from "shared/hooks/useMK";

const EnhancedAuthorization = (props) => {
    const [state, setState] = useState({
        hasAuthorization: false,
    });
    const mk = useMK();

    useEffect(() => {
        setState({
            ...state,
            hasAuthorization: mk.instance.isAuthorized,
        });
    }, []);

    const login = async () => {
        await mk.instance.authorize();
        window.location.reload();
    };

    const logout = async () => {
        await mk.instance.unauthorize();
        window.location.reload();
    };

    return (
        <Authorization {...state} login={login} logout={logout} {...props} />
    );
};

export default EnhancedAuthorization;
