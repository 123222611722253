import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import APIDownloadLink from "shared/components/APIDownloadLink";

const Indicators = ({ year, chartType }) => {
    const rows = ["top_titles", "top_artists", "indicators"].map((file) => (
        <tr key={file}>
            <td>
                <APIDownloadLink
                    route={`cnm/report/indicators/${file}/${year}/${chartType}`}
                    filename={`cnm_${file}_${year}_${chartType}.xlsx`}
                >
                    <I18n t={`cnm.report.indicators.${file}`} />
                </APIDownloadLink>
            </td>
        </tr>
    ));
    return (
        <div className="row">
            <div className="col">
                <Widget title="cnm.report.indicators.title" className="pb-0">
                    <Widget.Body
                        className="widget-table-overflow"
                        style={{
                            margin: "10px -20px 0px -20px",
                            maxHeight: "200px",
                            overflow: "auto",
                        }}
                    >
                        <table
                            className="table table-striped"
                            style={{ marginBottom: "0px" }}
                        >
                            <tbody>{rows}</tbody>
                        </table>
                    </Widget.Body>
                </Widget>
            </div>
        </div>
    );
};

export default Indicators;
