import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";

const Create = ({
    show,
    handleClose,
    handleShow,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
}) => {
    return (
        <>
            <Button
                variant="success"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.album.cover.create.title"
                )}
            >
                <I18n t="utils.button.add" />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="artist.management.disco.objects.album.cover.create.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <Form.Group>
                            <Form.Label>
                                <I18n t="artist.management.disco.objects.album.cover.url" />
                            </Form.Label>
                            <InputText
                                error={
                                    errors.url && [
                                        I18n.getTranslation(
                                            location,
                                            errors.url.message
                                        ),
                                    ]
                                }
                                {...register("url")}
                            />
                        </Form.Group>
                        <div className="d-flex flex-row-reverse pb-3">
                            <Button
                                variant="success"
                                disabled={isSubmitting || !isDirty}
                                onClick={
                                    !isSubmitting && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.disco.objects.album.cover.create.title`
                                )}
                            >
                                <I18n t={`utils.button.add`} />
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Create;
