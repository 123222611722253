import React, { useEffect, useCallback } from "react";
import Translation from "./Translation";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import swal from "sweetalert";

const defaultValues = {
    id_lang: null,
};

const schema = yup.object().shape({
    id_lang: yup.string().nullable().required("utils.form.required"),
});

const EnhancedTranslation = ({
    lang,
    biographyLangOptions,
    updateBiography,
    isReference,
    isOfficial,
    setToTranslate,
    ...props
}) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const location = useLocation();
    const {
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const getTranslation = () => {
        return api
            .get(`artist/${artistId}/biography/${lang}/translate`)
            .then((response) => {
                if (response?.text) {
                    updateBiography(response.text, 1);
                    setToTranslate(false);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onSubmit = () => {
        const langs = biographyLangOptions();
        if (langs.some(({ value }) => value === lang)) {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.bio.translation.alert.title"
                ),
                text: I18n.getTranslation(
                    location,
                    "artist.management.bio.translation.alert.text"
                ),
                icon: "error",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    return;
                }

                return getTranslation();
            });
        } else {
            return getTranslation();
        }
    };

    const getBiographyLangFrom = useCallback(() => {
        api.get(`artist/${artistId}/biography/${lang}/translation`)
            .then((response) => {
                if (response?.id_lang) {
                    reset({ id_lang: response.id_lang });
                    setToTranslate(response.to_translate);
                } else {
                    reset(defaultValues);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [biographyLangOptions, isReference, isOfficial]);

    useEffect(getBiographyLangFrom, [getBiographyLangFrom]);

    const updBiographyLangFrom = useCallback((id_lang_from) => {
        api.post(
            `artist/${artistId}/biography/${lang}/translation`,
            {},
            { id_lang_from }
        ).catch((error) => {
            console.error(error);
            toast.error(error.message);
        });
    }, []);

    return (
        <Translation
            {...props}
            location={location}
            control={control}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            biographyLangOptions={biographyLangOptions}
            updBiographyLangFrom={updBiographyLangFrom}
            lang={lang}
            isReference={isReference}
            setToTranslate={setToTranslate}
        />
    );
};

export default EnhancedTranslation;
