import React, { useMemo } from "react";
import Albums from "./Albums";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import AlbumListAction from "shared/components/AlbumListAction";
import ImageLoader from "shared/components/ImageLoader";
import ArtistLink from "shared/components/ArtistLink";
import placeholder from "assets/static/images/logo_placeholder.png";
import clsx from "clsx";
import useQuery from "shared/hooks/useQuery";
import { displayAsString } from "shared/functions/Date";

const EnhancedAlbums = ({ albums, limit, ...props }) => {
    const { locale } = useParams();
    const location = useLocation();
    const query = useQuery();

    const sliceData = useMemo(() => {
        if (limit) {
            return albums.slice(0, limit);
        }
        return albums;
    }, [albums, limit]);

    const columns = [
        {
            dataField: "cover",
            text: I18n.getTranslation(location, "search.albumsData.cover"),
            formatter: (cell) => {
                return (
                    <ImageLoader
                        src={cell}
                        fallbackSrc={placeholder}
                        className={clsx(
                            !cell && "cover-placeholder",
                            "cover-album"
                        )}
                    />
                );
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "id",
            text: I18n.getTranslation(location, "search.albumsData.id"),
            formatter: (cell) => {
                return cell ? <strong>{cell}</strong> : "-";
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "search.albumsData.title"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "artists",
            text: I18n.getTranslation(location, "search.albumsData.artist"),
            formatter: (cell) =>
                cell.map((artist, index) => (
                    <React.Fragment key={artist.id}>
                        {index !== 0 && <span className="mx-1">&bull;</span>}
                        <ArtistLink
                            artistId={artist.id}
                            content={artist.name}
                            view
                        />
                    </React.Fragment>
                )),
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "search.albumsData.type.head"),
            formatter: (cell) => (
                <I18n t={`search.albumsData.type.${cell?.toLowerCase()}`} />
            ),
        },
        {
            dataField: "format",
            text: I18n.getTranslation(
                location,
                "search.albumsData.format.head"
            ),
            formatter: (cell) => (
                <I18n t={`search.albumsData.format.${cell?.toLowerCase()}`} />
            ),
        },
        !limit && {
            dataField: "genre",
            text: I18n.getTranslation(location, "search.albumsData.genre"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
        },
        !limit && {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "search.albumsData.release_date"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                if (cell) {
                    return displayAsString(cell, locale);
                } else {
                    return "-";
                }
            },
            formatExtraData: {
                locale,
            },
        },
        !limit && {
            dataField: "label",
            text: I18n.getTranslation(location, "search.albumsData.label"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(location, "search.artistsData.actions"),
            formatter: (cell, { id, artists }) => {
                return (
                    <AlbumListAction artistId={artists[0].id} albumId={id} />
                );
            },
            headerAlign: "right",
            align: "right",
        },
    ].filter(Boolean);

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        totalSize: sliceData.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Albums
            {...props}
            albums={sliceData}
            columns={columns}
            limit={limit}
            pagination={pagination}
            location={location}
            query={query}
        />
    );
};

export default EnhancedAlbums;
