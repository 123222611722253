import React, { useState, useEffect, useCallback } from "react";
import Coverage from "./Coverage";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedCoverage = ({ startDate, endDate, ...props }) => {
    const [coverage, setCoverage] = useState({
        simple: [],
        creation: [],
        new_lyrics: [],
        discarded_full: [],
        forgot_full: [],
        forgot_lf: [],
        forgot_2_3: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const { api } = useAPI();

    const exportName = `${startDate}-${endDate}_${I18n.getTranslation(
        location,
        "data_external.deezer_top_songs.breadcrumb"
    )}_${I18n.getTranslation(
        location,
        "data_external.deezer_top_songs.coverage.nav"
    )}`;

    const getCoverage = useCallback(() => {
        setIsLoading(true);
        api.get(
            `data/external/deezer/top/songs/coverage/${startDate}/${endDate}`
        )
            .then((response) => {
                setCoverage(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
                setIsError(true);
            });
    }, [startDate, endDate]);

    useEffect(getCoverage, [getCoverage]);

    return (
        <Coverage
            {...props}
            coverage={coverage}
            isLoading={isLoading}
            isError={isError}
            exportName={exportName}
        />
    );
};

export default EnhancedCoverage;
