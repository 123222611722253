import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import Partner from "./Partner";
import List from "./List";
import { PARTNERS_TRACK_COMPONENT } from "pages/MatchingTrack";
import { Helmet } from "react-helmet";

const MatchingTrack = ({ partnerId, listId, location }) => {
    const Track = PARTNERS_TRACK_COMPONENT[partnerId];
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.track"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.matching.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/matching/track">
                            <I18n t="base.nav.matching.track" />
                        </NavLink>
                    </li>
                </ol>

                <div className="row">
                    <div className="col-md-4">
                        <Partner />
                    </div>
                    {partnerId && (
                        <div className="col">
                            <List />
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col">{listId && <Track />}</div>
                </div>
            </main>
        </div>
    );
};

export default MatchingTrack;
