import React from "react";
import {
    Chart as ChartJS,
    LinearScale,
    TimeScale,
    BarElement,
    Filler,
    Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";

ChartJS.register(LinearScale, TimeScale, BarElement, Filler, Tooltip);

const Chart = ({ data, options, plugins }) => {
    return <Bar data={data} options={options} plugins={plugins} />;
};

export default Chart;
