import React, { useState, useEffect, useCallback } from "react";
import Artists from "./Artists";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

const defaultValues = {
    isLoading: false,
    artists_creation: [],
    artists_with_photo: [],
    artists_with_bio: [],
};

const EnhancedArtists = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState(defaultValues);
    const { locale } = useParams();
    const location = useLocation();

    const getArtistsStats = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`statistics/ms/artists-stats`)
            .then((response) => {
                setState({
                    isLoading: false,
                    ...response,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultValues);
            });
    }, []);

    useEffect(getArtistsStats, [getArtistsStats]);

    const [biographiesLang, setBiographiesLang] = useState([]);
    const getBiographiesLang = useCallback(() => {
        api.get(`statistics/ms/biographies-lang`, {
            locale,
        })
            .then((response) => {
                setBiographiesLang(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getBiographiesLang, [getBiographiesLang]);

    return (
        <Artists
            {...props}
            {...state}
            biographiesLang={biographiesLang}
            location={location}
        />
    );
};

export default EnhancedArtists;
