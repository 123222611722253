import React from "react";
import ImageUploadModal from "shared/components/PictureModal/ImageUploadModal";
import PictureInformationModal from "shared/components/PictureModal/PictureInformationModal";
import PictureInformationModalGetty from "shared/components/PictureModal/Getty/PictureInformationModalGetty";
import PictureTagsModal from "shared/components/PictureModal/PictureTagsModal";
import ImageCropModal from "shared/components/PictureModal/ImageCropModal";

const EditWizard = ({
    pictureId,
    versionId,
    currentTab,
    setCurrentTab,
    gettyPicture,
    creationParams,
    onUploadSuccess,
    onInformationSuccess,
    onCropSuccess,
    onTagsVersionSuccess,
    returnToInformation,
    returnToCrop,
    returnToTagging,
    isEdit,
    isShows,
    isGetty,
}) => {
    return (
        <>
            <ImageUploadModal
                show={currentTab == "Upload"}
                handleHide={() => setCurrentTab(null)}
                onSuccess={onUploadSuccess}
                creationParams={creationParams}
                isGetty={isGetty}
                gettyPicture={gettyPicture}
            />

            {isGetty ? (
                <PictureInformationModalGetty
                    show={currentTab == "Legal"}
                    pictureId={pictureId}
                    versionId={versionId}
                    handleHide={() => setCurrentTab(null)}
                    onSuccess={onInformationSuccess}
                    returnToCrop={returnToCrop}
                    returnToTagging={returnToTagging}
                />
            ) : (
                <PictureInformationModal
                    show={currentTab == "Legal"}
                    pictureId={pictureId}
                    versionId={versionId}
                    handleHide={() => setCurrentTab(null)}
                    onSuccess={onInformationSuccess}
                    returnToCrop={returnToCrop}
                    returnToTagging={returnToTagging}
                />
            )}

            <ImageCropModal
                show={currentTab == "Cropping"}
                pictureId={pictureId}
                versionId={versionId}
                handleHide={() => setCurrentTab(null)}
                onSuccess={onCropSuccess}
                returnToInformation={returnToInformation}
                returnToTagging={returnToTagging}
            />

            <PictureTagsModal
                id_picture={pictureId}
                id_picture_version={versionId}
                show={currentTab == "Tagging"}
                handleHide={() => setCurrentTab(null)}
                onSuccess={onTagsVersionSuccess}
                returnToInformation={returnToInformation}
                returnToCrop={returnToCrop}
                isEdit={isEdit}
                isVersion
                isShows={isShows}
            />
        </>
    );
};

export default EditWizard;
