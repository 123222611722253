import React from "react";
import Pagination from "react-js-pagination";
import I18n from "shared/lib/I18n";

const Footer = ({ totalItemsCount, handlePageChange, activePage }) => {
    return (
        <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: "4.25rem" }}
        >
            <div>
                {totalItemsCount}{" "}
                <I18n t="plannings.manage.planning.itemsCount" />
            </div>
            {totalItemsCount > 10 && (
                <div>
                    <Pagination
                        innerClass="pagination mt-3"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeLinkClass="active"
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
};

export default Footer;
