import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const Names = ({
    names,
    columns,
    selectRow,
    show,
    toggleShow,
    isSubmitting,
    selected,
    handleSubmit,
}) => {
    return (
        <Modal show={show} onHide={toggleShow} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.wikipedia.names.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <BootstrapTable
                    keyField="id_lang"
                    data={names}
                    columns={columns}
                    bootstrap4
                    hover
                    bordered={false}
                    classes="table-header-fixed table-selectable"
                    selectRow={selectRow}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleShow}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || selected.length === 0}
                    onClick={
                        !isSubmitting && selected.length !== 0
                            ? handleSubmit
                            : null
                    }
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t={`utils.button.save`} />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Names;
