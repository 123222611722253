import React, { useEffect, useState, useRef } from "react";
import MultiSearch from "./MultiSearch";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import useOnClickOutside from "shared/hooks/useOnClickOutside";
import useOnScroll from "shared/hooks/useOnScroll";
import Mousetrap from "mousetrap";

const defaultValues = {
    q: "",
};

const defaultState = {
    artists: [],
    albums: [],
    recordings: [],
    isLoading: false,
    isOpen: false,
    isTouched: false,
};

const schema = yup.object().shape({
    q: yup.string().trim(),
});

const EnhancedMultiSearch = ({ shortcut, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();
    const [
        { artists, albums, recordings, isLoading, isOpen, isTouched },
        setState,
    ] = useState(defaultState);
    const inputGroupRef = useRef(null);
    const dataRef = useRef(null);
    const {
        register,
        watch,
        reset,
        handleSubmit,
        setFocus,
        formState: { isSubmitting },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const qWatch = watch("q");

    const load = useDebouncedCallback((q, cancelled) => {
        if (q) {
            if (!cancelled) {
                setState((prev) => ({
                    ...prev,
                    isLoading: true,
                    isOpen: true,
                    isTouched: true,
                }));
            }
            api.get("searchengine/multi-search", {
                q,
                artistLimit: 2,
                albumLimit: 3,
                recordingLimit: 4,
            })
                .then(({ results }) => {
                    if (!cancelled) {
                        setState((prev) => ({
                            ...prev,
                            artists: results.find(
                                (r) => r.indexUid === "artist"
                            ).hits,
                            albums: results.find((r) => r.indexUid === "album")
                                .hits,
                            recordings: results.find(
                                (r) => r.indexUid === "recording"
                            ).hits,
                            isLoading: false,
                        }));
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                    }
                });
        } else {
            if (!cancelled) {
                setState((prev) => ({
                    ...defaultState,
                    isOpen: prev.isTouched,
                }));
            }
        }
    }, 250);

    const onSubmit = ({ q }) => {
        if (q) {
            const params = new URLSearchParams({ q }).toString();
            navigate(`/${locale}/search?${params}`);
        }
    };

    const focusInput = (e) => {
        e.preventDefault();
        setFocus("q");
        handleFocus();
    };

    const handleReset = () => {
        setState(defaultState);
        reset(defaultValues);
    };

    const handleFocus = () => {
        setState((prev) => ({ ...prev, isOpen: true }));
    };

    useEffect(() => {
        if (shortcut) {
            Mousetrap.bind(["ctrl+k", "command+k"], focusInput);
            Mousetrap.bind(["esc", "escape"], handleReset);
        }
        return () => {
            if (shortcut) {
                Mousetrap.unbind(["ctrl+k", "command+k"]);
                Mousetrap.unbind(["esc", "escape"]);
            }
        };
    }, [shortcut]);

    useEffect(() => {
        let cancelled = false;

        load(qWatch, cancelled);

        return () => {
            cancelled = true;
        };
    }, [qWatch]);

    useOnClickOutside([inputGroupRef, dataRef], () => {
        if (isOpen) {
            setState((prev) => ({
                ...prev,
                isOpen: false,
            }));
        }
    });

    useOnScroll(() => {
        if (isOpen) {
            setState((prev) => ({
                ...prev,
                isOpen: false,
            }));
        }
    });

    return (
        <MultiSearch
            {...props}
            q={qWatch}
            artists={artists}
            albums={albums}
            recordings={recordings}
            isLoading={isLoading}
            isOpen={isOpen}
            isTouched={isTouched}
            inputGroupRef={inputGroupRef}
            dataRef={dataRef}
            handleReset={handleReset}
            handleFocus={handleFocus}
            register={register}
            location={location}
            locale={locale}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            shortcut={shortcut}
        />
    );
};

export default EnhancedMultiSearch;
