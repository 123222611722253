import React from "react";
import Widget from "shared/components/Widget";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import moment from "moment";
import { NavLink } from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import {
    SUBTASK_STATUS_ICON,
    SUBTASK_STATUS_COLOR,
} from "shared/constants/Planning/SubTask";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import "./Informations.css";

const Informations = ({ lyric, isLoading, locale, user, showComment }) => {
    return (
        <Widget
            title={`apple.sample.assessment.informations.head`}
            className="mb-3 apple__sample__assessment__informations__widget"
            actions={
                lyric.appleId && (
                    <a
                        href={`https://music.apple.com/song/${lyric.appleId}`}
                        className="btn btn-info"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faApple}
                            className="mr-2"
                            fixedWidth
                        />
                        Apple Music
                    </a>
                )
            }
            enableCollapse
        >
            <Widget.Body className="border-top pt-3">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {user.hasRight(
                            "apple.sample.assessment.manage",
                            "apple.sample.assessment.edito"
                        ) &&
                            lyric.planning?.map((task, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <div className="row">
                                            <div className="col-2">
                                                <strong>
                                                    <I18n t="apple.sample.assessment.informations.planning" />
                                                </strong>
                                                <br />
                                                <span>
                                                    {user.hasRight(
                                                        "plannings.manage"
                                                    ) ? (
                                                        <NavLink
                                                            to={`/plannings/${task.planning_id}/${PLANNING_PARTNER.ALL}`}
                                                            target="__blank"
                                                        >
                                                            {`${task.start_date} - ${task.end_date}`}
                                                        </NavLink>
                                                    ) : task.start_date ? (
                                                        `${task.start_date} - ${task.end_date}`
                                                    ) : (
                                                        "-"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <strong>
                                                    <I18n t="apple.sample.assessment.informations.manager" />
                                                </strong>
                                                <br />
                                                <span>
                                                    {task.created_by ?? "-"}
                                                </span>
                                            </div>
                                            <div className="col align-self-center">
                                                <div className="btn-group btn-group-sm">
                                                    <span
                                                        className={`btn btn-${
                                                            SUBTASK_STATUS_COLOR[
                                                                task.status
                                                            ]
                                                        }`}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="mr-2"
                                                            icon={
                                                                SUBTASK_STATUS_ICON[
                                                                    task.status
                                                                ]
                                                            }
                                                            fixedWidth
                                                        />
                                                        {`${task.type} - `}
                                                        <I18n
                                                            t={`apple.sample.assessment.informations.status.${task.status}`}
                                                        />
                                                    </span>
                                                    {task.comment && (
                                                        <button
                                                            type="button"
                                                            className={`btn btn-${
                                                                SUBTASK_STATUS_COLOR[
                                                                    task.status
                                                                ]
                                                            } border-left`}
                                                            onClick={() =>
                                                                showComment(
                                                                    task.comment
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faComment}
                                                                fixedWidth
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                );
                            })}
                        <div className="row">
                            <div className="col-1">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.catalogType" />
                                </strong>
                                <br />
                                <span>{lyric.catalogType ?? "-"}</span>
                            </div>
                            <div className="col-1">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.month" />
                                </strong>
                                <br />
                                <span className="capitalize">
                                    {lyric.month
                                        ? moment(lyric.month, "MM")
                                              .locale(locale)
                                              .format("MMMM")
                                        : "-"}
                                </span>
                            </div>
                            <div className="col-2">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.artist" />
                                </strong>
                                <br />
                                <p
                                    className="mb-0 text-truncate"
                                    title={lyric.artist ?? "-"}
                                >
                                    {lyric.artist ?? "-"}
                                </p>
                            </div>
                            <div className="col-2">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.album" />
                                </strong>
                                <br />
                                <p
                                    className="mb-0 text-truncate"
                                    title={lyric.album ?? "-"}
                                >
                                    {lyric.album ?? "-"}
                                </p>
                            </div>
                            <div className="col-2">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.title" />
                                </strong>
                                <br />
                                <p
                                    className="mb-0 text-truncate"
                                    title={lyric.title ?? "-"}
                                >
                                    {lyric.title ?? "-"}
                                </p>
                            </div>
                            {/* <div className="col-1">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.duration" />
                                </strong>
                                <br />
                                <span>{lyric.duration ?? "-"}</span>
                            </div> */}
                            <div className="col-2">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.isrc" />
                                </strong>
                                <br />
                                <span>{lyric.isrc ?? "-"}</span>
                            </div>
                            <div className="col-1">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.genre" />
                                </strong>
                                <br />
                                <p
                                    className="mb-0 text-truncate"
                                    title={lyric.genre ?? "-"}
                                >
                                    {lyric.genre ?? "-"}
                                </p>
                            </div>
                            <div className="col-1">
                                <strong>
                                    <I18n t="apple.sample.assessment.informations.language" />
                                </strong>
                                <br />
                                <span>{lyric.language ?? "-"}</span>
                            </div>
                            {/* <div className="col">
                            <strong>
                                <I18n t="apple.sample.assessment.informations.country" />
                            </strong>
                            <br />
                            <span>{lyric.country ?? "-"}</span>
                        </div> */}
                        </div>
                    </>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Informations;
