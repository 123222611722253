import React from "react";
import { createRoot } from "react-dom/client";
import App from "layout/App";
import "assets/static/styles/app.css";
import "assets/static/styles/colors.css";
import * as serviceWorker from "serviceWorker";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { GoogleReCaptchaProvider } from "@google-recaptcha/react";

const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_URL,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.REACT_APP_MATOMO_URL}/matomo.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_URL}/matomo.js`,
    disabled:
        !process.env.REACT_APP_MATOMO_DISABLED ||
        String(process.env.REACT_APP_MATOMO_DISABLED) === "true",
    heartBeat: {
        active: true,
        seconds: 10,
    },
    linkTracking: false,
    configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: "POST",
    },
});

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
    <MatomoProvider value={instance}>
        <GoogleReCaptchaProvider
            type="v2-checkbox"
            siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            isEnterprise
        >
            <App />
        </GoogleReCaptchaProvider>
    </MatomoProvider>
);

serviceWorker.unregister();
