import React, { useState, useEffect, useCallback, useRef } from "react";
import AlbumView from "./AlbumView";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import useUser from "shared/hooks/useUser";
import { AlbumViewProvider } from "./AlbumView.provider";

const defaultHasData = {
    releases: true,
};

const EnhancedAlbumView = (props) => {
    const { user } = useUser();
    const { api } = useAPI();
    const { locale, selectedTab } = useParams();
    const location = useLocation();
    const { albumId } = useIntParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        album: {},
    });
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [albumId]);
    const onSelectTab = (k) => {
        navigate(`/${locale}/album/${albumId}/view/${k}`);
    };

    const { ref: headerObserverRef, inView: headerObserverInView } = useInView({
        threshold: 1,
    });

    const containerRef = useRef(null);
    const [containerTopOffset, setContainerTopOffset] = useState();

    useEffect(() => {
        if (containerRef) {
            setContainerTopOffset(
                containerRef.current.getBoundingClientRect().top
            );
        }
    }, [containerRef, headerObserverInView, setContainerTopOffset]);

    const getAlbum = useCallback(() => {
        api.get(`album/${albumId}`, { locale })
            .then((response) => {
                setState({ album: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId, locale]);

    useEffect(getAlbum, [getAlbum]);

    const redirectToEdit = () => {
        navigate(
            `/${locale}/artist/${state.album.artists[0].id}/edit/discography/album/${albumId}`
        );
    };

    return (
        <AlbumViewProvider>
            <AlbumView
                {...props}
                {...state}
                headerObserverRef={headerObserverRef}
                isHeaderSticky={!headerObserverInView}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                locale={locale}
                location={location}
                containerRef={containerRef}
                containerTopOffset={containerTopOffset}
                redirectToEdit={redirectToEdit}
                hasData={hasData}
                setNoData={setNoData}
                user={user}
            />
        </AlbumViewProvider>
    );
};

export default EnhancedAlbumView;
