import React from "react";
import { useWatch } from "react-hook-form";
import ISRCReference from "./ISRCReference";

const EnhancedISRCReference = ({ control, name, isrcs, ...props }) => {
    const selectedISRCs = useWatch({
        control,
        name,
        defaultValue: [],
    });
    const unselectedISRCs = [
        ...new Set(
            isrcs
                ?.map(({ isrc }) => isrc)
                ?.filter((isrc) => !selectedISRCs.includes(isrc))
        ),
    ];
    return (
        <ISRCReference
            isrcs={isrcs ?? []}
            selectedISRCs={selectedISRCs ?? []}
            unselectedISRCs={unselectedISRCs ?? []}
            {...props}
        />
    );
};

export default EnhancedISRCReference;
