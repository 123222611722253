import React, { useEffect } from "react";
import { Popover, Table } from "react-bootstrap";
import clsx from "clsx";
import moment from "moment";
import I18n from "shared/lib/I18n";
import ImageLoader from "shared/components/ImageLoader";
import "./ChartPopover.css";

const ChartPopover = React.forwardRef(
    ({ date, dateFormat, popper, className, locale, ...props }, ref) => {
        // Force the update when the date changes (this is necessary because the
        // target's position has changed).
        useEffect(() => {
            popper.scheduleUpdate();
        }, [date, popper]);

        return (
            <Popover
                ref={ref}
                className={clsx(
                    className,
                    "artist-view-popularity-histogram-popover"
                )}
                {...props}
            >
                <Popover.Title as="h3" className="text-capitalize">
                    {moment(date?.date).locale(locale).format(dateFormat)}
                </Popover.Title>
                <Popover.Content>
                    {date === null || date.charts === 0 ? (
                        <I18n t="artist.view.popularityHistogram.popover.noData" />
                    ) : (
                        <div className="d-flex">
                            {date?.cover !== null &&
                                date?.cover !== undefined && (
                                    <ImageLoader
                                        className="cover-art mr-2"
                                        src={date?.cover}
                                    />
                                )}
                            <Table borderless className="mb-0">
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            <I18n t="artist.view.popularityHistogram.popover.popularity" />
                                        </th>
                                        <td>
                                            {Math.round(date?.score)} %
                                            {date?.rank !== null && (
                                                <>
                                                    {" ("}
                                                    <I18n
                                                        t="utils.nth"
                                                        args={date?.rank}
                                                    />
                                                    {")"}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    {date?.mostPopularTitle !== null && (
                                        <tr className="border-top">
                                            <th scope="row">
                                                <I18n t="artist.view.popularityHistogram.popover.mostPopularTitle" />
                                            </th>
                                            <td>{date?.mostPopularTitle}</td>
                                        </tr>
                                    )}
                                    {date?.bestRank !== null && (
                                        <>
                                            <tr>
                                                <th scope="row">
                                                    <I18n t="artist.view.popularityHistogram.popover.bestCountry" />
                                                </th>
                                                <td>{date?.bestCountry}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <I18n t="artist.view.popularityHistogram.popover.bestRank" />
                                                </th>
                                                <td>
                                                    <I18n
                                                        t="utils.nth"
                                                        args={date?.bestRank}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <td colSpan={2}>
                                            <I18n
                                                t="artist.view.popularityHistogram.popover.charts"
                                                args={date?.charts}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Popover.Content>
            </Popover>
        );
    }
);

export default ChartPopover;
