import React from "react";
import styles from "./Stepper.module.css";
import Step from "./Step";
import clsx from "clsx";

const Stepper = ({ steps, activeStep, isCompleted, ...props }) => {
    return (
        <div className={clsx(styles.root)}>
            <div className={clsx(styles.stepper)}>
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        active={index === activeStep}
                        completed={
                            isCompleted
                                ? index !== activeStep
                                : index < activeStep
                        }
                        first={index === 0}
                        isLast={index === steps.length - 1}
                        index={index}
                        {...step}
                        {...props}
                    />
                ))}
            </div>
        </div>
    );
};

export default Stepper;
