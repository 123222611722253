import {
    ARTIST_SET,
    NAMES_UP_TO_DATE,
    BAS_UP_TO_DATE,
} from "./ArtistManagement.constants";

export const ArtistManagementReducer = (state, action) => {
    switch (action.type) {
        case ARTIST_SET:
            return {
                ...state,
                artist: action.data,
            };
        case NAMES_UP_TO_DATE:
            return {
                ...state,
                namesUpToDate: action.data,
            };
        case BAS_UP_TO_DATE:
            return {
                ...state,
                basUpToDate: action.data,
            };
    }
};
