import React from "react";
import {
    faLink,
    faSave,
    faSearch,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import InputText from "shared/components/Form/InputText";

const FnacAlbumMatcher = ({
    fnacId,
    searchLink,
    control,
    onSubmit,
    isSubmitting,
    isDirty,
    disabled,
}) => {
    return (
        <div className="d-flex align-items-center">
            <div className="flex-grow-1">
                <Form>
                    <InputGroup>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <InputText
                                    onChange={(event) => {
                                        onChange(
                                            event.target.value?.match(
                                                /www\.fnac\.com\/([a-z0-9]+)\//
                                            )?.[1] ?? event.target.value
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    disabled={disabled}
                                />
                            )}
                            name="fnacId"
                        />
                        <InputGroup.Append>
                            <a
                                href={searchLink}
                                className={clsx(
                                    "btn btn-primary",
                                    disabled && "disabled"
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </a>
                        </InputGroup.Append>
                        <InputGroup.Append>
                            <Button
                                variant="success"
                                disabled={disabled || !isDirty || isSubmitting}
                                onClick={onSubmit}
                            >
                                <FontAwesomeIcon
                                    icon={isSubmitting ? faSpinner : faSave}
                                    pulse={isSubmitting}
                                />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form>
            </div>
            <div>
                {fnacId && (
                    <a
                        href={`https://www.fnac.com/${fnacId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2"
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </a>
                )}
            </div>
        </div>
    );
};

export default FnacAlbumMatcher;
