import React from "react";
import Widget from "shared/components/Widget";
import ArtistLink from "shared/components/ArtistLink";
import I18n from "shared/lib/I18n";
import { displayAsString } from "shared/functions/Date";

const Group = ({ currentGroup, oldGroup, roleOptions, isLoading, locale }) => {
    return (
        <Widget title="artist.view.profileGroup.head" isLoading={isLoading}>
            <Widget.Body>
                {currentGroup.length === 0 && oldGroup.length === 0 && (
                    <hr className="my-2" />
                )}
                {currentGroup.length > 0 && (
                    <>
                        <hr className="my-2" />
                        <div className="row">
                            <div className="col">
                                <strong>
                                    <I18n t="artist.view.profileGroup.current" />
                                </strong>
                                <hr className="my-2" />
                                <div>
                                    {currentGroup.map(
                                        ({
                                            id,
                                            artist,
                                            start_date,
                                            end_date,
                                            roles,
                                        }) => {
                                            const data = [
                                                ...new Set(
                                                    [
                                                        start_date,
                                                        end_date,
                                                    ].filter(Boolean)
                                                ),
                                            ].map((row) =>
                                                displayAsString(row, locale)
                                            );
                                            return (
                                                <p key={id} className="mb-1">
                                                    <ArtistLink
                                                        artistId={artist.id}
                                                        content={artist.name}
                                                        view
                                                    />
                                                    {data.length !== 0 &&
                                                        ` - ${data.join(
                                                            " - "
                                                        )}`}
                                                    {roles.length !== 0 &&
                                                        roles.map((r) => (
                                                            <span key={r}>
                                                                <span className="mx-1">
                                                                    -
                                                                </span>
                                                                {roleOptions.find(
                                                                    ({ id }) =>
                                                                        id === r
                                                                )?.nom ?? r}
                                                            </span>
                                                        ))}
                                                </p>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {oldGroup.length > 0 && (
                    <>
                        <hr className="my-2" />
                        <div className="row">
                            <div className="col">
                                <strong>
                                    <I18n t="artist.view.profileGroup.old" />
                                </strong>
                                <hr className="my-2" />
                                <div>
                                    {oldGroup.map(
                                        ({
                                            id,
                                            artist,
                                            start_date,
                                            end_date,
                                            roles,
                                        }) => {
                                            const data = [
                                                ...new Set(
                                                    [
                                                        start_date,
                                                        end_date,
                                                    ].filter(Boolean)
                                                ),
                                            ].map((row) =>
                                                displayAsString(row, locale)
                                            );
                                            return (
                                                <p key={id} className="mb-1">
                                                    <ArtistLink
                                                        artistId={artist.id}
                                                        content={artist.name}
                                                        view
                                                    />
                                                    {data.length !== 0 &&
                                                        ` - ${data.join(
                                                            " - "
                                                        )}`}
                                                    {roles.length !== 0 &&
                                                        roles.map((r) => (
                                                            <span key={r}>
                                                                <span className="mx-1">
                                                                    -
                                                                </span>
                                                                {roleOptions.find(
                                                                    ({ id }) =>
                                                                        id === r
                                                                )?.nom ?? r}
                                                            </span>
                                                        ))}
                                                </p>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Group;
