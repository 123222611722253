import React from "react";
import TagsButton from "./TagsButton";
import useToggle from "shared/hooks/useToggle";
import { useLocation } from "react-router-dom";

const EnhancedTagsButton = (props) => {
    const location = useLocation();
    const [showTags, toggleTags] = useToggle(false);

    return (
        <TagsButton
            {...props}
            showTags={showTags}
            toggleTags={toggleTags}
            location={location}
        />
    );
};

export default EnhancedTagsButton;
