import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import APIDownloadLink from "shared/components/APIDownloadLink";

const ExportButton = ({
    year,
    chartType,
    availablePlatform,
    show,
    onShow,
    onHide,
}) => {
    return (
        <>
            <Button variant="default" onClick={onShow}>
                <I18n t="utils.button.export" />
            </Button>

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.charts.aggregate.chart.export.head" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        <I18n t="cnm.charts.aggregate.chart.export.requestForComplement" />
                    </h5>
                    <p>
                        {availablePlatform.map(({ id, name }) => (
                            <APIDownloadLink
                                key={id}
                                className="btn btn-primary mr-2"
                                route={`cnm/charts/request/${id}/${year}/${chartType}`}
                                filename={`cnm_request_${name.toLowerCase()}_${year}_${chartType}.jsonl`}
                            >
                                {name}
                            </APIDownloadLink>
                        ))}
                    </p>
                    <h5>
                        <I18n t="cnm.charts.aggregate.chart.export.requestForConsolidationData" />
                    </h5>
                    <p>
                        <APIDownloadLink
                            className="btn btn-primary mr-2"
                            route={`cnm/charts/request/cypok/${year}/${chartType}`}
                            filename={`cnm_request_cypok_${year}_${chartType}.jsonl`}
                        >
                            <I18n t="cnm.charts.aggregate.chart.export.cypok" />
                        </APIDownloadLink>
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ExportButton;
