import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTimes } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Lock = ({ handleClick, location }) => {
    return (
        <>
            <Button
                variant="danger"
                onClick={handleClick}
                title={I18n.getTranslation(location, "user.manage.lock.title")}
            >
                <FontAwesomeIcon icon={faUserTimes} />
            </Button>
        </>
    );
};

export default Lock;
