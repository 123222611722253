import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import AgeType from "./AgeType";
import { getAgeType } from "./AgeType.pure";

const EnhancedAgeType = ({ year, control, name, ...props }) => {
    const dateStr = useWatch({
        control,
        name,
        defaultValue: undefined,
    });

    const { type, change } = useMemo(
        () => getAgeType(dateStr, year),
        [dateStr, year]
    );

    return <AgeType type={type} change={change} {...props} />;
};

export default EnhancedAgeType;
