import React from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import swal from "sweetalert";
import DeleteButton from "./DeleteButton";

const EnhancedDeleteButton = ({ id, getAssessments, ...props }) => {
    const location = useLocation();
    const { api } = useAPI();
    const { locale } = useParams();

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "apple.sample.assessment.rating.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "apple.sample.assessment.rating.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            api.delete(`apple/sample/assessment/ratings/${id}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "apple.sample.assessment.rating.delete.success"
                        )
                    );
                    getAssessments();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        });
    };

    return (
        <DeleteButton
            handleDelete={handleDelete}
            location={location}
            {...props}
        />
    );
};

export default EnhancedDeleteButton;
