import React, { useState, useEffect, useCallback } from "react";
import Genres from "./Genres";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import {
    getMain,
    getPrincipal,
    getSecondary,
    getInfluence,
} from "./Genres.pure";
import useUser from "shared/hooks/useUser";

const EnhancedGenres = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        genre: [],
        genreOptions: [],
        isLoading: true,
    });

    const getGenre = useCallback(() => {
        return api
            .get(`artist/${artistId}/genre`)
            .then((response) => {
                setState((prev) => ({ ...prev, genre: response }));
                if (response.filter((e) => e.id_genre !== 0).length === 0) {
                    setNoData("genres");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getGenreOptions = useCallback(() => {
        return api
            .get(`genre/${locale}`)
            .then((response) => {
                setState((prev) => ({ ...prev, genreOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getGenre(), getGenreOptions()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getGenre, getGenreOptions]);

    return (
        <Genres
            {...props}
            {...state}
            main={getMain(state)}
            principal={getPrincipal(state)}
            secondary={getSecondary(state)}
            influence={getInfluence(state)}
            locale={locale}
            user={user}
        />
    );
};

export default EnhancedGenres;
