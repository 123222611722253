import React, { useCallback, useEffect, useState } from "react";
import ArtistDelete from "./ArtistDelete";
import useIntParams from "../../shared/hooks/useIntParams";
import { toast } from "react-toastify";
import useAPI from "../../shared/hooks/useApi";
import { useNavigate, useLocation, useParams } from "react-router";
import I18n from "../../shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "shared/lib/I18n";
import swal from "sweetalert";

const EnhancedArtistDelete = (props) => {
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        artist: {},
        objectLinked: [],
    });
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const getState = useCallback(() => {
        api.get(`artist/${artistId}/objectlinked`, { locale })
            .then((response) => {
                setState(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(getState, [getState]);

    const isDeleting = state.objectLinked.some((element) => {
        return element.nb !== 0 && element.critical;
    });

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.delete.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.delete.delete.alert.text"
            ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            api.delete(`artist/${artistId}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "artist.delete.delete.success"
                        )
                    );
                    navigate(`/${locale}/artist`);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "artist.delete.delete.error",
                            error.message
                        )
                    );
                });
        });
    };

    const columns = [
        {
            dataField: "object",
            text: I18n.getTranslation(location, "artist.delete.object"),
            headerStyle: { width: "7em" },
            formatter: (cell, row, rowIndex, data) => {
                return I18n.getTranslation(
                    data.location,
                    "artist.delete." + cell
                );
            },
            formatExtraData: {
                location,
            },
        },
        {
            dataField: "nb",
            text: I18n.getTranslation(location, "artist.delete.nb"),
            formatter: (cell, row) => {
                return row.nb !== 0 && row.critical ? (
                    <>
                        <FontAwesomeIcon
                            className="text-danger"
                            icon={faTimes}
                            size="lg"
                            fixedWidth
                        />
                        {`- ${cell}`}
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon
                            className="text-success"
                            icon={faCheck}
                            size="lg"
                            fixedWidth
                        />
                        {cell > 0 && `- ${cell}`}
                    </>
                );
            },
            headerStyle: { width: "7em" },
        },
        {
            dataField: "url",
            text: I18n.getTranslation(location, "artist.delete.url"),
            formatter: (cell) => {
                return (
                    <NavLink to={cell} className="btn btn-primary btn-sm mr-1">
                        <FontAwesomeIcon icon={faSearch} size="lg" fixedWidth />
                    </NavLink>
                );
            },
            headerStyle: { width: "7em" },
        },
    ];

    return (
        <ArtistDelete
            {...props}
            {...state}
            columns={columns}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            location={location}
        />
    );
};

export default EnhancedArtistDelete;
