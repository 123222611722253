import React, { useState } from "react";
import Upload from "./Upload";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useParams, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { HTTP_CONFLICT } from "shared/constants/http";
import { UPLOAD_MAX_SIZE } from "shared/constants/file";

const schema = yup.object().shape({
    file: yup
        .mixed()
        .test("fileSize", "utils.form.upload_max_size", (value) => {
            return value.size <= UPLOAD_MAX_SIZE;
        })
        .required("utils.form.required"),
    year: yup
        .string()
        .trim()
        .required("utils.form.required")
        .matches("^[0-9]{4}$", "utils.form.year_format"),
});

const EnhancedUpload = ({ onSuccess, ...props }) => {
    const { api } = useAPI();
    const { id_pf_or_year, locale } = useParams();
    const location = useLocation();
    const [state, setState] = useState({
        show: false,
        differences: [],
    });
    const handleClose = () =>
        setState((state) => ({
            ...state,
            show: false,
        }));
    const handleShow = () =>
        setState({
            show: true,
            differences: [],
        });
    const setDifferences = (differences) =>
        setState((state) => ({
            ...state,
            differences,
        }));

    const showConfirmation = state.differences.length !== 0;

    const now = new Date();
    const currentYear = now.getFullYear();
    const yearOptions = [currentYear, currentYear - 1];

    const typeOptions = ["TEST", "REAL"];

    const {
        handleSubmit,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            file: null,
            // Select the previous year by default between January and June, and
            // the current year between July and December.
            year: now.getMonth() <= 7 ? currentYear - 1 : currentYear,
            type: "TEST",
        },
    });

    const onSubmit = ({ file, year, type }) =>
        api
            .postFile(
                `cnm/charts/platform/${id_pf_or_year}/year/${year}/type/${type.toLowerCase()}`,
                { locale, force: showConfirmation ? 1 : 0 },
                { file }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.charts.platform.upload.success"
                    )
                );
                handleClose();
                onSuccess(year, type);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);

                if (error.status === HTTP_CONFLICT && error.body?.differences) {
                    setDifferences(error.body?.differences);
                }
            });

    return (
        <Upload
            yearOptions={yearOptions}
            typeOptions={typeOptions}
            errors={errors}
            handleClose={handleClose}
            handleShow={handleShow}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
            showConfirmation={showConfirmation}
            {...state}
            {...props}
        />
    );
};

export default EnhancedUpload;
