import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Create from "./Button/Create";
import Widget from "shared/components/Widget";

const Role = ({
    role,
    columns,
    isLoading,
    options,
    getRole,
    defaultSorted,
    rightsOptions,
    location,
}) => {
    const { SearchBar } = Search;
    return (
        <ToolkitProvider keyField="id" data={role} columns={columns} search>
            {(toolkitprops) => (
                <Widget
                    title="user.role.manage.role.head"
                    isLoading={isLoading}
                >
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-11">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "user.role.manage.role.search"
                                    )}
                                />
                            </div>
                            <div className="col-1">
                                <Create
                                    onSuccess={getRole}
                                    rightsOptions={rightsOptions}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body className="widget-table-overflow pb-3">
                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            striped
                            hover
                            noDataIndication={
                                <I18n t="user.role.manage.role.empty" />
                            }
                            pagination={paginationFactory(options)}
                            defaultSorted={defaultSorted}
                        />
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Role;
