import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import MoodmediaSearch from "./MoodmediaSearch";

const EnhancedMoodmediaSearch = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState({ tracks: [], artists: [] });

    const onSearch = (artist, title) => {
        setIsLoading(true);
        api.get("moodmedia/search", { locale, artist, title })
            .then((response) => {
                setResults(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    };

    return (
        <MoodmediaSearch
            isLoading={isLoading}
            results={results}
            onSearch={onSearch}
            location={location}
            {...props}
        />
    );
};

export default EnhancedMoodmediaSearch;
