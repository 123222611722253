import {
    RELATED_TYPE_RELATED,
    RELATED_TYPE_SUCCESSOR,
    RELATED_TYPE_INFLUENCE,
} from "shared/constants/cms/artist";

export const getRelated = ({ data }) => {
    if (data.length !== 0) {
        return data
            .filter(({ type }) => type === RELATED_TYPE_RELATED)
            .map(({ artist }) => artist);
    }
    return [];
};

export const getSuccessor = ({ data }) => {
    if (data.length !== 0) {
        return data
            .filter(({ type }) => type === RELATED_TYPE_SUCCESSOR)
            .map(({ artist }) => artist);
    }
    return [];
};

export const getInfluence = ({ data }) => {
    if (data.length !== 0) {
        return data
            .filter(({ type }) => type === RELATED_TYPE_INFLUENCE)
            .map(({ artist }) => artist);
    }
    return [];
};
