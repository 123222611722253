import React, { useState } from "react";
import ExportButton from "./ExportButton";

const EnhancedExportButton = (props) => {
    const [show, setShow] = useState(false);
    const onShow = () => {
        setShow(true);
    };
    const onHide = () => {
        setShow(false);
    };

    return (
        <ExportButton show={show} onShow={onShow} onHide={onHide} {...props} />
    );
};

export default EnhancedExportButton;
