import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const Action = ({ showPanel, exist, location }) => {
    return (
        <Button
            variant={exist ? "success" : "primary"}
            onClick={showPanel}
            disabled={exist}
            title={I18n.getTranslation(
                location,
                `apple.sample.management.lyrics.action.addToPlanning.${
                    exist ? "exist" : "title"
                }`
            )}
        >
            <FontAwesomeIcon icon={exist ? faCheck : faPlus} fixedWidth />
        </Button>
    );
};

export default Action;
