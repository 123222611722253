import React, { useEffect } from "react";
import SearchForm from "./SearchForm";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useToggle from "shared/hooks/useToggle";

const schema = yup.object().shape({
    search: yup.string().nullable().required("utils.form.required"),
    search_type: yup.string().required("utils.form.required"),
});

const EnhancedSearchForm = ({ searchParams, setSearchParams, ...props }) => {
    const [showAdvancedForm, toggleShowAdvancedForm] = useToggle();
    const location = useLocation();

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            search: null,
            search_type: "best_match",
        },
    });

    useEffect(() => {
        reset(searchParams);
    }, [searchParams]);

    const searchTypeOptions = [
        {
            value: "best_match",
            label: I18n.getTranslation(
                location,
                `common.picture.getty.bas.filters.search_type.best_match`
            ),
        },
        {
            value: "most_popular",
            label: I18n.getTranslation(
                location,
                `common.picture.getty.bas.filters.search_type.most_popular`
            ),
        },
    ];

    return (
        <SearchForm
            {...props}
            showAdvancedForm={showAdvancedForm}
            toggleShowAdvancedForm={toggleShowAdvancedForm}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            onSubmit={setSearchParams}
            location={location}
            searchTypeOptions={searchTypeOptions}
        />
    );
};

export default EnhancedSearchForm;
