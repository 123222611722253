import React from "react";
import I18n from "shared/lib/I18n";
import WorkData from "./WorkData";

const Work = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="recording.view.noData.work" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col">
                <WorkData setNoData={setNoData} />
            </div>
        </div>
    );
};

export default Work;
