import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import TrackTranslationsForm from "./TrackTranslationsForm";

const getFormValues = ({ artistNames, recordingsAlbums, recordings }) => ({
    artistName: artistNames[0] ?? "",
    albumTitles: recordingsAlbums.reduce(
        (acc, album) => ({
            ...acc,
            [`id_${album.id}`]: album.translatedTitle ?? "",
        }),
        {}
    ),
    recordingsTitle: recordings[0]?.translatedTitle ?? "",
    recordingsSubtitle: recordings[0]?.translatedSubtitle ?? "",
});

const EnhancedTrackTranslationsForm = ({
    entryId,
    artistNames,
    recordingsAlbums,
    recordings,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale, language } = useParams();

    const schema = useMemo(
        () =>
            yup.object().shape({
                artistName: yup.string().ensure().trim(),
                albumTitles: yup.object().shape(
                    recordingsAlbums.reduce(
                        (acc, album) => ({
                            ...acc,
                            [`id_${album.id}`]: yup.string().ensure().trim(),
                        }),
                        {}
                    )
                ),
                recordingsTitle: yup.string().ensure().trim(),
                recordingsSubtitle: yup.string().ensure().trim(),
            }),
        [recordingsAlbums]
    );

    const defaultValues = useMemo(
        () =>
            getFormValues({
                artistNames,
                recordingsAlbums,
                recordings,
            }),
        [artistNames, recordingsAlbums, recordings]
    );

    const {
        handleSubmit,
        register,
        reset,
        resetField,
        // dirtyFields is not used, but it needs to be here to make the
        // keepDirtyValues option of the reset function work
        formState: { isSubmitting, dirtyFields, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    // Reset the form when the matchings are modified
    //  - keep the dirty recording/albums translations, to allow to add
    //    recording matchings after filling out the form
    //  - only reset the artist translation when the artist matching changes
    useEffect(() => {
        reset(defaultValues, { keepDirtyValues: true });
    }, [defaultValues]);
    useEffect(() => {
        resetField("artistName");
    }, [artistNames]);

    const onSubmit = ({
        artistName,
        albumTitles,
        recordingsTitle,
        recordingsSubtitle,
    }) => {
        api.post(
            `matching/top/translation/track/${language}/${entryId}/translation`,
            { locale },
            {
                artistName,
                albumTitles: recordingsAlbums.map((album) => ({
                    id: album.id,
                    title: albumTitles[`id_${album.id}`],
                })),
                recordingsTitle,
                recordingsSubtitle,
            }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.charts.charts.trackTranslationsForm.save.success"
                    )
                );
                reset(getFormValues(response.translations));
                onSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "common.charts.charts.trackTranslationsForm.save.error",
                        error.message
                    )
                );
            });
    };

    return (
        <TrackTranslationsForm
            location={location}
            register={register}
            artistNames={artistNames}
            recordingsAlbums={recordingsAlbums}
            recordings={recordings}
            isSubmitting={isSubmitting}
            isDirty={isDirty || artistNames.length > 1 || recordings.length > 1}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            onReset={() => reset()}
            {...props}
        />
    );
};

export default EnhancedTrackTranslationsForm;
