import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import I18n, { NavLink } from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import MultiSearch from "shared/components/MultiSearch";
import clsx from "clsx";

const Navbar = () => {
    const { api } = useAPI();
    const { user, invalidate, setUserLanguage } = useUser();
    const { locale } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const hideMultiSearch = pathname.match(/^\/(fr|en|ja|de|ko)\/search/);

    const onLogout = () => {
        invalidate();
        navigate(`/${locale}/login`);
    };

    const onChangeLanguage = (language) => {
        api.put("user/language", { language })
            .then((response) => {
                setUserLanguage(language);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <nav className="page-controls navbar navbar-dashboard">
            <div className="container-fluid">
                {!hideMultiSearch && (
                    <div className="m-auto" style={{ width: "60rem" }}>
                        <MultiSearch shortcut />
                    </div>
                )}
                <ul
                    className={clsx("navbar-nav", hideMultiSearch && "ml-auto")}
                >
                    <li className="nav-item dropdown">
                        <button
                            onClick={() => onChangeLanguage("fr")}
                            className="btn btn-light"
                        >
                            FR
                        </button>
                        <button
                            onClick={() => onChangeLanguage("en")}
                            className="btn btn-light"
                        >
                            EN
                        </button>
                        <button
                            onClick={() => onChangeLanguage("de")}
                            className="btn btn-light"
                        >
                            DE
                        </button>
                        <button
                            onClick={() => onChangeLanguage("ja")}
                            className="btn btn-light"
                        >
                            日本語
                        </button>
                        <button
                            onClick={() => onChangeLanguage("ko")}
                            className="btn btn-light"
                        >
                            한국어
                        </button>
                        <button
                            className="btn btn-light dropdown-toggle"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {user.name}
                        </button>
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                        >
                            <NavLink to="profile" className="dropdown-item">
                                <i className="fa fa-user" /> &nbsp;
                                <I18n t="base.user.account" />
                            </NavLink>
                            <div className="dropdown-divider" />
                            <button
                                className="dropdown-item"
                                onClick={onLogout}
                            >
                                <i className="fas fa-sign-out-alt" /> &nbsp;
                                <I18n t="base.user.logout" />
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
