import React, { useState, useEffect, useCallback } from "react";
import useAPI from "shared/hooks/useApi";
import Matching from "./Matching";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import MatchingDelete from "pages/ArtistManagement/Discography/Actions/Track/MatchingDelete";

const EnhancedMatching = (props) => {
    const [tracksMatching, setTracksMatching] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { api } = useAPI();
    const { artistId, albumId, releaseId, trackId, recordingId } = useParams();

    const getMatching = useCallback(() => {
        if (recordingId) {
            setIsLoading(true);
            api.get(
                `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}/matching`
            )
                .then((response) => {
                    setTracksMatching(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        }
    }, [recordingId]);

    useEffect(getMatching, [getMatching]);

    const deleteMatching = (v_partner, v_id_partner) => {
        setTracksMatching(
            tracksMatching.filter(
                ({ partner, id_partner }) =>
                    partner !== v_partner && id_partner !== v_id_partner
            )
        );
    };

    const columns = [
        {
            dataField: "partner",
            text: "",
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.matching.partner"
                ),
            sort: true,
            searchable: false,
            headerStyle: () => {
                return {
                    width: "80px",
                };
            },
        },
        {
            dataField: "id_partner",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.matching.id"
            ),
            sort: true,
            searchable: true,
        },
        {
            dataField: "dummy",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "artist.management.disco.matching.actions"
            ),
            headerStyle: () => {
                return {
                    width: "80px",
                };
            },
            formatter: (cellContent, row) => (
                <>
                    {row.url !== undefined && (
                        <a
                            className="btn btn-link p-1 mr-1"
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={I18n.getTranslation(
                                location,
                                "artist.management.disco.matching.view"
                            )}
                        >
                            <FontAwesomeIcon icon={faLink} fixedWidth />
                        </a>
                    )}
                    <>
                        <MatchingDelete
                            partner={row.partner}
                            id_partner={row.id_partner}
                            onSuccess={deleteMatching}
                        />
                    </>
                </>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "partner",
            order: "asc",
        },
    ];

    return (
        <Matching
            {...props}
            tracksMatching={tracksMatching}
            columns={columns}
            isLoading={isLoading}
            defaultSorted={defaultSorted}
        />
    );
};

export default EnhancedMatching;
