import React, { useState, useEffect } from "react";
import Credits from "./Credits";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    creditsData: true,
};

const EnhancedCredits = (props) => {
    const { albumId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [albumId]);

    return <Credits {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedCredits;
