import React from "react";
import { useWatch } from "react-hook-form";
import PublicationDateReference from "./PublicationDateReference";

const EnhancedPublicationDateReference = ({ control, name, ...props }) => {
    const selectedPublicationDate = useWatch({
        control,
        name,
    });
    return (
        <PublicationDateReference
            selectedPublicationDate={selectedPublicationDate}
            {...props}
        />
    );
};

export default EnhancedPublicationDateReference;
