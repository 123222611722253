import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import AppleSampleReport from "./AppleSampleReport";

const EnhancedAppleSampleReport = (props) => {
    const { locale, selectedTab } = useParams();
    const { sampleId } = useIntParams();
    const navigate = useNavigate();
    const [sampleDate, setSampleDate] = useState();
    const location = useLocation();

    const onSelectTab = (k) => {
        if (sampleId) {
            navigate(`/${locale}/apple/sample/report/${sampleId}/${k}`);
        } else {
            navigate(`/${locale}/apple/sample/report`);
        }
    };

    return (
        <AppleSampleReport
            {...props}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            sampleDate={sampleDate}
            setSampleDate={setSampleDate}
            location={location}
        />
    );
};

export default EnhancedAppleSampleReport;
