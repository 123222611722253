import React, { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import DeleteButton from "./DeleteButton";

const EnhancedDeleteButton = ({ year, id, onDelete, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({
        show: false,
        isLoading: false,
        matchings: [],
    });
    const onHide = () => {
        setState({
            show: false,
            isLoading: false,
            matchings: [],
        });
    };

    const onConfirm = () => {
        api.delete(`cnm/repository/cnm_labels/${year}/label/${id}`, { locale })
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.cnm_labels.delete.success"
                    )
                );
                onHide();
                onDelete({ id });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onShow = () => {
        let cancelled = false;
        setState({
            show: true,
            isLoading: true,
            matchings: [],
        });

        api.get(
            `cnm/repository/cnm_labels/${year}/label/${id}/matched_titles`,
            { locale }
        )
            .then((response) => {
                if (!cancelled) {
                    setState({
                        show: true,
                        isLoading: false,
                        matchings: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        show: false,
                        isLoading: false,
                        matchings: [],
                    });
                }
            });

        return () => {
            cancelled = true;
        };
    };

    return (
        <DeleteButton
            {...state}
            onShow={onShow}
            onHide={onHide}
            onConfirm={onConfirm}
            {...props}
        />
    );
};

export default EnhancedDeleteButton;
