import React from "react";
import I18n from "shared/lib/I18n";
import LanguageSelect from "./LanguageSelect";
import CategoryList from "shared/components/Charts/CategoryList";
import ChartsList from "shared/components/Charts/ChartsList";
import { CHARTS_MODE } from "shared/constants/Charts";
import Charts from "shared/components/Charts/Charts";
import { Helmet } from "react-helmet";

const ChartsTranslation = ({
    language,
    categoryId,
    chartId,
    onSelectCategory,
    onSelectChart,
    location,
}) => {
    return (
        <div className="content-wrap charts-translation">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "charts.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "charts.translation.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb d-flex align-items-center">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="charts.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="charts.translation.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active d-flex align-items-center">
                        <LanguageSelect />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-md-8">
                        <CategoryList
                            categoryId={categoryId}
                            onSelect={onSelectCategory}
                        />
                    </div>
                    <div className="col-md-4">
                        <ChartsList
                            categoryId={categoryId}
                            chartId={chartId}
                            language={language}
                            onSelect={onSelectChart}
                            mode={CHARTS_MODE.TRANSLATION}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {chartId !== undefined ? (
                            <Charts
                                categoryId={categoryId}
                                chartId={chartId}
                                language={language}
                                mode={CHARTS_MODE.TRANSLATION}
                            />
                        ) : undefined}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ChartsTranslation;
