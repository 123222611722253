import React, { useState, useEffect, useCallback } from "react";
import Tasks from "./Tasks";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import useAPI from "shared/hooks/useApi";

const EnhancedTasks = (props) => {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const { locale, planningId } = useParams();
    const { api } = useAPI();

    const getTasks = useCallback(() => {
        if (planningId) {
            api.get(`plannings/${planningId}/tasks`, {
                locale,
                mode: "picture",
            })
                .then((response) => {
                    setTasks(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        } else {
            setTasks([]);
        }
    }, [locale, planningId]);

    useEffect(() => {
        setIsLoading(true);
        getTasks();
        setIsLoading(false);
    }, [getTasks]);

    return (
        <Tasks
            {...props}
            tasks={tasks}
            filteredTasks={filteredTasks}
            setFilteredTasks={setFilteredTasks}
            isLoading={isLoading}
            onCheck={getTasks}
        />
    );
};

export default EnhancedTasks;
