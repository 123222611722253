import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faTrashAlt,
    faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { useCreateOrUpdateModal } from "./CreateOrUpdateModal";
import Platforms from "./Platforms";

const EnhancedPlatforms = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const { user } = useUser();
    const editable = user.hasRight("cnm.repository.platforms.manage");

    const [state, setState] = useState({
        isLoading: true,
        platforms: [],
    });

    const fetchPlatforms = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            platforms: [],
        });
        api.get("cnm/platform", { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        platforms: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        platforms: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(fetchPlatforms, [fetchPlatforms]);

    const onCreate = ({ name }) => {
        return api
            .post("cnm/platform", { locale }, { name })
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.platforms.add.success"
                    )
                );
                setState((state) => ({
                    ...state,
                    platforms: [...state.platforms, response],
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onUpdate = ({ id, name, enabled, action = "edit" }) => {
        return api
            .post(`cnm/platform/${id}`, { locale }, { name, enabled })
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `cnm.repositories.platforms.${action}.success`
                    )
                );
                setState((state) => ({
                    ...state,
                    platforms: state.platforms.map((platform) =>
                        platform.id == id ? { id, name, enabled } : platform
                    ),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const { showCreateModal, showUpdateModal, createOrUpdateModalProps } =
        useCreateOrUpdateModal({
            onCreate,
            onUpdate,
        });

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.platforms.name"
            ),
            formatter: (cell, row, rowIndex, data) =>
                editable ? (
                    <div className="d-flex align-items-center">
                        <span className="flex-grow-1">{cell}</span>
                        <ButtonGroup>
                            <Button
                                variant="primary"
                                onClick={() => showUpdateModal(row)}
                                title={data.editTooltip}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            {row.enabled ? (
                                <Button
                                    variant="danger"
                                    onClick={() =>
                                        onUpdate({
                                            ...row,
                                            enabled: false,
                                            action: "disable",
                                        })
                                    }
                                    title={data.disableTooltip}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            ) : (
                                <Button
                                    variant="success"
                                    onClick={() =>
                                        onUpdate({
                                            ...row,
                                            enabled: true,
                                            action: "enable",
                                        })
                                    }
                                    title={data.enableTooltip}
                                >
                                    <FontAwesomeIcon icon={faTrashRestoreAlt} />
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                ) : (
                    cell
                ),
            formatExtraData: {
                editTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.platforms.edit.tooltip"
                ),
                enableTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.platforms.enable.tooltip"
                ),
                disableTooltip: I18n.getTranslation(
                    location,
                    "cnm.repositories.platforms.disable.tooltip"
                ),
            },
        },
    ];

    const rowClasses = (row) => (row.enabled ? undefined : "table-secondary");

    return (
        <Platforms
            columns={columns}
            rowClasses={rowClasses}
            editable={editable}
            createOrUpdateModalProps={createOrUpdateModalProps}
            showCreateModal={showCreateModal}
            location={location}
            {...state}
            {...props}
        />
    );
};

export default EnhancedPlatforms;
