import React from "react";
import { Modal, Button } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import InputTextArea from "shared/components/Form/InputTextArea";
import I18n from "shared/lib/I18n";
import Tippy from "@tippyjs/react";
import Loader from "shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Stepper from "shared/components/Stepper";
import clsx from "clsx";

const PictureInformationModalGetty = ({
    show,
    onNext,
    handleHide,
    location,
    isLoading,
    picture,
    returnToCrop,
    returnToTagging,
    pictureInformation,
}) => {
    const steps = [
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModalGetty.upload"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModalGetty.legal"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModalGetty.cropping"
            ),
            onClick: returnToCrop,
        },
        {
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModalGetty.tagging"
            ),
            onClick: returnToTagging,
        },
    ];
    const activeStep = 1;

    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            className="modal-add-image"
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        isCompleted={
                            picture?.url_version &&
                            Object.keys(picture?.tags).length > 0
                        }
                    />
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleHide}
                        fixedWidth
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="modal-add-image">
                {isLoading ? (
                    <Loader />
                ) : (
                    pictureInformation && (
                        <div className="container-fluid">
                            <div className="row" style={{ padding: "10px" }}>
                                <div className="col">
                                    {picture?.url && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <ImageLoader
                                                src={picture?.url}
                                                style={{
                                                    maxHeight: "450px",
                                                    maxWidth: "500px",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col">
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.source" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.source`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {pictureInformation.source}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.source_url" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.source_url`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {
                                                JSON.parse(
                                                    pictureInformation.referral_destinations
                                                ).uri
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.author" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.author`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {pictureInformation.artist}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.year" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.year`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {
                                                pictureInformation.date_created.split(
                                                    "-"
                                                )[0]
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.copyright_owner" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.copyright_owner`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {pictureInformation.credit_line}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.license" />
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {pictureInformation.license}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.copyright" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.copyright`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <div
                                            className={clsx("form-control")}
                                            style={{
                                                minHeight: "4.5vh",
                                                height: "auto",
                                            }}
                                            readOnly={true}
                                            disabled={true}
                                        >
                                            {
                                                pictureInformation.copyright_notice
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <I18n t="common.pictureInformationModalGetty.complementary" />
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    `common.pictureInformationModalGetty.description.complementary`
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span className="ml-1">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        fixedWidth
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                        <InputTextArea
                                            value={`Allowed use : ${
                                                JSON.parse(
                                                    pictureInformation.allowed_use
                                                ).how_can_i_use_it
                                            }\nEditorial source : ${
                                                pictureInformation.editorial_source
                                            }\nCollection ID : ${
                                                pictureInformation.collection_id
                                            }\nCollection name : ${
                                                pictureInformation.collection_name
                                            }\nCollection code : ${
                                                pictureInformation.collection_code
                                            }`}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    disabled={isLoading}
                    onClick={!isLoading ? onNext : null}
                >
                    <I18n t={`utils.button.next`} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PictureInformationModalGetty;
