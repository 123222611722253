import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import InputTextArea from "shared/components/Form/InputTextArea";

const Highlights = ({
    getHighlights,
    isLoading,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
}) => {
    return (
        <Widget title={`artist.management.highlights.head`} enableCollapse>
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row">
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.highlights.famous_for`}
                                    />
                                </Form.Label>
                                <InputTextArea
                                    {...register("famous_for")}
                                    error={
                                        errors.famous_for && [
                                            I18n.getTranslation(
                                                location,
                                                errors.famous_for.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.highlights.awards`}
                                    />
                                </Form.Label>
                                <InputTextArea
                                    {...register("awards")}
                                    error={
                                        errors.awards && [
                                            I18n.getTranslation(
                                                location,
                                                errors.awards.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? getHighlights
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.highlights.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.highlights.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Highlights;
