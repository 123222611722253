import React from "react";
import Year from "./Year";
import Chart from "./Chart";

const Aggregate = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Year />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Chart />
                </div>
            </div>
        </>
    );
};

export default Aggregate;
