import React from "react";
import CreateForm from "./CreateForm";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useLocation, useParams, useNavigate } from "react-router";
import useAPI from "shared/hooks/useApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import swal from "sweetalert";
import { useDebouncedCallback } from "use-debounce";

const defaultValues = {
    search: null,
};

const schema = yup.object().shape({
    search: yup.string().required("utils.form.required"),
});

const EnhancedCreateForm = ({ setState, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onSubmit = (data) => {
        setState((previousState) => {
            return { ...previousState, isLoading: true, hasSearch: true };
        });

        api.get(`artist/curate`, { locale, ...data })
            .then((response) => {
                setState((previousState) => {
                    return {
                        ...previousState,
                        isLoading: false,
                        artists: response,
                    };
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState((previousState) => {
                    return {
                        ...previousState,
                        isLoading: false,
                        hasSearch: false,
                        artists: [],
                    };
                });
            });
    };

    const onSubmitCreate = (data) => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.create.create.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.create.create.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            api.post(`artist/create`, { locale }, data)
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.create.create.success`
                        )
                    );

                    navigate(`/${locale}/artist/${response.id_artist}/edit`);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.create.create.error`,
                            error.message
                        )
                    );
                });
        });
    };

    const redirectToArtist = (artistId) => {
        navigate(`/${locale}/artist/${artistId}/edit`);
    };

    const loadOptions = useDebouncedCallback((term, callback) => {
        api.get(`searchengine/artist`, { term })
            .then((response) => {
                callback(
                    response.map((row) => {
                        const name = [
                            row.id,
                            row.name,
                            row.complement,
                            row.type?.langs?.[locale],
                            row.country?.langs?.[locale],
                            row.role?.langs?.[locale],
                            row.genre?.langs?.[locale],
                        ];
                        return {
                            value: { id: row.id, name: row.name },
                            label: name.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    return (
        <CreateForm
            {...props}
            loadOptions={loadOptions}
            errors={errors}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            onSubmitCreate={onSubmitCreate}
            location={location}
            redirectToArtist={redirectToArtist}
        />
    );
};

export default EnhancedCreateForm;
