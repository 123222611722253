import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import LabelUploadModal from "shared/components/LabelUploadModal";
import PictureTagsModal from "shared/components/PictureTagsModal";
import PictureCropModal from "shared/components/PictureCropModal";

const AddButton = ({
    artistId,
    showUpload,
    toggleUpload,
    onUploadSuccess,
    pictureId,
    versionId,
    showTags,
    toggleTags,
    onTagsSuccess,
    showCrop,
    toggleCrop,
    onCropSuccess,
    showTagsVersion,
    toggleTagsVersion,
    onTagsVersionSuccess,
}) => {
    return (
        <>
            <Button variant="primary" onClick={toggleUpload} size="lg">
                <FontAwesomeIcon icon={faImage} className="mr-2" />
                <I18n t="common.picture.action.add" />
            </Button>

            <LabelUploadModal
                artistId={artistId}
                show={showUpload}
                handleHide={toggleUpload}
                onSuccess={onUploadSuccess}
                withLabel
            />

            <PictureTagsModal
                id_picture={pictureId}
                id_picture_version={versionId}
                show={showTags}
                handleHide={toggleTags}
                onSuccess={onTagsSuccess}
            />

            <PictureCropModal
                id_picture={pictureId}
                show={showCrop}
                handleHide={toggleCrop}
                onSuccess={onCropSuccess}
            />

            <PictureTagsModal
                id_picture={pictureId}
                id_picture_version={versionId}
                show={showTagsVersion}
                handleHide={toggleTagsVersion}
                onSuccess={onTagsVersionSuccess}
                isVersion
            />
        </>
    );
};

export default AddButton;
