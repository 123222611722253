import React from "react";
import CheckedFilter from "./CheckedFilter";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const EnhancedCheckedFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.checked.true"
            ),
            value: true,
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.checked.false"
            ),
            value: false,
        },
    ];

    return <CheckedFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedCheckedFilter;
