import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import {
    DiscographyContext,
    TRACKS_SET,
    TRACK_BASIC_UP_TO_DATE,
} from "pages/ArtistManagement/Discography";
import Tracklist from "./Tracklist";
import { useTracks } from "./Tracklist.hooks";
import swal from "@sweetalert/with-react";

const EnhancedTracklist = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { artistId, albumId, releaseId, locale } = useParams();

    // defaultTracks contains the tracks that are currently stored in the
    // context and in the database.
    // tracks contains the tracks that are displayed in the table, and which may
    // have been modified.
    // This component does not modify defaultTracks directly, to allow to commit
    // or revert the changes when desired.
    const {
        tracksLoading,
        tracks: defaultTracks,
        dispatchDiscography,
    } = useContext(DiscographyContext);

    const {
        tracks,
        errors,
        isValid,
        dirtyCells,
        isDirty,
        resetTracks,
        setTrackField,
    } = useTracks(defaultTracks);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = () => {
        let recordingControlOption = 0;

        setIsSubmitting(true);

        const sendRequest = () => {
            api.put(
                `artist/${artistId}/album/${albumId}/release/${releaseId}/tracks`,
                { locale, recordingControlOption },
                tracks
            )
                .then(onRequestSuccess)
                .catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            toast.success(
                I18n.getTranslation(
                    location,
                    `artist.management.disco.objects.release.tracklist.update.success`
                )
            );
            dispatchDiscography({ type: TRACKS_SET, data: response });
            dispatchDiscography({ type: TRACK_BASIC_UP_TO_DATE, data: false });
            setIsSubmitting(false);
        };

        const onDuplicateIsrc = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.release.tracklist.update.warningIsrc.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.objects.release.tracklist.update.warningIsrc.text"
                        )}
                        <ul className="list-group border-bottom">
                            {error.body.recordings.map((row) => (
                                <li
                                    key={row.id_recording}
                                    className="list-group-item"
                                >
                                    <a
                                        href={`/${locale}/artist/${row.id_artist}/edit/discography/album/${row.id_album}/release/${row.id_release}/track/${row.id_track}/recording/${row.id_recording}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.isrc} - {row.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    create: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.create"
                        ),
                        value: 1,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                    merge: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.merge"
                        ),
                        value: 2,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmitting(false);
                    return;
                }

                recordingControlOption = isConfirm;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_isrc") {
                onDuplicateIsrc(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.release.tracklist.update.error`,
                        error.message
                    )
                );
                setIsSubmitting(false);
            }
        };

        sendRequest();
    };

    return (
        <Tracklist
            isLoading={tracksLoading}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            isValid={isValid}
            tracks={tracks}
            errors={errors}
            dirtyCells={dirtyCells}
            onSubmit={onSubmit}
            setTrackField={setTrackField}
            resetTracks={resetTracks}
            location={location}
            {...props}
        />
    );
};

export default EnhancedTracklist;
