import * as moment from "moment";

export const getAgeType = (publicationDateStr, year) => {
    const publicationDate = moment.utc(
        publicationDateStr?.replaceAll("-00", "-01"),
        "YYYY-MM-DD",
        true
    );
    if (!publicationDate.isValid()) {
        return { type: null, change: null };
    }

    const recurringDate = publicationDate.clone().add(1, "year");
    const goldDate = publicationDate.clone().add(3, "year");

    const yearStart = moment.utc([year, 0, 1]);
    const yearEnd = moment.utc([year + 1, 0, 1]);
    const yearMiddle = moment.utc([year, 6, 2]);

    if (publicationDate >= yearStart) {
        return {
            type: "new",
            change: null,
        };
    }
    if (recurringDate >= yearStart) {
        return {
            type: recurringDate >= yearMiddle ? "new" : "recurring",
            change: {
                from: "new",
                to: "recurring",
                date: recurringDate,
            },
        };
    }
    if (goldDate >= yearEnd) {
        return {
            type: "recurring",
            change: null,
        };
    }
    if (goldDate >= yearStart) {
        return {
            type: goldDate >= yearMiddle ? "recurring" : "gold",
            change: {
                from: "recurring",
                to: "gold",
                date: goldDate,
            },
        };
    }
    return {
        type: "gold",
        change: null,
    };
};
