import { createSelector } from "reselect";

export const getFilterOptions = createSelector(
    [(args) => args.categories, (args) => args.filter],
    (categories, filter) =>
        categories.reduce(
            (acc, curr) => {
                if (curr.source) {
                    acc["source"] = [
                        ...acc.source,
                        {
                            label: curr.source,
                            value: curr.source,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (curr.area) {
                    acc["area"] = [
                        ...acc.area,
                        {
                            label: curr.area,
                            value: curr.area,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                if (
                    curr.country &&
                    (filter.area === null || curr.area === filter.area.value)
                ) {
                    acc["country"] = [
                        ...acc.country,
                        {
                            label: curr.country,
                            value: curr.country,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                return acc;
            },
            {
                source: [],
                area: [],
                country: [],
            }
        )
);

export const getFilteredCategories = createSelector(
    [(args) => args.categories, (args) => args.filter],
    (categories, filter) => {
        let filteredCategories = categories;

        if (filter.source) {
            filteredCategories = filteredCategories.filter(
                (p) => p.source === filter.source.value
            );
        }

        if (filter.area) {
            filteredCategories = filteredCategories.filter(
                (p) => p.area === filter.area.value
            );
        }

        if (filter.country) {
            filteredCategories = filteredCategories.filter(
                (p) => p.country === filter.country.value
            );
        }

        return filteredCategories;
    }
);
