import React from "react";
import DownloadButton from "./DownloadButton";

const EnhancedDownloadButton = ({ href, ...props }) => {
    const onClick = () => {
        fetch(href)
            .then((response) => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        href.substring(href.lastIndexOf("/") + 1)
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return <DownloadButton onClick={onClick} {...props} />;
};

export default EnhancedDownloadButton;
