import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import ProfileInformation from "./ProfileInformation";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    MiscEntityManagementContext,
    MISC_ENTITY_SET,
} from "pages/MiscEntityManagement";

const schema = yup.object().shape({
    name: yup.string().trim().required("utils.form.required"),
    type: yup.string().required("utils.form.required"),
    description: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    date: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable()
        .test(
            "is-not-0",
            "utils.form.date_zero",
            (value) => value !== "0000-00-00"
        )
        .matches("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "utils.form.date_format"),
});

const EnhancedProfileInformation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [typeOptions, setTypeOptions] = useState([]);
    const { miscEntityId } = useIntParams();
    const { dispatchMiscEntity } = useContext(MiscEntityManagementContext);

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            name: null,
            type: null,
            description: null,
            date: null,
        },
    });

    const getProfileInformation = useCallback(() => {
        let cancelled = false;

        api.get(`miscentity/${miscEntityId}`)
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    dispatchMiscEntity({
                        type: MISC_ENTITY_SET,
                        data: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                }
            });

        return () => {
            cancelled = true;
        };
    }, [miscEntityId]);

    const getTypeOptions = useCallback(() => {
        let cancelled = false;

        api.get("miscentity/types", { locale })
            .then((response) => {
                if (!cancelled) {
                    setTypeOptions(response);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setTypeOptions([]);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            await Promise.all([getProfileInformation(), getTypeOptions()]);
            setIsLoading(false);
        };
        init();
    }, [getProfileInformation, getTypeOptions]);

    const onSubmit = async (data) => {
        await api
            .put(`miscentity/${miscEntityId}`, {}, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `misc_entity.management.update.success`
                    )
                );
                reset(response);
                dispatchMiscEntity({
                    type: MISC_ENTITY_SET,
                    data: response,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `misc_entity.management.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <ProfileInformation
            {...props}
            getProfileInformation={getProfileInformation}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            typeOptions={typeOptions}
        />
    );
};

export default EnhancedProfileInformation;
