import React from "react";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Lyric.css";

const Lyric = ({
    lyrics,
    columns,
    colGrid,
    rowClasses,
    expandRow,
    isLoading,
}) => {
    return (
        <Widget
            title={`apple.sample.assessment.lyric.head`}
            className={`vertical-shrink apple__sample__assessment__lyric__widget__col-${colGrid} mb-0`}
        >
            <Widget.Body className="widget-table-overflow apple__sample__assessment__lyric__widget__body">
                {isLoading ? (
                    <Loader />
                ) : (
                    <BootstrapTable
                        keyField="id"
                        data={lyrics}
                        columns={columns}
                        bootstrap4
                        hover
                        condensed
                        noDataIndication={
                            <I18n t="apple.sample.assessment.lyric.empty" />
                        }
                        classes="table-bordered-header-fixed"
                        rowClasses={rowClasses}
                        expandRow={expandRow}
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Lyric;
