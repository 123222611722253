import React from "react";
import Task from "./Task";
import { useParams } from "react-router";

const EnhancedTask = (props) => {
    const { locale } = useParams();

    return <Task {...props} locale={locale} />;
};

export default EnhancedTask;
