import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";

const CheckedFilter = ({ filterOptions, value, setFilter }) => {
    return (
        <InputSelect
            onChange={(opt) => {
                setFilter((prev) => ({
                    ...prev,
                    checked: opt,
                }));
            }}
            value={value}
            placeholder={
                <I18n t="matching.artist.artist.filter.checked.placeholder" />
            }
            options={filterOptions}
        />
    );
};

export default CheckedFilter;
