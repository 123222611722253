import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import clsx from "clsx";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import useAPI from "shared/hooks/useApi";

import CypokLanguages from "./CypokLanguages";

const EnhancedCypokLanguages = ({ year, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: true,
        titles: [],
    });

    const fetchLanguages = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            titles: [],
        });
        api.get(`cnm/repository/cypok_languages/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        titles: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        titles: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchLanguages, [fetchLanguages]);

    const columns = [
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cypok_languages.artist"
            ),
            filterValue: normalizeTitle,
            headerStyle: {
                width: "25%",
            },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cypok_languages.title_column"
            ),
            filterValue: normalizeTitle,
            headerStyle: {
                width: "25%",
            },
        },
        {
            dataField: "languages",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cypok_languages.languages"
            ),
            formatter: (cell, row) =>
                Object.entries(cell)
                    .sort((a, b) => b[1] - a[1])
                    .map(([lang, words]) => (
                        <span
                            key={lang}
                            className={clsx(
                                "badge",
                                "mr-2",
                                lang === "fr" && words >= row.words / 2
                                    ? "badge-success"
                                    : "badge-secondary"
                            )}
                        >
                            {lang} - {words} / {row.words}
                        </span>
                    )),
            headerStyle: {
                width: "50%",
            },
        },
    ];

    return (
        <CypokLanguages
            columns={columns}
            onColumnMatch={onColumnMatchNormalized}
            {...state}
            {...props}
        />
    );
};

export default EnhancedCypokLanguages;
