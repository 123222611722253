import React from "react";
import SubTask from "./SubTask";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import swal from "sweetalert";

const EnhancedSubTask = (props) => {
    const location = useLocation();

    const showComment = (comment) => {
        swal(comment, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };
    return <SubTask {...props} showComment={showComment} />;
};

export default EnhancedSubTask;
