import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const AddToPlanningButton = ({ location, onClick }) => {
    return (
        <button
            className="btn btn-sm btn-primary"
            onClick={onClick}
            title={I18n.getTranslation(
                location,
                "common.charts.charts.addToPlanningButton.title"
            )}
        >
            <FontAwesomeIcon icon={faPlus} fixedWidth />
        </button>
    );
};

export default AddToPlanningButton;
