import React from "react";
import Chart from "./Chart";
import Loader from "shared/components/Loader";

const Recordings = ({ isLoading, recordings_creation, location }) => {
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div style={{ height: "500px" }}>
                    <Chart
                        recordings_creation={recordings_creation}
                        location={location}
                    />
                </div>
            )}
        </>
    );
};

export default Recordings;
