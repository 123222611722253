import React from "react";
import I18n from "shared/lib/I18n";
import Task from "./Task";
import Filter from "./Filter";
import Loader from "shared/components/Loader";
import InputSelect from "shared/components/Form/InputSelect";

const Tasks = ({
    tasks,
    filteredTasks,
    setFilteredTasks,
    isLoading,
    handleStatusChanged,
    handleDeleted,
    handleTasksChange,
    managerOptions,
    setManager,
}) => {
    let tasksList = (
        <tr>
            <td colSpan="42" style={{ textAlign: "center" }}>
                <I18n t="plannings.view.tasks.empty" />
            </td>
        </tr>
    );

    if (filteredTasks.length !== 0) {
        tasksList = filteredTasks.map((task) => (
            <Task
                key={task.id}
                task={task}
                handleStatusChanged={handleStatusChanged}
                handleDeleted={handleDeleted}
                handleTasksChange={handleTasksChange}
            />
        ));
    }

    return (
        <section className="widget pb-0">
            <header>
                <div className="row">
                    <div className="col-2 offset-10">
                        <InputSelect
                            onChange={(opt) => {
                                setManager(opt ? opt.value : null);
                            }}
                            placeholder={
                                <I18n t="plannings.view.tasks.manager" />
                            }
                            options={managerOptions}
                        />
                    </div>
                </div>
            </header>
            <div className="widget-body" id="matching-form">
                <div className="row">
                    <div className="col-md-12">
                        <Filter
                            tasks={tasks}
                            setFilteredTasks={setFilteredTasks}
                        />
                    </div>
                </div>
            </div>
            <div className="widget-body widget-table-overflow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped table-header-fixed"
                        style={{ marginBottom: "0px" }}
                    >
                        <thead>
                            <tr>
                                <th>
                                    <I18n t="plannings.view.tasks.artist" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.album" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.track" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.category" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.reason" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.classification" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.tasks" />
                                </th>
                                <th>
                                    <I18n t="plannings.view.tasks.information" />
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{tasksList}</tbody>
                    </table>
                )}
            </div>
        </section>
    );
};

export default Tasks;
