import React, { useState } from "react";
import MsStatistics from "./MsStatistics";
import { useLocation } from "react-router";

const EnhancedMsStatistics = (props) => {
    const [selectedTab, setSelectedTab] = useState("artists");

    const onSelectTab = (k) => setSelectedTab(k);

    return (
        <MsStatistics
            {...props}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
        />
    );
};

export default EnhancedMsStatistics;
