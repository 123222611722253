import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import YearList from "./YearList";

const EnhancedYearList = ({ ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: true,
        years: [],
    });

    const fetchYears = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            years: [],
        });
        api.get(`cnm/charts/aggregate`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        years: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        years: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(fetchYears, [fetchYears]);

    return <YearList {...state} {...props} />;
};

export default EnhancedYearList;
