import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Root = () => {
    return (
        <>
            <Outlet />
            <ToastContainer
                autoClose={8000}
                draggable={false}
                position="top-right"
                hideProgressBar={true}
                closeOnClick={true}
                rtl={false}
                pauseOnHover={true}
                icon={false}
            />
        </>
    );
};

export default Root;
