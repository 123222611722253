import React, { useEffect } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    role_fr: yup.string().required("utils.form.required"),
    role_en: yup.string().required("utils.form.required"),
    role_ja: yup.string().required("utils.form.required"),
    role_de: yup.string().required("utils.form.required"),
    role_ko: yup.string().required("utils.form.required"),
});

const EnhancedCreateOrUpdateModal = ({
    show,
    handleClose,
    role,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset({
            role_fr: role?.langs.find(({ lang }) => lang === "FR")?.name,
            role_en: role?.langs.find(({ lang }) => lang === "EN")?.name,
            role_ja: role?.langs.find(({ lang }) => lang === "JA")?.name,
            role_de: role?.langs.find(({ lang }) => lang === "DE")?.name,
            role_ko: role?.langs.find(({ lang }) => lang === "KO")?.name,
            role_pt: role?.langs.find(({ lang }) => lang === "PT")?.name,
            role_es: role?.langs.find(({ lang }) => lang === "ES")?.name,
            role_us: role?.langs.find(({ lang }) => lang === "US")?.name,
        });
    }, [reset, role, show]);

    const onSubmit = (data) => {
        let apiRequest;
        if (role?.id) {
            apiRequest = api.put(`role/${role.id}`, { locale }, data);
        } else {
            apiRequest = api.post(`role`, {}, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `role.manage.${role?.id ? "update" : "create"}.success`
                    )
                );
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `role.manage.${role?.id ? "update" : "create"}.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <CreateOrUpdateModal
            {...props}
            show={show}
            role={role}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
