import React, { useState, useEffect } from "react";
import Months from "./Months";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedMonths = ({ userId, ...props }) => {
    const [months, setMonths] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState();
    const { api } = useAPI();

    useEffect(() => {
        if (userId) {
            api.get(`plannings/billing/${userId}`)
                .then((response) => {
                    setMonths(response);
                    setSelected(response[response.length - 1]?.month);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [userId]);

    const getSelectedMonth = () => {
        return months.find(({ month }) => selected === month);
    };

    const getFirstMonth = () => {
        return months[0]?.month;
    };

    const getLastMonth = () => {
        return months[months.length - 1]?.month;
    };

    const setNextSelectedMonth = () => {
        setSelected(
            months[months.findIndex(({ month }) => month === selected) + 1]
                .month
        );
    };

    const setPreviousSelectedMonth = () => {
        setSelected(
            months[months.findIndex(({ month }) => month === selected) - 1]
                .month
        );
    };

    return (
        <Months
            {...props}
            userId={userId}
            month={getSelectedMonth()}
            first={getFirstMonth()}
            last={getLastMonth()}
            next={setNextSelectedMonth}
            previous={setPreviousSelectedMonth}
            isLoading={isLoading}
        />
    );
};

export default EnhancedMonths;
