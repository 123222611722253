import React from "react";
import DeleteButton from "./DeleteButton";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";
import { useParams } from "react-router-dom";

const EnhancedDeleteButton = ({ pictureId, onSuccess, ...props }) => {
    const { api } = useApi();
    const location = useLocation();
    const { locale } = useParams();

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.picture.action.del.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.picture.action.del.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.delete(`picture/${pictureId}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "common.picture.action.del.success"
                        )
                    );
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "common.picture.action.del.error",
                            error.message
                        )
                    );
                });
        });
    };

    return (
        <DeleteButton
            {...props}
            handleDelete={handleDelete}
            location={location}
        />
    );
};

export default EnhancedDeleteButton;
