import React from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import DeleteButton from "./DeleteButton";

const EnhancedDeleteButton = ({ fileId, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const onClick = () => {
        api.delete(`snep/file/${fileId}`, { locale })
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "snep.file.fileList.delete.success"
                    )
                );
                onSuccess();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return <DeleteButton {...props} onClick={onClick} location={location} />;
};

export default EnhancedDeleteButton;
