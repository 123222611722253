import { createSelector } from "reselect";

export const getFilteredTasks = createSelector(
    [(args) => args.tasks, (args) => args.filter],
    (tasks, filter) => {
        let filteredTasks = tasks;

        if (filter.category !== null) {
            filteredTasks = filteredTasks.filter(
                (p) => p.category === filter.category.value
            );
        }

        return filteredTasks;
    }
);

export const getCategoryOptions = createSelector(
    [(args) => args.tasks],
    (tasks) =>
        tasks.reduce((acc, curr) => {
            if (curr.category) {
                acc = [
                    ...acc,
                    {
                        label: curr.category,
                        value: curr.category,
                    },
                ].filter(
                    (row, index, self) =>
                        self.findIndex((t) => t.value === row.value) === index
                );
            }

            return acc;
        }, [])
);
