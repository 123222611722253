import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";

const Row = ({ planning, selected }) => {
    return (
        <tr className={selected === planning.id ? "table-info" : undefined}>
            <td>
                <I18n
                    t={`plannings.picture.planning.${planning.status.toLowerCase()}`}
                />
            </td>
            <td>{planning.title ?? "-"}</td>
            <td>
                <NavLink to={`/plannings/picture/${planning.id}`}>
                    {planning.start_date}
                </NavLink>
            </td>
            <td>
                <NavLink to={`/plannings/picture/${planning.id}`}>
                    {planning.end_date}
                </NavLink>
            </td>
        </tr>
    );
};

export default Row;
