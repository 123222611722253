import React from "react";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import { useLocation } from "react-router";

const SearchForm = ({ isLoading, onSubmit, register, errors }) => {
    const location = useLocation();
    return (
        <div className="row">
            <div className="col-md-12">
                <section className="widget">
                    <div className="widget-body">
                        <form onSubmit={onSubmit}>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.search.artist" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("artist")}
                                            error={
                                                errors.artist && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.artist.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="moodmedia.search.title" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("title")}
                                            error={
                                                errors.title && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.title.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-block btn-info"
                                            disabled={isLoading}
                                        >
                                            <I18n t="utils.button.search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SearchForm;
