import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ArtistLink from "shared/components/ArtistLink";
import I18n from "shared/lib/I18n";

const DuplicatesModalButton = ({
    show,
    onShow,
    onHide,
    duplicates,
    location,
}) => {
    if (duplicates.length === 0) {
        return null;
    }

    return (
        <>
            <Button
                variant="outline-warning"
                onClick={onShow}
                title={I18n.getTranslation(
                    location,
                    "artist.management.social.duplicates.tooltip"
                )}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
            </Button>
            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="artist.management.social.duplicates.head" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <I18n t="artist.management.social.duplicates.body" />
                    </p>
                    <ul>
                        {duplicates.map(({ id, name }) => (
                            <li key={id}>
                                <ArtistLink
                                    artistId={id}
                                    content={name}
                                    blank
                                />
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DuplicatesModalButton;
