import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputFile from "shared/components/Form/InputFile";
import ImageLoader from "shared/components/ImageLoader";
import I18n from "shared/lib/I18n";
import moment from "moment";
import Loader from "shared/components/Loader";
import Stepper from "shared/components/Stepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import "./ImageUploadModal.css";

const ImageUploadModal = ({
    show,
    handleHide,
    handleDelete,
    errors,
    register,
    control,
    isSubmitting,
    isSubmittingGetty,
    isDirty,
    isDisabled,
    isGettingUpscale,
    isGettingDownscale,
    onSubmit,
    onSubmitGetty,
    fileWatch,
    linkWatch,
    imageDimensions,
    location,
    isGetty,
    gettyPicture,
}) => {
    const steps = [
        {
            title: I18n.getTranslation(
                location,
                "common.imageUploadModal.upload"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.imageUploadModal.legal"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.imageUploadModal.cropping"
            ),
        },
        {
            title: I18n.getTranslation(
                location,
                "common.imageUploadModal.tagging"
            ),
        },
    ];
    const activeStep = 0;

    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            className="modal-add-image"
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <Stepper steps={steps} activeStep={activeStep} />
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                            if (isDirty) {
                                handleDelete();
                            } else {
                                handleHide();
                            }
                        }}
                        fixedWidth
                    />
                </div>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body className="modal-add-image">
                    <div className="container-fluid">
                        <div className="row" style={{ padding: "10px" }}>
                            <div className="col">
                                {fileWatch || linkWatch ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ImageLoader
                                            src={
                                                fileWatch
                                                    ? URL.createObjectURL(
                                                          fileWatch
                                                      )
                                                    : linkWatch
                                            }
                                            style={{
                                                maxHeight: "450px",
                                                maxWidth: "500px",
                                            }}
                                        />
                                    </div>
                                ) : gettyPicture ? (
                                    <div style={{ textAlign: "center" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <ImageLoader
                                                src={
                                                    gettyPicture.display_sizes.find(
                                                        ({ name }) =>
                                                            name === "preview"
                                                    )?.uri
                                                }
                                                style={{
                                                    maxHeight: "450px",
                                                    maxWidth: "500px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className={"upload-placeholder"}>
                                            <FontAwesomeIcon
                                                icon={faImage}
                                                className="mr-2"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col">
                                {!isGetty && (
                                    <>
                                        <InputText
                                            {...register("link")}
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "common.imageUploadModal.link"
                                            )}
                                            error={
                                                errors.link && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.link.message
                                                    ),
                                                ]
                                            }
                                        />
                                        <hr />
                                        <Controller
                                            control={control}
                                            name="file"
                                            render={({
                                                field: {
                                                    onChange,
                                                    value,
                                                    name,
                                                    ref,
                                                },
                                            }) => (
                                                <InputFile
                                                    name={name}
                                                    ref={ref}
                                                    dataBrowse={I18n.getTranslation(
                                                        location,
                                                        "common.imageUploadModal.browse"
                                                    )}
                                                    onChange={(e) => {
                                                        onChange(
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                    label={
                                                        value !== null
                                                            ? value.name
                                                            : I18n.getTranslation(
                                                                  location,
                                                                  "common.imageUploadModal.file"
                                                              )
                                                    }
                                                    error={
                                                        errors.file && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.file
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    accept="image/jpeg,image/png,.jpg,.jpeg,.png"
                                                />
                                            )}
                                        />
                                        <hr />
                                    </>
                                )}
                                <div className="form-group">
                                    <div
                                        className={`input-group mb-3 ${
                                            isDisabled && "has-error"
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <label
                                                className="input-group-text control-label"
                                                htmlFor="crop_width"
                                            >
                                                <I18n t="common.imageCropModal.width" />
                                            </label>
                                        </div>
                                        <input
                                            className="form-control"
                                            readOnly="readonly"
                                            placeholder={
                                                gettyPicture
                                                    ? gettyPicture
                                                          .max_dimensions.width
                                                    : imageDimensions.width
                                            }
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                px
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={`input-group mb-3 ${
                                            isDisabled && "has-error"
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <label
                                                className="input-group-text control-label"
                                                htmlFor="crop_height"
                                            >
                                                <I18n t="common.imageCropModal.height" />
                                            </label>
                                        </div>
                                        <input
                                            className="form-control"
                                            readOnly="readonly"
                                            placeholder={
                                                gettyPicture
                                                    ? gettyPicture
                                                          .max_dimensions.height
                                                    : imageDimensions.height
                                            }
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                px
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {isGettingUpscale && (
                                    <span>
                                        <I18n t="common.imageUploadModal.warning_upscale" />
                                    </span>
                                )}
                                {isGettingDownscale && (
                                    <span>
                                        <I18n t="common.imageUploadModal.warning_downscale" />
                                    </span>
                                )}
                                {gettyPicture && (
                                    <table className="table table-bordered table-hover table-sm border-left-0 border-right-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <I18n t="common.picture.getty.bas.action.detail.date_created" />
                                                    </strong>
                                                </td>
                                                <td>
                                                    {gettyPicture.date_created
                                                        ? moment(
                                                              gettyPicture.date_created
                                                          ).format("YYYY-MM-DD")
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <I18n t="common.picture.getty.bas.action.detail.people" />
                                                    </strong>
                                                </td>
                                                <td>
                                                    {gettyPicture.people
                                                        .length > 0 ? (
                                                        <ul className="mb-0">
                                                            {gettyPicture.people.map(
                                                                (row) => (
                                                                    <li
                                                                        key={
                                                                            row
                                                                        }
                                                                    >
                                                                        {row}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <I18n t="common.picture.getty.bas.action.detail.caption" />
                                                    </strong>
                                                </td>
                                                <td>
                                                    {gettyPicture.caption ??
                                                        "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={
                            isGetty && !isSubmittingGetty
                                ? false
                                : isSubmitting || !isDirty
                        }
                        onClick={
                            !isSubmittingGetty && isGetty
                                ? () => {
                                      onSubmitGetty();
                                  }
                                : !isSubmitting && isDirty
                                ? () => {
                                      onSubmit();
                                  }
                                : null
                        }
                    >
                        {isSubmitting || isSubmittingGetty ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t={`utils.button.upload`} />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ImageUploadModal;
