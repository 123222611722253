import React from "react";
import I18n from "shared/lib/I18n";
import FileList from "./FileList";
import { Helmet } from "react-helmet";
import "./SnepFileManagement.css";

const SnepFileManagement = ({ location }) => {
    return (
        <div className="content-wrap snep-file-management">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "snep.file.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="snep.file.breadcrumb" />
                    </li>
                </ol>

                <FileList />
            </main>
        </div>
    );
};

export default SnepFileManagement;
