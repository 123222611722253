import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const AlbumSelect = ({ id, title, loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={
                id !== null &&
                typeof id !== "undefined" &&
                title !== null &&
                typeof title !== "undefined"
                    ? {
                          value: { id, title },
                          label: `${title} (${id})`,
                      }
                    : null
            }
            loadOptions={loadOptions}
            onChange={onChange}
            {...props}
        />
    );
};

export default AlbumSelect;
