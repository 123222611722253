import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PicturesLabelRejected from "./PicturesLabelRejected";
import { useLocation } from "react-router";

const EnhancedPicturesLabelRejected = (props) => {
    const [rejectedGallery, setRejectedGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { api } = useAPI();
    const location = useLocation();

    const getGalleries = useCallback(() => {
        api.get("picture/label/rejected")
            .then((response) => {
                setRejectedGallery(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getGalleries, [getGalleries]);

    return (
        <PicturesLabelRejected
            {...props}
            rejectedGallery={rejectedGallery}
            getGalleries={getGalleries}
            isLoading={isLoading}
            location={location}
        />
    );
};

export default EnhancedPicturesLabelRejected;
