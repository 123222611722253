import React from "react";
import Loader from "shared/components/Loader";
import List from "./List";
import { SIMPLE, CREATION, DISCARDED, FORGOT } from "./Coverage.constants";

const Coverage = ({ coverage, isLoading, exportName }) => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <List
                id={SIMPLE}
                data={coverage.simple}
                title="data_external.internationals_charts.coverage.simple"
                exportName={exportName}
            />
            <List
                id={CREATION}
                data={coverage.creation}
                title="data_external.internationals_charts.coverage.creation"
                exportName={exportName}
            />
            <List
                id={DISCARDED}
                data={coverage.discarded}
                title="data_external.internationals_charts.coverage.discarded"
                exportName={exportName}
            />
            <List
                id={FORGOT}
                data={coverage.forgot}
                title="data_external.internationals_charts.coverage.forgot"
                exportName={exportName}
            />
        </>
    );
};

export default Coverage;
