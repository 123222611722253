import React from "react";
import clsx from "clsx";

const AlbumLink = ({ albumId, content, part, locale, className, blank }) => {
    if (!albumId) {
        return content;
    }

    const href =
        "/" +
        [locale, "album", albumId, "view", part].filter(Boolean).join("/");

    if (blank) {
        return (
            <a
                href={href}
                className={clsx(className)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {content}
            </a>
        );
    } else {
        return (
            <a href={href} className={clsx(className)}>
                {content}
            </a>
        );
    }
};

export default AlbumLink;
