import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import ProfileInformation from "./ProfileInformation";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    ProfileContext,
    ARTIST_TYPE_SET,
} from "pages/ArtistManagement/Profile";
import { ArtistManagementContext, ARTIST_SET } from "pages/ArtistManagement";

const schema = yup.object().shape({
    type: yup.string().required("utils.form.required"),
    name: yup.string().trim().required("utils.form.required"),
    complement: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    realname: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    gender: yup
        .string()
        .nullable()
        .when(["type"], {
            is: (type) => type === "Personne",
            then: () => yup.string().nullable().required("utils.form.required"),
        })
        .transform((value) => value || null),
    country: yup.number().required("utils.form.required"),
    isni: yup
        .string()
        .nullable()
        .transform((value) => value?.replace(/\s+/g, "") || null)
        .matches(/^[0-9]{15}[0-9X]$/, "utils.form.isni_format"),
});

const EnhancedProfileInformation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { dispatchProfile, artistType } = useContext(ProfileContext);
    const { dispatchArtist, artist } = useContext(ArtistManagementContext);

    const [showISNISearch, setShowISNISearch] = useState(false);
    const onShowISNISearch = () => setShowISNISearch(true);
    const onHideISNISearch = () => setShowISNISearch(false);

    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            type: null,
            name: null,
            complement: null,
            realname: null,
            gender: null,
            country: null,
            isni: null,
        },
    });
    const watchType = watch("type");
    const watchISNI = watch("isni");
    const setISNI = (isni) =>
        setValue("isni", isni, {
            shouldValidate: true,
            shouldDirty: true,
        });
    const getName = () => getValues("name");

    useEffect(() => {
        if (watchType) {
            dispatchProfile({
                type: ARTIST_TYPE_SET,
                data: watchType,
            });
        }
    }, [watchType, dispatchProfile]);

    const getProfileInformation = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/profile/information`, { locale })
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getProfileInformation, [getProfileInformation]);

    const [countryOptions, setCountryOptions] = useState([]);
    const getCountry = useCallback(() => {
        api.get(`country/${locale}`)
            .then((response) => {
                setCountryOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getCountry, [getCountry]);

    const [typeOptions, setTypeOptions] = useState([]);
    const getType = useCallback(() => {
        api.get(`artist/type/${locale}`)
            .then((response) => {
                setTypeOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getType, [getType]);

    const [genderOptions, setGenderOptions] = useState([]);
    const getGender = useCallback(() => {
        api.get(`artist/gender/${locale}`)
            .then((response) => {
                setGenderOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getGender, [getGender]);

    const onSubmit = async (data) => {
        await api
            .put(`artist/${artistId}/profile/information`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.profileInformation.update.success`
                    )
                );
                reset(response);
                if (response.type !== artistType) {
                    dispatchProfile({
                        type: ARTIST_TYPE_SET,
                        data: response.type,
                    });
                }
                dispatchArtist({
                    type: ARTIST_SET,
                    data: {
                        ...artist,
                        nom: response.name,
                        complement: response.complement,
                    },
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.profileInformation.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <ProfileInformation
            {...props}
            getProfileInformation={getProfileInformation}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            artistType={artistType}
            countryOptions={countryOptions}
            typeOptions={typeOptions}
            genderOptions={genderOptions}
            watchISNI={watchISNI}
            setISNI={setISNI}
            getName={getName}
            showISNISearch={showISNISearch}
            onShowISNISearch={onShowISNISearch}
            onHideISNISearch={onHideISNISearch}
        />
    );
};

export default EnhancedProfileInformation;
