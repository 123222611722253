import React from "react";
import Widget from "shared/components/Widget";
import ArtistLink from "shared/components/ArtistLink";
import AlbumLink from "shared/components/AlbumLink";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import { displayAsString } from "shared/functions/Date";
import "./CoPerformer.css";

const CoPerformer = ({ coPerformer, isLoading, locale }) => {
    return (
        <Widget
            title="artist.view.creditsCoPerformer.head"
            className="artist-view-co-performer"
            isLoading={isLoading}
            style={{ maxHeight: "24rem", overflow: "auto" }}
            headerClassName="sticky-top bg-white"
            headerStyle={{ top: "-15px" }}
        >
            <Widget.Body>
                <hr className="my-2 sticky-top" style={{ top: "37px" }} />
                <div className="row">
                    <div className="col">
                        {coPerformer.map(
                            (
                                { id, title, release_date, cover, artists },
                                index
                            ) => (
                                <div key={id}>
                                    {index !== 0 && <hr className="my-2" />}
                                    <div className="d-flex align-items-center">
                                        {cover ? (
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Popover
                                                        id={`popover-positioned-top`}
                                                    >
                                                        <Popover.Content className="bg-black rounded-sm">
                                                            <ImageLoader
                                                                src={cover}
                                                                style={{
                                                                    width: "400px",
                                                                    height: "400px",
                                                                    objectFit:
                                                                        "contain",
                                                                }}
                                                            />
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            >
                                                <div>
                                                    <ImageLoader
                                                        src={cover}
                                                        className="img-40"
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                        ) : (
                                            <div className="img-40 d-flex justify-content-center align-items-center">
                                                -
                                            </div>
                                        )}
                                        <span className="ml-2 pl-2 border-left align-self-stretch d-flex align-items-center">
                                            <AlbumLink
                                                albumId={id}
                                                content={title}
                                            />
                                            {artists.map((artist) => (
                                                <React.Fragment key={artist.id}>
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                    <ArtistLink
                                                        artistId={artist.id}
                                                        content={artist.name}
                                                        view
                                                    />
                                                </React.Fragment>
                                            ))}
                                            {release_date && (
                                                <>
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                    {displayAsString(
                                                        release_date,
                                                        locale
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default CoPerformer;
