import React from "react";
import Widget from "shared/components/Widget";
import InputSwitch from "shared/components/Form/InputSwitch";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import I18n from "shared/lib/I18n";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Colors,
    zoomPlugin
);

const Trend = ({
    data,
    options,
    isLoading,
    dynamicRank,
    dynamicDate,
    setDynamic,
    location,
}) => {
    return (
        <Widget
            title="recording.view.chartsRecordingCharts.trend.head"
            isLoading={isLoading}
            actions={
                <div className="d-flex">
                    <div className="mr-2">
                        <InputSwitch
                            checked={dynamicDate}
                            onChange={() =>
                                setDynamic((prev) => ({
                                    ...prev,
                                    date: !prev.date,
                                }))
                            }
                            label={I18n.getTranslation(
                                location,
                                "recording.view.chartsRecordingCharts.trend.dynamicDate"
                            )}
                        />
                    </div>
                    <div>
                        <InputSwitch
                            checked={dynamicRank}
                            onChange={() =>
                                setDynamic((prev) => ({
                                    ...prev,
                                    rank: !prev.rank,
                                }))
                            }
                            label={I18n.getTranslation(
                                location,
                                "recording.view.chartsRecordingCharts.trend.dynamicRank"
                            )}
                        />
                    </div>
                </div>
            }
        >
            <Widget.Body className="d-flex justify-content-center">
                <Line options={options} data={data} />
            </Widget.Body>
        </Widget>
    );
};

export default Trend;
