import React, { useContext } from "react";
import Action from "./Action";
import { useLocation } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
    SidebarNotificationContext,
    PICTURES_LABEL_AWAITING_DECREMENT,
} from "layout/SidebarNotification";
import I18n from "shared/lib/I18n";

const EnhancedAction = ({ id_picture, onSuccess, ...props }) => {
    const location = useLocation();
    const {
        picturesLabelAwaiting: { dispatch },
    } = useContext(SidebarNotificationContext);
    const { api } = useAPI();

    const setStatus = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "pictures.label.validation.validate.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.validate.alert.text"
            ),
            icon: "warning",
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.put(
                `picture/label/${id_picture}/status`,
                {},
                { status: "AWAITING_FOR_PROCESSING" }
            )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "pictures.label.validation.validate.success"
                        )
                    );
                    dispatch({
                        type: PICTURES_LABEL_AWAITING_DECREMENT,
                    });
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "pictures.label.validation.validate.error",
                            error.message
                        )
                    );
                });
        });
    };

    const setRejected = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "pictures.label.validation.reject.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "pictures.label.validation.reject.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            var textarea = document.createElement("textarea");
            textarea.rows = 6;
            textarea.className = "swal-content__textarea";

            swal({
                title: I18n.getTranslation(
                    location,
                    "pictures.label.validation.reject.alert.commentTitle"
                ),
                content: textarea,
                buttons: {
                    validate: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.validate"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isValidated) => {
                if (!isValidated) {
                    return;
                }

                const newComment = textarea.value || null;
                api.put(
                    `picture/label/${id_picture}/status`,
                    {},
                    { status: "REJECTED", motif: newComment }
                )
                    .then((response) => {
                        toast.success(
                            I18n.getTranslation(
                                location,
                                "pictures.label.validation.reject.success"
                            )
                        );
                        dispatch({
                            type: PICTURES_LABEL_AWAITING_DECREMENT,
                        });
                        onSuccess?.();
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(
                            I18n.getTranslation(
                                location,
                                "pictures.label.validation.reject.error",
                                error.message
                            )
                        );
                    });
            });
        });
    };

    return (
        <Action
            {...props}
            setStatus={setStatus}
            setRejected={setRejected}
            location={location}
        />
    );
};

export default EnhancedAction;
