import React from "react";
import { NavLink } from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faCompactDisc,
    faTrashAlt,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import AddToPlanningButton from "shared/components/ArtistListAction/AddToPlanningButton";

const ArtistListAction = ({ user, msArtist }) => {
    return (
        <>
            <NavLink
                to={`/artist/${msArtist.id}/view`}
                className="btn btn-primary btn-sm mr-1"
            >
                <FontAwesomeIcon icon={faEye} size="lg" fixedWidth />
            </NavLink>
            {user.hasRight("artist.manage.information") && (
                <NavLink
                    to={`/artist/${msArtist.id}/edit`}
                    className="btn btn-primary btn-sm mr-1"
                >
                    <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
                </NavLink>
            )}
            {user.hasRight("plannings.manage") && (
                <AddToPlanningButton msArtist={msArtist} />
            )}
            {user.hasRight("artist.disco.manage") && (
                <NavLink
                    to={`/artist/${msArtist.id}/edit/discography`}
                    className="btn btn-primary btn-sm mr-1"
                >
                    <FontAwesomeIcon
                        icon={faCompactDisc}
                        size="lg"
                        fixedWidth
                    />
                </NavLink>
            )}
            {user.hasRight("artist.delete") && (
                <NavLink
                    to={`/artist/${msArtist.id}/delete`}
                    className="btn btn-danger btn-sm"
                >
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" fixedWidth />
                </NavLink>
            )}
        </>
    );
};

export default ArtistListAction;
