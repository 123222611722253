import React, { useState, useEffect, useCallback } from "react";
import MiscEntity from "./MiscEntity";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "shared/lib/I18n";
import Delete from "./Button/Delete";

const EnhancedMiscEntity = (props) => {
    const [miscEntity, setMiscEntity] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { api } = useAPI();
    const location = useLocation();
    const navigate = useNavigate();
    const { locale } = useParams();

    const getMiscEntity = useCallback(() => {
        setIsLoading(true);
        api.get(`miscentity`, { locale })
            .then((response) => {
                setMiscEntity(response);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [locale]);
    useEffect(getMiscEntity, [getMiscEntity]);

    const onDelete = (id) =>
        setMiscEntity((prev) => prev.filter((entity) => entity.id !== id));

    const onCreate = (id) => navigate(`/${locale}/misc-entity/${id}/edit`);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "misc_entity.id"),
            sort: true,
        },
        {
            dataField: "type.name",
            text: I18n.getTranslation(location, "misc_entity.type"),
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "misc_entity.name"),
            sort: true,
        },
        {
            dataField: "description",
            text: I18n.getTranslation(location, "misc_entity.description"),
            sort: true,
        },
        {
            dataField: "date",
            text: I18n.getTranslation(location, "misc_entity.date"),
            sort: true,
        },
        {
            dataField: "actions",
            isDummyField: true,
            text: "",
            formatter: (cell, row) => (
                <>
                    <NavLink
                        to={`/misc-entity/${row.id}/edit`}
                        className="btn btn-primary btn-sm mr-1"
                        title={I18n.getTranslation(
                            location,
                            "misc_entity.management.update.title"
                        )}
                    >
                        <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
                    </NavLink>
                    <Delete id={row.id} onSuccess={() => onDelete(row.id)} />
                </>
            ),
            headerStyle: {
                width: "8em",
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    return (
        <MiscEntity
            miscEntity={miscEntity}
            columns={columns}
            isLoading={isLoading}
            defaultSorted={defaultSorted}
            onCreate={onCreate}
            location={location}
            {...props}
        />
    );
};

export default EnhancedMiscEntity;
