import React from "react";
import Delete from "./Delete";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n, { Link } from "shared/lib/I18n";

const EnhancedDelete = ({ id, onSuccess, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "labelPromo.manage.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "labelPromo.manage.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.delete(`label-promo/${id}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "labelPromo.manage.delete.success"
                        )
                    );
                    onSuccess?.();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        <>
                            <span>
                                {I18n.getTranslation(
                                    location,
                                    "labelPromo.manage.delete.error",
                                    error.message
                                )}
                            </span>
                            <br />
                            {error.body?.artists && (
                                <ul>
                                    {error.body?.artists.map(
                                        ({ id_artist, name_artist }) => {
                                            return (
                                                <li key={id_artist}>
                                                    <Link
                                                        href={`/artist/${id_artist}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {name_artist}
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            )}
                            {error.body?.users && (
                                <ul>
                                    {error.body?.users.map(
                                        ({ id, username }) => {
                                            return <li key={id}>{username}</li>;
                                        }
                                    )}
                                </ul>
                            )}
                        </>
                    );
                });
        });
    };

    return <Delete {...props} onClick={onClick} location={location} />;
};

export default EnhancedDelete;
