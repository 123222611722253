import React from "react";
import { ListGroupItem, Row, Col } from "react-bootstrap";
import DeleteButton from "./DeleteButton";
import I18n from "shared/lib/I18n";
import "./RatingLine.css";

const RatingLine = ({
    id,
    criteria,
    errorType,
    value,
    comment,
    user,
    ...props
}) => {
    return (
        <ListGroupItem className="apple__sample__assessment__ratings__rating">
            {user.hasRight("apple.sample.assessment.manage") && (
                <span className="apple__sample__assessment__ratings__rating__delete">
                    <DeleteButton id={id} {...props} />
                </span>
            )}
            <Row className="apple__sample__assessment__ratings__rating__row">
                <div className="text-right">
                    <strong>
                        <I18n t="apple.sample.assessment.rating.criteria" />
                    </strong>
                </div>
                <Col>
                    <span>{criteria}</span>
                </Col>
            </Row>
            <Row className="apple__sample__assessment__ratings__rating__row">
                <div className="text-right">
                    <strong>
                        <I18n t="apple.sample.assessment.rating.errorType" />
                    </strong>
                </div>
                <Col>
                    <span>
                        {errorType} (<strong> - {value}</strong> )
                    </span>
                </Col>
            </Row>
            <Row className="apple__sample__assessment__ratings__rating__row">
                <div className="text-right">
                    <strong>
                        <I18n t="apple.sample.assessment.rating.comment" />
                    </strong>
                </div>
                <Col>
                    <span className="pre-wrap">{comment}</span>
                </Col>
            </Row>
        </ListGroupItem>
    );
};

export default RatingLine;
