import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import clsx from "clsx";
import InputSwitch from "shared/components/Form/InputSwitch";
import InputSelect from "shared/components/Form/InputSelect";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Charts = ({
    isLoading,
    charts,
    columns,
    selectRow,
    onColumnMatch,
    location,
    filterOptions,
    filter,
    setFilter,
}) => {
    return (
        <ToolkitProvider
            keyField="id"
            data={charts}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <Widget
                    title="recording.view.chartsRecordingCharts.charts.head"
                    isLoading={isLoading}
                    className="pb-0"
                    actions={
                        <InputSwitch
                            checked={filter.reference}
                            onChange={() =>
                                setFilter((prev) => ({
                                    ...prev,
                                    reference: !filter.reference,
                                }))
                            }
                            label={
                                <>
                                    <strong>
                                        <I18n t="recording.view.chartsRecordingCharts.filter.reference.label" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.chartsRecordingCharts.filter.reference.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </>
                            }
                        />
                    }
                >
                    <Widget.Body>
                        <div className="mb-2">
                            <InputSelect
                                placeholder={
                                    <I18n t="recording.view.chartsRecordingCharts.filter.format.label" />
                                }
                                value={filter.format}
                                options={filterOptions.format}
                                onChange={(format) => {
                                    setFilter((prev) => ({
                                        ...prev,
                                        format,
                                    }));
                                }}
                                isOptionSelected={(e, s) =>
                                    s.find((a) => a.value === e.value)
                                }
                                closeMenuOnSelect={false}
                                hideSelectedOptions={true}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                isMulti
                            />
                        </div>
                        <div>
                            <Search.SearchBar
                                {...toolkitprops.searchProps}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "recording.view.chartsRecordingCharts.charts.search"
                                )}
                            />
                        </div>
                    </Widget.Body>
                    <Widget.Body
                        className="widget-table-overflow mt-4 border-top"
                        style={{ overflow: "auto", height: "566px" }}
                    >
                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            hover
                            condensed
                            bordered={false}
                            noDataIndication={
                                <I18n t="recording.view.chartsRecordingCharts.charts.empty" />
                            }
                            classes={clsx(
                                "mb-0 table-header-fixed table-truncate",
                                charts.length > 0 && "table-selectable"
                            )}
                            selectRow={selectRow}
                        />
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Charts;
