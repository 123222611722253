import React from "react";
import useUser from "shared/hooks/useUser";
import RepositoryList from "./RepositoryList";

const EnhancedRepositoryList = (props) => {
    const { user } = useUser();
    const repositories = [
        "cnm_artists_titles",
        "cnm_distributors",
        "cnm_genres",
        "cnm_labels",
        "csa_artists",
        "cypok_languages",
        "platforms",
    ].filter((repository) =>
        user.hasRight(
            `cnm.repository.${repository}.show`,
            `cnm.repository.${repository}.manage`
        )
    );

    return <RepositoryList repositories={repositories} {...props} />;
};

export default EnhancedRepositoryList;
