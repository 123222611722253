import React, { useReducer, useMemo } from "react";
import { DiscographyContext } from "./Discography.context";
import { DiscographyReducer } from "./Discography.reducer";

const initialState = {
    albums: [],
    releases: [],
    tracksLoading: false,
    tracks: [],
    sandbox: {
        artistAlbums: [],
        searchResults: [],
    },
    albumHasReview: false,
    releaseMatchingUpToDate: true,
    albumCoverUpToDate: true,
    trackBasicUpToDate: true,
};

export const DiscographyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DiscographyReducer, initialState);
    const contextValue = useMemo(
        () => ({
            dispatchDiscography: dispatch,
            albums: state.albums,
            releases: state.releases,
            tracksLoading: state.tracksLoading,
            tracks: state.tracks,
            sandbox: state.sandbox,
            albumHasReview: state.albumHasReview,
            releaseMatchingUpToDate: state.releaseMatchingUpToDate,
            albumCoverUpToDate: state.albumCoverUpToDate,
            trackBasicUpToDate: state.trackBasicUpToDate,
        }),
        [state, dispatch]
    );

    return (
        <DiscographyContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </DiscographyContext.Provider>
    );
};
