import { createSelector } from "reselect";

export const getFilteredRows = createSelector(
    [(args) => args.value, (args) => args.rows],
    (value, rows) => {
        let filteredRows = rows;

        if (value) {
            filteredRows = filteredRows.filter((r) =>
                r.task.ms_artist.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            );
        }

        return filteredRows;
    }
);
