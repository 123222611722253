import React from "react";
import I18n from "shared/lib/I18n";
import InternationalsCharts from "./InternationalsCharts";
import Stats from "./Stats";
import Coverage from "./Coverage";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import "./DataExternalInternationalsCharts.css";

const DataExternalInternationalsCharts = ({
    selected,
    setSelected,
    startDate,
    endDate,
    previous,
    next,
    hasNext,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "data_external.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "data_external.internationals_charts.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="data_external.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="data_external.internationals_charts.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-end">
                        <button
                            type="button"
                            onClick={previous}
                            className="btn btn-primary btn-lg"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                            <span className="ml-3">
                                <I18n t="utils.button.previous" />
                            </span>
                        </button>
                        <div className="text-center">
                            <h2>{`${startDate} - ${endDate}`}</h2>
                        </div>
                        <button
                            type="button"
                            onClick={next}
                            className="btn btn-primary btn-lg"
                            disabled={!hasNext() ? "disabled" : undefined}
                        >
                            <span className="mr-3">
                                <I18n t="utils.button.next" />
                            </span>
                            <FontAwesomeIcon icon={faArrowRight} size="lg" />
                        </button>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <Tab.Container
                            id="internationals-charts-tab"
                            activeKey={selected}
                            onSelect={(k) => setSelected(k)}
                            mountOnEnter
                        >
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="charts">
                                        <I18n t="data_external.internationals_charts.charts.nav" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="stats">
                                        <I18n t="data_external.internationals_charts.stats.nav" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="coverage">
                                        <I18n t="data_external.internationals_charts.coverage.nav" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="nav-content overflow-hidden">
                                <Tab.Pane
                                    eventKey="charts"
                                    className="nav-pane"
                                >
                                    <InternationalsCharts />
                                </Tab.Pane>
                                <Tab.Pane eventKey="stats" className="nav-pane">
                                    <Stats
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="coverage"
                                    className="nav-pane"
                                >
                                    <Coverage
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default DataExternalInternationalsCharts;
