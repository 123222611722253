import React from "react";
import { Controller } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputSelect from "shared/components/Form/InputSelect";
import InputText from "shared/components/Form/InputText";

const Translations = ({
    fields,
    errors,
    languages,
    register,
    control,
    append,
    remove,
    onSubmit,
    fetchTranslations,
    isLoading,
    isDirty,
    isValid,
    isSubmitting,
    location,
}) => {
    return (
        <Form
            onSubmit={
                !isSubmitting && !isLoading && isDirty && isValid
                    ? onSubmit
                    : null
            }
        >
            <Widget
                title="artist.management.disco.objects.track.translations.head"
                enableCollapse
            >
                <Widget.Body
                    enableCollapse
                    className="widget-table-overflow flex-grow-1"
                    style={{
                        height: "250px",
                        overflow: "auto",
                        borderTop: "1px solid #dee2e6",
                        marginBottom: "15px",
                    }}
                >
                    {isLoading ? (
                        <Loader
                            className="h-100 align-items-center"
                            size="3x"
                        />
                    ) : (
                        <table
                            className="table table-striped table-hover table-sm table-header-fixed"
                            style={{ tableLayout: "fixed" }}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        <I18n t="artist.management.disco.objects.track.translations.language" />
                                    </th>
                                    <th>
                                        <I18n t="artist.management.disco.objects.track.translations.title" />
                                    </th>
                                    <th>
                                        <I18n t="artist.management.disco.objects.track.translations.subtitle" />
                                    </th>
                                    <th
                                        style={{
                                            width: "calc(1.25em + 2px + 2.6rem)",
                                        }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>
                                            <Controller
                                                name={`translations[${index}].language`}
                                                control={control}
                                                defaultValue={item.language}
                                                render={({
                                                    field: { value },
                                                }) => value.name}
                                            />
                                        </td>
                                        <td>
                                            <InputText
                                                {...register(
                                                    `translations[${index}].title`
                                                )}
                                                defaultValue={item.title}
                                                showError={false}
                                                error={
                                                    errors?.translations?.[
                                                        index
                                                    ]?.title && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.translations[
                                                                index
                                                            ].title.message
                                                        ),
                                                    ]
                                                }
                                            />
                                        </td>
                                        <td>
                                            <InputText
                                                {...register(
                                                    `translations[${index}].subtitle`
                                                )}
                                                defaultValue={item.subtitle}
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                onClick={() => remove(index)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    fixedWidth
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>
                                        <InputSelect
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "artist.management.disco.objects.track.translations.languagePlaceholder"
                                            )}
                                            options={languages.map(
                                                ({ id, name }) => ({
                                                    value: id,
                                                    label: name,
                                                })
                                            )}
                                            isOptionDisabled={({ value }) =>
                                                fields.find(
                                                    ({ language }) =>
                                                        language.id === value
                                                ) !== undefined
                                            }
                                            isClearable={false}
                                            value={null}
                                            onChange={(opt, action) => {
                                                if (
                                                    action.action ===
                                                    "select-option"
                                                ) {
                                                    append({
                                                        language: {
                                                            id: opt.value,
                                                            name: opt.label,
                                                        },
                                                        title: "",
                                                        subtitle: "",
                                                    });
                                                }
                                            }}
                                            enablePortal
                                        />
                                    </td>
                                    <td>
                                        <InputText disabled />
                                    </td>
                                    <td>
                                        <InputText disabled />
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </Widget.Body>
                <Widget.Body enableCollapse className="flex-grow-1">
                    <div className="d-flex flex-row-reverse">
                        <Button
                            variant="secondary ml-2"
                            disabled={isSubmitting || isLoading}
                            title={I18n.getTranslation(
                                location,
                                "artist.management.disco.objects.track.translations.reset.title"
                            )}
                            onClick={fetchTranslations}
                        >
                            <I18n t="utils.button.reset" />
                        </Button>
                        <Button
                            variant="success"
                            type="submit"
                            disabled={
                                isSubmitting ||
                                isLoading ||
                                !isDirty ||
                                !isValid
                            }
                            title={I18n.getTranslation(
                                location,
                                "artist.management.disco.objects.track.translations.update.title"
                            )}
                        >
                            <I18n t={`utils.button.edit`} />
                        </Button>
                    </div>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default Translations;
