import React from "react";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const GenreSelect = ({
    isLoading,
    genres,
    control,
    name,
    error,
    location,
    disabled,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputSelect
                    innerRef={ref}
                    name={name}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.genre.placeholder"
                    )}
                    options={
                        genres?.map(({ id, name }) => ({
                            value: id,
                            label: name,
                        })) ?? []
                    }
                    value={
                        value
                            ? {
                                  value: value.id,
                                  label: value.name,
                              }
                            : null
                    }
                    onChange={(option, action) => {
                        if (action.action === "select-option") {
                            onChange({
                                id: option.value,
                                name: option.label,
                            });
                        } else if (action.action === "clear") {
                            onChange(null);
                        }
                    }}
                    isLoading={isLoading}
                    isDisabled={disabled}
                    isClearable
                    enablePortal
                    error={error}
                />
            )}
        />
    );
};

export default GenreSelect;
