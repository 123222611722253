import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const Tracklist = ({ tracklist, isLoading, columns, title, titleArgs }) => {
    return (
        <Widget title={title} titleArgs={titleArgs} isLoading={isLoading}>
            <Widget.Body className="widget-table-overflow overflow-auto border-top">
                <BootstrapTable
                    keyField="id"
                    data={tracklist}
                    columns={columns}
                    bootstrap4
                    condensed
                    bordered={false}
                    noDataIndication={
                        <I18n t="album.view.profileTracklist.empty" />
                    }
                    classes="table-layout-auto mb-0"
                />
            </Widget.Body>
        </Widget>
    );
};

export default Tracklist;
