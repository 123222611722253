import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import PictureCheckButton from "shared/components/PictureCheckButton";

const PicturesCell = ({
    artistId,
    hasBackstagePicture,
    hasOtherPicture,
    hasGettyPicture,
    hasCurationPicture,
    lastGettyPictureDate,
    lastGettyCreation,
    hasLabelPicture,
    lastLabelCreation,
    hasPictureCheck,
    pictureComment,
    lastPictureCheck,
    hasRecentPictureCheck,
    showComment,
    onCheck,
    picturesStatus,
}) => {
    return (
        <div className="d-flex align-items-center">
            <div className="">
                <span
                    className={`badge badge-lg badge-${
                        picturesStatus === 1
                            ? "success"
                            : picturesStatus === 2
                            ? "warning"
                            : picturesStatus === 3
                            ? "danger"
                            : "secondary"
                    }`}
                >
                    <I18n
                        t={
                            hasBackstagePicture
                                ? "partner_tops.pictures.backstage"
                                : hasLabelPicture
                                ? "partner_tops.pictures.label"
                                : hasGettyPicture
                                ? "partner_tops.pictures.getty"
                                : hasCurationPicture
                                ? "partner_tops.pictures.curation"
                                : hasOtherPicture
                                ? "partner_tops.pictures.other"
                                : "partner_tops.pictures.none"
                        }
                    />
                </span>
            </div>
            <div className="flex-grow-1">
                {!hasBackstagePicture && (
                    <ul className="mb-0">
                        {hasLabelPicture && (
                            <li>
                                <I18n t="partner_tops.pictures.last_label_creation" />
                                {` : ${lastLabelCreation}`}
                            </li>
                        )}
                        {lastGettyCreation !== null && (
                            <li>
                                <I18n t="partner_tops.pictures.last_getty_creation" />
                                {` : ${lastGettyCreation}`}
                            </li>
                        )}
                        {lastGettyPictureDate !== null && (
                            <li>
                                <I18n t="partner_tops.pictures.last_getty_date" />
                                {` : ${lastGettyPictureDate}`}
                            </li>
                        )}
                        {hasPictureCheck && (
                            <li>
                                <I18n t="partner_tops.pictures.last_check_date" />
                                {` : `}
                                {pictureComment !== null ? (
                                    <button
                                        className="badge badge-lg badge-info btn btn-info"
                                        onClick={(e) =>
                                            showComment(e, pictureComment)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faComment}
                                            className="mr-1"
                                        />
                                        {lastPictureCheck}
                                    </button>
                                ) : (
                                    lastPictureCheck
                                )}
                            </li>
                        )}
                    </ul>
                )}
            </div>
            {artistId && !hasBackstagePicture && !hasRecentPictureCheck && (
                <PictureCheckButton
                    artistId={artistId}
                    comment={pictureComment}
                    onCheck={(comment) => onCheck(artistId, comment)}
                />
            )}
        </div>
    );
};

export default PicturesCell;
