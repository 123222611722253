import React from "react";
import { useParams } from "react-router";
import ChartPopover from "./ChartPopover";

const EnhancedChartPopover = React.forwardRef((props, ref) => {
    const { locale } = useParams();

    return <ChartPopover {...props} ref={ref} locale={locale} />;
});

export default EnhancedChartPopover;
