import React from "react";
import Filter from "./Filter";
import List from "./List";
import I18n from "shared/lib/I18n";

const Planning = ({
    plannings,
    filteredPlannings,
    setFilteredPlannings,
    isLoading,
}) => {
    return (
        <section className="widget pb-0">
            <header>
                <h4>
                    <I18n t="plannings.picture.planning.head" />
                </h4>
            </header>
            <Filter
                plannings={plannings}
                setFilteredPlannings={setFilteredPlannings}
            />
            <List isLoading={isLoading} plannings={filteredPlannings} />
        </section>
    );
};

export default Planning;
