import React from "react";
import LazyLoad from "react-lazyload";
import Loader from "shared/components/Loader";
import MetaUpdate from "./MetaUpdate";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./Picture.css";

const Picture = ({ picture, getGallery, location }) => {
    return (
        <div
            className={`card h-100 border mr-2 mb-2${
                picture.is_expired
                    ? " text-white bg-dark border-dark expired"
                    : ""
            }`}
            style={{ width: "16rem" }}
        >
            <LazyLoad
                height="100%"
                placeholder={<Loader />}
                debounce={200}
                once
            >
                <div className="image-container">
                    <img
                        src={picture.url}
                        className="card-img-top"
                        alt={picture.title}
                    />
                    <div className="image-content-over">
                        <div className="d-flex h-100">
                            <div className="border-right border-white w-100">
                                <a
                                    className="h-100 w-100 d-flex"
                                    href={picture.url}
                                    target="_blank"
                                >
                                    <span className="text-white mx-auto my-auto">
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            size="2x"
                                        />
                                    </span>
                                </a>
                            </div>
                            <div className="w-100">
                                <MetaUpdate
                                    pictureId={picture.id_picture}
                                    onSuccess={getGallery}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            <div className="card-body p-3">
                <p className="card-text">
                    {picture.is_expired ? (
                        <span className="font-weight-bold">
                            <I18n t="pictures.label.gallery.expired" />
                        </span>
                    ) : (
                        `${I18n.getTranslation(
                            location,
                            "pictures.label.gallery.takedownLabel"
                        )} : ${picture.takedown_at ?? "-"}`
                    )}
                </p>
                <hr className="dropdown-divider mb-3" />
                <p className="card-text">© {picture.copyright}</p>
            </div>
        </div>
    );
};

export default Picture;
