import React from "react";
import { NavLink } from "shared/lib/I18n";
import Update from "pages/Plannings/Button/Update";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const Row = ({ planning, status, getPlannings }) => {
    return (
        <tr>
            <td>{planning.title ?? "-"}</td>
            <td>
                <NavLink
                    to={`/plannings/${planning.id}/${PLANNING_PARTNER.ALL}`}
                >
                    {planning.start_date}
                </NavLink>
            </td>
            <td>
                <NavLink
                    to={`/plannings/${planning.id}/${PLANNING_PARTNER.ALL}`}
                >
                    {planning.end_date}
                </NavLink>
            </td>
            <td>
                {planning.nb_tasks_completed}/{planning.nb_tasks}
            </td>
            <td className="text-right">
                {status !== "DONE" && (
                    <Update planning={planning} onSuccess={getPlannings} />
                )}
            </td>
        </tr>
    );
};

export default Row;
