import { createSelector } from "reselect";
import I18n from "shared/lib/I18n";
import moment from "moment";

export const getFilterOptions = createSelector(
    [(args) => args.plannings, (args) => args.location],
    (plannings, location) =>
        plannings.reduce(
            (acc, curr) => {
                if (curr.status) {
                    acc["status"] = [
                        ...acc.status,
                        {
                            label:
                                I18n.getTranslation(
                                    location,
                                    `plannings.picture.planning.${curr.status.toLowerCase()}`
                                ) || curr.status,
                            value: curr.status,
                        },
                    ].filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    );
                }

                return acc;
            },
            {
                status: [],
            }
        )
);

export const getFilteredPlannings = createSelector(
    [(args) => args.plannings, (args) => args.selectedFilterOptions],
    (plannings, selectedFilterOptions) => {
        let filteredPlannings = plannings;

        if (selectedFilterOptions.startDate) {
            filteredPlannings = filteredPlannings.filter((p) =>
                moment(p.start_date).isSameOrAfter(
                    selectedFilterOptions.startDate
                )
            );
        }

        if (selectedFilterOptions.endDate) {
            filteredPlannings = filteredPlannings.filter((p) =>
                moment(p.end_date).isSameOrBefore(selectedFilterOptions.endDate)
            );
        }

        if (selectedFilterOptions.status) {
            filteredPlannings = filteredPlannings.filter(
                (p) => p.status === selectedFilterOptions.status
            );
        }

        return filteredPlannings;
    }
);
