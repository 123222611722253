import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import SeatgeekArtist from "./SeatgeekArtist";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import ArtistMatcher from "./ArtistMatcher";
import CheckAction from "./Action/CheckAction";
import MarkAction from "./Action/MarkAction";
import UnmarkAction from "./Action/UnmarkAction";
import { getFilteredData } from "./SeatgeekArtist.selector";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedSeatgeekArtist = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
        completed: null,
        checked: null,
        skiped: null,
    });

    const fetchSeatgeekArtist = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/artist/${partnerId}/list/${listId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchSeatgeekArtist();
    }, [fetchSeatgeekArtist]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.artist.artist.id"),
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "matching.artist.artist.name"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "spotify_id",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.spotify"
            ),
            formatter: (cell) => {
                if (cell) {
                    return (
                        <a
                            href={`https://open.spotify.com/artist/${cell}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Spotify
                        </a>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            dataField: "musicbrainz_id",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.musicbrainz"
            ),
            formatter: (cell) => {
                if (cell) {
                    return (
                        <a
                            href={`https://musicbrainz.org/artist/${cell}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MusicBrainz
                        </a>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.artist"
            ),
            formatter: (cell, { id }, rowIndex, { locale }) => {
                return (
                    <ArtistMatcher
                        listId={listId}
                        rowId={id}
                        locale={locale}
                        artist={cell}
                        onChange={(data) => updateRow(id, data)}
                    />
                );
            },
            formatExtraData: {
                locale,
            },
            headerStyle: {
                width: "25rem",
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "nb_pictures",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.getty_picture"
            ),
            formatter: (cell, { artist }) => {
                return artist ? (
                    <ArtistLink
                        artistId={artist.id}
                        content={
                            <I18n
                                t="matching.artist.artist.picture"
                                args={cell}
                            />
                        }
                        part="getty"
                        edit
                        blank
                    />
                ) : (
                    "-"
                );
            },
            headerStyle: {
                width: "11rem",
            },
            filterValue: (cell, { artist }) => artist?.name,
        },
        {
            dataField: "max_created_at",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.max_created_at"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: {
                width: "7rem",
            },
            searchable: false,
        },
        {
            dataField: "action",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.action"
            ),
            formatter: (cell, { id, artist, skiped }) => {
                if (artist) {
                    return (
                        <CheckAction
                            listId={listId}
                            rowId={id}
                            artistId={artist.id}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                }

                if (skiped) {
                    return (
                        <UnmarkAction
                            listId={listId}
                            rowId={id}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                } else {
                    return (
                        <MarkAction
                            listId={listId}
                            rowId={id}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                }
            },
        },
        {
            dataField: "checked_at",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.checked"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: {
                width: "7rem",
            },
            searchable: false,
        },
    ].filter(Boolean);

    const defaultSorted = [
        {
            dataField: "order_id",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ artist, completed, skiped }) => {
        let classes = null;

        if (completed) {
            return "table-success";
        }

        if (artist) {
            return "table-warning";
        }

        if (skiped) {
            return "table-secondary";
        }

        return classes;
    };

    return (
        <SeatgeekArtist
            {...props}
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            location={location}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedSeatgeekArtist;
