import React from "react";
import { useParams } from "react-router";
import * as moment from "moment";
import "moment/locale/fr";

const I18nRelativeDate = ({ date, defaultLocale }) => {
    const locale = defaultLocale ?? useParams().locale;
    const localizedDate = moment(date).locale(locale);

    return (
        <span title={localizedDate.format("LLLL")}>
            {localizedDate.fromNow()}
        </span>
    );
};

export default I18nRelativeDate;
