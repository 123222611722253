import React from "react";
import { useLocation } from "react-router";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import PartnerFilter from "./PartnerFilter";
import StatusFilter from "./StatusFilter";
import Search from "./Search";
import "./Table.css";

const Table = ({
    albums,
    isLoading,
    columns,
    pagination,
    defaultSorted,
    rowClasses,
    rowEvents,
    partnerFilter,
    setPartnerFilter,
    statusFilter,
    setStatusFilter,
    searchQuery,
    setSearchQuery,
    onColumnMatch,
}) => {
    const location = useLocation();

    return (
        <>
            <ToolkitProvider
                keyField="key"
                data={albums}
                columns={columns}
                search={{ onColumnMatch }}
            >
                {(toolkitprops) => (
                    <PaginationProvider pagination={pagination}>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <header className="d-flex justify-content-between">
                                    <h4>
                                        <I18n t="artist.management.disco.sandbox.head" />
                                    </h4>
                                </header>
                                <div className="widget-body">
                                    <Search
                                        {...toolkitprops.searchProps}
                                        onGlobalSearch={setSearchQuery}
                                        disabled={paginationProps.page !== 1}
                                    />
                                    <PartnerFilter
                                        disabled={paginationProps.page !== 1}
                                        partnerFilter={partnerFilter}
                                        setPartnerFilter={setPartnerFilter}
                                    />
                                    <StatusFilter
                                        disabled={paginationProps.page !== 1}
                                        statusFilter={statusFilter}
                                        setStatusFilter={setStatusFilter}
                                    />
                                    {searchQuery !== null && (
                                        <div
                                            className="alert alert-primary alert-dismissible widget-alert mb-0"
                                            role="alert"
                                        >
                                            <I18n
                                                t="artist.management.disco.sandbox.search.result"
                                                args={searchQuery}
                                            />
                                            <button
                                                type="button"
                                                className="close small-close"
                                                title={I18n.getTranslation(
                                                    location,
                                                    "artist.management.disco.sandbox.search.reset"
                                                )}
                                                onClick={() =>
                                                    setSearchQuery(null)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faUndo}
                                                    fixedWidth
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="widget-body widget-table-overflow mb-0"
                                    style={{
                                        height: "600px",
                                        overflow: "auto",
                                        borderTop: "1px solid #dee2e6",
                                    }}
                                >
                                    {isLoading ? (
                                        <Loader
                                            className="h-100 align-items-center"
                                            size="3x"
                                        />
                                    ) : (
                                        <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            bootstrap4
                                            hover
                                            bordered={false}
                                            condensed
                                            noDataIndication={
                                                <I18n t="artist.management.disco.sandbox.empty" />
                                            }
                                            classes="table-header-fixed table-truncate"
                                            defaultSorted={defaultSorted}
                                            rowClasses={rowClasses}
                                            rowEvents={rowEvents}
                                        />
                                    )}
                                </div>
                                <div className="widget-body">
                                    <div className="row align-items-center py-3">
                                        {!isLoading && (
                                            <>
                                                <div className="col ">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                        dataSize={albums.length}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        </>
    );
};

export default React.memo(Table);
