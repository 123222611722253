import React, { useState } from "react";
import Search from "./Search";
import { useLocation, useNavigate, useParams } from "react-router";
import useQuery from "shared/hooks/useQuery";

const EnhancedSearch = (props) => {
    const { locale, selectedTab } = useParams();
    const navigate = useNavigate();
    const query = useQuery();
    const location = useLocation();
    const [state, setState] = useState({
        artists: [],
        albums: [],
        recordings: [],
        isLoading: false,
    });

    const onSelectTab = (k) => {
        navigate(`/${locale}/search/${k}?${query.toString()}`);
    };

    return (
        <Search
            {...props}
            {...state}
            hasSearch={query.has("q")}
            setState={setState}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            location={location}
        />
    );
};

export default EnhancedSearch;
