import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputDate from "shared/components/Form/InputDate";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import moment from "moment";

const LabelInformationModal = ({
    show,
    handleHide,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    isLoading,
    location,
}) => {
    return (
        <Modal show={show} onHide={handleHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="common.labelInformationModal.head" />
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="common.labelInformationModal.title" />
                                </Form.Label>
                                <InputText
                                    {...register("title")}
                                    error={
                                        errors.title && [
                                            I18n.getTranslation(
                                                location,
                                                errors.title.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="common.labelInformationModal.copyright" />
                                </Form.Label>
                                <InputText
                                    {...register("copyright")}
                                    error={
                                        errors.copyright && [
                                            I18n.getTranslation(
                                                location,
                                                errors.copyright.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="common.labelInformationModal.takedown" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="date_takedown"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <InputDate
                                            selected={
                                                value ? new Date(value) : null
                                            }
                                            onChange={(date) => {
                                                onChange(
                                                    date
                                                        ? moment(date).format(
                                                              "YYYY-MM-DD"
                                                          )
                                                        : null
                                                );
                                            }}
                                            error={
                                                errors.date_takedown && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.date_takedown
                                                            .message
                                                    ),
                                                ]
                                            }
                                            isClearable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        <I18n t="utils.button.close" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting && isDirty ? onSubmit : null}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t={`utils.button.edit`} />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LabelInformationModal;
