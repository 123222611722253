import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import ArtistSelect from "./ArtistSelect";
import useAPI from "shared/hooks/useApi";

const EnhancedArtistSelect = ({ onMatch, onUnmatch, locale, ...props }) => {
    const { api } = useAPI();

    const search = useDebouncedCallback((term, callback) => {
        api.get(`searchengine/artist`, {
            term,
            onlyCompleted: true,
        })
            .then((response) => {
                callback(
                    response.map((row) => {
                        const name = [
                            row.id,
                            row.name,
                            row.complement,
                            row.type?.langs?.[locale],
                            row.country?.langs?.[locale],
                            row.role?.langs?.[locale],
                            row.genre?.langs?.[locale],
                        ];
                        return {
                            value: { id: row.id, name: row.name },
                            label: name.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            if (onMatch !== undefined) {
                onMatch(opt.value.id, opt.value.name);
            }
        } else {
            if (onUnmatch !== undefined) {
                onUnmatch();
            }
        }
    };

    return <ArtistSelect loadOptions={search} onChange={onChange} {...props} />;
};

export default EnhancedArtistSelect;
