import React from "react";
import I18n from "shared/lib/I18n";
import { displayAsString } from "shared/functions/Date";

const ReleaseDate = ({ release_date, locale }) => {
    return (
        <>
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`album.view.profileInformation.release_date`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div className="row" style={{ minHeight: "3rem" }}>
                <div className="col">
                    <span
                        className="font-weight-bold"
                        style={{ fontSize: "1.3rem" }}
                    >
                        {displayAsString(release_date, locale)}
                    </span>
                </div>
            </div>
        </>
    );
};

export default ReleaseDate;
