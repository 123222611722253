import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import ImportModal from "./ImportModal";
import { prefill } from "./ImportModal.pure";

const schema = yup.object().shape({
    startDate: yup.string().required("utils.form.required"),
    endDate: yup.string().required("utils.form.required"),
    chart: yup.mixed().required("utils.form.required"),
});

const EnhancedImportModal = ({
    fileId,
    fileName,
    onHide,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const [charts, setCharts] = useState([]);

    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            name: null,
            startDate: null,
            endDate: null,
            chart: null,
        },
    });

    const getCharts = useCallback(() => {
        return api
            .get(`snep/charts`)
            .then((response) => {
                setCharts(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getCharts();
    }, [getCharts]);

    useEffect(() => {
        if (fileName) {
            reset(prefill(fileName, charts));
        }
    }, [fileName, charts]);

    const onSubmit = ({ startDate, endDate, chart }) => {
        return api
            .post(
                `snep/file/${fileId}/import`,
                { locale },
                { startDate, endDate, chartId: chart.value }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "snep.file.fileList.import.success"
                    )
                );
                onHide();
                onSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const chartOptions = useMemo(
        () => [
            {
                label: I18n.getTranslation(
                    location,
                    "snep.file.fileList.import.chart.album"
                ),
                options: charts
                    .filter(({ type }) => type === "album")
                    .map(({ id, name }) => ({ value: id, label: name })),
            },
            {
                label: I18n.getTranslation(
                    location,
                    "snep.file.fileList.import.chart.track"
                ),
                options: charts
                    .filter(({ type }) => type === "track")
                    .map(({ id, name }) => ({ value: id, label: name })),
            },
        ],
        [charts]
    );

    return (
        <ImportModal
            chartOptions={chartOptions}
            onHide={onHide}
            control={control}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
            {...props}
        />
    );
};

export default EnhancedImportModal;
