import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import { useLocation } from "react-router";

const EnhancedChangePassword = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <ChangePassword
            {...props}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            location={location}
        />
    );
};

export default EnhancedChangePassword;
