import React from "react";
import { useWatch } from "react-hook-form";
import LyricfindLanguageReference from "./LyricfindLanguageReference";

const EnhancedLyricfindLanguageReference = ({
    languages,
    control,
    ...props
}) => {
    const selectedLanguage = useWatch({
        control,
        name: "title.language",
    });

    const language =
        languages === null || languages.length != 1
            ? null
            : languages[0] === "instrumental"
            ? "INSTRUMENTAL"
            : languages[0] === "fr"
            ? "FRENCH"
            : "INTERNATIONAL";

    return (
        <LyricfindLanguageReference
            languages={languages}
            language={language}
            selectedLanguage={selectedLanguage}
            {...props}
        />
    );
};

export default EnhancedLyricfindLanguageReference;
