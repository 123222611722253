import React from "react";
import Task from "./Task";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import moment from "moment";
import swal from "sweetalert";

const EnhancedTask = ({ task, ...props }) => {
    const location = useLocation();

    let informations = [];

    if (task.information.hasOwnProperty("label")) {
        informations = [
            {
                badges: [
                    {
                        label: moment(
                            task.information.label.last_created_at
                        ).format("YYYY-MM-DD"),
                        color: "success",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.last_label_created_at"
                        ),
                    },
                    {
                        label: task.information.label.last_getty_created_at
                            ? moment(
                                  task.information.label.last_getty_created_at
                              ).format("YYYY-MM-DD")
                            : "-",
                        color: "info",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.last_getty_created_at"
                        ),
                    },
                    {
                        label: task.information.label.max_picture_date,
                        color: "info",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.max_getty_date"
                        ),
                    },
                ],
            },
        ];
    } else if (task.information.hasOwnProperty("getty")) {
        informations = [
            {
                badges: [
                    {
                        label: moment(
                            task.information.getty.last_created_at
                        ).format("YYYY-MM-DD"),
                        color: "info",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.last_getty_created_at"
                        ),
                    },
                    {
                        label: task.information.getty.max_picture_date,
                        color: "info",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.max_getty_date"
                        ),
                    },
                ],
            },
        ];
    } else if (task.information.hasOwnProperty("not_found")) {
        informations = [
            {
                badges: [
                    {
                        label: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.has_disco"
                        ),
                        color: task.information.not_found.has_disco
                            ? "success"
                            : "danger",
                    },
                    {
                        label: task.information.not_found.last_check_date
                            ? moment(
                                  task.information.not_found.last_check_date
                              ).format("YYYY-MM-DD")
                            : "-",
                        color: "info",
                        tooltip: I18n.getTranslation(
                            location,
                            "plannings.picture.tasks.last_check_date"
                        ),
                    },
                ],
            },
        ];
    }

    const showComment = (e, comment) => {
        e.preventDefault();
        swal(comment, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };

    return (
        <Task
            {...props}
            task={task}
            informations={informations}
            showComment={showComment}
        />
    );
};

export default EnhancedTask;
