import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const Delete = ({ onClick, location }) => {
    return (
        <Button
            variant="danger"
            onClick={onClick}
            title={I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.basic.delete.title"
            )}
            className="ml-2"
        >
            <I18n t="utils.button.delete" />
        </Button>
    );
};

export default Delete;
