import React, { useState, useEffect } from "react";
import Cover from "./Cover";
import { useMK } from "shared/hooks/useMK";

const EnhancedCover = (props) => {
    const [coverArt, setCoverArt] = useState(
        "https://is1-ssl.mzstatic.com/image/thumb/Features127/v4/75/f9/6f/75f96fa5-99ca-0854-3aae-8f76f5cb7fb5/source/400x400bb.jpeg"
    );
    const mk = useMK({
        mediaItem: window.MusicKit.Events.mediaItemStateDidChange,
    });

    useEffect(() => {
        if (mk.mediaItem) {
            setCoverArt(
                window.MusicKit.formatArtworkURL(
                    mk.mediaItem.attributes.artwork,
                    400,
                    400
                )
            );
        }
    }, [mk.mediaItem]);

    return <Cover coverArt={coverArt} {...props} />;
};

export default EnhancedCover;
