import React from "react";
import PlanningsPicture from "./PlanningsPicture";
import { useLocation } from "react-router";

const EnhancedPlanningsPicture = (props) => {
    const location = useLocation();
    return <PlanningsPicture {...props} location={location} />;
};

export default EnhancedPlanningsPicture;
