import React from "react";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import I18n from "shared/lib/I18n";
import Error from "shared/components/Form/Error";
import CreateOrUpdateButton from "pages/CNMRepositories/Repository/CNMDistributors/CreateOrUpdateButton";

const DistributorSelect = ({
    year,
    control,
    name,
    error,
    location,
    disabled,
    loadOptions,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <>
                    <span className="d-inline-flex w-100">
                        <InputAsyncSelect
                            innerRef={ref}
                            name={name}
                            className="flex-grow-1"
                            placeholder={I18n.getTranslation(
                                location,
                                "cnm.report.consolidate.edit.distributor.placeholder"
                            )}
                            loadOptions={loadOptions}
                            value={
                                value
                                    ? {
                                          value: value,
                                          label: `${value.name} (${value.type})`,
                                      }
                                    : null
                            }
                            onChange={(option, action) => {
                                if (action.action === "select-option") {
                                    onChange(option.value);
                                } else if (action.action === "clear") {
                                    onChange(null);
                                }
                            }}
                            isDisabled={disabled}
                            isClearable
                            enablePortal
                            error={error}
                            showError={false}
                        />
                        {value === null && (
                            <CreateOrUpdateButton
                                className="ml-2"
                                year={year}
                                onSuccess={onChange}
                                title={I18n.getTranslation(
                                    location,
                                    "cnm.report.consolidate.edit.distributor.create"
                                )}
                                disabled={disabled}
                            >
                                <FontAwesomeIcon icon={faPlus} fixedWidth />
                            </CreateOrUpdateButton>
                        )}
                    </span>
                    {error !== null && <Error error={error} className="mt-3" />}
                </>
            )}
        />
    );
};

export default DistributorSelect;
