import React, { useState, useEffect, useCallback } from "react";
import Genres from "./Genres";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import useUser from "shared/hooks/useUser";

const EnhancedGenres = (props) => {
    const { api } = useAPI();
    const { user } = useUser();
    const { albumId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        genre: {},
        isLoading: true,
    });

    const getGenre = useCallback(() => {
        return api
            .get(`album/${albumId}/genre`)
            .then((response) => {
                setState((prev) => ({ ...prev, genre: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getGenre()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getGenre]);

    return <Genres {...props} {...state} locale={locale} user={user} />;
};

export default EnhancedGenres;
