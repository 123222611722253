import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import CSAArtistSelect from "./CSAArtistSelect";

const EnhancedCSAArtistSelect = ({ year, locale, ...props }) => {
    const { api } = useAPI();
    const loadOptions = useDebouncedCallback((term, callback) => {
        api.get(`cnm/report/consolidation/csa_artists/${year}`, {
            term,
            locale,
        })
            .then((response) => {
                callback(
                    response.map((row) => ({
                        value: row,
                        label: row.name,
                    }))
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    return <CSAArtistSelect loadOptions={loadOptions} {...props} />;
};

export default EnhancedCSAArtistSelect;
