import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import DiscardReasonSelect from "./DiscardReasonSelect";

const EnhancedDiscardReasonSelect = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [options, setOptions] = useState([]);

    const getOptions = useCallback(() => {
        api.get("cnm/consolidation/discard_reasons")
            .then((response) => {
                setOptions(
                    response.map((reason) => ({ value: reason, label: reason }))
                );
            })
            .catch((error) => {
                setOptions([]);
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(getOptions, [getOptions]);

    return (
        <DiscardReasonSelect {...props} options={options} location={location} />
    );
};

export default EnhancedDiscardReasonSelect;
