import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import SearchForm from "./SearchForm";

const EnhancedSearchForm = ({ query, setQuery, ...props }) => {
    const location = useLocation();
    const { handleSubmit, register, reset } = useForm({
        defaultValues: {
            query: "",
        },
    });

    const onSubmit = handleSubmit(({ query }) => setQuery(query));

    useEffect(() => {
        reset({ query });
    }, [query]);

    return (
        <SearchForm
            {...props}
            onSubmit={onSubmit}
            register={register}
            location={location}
        />
    );
};

export default EnhancedSearchForm;
