import React, { useMemo } from "react";
import Recordings from "./Recordings";
import { useLocation, useParams } from "react-router";
import { ProgressBar } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import Tippy from "@tippyjs/react";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import RecordingListAction from "shared/components/RecordingListAction";
import ImageLoader from "shared/components/ImageLoader";
import ArtistLink from "shared/components/ArtistLink";
import RecordingLink from "shared/components/RecordingLink";
import placeholder from "assets/static/images/logo_placeholder.png";
import clsx from "clsx";
import useQuery from "shared/hooks/useQuery";
import { displayAsString } from "shared/functions/Date";

const EnhancedRecordings = ({ recordings, limit, ...props }) => {
    const { locale } = useParams();
    const location = useLocation();
    const query = useQuery();

    const sliceData = useMemo(() => {
        if (limit) {
            return recordings.slice(0, limit);
        }
        return recordings;
    }, [recordings, limit]);

    const columns = [
        {
            dataField: "cover",
            text: I18n.getTranslation(location, "search.recordingsData.cover"),
            formatter: (cell) => {
                return (
                    <ImageLoader
                        src={cell}
                        fallbackSrc={placeholder}
                        className={clsx(
                            !cell && "cover-placeholder",
                            "cover-recording"
                        )}
                    />
                );
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "id",
            text: I18n.getTranslation(location, "search.recordingsData.id"),
            formatter: (cell) => {
                return cell ? <strong>{cell}</strong> : "-";
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "search.recordingsData.title"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "search.recordingsData.subtitle"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        !limit && {
            dataField: "popularity",
            text: I18n.getTranslation(
                location,
                "search.recordingsData.popularity"
            ),
            formatter: (cell) => {
                return cell ? (
                    <div style={{ width: "60px" }}>
                        <Tippy
                            content={`${Math.round(cell)}`}
                            trigger="mouseenter"
                        >
                            <ProgressBar
                                now={cell}
                                variant="purple-ms"
                                style={{
                                    height: "8px",
                                }}
                            />
                        </Tippy>
                    </div>
                ) : (
                    "-"
                );
            },
        },
        {
            dataField: "artists",
            text: I18n.getTranslation(location, "search.recordingsData.artist"),
            formatter: (cell) =>
                cell.map((artist, index) => (
                    <React.Fragment key={artist.id}>
                        {index !== 0 && <span className="mx-1">&bull;</span>}
                        <ArtistLink
                            artistId={artist.id}
                            content={artist.name}
                            view
                        />
                    </React.Fragment>
                )),
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "search.recordingsData.duration"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: { width: "8rem" },
        },
        !limit && {
            dataField: "genre",
            text: I18n.getTranslation(location, "search.recordingsData.genre"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
            headerStyle: { width: "8rem" },
        },
        !limit && {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "search.recordingsData.release_date"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                if (cell) {
                    return displayAsString(cell, locale);
                } else {
                    return "-";
                }
            },
            formatExtraData: {
                locale,
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "complementary",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "search.recordingsData.complementary"
            ),
            formatter: (cell, { id, has_audio, has_lyric, has_credits }) => (
                <div className="d-flex">
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_audio ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="search.recordingsData.has_audio" />
                            </span>
                        }
                        part="audio_description"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_lyric ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="search.recordingsData.has_lyric" />
                            </span>
                        }
                        part="lyrics"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_credits ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="search.recordingsData.has_credits" />
                            </span>
                        }
                        part="credits"
                    />
                </div>
            ),
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "search.recordingsData.actions"
            ),
            formatter: (
                cell,
                { id, artists, album_id, release_id, track_id }
            ) => {
                return (
                    <RecordingListAction
                        artistId={artists[0].id}
                        albumId={album_id}
                        releaseId={release_id}
                        trackId={track_id}
                        recordingId={id}
                    />
                );
            },
            headerAlign: "right",
            align: "right",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        totalSize: sliceData.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Recordings
            {...props}
            recordings={sliceData}
            columns={columns}
            limit={limit}
            pagination={pagination}
            location={location}
            query={query}
        />
    );
};

export default EnhancedRecordings;
