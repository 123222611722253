import { useEffect } from "react";

const useOnClickOutside = (refs, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const outside = refs.every(
                (ref) => ref.current && !ref.current.contains(event.target)
            );
            if (outside) {
                callback?.(event);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refs]);
};

export default useOnClickOutside;
