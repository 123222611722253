import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import Loader from "shared/components/Loader";

const ISNISearchModal = ({
    location,
    show,
    onHide,
    register,
    isSubmitting,
    errors,
    onSubmit,
    columns,
    results,
}) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.profileInformation.isniSearch.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Form onSubmit={onSubmit}>
                    <div className="form-row m-3">
                        <div className="col">
                            <InputText
                                {...register("term")}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "artist.management.profileInformation.isniSearch.placeholder"
                                )}
                                error={
                                    errors.term && [
                                        I18n.getTranslation(
                                            location,
                                            errors.term.message
                                        ),
                                    ]
                                }
                            />
                        </div>
                        <div className="col-auto">
                            <Button type="submit" variant="primary">
                                <I18n t="utils.button.search" />
                            </Button>
                        </div>
                    </div>
                </Form>
                {isSubmitting ? (
                    <Loader className="mb-3" />
                ) : (
                    <BootstrapTable
                        keyField="isni"
                        data={results}
                        columns={columns}
                        noDataIndication={
                            <I18n t="artist.management.profileInformation.isniSearch.empty" />
                        }
                        bootstrap4
                        hover
                        bordered={false}
                        classes="table-header-fixed mb-0"
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    <I18n t="utils.button.close" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ISNISearchModal;
