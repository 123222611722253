import React from "react";
import I18n from "shared/lib/I18n";
import PopularityOverview from "./PopularityOverview";
import PopularityHistogram from "./PopularityHistogram";
import PopularityMap from "./PopularityMap";

const Popularity = ({
    id_recording,
    showGold,
    setShowGold,
    hasData,
    setNoData,
}) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="recording.view.noData.popularity" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <PopularityOverview
                        id_recording={id_recording}
                        setNoData={setNoData}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <PopularityHistogram
                        id_recording={id_recording}
                        showGold={showGold}
                        setShowGold={setShowGold}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <PopularityMap
                        id_recording={id_recording}
                        showGold={showGold}
                        setShowGold={setShowGold}
                    />
                </div>
            </div>
        </>
    );
};

export default Popularity;
