import React, { useState, useEffect, useCallback } from "react";
import Recordings from "./Recordings";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const EnhancedRecordings = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        recordings_creation: [],
    });
    const location = useLocation();

    const getRecordingsStats = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`statistics/ms/recordings-stats`)
            .then((response) => {
                setState({
                    isLoading: false,
                    recordings_creation: response,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState({
                    isLoading: false,
                    recordings_creation: [],
                });
            });
    }, []);

    useEffect(getRecordingsStats, [getRecordingsStats]);

    return <Recordings {...props} {...state} location={location} />;
};

export default EnhancedRecordings;
