import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CreateOrUpdateModal from "pages/RoleManagement/Role/Form/CreateOrUpdateModal";
import I18n from "shared/lib/I18n";

const Update = ({
    role,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "role.manage.update.title"
                )}
            >
                <FontAwesomeIcon icon={faEdit} size="lg" />
            </Button>

            <CreateOrUpdateModal
                role={role}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
            />
        </>
    );
};

export default Update;
