import React, { useState } from "react";
import Archive from "./Archive";
import { useLocation } from "react-router";

const EnhancedArchive = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Archive
            {...props}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            location={location}
        />
    );
};

export default EnhancedArchive;
