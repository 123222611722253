import React from "react";
import Action from "./Action";
import I18n from "shared/lib/I18n";
import { useParams, useLocation } from "react-router";
import { usePanel } from "layout/Panel";
import Panel from "shared/components/Panel";
import TaskForm from "pages/PlanningsManagement/Form/TaskForm";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import moment from "moment";
import { capitalizeAll } from "shared/functions/String";

const EnhancedAction = ({
    type = "ASSESSMENT",
    task,
    planning,
    assignement,
    onSuccess,
    ...props
}) => {
    const { locale } = useParams();
    const location = useLocation();

    const [showPanel, hidePanel] = usePanel(() => (
        <Panel
            title={I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.action.addToPlanning.title"
            )}
            hidePanel={hidePanel}
        >
            <div className="m-3">
                <TaskForm
                    planning={planning}
                    subtasks_type={
                        task.catalog_type === "Owned" && type === "ASSESSMENT"
                            ? "APL-LC-SYNC"
                            : task.catalog_type === "Owned" &&
                              type === "COUNTER_ASSESSMENT"
                            ? "APL-LC-CSYNC"
                            : task.catalog_type === "Unowned" &&
                              type === "ASSESSMENT"
                            ? "APL-LC-AS"
                            : task.catalog_type === "Unowned" &&
                              type === "COUNTER_ASSESSMENT"
                            ? "APL-LC-CAS"
                            : null
                    }
                    subtasks_assignement={assignement}
                    artist_name={task.artist}
                    album_title={task.album}
                    track_title={task.title}
                    category="Lyrics assessment"
                    reason={`${task.catalog_type} - ${capitalizeAll(
                        // Allways in english
                        moment(task.sample_of)
                            .locale("en")
                            .format("YYYY - MMMM")
                    )}`}
                    lyrics_sample_id={task.id}
                    onSubmitSuccess={() => {
                        onSuccess?.();
                        hidePanel();
                    }}
                    defaultLocale={locale}
                    partnerId={PLANNING_PARTNER.APPLE}
                    subtaskGroupExcluded={
                        type === "COUNTER_ASSESSMENT" ? ["APL_AS"] : null
                    }
                    forceSubmit
                />
            </div>
        </Panel>
    ));

    return (
        <Action
            {...props}
            showPanel={showPanel}
            exist={
                type === "ASSESSMENT"
                    ? task.has_assessment
                    : task.has_counter_assessment
            }
            location={location}
        />
    );
};

export default EnhancedAction;
