import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import EditMatchingsModal from "shared/components/EditMatchingsModal";

const ArtistMatcher = ({
    artist,
    msArtist,
    show,
    setShow,
    fetchCandidates,
    onMatchingChange,
    locale,
}) => {
    return (
        <>
            <EditMatchingsModal
                artistId={artist.id}
                artistName={artist.name}
                show={show}
                onHide={() => setShow(false)}
                locale={locale}
                onChange={onMatchingChange}
                fetchCandidates={fetchCandidates}
            />
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    {msArtist !== null ? (
                        <a
                            href={`/${locale}/artist/${msArtist.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {msArtist.name} ({msArtist.id})
                        </a>
                    ) : (
                        "-"
                    )}
                </div>
                <div className="btn-group btn-group-sm">
                    <Button
                        variant="primary"
                        onClick={() => setShow(true)}
                        title={I18n.getTranslation(
                            location,
                            "matching.track.track.edit_matchings"
                        )}
                        style={{ width: "30px" }}
                    >
                        <FontAwesomeIcon icon={faPen} size="lg" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ArtistMatcher;
