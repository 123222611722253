import React, { useCallback } from "react";
import { useLocation } from "react-router";
import cellEditFactory, {
    Type,
} from "@musicstory/react-bootstrap-table2-editor";
import I18n from "shared/lib/I18n";
import Tracks from "./Tracks";
import { TRACK_HIT, TRACK_DISCOVERY } from "shared/constants/cms/track";

const EnhancedTracks = ({ errors, dirtyCells, setTrackField, ...props }) => {
    const location = useLocation();

    const cellClass = useCallback(
        (column) => (cell, row, rowIndex, colIndex) => {
            if (errors[row.id_track][column] !== null) {
                return "table-danger";
            }
            if (dirtyCells[row.id_track][column]) {
                return "table-info";
            }
        },
        [errors, dirtyCells]
    );

    const cellTitle = useCallback(
        (column) => (cell, row, rowIndex, colIndex) =>
            errors[row.id_track][column] ?? cell,
        [errors]
    );

    const columns = [
        {
            dataField: "no_cd",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.no_cd"
            ),
            title: cellTitle("no_cd"),
            headerStyle: { width: "2em" },
            editCellClasses: "cell-edit",
            classes: cellClass("no_cd"),
        },
        {
            dataField: "no_piste",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.no_piste"
            ),
            title: cellTitle("no_piste"),
            // Sort by disc and track number
            sort: true,
            sortValue: (cell, row) => ({
                disc: row.no_cd,
                track: row.no_piste,
            }),
            sortFunc: (a, b, order, dataField) =>
                a.disc === b.disc
                    ? (order === "asc" ? 1 : -1) * (a.track - b.track)
                    : (order === "asc" ? 1 : -1) * (a.disc - b.disc),
            headerStyle: { width: "3em" },
            editCellClasses: "cell-edit",
            classes: cellClass("no_piste"),
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.isrc"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("isrc"),
            sort: true,
            headerStyle: { width: "75%" },
            editCellClasses: "cell-edit",
            classes: cellClass("isrc"),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.title"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("title"),
            sort: true,
            headerStyle: { width: "100%" },
            editCellClasses: "cell-edit",
            classes: cellClass("title"),
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.subtitle"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("subtitle"),
            sort: true,
            headerStyle: { width: "50%" },
            editCellClasses: "cell-edit",
            classes: cellClass("subtitle"),
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.duration"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("duration"),
            headerStyle: { width: "5em" },
            editCellClasses: "cell-edit",
            classes: cellClass("duration"),
        },
        {
            dataField: "hit",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.hit"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("hit"),
            headerStyle: { width: "4em" },
            editCellClasses: "cell-edit",
            classes: cellClass("hit"),
            editor: {
                type: Type.SELECT,
                options: [{ value: null, label: "-" }].concat(
                    TRACK_HIT.map((k) => {
                        return { value: k, label: k };
                    })
                ),
            },
        },
        {
            dataField: "discovery",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.release.tracklist.tracks.discovery.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.release.tracklist.tracks.discovery.title"
                ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: cellTitle("discovery"),
            headerStyle: { width: "5em" },
            editCellClasses: "cell-edit",
            classes: cellClass("discovery"),
            editor: {
                type: Type.SELECT,
                options: [{ value: null, label: "-" }].concat(
                    TRACK_DISCOVERY.map((k) => {
                        return { value: k, label: k };
                    })
                ),
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "no_piste",
            order: "asc",
        },
    ];

    const cellEdit = cellEditFactory({
        mode: "dbclick",
        blurToSave: true,
        autoSelectText: true,
        beforeSaveCell: (oldValue, newValue, row, column, done) => {
            setTrackField(row.id_track, column.dataField, newValue);

            // Prevent react-bootstrap-table from modifying the tracks.
            done(false);
            return { async: true };
        },
    });

    return (
        <Tracks
            {...props}
            columns={columns}
            defaultSorted={defaultSorted}
            cellEdit={cellEdit}
        />
    );
};

export default EnhancedTracks;
