import React from "react";
import { useParams } from "react-router";
import ArtistLink from "./ArtistLink";

const EnhancedArtistLink = (props) => {
    const { locale } = useParams();
    return <ArtistLink {...props} locale={locale} />;
};

export default EnhancedArtistLink;
