import React, { useState, useContext } from "react";
import Names from "./Names";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useIntParams from "shared/hooks/useIntParams";
import {
    ArtistManagementContext,
    NAMES_UP_TO_DATE,
} from "pages/ArtistManagement";

const EnhancedNames = ({ names, toggleShow, ...props }) => {
    const { dispatchArtist } = useContext(ArtistManagementContext);
    const { api } = useAPI();
    const location = useLocation();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selected, setSelected] = useState([]);

    const columns = [
        {
            dataField: "id_lang",
            text: I18n.getTranslation(
                location,
                "artist.management.wikipedia.names.lang"
            ),
            formatter: (cell, row) => {
                return `${cell} - ${row.lang_name}`;
            },
        },
        {
            dataField: "wikipedia_name",
            text: I18n.getTranslation(
                location,
                "artist.management.wikipedia.names.name"
            ),
        },
    ];

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        headerColumnStyle: () => {
            return {
                textAlign: "center",
                paddingBottom: "0.4rem",
            };
        },
        selectColumnStyle: () => {
            return {
                textAlign: "center",
                paddingBottom: "0.2rem",
            };
        },
        selected: selected,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                setSelected((current) => [...current, row.id_lang]);
            } else {
                setSelected((current) =>
                    current.filter((c) => c !== row.id_lang)
                );
            }
        },
        onSelectAll: (isSelect, rows) => {
            const ids = rows.map((r) => r.id_lang);
            if (isSelect) {
                setSelected(ids);
            } else {
                setSelected([]);
            }
        },
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const newNames = names
            .filter(({ id_lang }) => selected.includes(id_lang))
            .map(({ id_lang, wikipedia_name }) => {
                return {
                    name: wikipedia_name,
                    language: id_lang,
                };
            });

        let currentNames = [];
        await api
            .get(`artist/${artistId}/names`)
            .then((response) => {
                currentNames = response;
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsSubmitting(false);
            });

        api.post(`artist/${artistId}/names`, { locale }, [
            ...currentNames,
            ...newNames,
        ])
            .then((response) => {
                toggleShow();
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.names.update.success`
                    )
                );
                dispatchArtist({
                    type: NAMES_UP_TO_DATE,
                    data: false,
                });
                setSelected([]);
                setIsSubmitting(false);
            })
            .catch((error) => {
                console.error(error);
                setIsSubmitting(false);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.names.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Names
            {...props}
            names={names}
            columns={columns}
            selectRow={selectRow}
            toggleShow={toggleShow}
            isSubmitting={isSubmitting}
            selected={selected}
            handleSubmit={handleSubmit}
        />
    );
};

export default EnhancedNames;
