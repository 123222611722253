import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CreateOrUpdateModal from "pages/UserRightManagement/Right/Form/CreateOrUpdateModal";
import I18n from "shared/lib/I18n";

const Update = ({
    right,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "user.right.manage.update.title"
                )}
            >
                <FontAwesomeIcon icon={faEdit} />
            </Button>

            {show && (
                <CreateOrUpdateModal
                    right={right}
                    show={show}
                    handleClose={handleClose}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
};

export default Update;
