import React, { useEffect, useState, useCallback } from "react";
import LabelInformationModal from "./LabelInformationModal";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    title: yup
        .string()
        .nullable()
        .transform((value) => value || null),
    copyright: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .required("utils.form.required"),
    date_takedown: yup
        .string()
        .nullable()
        .transform((value) => value || null),
});

const EnhancedLabelInformationModal = ({
    id_picture,
    show,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            title: null,
            copyright: null,
            date_takedown: null,
        },
    });

    useEffect(() => {
        if (!show) {
            reset({
                title: null,
                copyright: null,
                date_takedown: null,
            });
        }
    }, [show]);

    const getImageInformation = useCallback(() => {
        let cancelled = false;

        if (id_picture && show) {
            setIsLoading(true);
            api.get(`picture/label/${id_picture}/information`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        reset(response);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        reset();
                        setIsLoading(false);
                    }
                });
        }

        return () => {
            cancelled = true;
        };
    }, [id_picture, show, locale]);

    useEffect(getImageInformation, [getImageInformation]);

    const onSubmit = (data) => {
        return api
            .put(`picture/label/${id_picture}/information`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.labelInformationModal.success"
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <LabelInformationModal
            {...props}
            show={show}
            locale={locale}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            location={location}
        />
    );
};

export default EnhancedLabelInformationModal;
