import React, { useState, useRef, useEffect, useCallback } from "react";
import GenreRepartition from "./GenreRepartition";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router-dom";

const EnhancedGenreRepartition = ({ id_country, locale, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        genre_repartition: [],
    });
    const location = useLocation();

    const popoverPointerRef = useRef(null);
    const containerRef = useRef(null);
    const [popover, setPopover] = useState({
        show: false,
        left: 0,
        top: 0,
        genre_repartition: null,
    });

    const getUpdatedGenreRepartition = (genre_repartition) => {
        const otherCount = { value: 0 };
        const otherGenres = [];

        const otherGenreRepartition = genre_repartition.filter((genre) => {
            const pourcentage = genre.percentage;
            if (pourcentage > 5) {
                return true;
            } else {
                otherCount.value += genre.count;
                otherGenres.push({
                    genre:
                        genre.genre ??
                        I18n.getTranslation(
                            location,
                            "landing.industryStats.no_data"
                        ),
                    pourcentage: pourcentage,
                });
                return false;
            }
        });

        if (otherCount.value > 0) {
            const updatedGenreRepartition = [
                ...otherGenreRepartition,
                {
                    id: 0,
                    genre: I18n.getTranslation(
                        location,
                        "landing.industryStats.other"
                    ),
                    count: otherCount.value,
                    percentage: otherGenres.reduce(
                        (sum, g) => sum + g.pourcentage,
                        0
                    ),
                    top_artists: [],
                    genres: otherGenres,
                },
            ];
            return updatedGenreRepartition;
        } else {
            return genre_repartition;
        }
    };

    const getGenreRepartition = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_country) {
            api.get(`statistics/industry/${id_country}/genre-repartition`, {
                locale,
            })
                .then((response) => {
                    setState({
                        isLoading: false,
                        genre_repartition: getUpdatedGenreRepartition(response),
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        genre_repartition: [],
                    });
                });
        }
    }, [id_country]);

    useEffect(getGenreRepartition, [getGenreRepartition]);

    return (
        <GenreRepartition
            {...props}
            {...state}
            popoverPointerRef={popoverPointerRef}
            containerRef={containerRef}
            popover={popover}
            setPopover={setPopover}
            id_country={id_country}
        />
    );
};

export default EnhancedGenreRepartition;
