import React from "react";
import Widget from "shared/components/Widget";
import Delete from "pages/ArtistManagement/Discography/Actions/Track/Delete";
import CreateOrUpdate from "pages/ArtistManagement/Discography/Forms/Track/CreateOrUpdate";
import InputSwitch from "shared/components/Form/InputSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";

const Basic = ({
    artistId,
    albumId,
    releaseId,
    trackId,
    recordingId,
    onSuccess,
    redirectToView,
}) => {
    return (
        <Widget
            title="artist.management.disco.objects.track.basic.head"
            actions={
                <div className="d-flex">
                    <div className="align-self-center d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} fixedWidth />
                        <InputSwitch checked={true} onChange={redirectToView} />
                        <FontAwesomeIcon icon={faPen} fixedWidth />
                    </div>
                    <Delete />
                </div>
            }
            enableCollapse
        >
            <Widget.Body
                className="pt-3"
                style={{ borderTop: "1px solid #dee2e6" }}
            >
                <CreateOrUpdate
                    artistId={artistId}
                    albumId={albumId}
                    releaseId={releaseId}
                    trackId={trackId}
                    recordingId={recordingId}
                    onSuccess={onSuccess}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Basic;
