import React from "react";
import Partner from "./Partner";
import { useLocation, useNavigate, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { PARTNERS } from "pages/MatchingTrack";

const EnhancedPartner = (props) => {
    const navigate = useNavigate();
    const { locale, partnerId } = useParams();
    const location = useLocation();

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(location, "matching.track.partner.name"),
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(`/${locale}/matching/track/${row.id}`);
            }
        },
        selected: [partnerId],
    };

    return (
        <Partner
            {...props}
            partners={PARTNERS}
            columns={columns}
            defaultSorted={defaultSorted}
            selectRow={selectRow}
        />
    );
};

export default EnhancedPartner;
