import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputPassword from "shared/components/Form/InputPassword";
import { Form, Button } from "react-bootstrap";
import Layout from "layout/Auth/Layout";
import clsx from "clsx";
import Error from "shared/components/Form/Error";
import SidePanel from "layout/Auth/Layout/SidePanel";

const Login = ({
    handleLogin,
    globalError,
    errors,
    register,
    isSubmitting,
    isDirty,
    location,
}) => {
    return (
        <Layout>
            <div
                className="row justify-content-center"
                style={{ minHeight: "610px" }}
            >
                <div className="col-lg-6">
                    <SidePanel />
                </div>
                <div className="col-lg-6">
                    <div className="p-lg-5 p-4">
                        <div>
                            <h3 className="text-secondary">
                                <I18n t={`security.login.title`} />
                            </h3>
                        </div>

                        <hr />

                        <div className="mt-4">
                            <Form onSubmit={!isSubmitting ? handleLogin : null}>
                                {globalError && (
                                    <Error
                                        error={[
                                            I18n.getTranslation(
                                                location,
                                                globalError
                                            ),
                                        ]}
                                    />
                                )}
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t={`security.login.username`} />
                                    </Form.Label>
                                    <InputText
                                        {...register("username")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.username && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.username.message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <div className="float-right">
                                        <NavLink
                                            to={`/resetting/request`}
                                            className="text-muted"
                                        >
                                            <I18n t={`security.login.forgot`} />
                                        </NavLink>
                                    </div>
                                    <Form.Label>
                                        <I18n t={`security.login.password`} />
                                    </Form.Label>
                                    <InputPassword
                                        {...register("password")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.password && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.password.message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>

                                <div className="mt-4">
                                    <Button
                                        variant="inverse"
                                        disabled={isSubmitting || !isDirty}
                                        type="submit"
                                        size="lg"
                                        block
                                    >
                                        <I18n t={`utils.button.login`} />
                                    </Button>
                                </div>
                            </Form>
                        </div>

                        <hr className="my-4" />

                        <div className="text-center">
                            <p className="mb-0">
                                <I18n t={`security.login.noAccount`} />
                                <NavLink
                                    to={`/register`}
                                    className="text-primary text-decoration-underline ml-2"
                                >
                                    <I18n t={`security.login.create`} />
                                </NavLink>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Login;
