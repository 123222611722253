import React from "react";
import { useParams } from "react-router";
import moment from "moment";
import Chart from "./Chart";

const EnhancedChart = React.memo(
    ({
        dates,
        dateUnit,
        dateFormat,
        suggestedMin,
        suggestedMax,
        greySide,
        brightSideLabel,
        setPopover,
        ...props
    }) => {
        // The component is memoized to prevent rerenders when the tooltip position
        // changes

        const { locale } = useParams();

        const goldThreshold = moment().subtract(19, "months").startOf(dateUnit);

        const leftBackgroundColor =
            greySide === "left"
                ? "rgba(145, 28, 225, 0.5)"
                : "rgba(145, 28, 225, 1)";
        const rightBackgroundColor =
            greySide === "right"
                ? "rgba(145, 28, 225, 0.5)"
                : "rgba(145, 28, 225, 1)";
        const leftHoverBackgroundColor =
            greySide === "left"
                ? "rgba(90, 15, 141, 0.5)"
                : "rgba(90, 15, 141, 1)";
        const rightHoverBackgroundColor =
            greySide === "right"
                ? "rgba(90, 15, 141, 0.5)"
                : "rgba(90, 15, 141, 1)";

        const data = {
            labels: dates.map(({ date }) => date),
            datasets: [
                {
                    label: "popularity",
                    data: dates.map(({ score }) => score),
                    backgroundColor: dates.map(({ date }) =>
                        moment(date) > goldThreshold
                            ? leftBackgroundColor
                            : rightBackgroundColor
                    ),
                    hoverBackgroundColor: dates.map(({ date }) =>
                        moment(date) > goldThreshold
                            ? leftHoverBackgroundColor
                            : rightHoverBackgroundColor
                    ),
                    borderRadius: 2,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: "time",
                    reverse: true,
                    suggestedMin,
                    suggestedMax,
                    time: {
                        unit: dateUnit,
                    },
                    ticks: {
                        callback: (value) =>
                            moment(value).locale(locale).format(dateFormat),
                    },
                },
                y: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                    ticks: {
                        stepSize: 25,
                    },
                },
            },
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: ({ chart, tooltip }) => {
                        setPopover({
                            show:
                                tooltip.opacity > 0 &&
                                tooltip.dataPoints !== undefined,
                            left: chart.canvas.offsetLeft + tooltip.caretX,
                            top: chart.canvas.offsetTop + tooltip.caretY,
                            date:
                                tooltip.dataPoints !== undefined
                                    ? dates[tooltip.dataPoints[0].dataIndex]
                                    : null,
                        });
                    },
                },
                greyBackground: {
                    greySide: greySide,
                    brightSideLabel: brightSideLabel,
                    value: goldThreshold
                        .clone()
                        .add(dateUnit === "month" ? 15 : 183, "days"),
                },
            },
        };

        // Plugin to grey-out part of the chart, based on https://www.youtube.com/watch?v=5klTWorgu3Q
        const greyBackground = {
            id: "greyBackground",
            beforeDraw: (chart, args, options) => {
                const {
                    ctx,
                    chartArea: { top, right, left, height },
                    scales: { x },
                } = chart;
                ctx.save();

                const linePosition = x.getPixelForValue(options.value);

                // Draw background
                ctx.fillStyle = "#ddd";
                if (options.greySide === "left") {
                    ctx.fillRect(left, top, linePosition - left, height);
                } else if (options.greySide === "right") {
                    ctx.fillRect(
                        linePosition,
                        top,
                        right - linePosition,
                        height
                    );
                }
                ctx.restore();
            },
            afterDraw: (chart, args, options) => {
                const {
                    ctx,
                    chartArea: { top },
                    scales: { x },
                } = chart;
                ctx.save();

                const linePosition = x.getPixelForValue(options.value);

                // Draw labels
                ctx.fillStyle = "#666";
                ctx.textBaseline = "top";
                if (options.greySide === "left") {
                    ctx.textAlign = "left";
                    ctx.fillText(
                        options.brightSideLabel,
                        linePosition + 5,
                        top + 7
                    );
                } else if (options.greySide === "right") {
                    ctx.textAlign = "right";
                    ctx.fillText(
                        options.brightSideLabel,
                        linePosition - 7,
                        top + 7
                    );
                }
                ctx.restore();
            },
        };

        return (
            <Chart
                {...props}
                data={data}
                options={options}
                plugins={[greyBackground]}
            />
        );
    }
);

export default EnhancedChart;
