import React from "react";
import I18n from "shared/lib/I18n";
import IndustryStatistics from "pages/LandingStatistics/IndustryStatistics";
import MsStatistics from "pages/LandingStatistics/MsStatistics";
import PlanningEditorial from "./PlanningEditorial";
import { Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./PlanningsTab.css";
import clsx from "clsx";

const PlanningsTab = ({
    headerObserverRef,
    isHeaderSticky,
    selectedTab,
    onSelectTab,
    location,
    containerRef,
    containerTopOffset,
    plannings,
}) => {
    return (
        <>
            <div id="header-observer" ref={headerObserverRef}></div>
            <Tab.Container
                id="plannings-tab-tab"
                activeKey={selectedTab}
                defaultActiveKey="planning"
                onSelect={onSelectTab}
                mountOnEnter
                unmountOnExit
            >
                <Nav
                    variant="pills"
                    className={clsx(
                        "plannings-tab-nav",
                        isHeaderSticky && "nav-sticky"
                    )}
                    justify
                >
                    <Nav.Item>
                        <Nav.Link eventKey="planning">
                            <I18n t="plannings.production.my_planning" />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="industry">
                            <I18n t="plannings.production.industry" />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="ms">
                            <I18n t="plannings.production.ms" />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content
                    ref={containerRef}
                    className={clsx(
                        "plannings-tab-nav-content nav-content overflow-hidden"
                    )}
                    style={{
                        marginTop: isHeaderSticky
                            ? `${containerTopOffset}px`
                            : "1.2rem",
                    }}
                >
                    <Tab.Pane eventKey="planning" className="nav-pane">
                        <Helmet>
                            <title>{`${I18n.getTranslation(
                                location,
                                "dashboard"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.breadcrumb"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.my_planning"
                            )}`}</title>
                        </Helmet>
                        {plannings.map((planning) => (
                            <PlanningEditorial
                                key={`${planning.id}-${planning.partner_id}`}
                                planning={planning}
                            />
                        ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="industry" className="nav-pane">
                        <Helmet>
                            <title>{`${I18n.getTranslation(
                                location,
                                "dashboard"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.breadcrumb"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.industry"
                            )}`}</title>
                        </Helmet>
                        <IndustryStatistics />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ms" className="nav-pane">
                        <Helmet>
                            <title>{`${I18n.getTranslation(
                                location,
                                "dashboard"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.breadcrumb"
                            )} - ${I18n.getTranslation(
                                location,
                                "plannings.production.ms"
                            )}`}</title>
                        </Helmet>
                        <MsStatistics />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default PlanningsTab;
