import React from "react";
import { Button } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import Loader from "shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import ImageLoader from "shared/components/ImageLoader";
import Tippy from "@tippyjs/react";
import I18n from "shared/lib/I18n";
import "./Picture.css";

const Picture = ({ picture, onEdit, location, getPictures, handleDelete }) => {
    return (
        <div className="misc-entity-management-curation-card">
            <div className={clsx("card-without-delete", "card", "border")}>
                <LazyLoad
                    height="100%"
                    placeholder={<Loader />}
                    debounce={200}
                    once
                >
                    <div className="image-container">
                        <ImageLoader
                            src={picture.url_version + "?r=" + Math.random()}
                            fallbackSrc={picture.url + "?r=" + Math.random()}
                            className="card-img-top"
                        />
                        <div className="image-content-over">
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <a
                                    className="d-flex"
                                    href={picture.url_version || picture.url}
                                    target="_blank"
                                >
                                    <span className="text-white">
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            size="2x"
                                            fixedWidth
                                        />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </LazyLoad>
                <div className="card-body p-2">
                    <p className="card-text d-flex justify-content-around align-items-center">
                        <span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "misc_entity.management.imageCuration.width"
                                )}
                                trigger="mouseenter"
                            >
                                <span>{picture.width}</span>
                            </Tippy>
                            <span className="mx-1">x</span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "misc_entity.management.imageCuration.height"
                                )}
                                trigger="mouseenter"
                            >
                                <span>{picture.height}</span>
                            </Tippy>
                        </span>
                        <span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "misc_entity.management.imageCuration.created_at"
                                )}
                                trigger="mouseenter"
                            >
                                <span>{picture.created_at || "-"}</span>
                            </Tippy>
                        </span>
                    </p>
                </div>
                <div className="card-body p-2 border-top">
                    <p className="card-text text-center">
                        <span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "misc_entity.management.imageCuration.copyright"
                                )}
                                trigger="mouseenter"
                            >
                                <span>{picture.copyright || "-"}</span>
                            </Tippy>
                        </span>
                    </p>
                </div>
                {picture.tags.length > 0 && (
                    <div className="card-body px-3 pt-1 pb-1 border-top">
                        <div className="row list-tags no-gutters">
                            {picture.tags.map(({ group, name }) => (
                                <span
                                    key={group}
                                    className={`col badge badge-light border m-1`}
                                >
                                    {name}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
                <div className="card-body p-2 border-top">
                    <p className={`card-text`}>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageCuration.status"
                            )}
                            trigger="mouseenter"
                        >
                            <span
                                className={`col badge badge-${
                                    picture.id_version &&
                                    picture.tags.length > 0
                                        ? "success"
                                        : "warning"
                                } badge-lg border`}
                            >
                                {picture.id_version && picture.tags.length > 0
                                    ? "DOCUMENTED"
                                    : "AWAITING"}
                            </span>
                        </Tippy>
                    </p>
                </div>
                <div className="card-footer p-2">
                    <div className="d-flex">
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageCuration.edit_image"
                            )}
                            trigger="mouseenter"
                        >
                            <Button
                                variant="primary"
                                size="xs"
                                className={clsx("w-100 mr-1")}
                                onClick={() => onEdit(picture)}
                            >
                                <I18n t="misc_entity.management.imageCuration.edit" />
                            </Button>
                        </Tippy>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageCuration.delete_image"
                            )}
                            trigger="mouseenter"
                        >
                            <Button
                                variant="danger"
                                size="xs"
                                className={clsx("w-100 ml-1")}
                                onClick={() =>
                                    handleDelete(
                                        picture.id_picture,
                                        getPictures
                                    )
                                }
                            >
                                <I18n t="misc_entity.management.imageCuration.delete" />
                            </Button>
                        </Tippy>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Picture;
