import React from "react";
import AuthorManagement from "./AuthorManagement";
import { useLocation } from "react-router";

const EnhancedAuthorManagement = (props) => {
    const location = useLocation();
    return <AuthorManagement {...props} location={location} />;
};

export default EnhancedAuthorManagement;
