import React, { useState, useEffect, useCallback, useMemo } from "react";
import SearchForm from "./SearchForm";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useLocation, useParams, useNavigate } from "react-router";
import useAPI from "shared/hooks/useApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useToggle from "shared/hooks/useToggle";
import useUser from "shared/hooks/useUser";
import useQuery from "shared/hooks/useQuery";

const defaultValues = {
    search: null,
    main_genre: null,
    principal_genre: null,
    secondary_genre: null,
    country: null,
    type: null,
    gender: null,
    role: null,
    bio_en: null,
    bio_fr: null,
    bio_de: null,
    bio_pt: null,
    curation: null,
    getty: null,
    label: null,
    editorialized: null,
};

const schema = yup.object().shape({
    search: yup
        .string()
        .nullable()
        .when(
            [
                "main_genre",
                "principal_genre",
                "secondary_genre",
                "country",
                "type",
                "gender",
                "role",
                "bio_en",
                "bio_fr",
                "bio_de",
                "bio_pt",
                "curation",
                "getty",
                "label",
                "editorialized",
            ],
            {
                is: (
                    mainGenre,
                    principalGenre,
                    secondaryGenre,
                    country,
                    type,
                    gender,
                    role,
                    bio_en,
                    bio_fr,
                    bio_de,
                    bio_pt,
                    curation,
                    getty,
                    label,
                    editorialized
                ) =>
                    [
                        mainGenre,
                        principalGenre,
                        secondaryGenre,
                        country,
                        type,
                        gender,
                        role,
                        bio_en,
                        bio_fr,
                        bio_de,
                        bio_pt,
                        curation,
                        getty,
                        label,
                        editorialized,
                    ].filter((x) => x !== null).length === 0,
                then: () => yup.string().required("utils.form.required"),
            }
        )
        .transform((value) => value || null),
});

const EnhancedSearchForm = ({ setState, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const [showAdvancedForm, toggleShowAdvancedForm] = useToggle();
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();
    const query = useQuery();

    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onReset = () => {
        reset(defaultValues);
    };

    const queryParams = useMemo(() => {
        let params = {};

        for (let [key, value] of query.entries()) {
            params[key] = value;
        }

        return params;
    }, [query]);

    const onSubmit = (data) => {
        const filteredData = Object.entries(data).reduce(
            (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
            {}
        );

        if (Object.entries(filteredData).length !== 0) {
            const params = new URLSearchParams(filteredData).toString();
            navigate(`/${locale}/artist?${params}`);
        }
    };

    const fetchArtist = useCallback(() => {
        const params = Object.entries(queryParams);
        if (params.length > 0) {
            params.map(([key, value]) => {
                setValue(key, value, {
                    shouldDirty: true,
                });
            });

            setState((previousState) => {
                return { ...previousState, isLoading: true };
            });

            api.get(`artist`, {
                locale,
                ...queryParams,
            })
                .then((response) => {
                    setState({ artists: response, isLoading: false });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.search.error`,
                            error.message
                        )
                    );
                    setState({ artists: [], isLoading: false });
                });
        } else {
            onReset();
            setState({ artists: [], isLoading: false });
        }
    }, [queryParams, locale]);

    useEffect(fetchArtist, [fetchArtist]);

    const [genreOptions, setGenreOptions] = useState([]);
    const getGenre = useCallback(() => {
        if (showAdvancedForm) {
            api.get(`genre/${locale}`)
                .then((response) => {
                    setGenreOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, showAdvancedForm]);

    useEffect(getGenre, [getGenre]);

    const [countryOptions, setCountryOptions] = useState([]);
    const getCountry = useCallback(() => {
        if (showAdvancedForm) {
            api.get(`country/${locale}`)
                .then((response) => {
                    setCountryOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, showAdvancedForm]);

    useEffect(getCountry, [getCountry]);

    const [typeOptions, setTypeOptions] = useState([]);
    const getType = useCallback(() => {
        if (showAdvancedForm) {
            api.get(`artist/type/${locale}`)
                .then((response) => {
                    setTypeOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, showAdvancedForm]);

    useEffect(getType, [getType]);

    const [genderOptions, setGenderOptions] = useState([]);
    const getGender = useCallback(() => {
        if (showAdvancedForm) {
            api.get(`artist/gender/${locale}`)
                .then((response) => {
                    setGenderOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, showAdvancedForm]);

    useEffect(getGender, [getGender]);

    const [roleOptions, setRoleOptions] = useState([]);
    const getRole = useCallback(() => {
        if (showAdvancedForm) {
            api.get(`role/${locale}`)
                .then((response) => {
                    setRoleOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, showAdvancedForm]);

    useEffect(getRole, [getRole]);

    const filterOptions = useCallback(
        (option) => [
            {
                value: true,
                label: I18n.getTranslation(
                    location,
                    `artist.search.filters.${option}.yes`
                ),
            },
            {
                value: false,
                label: I18n.getTranslation(
                    location,
                    `artist.search.filters.${option}.no`
                ),
            },
        ],
        [locale]
    );

    return (
        <SearchForm
            {...props}
            user={user}
            showAdvancedForm={showAdvancedForm}
            toggleShowAdvancedForm={toggleShowAdvancedForm}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            onReset={onReset}
            location={location}
            countryOptions={countryOptions}
            typeOptions={typeOptions}
            genderOptions={genderOptions}
            roleOptions={roleOptions}
            genreOptions={genreOptions}
            filterOptions={filterOptions}
        />
    );
};

export default EnhancedSearchForm;
