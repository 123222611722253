import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Overlay } from "react-bootstrap";
import Chart from "./Chart";
import ChartPopover from "./ChartPopover";
import Loader from "shared/components/Loader";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const SexeRepartition = ({
    popover,
    popoverPointerRef,
    containerRef,
    isLoading,
    sexe_repartition,
    setPopover,
    id_country,
    countryOptions,
    location,
}) => {
    const country = countryOptions.find((c) => id_country === c.id)?.nom;
    return (
        <Widget
            title="landing.industryStats.sexe_repartition"
            titleArgs={country}
            actions={
                <Tippy
                    content={I18n.getTranslation(
                        location,
                        "landing.industryStats.sexe_repartition_description"
                    )}
                    trigger="mouseenter"
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="lg"
                            fixedWidth
                        />
                    </span>
                </Tippy>
            }
        >
            <Widget.Body>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div ref={containerRef} style={{ height: "200px" }}>
                        <div
                            ref={popoverPointerRef}
                            style={{
                                width: "0",
                                height: "0",
                                position: "absolute",
                                left: `${popover.left}px`,
                                top: `${popover.top}px`,
                            }}
                        ></div>
                        <Overlay
                            show={popover.show}
                            target={popoverPointerRef.current}
                            placement="top"
                            flip={true}
                            popperConfig={{
                                modifiers: [
                                    {
                                        name: "preventOverflow",
                                        options: {
                                            boundary: containerRef.current,
                                        },
                                    },
                                ],
                            }}
                        >
                            <ChartPopover
                                sexe_repartition={popover.sexe_repartition}
                            />
                        </Overlay>
                        <Chart
                            sexe_repartition={sexe_repartition}
                            setPopover={setPopover}
                        />
                    </div>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default SexeRepartition;
