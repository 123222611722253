import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import CreateOrUpdateModal from "pages/UserRoleManagement/Role/Form/CreateOrUpdateModal";

const Create = ({
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
    rightsOptions,
}) => {
    return (
        <>
            <Button
                variant="success"
                block
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "user.role.manage.create.title"
                )}
            >
                <I18n t="utils.button.add" />
            </Button>

            {show && (
                <CreateOrUpdateModal
                    show={show}
                    handleClose={handleClose}
                    onSuccess={onSuccess}
                    rightsOptions={rightsOptions}
                />
            )}
        </>
    );
};

export default Create;
