import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import ArtistTitle from "./ArtistTitle";

const EnhancedArtistTitle = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(null);

    const onSearch = (name, title) => {
        if (name === "" || title === "") {
            return;
        }

        setIsLoading(true);
        api.get("artist-title/search", { locale, name, title })
            .then((response) => {
                setResults(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    };

    return (
        <ArtistTitle
            isLoading={isLoading}
            results={results}
            onSearch={onSearch}
            location={location}
            {...props}
        />
    );
};

export default EnhancedArtistTitle;
