import React from "react";
import Bio from "./Bio";
import Wikipedia from "./Wikipedia";

const Biography = ({
    langs,
    locale,
    biographyLangOptions,
    updateBiographyLangStatus,
}) => {
    return (
        <div className="row">
            <div className="col">
                {langs[locale].map((lang) => {
                    return (
                        <Bio
                            key={lang}
                            lang={lang}
                            biographyLangOptions={biographyLangOptions}
                            updateBiographyLangStatus={
                                updateBiographyLangStatus
                            }
                        />
                    );
                })}
            </div>
            <div className="col">
                <Wikipedia />
            </div>
        </div>
    );
};

export default Biography;
