import { createSelector } from "reselect";
import moment from "moment";
import I18n from "shared/lib/I18n";

export const getFilterOptions = createSelector(
    [(args) => args.albums, (args) => args.location],
    (albums, location) =>
        albums.reduce(
            (acc, curr) => {
                acc["types"] = [
                    ...acc.types,
                    {
                        label: I18n.getTranslation(
                            location,
                            `artist.view.discoAlbums.type.${curr.type?.toLowerCase()}`
                        ),
                        value: curr.type,
                    },
                ]
                    .filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    )
                    .sort((a, b) => a.label.localeCompare(b.label));

                acc["formats"] = [
                    ...acc.formats,
                    {
                        label: I18n.getTranslation(
                            location,
                            `artist.view.discoAlbums.format.${curr.format?.toLowerCase()}`
                        ),
                        value: curr.format,
                    },
                ]
                    .filter(
                        (row, index, self) =>
                            self.findIndex((t) => t.value === row.value) ===
                            index
                    )
                    .sort((a, b) => a.label.localeCompare(b.label));

                return acc;
            },
            {
                types: [],
                formats: [],
            }
        )
);

export const getFilteredAlbums = createSelector(
    [(args) => args.albums, (args) => args.selectedFilterOptions],
    (albums, selectedFilterOptions) => {
        let filteredAlbums = albums;

        if (selectedFilterOptions.type) {
            filteredAlbums = filteredAlbums.filter(
                ({ type }) => type === selectedFilterOptions.type.value
            );
        }

        if (selectedFilterOptions.format) {
            filteredAlbums = filteredAlbums.filter(
                ({ format }) => format === selectedFilterOptions.format.value
            );
        }

        if (selectedFilterOptions.year) {
            filteredAlbums = filteredAlbums.filter(({ release_date }) => {
                const year = moment(release_date).format("YYYY");
                return (
                    (selectedFilterOptions.mode === "=" &&
                        selectedFilterOptions.year === year) ||
                    (selectedFilterOptions.mode === "<" &&
                        year < selectedFilterOptions.year) ||
                    (selectedFilterOptions.mode === ">" &&
                        year > selectedFilterOptions.year)
                );
            });
        }

        return filteredAlbums;
    }
);

export const orderByReleaseDate = (data) => {
    return data
        .sort((a, b) => {
            if (a.release_date === b.release_date) {
                return 0;
            }
            if (!a.release_date) {
                return 1;
            }
            if (!b.release_date) {
                return -1;
            }
            return b.release_date.localeCompare(a.release_date);
        })
        .map((curr, index) => ({ rn: index + 1, ...curr }));
};
