import React from "react";
import MultiRecordingSelect from "shared/components/MultiRecordingSelect";

const RecordingMatcher = ({
    msArtists,
    msRecordings,
    onMatchingChange,
    locale,
}) => {
    return (
        <MultiRecordingSelect
            artistIds={msArtists}
            selected={msRecordings}
            locale={locale}
            onChange={onMatchingChange}
            menuPlacement="auto"
            isClearable={false}
        />
    );
};

export default RecordingMatcher;
