import React from "react";
import I18n from "shared/lib/I18n";
import User from "./User";
import { Helmet } from "react-helmet";

const UserManagement = ({ location }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "user.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "user.manage.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="user.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="user.manage.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-12">
                        <User />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default UserManagement;
