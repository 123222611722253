import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputPassword from "shared/components/Form/InputPassword";

const ChangePasswordModal = ({
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    errors,
    isSubmitting,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t={`user.manage.change_password.title`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.change_password.password" />
                        </Form.Label>
                        <InputPassword
                            {...register("password")}
                            error={
                                errors.password && [
                                    I18n.getTranslation(
                                        location,
                                        errors.password.message
                                    ),
                                ]
                            }
                            showEye
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;
