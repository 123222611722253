import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import PlanningsManagement from "./PlanningsManagement";
import useAPI from "shared/hooks/useApi";
import { usePanel } from "layout/Panel";
import Panel from "shared/components/Panel";
import History from "./History";
import I18n from "shared/lib/I18n";
import PlanningsManagementContext from "./PlanningsManagement.context";

const EnhancedPlanningsManagement = (props) => {
    const [tasks, setTasks] = useState([]);
    const [tasksIsLoading, setTasksIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [historyIsLoading, setHistoryIsLoading] = useState(true);
    const [manager, setManager] = useState(null);
    const [partners, setPartners] = useState([]);
    const { locale, planningId, partnerId, selectedTab } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { api } = useAPI();
    const [lock, setLock] = useState(false);
    const planningsManagementContextValue = useMemo(() => {
        return {
            lock,
            setLock,
        };
    }, [lock, setLock]);
    const [planning, setPlanning] = useState();

    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/${planningId}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setPlanning(response);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [planningId, setPlanning, locale]);

    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/${planningId}/partners`)
            .then((response) => {
                if (!cancelled) {
                    setPartners(response);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [setPartners, planningId]);

    useEffect(() => {
        if (planning?.status) {
            setLock(planning.status === "DONE");
        }
    }, [planning, setLock]);

    const fetchTasks = () => {
        let cancelled = false;

        api.get(`plannings/${planningId}/tasks`, { locale, manager, partnerId })
            .then((response) => {
                if (!cancelled) {
                    setTasks(response);
                    setTasksIsLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                if (!cancelled) {
                    setTasks([]);
                    setTasksIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    };

    const fetchHistory = () => {
        let cancelled = false;

        api.get(`plannings/${planningId}/history`, {
            locale,
            manager,
            partnerId,
        })
            .then((response) => {
                if (!cancelled) {
                    setHistory(response);
                    setHistoryIsLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                if (!cancelled) {
                    setHistory([]);
                    setHistoryIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    };

    useEffect(() => {
        setTasksIsLoading(true);
        fetchTasks();
    }, [locale, planningId, setTasks, setTasksIsLoading, manager, partnerId]);
    useEffect(() => {
        setHistoryIsLoading(true);
        fetchHistory();
    }, [
        locale,
        planningId,
        setHistory,
        setHistoryIsLoading,
        manager,
        partnerId,
    ]);

    const [managerOptions, setManagerOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/managers`)
            .then((response) => {
                if (!cancelled) {
                    setManagerOptions(
                        response.map(({ id, name }) => {
                            return { label: name, value: id };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [setManagerOptions]);

    const handleTasksChange = () => {
        fetchTasks();
        fetchHistory();
    };

    const [showPanel, hidePanel] = usePanel(() => (
        <Panel
            title={I18n.getTranslation(location, "plannings.view.history.head")}
            hidePanel={hidePanel}
        >
            <History
                isLoading={historyIsLoading}
                rows={history}
                defaultLocale={locale}
            />
        </Panel>
    ));

    const onSelect = (k) => {
        if (selectedTab) {
            navigate(`/${locale}/plannings/${planningId}/${k}/${selectedTab}`);
        } else {
            navigate(`/${locale}/plannings/${planningId}/${k}`);
        }
    };

    const onSelectTab = (k) =>
        navigate(`/${locale}/plannings/${planningId}/${partnerId}/${k}`);

    return (
        <PlanningsManagementContext.Provider
            value={planningsManagementContextValue}
        >
            <PlanningsManagement
                {...props}
                planning={planning}
                tasks={tasks}
                tasksIsLoading={tasksIsLoading}
                handleTasksChange={handleTasksChange}
                showHistory={showPanel}
                partners={partners}
                managerOptions={managerOptions}
                setManager={setManager}
                partnerId={parseInt(partnerId, 10)}
                onSelect={onSelect}
                onSelectTab={onSelectTab}
                selectedTab={selectedTab}
                location={location}
            />
        </PlanningsManagementContext.Provider>
    );
};

export default EnhancedPlanningsManagement;
