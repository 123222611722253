import React from "react";
import { GoogleReCaptchaCheckbox } from "@google-recaptcha/react";

const ReCaptcha = ({ onVerifyCaptcha, action, locale }) => {
    return (
        <GoogleReCaptchaCheckbox
            action={action}
            onChange={onVerifyCaptcha}
            language={locale}
        />
    );
};

export default ReCaptcha;
