import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import HubbardArtist from "./HubbardArtist";
import { useLocation, useParams } from "react-router";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import CheckAction from "./Action/CheckAction";
import MarkAction from "./Action/MarkAction";
import UnmarkAction from "./Action/UnmarkAction";
import { getFilteredData } from "./HubbardArtist.selector";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedHubbardArtist = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
        completed: null,
        checked: null,
        skiped: null,
    });

    const [matchingsModalArtistID, setMatchingsModalArtistID] = useState(null);
    const matchingsModalArtist =
        matchingsModalArtistID !== null
            ? data.find(
                  (artist) => artist.id_musicbrainz == matchingsModalArtistID
              )
            : null;

    const fetchHubbardArtist = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/artist/${partnerId}/list/${listId}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchHubbardArtist();
    }, [fetchHubbardArtist]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        listId === "missing" && {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.artist.artist.id"),
            sort: true,
            searchable: false,
        },
        listId !== "missing" && {
            dataField: "pos",
            text: I18n.getTranslation(location, "matching.artist.artist.id"),
            sort: true,
            searchable: false,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "matching.artist.artist.name"),
            formatter: (cell, { gid }) => {
                return (
                    <a
                        href={`https://musicbrainz.org/artist/${gid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {cell}
                    </a>
                );
            },
        },
        {
            dataField: "comment",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.complementary"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "matching.artist.artist.type"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.artist"
            ),
            formatter: (
                cell,
                { id_musicbrainz },
                rowIndex,
                { location, locale }
            ) => {
                if (!id_musicbrainz) {
                    return "-";
                }

                return (
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {cell !== null ? (
                                <a
                                    href={`/${locale}/artist/${cell.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {cell.name}
                                </a>
                            ) : (
                                "-"
                            )}
                        </div>
                        <div className="btn-group btn-group-sm">
                            <Button
                                variant="primary"
                                onClick={() =>
                                    setMatchingsModalArtistID(id_musicbrainz)
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "matching.artist.artist.edit_matchings"
                                )}
                                style={{ width: "30px" }}
                            >
                                <FontAwesomeIcon icon={faPen} size="lg" />
                            </Button>
                        </div>
                    </div>
                );
            },
            formatExtraData: {
                location,
                locale,
            },
            headerStyle: {
                width: "25rem",
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "nb_pictures",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.getty_picture"
            ),
            formatter: (cell, { artist }) => {
                return artist ? (
                    <ArtistLink
                        artistId={artist.id}
                        content={
                            <I18n
                                t="matching.artist.artist.picture"
                                args={cell}
                            />
                        }
                        part="getty"
                        edit
                        blank
                    />
                ) : (
                    "-"
                );
            },
            headerStyle: {
                width: "11rem",
            },
            filterValue: (cell, { artist }) => artist?.name,
        },
        {
            dataField: "action",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.action"
            ),
            formatter: (cell, { id, artist, skiped, gid, id_musicbrainz }) => {
                if (!id_musicbrainz) {
                    return "-";
                }

                if (artist) {
                    return (
                        <CheckAction
                            listId={listId}
                            rowId={id}
                            artistId={artist.id}
                            gid={gid}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                }

                if (skiped) {
                    return (
                        <UnmarkAction
                            listId={listId}
                            rowId={id}
                            gid={gid}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                } else {
                    return (
                        <MarkAction
                            listId={listId}
                            rowId={id}
                            gid={gid}
                            onSuccess={(data) => updateRow(id, data)}
                        />
                    );
                }
            },
            filterValue: (cell, { gid }) => gid,
        },
        {
            dataField: "checked_at",
            text: I18n.getTranslation(
                location,
                "matching.artist.artist.checked"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: {
                width: "7rem",
            },
            searchable: false,
        },
    ].filter(Boolean);

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ artist, completed, id_musicbrainz, skiped }) => {
        let classes = null;

        if (!id_musicbrainz) {
            return "table-danger";
        }

        if (completed) {
            return "table-success";
        }

        if (artist) {
            return "table-warning";
        }

        if (skiped) {
            return "table-secondary";
        }

        return classes;
    };

    const onMatchingChange = useCallback(
        (artistId, candidateId, state) =>
            api
                .post(
                    `matching/artist/${partnerId}/list/${listId}/artist`,
                    {
                        locale,
                    },
                    {
                        partnerID: artistId,
                        msID: candidateId,
                        state,
                    }
                )
                .then((response) => {
                    setState((prev) => ({
                        ...prev,
                        data: prev.data.map((row) =>
                            row.id_musicbrainz === artistId
                                ? { ...row, ...response.artistInfos }
                                : row
                        ),
                    }));
                    return response.candidates;
                }),
        [partnerId, listId, locale]
    );

    const fetchCandidates = useCallback(
        (artistId) => api.get(`matching/mb/ms/${artistId}`, { locale }),
        [locale]
    );

    return (
        <HubbardArtist
            {...props}
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            locale={locale}
            location={location}
            filter={filter}
            setFilter={setFilter}
            matchingsModalArtist={matchingsModalArtist}
            setMatchingsModalArtistID={setMatchingsModalArtistID}
            onMatchingChange={onMatchingChange}
            fetchCandidates={fetchCandidates}
        />
    );
};

export default EnhancedHubbardArtist;
