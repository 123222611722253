import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";

const GenreList = ({ columns, onColumnMatch, isLoading, genres, location }) => {
    return (
        <ToolkitProvider
            keyField="id"
            data={genres}
            columns={columns}
            search={{ onColumnMatch }}
        >
            {(toolkitprops) => (
                <Widget
                    title="cnm.repositories.cnm_genres.genres.title"
                    className="vertical-shrink mb-0 pb-0"
                >
                    <Widget.Body>
                        <Search.SearchBar
                            {...toolkitprops.searchProps}
                            placeholder={I18n.getTranslation(
                                location,
                                "cnm.repositories.cnm_genres.genres.search"
                            )}
                        />
                    </Widget.Body>
                    <Widget.Body
                        className="widget-table-overflow overflow-auto"
                        style={{
                            margin: "10px -20px 0px -20px",
                        }}
                    >
                        {isLoading ? (
                            <Loader
                                className="h-100 align-items-center"
                                size="3x"
                            />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                striped
                                hover
                                bordered={false}
                                noDataIndication={
                                    <I18n t="cnm.repositories.cnm_genres.genres.empty" />
                                }
                                classes="table-header-fixed"
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default GenreList;
