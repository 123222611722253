import React, { useCallback, useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useAPI from "shared/hooks/useApi";
import Register from "./Register";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    registration_is_client: yup.boolean().required("utils.form.required"),
    email: yup
        .string()
        .email()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    firstname: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    lastname: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required")
        .matches(/^(?=.*[A-Z]).+$/, "utils.form.must_contain_uppercase")
        .matches(/^(?=.*[a-z]).+$/, "utils.form.must_contain_lowercase")
        .matches(/^.{8,}$/, "utils.form.must_contain_8_characters")
        .matches(/^(?=.*\d).+$/, "utils.form.must_contain_digit")
        .matches(/^(?=.*[a-zA-Z]).+$/, "utils.form.must_contain_letter"),
    registration_type: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    registration_label: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .when(["registration_type"], {
            is: (registration_type) =>
                registration_type === "label_distributor",
            then: () => yup.string().required("utils.form.required"),
        }),
    registration_company: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .when(["registration_type"], {
            is: (registration_type) =>
                ["music_service", "cmo", "label_distributor"].includes(
                    registration_type
                ),
            then: () => yup.string().required("utils.form.required"),
        }),
    registration_artist: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .when(["registration_type"], {
            is: (registration_type) => registration_type === "artist_manager",
            then: () => yup.string().required("utils.form.required"),
        }),
    registration_role: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .when(["registration_type"], {
            is: (registration_type) =>
                ["music_service", "cmo", "label_distributor"].includes(
                    registration_type
                ),
            then: () => yup.string().required("utils.form.required"),
        }),
    registration_describe: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    registration_language: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    captcha_token: yup.string().required("utils.form.required"),
});

const getDefaultLanguage = () => {
    const language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    const defaultLanguage = language.split("-")[0];

    return defaultLanguage &&
        ["fr", "en", "de", "ja", "ko"].includes(defaultLanguage)
        ? defaultLanguage
        : "en";
};

const defaultValues = {
    registration_is_client: false,
    email: null,
    firstname: null,
    lastname: null,
    password: null,
    registration_type: null,
    registration_label: null,
    registration_company: null,
    registration_role: null,
    registration_describe: null,
    registration_language: getDefaultLanguage(),
    registration_artist: null,
    captcha_token: null,
};

const EnhancedRegister = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        globalError: null,
    });

    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        resetField,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const isClientWatch = watch("registration_is_client");
    const accountTypeWatch = watch("registration_type");

    useEffect(() => {
        resetField("registration_label");
        resetField("registration_company");
        resetField("registration_role");
        resetField("registration_describe");
        resetField("registration_artist");
    }, [accountTypeWatch]);

    const handleRegister = async (data) => {
        try {
            await api.register(data);
            toast.success(
                I18n.getTranslation(location, `security.register.success`)
            );
            navigate(`/${locale}/login`);
        } catch (e) {
            setState((state) => ({ ...state, globalError: e.message || e }));
        }
    };

    const accountTypeOptions = [
        {
            label: I18n.getTranslation(
                location,
                "security.register.account_type.music_service"
            ),
            value: "music_service",
        },
        {
            label: I18n.getTranslation(
                location,
                "security.register.account_type.cmo"
            ),
            value: "cmo",
        },
        {
            label: I18n.getTranslation(
                location,
                "security.register.account_type.label_distributor"
            ),
            value: "label_distributor",
        },
        {
            label: I18n.getTranslation(
                location,
                "security.register.account_type.artist_manager"
            ),
            value: "artist_manager",
        },
    ];

    const accountRoleOptions = {
        music_service: [
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.music_editor"
                ),
                value: "music_editor",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.marketing"
                ),
                value: "marketing",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.product_management"
                ),
                value: "product_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.other"
                ),
                value: "other",
            },
        ],
        label_distributor: [
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.promotion"
                ),
                value: "promotion",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.business_development"
                ),
                value: "business_development",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.a_and_r"
                ),
                value: "a_and_r",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.supply_chain"
                ),
                value: "supply_chain",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.analytics"
                ),
                value: "analytics",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.other"
                ),
                value: "other",
            },
        ],
        cmo: [
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "security.register.role.catalog_management"
                ),
                value: "catalog_management",
            },
        ],
    };

    const accountLanguageOptions = [
        {
            label: I18n.getTranslation(location, "utils.lang.FR"),
            value: "fr",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.EN"),
            value: "en",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.DE"),
            value: "de",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.JA"),
            value: "ja",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.KO"),
            value: "ko",
        },
    ];

    const loadLabelOptions = async (term, callback) => {
        const response = await api.searchLabel(term);
        const result = await response.json();

        if (result.length > 0) {
            callback(
                result.map(({ name }) => {
                    return {
                        value: name,
                        label: name,
                    };
                })
            );
        }

        return [];
    };

    const onVerifiedCaptcha = useCallback((token) => {
        setValue("captcha_token", token);
    }, []);

    return (
        <Register
            {...state}
            handleRegister={handleSubmit(handleRegister)}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            control={control}
            location={location}
            accountTypeOptions={accountTypeOptions}
            accountRoleOptions={accountRoleOptions}
            accountLanguageOptions={accountLanguageOptions}
            isClientWatch={isClientWatch}
            accountTypeWatch={accountTypeWatch}
            loadLabelOptions={loadLabelOptions}
            onVerifiedCaptcha={onVerifiedCaptcha}
            {...props}
        />
    );
};

export default EnhancedRegister;
