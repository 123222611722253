import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import PopularityHistogram from "./PopularityHistogram";

const defaultState = {
    gold: [],
    current: [],
    isLoading: false,
};

const EnhancedPopularityHistogram = ({ id_recording, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const [state, setState] = useState(defaultState);

    const popoverPointerRef = useRef(null);
    const containerRef = useRef(null);
    const [popover, setPopover] = useState({
        show: false,
        left: 0,
        top: 0,
        date: null,
    });

    const getPopularity = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_recording) {
            return api
                .get(`recording/${id_recording}/popularity/histogram`, {
                    locale,
                })
                .then((response) => {
                    setState({
                        ...response,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                });
        }
    }, [id_recording, locale]);

    useEffect(() => {
        getPopularity();
    }, [getPopularity]);

    return (
        <PopularityHistogram
            {...props}
            {...state}
            popoverPointerRef={popoverPointerRef}
            containerRef={containerRef}
            popover={popover}
            setPopover={setPopover}
            location={location}
        />
    );
};

export default EnhancedPopularityHistogram;
