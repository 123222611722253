import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import Recommendation from "./Recommendation";

const EnhancedRecommendation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState({ tracks: [] });

    const onSearch = (
        msTrackIds,
        msArtistIds,
        faBpm,
        faArousal,
        faValence,
        faIntensity,
        faMoods,
        yearRange,
        minAccuracy
    ) => {
        setIsLoading(true);
        api.get("api/recommendation", {
            locale,
            msTrackIds: msTrackIds || "",
            msArtistIds: msArtistIds || "",
            faBpm: faBpm || "",
            faArousal: faArousal || "",
            faValence: faValence || "",
            faIntensity: faIntensity || "",
            faMoods: faMoods || [],
            yearRange: yearRange || 5,
            minAccuracy: minAccuracy || 32,
        })
            .then((response) => {
                setResults(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    };

    return (
        <Recommendation
            isLoading={isLoading}
            results={results}
            onSearch={onSearch}
            location={location}
            {...props}
        />
    );
};

export default EnhancedRecommendation;
