import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const Languages = ({ principal, secondary, isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="loader" style={{ zIndex: "9999999" }}>
                    <Loader size="2x" />
                </div>
            )}
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`album.view.universLanguages.head`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div
                className="row"
                style={{ maxHeight: "5rem", overflow: "auto" }}
            >
                <div className="col">
                    {principal}
                    {secondary.length > 0 &&
                        secondary.map((row) => (
                            <span>
                                {", "}
                                {row}
                            </span>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Languages;
