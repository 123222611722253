import React from "react";

export const SidebarNotificationContext = React.createContext({
    labelPromoDisable: {
        count: 0,
        dispatch: () => {},
    },
    picturesLabelAwaiting: {
        count: 0,
        dispatch: () => {},
    },
});
