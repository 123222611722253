import React, { useState, useEffect } from "react";
import Popularity from "./Popularity";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    overview: true,
};

const EnhancedPopularity = (props) => {
    const [showGold, setShowGold] = useState(false);

    const { artistId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [artistId]);

    return (
        <Popularity
            showGold={showGold}
            setShowGold={setShowGold}
            hasData={hasData}
            setNoData={setNoData}
            {...props}
        />
    );
};

export default EnhancedPopularity;
