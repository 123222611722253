import React from "react";
import List from "./List";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import {
    SIMPLE,
    CREATION,
    DISCARDED,
} from "pages/DataExternalUniversalArtist/Coverage/Coverage.constants";

const EnhancedList = ({ id, title, exportName, ...props }) => {
    const location = useLocation();

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.coverage.id"
            ),
        },
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.coverage.name"
            ),
        },
    ];

    switch (id) {
        case SIMPLE:
        case CREATION:
            columns.push({
                dataField: "ms_artist",
                text: I18n.getTranslation(
                    location,
                    "data_external.universal_artist.coverage.ms_artist"
                ),
                formatter: (cell) => {
                    return cell ? (
                        <ArtistLink
                            artistId={cell.id}
                            content={cell.name}
                            blank
                        />
                    ) : (
                        "-"
                    );
                },
                filterValue: (cell) => cell?.name,
            });
            break;
    }

    switch (id) {
        case CREATION:
            columns.push({
                dataField: "user_creation",
                text: I18n.getTranslation(
                    location,
                    "data_external.universal_artist.coverage.user"
                ),
            });
            break;
        case DISCARDED:
            columns.push({
                dataField: "comment",
                text: I18n.getTranslation(
                    location,
                    "data_external.universal_artist.coverage.comment"
                ),
                formatter: (cell) => {
                    return <span className="pre-wrap">{cell}</span>;
                },
                searchable: false,
            });
            columns.push({
                dataField: "user_discarded",
                text: I18n.getTranslation(
                    location,
                    "data_external.universal_artist.coverage.user"
                ),
            });
            break;
    }

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <List
            {...props}
            title={title}
            exportName={`${exportName}_${I18n.getTranslation(location, title)}`}
            columns={columns}
            options={options}
        />
    );
};

export default EnhancedList;
