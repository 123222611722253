import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { HTTP_NOT_FOUND, HTTP_CONFLICT } from "shared/constants/http";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import DiscardCell from "./DiscardCell";
import PicturesCell from "./PicturesCell";
import RecoverCell from "./RecoverCell";
import ArtistList from "./ArtistList";

const EnhancedArtistList = (props) => {
    const { api } = useAPI();
    const location = useLocation();

    const { locale, partner, date, top } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [artists, setArtists] = useState({ all: [], filtered: [] });
    const [filters, setFilters] = useState({
        matched: null,
        discarded: null,
        bioEN: null,
        bioFR: null,
        pictures: null,
    });
    const [matchingsModalArtistID, setMatchingsModalArtistID] = useState(null);
    const matchingsModalArtist =
        matchingsModalArtistID !== null
            ? artists.all.find(
                  (artist) => artist.artistId == matchingsModalArtistID
              )
            : null;

    const filterArtists = (artists) => ({
        all: artists,
        filtered: artists.filter(
            (artist) =>
                (filters.matched === null ||
                    filters.matched == (artist.matchingId !== null)) &&
                (filters.discarded === null ||
                    filters.discarded == (artist.discarded !== null)) &&
                (filters.bioEN === null ||
                    filters.bioEN == (artist.hasBioEN ?? false)) &&
                (filters.bioFR === null ||
                    filters.bioFR == (artist.hasBioFR ?? false)) &&
                (filters.pictures === null ||
                    filters.pictures == artist.picturesStatus)
        ),
    });

    const setArtistsAndFilter = (artists) => {
        setArtists(filterArtists(artists));
    };

    useEffect(() => {
        setArtists(({ all }) => filterArtists(all));
    }, [filters]);

    const updateArtist = (key, id, updatedArtist) => {
        setArtists(({ all, filtered }) => ({
            all: all.map((artist) =>
                artist[key] == id ? { ...artist, ...updatedArtist } : artist
            ),
            // Always keep the updated artist in the filtered list, even if it
            // should be filtered-out given its new state.
            filtered: filtered.map((artist) =>
                artist[key] == id ? { ...artist, ...updatedArtist } : artist
            ),
        }));
    };

    const fetchArtists = useCallback(() => {
        if (
            partner === undefined ||
            date === undefined ||
            (partner != "qb" && top === undefined)
        ) {
            setArtistsAndFilter([]);
            setIsLoading(false);
            return;
        }

        let cancelled = false;
        setIsLoading(true);
        api.get(
            partner == "qb"
                ? `partner_tops/${partner}/${date}`
                : `partner_tops/${partner}/${date}/${top}`
        )
            .then((response) => {
                if (!cancelled) {
                    setArtistsAndFilter(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setArtistsAndFilter([]);
                    setIsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [partner, date, top]);

    useEffect(() => {
        fetchArtists();
    }, [fetchArtists]);

    const onMatchingChange = useCallback(
        (artistId, candidateId, state) =>
            api
                .post(
                    `partner_tops/${partner}/matching`,
                    {
                        locale,
                    },
                    {
                        partnerID: artistId,
                        msID: candidateId,
                        state,
                    }
                )
                .then((response) => {
                    updateArtist("artistId", artistId, response.artistInfos);
                    return response.candidates;
                }),
        [partner, locale]
    );
    const onDiscard = (artistId, comment) => {
        api.post(`partner_tops/${partner}/discard/${artistId}`, {}, { comment })
            .then((response) => {
                updateArtist("artistId", artistId, response.artistInfos);
                toast.success(
                    I18n.getTranslation(
                        location,
                        "partner_tops.artists.discard.success"
                    )
                );
            })
            .catch((error) => {
                updateArtist("artistId", artistId, error.body?.artistInfos);
                console.error(error);

                toast.error(
                    error.status === HTTP_CONFLICT
                        ? I18n.getTranslation(
                              location,
                              "partner_tops.artists.discard.duplicate"
                          )
                        : I18n.getTranslation(
                              location,
                              "partner_tops.artists.discard.error",
                              error.message
                          )
                );
            });
    };
    const onRecover = (artistId) => {
        api.delete(`partner_tops/${partner}/discard/${artistId}`)
            .then((response) => {
                updateArtist("artistId", artistId, response.artistInfos);
                toast.success(
                    I18n.getTranslation(
                        location,
                        "partner_tops.artists.recover.success"
                    )
                );
            })
            .catch((error) => {
                updateArtist("artistId", artistId, error.body?.artistInfos);
                console.error(error);

                toast.error(
                    error.status === HTTP_NOT_FOUND
                        ? I18n.getTranslation(
                              location,
                              "partner_tops.artists.recover.not_found"
                          )
                        : I18n.getTranslation(
                              location,
                              "partner_tops.artists.recover.error",
                              error.message
                          )
                );
            });
    };
    const onCheck = (msID, comment) => {
        updateArtist("matchingId", msID, {
            hasPictureCheck: true,
            pictureComment: comment,
            lastPictureCheck: new Date().toISOString().substring(0, 10),
            picturesStatus: 4,
        });
    };

    const fetchCandidates = useCallback(
        (artistId) =>
            api.get(`matching/candidates/${partner}/ms/${artistId}`, {
                locale,
            }),
        [partner, locale]
    );

    const rowClasses = (row) =>
        row.discarded !== null ? "table-secondary" : undefined;

    const columns = [
        {
            dataField: "position",
            text: I18n.getTranslation(location, "partner_tops.artists.rank"),
            headerStyle: { width: "7em" },
            csvType: Number,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "partner_tops.artists.name"),
            headerStyle: { width: "20%" },
            formatter: (cell, row) => (
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: "matchingId",
            text: I18n.getTranslation(
                location,
                "partner_tops.artists.matchings"
            ),
            headerStyle: { width: "40%" },
            classes: (cell, row) => {
                if (row.discarded !== null) {
                    return "table-secondary";
                }
                if (cell !== null) {
                    return "table-success";
                }
                return "table-danger";
            },
            formatter: (cell, row) =>
                row.discarded !== null ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <I18n
                            t="partner_tops.artists.discarded"
                            args={row.discarded}
                        />
                        <RecoverCell
                            onConfirm={() => onRecover(row.artistId)}
                        />
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {cell !== null ? (
                                <a
                                    href={`/${locale}/artist/${cell}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {row.matchingName} ({cell})
                                </a>
                            ) : (
                                "-"
                            )}
                        </div>
                        {cell === null && (
                            <DiscardCell
                                onConfirm={(comment) =>
                                    onDiscard(row.artistId, comment)
                                }
                            />
                        )}
                        <div className="btn-group btn-group-sm">
                            <Button
                                variant="primary"
                                onClick={() =>
                                    setMatchingsModalArtistID(row.artistId)
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "partner_tops.artists.editMatchings"
                                )}
                                style={{ width: "30px" }}
                            >
                                <FontAwesomeIcon icon={faPen} size="lg" />
                            </Button>
                        </div>
                    </div>
                ),
            csvExport: false,
        },
        {
            dataField: "dummyBiographies",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "partner_tops.artists.biographies"
            ),
            headerStyle: { width: "14em" },
            formatter: (cell, { matchingId, hasBioEN, hasBioFR }) => {
                if (matchingId === null) {
                    return "-";
                }

                return (
                    <>
                        <span
                            className={`badge badge-lg ${
                                hasBioEN ? "badge-success" : "badge-danger"
                            } mr-3`}
                        >
                            <I18n t="partner_tops.artists.biographies_en" />
                        </span>
                        <span
                            className={`badge badge-lg ${
                                hasBioFR ? "badge-success" : "badge-danger"
                            } mr-3`}
                        >
                            <I18n t="partner_tops.artists.biographies_fr" />
                        </span>
                    </>
                );
            },
            csvExport: false,
        },
        {
            dataField: "dummyPictures",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "partner_tops.artists.pictures"
            ),
            headerStyle: { width: "50%" },
            formatter: (cell, row) => (
                <PicturesCell
                    artistId={row.matchingId}
                    hasBackstagePicture={row.hasBackstagePicture}
                    hasOtherPicture={row.hasOtherPicture}
                    hasGettyPicture={row.hasGettyPicture}
                    hasCurationPicture={row.hasCurationPicture}
                    lastGettyPictureDate={row.lastGettyPictureDate}
                    lastGettyCreation={row.lastGettyCreation}
                    hasLabelPicture={row.hasLabelPicture}
                    lastLabelCreation={row.lastLabelCreation}
                    hasPictureCheck={row.hasPictureCheck}
                    pictureComment={row.pictureComment}
                    lastPictureCheck={row.lastPictureCheck}
                    hasRecentPictureCheck={row.hasRecentPictureCheck}
                    onCheck={onCheck}
                    picturesStatus={row.picturesStatus}
                />
            ),
            csvExport: false,
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        totalSize: artists.filtered.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <ArtistList
            partner={partner}
            date={date}
            top={top}
            isLoading={isLoading}
            artists={artists.filtered}
            columns={columns}
            pagination={pagination}
            rowClasses={rowClasses}
            filters={filters}
            setFilters={setFilters}
            matchingsModalArtist={matchingsModalArtist}
            setMatchingsModalArtistID={setMatchingsModalArtistID}
            locale={locale}
            onMatchingChange={onMatchingChange}
            fetchCandidates={fetchCandidates}
            {...props}
        />
    );
};

export default EnhancedArtistList;
