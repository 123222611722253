import React from "react";
import { Modal, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import TaskForm from "pages/PlanningsManagement/Form/TaskForm";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddToPlanningButton = ({ show, handleClose, handleShow, msArtist }) => {
    return (
        <>
            <Button
                variant="primary"
                className="mr-1"
                size="sm"
                onClick={handleShow}
            >
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t={`plannings.view.tasks.action.create.title`} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskForm
                        ms_artist={msArtist}
                        partnerId={PLANNING_PARTNER.EDITORIAL}
                        onSubmitSuccess={() => {
                            handleClose();
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddToPlanningButton;
