import React, { useEffect, useContext } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    title: yup
        .string()
        .trim()
        .nullable()
        .transform((value) => value || null),
    start_date: yup.string().nullable().required("utils.form.required"),
    end_date: yup.string().nullable().required("utils.form.required"),
});

const defaultValues = {
    title: null,
    start_date: null,
    end_date: null,
};

const EnhancedCreateOrUpdateModal = ({
    handleClose,
    planning,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        reset({
            ...defaultValues,
            ...planning,
        });
    }, [reset, planning]);

    const onSubmit = (data) => {
        let apiRequest;
        if (planning?.id) {
            apiRequest = api.put(`plannings/${planning.id}`, { locale }, data);
        } else {
            apiRequest = api.post(`plannings/create`, {}, data);
        }
        apiRequest
            .then((response) => {
                toast.success(response.message);
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CreateOrUpdateModal
            {...props}
            planning={planning}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            control={control}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
