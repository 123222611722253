import React, { useState } from "react";
import Create from "./Create";
import { useParams, useLocation, useNavigate } from "react-router";

const EnhancedCreate = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedTab, artistId, locale } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSuccess = (albumId) => {
        handleClose();
        navigate(
            `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}`
        );
    };

    return (
        <Create
            {...props}
            artistId={artistId}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            onSuccess={onSuccess}
            location={location}
        />
    );
};

export default EnhancedCreate;
