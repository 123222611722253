import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import InputAsyncCreatableSelect from "shared/components/Form/InputAsyncCreatableSelect";

const LabelSelect = ({ value, loadOptions, onChange, creatable, ...props }) => {
    if (creatable) {
        return (
            <InputAsyncCreatableSelect
                value={value}
                loadOptions={loadOptions}
                onChange={onChange}
                {...props}
            />
        );
    }
    return (
        <InputAsyncSelect
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
            {...props}
        />
    );
};

export default LabelSelect;
