import React from "react";
import Action from "./Action";
import useToggle from "shared/hooks/useToggle";

const EnhancedAction = ({ onSuccess, ...props }) => {
    const [showUpload, toggleUpload] = useToggle(false);
    const onUploadSuccess = () => {
        toggleUpload();
        onSuccess?.();
    };

    return (
        <Action
            {...props}
            showUpload={showUpload}
            toggleUpload={toggleUpload}
            onUploadSuccess={onUploadSuccess}
        />
    );
};

export default EnhancedAction;
