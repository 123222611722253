import React from "react";
import Chart from "./Chart";
import Loader from "shared/components/Loader";
import Tippy from "@tippyjs/react";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Artists = ({
    isLoading,
    artists_creation,
    artists_with_photo,
    artists_with_bio,
    biographiesLang,
    location,
}) => {
    const content = (
        <div>
            <p>
                {I18n.getTranslation(
                    location,
                    "landing.msStats.artists.biographies_description"
                )}
            </p>
            <ul>
                {biographiesLang.map((lang, index) => (
                    <li key={index}>{lang.name}</li>
                ))}
            </ul>
        </div>
    );
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div
                    style={{
                        position: "relative",
                        height: "500px",
                    }}
                >
                    <Tippy content={content} trigger="mouseenter">
                        <span
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                cursor: "pointer",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="lg"
                                fixedWidth
                            />
                        </span>
                    </Tippy>
                    <Chart
                        artists_creation={artists_creation}
                        artists_with_photo={artists_with_photo}
                        artists_with_bio={artists_with_bio}
                        location={location}
                    />
                </div>
            )}
        </>
    );
};

export default Artists;
