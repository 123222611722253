import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import CreationAction from "./CreationAction";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedCreationAction = ({
    listId,
    rowId,
    artistId,
    artist_created,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const onMark = () => {
        if (artist_created) {
            api.delete(
                `matching/artist/universal/list/${listId}/${rowId}/artist/created`
            )
                .then((response) => {
                    onSuccess(response);

                    toast.success(
                        I18n.getTranslation(
                            location,
                            "matching.artist.artist.unartistCreated.success"
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "matching.artist.artist.unartistCreated.error",
                            error.message
                        )
                    );
                });
        } else {
            api.post(
                `matching/artist/universal/list/${listId}/${rowId}/artist/created`,
                { locale, artistId }
            )
                .then((response) => {
                    onSuccess(response);

                    toast.success(
                        I18n.getTranslation(
                            location,
                            "matching.artist.artist.artistCreated.success"
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "matching.artist.artist.artistCreated.error",
                            error.message
                        )
                    );
                });
        }
    };

    return (
        <CreationAction
            onMark={onMark}
            artist_created={artist_created}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCreationAction;
