import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const CleanButton = ({ disabled, onClick, location }) => {
    return (
        <Button
            variant="warning"
            size="sm"
            title={I18n.getTranslation(
                location,
                "snep.file.fileList.clean.title"
            )}
            disabled={disabled}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faBackward} size="lg" fixedWidth />
        </Button>
    );
};

export default CleanButton;
