import React, { useContext, useCallback, useEffect } from "react";
import ArtistTitle from "./ArtistTitle";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useLocation, useParams, useNavigate } from "react-router";
import { ArtistManagementContext, ARTIST_SET } from "pages/ArtistManagement";
import swal from "sweetalert";

const EnhancedArtistTitle = (props) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const location = useLocation();
    const { locale } = useParams();
    const navigate = useNavigate();
    const { dispatchArtist, artist } = useContext(ArtistManagementContext);

    const getArtist = useCallback(() => {
        api.get(`artist/${artistId}`)
            .then((response) => {
                dispatchArtist({ type: ARTIST_SET, data: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    useEffect(getArtist, [getArtist]);

    const handleComplete = useCallback(() => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.action.complete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.action.complete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }

            api.put(`artist/${artistId}/complete`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.management.action.complete.success`
                        )
                    );

                    dispatchArtist({ type: ARTIST_SET, data: response });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.management.action.complete.error`,
                            error.message
                        )
                    );
                });
        });
    }, [artistId, location]);

    const redirectToView = () => {
        navigate(`/${locale}/artist/${artistId}/view`);
    };

    return (
        <ArtistTitle
            {...props}
            artist={artist}
            handleComplete={handleComplete}
            location={location}
            redirectToView={redirectToView}
        />
    );
};

export default EnhancedArtistTitle;
