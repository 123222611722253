import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const Archive = ({ value, show, handleClose, handleShow, location }) => {
    if (!value) {
        return null;
    }

    return (
        <>
            <Button
                variant="dark"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "artist.management.bio.archive"
                )}
                className="ml-3"
            >
                <I18n t="artist.management.bio.archive" />
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                size="lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="artist.management.bio.archive" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: value,
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Archive;
