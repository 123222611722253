import React from "react";
import Error from "shared/components/Form/Error";
import clsx from "clsx";

const InputText = React.forwardRef(
    ({ className, error, showError = true, ...props }, ref) => {
        return (
            <>
                <input
                    type="text"
                    className={clsx(
                        "form-control",
                        error && "is-invalid",
                        className
                    )}
                    title={error ? error.join(", ") : ""}
                    ref={ref}
                    {...props}
                />
                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputText;
