import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { useParams } from "react-router";

const EnhancedPrivacyPolicy = (props) => {
    const { locale } = useParams();
    return <PrivacyPolicy locale={locale} {...props} />;
};

export default EnhancedPrivacyPolicy;
