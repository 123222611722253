import React, { useMemo } from "react";
import { useLocation } from "react-router";
import GenreList from "./GenreList";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";

const EnhancedGenreList = ({ genres, matchings, setFilters, ...props }) => {
    const location = useLocation();

    const enrichedGenres = useMemo(() => {
        // Object containing the total number of children of each MS main genres.
        const totalChildren = matchings.reduce(
            (totalChildren, { msMainId }) => ({
                ...totalChildren,
                [msMainId]: (totalChildren?.[msMainId] ?? 0) + 1,
            }),
            {}
        );

        // Object containing the ID of the CNM genre matched with each MS main genres.
        const mainMatching = matchings.reduce(
            (mainMatching, { cnmId, msId, msMainId }) =>
                msId == msMainId
                    ? {
                          ...mainMatching,
                          [msMainId]: cnmId,
                      }
                    : mainMatching,
            {}
        );

        // Object containing the number of children of each MS main genre that
        // is matched with the same genre as its parent.
        const childrenWithSameMatching = matchings.reduce(
            (childrenWithSameMatching, { cnmId, msMainId }) =>
                cnmId == mainMatching[msMainId]
                    ? {
                          ...childrenWithSameMatching,
                          [msMainId]:
                              (childrenWithSameMatching?.[msMainId] ?? 0) + 1,
                      }
                    : childrenWithSameMatching,
            {}
        );

        return genres.map(({ id, name }) => {
            let msGenres = matchings
                .filter(
                    ({ cnmId, msId, msMainId }) =>
                        // Remove MS genres that are matched with the current CNM genre
                        cnmId == id &&
                        // and minor MS genres whose parent is matched with the current CNM genre
                        (msId == msMainId || mainMatching[msMainId] != id)
                )
                .map(({ msId, msMainId, msName }) => {
                    const isMain = msId == msMainId;

                    if (isMain) {
                        return {
                            msId,
                            msMainId,
                            msName,
                            isMain: true,
                            childrenWithSameMatching:
                                childrenWithSameMatching[msId],
                            totalChildren: totalChildren[msId],
                        };
                    } else {
                        return {
                            msId,
                            msMainId,
                            msName,
                            isMain: false,
                            childrenWithSameMatching: null,
                            totalChildren: null,
                        };
                    }
                });

            // Show main genres first
            msGenres.sort((a, b) =>
                a.isMain === b.isMain ? 0 : a.isMain ? -1 : 1
            );

            return {
                id,
                name,
                msGenres,
            };
        });
    }, [genres, matchings]);

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_genres.genres.cnmGenre"
            ),
            formatter: (cell, row) => (
                <a
                    onClick={(e) => {
                        setFilters({
                            msMainGenre: null,
                            cnmGenre: row.id,
                        });
                        e.preventDefault();
                    }}
                    href="#"
                >
                    {cell}
                </a>
            ),
            filterValue: normalizeTitle,
        },
        {
            dataField: "msGenres",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_genres.genres.msGenres"
            ),
            formatter: (cell) =>
                cell.map(
                    ({
                        msId,
                        msMainId,
                        msName,
                        isMain,
                        childrenWithSameMatching,
                        totalChildren,
                    }) => (
                        <a
                            key={msId}
                            className={`badge badge-${
                                isMain ? "primary" : "secondary"
                            } mr-1 mb-1`}
                            style={{ fontSize: "100%" }}
                            onClick={(e) => {
                                setFilters({
                                    msMainGenre: msMainId,
                                    cnmGenre: null,
                                });
                                e.preventDefault();
                            }}
                            href="#"
                        >
                            {isMain
                                ? `${msName} (${childrenWithSameMatching} / ${totalChildren})`
                                : msName}
                        </a>
                    )
                ),
        },
    ];

    return (
        <GenreList
            columns={columns}
            onColumnMatch={onColumnMatchNormalized}
            genres={enrichedGenres}
            location={location}
            {...props}
        />
    );
};

export default EnhancedGenreList;
