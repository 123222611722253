import React, { useState, useEffect, useCallback } from "react";
import Author from "./Author";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import Delete from "./Button/Delete";

const EnhancedAuthor = (props) => {
    const [author, setAuthor] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { api } = useAPI();

    const getAuthor = useCallback(() => {
        api.get("author")
            .then((response) => {
                setAuthor(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getAuthor, [getAuthor]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "author.manage.author.id"),
            sort: true,
        },
        {
            dataField: "firstname",
            text: I18n.getTranslation(
                location,
                "author.manage.author.firstname"
            ),
            sort: true,
        },
        {
            dataField: "lastname",
            text: I18n.getTranslation(
                location,
                "author.manage.author.lastname"
            ),
            sort: true,
        },
        {
            dataField: "signature",
            text: I18n.getTranslation(
                location,
                "author.manage.author.signature"
            ),
            sort: true,
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            align: "center",
            text: I18n.getTranslation(location, "author.manage.author.action"),
            formatter: (cell, row) => {
                return (
                    <ButtonGroup>
                        <Update author={row} onSuccess={getAuthor} />
                        <Delete id={row.id} onSuccess={getAuthor} />
                    </ButtonGroup>
                );
            },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Author
            {...props}
            author={author}
            columns={columns}
            isLoading={isLoading}
            options={options}
            getAuthor={getAuthor}
        />
    );
};

export default EnhancedAuthor;
