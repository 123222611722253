import { useEffect } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const defaultUser = {
    id: null,
    name: null,
    email: null,
    language: null,
    rights: [],
    roles: [],
    token: null,
    refresh_token: null,
    chatwoot_token: null,
    chatwoot_hash: null,
    authenticated: false,
    isLoading: true,
};

const useStore = create(
    devtools((set, get) => ({
        user: {
            ...defaultUser,
            hasRight: (...rights) =>
                rights.some((value) => get().user.rights.includes(value)),
        },
        setUser: (user) =>
            set((state) => ({
                user: {
                    ...state.user,
                    ...user,
                    authenticated: true,
                    isLoading: false,
                },
            })),
        invalidate: () =>
            set((state) => ({ user: { ...state.user, ...defaultUser } })),
        setIsLoading: (isLoading) =>
            set((state) => ({
                user: { ...state.user, isLoading },
            })),
        getToken: () => get().user.token,
    }))
);

const useUser = () => {
    const state = useStore();
    const setUser = (user) => {
        state.setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
    };

    const setUserLanguage = (language) => {
        const newUser = { ...state.user, language };
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(newUser));
        state.setUser(newUser);
    };

    const invalidate = () => {
        localStorage.removeItem("user");
        state.invalidate();
    };

    useEffect(() => {
        const user = localStorage.getItem("user");

        if (user) {
            state.setUser(JSON.parse(user));
        } else {
            state.setIsLoading(false);
        }
    }, []);

    return {
        user: state.user,
        setUser,
        setUserLanguage,
        invalidate,
        getToken: state.getToken,
    };
};

export default useUser;
