import React from "react";
import { useLocation } from "react-router";
import { ButtonGroup, ListGroup } from "react-bootstrap";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
    normalizeInteger,
} from "shared/functions/normalize";
import ArtistLink from "shared/components/ArtistLink";
import UpdateModalButton from "./UpdateModalButton";
import DeleteButton from "./DeleteButton";
import ArtistList from "./ArtistList";

const EnhancedArtistList = ({
    editable,
    selectedArtist,
    setSelectedArtist,
    onUpdate,
    onDelete,
    ...props
}) => {
    const location = useLocation();
    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.artists.id"
            ),
            formatter: (cell) => (cell !== 0 ? cell : "-"),
            filterValue: normalizeInteger,
            headerStyle: { width: "5em" },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.artists.name"
            ),
            formatter: (cell, row) =>
                row.id === 0 ? (
                    <span className="font-italic">
                        <I18n t="cnm.repositories.cnm_artists_titles.artists.noArtist" />
                    </span>
                ) : (
                    cell
                ),
            filterValue: normalizeTitle,
        },
        {
            dataField: "msArtists",
            text: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_artists_titles.artists.msArtists"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <div className="d-flex align-items-center">
                    {cell.length > 0 ? (
                        <div className="flex-grow-1">
                            <ListGroup variant="flush">
                                {cell.map(({ id, name }) => (
                                    <ListGroup.Item
                                        key={id}
                                        className="bg-transparent p-1"
                                    >
                                        <ArtistLink
                                            artistId={id}
                                            content={[id, name].join(" - ")}
                                            blank
                                        />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    ) : (
                        <span className="flex-grow-1">-</span>
                    )}
                    {row.id !== 0 && data.editable && (
                        <ButtonGroup onClick={(e) => e.stopPropagation()}>
                            <UpdateModalButton
                                artist={row}
                                onUpdate={onUpdate}
                            />
                            <DeleteButton
                                id={row.id}
                                onDelete={onDelete}
                                disabled={row.hasTitles}
                            />
                        </ButtonGroup>
                    )}
                </div>
            ),
            formatExtraData: {
                editable,
            },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => setSelectedArtist(isSelect ? row : null),
        selected: selectedArtist ? [selectedArtist.id] : [],
    };

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 500,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <ArtistList
            columns={columns}
            selectRow={selectRow}
            pagination={pagination}
            onColumnMatch={onColumnMatchNormalized}
            {...props}
        />
    );
};

export default EnhancedArtistList;
