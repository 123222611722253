import { createSelector } from "reselect";
import moment from "moment";
import { MAIN_CHARTS } from "../RecordingCharts.constants";

export const getFilterOptions = createSelector(
    [(args) => args.type, (args) => args.recordings],
    (type, recordings) =>
        recordings.reduce(
            (acc, { main_charts, all_charts }) => {
                const charts = type === MAIN_CHARTS ? main_charts : all_charts;

                charts.forEach(({ chart, country }) => {
                    acc["charts"] = [
                        ...acc.charts,
                        {
                            label: chart.name,
                            value: chart.id,
                        },
                    ]
                        .filter(
                            (row, index, self) =>
                                self.findIndex((t) => t.value === row.value) ===
                                index
                        )
                        .sort((a, b) => a.label.localeCompare(b.label));

                    acc["country"] = [
                        ...acc.country,
                        {
                            label: country.name,
                            value: country.id,
                        },
                    ]
                        .filter(
                            (row, index, self) =>
                                self.findIndex((t) => t.value === row.value) ===
                                index
                        )
                        .sort((a, b) => a.label.localeCompare(b.label));
                });

                return acc;
            },
            {
                charts: [],
                country: [],
            }
        )
);

export const getFilteredRecordings = createSelector(
    [
        (args) => args.type,
        (args) => args.recordings,
        (args) => args.selectedFilterOptions,
    ],
    (type, recordings, selectedFilterOptions) => {
        let filteredRecordings = recordings;

        if (selectedFilterOptions.chart) {
            filteredRecordings = filteredRecordings.filter(
                ({ main_charts, all_charts }) => {
                    const charts =
                        type === MAIN_CHARTS ? main_charts : all_charts;

                    return charts.some(
                        (element) =>
                            element.chart.id ===
                            selectedFilterOptions.chart.value
                    );
                }
            );
        }

        if (selectedFilterOptions.country) {
            filteredRecordings = filteredRecordings.filter(
                ({ main_charts, all_charts }) => {
                    const charts =
                        type === MAIN_CHARTS ? main_charts : all_charts;

                    return charts.some(
                        (element) =>
                            element.country.id ===
                            selectedFilterOptions.country.value
                    );
                }
            );
        }

        if (selectedFilterOptions.year) {
            filteredRecordings = filteredRecordings.filter(
                ({ main_charts, all_charts }) => {
                    const charts =
                        type === MAIN_CHARTS ? main_charts : all_charts;

                    return charts.some(({ date }) => {
                        const year = moment(date).format("YYYY");
                        return (
                            (selectedFilterOptions.mode === "=" &&
                                selectedFilterOptions.year === year) ||
                            (selectedFilterOptions.mode === "<" &&
                                year < selectedFilterOptions.year) ||
                            (selectedFilterOptions.mode === ">" &&
                                year > selectedFilterOptions.year)
                        );
                    });
                }
            );
        }

        return filteredRecordings;
    }
);
