import React from "react";
import InputRange from "shared/components/Form/InputRange";
import "./Time.css";

const Time = ({
    getCurrentPlaybackTime,
    getCurrentPlaybackDuration,
    getScrubberValue,
    onScrub,
    onStartScrubbing,
    onEndScrubbing,
    duration,
}) => {
    return (
        <div className="apple__player__times">
            <div>
                <span>{getCurrentPlaybackTime()}</span>
                <InputRange
                    value={getScrubberValue()}
                    onChange={onScrub}
                    onMouseDown={onStartScrubbing}
                    onTouchStart={onStartScrubbing}
                    onMouseUp={onEndScrubbing}
                    onTouchEnd={onEndScrubbing}
                    min={0}
                    max={duration}
                    step={1}
                />
                <span>{getCurrentPlaybackDuration()}</span>
            </div>
        </div>
    );
};

export default Time;
