import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import CheckAction from "./CheckAction";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedCheckAction = ({
    listId,
    rowId,
    artistId,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();

    const onCheck = () => {
        api.post(
            `matching/artist/seatgeek/list/${listId}/${rowId}/check/${artistId}`
        )
            .then((response) => {
                onSuccess(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.check.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.artist.artist.check.error",
                        error.message
                    )
                );
            });
    };

    return <CheckAction onCheck={onCheck} artistId={artistId} {...props} />;
};

export default EnhancedCheckAction;
