import React, { useState } from "react";
import Volume from "./Volume";
import {
    faTimes,
    faVolumeOff,
    faVolumeDown,
    faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { useMK } from "shared/hooks/useMK";

const EnhancedVolume = (props) => {
    const [state, setState] = useState({
        volume: 1,
    });
    const mk = useMK();

    const getVolumeIconClasses = () => {
        if (mk.instance.volume === 0) {
            return faTimes;
        }

        if (mk.instance.volume < 0.3) {
            return faVolumeOff;
        }

        if (mk.instance.volume < 0.6) {
            return faVolumeDown;
        }

        return faVolumeUp;
    };

    const changeVolume = (volume) => {
        mk.instance.volume = volume;

        setState({
            ...state,
            volume,
        });
    };

    const onScrub = (e) => {
        changeVolume(parseFloat(e.target.value));
    };

    const onEndScrubbing = (e) => {
        const element = e.target;
        element.blur();
        changeVolume(parseFloat(e.target.value));
    };

    return (
        <Volume
            {...state}
            getVolumeIconClasses={getVolumeIconClasses}
            onScrub={onScrub}
            onEndScrubbing={onEndScrubbing}
            {...props}
        />
    );
};

export default EnhancedVolume;
