import React, { useState, useEffect, useCallback } from "react";
import Role from "./Role";
import I18n from "shared/lib/I18n";
import useUser from "shared/hooks/useUser";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import FlagIcon from "shared/functions/FlagIcon";
import { useLocation } from "react-router";

const EnhancedRole = (props) => {
    const [role, setRole] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useUser();
    const { api } = useAPI();
    const location = useLocation();

    const getRole = useCallback(() => {
        setIsLoading(true);
        api.get(`role`)
            .then((response) => {
                setRole(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getRole, [getRole]);

    const columns = [
        {
            dataField: "role_fr",
            isDummyField: true,
            text: I18n.getTranslation(location, "role.role_fr"),
            sort: true,
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "FR")?.name;
                return name ? (
                    <>
                        <FlagIcon code="fr" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "FR")?.name,
            sortValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "FR")?.name,
        },
        {
            dataField: "role_en",
            isDummyField: true,
            text: I18n.getTranslation(location, "role.role_en"),
            sort: true,
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "EN")?.name;
                return name ? (
                    <>
                        <FlagIcon code="gb" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "EN")?.name,
            sortValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "EN")?.name,
        },
        {
            dataField: "role_ja",
            isDummyField: true,
            text: I18n.getTranslation(location, "role.role_ja"),
            sort: true,
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "JA")?.name;
                return name ? (
                    <>
                        <FlagIcon code="jp" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "JA")?.name,
            sortValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "JA")?.name,
        },
        {
            dataField: "role_de",
            isDummyField: true,
            text: I18n.getTranslation(location, "role.role_de"),
            sort: true,
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "DE")?.name;
                return name ? (
                    <>
                        <FlagIcon code="de" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "DE")?.name,
            sortValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "DE")?.name,
        },
        {
            dataField: "role_ko",
            isDummyField: true,
            text: I18n.getTranslation(location, "role.role_ko"),
            sort: true,
            formatter: (cell, row) => {
                const name = row.langs.find(({ lang }) => lang === "KO")?.name;
                return name ? (
                    <>
                        <FlagIcon code="kr" className="mr-1" />
                        {name}
                    </>
                ) : (
                    "-"
                );
            },
            filterValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "KO")?.name,
            sortValue: (cell, row) =>
                row.langs.find(({ lang }) => lang === "KO")?.name,
        },
    ];

    const defaultSorted = [
        {
            dataField: "role_fr",
            order: "asc",
        },
    ];

    return (
        <Role
            role={role}
            columns={columns}
            isLoading={isLoading}
            user={user}
            defaultSorted={defaultSorted}
            location={location}
            {...props}
        />
    );
};

export default EnhancedRole;
