import React from "react";
import { Controller } from "react-hook-form";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import I18n from "shared/lib/I18n";

const CSAArtistSelect = ({
    control,
    name,
    location,
    disabled,
    loadOptions,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name, ref } }) => (
                <InputAsyncSelect
                    innerRef={ref}
                    name={name}
                    placeholder={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.csaArtist.placeholder"
                    )}
                    loadOptions={loadOptions}
                    value={
                        value
                            ? {
                                  value: value,
                                  label: value.name,
                              }
                            : null
                    }
                    onChange={(option, action) => {
                        if (action.action === "select-option") {
                            onChange(option.value);
                        } else if (action.action === "clear") {
                            onChange(null);
                        }
                    }}
                    isDisabled={disabled}
                    isClearable
                    enablePortal
                />
            )}
        />
    );
};

export default CSAArtistSelect;
