import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const Related = ({ related, isLoading, columns, defaultSorted }) => {
    return (
        <Widget
            title={`genre.related.head`}
            className="vertical-shrink mb-0 pb-0 related"
            isLoading={isLoading}
        >
            <Widget.Body
                className="widget-table-overflow overflow-auto"
                style={{
                    margin: "10px -20px 0px -20px",
                }}
            >
                <BootstrapTable
                    keyField="id"
                    data={related}
                    columns={columns}
                    bootstrap4
                    striped
                    hover
                    condensed
                    noDataIndication={<I18n t="genre.empty" />}
                    classes="table-bordered-header-fixed mb-0"
                    defaultSorted={defaultSorted}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Related;
