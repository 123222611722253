import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import ImageLoader from "shared/components/ImageLoader";
import placeholder from "assets/static/images/logo_placeholder.png";
import styles from "shared/components/MultiSearch/MultiSearch.module.css";

const Recordings = ({ q, handleReset, recordings }) => {
    return (
        <>
            <div className={styles.head}>
                <div className="d-flex justify-content-between">
                    <div className={styles.head__title}>
                        <I18n t="common.multiSearch.recording" />
                    </div>
                    <div>
                        <NavLink
                            to={`/search/recordings?q=${q}`}
                            onClick={handleReset}
                        >
                            <I18n t="common.multiSearch.more" />
                        </NavLink>
                    </div>
                </div>
            </div>
            <div>
                {recordings.length !== 0 ? (
                    recordings.map((recording) => {
                        return (
                            <NavLink
                                key={recording.id}
                                to={`/recording/${recording.recording_id}/view`}
                                onClick={handleReset}
                                className={styles.content__a}
                            >
                                <div className={styles.content__row}>
                                    <div>
                                        <ImageLoader
                                            src={recording.cover}
                                            fallbackSrc={placeholder}
                                            fallbackClassName={
                                                styles.cover__placeholder
                                            }
                                            className={styles.cover__recording}
                                        />
                                    </div>
                                    <div
                                        className={styles.content__information}
                                    >
                                        <div className={styles.content__title}>
                                            <span>
                                                {recording.recording_id}
                                            </span>
                                            <span className="mx-1">-</span>
                                            <span>{recording.title}</span>
                                            {recording.subtitle && (
                                                <span className="ml-1">
                                                    ({recording.subtitle})
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className={styles.content__subtitle}
                                        >
                                            {recording.artists && (
                                                <>
                                                    {recording.artists.map(
                                                        (artist, index) => (
                                                            <React.Fragment
                                                                key={artist.id}
                                                            >
                                                                {index !==
                                                                    0 && (
                                                                    <span className="mx-1">
                                                                        &bull;
                                                                    </span>
                                                                )}
                                                                {artist.name}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                </>
                                            )}
                                            {recording.duration && (
                                                <>
                                                    {recording.duration}
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                </>
                                            )}
                                            <span
                                                className={`badge badge-${
                                                    recording.has_audio
                                                        ? "success"
                                                        : "secondary"
                                                }`}
                                            >
                                                <I18n t="common.multiSearch.has_audio" />
                                            </span>
                                            <span
                                                className={`badge badge-${
                                                    recording.has_lyric
                                                        ? "success"
                                                        : "secondary"
                                                } ml-1`}
                                            >
                                                <I18n t="common.multiSearch.has_lyric" />
                                            </span>
                                            <span
                                                className={`badge badge-${
                                                    recording.has_credits
                                                        ? "success"
                                                        : "secondary"
                                                } ml-1`}
                                            >
                                                <I18n t="common.multiSearch.has_credits" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        );
                    })
                ) : (
                    <div className={styles.content__row__empty}>
                        <I18n t="common.multiSearch.empty" />
                    </div>
                )}
            </div>
        </>
    );
};

export default Recordings;
