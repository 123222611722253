import React from "react";
import I18n from "shared/lib/I18n";
import { displayAsString } from "shared/functions/Date";
import cdjournalIcon from "assets/static/images/cdjournal.png";
import "./Data.css";

const Data = ({ biography, id_lang, locale }) => {
    return (
        <div className="p-4">
            {id_lang === "CD_JOURNAL" && (
                <>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="mr-4 text-right">
                                    <p className="mb-0">
                                        <strong>
                                            <I18n t="artist.view.biographyGallery.licence.title" />
                                        </strong>
                                    </p>
                                    <p className="mb-0">
                                        <strong>
                                            <I18n t="artist.view.biographyGallery.licence.subtitle" />
                                        </strong>
                                    </p>
                                </div>
                                <a
                                    href="https://www.cdjournal.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={cdjournalIcon}
                                        width="200px"
                                        alt="cdjournal"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
            )}

            <div className="row">
                <div className="col">
                    <div>
                        <strong>
                            <I18n t="artist.view.biographyGallery.writer" />
                        </strong>
                        <span className="mx-1">:</span>
                        {biography.author}
                    </div>
                    <div>
                        <strong>
                            <I18n t="artist.view.biographyGallery.updated_at" />
                        </strong>
                        <span className="mx-1">:</span>
                        {displayAsString(biography.updated_at, locale)}
                    </div>
                </div>
                <div className="col text-right">
                    {!["JA", "CD_JOURNAL"].includes(id_lang) && (
                        <div>
                            <span className="mr-1">{biography.nb_words}</span>
                            <strong>
                                <I18n t="artist.view.biographyGallery.words" />
                            </strong>
                        </div>
                    )}
                    <div>
                        <span className="mr-1">{biography.nb_characters}</span>
                        <strong>
                            <I18n t="artist.view.biographyGallery.signs" />
                        </strong>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col">
                    <strong className="d-inline-block mb-3">
                        <I18n t="artist.view.biographyGallery.biography" />
                    </strong>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: biography.biography,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Data;
