import React, { useState, useEffect, useCallback } from "react";
import Related from "./Related";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { getRelated, getSuccessor, getInfluence } from "./Related.pure";

const EnhancedRelated = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        data: [],
        isLoading: true,
    });

    const getData = useCallback(() => {
        return api
            .get(`artist/${artistId}/related`)
            .then((response) => {
                setState((prev) => ({ ...prev, data: response }));
                if (response.length === 0) {
                    setNoData("related");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getData()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getData]);

    return (
        <Related
            {...props}
            {...state}
            related={getRelated(state)}
            successor={getSuccessor(state)}
            influence={getInfluence(state)}
        />
    );
};

export default EnhancedRelated;
