import React from "react";
import Error from "shared/components/Form/Error";

const InputFile = React.forwardRef(
    (
        {
            error,
            onChange,
            label,
            accept,
            dataBrowse,
            className,
            name,
            disabled,
        },
        ref
    ) => {
        return (
            <>
                <div className={`custom-file ${className}`}>
                    <input
                        type="file"
                        accept={accept}
                        className={`custom-file-input ${error && "is-invalid"}`}
                        onChange={onChange}
                        ref={ref}
                        name={name}
                        disabled={disabled}
                    />
                    <label
                        className="custom-file-label control-label"
                        data-browse={dataBrowse}
                    >
                        {label}
                    </label>
                </div>
                <Error error={error} className="mt-3" />
            </>
        );
    }
);

export default InputFile;
