export const VARIOUS_ARTIST_ID = 102171;
export const TYPE_BAND = "Groupe";
export const TYPE_PERSON = "Personne";
export const GENRE_TYPE_MAIN = "0";
export const GENRE_TYPE_PRINCIPAL = "1";
export const GENRE_TYPE_SECONDARY = "2";
export const GENRE_TYPE_INFLUENCE = "3";
export const RELATED_TYPE_RELATED = "Associé";
export const RELATED_TYPE_SUCCESSOR = "Successeur";
export const RELATED_TYPE_INFLUENCE = "Influence";
