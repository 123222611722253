import React from "react";
import ProductPopover from "./ProductPopover";
import MatchingsModal from "./MatchingsModal";
import TracklistComparisonModal from "./TracklistComparisonModal";
import CopyProductModal from "./CopyProductModal";
import Table from "./Table";
import "./Sandbox.css";

const Sandbox = ({
    popoverState,
    showPopover,
    hidePopover,
    matchingsState,
    showMatchings,
    hideMatchings,
    tracklistComparisonState,
    showTracklistComparison,
    hideTracklistComparison,
    copyProductState,
    showCopyProduct,
    hideCopyProduct,
    partnerFilter,
    setPartnerFilter,
    statusFilter,
    setStatusFilter,
    albums,
    isLoading,
    searchQuery,
    setSearchQuery,
    covers,
    setCovers,
}) => {
    return (
        <section className="widget">
            <Table
                showPopover={showPopover}
                hidePopover={hidePopover}
                showMatchings={showMatchings}
                showTracklistComparison={showTracklistComparison}
                showCopyProduct={showCopyProduct}
                partnerFilter={partnerFilter}
                setPartnerFilter={setPartnerFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                albums={albums}
                isLoading={isLoading}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
            <ProductPopover {...popoverState} />
            <MatchingsModal {...matchingsState} onClose={hideMatchings} />
            <TracklistComparisonModal
                {...tracklistComparisonState}
                onClose={hideTracklistComparison}
            />
            <CopyProductModal
                {...copyProductState}
                onClose={hideCopyProduct}
                covers={covers}
                setCovers={setCovers}
            />
        </section>
    );
};

export default Sandbox;
