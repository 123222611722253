import React from "react";
import { Navigate } from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import useUser from "shared/hooks/useUser";

const EnhancedArtistRedirect = (props) => {
    const { user } = useUser();
    const { artistId } = useIntParams();

    if (user.hasRight("artist.manage.information")) {
        return <Navigate to={`/artist/${artistId}/edit`} />;
    } else {
        return <Navigate to={`/artist/${artistId}/view`} />;
    }
};

export default EnhancedArtistRedirect;
