import { getYear } from "shared/functions/Date";

export const groupByRole = (data) => {
    let result = [];

    data.forEach((element) => {
        if (
            result.some(
                (e) =>
                    e.artist.id === element.artist.id &&
                    e.start_date === getYear(element.start_date) &&
                    e.end_date === getYear(element.end_date)
            )
        ) {
            result = result.map((r) => {
                if (r.artist.id === element.artist.id) {
                    return { ...r, roles: [...r.roles, element.id_role] };
                }
                return r;
            });
        } else {
            result = [
                ...result,
                {
                    id: element.id,
                    artist: element.artist,
                    start_date: getYear(element.start_date),
                    end_date: getYear(element.end_date),
                    roles: [element.id_role],
                },
            ];
        }
    });

    return result;
};

export const orderByStartDate = (data) => {
    return data.sort((a, b) => {
        if (a.start_date === b.start_date) {
            return 0;
        }
        if (!a.start_date) {
            return 1;
        }
        if (!b.start_date) {
            return -1;
        }
        return b.start_date.localeCompare(a.start_date);
    });
};
