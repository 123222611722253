import React from "react";

const MetabaseView = ({ src, width, height, frameBorder }) => {
    return (
        <iframe
            src={src}
            width={width}
            height={height}
            frameBorder={frameBorder}
        ></iframe>
    );
};

export default MetabaseView;
