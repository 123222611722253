import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import Highlights from "./Highlights";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    famous_for: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    awards: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
});

const EnhancedHighlights = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            famous_for: null,
            awards: null,
        },
    });

    const getHighlights = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/editorial/highlights`)
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getHighlights, [getHighlights]);

    const onSubmit = async (data) => {
        await api
            .put(`artist/${artistId}/editorial/highlights`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.highlights.update.success`
                    )
                );
                reset(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.highlights.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Highlights
            {...props}
            getHighlights={getHighlights}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
        />
    );
};

export default EnhancedHighlights;
