import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import ChartsTranslation from "./ChartsTranslation";

const EnhancedChartsTranslation = (props) => {
    const navigate = useNavigate();
    const { locale, language } = useParams();
    const { categoryId, chartId } = useIntParams();
    const location = useLocation();

    const onSelectCategory = (newCategoryId) => {
        navigate(`/${locale}/charts/translation/${language}/${newCategoryId}`);
    };
    const onSelectChart = (newChartId) => {
        navigate(
            `/${locale}/charts/translation/${language}/${categoryId}/${newChartId}`
        );
    };

    return (
        <ChartsTranslation
            language={language}
            categoryId={categoryId}
            chartId={chartId}
            onSelectCategory={onSelectCategory}
            onSelectChart={onSelectChart}
            location={location}
            {...props}
        />
    );
};

export default EnhancedChartsTranslation;
