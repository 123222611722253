import React, { useState, useEffect, useCallback } from "react";
import LabelPromo from "./LabelPromo";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import Delete from "./Button/Delete";
import Show from "./Button/Show";

const EnhancedLabelPromo = (props) => {
    const [labelPromo, setLabelPromo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { api } = useAPI();

    const getLabelPromo = useCallback(() => {
        api.get("label-promo")
            .then((response) => {
                setLabelPromo(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getLabelPromo, [getLabelPromo]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "labelPromo.manage.label.id"),
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "labelPromo.manage.label.name"),
            sort: true,
        },
        {
            dataField: "created_at",
            text: I18n.getTranslation(
                location,
                "labelPromo.manage.label.created_at"
            ),
            sort: true,
        },
        {
            dataField: "updated_at",
            text: I18n.getTranslation(
                location,
                "labelPromo.manage.label.updated_at"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "status",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "labelPromo.manage.label.status"
            ),
            align: "center",
            searchable: false,
            formatter: (cell, row) => {
                return row.enabled ? (
                    <FontAwesomeIcon
                        icon={faCheck}
                        size="lg"
                        className="text-success"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        className="text-danger"
                    />
                );
            },
            sortValue: (cell, row) => row.enabled,
            sort: true,
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            align: "center",
            text: I18n.getTranslation(
                location,
                "labelPromo.manage.label.action"
            ),
            formatter: (cell, row) => {
                return (
                    <>
                        <ButtonGroup className="mr-2">
                            <Show users={row.users} />
                        </ButtonGroup>
                        <ButtonGroup>
                            <Update label={row} onSuccess={getLabelPromo} />
                            <Delete id={row.id} onSuccess={getLabelPromo} />
                        </ButtonGroup>
                    </>
                );
            },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <LabelPromo
            {...props}
            labelPromo={labelPromo}
            columns={columns}
            isLoading={isLoading}
            options={options}
            getLabelPromo={getLabelPromo}
        />
    );
};

export default EnhancedLabelPromo;
