import React from "react";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Picture from "./Picture";

const Gallery = ({ total, pictures, loadMore, moreLoading, onAdd }) => {
    return pictures.length === 0 ? (
        <h3 className="text-center mt-4">
            <I18n t="common.picture.getty.bas.empty" />
        </h3>
    ) : (
        <div className="grid mt-4">
            {pictures.map((picture) => (
                <Picture key={picture.id} picture={picture} onAdd={onAdd} />
            ))}
            {total > pictures.length && (
                <div
                    className={clsx(
                        "artist-management-bas-card",
                        "card",
                        "border"
                    )}
                >
                    <div className="image-container">
                        <button
                            className="btn btn-info card-img-top"
                            onClick={!moreLoading ? loadMore : null}
                            style={{ height: "10vw" }}
                        >
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <span className="text-white">
                                    {moreLoading ? (
                                        <Loader size="4x" />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faCamera}
                                            size="4x"
                                            fixedWidth
                                        />
                                    )}
                                </span>
                            </div>
                        </button>
                    </div>
                    <div className="card-body p-2">
                        <p className="card-text d-flex justify-content-around align-items-center">
                            <span>
                                {moreLoading ? (
                                    <I18n t="common.picture.getty.bas.loading" />
                                ) : (
                                    <I18n t="common.picture.getty.bas.more" />
                                )}
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
