import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import filterFactory from "@musicstory/react-bootstrap-table2-filter";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "@musicstory/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "./Gallery.css";

const Gallery = ({
    label,
    gallery,
    columns,
    defaultSorted,
    paginationOptions,
    expandRow,
    isLoading,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();

    return (
        <section className="widget pb-0 picture-label-gallery">
            <ToolkitProvider
                keyField="id"
                data={gallery}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <>
                        <header>
                            <h2>
                                <I18n
                                    t="pictures.label.gallery.head"
                                    args={label}
                                />
                            </h2>
                        </header>
                        <div className="widget-body">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "pictures.label.gallery.search"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="widget-body widget-table-overflow">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    striped
                                    defaultSorted={defaultSorted}
                                    noDataIndication={
                                        <I18n t="pictures.label.gallery.empty" />
                                    }
                                    filter={filterFactory()}
                                    pagination={paginationFactory(
                                        paginationOptions
                                    )}
                                    expandRow={expandRow}
                                />
                            )}
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </section>
    );
};

export default Gallery;
