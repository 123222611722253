import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import PictureTagsModal from "shared/components/PictureTagsModal";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";

const TagsButton = ({
    pictureId,
    versionId,
    showTags,
    toggleTags,
    location,
}) => {
    return (
        <>
            <Tippy
                content={I18n.getTranslation(
                    location,
                    "common.picture.action.tags"
                )}
                trigger="mouseenter"
            >
                <button
                    className={clsx("btn bg-transparent border-0")}
                    onClick={toggleTags}
                >
                    <FontAwesomeIcon icon={faPencil} fixedWidth />
                </button>
            </Tippy>

            <PictureTagsModal
                id_picture={pictureId}
                id_picture_version={versionId}
                show={showTags}
                handleHide={toggleTags}
                onSuccess={toggleTags}
                isEdit
            />
        </>
    );
};

export default TagsButton;
