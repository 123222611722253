import React from "react";
import CropButton from "./CropButton";
import useToggle from "shared/hooks/useToggle";
import { useLocation } from "react-router-dom";

const EnhancedCropButton = ({ onSuccess, ...props }) => {
    const location = useLocation();
    const [showCrop, toggleCrop] = useToggle(false);
    const onCropSuccess = () => {
        toggleCrop();
        onSuccess?.();
    };

    return (
        <CropButton
            {...props}
            showCrop={showCrop}
            toggleCrop={toggleCrop}
            onCropSuccess={onCropSuccess}
            location={location}
        />
    );
};

export default EnhancedCropButton;
