import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import ChartsDisplay from "./ChartsDisplay";

const EnhancedChartsDisplay = (props) => {
    const navigate = useNavigate();
    const { locale } = useParams();
    const { categoryId, chartId } = useIntParams();
    const location = useLocation();

    const onSelectCategory = (newCategoryId) => {
        navigate(`/${locale}/charts/display/${newCategoryId}`);
    };
    const onSelectChart = (newChartId) => {
        navigate(`/${locale}/charts/display/${categoryId}/${newChartId}`);
    };

    return (
        <ChartsDisplay
            categoryId={categoryId}
            chartId={chartId}
            onSelectCategory={onSelectCategory}
            onSelectChart={onSelectChart}
            location={location}
            {...props}
        />
    );
};

export default EnhancedChartsDisplay;
