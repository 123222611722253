import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const Action = ({ setStatus, setRejected, location }) => {
    return (
        <div className={`btn-group btn-group-sm`}>
            <button
                className={`btn btn-success`}
                onClick={setStatus}
                title={I18n.getTranslation(
                    location,
                    "pictures.label.validation.validate.title"
                )}
                style={{ width: "30px" }}
            >
                <FontAwesomeIcon icon={faCheck} size="lg" />
            </button>
            <button
                className={`btn btn-danger`}
                onClick={setRejected}
                title={I18n.getTranslation(
                    location,
                    "pictures.label.validation.reject.title"
                )}
                style={{ width: "30px" }}
            >
                <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
        </div>
    );
};

export default Action;
