import { useParams, useLocation } from "react-router";
import useUser from "shared/hooks/useUser";
import { Navigate } from "react-router-dom";
import { stripLocale } from "shared/functions/Locale";

const Auth = ({ children }) => {
    const { user } = useUser();
    const { locale } = useParams();
    const { pathname, search, hash } = useLocation();

    if (user.isLoading) {
        return <></>;
    }

    if (!user.authenticated) {
        return <Navigate to={`/${locale}/login#${pathname}${search}${hash}`} />;
    }

    if (user.language && user.language !== locale) {
        return (
            <Navigate
                to={`/${user.language}${stripLocale(
                    pathname,
                    locale
                )}${search}`}
            />
        );
    }

    return children;
};

export default Auth;
