import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const TopList = ({ isLoading, tops, partner, date, selectedTop }) => {
    if (partner === undefined || partner == "qb" || date === undefined) {
        return null;
    }

    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="partner_tops.tops.empty" />
            </td>
        </tr>
    );
    if (tops.length !== 0) {
        rows = tops.map(({ id, label }) => (
            <tr
                key={id}
                className={selectedTop === id ? "table-info" : undefined}
            >
                <td>
                    <NavLink
                        to={`/matching/partner_tops/${partner}/${date}/${id}`}
                    >
                        {label}
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <section className="widget">
            <header>
                <h6>
                    <I18n t="partner_tops.tops.title" />
                </h6>
            </header>
            <div
                className="widget-body widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped"
                        style={{ marginBottom: "0px" }}
                    >
                        <tbody>{rows}</tbody>
                    </table>
                )}
            </div>
        </section>
    );
};

export default TopList;
