import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const Artist = ({ id, name, picture, genres }) => {
    const location = useLocation();

    return (
        <li
            style={{
                cursor: "auto",
                height: "120px",
                borderTop: "1px solid #fff",
            }}
        >
            <img
                style={{
                    height: "120px",
                    width: "auto",
                    marginRight: "10px",
                }}
                src={picture}
                alt={I18n.getTranslation(
                    location,
                    "artistTitle.resultsList.artist.picture"
                )}
                height="120"
            />
            <div
                style={{
                    marginLeft: "10px",
                }}
            >
                <h3>{name}</h3>
                <p>
                    <I18n t="artistTitle.resultsList.artist.id" args={id} />
                </p>
                <p>{genres?.map(({ name }) => name).join(" - ")}</p>
            </div>
        </li>
    );
};

export default Artist;
