import React from "react";
import List from "./List";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import ReleaseLink from "shared/components/ReleaseLink";
import {
    SIMPLE,
    CREATION,
    NEW_LYRICS,
    DISCARDED_FULL,
    FORGOT_2_3,
} from "pages/DataExternalDeezerTopsSongs/Coverage/Coverage.constants";

const EnhancedList = ({ id, title, exportName, ...props }) => {
    const location = useLocation();
    const { api } = useAPI();

    const columns = [
        {
            dataField: "date",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.date"
            ),
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.country"
            ),
        },
        {
            dataField: "id_deezer",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.id_deezer"
            ),
        },
        {
            dataField: "song",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.song"
            ),
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.artist"
            ),
        },
        {
            dataField: "album",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.coverage.album"
            ),
        },
    ];

    switch (id) {
        case SIMPLE:
        case CREATION:
        case NEW_LYRICS:
        case FORGOT_2_3:
            columns.push({
                dataField: "ms_artist",
                text: I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.coverage.ms_artist"
                ),
                formatter: (cell) => {
                    return cell ? (
                        <ArtistLink
                            artistId={cell.id}
                            content={cell.name}
                            blank
                        />
                    ) : (
                        "-"
                    );
                },
                filterValue: (cell) => cell?.name,
            });
            columns.push({
                dataField: "ms_album",
                text: I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.coverage.ms_album"
                ),
                formatter: (cell, row) => {
                    return cell ? (
                        <ReleaseLink
                            id_artist={cell.id_artist}
                            id_album={cell.id}
                            id_release={cell.id_edition}
                            title={cell.title}
                        />
                    ) : (
                        "-"
                    );
                },
                filterValue: (cell) => cell?.title,
            });
            if (id !== FORGOT_2_3) {
                columns.push({
                    dataField: "lf_composition",
                    text: I18n.getTranslation(
                        location,
                        "data_external.deezer_top_songs.coverage.lf_composition"
                    ),
                    formatter: (cell, row) => {
                        return cell
                            ? `${cell.lfid} - ${cell.title} - ${cell.artist}`
                            : row.lf_request_date || "-";
                    },
                    searchable: false,
                });
            }
            break;
        case CREATION:
            columns.push({
                dataField: "user_creation",
                text: I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.coverage.user"
                ),
            });
            break;
        case DISCARDED_FULL:
            columns.push({
                dataField: "comment",
                text: I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.coverage.comment"
                ),
                formatter: (cell) => {
                    return <span className="pre-wrap">{cell}</span>;
                },
                searchable: false,
            });
            columns.push({
                dataField: "user_discarded",
                text: I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.coverage.user"
                ),
            });
            break;
    }

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <List
            {...props}
            title={title}
            exportName={`${exportName}_${I18n.getTranslation(location, title)}`}
            columns={columns}
            options={options}
        />
    );
};

export default EnhancedList;
