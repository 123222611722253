import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import {
    SANDBOX_ARTIST_ALBUMS_SET,
    SANDBOX_SEARCH_RESULTS_SET,
    DiscographyContext,
} from "pages/ArtistManagement/Discography";
import {
    ArtistManagementContext,
    BAS_UP_TO_DATE,
} from "pages/ArtistManagement";

export const usePartnerAlbums = (artistId, searchQuery) => {
    const { api } = useAPI();
    const {
        dispatchDiscography,
        sandbox: { artistAlbums, searchResults },
    } = useContext(DiscographyContext);
    const { dispatchArtist, basUpToDate } = useContext(ArtistManagementContext);
    const [artistAlbumsLoading, setArtistAlbumsLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const getAlbums = useCallback(() => {
        setArtistAlbumsLoading(true);
        api.get(`disco/sandbox/${artistId}`)
            .then((response) => {
                dispatchDiscography({
                    type: SANDBOX_ARTIST_ALBUMS_SET,
                    data: response,
                });
                dispatchArtist({
                    type: BAS_UP_TO_DATE,
                    data: true,
                });
                setArtistAlbumsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                dispatchDiscography({
                    type: SANDBOX_ARTIST_ALBUMS_SET,
                    data: [],
                });
                setArtistAlbumsLoading(false);
            });
    }, [artistId]);

    useEffect(getAlbums, [getAlbums]);

    useEffect(() => {
        if (!basUpToDate) {
            getAlbums();
        }
    }, [basUpToDate]);

    const search = useCallback(() => {
        if (searchQuery !== null) {
            setSearchLoading(true);
            api.get(`disco/sandbox/${artistId}/search`, { query: searchQuery })
                .then((response) => {
                    dispatchDiscography({
                        type: SANDBOX_SEARCH_RESULTS_SET,
                        data: response,
                    });
                    setSearchLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    dispatchDiscography({
                        type: SANDBOX_SEARCH_RESULTS_SET,
                        data: [],
                    });
                    setSearchLoading(false);
                });
        }
    }, [artistId, searchQuery]);

    useEffect(search, [search]);

    return {
        isLoading: searchQuery === null ? artistAlbumsLoading : searchLoading,
        albums: searchQuery === null ? artistAlbums : searchResults,
    };
};
