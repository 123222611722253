import React, { useState, useEffect, useCallback } from "react";
import Albums from "./Albums";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import ImageLoader from "shared/components/ImageLoader";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import ArtistLink from "shared/components/ArtistLink";
import AlbumLink from "shared/components/AlbumLink";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { displayAsString } from "shared/functions/Date";

const defaultState = {
    albums: [],
    isLoading: true,
};

const defaultModalState = {
    show: false,
    links: [],
};

const EnhancedAlbums = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const { recordingId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const [modalState, setModalState] = useState(defaultModalState);

    const getAlbums = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`recording/${recordingId}/albums`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    albums: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [recordingId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getAlbums()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getAlbums]);

    const columns = [
        {
            dataField: "cover",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.cover"
            ),
            formatter: (cell) =>
                cell ? (
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Popover id={`popover-positioned-top`}>
                                <Popover.Content className="bg-black rounded-sm">
                                    <ImageLoader
                                        src={cell}
                                        style={{
                                            width: "400px",
                                            height: "400px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span>
                            <ImageLoader src={cell} className="img-40" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    "-"
                ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.title"
            ),
            formatter: (cell, { id, artists }) => (
                <>
                    <AlbumLink albumId={id} content={cell} />
                    {artists.map((artist) => (
                        <React.Fragment key={artist.id}>
                            <span className="mx-1">-</span>
                            <ArtistLink
                                artistId={artist.id}
                                content={artist.name}
                                view
                            />
                        </React.Fragment>
                    ))}
                </>
            ),
        },
        {
            dataField: "type",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.type.head"
            ),
            formatter: (cell) => (
                <I18n
                    t={`recording.view.profileAlbum.type.${cell?.toLowerCase()}`}
                />
            ),
            sort: true,
        },
        {
            dataField: "format",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.format.head"
            ),
            formatter: (cell) => (
                <I18n
                    t={`recording.view.profileAlbum.format.${cell?.toLowerCase()}`}
                />
            ),
            sort: true,
        },
        {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.release_date"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                if (cell) {
                    return displayAsString(cell, locale);
                } else {
                    return "-";
                }
            },
            formatExtraData: {
                locale,
            },
            sort: true,
        },
        {
            dataField: "links",
            text: I18n.getTranslation(
                location,
                "recording.view.profileAlbum.links"
            ),
            formatter: (cell) => (
                <Button
                    variant={cell.length === 0 ? "secondary" : "primary"}
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalState({ show: true, links: cell });
                    }}
                    disabled={cell.length === 0}
                >
                    <FontAwesomeIcon icon={faLink} fixedWidth />
                </Button>
            ),
        },
    ];

    const rowStyle = { lineHeight: "40px" };

    return (
        <Albums
            {...props}
            {...state}
            columns={columns}
            rowStyle={rowStyle}
            modalState={modalState}
            setModalState={setModalState}
        />
    );
};

export default EnhancedAlbums;
