import React, { useState } from "react";
import History from "./History";
import { getFilteredRows } from "./History.selector";

const EnhancedHistory = ({ rows, ...props }) => {
    const [filteredRows, setFilteredRows] = useState(rows);

    const handleSearch = (e) => {
        setFilteredRows(getFilteredRows({ value: e.target.value, rows }));
    };

    return (
        <History
            filteredRows={filteredRows}
            handleSearch={handleSearch}
            {...props}
        />
    );
};

export default EnhancedHistory;
