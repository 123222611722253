import React from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import RequestAction from "./RequestAction";

const EnhancedRequestAction = ({ listId, rowId, onChange, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();

    const onClick = () => {
        api.post(`matching/track/deezer/list/${listId}/${rowId}/request`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.request.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.request.error",
                        error.message
                    )
                );
            });
    };

    return <RequestAction {...props} onClick={onClick} location={location} />;
};

export default EnhancedRequestAction;
