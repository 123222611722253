import React from "react";
import RecordingsLink from "./RecordingsLink";

const EnhancedRecordingsLink = ({ links, ...props }) => {
    return (
        <RecordingsLink
            {...props}
            deezer={links.filter((r) => r.partner === "DZ")}
            qobuz={links.filter((r) => r.partner === "QB")}
            napster={links.filter((r) => r.partner === "NA")}
            itunes={links.filter((r) => r.partner === "IT")}
            spotify={links.filter((r) => r.partner === "SP")}
            musicbrainz={links.filter((r) => r.partner === "MB")}
            linemusic={links.filter((r) => r.partner === "LM")}
        />
    );
};

export default EnhancedRecordingsLink;
