import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "shared/components/Form/InputSelect";
import InputFile from "shared/components/Form/InputFile";
import I18n from "shared/lib/I18n";

const Upload = ({
    yearOptions,
    typeOptions,
    errors,
    show,
    handleClose,
    handleShow,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    differences,
    showConfirmation,
}) => {
    return (
        <>
            <Button variant="success" onClick={handleShow}>
                <I18n t="utils.button.upload" />
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.charts.platform.upload.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.charts.platform.upload.file" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="file"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputFile
                                        name={name}
                                        ref={ref}
                                        dataBrowse={I18n.getTranslation(
                                            location,
                                            "cnm.charts.platform.upload.browse"
                                        )}
                                        onChange={(e) =>
                                            onChange(e.target.files[0])
                                        }
                                        label={
                                            value !== null
                                                ? value.name
                                                : I18n.getTranslation(
                                                      location,
                                                      "cnm.charts.platform.upload.placeholder"
                                                  )
                                        }
                                        error={
                                            errors.file && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.file.message
                                                ),
                                            ]
                                        }
                                        accept="application/json,.json,.jsonl"
                                        disabled={showConfirmation}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.charts.platform.upload.year" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="year"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        name={name}
                                        value={
                                            value !== null
                                                ? {
                                                      value: value,
                                                      label: value,
                                                  }
                                                : null
                                        }
                                        options={yearOptions.map((year) => ({
                                            value: year,
                                            label: year,
                                        }))}
                                        onChange={(opt) => {
                                            onChange(opt.value);
                                        }}
                                        error={
                                            errors.year && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.year.message
                                                ),
                                            ]
                                        }
                                        enablePortal
                                        isClearable={false}
                                        isDisabled={showConfirmation}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.charts.platform.upload.type.label" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="type"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        name={name}
                                        value={
                                            value !== null
                                                ? {
                                                      value: value,
                                                      label: I18n.getTranslation(
                                                          location,
                                                          `cnm.charts.platform.upload.type.${value}`
                                                      ),
                                                  }
                                                : null
                                        }
                                        options={typeOptions.map((value) => ({
                                            value: value,
                                            label: I18n.getTranslation(
                                                location,
                                                `cnm.charts.platform.upload.type.${value}`
                                            ),
                                        }))}
                                        onChange={(opt) => {
                                            onChange(opt.value);
                                        }}
                                        error={
                                            errors.year && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.year.message
                                                ),
                                            ]
                                        }
                                        enablePortal
                                        isClearable={false}
                                        isDisabled={showConfirmation}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                    {showConfirmation && (
                        <div className="alert alert-warning mb-0">
                            <p>
                                <I18n t="cnm.charts.platform.upload.confirm.head" />
                            </p>
                            <ul className="mb-0">
                                {differences.map(
                                    ({ track, modifiedFields }) => (
                                        <li key={track.track_id}>
                                            <I18n
                                                t="cnm.charts.platform.upload.confirm.difference"
                                                args={{ track, modifiedFields }}
                                            />
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <I18n t="utils.button.close" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        type="submit"
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        {isSubmitting ? (
                            <>
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSpinner}
                                    pulse
                                />
                                <I18n t="cnm.charts.platform.upload.submitting" />
                            </>
                        ) : (
                            <I18n
                                t={
                                    showConfirmation
                                        ? "utils.button.confirm"
                                        : "cnm.charts.platform.upload.submit"
                                }
                            />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Upload;
