import React, { useState, useEffect, useCallback } from "react";
import PersonalDetails from "./PersonalDetails";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const schema = yup.object().shape({
    type: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    firstname: yup
        .string()
        .transform((value) => value || null)
        .trim()
        .required("utils.form.required"),
    lastname: yup
        .string()
        .transform((value) => value || null)
        .trim()
        .required("utils.form.required"),
    company: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    email: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    label: yup
        .string()
        .nullable()
        .transform((value) => (value !== "" ? value : null))
        .trim(),
    artist: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    role: yup
        .string()
        .nullable()
        .transform((value) => (value !== "" ? value : null))
        .trim(),
    describe: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    language: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    expires_at: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    token: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
});

const defaultValues = {
    type: null,
    firstname: null,
    lastname: null,
    company: null,
    email: null,
    label: null,
    artist: null,
    role: null,
    describe: null,
    language: null,
    expires_at: null,
    token: null,
};

const EnhancedPersonalDetails = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();
    const { setUserLanguage } = useUser();

    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        getValues,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const accountTypeWatch = watch("type");

    const accountLanguageOptions = [
        {
            label: I18n.getTranslation(location, "utils.lang.FR"),
            value: "fr",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.EN"),
            value: "en",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.DE"),
            value: "de",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.JA"),
            value: "ja",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.KO"),
            value: "ko",
        },
    ];

    const accountTypeOptions = [
        {
            label: I18n.getTranslation(
                location,
                "profile.personal_details.account_type.music_service"
            ),
            value: "music_service",
        },
        {
            label: I18n.getTranslation(
                location,
                "profile.personal_details.account_type.cmo"
            ),
            value: "cmo",
        },
        {
            label: I18n.getTranslation(
                location,
                "profile.personal_details.account_type.label_distributor"
            ),
            value: "label_distributor",
        },
        {
            label: I18n.getTranslation(
                location,
                "profile.personal_details.account_type.artist_manager"
            ),
            value: "artist_manager",
        },
    ];

    const accountRoleOptions = {
        music_service: [
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.music_editor"
                ),
                value: "music_editor",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.marketing"
                ),
                value: "marketing",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.product_management"
                ),
                value: "product_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.other"
                ),
                value: "other",
            },
        ],
        label_distributor: [
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.promotion"
                ),
                value: "promotion",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.business_development"
                ),
                value: "business_development",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.a_and_r"
                ),
                value: "a_and_r",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.supply_chain"
                ),
                value: "supply_chain",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.analytics"
                ),
                value: "analytics",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.other"
                ),
                value: "other",
            },
        ],
        cmo: [
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "profile.personal_details.role.catalog_management"
                ),
                value: "catalog_management",
            },
        ],
    };

    const [isToken, setIsToken] = useState(true);
    const getUser = useCallback(() => {
        setIsLoading(true);
        api.get(`user`)
            .then((response) => {
                reset(response);
                if (response.token) {
                    setIsToken(true);
                } else {
                    setIsToken(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getUser, [getUser]);

    const onSubmit = (data) => {
        return api
            .put(`user`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "profile.personal_details.success"
                    )
                );
                if (data.language) {
                    setUserLanguage(data.language);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const loadLabelOptions = async (term, callback) => {
        const response = await api.searchLabel(term);
        const result = await response.json();

        if (result.length > 0) {
            callback(
                result.map(({ name }) => {
                    return {
                        value: name,
                        label: name,
                    };
                })
            );
        }

        return [];
    };

    return (
        <PersonalDetails
            {...props}
            location={location}
            locale={locale}
            errors={errors}
            register={register}
            control={control}
            getValues={getValues}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            accountTypeOptions={accountTypeOptions}
            accountRoleOptions={accountRoleOptions}
            accountLanguageOptions={accountLanguageOptions}
            accountTypeWatch={accountTypeWatch}
            loadLabelOptions={loadLabelOptions}
            isToken={isToken}
        />
    );
};

export default EnhancedPersonalDetails;
