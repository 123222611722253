import React from "react";
import I18n from "shared/lib/I18n";
import MetabaseView from "shared/components/MetabaseView";
import InputSelect from "shared/components/Form/InputSelect";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import "./Stats.css";

const Stats = ({
    planning,
    manager,
    setManager,
    managerOptions,
    partnerId,
}) => {
    return (
        <>
            <div className="planning-stats-filter">
                <div className="row">
                    <div className="col-3">
                        <InputSelect
                            placeholder={
                                <I18n t="plannings.view.stats.manager" />
                            }
                            options={managerOptions}
                            onChange={(opt, action) => {
                                if (
                                    action.action === "select-option" ||
                                    action.action === "create-option"
                                ) {
                                    setManager([
                                        ...manager,
                                        action.option.label,
                                    ]);
                                } else if (action.action === "remove-value") {
                                    setManager(
                                        manager.filter(
                                            (label) =>
                                                label !=
                                                action.removedValue.label
                                        )
                                    );
                                } else if (action.action === "clear") {
                                    setManager([]);
                                }
                            }}
                            isMulti
                        />
                    </div>
                </div>
            </div>
            <MetabaseView
                id="50"
                frameBorder="0"
                width="100%"
                height="1850"
                params={{
                    date: `${planning.start_date}~${planning.end_date}`,
                    manager,
                    planning_partner:
                        partnerId === PLANNING_PARTNER.ALL ? [] : [partnerId],
                }}
            />
        </>
    );
};

export default Stats;
