import React, { useState, useCallback, useEffect } from "react";
import MetabaseView from "./MetabaseView";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";

const areEqual = (prevProps, nextProps) => {
    if (
        typeof prevProps.params === "object" &&
        typeof nextProps.params === "object"
    ) {
        return (
            Object.entries(prevProps.params).toString() ===
            Object.entries(nextProps.params).toString()
        );
    }
    return false;
};

const EnhancedMetabaseView = React.memo(({ id, params, ...props }) => {
    const [url, setUrl] = useState();
    const { api } = useAPI();

    const getUrl = useCallback(() => {
        let cancelled = false;

        api.get(
            `metabase/dashboard/${id}`,
            params ? { params: JSON.stringify(params) } : {}
        )
            .then((response) => {
                if (!cancelled) {
                    setUrl(response);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [params]);

    useEffect(getUrl, [getUrl]);

    return <MetabaseView src={url} {...props} />;
}, areEqual);

export default EnhancedMetabaseView;
