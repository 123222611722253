import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import { useLocation } from "react-router";
import "./InternationalsCharts.css";

const InternationalsCharts = ({
    charts,
    columns,
    isLoading,
    rowClasses,
    expandRow,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <section className="widget pb-0 internationals-charts">
            <ToolkitProvider
                keyField="id"
                data={charts}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <>
                        <div className="widget-body">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "data_external.internationals_charts.charts.search"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="widget-body widget-table-overflow">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    noDataIndication={
                                        <I18n t="data_external.internationals_charts.charts.empty" />
                                    }
                                    rowClasses={rowClasses}
                                    expandRow={expandRow}
                                />
                            )}
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </section>
    );
};

export default InternationalsCharts;
