import React from "react";
import useUser from "shared/hooks/useUser";
import AlbumListAction from "./AlbumListAction";

const EnhancedAlbumListAction = (props) => {
    const { user } = useUser();

    return <AlbumListAction {...props} user={user} />;
};

export default EnhancedAlbumListAction;
