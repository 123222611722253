import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import InputSwitch from "shared/components/Form/InputSwitch";
import Loader from "shared/components/Loader";

const PF = ({
    data,
    columns,
    isLoading,
    selectRow,
    rowClasses,
    showDisabled,
    setShowDisabled,
}) => {
    return (
        <Widget
            title={`cnm.charts.deduplicated.pf.head`}
            actions={
                <InputSwitch
                    label={
                        <I18n t="cnm.charts.deduplicated.pf.show_disabled" />
                    }
                    checked={showDisabled}
                    onChange={(e) => setShowDisabled(e.target.checked)}
                />
            }
        >
            <Widget.Body className="widget-table-overflow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <BootstrapTable
                        keyField="id"
                        data={data}
                        columns={columns}
                        bootstrap4
                        hover
                        noDataIndication={
                            <I18n t="cnm.charts.deduplicated.pf.empty" />
                        }
                        selectRow={selectRow}
                        rowClasses={rowClasses}
                    />
                )}
            </Widget.Body>
        </Widget>
    );
};

export default PF;
