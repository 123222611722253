import { createSelector } from "reselect";

export const getFilteredRows = createSelector(
    [(args) => args.rows, (args) => args.filter, (args) => args.cnmTitleId],
    (rows, filter, cnmTitleId) => {
        let filteredRows = rows;

        if (filter.missingPlatforms.length > 0) {
            filteredRows = filteredRows.filter(
                (r) =>
                    r.id === cnmTitleId ||
                    filter.missingPlatforms.every(
                        ({ value }) =>
                            !r.platforms.find(({ id }) => id === value)?.matched
                    )
            );
        }

        if (filter.numberOfMatchedPlatforms.length > 0) {
            filteredRows = filteredRows.filter(
                (r) =>
                    r.id === cnmTitleId ||
                    filter.numberOfMatchedPlatforms.some(
                        ({ value }) =>
                            value ===
                            r.platforms.filter(({ matched }) => matched).length
                    )
            );
        }

        if (filter.status !== null) {
            filteredRows = filteredRows.filter(
                (r) =>
                    r.id === cnmTitleId ||
                    (filter.status === "NONE" && !r.checked && !r.discarded) ||
                    (filter.status === "CHECKED" && r.checked) ||
                    (filter.status === "DISCARDED" && r.discarded)
            );
        }

        return filteredRows;
    }
);
