import React, { useEffect } from "react";
import { Popover, Table } from "react-bootstrap";
import clsx from "clsx";
import styles from "./ChartPopover.module.css";
import I18n from "shared/lib/I18n";

const ChartPopover = React.forwardRef(
    (
        { genre_repartition, popper, className, locale, location, ...props },
        ref
    ) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [genre_repartition, popper]);

        return (
            <Popover
                ref={ref}
                className={clsx(className, styles.popover)}
                {...props}
            >
                <Popover.Title as="h3" className="text-capitalize">
                    <div className={clsx(styles.container)}>
                        <span>
                            {genre_repartition.genre ??
                                I18n.getTranslation(
                                    location,
                                    "landing.industryStats.no_data"
                                )}
                        </span>
                        <span className={clsx(styles.pourcentage)}>
                            {genre_repartition.percentage}%
                        </span>
                    </div>
                </Popover.Title>
                <Popover.Content className={clsx(styles.content)}>
                    <span className={clsx(styles.text)}>
                        {genre_repartition.id === 0 ||
                        genre_repartition.id === null
                            ? ""
                            : I18n.getTranslation(
                                  location,
                                  "landing.industryStats.top_3"
                              )}
                    </span>
                    {genre_repartition.genres?.length > 0 ? (
                        <Table className={clsx(styles.table)}>
                            <tbody>
                                {genre_repartition.genres.map(
                                    (genre, index) => (
                                        <tr key={index}>
                                            <td>{genre.genre}</td>
                                            <th scope="row">
                                                {genre.pourcentage}%
                                            </th>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    ) : (
                        <Table className={clsx(styles.table)}>
                            <tbody>
                                {genre_repartition.top_artists.map(
                                    (artist, index) => (
                                        <tr key={artist.id}>
                                            <th scope="row">#{index + 1}</th>
                                            <td>{artist.name}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    )}
                </Popover.Content>
            </Popover>
        );
    }
);

export default ChartPopover;
