import React from "react";
import I18n from "shared/lib/I18n";
import Planning from "./Planning";
import {
    getPlanningsBuilding,
    getPlanningsProgress,
    getPlanningsDone,
} from "./Plannings.selector";
import { Helmet } from "react-helmet";

const Plannings = ({ plannings, location, ...props }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "plannings.manage.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="plannings.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="plannings.manage.breadcrumb" />
                    </li>
                </ol>
                <div className="row">
                    <div className="col-md-12">
                        <Planning
                            {...props}
                            title="plannings.manage.planning.building"
                            plannings={getPlanningsBuilding(plannings)}
                            status="BUILDING"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Planning
                            {...props}
                            title="plannings.manage.planning.progress"
                            plannings={getPlanningsProgress(plannings)}
                            status="PROGRESS"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Planning
                            {...props}
                            title="plannings.manage.planning.done"
                            plannings={getPlanningsDone(plannings)}
                            status="DONE"
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Plannings;
