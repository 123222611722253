import React from "react";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";

const LevelOfDevelopment = ({ level, location }) => {
    return (
        <InputText
            value={
                level !== null
                    ? I18n.getTranslation(
                          location,
                          `cnm.report.consolidate.edit.levelOfDevelopment.${level}`
                      )
                    : ""
            }
            readOnly
        />
    );
};

export default LevelOfDevelopment;
