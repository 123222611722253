import { createSelector } from "reselect";

export const getFilterOptions = createSelector(
    [(args) => args.entries],
    (entries) =>
        entries.reduce(
            (acc, curr) => {
                if (curr.format) {
                    acc["format"] = [
                        ...acc.format,
                        {
                            label: curr.format,
                            value: curr.format,
                        },
                    ]
                        .filter(
                            (row, index, self) =>
                                self.findIndex((t) => t.value === row.value) ===
                                index
                        )
                        .sort((a, b) => a.label.localeCompare(b.label));
                }

                return acc;
            },
            {
                format: [],
            }
        )
);

export const getFilteredCharts = createSelector(
    [(args) => args.entries, (args) => args.filter],
    (entries, filter) => {
        let filteredEntries = entries;

        if (filter.format.length !== 0) {
            filteredEntries = filteredEntries.filter(({ format }) =>
                filter.format.some((c) => c.value === format)
            );
        }

        if (filter.reference) {
            filteredEntries = filteredEntries.filter(({ main }) => main);
        }

        return filteredEntries
            .map(({ chart, country, source }) => ({
                id: chart.value,
                title: chart.label,
                country,
                source,
            }))
            .filter(
                (row, index, self) =>
                    self.findIndex((t) => t.id === row.id) === index
            )
            .sort((a, b) => a.title.localeCompare(b.title));
    }
);

export const getDefaultSelectedChart = createSelector(
    [(args) => args.entries],
    (entries) => {
        if (entries.length === 0) {
            return [];
        }

        // Sélectionner le chart id 107 s'il existe
        const chart107 = entries.find(
            (item) => item.chart && item.chart.value === 107
        );
        if (chart107) {
            return [
                {
                    id: chart107.chart.value,
                    title: chart107.chart.label,
                },
            ];
        }

        // Regarder dans tous les charts qui ont main à true
        const mainCharts = entries.filter((item) => item.main);

        if (mainCharts.length > 0) {
            // Utiliser l'ordre spécifique pour choisir le chart par défaut
            const preferredMainCharts = [
                16, 320, 10, 245, 363, 353, 113, 134, 124, 67, 331,
            ];
            for (const chartId of preferredMainCharts) {
                const preferredChart = mainCharts.find(
                    (item) =>
                        item.chart &&
                        parseInt(item.chart.value, 10) === parseInt(chartId, 10)
                );
                if (preferredChart) {
                    return [
                        {
                            id: preferredChart.chart.value,
                            title: preferredChart.chart.label,
                        },
                    ];
                }
            }

            // Prendre le premier dans les main
            return [
                {
                    id: mainCharts[0].chart.value,
                    title: mainCharts[0].chart.label,
                },
            ];
        }

        // Si aucun chart avec main à true, prendre le chart qui a le plus d'occurrence
        const chartOccurrences = {};
        entries.forEach((item) => {
            const chartId = item.chart && item.chart.value;
            if (chartId) {
                chartOccurrences[chartId] =
                    (chartOccurrences[chartId] || 0) + 1;
            }
        });

        const mostOccurringChartId = Object.keys(chartOccurrences).reduce(
            (a, b) => (chartOccurrences[a] > chartOccurrences[b] ? a : b)
        );
        const mostOccurringChart = entries.find(
            (item) =>
                item.chart &&
                parseInt(item.chart.value, 10) ===
                    parseInt(mostOccurringChartId, 10)
        );

        return [
            {
                id: mostOccurringChart.chart.value,
                title: mostOccurringChart.chart.label,
            },
        ];
    }
);
