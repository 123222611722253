import React from "react";
import { Overlay, Popover, Table } from "react-bootstrap";
import moment from "moment";
import I18n from "shared/lib/I18n";
import ImageLoader from "shared/components/ImageLoader";
import "./MapPopover.css";

const MapPopover = ({ show, country, target, locale }) => {
    return (
        <Overlay show={show} target={target} placement="bottom">
            <Popover className="artist-view-popularity-map-popover">
                <Popover.Title as="h3">{country?.name}</Popover.Title>
                <Popover.Content>
                    {country === null || country.charts === 0 ? (
                        <I18n t="artist.view.popularityMap.popover.noData" />
                    ) : (
                        <div className="d-flex">
                            {country?.cover !== null &&
                                country?.cover !== undefined && (
                                    <ImageLoader
                                        className="cover-art mr-2"
                                        src={country?.cover}
                                    />
                                )}
                            <Table borderless className="mb-0">
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            <I18n t="artist.view.popularityMap.popover.popularity" />
                                        </th>
                                        <td>
                                            {Math.round(country?.score)} %
                                            {country?.rank !== null && (
                                                <>
                                                    {" ("}
                                                    <I18n
                                                        t="utils.nth"
                                                        args={country?.rank}
                                                    />
                                                    {")"}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    {country?.mostPopularTitle !== null && (
                                        <tr className="border-top">
                                            <th scope="row">
                                                <I18n t="artist.view.popularityMap.popover.mostPopularTitle" />
                                            </th>
                                            <td>{country?.mostPopularTitle}</td>
                                        </tr>
                                    )}
                                    {country?.bestRank !== null && (
                                        <tr>
                                            <th scope="row">
                                                <I18n t="artist.view.popularityMap.popover.bestRank" />
                                            </th>
                                            <td>
                                                <I18n
                                                    t="utils.nth"
                                                    args={country?.bestRank}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {country?.bestMonth !== null && (
                                        <tr>
                                            <th scope="row">
                                                <I18n t="artist.view.popularityMap.popover.bestMonth" />
                                            </th>
                                            <td className="text-capitalize">
                                                {moment(country?.bestMonth)
                                                    .locale(locale)
                                                    .format("MMMM YYYY")}
                                            </td>
                                        </tr>
                                    )}
                                    {country?.weeksInCharts !== null && (
                                        <tr>
                                            <th scope="row">
                                                <I18n t="artist.view.popularityMap.popover.weeksInCharts" />
                                            </th>
                                            <td>{country?.weeksInCharts}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={2}>
                                            <I18n
                                                t="artist.view.popularityMap.popover.charts"
                                                args={country?.charts}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Popover.Content>
            </Popover>
        </Overlay>
    );
};

export default MapPopover;
