import React from "react";
import I18n from "shared/lib/I18n";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";

const ImageCard = ({
    url,
    partnerName,
    barcode,
    width,
    height,
    location,
    selected,
    onClick,
}) => {
    return (
        <div
            className={`card border ${
                selected && "border-success text-white bg-success"
            } mr-3 mb-3 ${onClick && "cursor-pointer"}`}
            style={{ width: "10.5rem" }}
            onClick={onClick}
        >
            <OverlayTrigger
                placement="top"
                overlay={
                    <Popover id={`popover-positioned-top`}>
                        <Popover.Content className="bg-black rounded-sm">
                            <ImageLoader
                                src={url}
                                width="400px"
                                alt="cover art"
                            />
                        </Popover.Content>
                    </Popover>
                }
            >
                <div>
                    <img src={url} className="card-img-top" />
                </div>
            </OverlayTrigger>
            <div className="card-body p-3">
                <p className="card-text text-center">
                    {width} x {height} - {partnerName}
                    {barcode && (
                        <span
                            title={I18n.getTranslation(
                                location,
                                `artist.management.disco.objects.album.cover.ean`
                            )}
                        >
                            {" "}
                            - {barcode}
                        </span>
                    )}
                </p>
            </div>
        </div>
    );
};

export default ImageCard;
