import React from "react";

const GenreLink = ({ id, name, locale, className }) => {
    return (
        <a
            href={`/${locale}/genre/view/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${className}`}
        >
            {name}
        </a>
    );
};

export default GenreLink;
