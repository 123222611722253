import React from "react";
import GettyFollowup from "./GettyFollowup";
import { useLocation } from "react-router";

const EnhancedGettyFollowup = (props) => {
    const location = useLocation();
    return <GettyFollowup {...props} location={location} />;
};

export default EnhancedGettyFollowup;
