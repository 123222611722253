import React from "react";
import I18n from "shared/lib/I18n";
import DnDColumns from "./DnDColumns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import InputSelect from "shared/components/Form/InputSelect";
import { displayAsString } from "shared/functions/Date";
import clsx from "clsx";
import "./IndustryStatistics.css";

const IndustryStatistics = ({
    columns,
    handleDragAndDrop,
    isHeaderSticky,
    headerObserverRef,
    countryOptions,
    selectedCountry,
    setSelectedCountry,
    mainChart,
    locale,
    location,
}) => {
    return (
        <div className="industry-statistics">
            <div id="header-observer" ref={headerObserverRef}></div>
            <div
                id="header"
                className={clsx(
                    "d-flex header",
                    isHeaderSticky && "header-sticky"
                )}
            >
                <div className={clsx("header-container")}>
                    <InputSelect
                        value={
                            selectedCountry !== null
                                ? {
                                      value: selectedCountry,
                                      label: countryOptions.find(
                                          (c) => selectedCountry === c.id
                                      )?.nom,
                                  }
                                : null
                        }
                        options={countryOptions.map(({ id, nom }) => {
                            return {
                                value: id,
                                label: nom,
                            };
                        })}
                        onChange={(opt) => {
                            setSelectedCountry(opt.value ?? null);
                            localStorage.setItem(
                                "selectedCountry",
                                opt.value ?? null
                            );
                        }}
                        isClearable={false}
                        id="input-select"
                        className="input-select"
                    />
                    <h4 className="title-text">
                        {I18n.getTranslation(
                            location,
                            "landing.industryStats.chart_title",
                            mainChart.top
                        )}
                        {
                            countryOptions.find((c) => selectedCountry === c.id)
                                ?.nom
                        }
                        {I18n.getTranslation(
                            location,
                            "landing.industryStats.chart_date",
                            {
                                start_date: displayAsString(
                                    mainChart.start_date,
                                    locale
                                ),
                                end_date: displayAsString(
                                    mainChart.end_date,
                                    locale
                                ),
                            }
                        )}
                    </h4>
                </div>
            </div>
            <DragDropContext onDragEnd={handleDragAndDrop}>
                <Droppable droppableId="ROOT" type="group">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={clsx("column-container")}
                        >
                            {columns.map((column, index) => (
                                <Draggable
                                    draggableId={column.id}
                                    index={index}
                                    key={column.id}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div
                                                style={{
                                                    height: "fit-content",
                                                }}
                                            >
                                                <DnDColumns {...column} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default IndustryStatistics;
