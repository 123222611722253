import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import LanguageSelect from "./LanguageSelect";

const defaultState = { isLoading: true, options: [] };

const EnhancedLanguageSelect = (props) => {
    const { api } = useAPI();
    const { locale, language, categoryId, topId } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        if (language === undefined) {
            navigate(`/${locale}/charts/translation/ja`);
        }
    }, [language]);

    const getOptions = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get("matching/top/translation/languages", { locale })
            .then((response) => {
                setState({ isLoading: false, options: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState({ isLoading: false, options: [] });
            });
    }, [locale]);

    useEffect(getOptions, [getOptions]);

    const selectedOption = useMemo(
        () =>
            state.options !== undefined && language !== undefined
                ? state.options.filter(({ value }) => value === language)[0]
                : undefined,
        [state.options, language]
    );

    const onChange = (opt) => {
        const path =
            topId !== undefined
                ? `/${categoryId}/${topId}`
                : categoryId !== undefined
                ? `/${categoryId}`
                : "";
        navigate(`/${locale}/charts/translation/${opt.value}${path}`);
    };

    return (
        <LanguageSelect
            onChange={onChange}
            selectedOption={selectedOption}
            {...state}
            {...props}
        />
    );
};

export default EnhancedLanguageSelect;
