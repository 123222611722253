import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Badge, Tabs, Tab } from "react-bootstrap";
import lyricfindIcon from "assets/static/images/lyricfind.png";
import Lyric from "./Lyric";
import "./LyricsInformation.css";

const LyricsInformation = ({ lyric, isLoading }) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-end mb-3">
                <div className="mr-4 text-right">
                    <p className="mb-0">
                        <strong>
                            <I18n t="recording.view.lyricsInformation.licence.title" />
                        </strong>
                    </p>
                    <p className="mb-0">
                        <strong>
                            <I18n t="recording.view.lyricsInformation.licence.subtitle" />
                        </strong>
                    </p>
                </div>
                <a
                    href="https://www.lyricfind.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={lyricfindIcon} width="200px" alt="Lyricfind" />
                </a>
            </div>
            <Widget
                title="recording.view.lyricsInformation.head"
                className="recording-view-lyrics-lyrics-information"
                isLoading={isLoading}
            >
                <Widget.Body>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col-2 border-right">
                            <strong>
                                <I18n t="recording.view.lyricsInformation.lfid" />
                            </strong>
                            <hr className="my-2" />
                            <div>{lyric.lfid}</div>
                        </div>
                        <div className="col">
                            <strong>
                                <I18n t="recording.view.lyricsInformation.territories" />
                            </strong>
                            <hr className="my-2" />
                            <div className="main-scroll">
                                {lyric.territories?.map((t) => {
                                    return (
                                        <Badge
                                            key={t}
                                            variant="secondary align-middle"
                                            className="mr-1"
                                        >
                                            {t}
                                        </Badge>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong className="d-inline-block mt-3 mb-4">
                                <I18n t="recording.view.lyricsInformation.lyric" />
                                <span className="mx-1">:</span>
                                <span>{lyric.language}</span>
                            </strong>
                            <Lyric lyric={lyric.lyric} lrc={lyric.lrc} />
                        </div>
                        {lyric.translations !== undefined &&
                            lyric.translations.length !== 0 && (
                                <div className="col-7 border-left">
                                    <Tabs
                                        variant="pills"
                                        justify
                                        id="recording-view-lyrics-lyrics-information-tab"
                                        className="recording-view-lyrics-lyrics-information-nav mb-3"
                                    >
                                        {lyric.translations.map(
                                            ({ language, lyric, lrc }) => {
                                                return (
                                                    <Tab
                                                        eventKey={language}
                                                        title={language}
                                                    >
                                                        <Lyric
                                                            lyric={lyric}
                                                            lrc={lrc}
                                                        />
                                                    </Tab>
                                                );
                                            }
                                        )}
                                    </Tabs>
                                </div>
                            )}
                    </div>
                </Widget.Body>
            </Widget>
        </>
    );
};

export default LyricsInformation;
