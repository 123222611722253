import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const DeleteButton = ({ onClick, location }) => {
    return (
        <Button
            variant="danger"
            size="sm"
            className="ml-1"
            title={I18n.getTranslation(
                location,
                "snep.file.fileList.delete.title"
            )}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" fixedWidth />
        </Button>
    );
};

export default DeleteButton;
