import React from "react";
import { useWatch } from "react-hook-form";
import LevelOfDevelopment from "./LevelOfDevelopment";

const EnhancedLevelOfDevelopment = ({ control, ...props }) => {
    const [cnmArtist, csaArtist, language] = useWatch({
        control,
        name: ["artist.cnmArtist", "artist.csaArtist", "title.language"],
        defaultValue: [null, null, null],
    });

    const level =
        language !== "FRENCH" || cnmArtist === null
            ? null
            : csaArtist !== undefined && csaArtist !== null
            ? "confirmed"
            : "new";

    return <LevelOfDevelopment level={level} {...props} />;
};

export default EnhancedLevelOfDevelopment;
