import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const MultiArtistSelect = ({ selected, loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={selected.map(({ id, name }) => ({
                id,
                name,
                label: `${name} (${id})`,
            }))}
            getOptionValue={({ id }) => id}
            loadOptions={loadOptions}
            onChange={onChange}
            isMulti
            {...props}
        />
    );
};

export default MultiArtistSelect;
