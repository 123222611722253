import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import UploadButton from "./UploadButton";
import { UPLOAD_MAX_SIZE } from "shared/constants/file";

const schema = yup.object().shape({
    file: yup
        .mixed()
        .test("fileSize", "utils.form.upload_max_size", (value) => {
            return value.size <= UPLOAD_MAX_SIZE;
        })
        .required("utils.form.required"),
    year: yup
        .string()
        .trim()
        .required("utils.form.required")
        .matches("^[0-9]{4}$", "utils.form.year_format"),
});

const EnhancedUploadButton = ({ repository, onSuccess }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [conflicts, setConflicts] = useState([]);

    const now = new Date();
    const currentYear = now.getFullYear();
    const yearOptions = [currentYear - 1, currentYear];

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            file: null,
            // Select the previous year by default between January and June, and
            // the current year between July and December.
            year: now.getMonth() <= 7 ? currentYear - 1 : currentYear,
        },
    });

    const onShow = () => {
        reset();
        setConflicts([]);
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = ({ file, year }) =>
        api
            .postFile(
                `cnm/repository/${repository}/${year}`,
                { locale },
                { file }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.upload.success"
                    )
                );
                onHide();
                onSuccess(year);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setConflicts(error.body?.conflicts ?? []);
            });

    return (
        <UploadButton
            repository={repository}
            yearOptions={yearOptions}
            show={show}
            onShow={onShow}
            onHide={onHide}
            conflicts={conflicts}
            errors={errors}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
        />
    );
};

export default EnhancedUploadButton;
