import React from "react";
import Loader from "shared/components/Loader";
import List from "./List";
import {
    SIMPLE,
    CREATION,
    NEW_LYRICS,
    DISCARDED_FULL,
    FORGOT_FULL,
    FORGOT_LF,
    FORGOT_2_3,
} from "./Coverage.constants";

const Coverage = ({ coverage, isLoading, isError, exportName }) => {
    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <Loader error size="3x" className="text-danger" />;
    }

    return (
        <>
            <List
                id={SIMPLE}
                data={coverage.simple}
                title="data_external.deezer_top_songs.coverage.simple"
                exportName={exportName}
            />
            <List
                id={CREATION}
                data={coverage.creation}
                title="data_external.deezer_top_songs.coverage.creation"
                exportName={exportName}
            />
            <List
                id={NEW_LYRICS}
                data={coverage.new_lyrics}
                title="data_external.deezer_top_songs.coverage.new_lyrics"
                exportName={exportName}
            />
            <List
                id={DISCARDED_FULL}
                data={coverage.discarded_full}
                title="data_external.deezer_top_songs.coverage.discarded_full"
                exportName={exportName}
            />
            <List
                id={FORGOT_FULL}
                data={coverage.forgot_full}
                title="data_external.deezer_top_songs.coverage.forgot_full"
                exportName={exportName}
            />
            <List
                id={FORGOT_LF}
                data={coverage.forgot_lf}
                title="data_external.deezer_top_songs.coverage.forgot_lf"
                exportName={exportName}
            />
            <List
                id={FORGOT_2_3}
                data={coverage.forgot_2_3}
                title="data_external.deezer_top_songs.coverage.forgot_2_3"
                exportName={exportName}
            />
        </>
    );
};

export default Coverage;
