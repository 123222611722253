import React from "react";
import { useLocation } from "react-router";
import Filter from "./Filter";

const EnhancedFilter = (props) => {
    const location = useLocation();
    return <Filter location={location} {...props} />;
};

export default EnhancedFilter;
