import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";

const CreationAction = ({ onMark, artist_created, disabled, location }) => {
    return (
        <button
            className={`btn btn-primary`}
            onClick={onMark}
            title={I18n.getTranslation(
                location,
                `matching.artist.artist.${
                    artist_created ? "unartistCreated" : "artistCreated"
                }.button.title`
            )}
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={artist_created ? faToggleOn : faToggleOff}
                size="lg"
                fixedWidth
            />
        </button>
    );
};

export default CreationAction;
