import React, { useEffect } from "react";
import { Popover } from "react-bootstrap";
import clsx from "clsx";
import styles from "./ChartPopover.module.css";
import I18n from "shared/lib/I18n";

const ChartPopover = React.forwardRef(
    (
        { title_repartition, popper, className, locale, location, ...props },
        ref
    ) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [title_repartition, popper]);

        return (
            <Popover
                ref={ref}
                className={clsx(className, styles.popover)}
                {...props}
            >
                <Popover.Title as="h3" className="text-capitalize">
                    <div className={clsx(styles.container)}>
                        <span>
                            {title_repartition.type === "gold"
                                ? I18n.getTranslation(
                                      location,
                                      "landing.industryStats.gold"
                                  )
                                : title_repartition.type === "current"
                                ? I18n.getTranslation(
                                      location,
                                      "landing.industryStats.current"
                                  )
                                : I18n.getTranslation(
                                      location,
                                      "landing.industryStats.no_data"
                                  )}
                        </span>
                        <span className={clsx(styles.pourcentage)}>
                            {title_repartition.percentage}%
                        </span>
                    </div>
                </Popover.Title>
            </Popover>
        );
    }
);

export default ChartPopover;
