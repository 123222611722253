export const getPrincipalMetas = (metas) => {
    return metas
        .filter(
            ({ key, value }) =>
                !(
                    value[0] === "{" &&
                    !["keywords", "referral_destinations"].includes(key)
                )
        )
        .reduce((acc, curr) => {
            if (curr.key === "keywords") {
                const parsedValue = JSON.parse(curr.value);
                if (acc.some((row) => row.key === "tags")) {
                    return acc.map((row) => {
                        if (row.key === "tags") {
                            return {
                                ...row,
                                value: [...row.value, parsedValue.text],
                            };
                        }
                        return row;
                    });
                } else {
                    return [...acc, { key: "tags", value: [parsedValue.text] }];
                }
            }

            return [...acc, curr];
        }, []);
};

export const getSecondaryMetas = (metas) => {
    return metas.filter(
        ({ key, value }) =>
            value[0] === "{" &&
            !["keywords", "referral_destinations"].includes(key)
    );
};
