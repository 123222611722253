import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import InputText from "shared/components/Form/InputText";
import InputNumber from "shared/components/Form/InputNumber";
import { Search } from "@musicstory/react-bootstrap-table2-toolkit";

const Filter = ({
    disabled,
    location,
    toolkitprops,
    setState,
    catalogTypeOptions,
    languageOptions,
    planningsOptions,
    assignementOptions,
}) => {
    const { SearchBar } = Search;
    return (
        <>
            <div className="row justify-content-end mb-3">
                <div className="col-4">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                planning: opt ? opt.value : null,
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.filters.planning" />
                        }
                        options={planningsOptions}
                    />
                </div>
                <div className="col-2">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                assignement: opt
                                    ? {
                                          id: opt.value,
                                          name: opt.label,
                                      }
                                    : null,
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.filters.assignement" />
                        }
                        options={assignementOptions}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-2">
                    {disabled ? (
                        <InputText
                            placeholder={I18n.getTranslation(
                                location,
                                "utils.select.placeholder"
                            )}
                            disabled={true}
                        />
                    ) : (
                        <SearchBar
                            {...toolkitprops.searchProps}
                            placeholder={I18n.getTranslation(
                                location,
                                "utils.select.placeholder"
                            )}
                            delay={2000}
                        />
                    )}
                </div>
                <div className="col-2">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                filter: {
                                    ...previousState.filter,
                                    catalog_type: opt ? opt.value : null,
                                },
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.catalog_type" />
                        }
                        options={catalogTypeOptions}
                        isDisabled={disabled}
                    />
                </div>
                <div className="col-2">
                    <InputNumber
                        onChange={(e) => {
                            setState((previousState) => ({
                                ...previousState,
                                filter: {
                                    ...previousState.filter,
                                    nb_line: e.target.value || null,
                                },
                            }));
                        }}
                        placeholder={I18n.getTranslation(
                            location,
                            "apple.sample.management.lyrics.filters.nb_line"
                        )}
                        min="0"
                        disabled={disabled}
                    />
                </div>
                <div className="col-2">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                filter: {
                                    ...previousState.filter,
                                    language: opt ? opt.value : null,
                                },
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.language" />
                        }
                        options={languageOptions}
                        isDisabled={disabled}
                    />
                </div>
                <div className="col-2">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                filter: {
                                    ...previousState.filter,
                                    assessment: opt ? opt.value : null,
                                },
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.assessment" />
                        }
                        options={[
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "apple.sample.management.lyrics.filters.assessment.yes"
                                ),
                                value: true,
                            },
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "apple.sample.management.lyrics.filters.assessment.no"
                                ),
                                value: false,
                            },
                        ]}
                        isDisabled={disabled}
                    />
                </div>
                <div className="col-2">
                    <InputSelect
                        onChange={(opt) => {
                            setState((previousState) => ({
                                ...previousState,
                                filter: {
                                    ...previousState.filter,
                                    counter_assessment: opt ? opt.value : null,
                                },
                            }));
                        }}
                        placeholder={
                            <I18n t="apple.sample.management.lyrics.counter_assessment" />
                        }
                        options={[
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "apple.sample.management.lyrics.filters.counter_assessment.yes"
                                ),
                                value: true,
                            },
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "apple.sample.management.lyrics.filters.counter_assessment.no"
                                ),
                                value: false,
                            },
                        ]}
                        isDisabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default Filter;
