import React, { useEffect, useState, useCallback } from "react";
import PictureInformationModal from "./PictureInformationModal";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import "./PictureInformationModal.css";

const schema = yup.object().shape({
    source: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    source_url: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    copyright_owner: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    author: yup
        .string()
        .when("license", {
            is: (license) => [2, 3, 4].includes(license),
            then: () => yup.string().required("utils.form.required"),
        })
        .nullable()
        .transform((value) => value || null)
        .trim(),
    copyright: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    year: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .matches(/^\d{4}$/, "utils.form.year_format"),
    complementary: yup
        .string()
        .nullable()
        .transform((value) => value || null)
        .trim(),
    license: yup.number().nullable().required("utils.form.required"),
});

const defaultValues = {
    source: null,
    source_url: null,
    copyright_owner: null,
    author: null,
    copyright: null,
    year: null,
    complementary: null,
    license: null,
};

const EnhancedPictureInformationModal = ({
    pictureId,
    versionId,
    show,
    handleHide,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });

    const {
        handleSubmit,
        register,
        control,
        watch,
        reset,
        setValue,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        if (!show) {
            reset(defaultValues);
        }
    }, [show]);

    const sourceOptions = [
        {
            label: "Facebook",
            value: "facebook",
        },
        {
            label: "Twitter",
            value: "twitter",
        },
        {
            label: "Instagram",
            value: "instagram",
        },
        {
            label: I18n.getTranslation(
                location,
                "common.pictureInformationModal.artist_site"
            ),
            value: "artist_site",
        },
        {
            label: I18n.getTranslation(
                location,
                "common.pictureInformationModal.artist_label"
            ),
            value: "artist_label",
        },
        {
            label: "Getty Images",
            value: "getty_images",
        },
        {
            label: "Wikimedia",
            value: "wikimedia",
        },
        {
            label: "Wikipedia",
            value: "wikipedia",
        },
        {
            label: I18n.getTranslation(
                location,
                "common.pictureInformationModal.other"
            ),
            value: "other",
        },
    ];

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(`picture/${pictureId}`, versionId && { versionId })
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, pictureId]);

    useEffect(getPicture, [getPicture]);

    const getPictureInformation = useCallback(() => {
        let cancelled = false;

        if (pictureId && show) {
            setIsLoading(true);
            api.get(`picture/curation/${pictureId}/information`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        reset(response);
                        if (response.license === null) {
                            setValue("license", 8);
                        }
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        reset();
                        setIsLoading(false);
                    }
                });
        }

        return () => {
            cancelled = true;
        };
    }, [pictureId, show, locale]);

    useEffect(getPictureInformation, [getPictureInformation]);

    const [licenseOptions, setLicenseOptions] = useState([]);
    const getLicense = useCallback(() => {
        if (show) {
            api.get(`license`)
                .then((response) => {
                    setLicenseOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [locale, show]);

    useEffect(getLicense, [getLicense]);

    const getPredictionUrl = useCallback(() => {
        if (show) {
            api.get(`picture/${pictureId}/predict/url`)
                .then((response) => {
                    setValue("complementary", response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, pictureId]);

    useEffect(getPredictionUrl, [getPredictionUrl]);

    const authorWatch = watch("author");
    const yearWatch = watch("year");
    const copyrightOwnerWatch = watch("copyright_owner");
    const licenseWatch = watch("license");

    useEffect(() => {
        let copyrightText = "©";

        if (authorWatch) {
            copyrightText += ` ${authorWatch}`;
        }

        if (yearWatch) {
            if (authorWatch) {
                copyrightText += ` / ${yearWatch}`;
            } else {
                copyrightText += ` ${yearWatch}`;
            }
        }

        if (copyrightOwnerWatch) {
            if (authorWatch || yearWatch) {
                copyrightText += ` / ${copyrightOwnerWatch}`;
            } else {
                copyrightText += ` ${copyrightOwnerWatch}`;
            }
        }

        if (licenseWatch) {
            const selectedLicense = licenseOptions.find(
                (option) => option.id === parseInt(licenseWatch)
            );
            const licenseName = selectedLicense ? selectedLicense.name : "";
            if (authorWatch || yearWatch || copyrightOwnerWatch) {
                copyrightText += ` / ${licenseName}`;
            } else {
                copyrightText += ` ${licenseName}`;
            }
        }

        setValue("copyright", copyrightText);
    }, [
        authorWatch,
        yearWatch,
        copyrightOwnerWatch,
        licenseWatch,
        licenseOptions,
    ]);

    const onSubmit = (data) => {
        return api
            .put(`picture/curation/${pictureId}/information`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.pictureInformationModal.success"
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.pictureInformationModal.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.pictureInformationModal.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleHide();
        });
    };

    return (
        <PictureInformationModal
            {...props}
            {...state}
            show={show}
            locale={locale}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            handleHide={handleHide}
            handleDelete={handleDelete}
            location={location}
            isLoading={isLoading}
            licenseOptions={licenseOptions}
            sourceOptions={sourceOptions}
        />
    );
};

export default EnhancedPictureInformationModal;
