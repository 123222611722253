import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import TrackMatcher from "./TrackMatcher";

const EnhancedTrackMatcher = ({
    entryId,
    locale,
    language,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const onMatch = (value, action) => {
        if (
            action.action !== "select-option" &&
            action.action != "remove-value"
        ) {
            return;
        }

        api.post(
            `matching/top/entry/${entryId}/recording`,
            {
                locale,
                language,
            },
            {
                msRecordingId:
                    action.action === "select-option"
                        ? action.option.id
                        : action.removedValue.id,
                state: action.action === "select-option" ? "VALID" : "INVALID",
            }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return <TrackMatcher locale={locale} onMatch={onMatch} {...props} />;
};

export default EnhancedTrackMatcher;
