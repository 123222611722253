import React, { useState, useEffect, useCallback } from "react";
import PlanningEditorial from "./PlanningEditorial";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";

const EnhancedPlanningEditorial = ({
    planning,
    // getNotifications,
    ...props
}) => {
    const { api } = useAPI();
    const { user } = useUser();
    const [state, setState] = useState({
        tasks: [],
        isLoading: true,
    });
    const { locale } = useParams();
    const location = useLocation();

    const getTasks = useCallback(() => {
        let cancelled = false;

        api.get(`plannings/${planning.id}/tasks`, {
            locale,
            mode: "production",
            partnerId: planning.partner_id,
        })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        tasks: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, planning.id]);

    useEffect(getTasks, [getTasks]);

    const handleStatusChanged = (task, subtask, newStatus) => {
        const isMatch = task.ms_album != null || task.ms_track != null;
        const canCompleted =
            newStatus === "COMPLETED" &&
            ([
                "BIO-A-FR",
                "BIO-A-EN",
                "BIO-A-DE",
                "BIO-U-FR",
                "BIO-U-EN",
                "BIO-U-DE",
                "BIO-C-FR",
                "BIO-C-EN",
                "BIO-C-DE",
            ].includes(subtask.type) ||
                isMatch);

        swal({
            title: !canCompleted
                ? I18n.getTranslation(
                      location,
                      `plannings.production.tasks.subtasks.match_required`
                  )
                : I18n.getTranslation(
                      location,
                      `plannings.production.tasks.subtasks.confirm_change_status.${newStatus}.title`
                  ),
            text:
                canCompleted &&
                I18n.getTranslation(
                    location,
                    `plannings.production.tasks.subtasks.confirm_change_status.${newStatus}.text`
                ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: canCompleted
                        ? I18n.getTranslation(
                              location,
                              "utils.sweetalert.cancel"
                          )
                        : I18n.getTranslation(
                              location,
                              "utils.sweetalert.close"
                          ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    visible: canCompleted,
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                api.put(
                    `plannings/tasks/subtasks/${subtask.id}/status`,
                    {
                        locale,
                    },
                    {
                        new_status: newStatus,
                        comment: subtask.comment,
                    }
                )
                    .then((response) => {
                        getTasks();
                        // getNotifications();
                        toast.success(response.message);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message);
                    });
            }
        });
    };

    const setAlbum = (taskId, releaseId) => {
        api.post(
            `plannings/${planning.id}/tasks/${taskId}/match/release`,
            { locale },
            { releaseId }
        )
            .then((response) => {
                getTasks();
                // getNotifications();
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const setRecording = (taskId, recordingId) => {
        api.post(
            `plannings/${planning.id}/tasks/${taskId}/match/recording`,
            { locale },
            { recordingId }
        )
            .then((response) => {
                getTasks();
                // getNotifications();
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <PlanningEditorial
            {...props}
            {...state}
            planning={planning}
            handleStatusChanged={handleStatusChanged}
            setAlbum={setAlbum}
            setRecording={setRecording}
            hasRight={user.hasRight}
        />
    );
};

export default EnhancedPlanningEditorial;
