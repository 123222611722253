import React from "react";
import I18n from "shared/lib/I18n";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import useUser from "shared/hooks/useUser";

const Fallback = ({ error, resetErrorBoundary }) => {
    const navigate = useNavigate();
    const { locale } = useParams();

    const redirectToHome = () => {
        resetErrorBoundary();
        navigate(`${locale}/`);
    };

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100vh", backgroundColor: "#eee" }}
        >
            <span className="mb-3">
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="7x"
                    fixedWidth
                />
            </span>
            <h1>
                <I18n t="error_boundary.message" />
            </h1>
            <h3 className="mb-3">
                <I18n t="error_boundary.submessage" />
            </h3>
            <button className="btn btn-primary" onClick={redirectToHome}>
                <I18n t="error_boundary.back" />
            </button>
        </div>
    );
};

const sendToSlack = (error, user) => {
    if (process.env.REACT_APP_SLACK_HOOK_ERROR) {
        fetch(process.env.REACT_APP_SLACK_HOOK_ERROR, {
            method: "post",
            body: JSON.stringify({
                blocks: [
                    {
                        type: "section",
                        text: {
                            type: "plain_text",
                            text: error.message,
                        },
                    },
                ],
                attachments: [
                    {
                        blocks: [
                            {
                                type: "section",
                                text: {
                                    type: "mrkdwn",
                                    text:
                                        "<" +
                                        window.location.href +
                                        "|# backoffice.music-story.com>",
                                },
                            },
                            {
                                type: "context",
                                elements: [
                                    {
                                        type: "plain_text",
                                        text: `User : ${user.id} - ${user.name} | Tag : ${process.env.REACT_APP_TAG}`,
                                    },
                                ],
                            },
                        ],
                        color: "#a61680",
                    },
                ],
            }),
        });
    }
};

const ErrorBoundaryProvider = ({ children }) => {
    const { user } = useUser();

    return (
        <ErrorBoundary
            FallbackComponent={Fallback}
            onError={(error) => sendToSlack(error, user)}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryProvider;
