import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import SubTask from "./SubTask";
import swal from "sweetalert";
import { usePlanningsManagementContext } from "pages/PlanningsManagement/PlanningsManagement.context";

const EnhancedSubTask = (props) => {
    const location = useLocation();
    const { lock } = usePlanningsManagementContext();

    const showComment = (comment) => {
        swal(comment, {
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.close"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        });
    };

    return (
        <SubTask {...props} showComment={showComment} isPlanningLock={lock} />
    );
};

export default EnhancedSubTask;
