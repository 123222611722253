import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import RepositoryList from "./RepositoryList";
import YearList from "./YearList";
import Repository from "./Repository";
import { Helmet } from "react-helmet";
import "./CNMRepositories.css";

const CNMRepositories = ({ repository, year, location }) => {
    return (
        <div className="content-wrap cnm-repositories">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.repositories"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/cnm/repositories">
                            <I18n t="base.nav.cnm.repositories" />
                        </NavLink>
                    </li>
                </ol>

                <RepositoryList repository={repository} />
                {repository !== undefined &&
                    repository !== "platforms" &&
                    repository != "cnm_artists_titles" && (
                        <YearList repository={repository} year={year} />
                    )}

                <Repository repository={repository} year={year} />
            </main>
        </div>
    );
};

export default CNMRepositories;
