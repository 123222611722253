import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useLocation } from "react-router";
import Create from "./Button/Create";

const User = ({
    user,
    columns,
    isLoading,
    options,
    getUser,
    defaultSorted,
    rolesOptions,
    rowClasses,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();
    return (
        <section className="widget pb-0">
            <ToolkitProvider keyField="id" data={user} columns={columns} search>
                {(toolkitprops) => (
                    <>
                        <header className="d-flex justify-content-between">
                            <h4>
                                <I18n t="user.manage.user.head" />
                            </h4>
                        </header>
                        <div className="widget-body">
                            <div className="row mb-3">
                                <div className="col-11">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "user.manage.user.search"
                                        )}
                                    />
                                </div>
                                <div className="col-1">
                                    <Create
                                        onSuccess={getUser}
                                        rolesOptions={rolesOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="widget-body widget-table-overflow">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    striped
                                    hover
                                    noDataIndication={
                                        <I18n t="user.manage.user.empty" />
                                    }
                                    pagination={paginationFactory(options)}
                                    defaultSorted={defaultSorted}
                                    rowClasses={rowClasses}
                                />
                            )}
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </section>
    );
};

export default User;
