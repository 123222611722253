import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";

const CreateButton = ({
    show,
    onShow,
    onHide,
    disabled,
    register,
    errors,
    isSubmitting,
    onSubmit,
    location,
}) => {
    return (
        <>
            <Button disabled={disabled} onClick={onShow}>
                <I18n t="cnm.aggregation.createButton.head" />
            </Button>

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.aggregation.createButton.head" />
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.aggregation.createButton.artist" />
                            </Form.Label>
                            <InputText
                                {...register("artist")}
                                error={
                                    errors.artist && [
                                        I18n.getTranslation(
                                            location,
                                            errors.artist.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.aggregation.createButton.title" />
                            </Form.Label>
                            <InputText
                                {...register("title")}
                                error={
                                    errors.title && [
                                        I18n.getTranslation(
                                            location,
                                            errors.title.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            <I18n t="utils.button.create" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default CreateButton;
