import { createSelector } from "reselect";

export const getPlanningsBuilding = createSelector(
    [(args) => args],
    (plannings) => {
        return plannings.filter((p) => p.status === "BUILDING");
    }
);

export const getPlanningsProgress = createSelector(
    [(args) => args],
    (plannings) => {
        return plannings.filter((p) => p.status === "PROGRESS");
    }
);

export const getPlanningsDone = createSelector(
    [(args) => args],
    (plannings) => {
        return plannings.filter((p) => p.status === "DONE");
    }
);
