import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import Filter from "./Filter";

const CategoryList = ({
    isLoading,
    columns,
    selectRow,
    filterOptions,
    filter,
    setFilter,
    categories,
}) => {
    return (
        <Widget title="common.charts.categoryList.head" isLoading={isLoading}>
            <Filter
                filterOptions={filterOptions}
                filter={filter}
                setFilter={setFilter}
            />
            <Widget.Body
                className="widget-table-overflow"
                style={{
                    maxHeight: "250px",
                    overflow: "auto",
                }}
            >
                <BootstrapTable
                    keyField="id"
                    columns={columns}
                    selectRow={selectRow}
                    data={categories}
                    classes="table-header-fixed table-layout-auto"
                    bordered={false}
                    bootstrap4
                    striped
                    hover
                    noDataIndication={
                        <I18n t="common.charts.categoryList.empty" />
                    }
                />
            </Widget.Body>
        </Widget>
    );
};

export default CategoryList;
