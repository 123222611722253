import React, { useState, useEffect, useCallback } from "react";
import PictureTagsModal from "./PictureTagsModal";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";

const schema = yup.object().shape({
    2: yup.number().nullable().required("utils.form.required"),
    3: yup.number().nullable().required("utils.form.required"),
    4: yup.number().nullable().required("utils.form.required"),
    6: yup.number().nullable().required("utils.form.required"),
    7: yup.number().nullable().required("utils.form.required"),
});

const defaultTags = {
    2: null,
    3: null,
    4: null,
    6: null,
    7: null,
    shot_date: null,
    ms_artists: [],
};

const EnhancedPictureTagsModal = ({
    id_picture,
    id_picture_version,
    show,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultTags,
    });

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(
                `picture/${id_picture}`,
                id_picture_version
                    ? {
                          locale,
                          versionId: id_picture_version,
                      }
                    : { locale }
            )
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, id_picture, locale]);

    useEffect(getPicture, [getPicture]);

    useEffect(() => {
        if (!show) {
            reset(defaultTags);
        }

        if (show && state.picture?.tags) {
            reset({ ...defaultTags, ...state.picture.tags });
        }
    }, [reset, show, state.picture]);

    const [tags, setTags] = useState([]);
    const getTags = useCallback(() => {
        if (show) {
            api.get("picture/tags", { locale })
                .then((response) => {
                    setTags(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, locale]);

    useEffect(getTags, [getTags]);

    const onSubmit = (data) => {
        return api
            .post(
                `picture/${id_picture_version}/tags`,
                { locale },
                { tags: data }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.pictureTagsModal.success"
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <PictureTagsModal
            {...props}
            {...state}
            show={show}
            tags={tags}
            errors={errors}
            isDirty={isDirty}
            control={control}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            locale={locale}
            location={location}
        />
    );
};

export default EnhancedPictureTagsModal;
