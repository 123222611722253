import React from "react";
import I18n from "shared/lib/I18n";
import { Button, Form } from "react-bootstrap";
import Loader from "shared/components/Loader";
import InputPassword from "shared/components/Form/InputPassword";

const ChangePassword = ({
    location,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
}) => {
    return (
        <Form onSubmit={!isSubmitting ? onSubmit : null}>
            <Form.Group className="m-0">
                <div className="row g-2">
                    <div className="col-lg-4">
                        <div>
                            <Form.Label>
                                <I18n
                                    t={`profile.change_password.old_password`}
                                />
                            </Form.Label>
                            <InputPassword
                                {...register("old_password")}
                                error={
                                    errors.old_password && [
                                        I18n.getTranslation(
                                            location,
                                            errors.old_password.message
                                        ),
                                    ]
                                }
                                showEye
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <Form.Label>
                                <I18n
                                    t={`profile.change_password.new_password`}
                                />
                            </Form.Label>
                            <InputPassword
                                {...register("new_password")}
                                error={
                                    errors.new_password && [
                                        I18n.getTranslation(
                                            location,
                                            errors.new_password.message
                                        ),
                                    ]
                                }
                                showEye
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div>
                            <Form.Label>
                                <I18n
                                    t={`profile.change_password.confirm_password`}
                                />
                            </Form.Label>
                            <InputPassword
                                {...register("confirm_password")}
                                error={
                                    errors.confirm_password && [
                                        I18n.getTranslation(
                                            location,
                                            errors.confirm_password.message
                                        ),
                                    ]
                                }
                                showEye
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-end">
                            <Button
                                disabled={isSubmitting || !isDirty}
                                onClick={!isSubmitting ? onSubmit : null}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`security.reset.button`} />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form.Group>
        </Form>
    );
};

export default ChangePassword;
