import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Loader from "shared/components/Loader";
import PlatformSelect from "./PlatformSelect";
import Filter from "./Filter";

const Chart = ({
    chart,
    columns,
    rowClasses,
    selectRow,
    pagination,
    defaultSorted,
    isLoading,
    location,
    filter,
    setFilter,
}) => {
    return (
        <Widget
            title="cnm.report.consolidate.chart.head"
            className="vertical-shrink pb-0 mb-0"
            actions={<PlatformSelect />}
        >
            <ToolkitProvider
                keyField="id"
                data={chart}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <PaginationProvider pagination={pagination}>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <Widget.Body>
                                    <div className="form-row mb-3">
                                        <div className="col-5">
                                            <Search.SearchBar
                                                {...toolkitprops.searchProps}
                                                placeholder={I18n.getTranslation(
                                                    location,
                                                    "cnm.report.consolidate.chart.search"
                                                )}
                                            />
                                        </div>
                                        <Filter
                                            filter={filter}
                                            setFilter={setFilter}
                                        />
                                    </div>
                                </Widget.Body>
                                <Widget.Body className="widget-table-overflow overflow-auto mb-0">
                                    {isLoading ? (
                                        <Loader />
                                    ) : (
                                        <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            bootstrap4
                                            striped
                                            bordered={false}
                                            hover
                                            noDataIndication={
                                                <I18n t="cnm.report.consolidate.chart.empty" />
                                            }
                                            classes="table-header-fixed table-selectable"
                                            rowClasses={rowClasses}
                                            selectRow={selectRow}
                                            defaultSortDirection="asc"
                                        />
                                    )}
                                </Widget.Body>
                                <Widget.Body className="my-2">
                                    <div className="row align-items-center">
                                        {!isLoading && (
                                            <>
                                                <div className="col">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                        dataSize={chart.length}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Widget.Body>
                            </>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        </Widget>
    );
};

export default Chart;
