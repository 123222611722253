import React from "react";
import { ComposableMap, Sphere, Graticule } from "react-simple-maps";
import { ButtonGroup, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import Geographies from "./Geographies";
import MapPopover from "./MapPopover";
import Widget from "shared/components/Widget";
import styles from "./PopularityMap.module.css";
import clsx from "clsx";

const PopularityMap = ({
    current,
    gold,
    isLoading,
    popover,
    showPopover,
    hidePopover,
    showGold,
    setShowGold,
    location,
}) => {
    return (
        <Widget className="mb-0" isLoading={isLoading}>
            <Widget.Body>
                <h4
                    style={{
                        position: "absolute",
                        left: "20px",
                        top: "15px",
                    }}
                >
                    <I18n t="recording.view.popularityMap.head" />
                </h4>
                <ButtonGroup
                    style={{
                        position: "absolute",
                        right: "20px",
                        top: "15px",
                    }}
                >
                    <Button
                        onClick={() => setShowGold(false)}
                        active={!showGold}
                        title={I18n.getTranslation(
                            location,
                            "recording.view.popularityMap.tabs.current.description"
                        )}
                        className={clsx(
                            styles.button,
                            !showGold && styles.active
                        )}
                    >
                        <I18n t="recording.view.popularityMap.tabs.current.title" />
                    </Button>
                    <Button
                        onClick={() => setShowGold(true)}
                        active={showGold}
                        title={I18n.getTranslation(
                            location,
                            "recording.view.popularityMap.tabs.gold.description"
                        )}
                        className={clsx(
                            styles.button,
                            showGold && styles.active
                        )}
                    >
                        <I18n t="recording.view.popularityMap.tabs.gold.title" />
                    </Button>
                </ButtonGroup>
                <div onMouseLeave={hidePopover}>
                    <MapPopover {...popover} />
                    <ComposableMap
                        width={820}
                        height={400}
                        projectionConfig={{
                            scale: 150,
                        }}
                        style={{
                            display: "block",
                            maxHeight: "740px",
                            margin: "auto",
                        }}
                    >
                        <Sphere stroke="#dee2e6" strokeWidth={0.4} />
                        <Graticule
                            stroke="#dee2e6"
                            strokeWidth={0.4}
                            onMouseEnter={hidePopover}
                        />
                        <Geographies
                            countries={showGold ? gold : current}
                            hoveredCountry={popover.show ? popover.id : null}
                            showPopover={showPopover}
                        />
                    </ComposableMap>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default PopularityMap;
