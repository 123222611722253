import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Helmet } from "react-helmet";
import { Nav, Tab } from "react-bootstrap";
import styles from "./Profile.module.css";
import PersonalDetails from "./PersonalDetails";
import ChangePassword from "./ChangePassword";
import clsx from "clsx";

const Profile = ({ location, selectedTab, onSelectTab }) => {
    return (
        <>
            <div className="content-wrap">
                <Helmet>
                    <title>{`${I18n.getTranslation(
                        location,
                        "dashboard"
                    )} - ${I18n.getTranslation(
                        location,
                        "profile.personal_details.breadcrumb"
                    )}`}</title>
                </Helmet>
                <main id="content" className="content" role="main">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <I18n t="dashboard" />
                        </li>
                        <li className="breadcrumb-item active">
                            <I18n t="profile.personal_details.breadcrumb" />
                        </li>
                    </ol>
                    <Widget className="m-0 p-0">
                        <Tab.Container
                            id="profile-tab"
                            activeKey={selectedTab}
                            defaultActiveKey="personal-details"
                            onSelect={onSelectTab}
                        >
                            <Widget.Body>
                                <div className={clsx(styles.cardHeader)}>
                                    <Nav>
                                        <Nav.Item
                                            className={clsx(styles.navItem)}
                                        >
                                            <Nav.Link
                                                eventKey="personal-details"
                                                className={clsx(
                                                    styles.navLink,
                                                    selectedTab ===
                                                        "personal-details" &&
                                                        styles.active
                                                )}
                                            >
                                                <I18n t="profile.personal_details.title" />
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item
                                            className={clsx(styles.navItem)}
                                        >
                                            <Nav.Link
                                                eventKey="change-password"
                                                className={clsx(
                                                    styles.navLink,
                                                    selectedTab ===
                                                        "change-password" &&
                                                        styles.active
                                                )}
                                            >
                                                <I18n t="profile.change_password.title" />
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Widget.Body>
                            <Widget.Body className="p-4">
                                <Tab.Content className="overflow-hidden">
                                    <Tab.Pane
                                        eventKey="personal-details"
                                        className="nav-pane"
                                    >
                                        <PersonalDetails />
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="change-password"
                                        className="nav-pane"
                                    >
                                        <ChangePassword />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Widget.Body>
                        </Tab.Container>
                    </Widget>
                </main>
            </div>
        </>
    );
};

export default Profile;
