import React from "react";
import Layout from "layout/Auth/Layout";
import styles from "./PrivacyPolicy.module.css";
import clsx from "clsx";

const PrivacyPolicy = ({ locale }) => {
    return (
        <Layout>
            <div
                className={clsx("row justify-content-center", styles.container)}
            >
                <div className="col-lg-12 p-5">
                    <h1 className="font-weight-bold">PRIVACY POLICY</h1>
                    <h5 className="font-weight-bold text-muted">
                        Last updated November 21, 2023
                    </h5>
                    <br />
                    <br />
                    <p>
                        This privacy notice for Music Story ("
                        <strong>we</strong>," "<strong>us</strong>," or "
                        <strong>our</strong>"), describes how and why we might
                        collect, store, use, and/or share ("
                        <strong>process</strong>") your information when you use
                        our services ("<strong>Services</strong>"), such as when
                        you:
                        <ul>
                            <li>
                                Download and use our Facebook application (Music
                                Story), or any other application of ours that
                                links to this privacy notice
                            </li>
                            <li>
                                Engage with us in other related ways, including
                                any sales, marketing, or events
                            </li>
                        </ul>
                        Questions or concerns?{" "}
                        <i>
                            Reading this privacy notice will help you understand
                            your privacy rights and choices. If you do not agree
                            with our policies and practices, please do not use
                            our Services. If you still have any questions or
                            concerns, please contact us at
                            webmaster@music-story.com.
                        </i>
                    </p>
                    <br />
                    <br />
                    <h4 className="font-weight-bold">SUMMARY OF KEY POINTS</h4>
                    <br />
                    <p className="font-weight-bold">
                        This summary provides key points from our privacy
                        notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our table of contents below to find
                        the section you are looking for.
                    </p>
                    <p>
                        <strong>
                            What personal information do we process?
                        </strong>{" "}
                        When you visit, use, or navigate our Services, we may
                        process personal information depending on how you
                        interact with us and the Services, the choices you make,
                        and the products and features you use. Learn more about
                        personal information you disclose to us.
                    </p>
                    <p>
                        <strong>
                            Do we process any sensitive personal information?
                        </strong>{" "}
                        We do not process sensitive personal information.
                    </p>
                    <p>
                        <strong>
                            Do we receive any information from third parties?
                        </strong>{" "}
                        We do not receive any information from third parties.
                    </p>
                    <p>
                        <strong>How do we process your information?</strong> We
                        process your information to provide, improve, and
                        administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent. We process your information only when
                        we have a valid legal reason to do so. Learn more about
                        how we process your information.
                    </p>
                    <p>
                        <strong>
                            In what situations and with which parties do we
                            share personal information?
                        </strong>{" "}
                        We may share information in specific situations and with
                        specific third parties. Learn more about when and with
                        whom we share your personal information.
                    </p>
                    <p>
                        <strong>How do we keep your information safe?</strong>{" "}
                        We have organizational and technical processes and
                        procedures in place to protect your personal
                        information. However, no electronic transmission over
                        the internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Learn more about how we keep
                        your information safe.
                    </p>
                    <p>
                        <strong>What are your rights?</strong> Depending on
                        where you are located geographically, the applicable
                        privacy law may mean you have certain rights regarding
                        your personal information. Learn more about your privacy
                        rights.
                    </p>
                    <p>
                        <strong>How do you exercise your rights?</strong> The
                        easiest way to exercise your rights is by submitting a
                        data subject access request, or by contacting us. We
                        will consider and act upon any request in accordance
                        with applicable data protection laws.
                    </p>
                    <p>
                        Want to learn more about what we do with any information
                        we collect? Review the privacy notice in full.
                    </p>
                    <br />
                    <br />
                    <h4 className="font-weight-bold">TABLE OF CONTENTS</h4>
                    <br />
                    <h4>
                        <a href={`/${locale}/privacy#title-1`}>
                            1. WHAT INFORMATION DO WE COLLECT?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-2`}>
                            2. HOW DO WE PROCESS YOUR INFORMATION?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-3`}>
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            PERSONAL INFORMATION?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-4`}>
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-5`}>
                            5. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-6`}>
                            6. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-7`}>
                            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-8`}>
                            8. DO WE COLLECT INFORMATION FROM MINORS?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-9`}>
                            9. WHAT ARE YOUR PRIVACY RIGHTS?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-10`}>
                            10. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-11`}>
                            11. DO WE MAKE UPDATES TO THIS NOTICE?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-12`}>
                            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </a>
                    </h4>
                    <h4>
                        <a href={`/${locale}/privacy#title-13`}>
                            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                            WE COLLECT FROM YOU?
                        </a>
                    </h4>
                    <br />
                    <br />
                    <h4 id="title-1" className="font-weight-bold">
                        1. WHAT INFORMATION DO WE COLLECT?
                    </h4>
                    <br />
                    <p className="font-weight-bold">
                        Personal information you disclose to us
                    </p>
                    <p>
                        In Short: We collect personal information that you
                        provide to us.
                    </p>
                    <p>
                        We collect personal information that you voluntarily
                        provide to us when you express an interest in obtaining
                        information about us or our products and Services, when
                        you participate in activities on the Services, or
                        otherwise when you contact us.
                    </p>
                    <p>
                        <strong>Personal Information Provided by You.</strong>{" "}
                        The personal information that we collect depends on the
                        context of your interactions with us and the Services,
                        the choices you make, and the products and features you
                        use. The personal information we collect may include the
                        following:
                        <ul>
                            <li>names</li>
                            <li>email addresses</li>
                            <li>usernames</li>
                            <li>job titles</li>
                            <li>contact preferences</li>
                        </ul>
                        <strong>Sensitive Information.</strong> We do not
                        process sensitive information.
                    </p>
                    <p>
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                    </p>
                    <p>
                        <strong>
                            Information collected when you use our Facebook
                            application(s).
                        </strong>{" "}
                        We by default access your Facebook basic account
                        information, including your name, email, gender,
                        birthday, current city, and profile picture URL, as well
                        as other information that you choose to make public. We
                        may also request access to other permissions related to
                        your account, such as friends, check-ins, and likes, and
                        you may choose to grant or deny us access to each
                        individual permission. For more information regarding
                        Facebook permissions, refer to the Facebook Permissions
                        Reference page.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-2" className="font-weight-bold">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </h4>
                    <br />
                    <p>
                        In Short: We process your information to provide,
                        improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply
                        with law. We may also process your information for other
                        purposes with your consent.
                    </p>
                    <p>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                        <ul>
                            <li>
                                <strong>
                                    To save or protect an individual's vital
                                    interest.
                                </strong>{" "}
                                We may process your information when necessary
                                to save or protect an individual’s vital
                                interest, such as to prevent harm.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <br />
                    <h4 id="title-3" className="font-weight-bold">
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                        INFORMATION?
                    </h4>
                    <br />
                    <p>
                        In Short: We only process your personal information when
                        we believe it is necessary and we have a valid legal
                        reason (i.e., legal basis) to do so under applicable
                        law, like with your consent, to comply with laws, to
                        provide you with services to enter into or fulfill our
                        contractual obligations, to protect your rights, or to
                        fulfill our legitimate business interests.
                    </p>
                    <p>
                        The General Data Protection Regulation (GDPR) and UK
                        GDPR require us to explain the valid legal bases we rely
                        on in order to process your personal information. As
                        such, we may rely on the following legal bases to
                        process your personal information:
                        <ul>
                            <li>
                                <strong>Consent.</strong> We may process your
                                information if you have given us permission
                                (i.e., consent) to use your personal information
                                for a specific purpose. You can withdraw your
                                consent at any time. Learn more about
                                withdrawing your consent.
                            </li>
                            <li>
                                <strong>Legal Obligations.</strong> We may
                                process your information where we believe it is
                                necessary for compliance with our legal
                                obligations, such as to cooperate with a law
                                enforcement body or regulatory agency, exercise
                                or defend our legal rights, or disclose your
                                information as evidence in litigation in which
                                we are involved.
                            </li>
                            <li>
                                <strong>Vital Interests.</strong> We may process
                                your information where we believe it is
                                necessary to protect your vital interests or the
                                vital interests of a third party, such as
                                situations involving potential threats to the
                                safety of any person.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <br />
                    <h4 id="title-4" className="font-weight-bold">
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?
                    </h4>
                    <br />
                    <p>
                        In Short: We may share information in specific
                        situations described in this section and/or with the
                        following third parties.
                    </p>
                    <p>
                        We may need to share your personal information in the
                        following situations:
                        <ul>
                            <li>
                                <strong>Business Transfers.</strong> We may
                                share or transfer your information in connection
                                with, or during negotiations of, any merger,
                                sale of company assets, financing, or
                                acquisition of all or a portion of our business
                                to another company.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <br />
                    <h4 id="title-5" className="font-weight-bold">
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </h4>
                    <br />
                    <p>
                        In Short: We may use cookies and other tracking
                        technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to access or store
                        information. Specific information about how we use such
                        technologies and how you can refuse certain cookies is
                        set out in our Cookie Notice.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-6" className="font-weight-bold">
                        6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </h4>
                    <br />
                    <p>
                        In Short: We keep your information for as long as
                        necessary to fulfill the purposes outlined in this
                        privacy notice unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting,
                        or other legal requirements). No purpose in this notice
                        will require us keeping your personal information for
                        longer than 1 year.
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-7" className="font-weight-bold">
                        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </h4>
                    <br />
                    <p>
                        In Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures.
                    </p>
                    <p>
                        We have implemented appropriate and reasonable technical
                        and organizational security measures designed to protect
                        the security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Services is at your
                        own risk. You should only access the Services within a
                        secure environment.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-8" className="font-weight-bold">
                        8. DO WE COLLECT INFORMATION FROM MINORS?
                    </h4>
                    <br />
                    <p>
                        In Short: We do not knowingly collect data from or
                        market to children under 18 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are
                        the parent or guardian of such a minor and consent to
                        such minor dependent’s use of the Services. If we learn
                        that personal information from users less than 18 years
                        of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete
                        such data from our records. If you become aware of any
                        data we may have collected from children under age 18,
                        please contact us at webmaster@music-story.com.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-9" className="font-weight-bold">
                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </h4>
                    <br />
                    <p>
                        In Short: In some regions, such as the European Economic
                        Area (EEA), United Kingdom (UK), and Switzerland, you
                        have rights that allow you greater access to and control
                        over your personal information. You may review, change,
                        or terminate your account at any time.
                    </p>
                    <p>
                        In some regions (like the EEA, UK, and Switzerland), you
                        have certain rights under applicable data protection
                        laws. These may include the right (i) to request access
                        and obtain a copy of your personal information, (ii) to
                        request rectification or erasure; (iii) to restrict the
                        processing of your personal information; (iv) if
                        applicable, to data portability; and (v) not to be
                        subject to automated decision-making. In certain
                        circumstances, you may also have the right to object to
                        the processing of your personal information. You can
                        make such a request by contacting us by using the
                        contact details provided in the section "HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE?" below.
                    </p>
                    <p>
                        We will consider and act upon any request in accordance
                        with applicable data protection laws.
                    </p>
                    <p>
                        If you are located in the EEA or UK and you believe we
                        are unlawfully processing your personal information, you
                        also have the right to complain to your Member State
                        data protection authority or UK data protection
                        authority.
                    </p>
                    <p>
                        If you are located in Switzerland, you may contact the
                        Federal Data Protection and Information Commissioner.
                    </p>
                    <p>
                        <strong>Withdrawing your consent:</strong> If we are
                        relying on your consent to process your personal
                        information, you have the right to withdraw your consent
                        at any time. You can withdraw your consent at any time
                        by contacting us by using the contact details provided
                        in the section "HOW CAN YOU CONTACT US ABOUT THIS
                        NOTICE?" below.
                    </p>
                    <p>
                        However, please note that this will not affect the
                        lawfulness of the processing before its withdrawal nor,
                        will it affect the processing of your personal
                        information conducted in reliance on lawful processing
                        grounds other than consent.
                    </p>
                    <p>
                        <strong>
                            Opting out of marketing and promotional
                            communications:
                        </strong>{" "}
                        You can unsubscribe from our marketing and promotional
                        communications at any time by clicking on the
                        unsubscribe link in the emails that we send, or by
                        contacting us using the details provided in the section
                        "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You
                        will then be removed from the marketing lists. However,
                        we may still communicate with you — for example, to send
                        you service-related messages that are necessary for the
                        administration and use of your account, to respond to
                        service requests, or for other non-marketing purposes.
                    </p>
                    <p>
                        <strong>Cookies and similar technologies:</strong> Most
                        Web browsers are set to accept cookies by default. If
                        you prefer, you can usually choose to set your browser
                        to remove cookies and to reject cookies. If you choose
                        to remove cookies or reject cookies, this could affect
                        certain features or services of our Services.
                    </p>
                    <p>
                        If you have questions or comments about your privacy
                        rights, you may email us at webmaster@music-story.com.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-10" className="font-weight-bold">
                        10. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </h4>
                    <br />
                    <p>
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track ("DNT")
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. At this
                        stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we
                        do not currently respond to DNT browser signals or any
                        other mechanism that automatically communicates your
                        choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-11" className="font-weight-bold">
                        11. DO WE MAKE UPDATES TO THIS NOTICE?
                    </h4>
                    <br />
                    <p>
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        "Revised" date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy notice frequently to be informed of
                        how we are protecting your information.
                    </p>
                    <br />
                    <br />
                    <h4 id="title-12" className="font-weight-bold">
                        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </h4>
                    <br />
                    <p>
                        If you have questions or comments about this notice, you
                        may email us at webmaster@music-story.com or contact us
                        by post at:
                    </p>
                    <p>
                        Music Story
                        <br />
                        14 Rue du Carrousel
                        <br />
                        Villeneuve-d'Ascq 59650
                        <br />
                        France
                    </p>
                    <br />
                    <br />
                    <h4 id="title-13" className="font-weight-bold">
                        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                    </h4>
                    <br />
                    <p>
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, change that
                        information, or delete it. To request to review, update,
                        or delete your personal information, please fill out and
                        submit a data subject access request.
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
