import { useState, useCallback } from "react";

const useToggle = (defaultValue = false) => {
    const [value, setValue] = useState(defaultValue);
    const toggle = useCallback(() => {
        setValue((previousValue) => !previousValue);
    }, []);
    return [value, toggle];
};

export default useToggle;
