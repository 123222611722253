import React from "react";
import Chart from "./Chart";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router-dom";

const EnhancedChart = React.memo(
    ({ genre_repartition, setPopover, ...props }) => {
        const location = useLocation();

        const getColor = (id, index) => {
            if (id === null) {
                return "#DDDDDD";
            }
            if (id === 0) {
                return "#5F5F5F";
            }
            if (index === 0) return "#000000";
            if (index === 1) return "#3900D4";
            if (index === 2) return "#8866E5";
            if (index === 3) return "#FF00FF";
            if (index === 4) return "#FF99FF";
            return "#F2F2F2";
        };

        const data = {
            labels: genre_repartition.map(
                ({ genre }) =>
                    genre ??
                    I18n.getTranslation(
                        location,
                        "landing.industryStats.no_data"
                    )
            ),
            datasets: [
                {
                    label: "genre",
                    data: genre_repartition.map(({ count }) => count),
                    backgroundColor: genre_repartition.map(({ id }, index) =>
                        getColor(id, index)
                    ),
                    borderRadius: 2,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                legend: {
                    position: "top",
                },
                tooltip: {
                    enabled: false,
                    external: ({ chart, tooltip }) => {
                        setPopover({
                            show:
                                tooltip.opacity > 0 &&
                                tooltip.dataPoints !== undefined,
                            left: chart.canvas.offsetLeft + tooltip.caretX,
                            top: chart.canvas.offsetTop + tooltip.caretY,
                            genre_repartition:
                                tooltip.dataPoints !== undefined
                                    ? genre_repartition[
                                          tooltip.dataPoints[0].dataIndex
                                      ]
                                    : null,
                        });
                    },
                },
            },
        };

        return <Chart {...props} data={data} options={options} />;
    }
);

export default EnhancedChart;
