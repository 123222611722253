import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import CreationSwitch from "./CreationSwitch";

const EnhancedCreationSwitch = ({
    rowId,
    msArtist,
    locale,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const onChecked = (creation) => {
        const apiRequest = creation
            ? api.post(
                  `matching/top/artist/created/${rowId}`,
                  {
                      locale,
                  },
                  {
                      artistId: msArtist.id,
                  }
              )
            : api.delete(`matching/top/artist/created/${rowId}`, {
                  locale,
              });
        apiRequest
            .then((response) => {
                onChange({ creation });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CreationSwitch msArtist={msArtist} onChecked={onChecked} {...props} />
    );
};

export default EnhancedCreationSwitch;
