import React from "react";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import Widget from "shared/components/Widget";
import { CHARTS_MODE } from "shared/constants/Charts";

const Filter = ({ location, filter, setFilter, mode }) => {
    if (mode !== CHARTS_MODE.MATCHING) {
        return null;
    }

    return (
        <Widget.Body>
            <div className="row mb-2">
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                matched: opt,
                            }));
                        }}
                        value={filter.matched}
                        placeholder={
                            <I18n t="common.charts.charts.filter.matched.placeholder" />
                        }
                        options={[
                            {
                                value: true,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.matched.true"
                                ),
                            },
                            {
                                value: false,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.matched.false"
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                newEntry: opt,
                            }));
                        }}
                        value={filter.newEntry}
                        placeholder={
                            <I18n t="common.charts.charts.filter.newEntry.placeholder" />
                        }
                        options={[
                            {
                                value: true,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.newEntry.true"
                                ),
                            },
                            {
                                value: false,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.newEntry.false"
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                toBeTreated: opt,
                            }));
                        }}
                        value={filter.toBeTreated}
                        placeholder={
                            <I18n t="common.charts.charts.filter.toBeTreated.placeholder" />
                        }
                        options={[
                            {
                                value: true,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.toBeTreated.true"
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="col">
                    <InputSelect
                        onChange={(opt) => {
                            setFilter((prev) => ({
                                ...prev,
                                discarded: opt,
                            }));
                        }}
                        value={filter.discarded}
                        placeholder={
                            <I18n t="common.charts.charts.filter.discarded.placeholder" />
                        }
                        options={[
                            {
                                value: true,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.discarded.true"
                                ),
                            },
                            {
                                value: false,
                                label: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.filter.discarded.false"
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </Widget.Body>
    );
};

export default Filter;
