import React from "react";
import List from "./List";
import Footer from "./Footer";
import Widget from "shared/components/Widget";
import Create from "pages/Plannings/Button/Create";

const Planning = ({
    title,
    planningLength,
    handlePageChange,
    activePage,
    getPlanningsByPage,
    status,
    getPlannings,
    ...props
}) => {
    return (
        <Widget
            title={title}
            actions={
                status === "BUILDING" && <Create onSuccess={getPlannings} />
            }
            className="pb-0"
        >
            <Widget.Body>
                <List
                    {...props}
                    plannings={getPlanningsByPage()}
                    status={status}
                    getPlannings={getPlannings}
                />
                <Footer
                    totalItemsCount={planningLength}
                    handlePageChange={handlePageChange}
                    activePage={activePage}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Planning;
