import {
    RELEASE_SELECTION_SET,
    ISRC_SELECTION_SET,
    RELEASE_HIGHLIGHT_SET,
    ISRC_HIGHLIGHT_SET,
} from "./AlbumView.constants";

export const AlbumViewReducer = (state, action) => {
    switch (action.type) {
        case RELEASE_SELECTION_SET:
            return {
                ...state,
                profileSelection: {
                    releaseId: action.data,
                    isrc: null,
                },
            };
        case ISRC_SELECTION_SET:
            return {
                ...state,
                profileSelection: {
                    releaseId: null,
                    isrc: action.data,
                },
            };
        case RELEASE_HIGHLIGHT_SET:
            return {
                ...state,
                profileHighlights: {
                    releases: action.data,
                    isrcs: [],
                },
            };
        case ISRC_HIGHLIGHT_SET:
            return {
                ...state,
                profileHighlights: {
                    releases: [],
                    isrcs: action.data,
                },
            };
    }
};
