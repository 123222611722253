import React from "react";
import useUser from "shared/hooks/useUser";
import RatingLine from "./RatingLine";

const EnhancedRatingLine = (props) => {
    const { user } = useUser();
    return <RatingLine user={user} {...props} />;
};

export default EnhancedRatingLine;
