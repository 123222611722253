import React, { useState, useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import Review from "./Review";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import RichTextEditor from "react-rte";
import {
    DiscographyContext,
    ALBUM_HAS_REVIEW,
} from "pages/ArtistManagement/Discography";

const EnhancedReview = (props) => {
    const [review, setReview] = useState(RichTextEditor.createEmptyValue());
    const [author, setAuthor] = useState();
    const { api } = useAPI();
    const [authorOptions, setAuthorOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { albumId, locale } = useParams();
    const location = useLocation();
    const { dispatchDiscography } = useContext(DiscographyContext);

    const initForm = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}/review`)
                .then(({ authors, ...response }) => {
                    if (response.review) {
                        setReview(
                            RichTextEditor.createValueFromString(
                                response.review,
                                "html"
                            )
                        );
                    } else {
                        setReview(RichTextEditor.createEmptyValue());
                    }
                    dispatchDiscography({
                        type: ALBUM_HAS_REVIEW,
                        data: !!response.review,
                    });
                    if (authors) {
                        setAuthor(
                            authors.find(({ principal }) => principal)?.id
                        );
                        setAuthorOptions(
                            authors.map(
                                ({ id, fullname, signature, updated_at }) => {
                                    return {
                                        label: `${fullname} - ${signature}${
                                            updated_at ? " - " + updated_at : ""
                                        }`,
                                        value: id,
                                    };
                                }
                            )
                        );
                    }
                    setErrors({});
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        }
    }, [albumId]);

    useEffect(initForm, [initForm]);

    const onSubmit = () => {
        let processedErrors = {};
        const convertedReview = review.toString("html");

        /* RichTextEditor return this string when empty value is converted with html */
        if (convertedReview !== "<p><br></p>" && !author) {
            processedErrors = {
                author: {
                    message: "utils.form.required",
                },
            };
        }

        setErrors(processedErrors);

        if (Object.values(processedErrors).length === 0) {
            setIsSubmitting(true);
            api.put(
                `album/${albumId}/review`,
                { locale },
                {
                    review:
                        convertedReview === "<p><br></p>"
                            ? null
                            : convertedReview,
                    author,
                }
            )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.album.review.update.success`
                        )
                    );
                    dispatchDiscography({
                        type: ALBUM_HAS_REVIEW,
                        data: convertedReview !== "<p><br></p>",
                    });
                    setIsSubmitting(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.album.review.update.error`,
                            error.message
                        )
                    );
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <Review
            review={review}
            setReview={setReview}
            author={author}
            setAuthor={setAuthor}
            authorOptions={authorOptions}
            errors={errors}
            initForm={initForm}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            location={location}
            {...props}
        />
    );
};

export default EnhancedReview;
