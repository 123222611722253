import React from "react";
import CompletedFilter from "./CompletedFilter";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const EnhancedCompletedFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.completed.true"
            ),
            value: true,
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.completed.false"
            ),
            value: false,
        },
    ];

    return <CompletedFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedCompletedFilter;
