import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Filters from "./Filters";

const CNMTitleList = ({
    rows,
    platforms,
    columns,
    pagination,
    defaultSorted,
    selectRow,
    rowClasses,
    isLoading,
    filter,
    setFilter,
    location,
}) => {
    return (
        <ToolkitProvider keyField="id" data={rows} columns={columns} search>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title="cnm.aggregation.cnmTitleList.head"
                            className="vertical-shrink pb-0"
                            isLoading={isLoading}
                        >
                            <Widget.Body>
                                <div className="form-row mb-3">
                                    <div className="col">
                                        <Search.SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "cnm.aggregation.cnmTitleList.search"
                                            )}
                                        />
                                    </div>
                                    <Filters
                                        platforms={platforms}
                                        filter={filter}
                                        setFilter={setFilter}
                                    />
                                </div>
                            </Widget.Body>
                            <Widget.Body className="widget-table-overflow overflow-auto mb-0">
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    bootstrap4
                                    striped
                                    hover
                                    noDataIndication={
                                        <I18n t="cnm.aggregation.cnmTitleList.empty" />
                                    }
                                    classes="table-header-fixed table-layout-auto table-selectable"
                                    bordered={false}
                                />
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-2 mb-2 mr-0">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={rows.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default CNMTitleList;
