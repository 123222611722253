export const status = {
    CREATION: {
        translation: "plannings.client.tasks.status.creation",
        color: "success",
    },
    UPDATE: {
        translation: "plannings.client.tasks.status.update",
        color: "primary",
    },
    NEW: {
        translation: "plannings.client.tasks.status.new",
        color: "success",
    },
    PROGRESS: {
        translation: "plannings.client.tasks.status.progress",
        color: "info",
    },
    EXIST: {
        translation: "plannings.client.tasks.status.exist",
        color: "secondary",
    },
    MISSING: {
        translation: "plannings.client.tasks.status.missing",
        color: "secondary",
    },
};
