import { createSelector } from "reselect";

const getLyricsFilter = (state) => state.filter;
const getLyrics = (state) => state.lyrics;

export const getLyricsFiltered = createSelector(
    [getLyricsFilter, getLyrics],
    (lyricsFilter, lyrics) => {
        let newLyrics = lyrics;

        if (lyricsFilter.catalog_type) {
            newLyrics = newLyrics.filter(
                (c) => c.catalog_type === lyricsFilter.catalog_type
            );
        }

        if (lyricsFilter.language) {
            newLyrics = newLyrics.filter(
                (c) => c.language === lyricsFilter.language
            );
        }

        if (lyricsFilter.assessment !== null) {
            newLyrics = newLyrics.filter(
                (c) => c.has_assessment === lyricsFilter.assessment
            );
        }

        if (lyricsFilter.counter_assessment !== null) {
            newLyrics = newLyrics.filter(
                (c) =>
                    c.has_counter_assessment === lyricsFilter.counter_assessment
            );
        }

        if (lyricsFilter.nb_line !== null) {
            newLyrics = newLyrics.slice(0, lyricsFilter.nb_line);
        }

        return newLyrics;
    }
);

export const getSelectCatalogTypeOptions = createSelector(
    [getLyricsFiltered],
    (lyrics) => {
        let res = [];

        if (lyrics.length === 0) {
            return res;
        }

        lyrics.forEach(({ catalog_type }) => {
            if (catalog_type) {
                res.push({ value: catalog_type, label: catalog_type });
            }
        });

        return res.filter(
            (row, index, self) =>
                self.findIndex(
                    (t) => t.value === row.value && t.name === row.name
                ) === index
        );
    }
);

export const getSelectLanguageOptions = createSelector(
    [getLyricsFiltered],
    (lyrics) => {
        let res = [];

        if (lyrics.length === 0) {
            return res;
        }

        lyrics.forEach(({ language }) => {
            if (language) {
                res.push({ value: language, label: language });
            }
        });

        return res.filter(
            (row, index, self) =>
                self.findIndex(
                    (t) => t.value === row.value && t.name === row.name
                ) === index
        );
    }
);
