import React from "react";
import List from "./List";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import {
    SIMPLE,
    CREATION,
    DISCARDED,
} from "pages/DataExternalInternationalsCharts/Coverage/Coverage.constants";

const EnhancedList = ({ id, title, exportName, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();

    const columns = [
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.title"
            ),
            formatter: (cell, { id_type, id_top }) => {
                return (
                    <a
                        href={`/${locale}/matching/tops/${id_type}/${id_top}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {cell}
                    </a>
                );
            },
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.country"
            ),
        },
        {
            dataField: "area",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.area"
            ),
        },
        {
            dataField: "date",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.date"
            ),
            formatter: (cell, { start_date, end_date }) =>
                `${start_date} - ${end_date}`,
            filterValue: (cell, { start_date, end_date }) => [
                start_date,
                end_date,
            ],
        },
        {
            dataField: "rank",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.rank"
            ),
            searchable: false,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.artist"
            ),
        },
        {
            dataField: "album_track",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.coverage.album_track"
            ),
            formatter: (cell, { album, track }) => album ?? track ?? "-",
        },
    ];

    switch (id) {
        case SIMPLE:
        case CREATION:
        case DISCARDED:
            columns.push({
                dataField: "ms_artist",
                text: I18n.getTranslation(
                    location,
                    "data_external.internationals_charts.coverage.ms_artist"
                ),
                formatter: (cell) => {
                    return cell ? (
                        <ArtistLink
                            artistId={cell.id}
                            content={cell.name}
                            blank
                        />
                    ) : (
                        "-"
                    );
                },
                filterValue: (cell) => cell?.name,
            });
            break;
    }

    switch (id) {
        case CREATION:
        case DISCARDED:
            columns.push({
                dataField: "comment",
                text: I18n.getTranslation(
                    location,
                    "data_external.internationals_charts.coverage.comment"
                ),
                formatter: (cell) => {
                    return cell ? (
                        <span className="pre-wrap">{cell}</span>
                    ) : (
                        "-"
                    );
                },
                searchable: false,
            });
    }

    switch (id) {
        case CREATION:
            columns.push({
                dataField: "user_creation",
                text: I18n.getTranslation(
                    location,
                    "data_external.internationals_charts.coverage.user"
                ),
            });
            break;
        case DISCARDED:
            columns.push({
                dataField: "user_discarded",
                text: I18n.getTranslation(
                    location,
                    "data_external.internationals_charts.coverage.user"
                ),
            });
            break;
    }

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <List
            {...props}
            title={title}
            exportName={`${exportName}_${I18n.getTranslation(location, title)}`}
            columns={columns}
            options={options}
        />
    );
};

export default EnhancedList;
