import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import CNMCharts from "./CNMCharts";
import useUser from "shared/hooks/useUser";

const EnhancedCNMCharts = (props) => {
    const { type, locale } = useParams();
    const [selected, setSelected] = useState();
    const navigate = useNavigate();
    const { user } = useUser();
    const location = useLocation();

    useEffect(() => {
        setSelected(type);
    }, [type]);

    return (
        <CNMCharts
            selected={selected}
            locale={locale}
            navigate={navigate}
            user={user}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCNMCharts;
