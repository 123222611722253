import React, { useContext } from "react";

const defaultValues = { lock: false, setLock: () => {} };

const PlanningsManagementContext = React.createContext(defaultValues);

export const usePlanningsManagementContext = () =>
    useContext(PlanningsManagementContext);

export default PlanningsManagementContext;
