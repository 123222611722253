import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const SearchForm = ({ onSubmit, register, location }) => {
    return (
        <form onSubmit={onSubmit}>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder={I18n.getTranslation(
                        location,
                        "utils.select.placeholder"
                    )}
                    {...register("query")}
                    required
                />
                <div className="input-group-append">
                    <Button type="submit">
                        <I18n t="utils.button.search" />
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
