import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const DetailButton = ({ onAdd, gettyPicture }) => {
    return (
        <button
            className={clsx("btn bg-transparent border-0 w-100 h-100")}
            onClick={() => onAdd(gettyPicture)}
        >
            <span className="text-white">
                <FontAwesomeIcon icon={faEye} size="2x" fixedWidth />
            </span>
        </button>
    );
};

export default DetailButton;
