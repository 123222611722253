import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useQuery from "shared/hooks/useQuery";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";

const schema = yup.object().shape(
    {
        msTrackIds: yup
            .string()
            .trim()
            .when(["msArtistIds"], {
                is: (val1) => val1 === "",
                then: () => yup.string().required("utils.form.required"),
            }),
        msArtistIds: yup.string().trim(),
        yearRange: yup
            .number()
            .typeError("utils.form.required")
            .positive("recommendation.form.positive"),
        minAccuracy: yup
            .number()
            .typeError("utils.form.required")
            .min(0, "recommendation.form.min")
            .max(100, "recommendation.form.max"),
    }
);

const EnhancedSearchForm = ({ onSearch, ...props }) => {
    const { api } = useAPI();
    const query = useQuery();
    const msTrackIds = query.get("msTrackIds");
    const msArtistIds = query.get("msArtistIds");
    const faBpm = query.get("faBpm");
    const faArousal = query.get("faArousal");
    const faValence = query.get("faValence");
    const faIntensity = query.get("faIntensity");
    const faMoods = query.get("faMoods");
    const yearRange = query.get("yearRange");
    const minAccuracy = query.get("minAccuracy");

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            msTrackIds: msTrackIds || "",
            msArtistIds: msArtistIds || "",
            faBpm: faBpm || "",
            faArousal: faArousal || "",
            faValence: faValence || "",
            faIntensity: faIntensity || "",
            faMoods: faMoods || [],
            yearRange: yearRange || 5,
            minAccuracy: minAccuracy || 32,
        },
    });

    const onSubmit = handleSubmit((data) =>
        onSearch(
            data.msTrackIds,
            data.msArtistIds,
            data.faBpm,
            data.faArousal,
            data.faValence,
            data.faIntensity,
            data.faMoods,
            data.yearRange,
            data.minAccuracy
        )
    );

    useEffect(() => {
        if (msTrackIds || msArtistIds) onSubmit();
    }, [msTrackIds, msArtistIds]);

    const selectOptions = [
        {
            value: 1,
            label: I18n.getTranslation(location, "recommendation.form.select_options.low"),
        },
        {
            value: 2,
            label: I18n.getTranslation(location, "recommendation.form.select_options.medium"),
        },
        {
            value: 3,
            label: I18n.getTranslation(location, "recommendation.form.select_options.high"),
        },
    ];

    const selectOptionsBpm = [
        {
            value: 1,
            label: I18n.getTranslation(location, "recommendation.form.select_options_bpm.low"),
        },
        {
            value: 2,
            label: I18n.getTranslation(location, "recommendation.form.select_options_bpm.medium"),
        },
        {
            value: 3,
            label: I18n.getTranslation(location, "recommendation.form.select_options_bpm.high"),
        },
    ];

    const [moodOptions, setMoodOptions] = useState([]);
    const getMood = useCallback(() => {
        api.get(`mood`)
            .then((response) => {
                setMoodOptions(response.map( ({ mood }) => {return {value:mood, label:mood} } ));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);
    useEffect(getMood, [getMood]);

    return (
        <SearchForm
            onSubmit={onSubmit}
            register={register}
            control={control}
            errors={errors}
            selectOptions={selectOptions}
            selectOptionsBpm={selectOptionsBpm}
            moodOptions={moodOptions}
            {...props}
        />
    );
};

export default EnhancedSearchForm;
