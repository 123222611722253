import React from "react";
import { Geography, Marker } from "react-simple-maps";

const Country = ({
    geography,
    charts,
    score,
    hovered,
    markerRef,
    onMouseEnter,
}) => {
    const color =
        charts === 0
            ? "#ddd"
            : score === 0
            ? "rgb(244, 232, 252)"
            : `rgb(${244 - 99 * 0.01 * score}, ${232 - 204 * 0.01 * score}, ${
                  252 - 27 * 0.01 * score
              })`;

    return (
        <>
            <Geography
                geography={geography}
                fill={color}
                stroke={"#ffffff"}
                strokeWidth={hovered ? 0.8 : 0.3}
                onMouseEnter={() => onMouseEnter(markerRef.current)}
            />
            <Marker
                coordinates={[
                    geography.properties.longitude,
                    geography.properties.latitude,
                ]}
            >
                <circle r={1} visibility="hidden" ref={markerRef} />
            </Marker>
        </>
    );
};

export default Country;
