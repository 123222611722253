import React, { useState, useEffect, useCallback } from "react";
import Curation from "./Curation";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { useParams } from "react-router";

const defaultState = {
    isLoading: false,
    pictures: [],
};

const EnhancedCuration = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const { locale } = useParams();

    const getPictures = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/picture/curation`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        pictures: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getPictures, [getPictures]);

    const [selectedPicture, setSelectedPicture] = useState({
        pictureId: null,
        versionId: null,
    });

    const [currentTab, setCurrentTab] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const onAdd = () => {
        setSelectedPicture({
            pictureId: null,
            versionId: null,
        });
        setIsEdit(false);
        setCurrentTab("Upload");
    };

    const onEdit = (picture) => {
        setSelectedPicture({
            pictureId: picture.id_picture,
            versionId: picture.id_version,
        });
        setIsEdit(true);
        setCurrentTab("Legal");
    };

    return (
        <Curation
            {...props}
            {...state}
            selectedPicture={selectedPicture}
            setSelectedPicture={setSelectedPicture}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onEdit={onEdit}
            onAdd={onAdd}
            getPictures={getPictures}
            artistId={artistId}
            isEdit={isEdit}
        />
    );
};

export default EnhancedCuration;
