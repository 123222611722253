import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import TextareaSwalButton from "shared/components/TextareaSwalButton";

const MarkAction = ({ disabled, onMark, location }) => {
    return (
        <TextareaSwalButton
            className="btn btn-primary"
            title={I18n.getTranslation(
                location,
                "matching.artist.artist.mark.button.title"
            )}
            disabled={disabled}
            swalOptions={{
                title: I18n.getTranslation(
                    location,
                    "matching.artist.artist.mark.modal.title"
                ),
                text: I18n.getTranslation(
                    location,
                    "matching.artist.artist.mark.modal.text"
                ),
            }}
            placeholder={I18n.getTranslation(
                location,
                "matching.artist.artist.mark.modal.placeholder"
            )}
            onConfirm={onMark}
            required
        >
            <FontAwesomeIcon icon={faThumbsDown} size="lg" fixedWidth />
        </TextareaSwalButton>
    );
};

export default MarkAction;
