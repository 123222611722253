import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import SpotifyTrackMatcher from "./SpotifyTrackMatcher";

const EnhancedSpotifyTrackMatcher = ({
    listId,
    rowId,
    defaultSearch,
    onChange,
    ...props
}) => {
    const { api } = useAPI();

    const loadOptions = useDebouncedCallback((term, callback) => {
        api.get("matching/track/sp/search", { term: term })
            .then((response) => {
                callback(
                    response.map(({ id, name, album, artists }) => {
                        const artistNames = artists
                            .map(({ name }) => name)
                            .join(", ");
                        return {
                            value: { id, id_album: album.id },
                            label: `${name} - ${album.name} - ${artistNames} - ${album.release_date} (${id})`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onMatch = ({ id, id_album }) => {
        api.post(
            `matching/track/snep/list/${listId}/${rowId}/spotify/${id}`,
            {},
            { id_album }
        )
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.match.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.match.error",
                        error.message
                    )
                );
            });
    };

    const onUnmatch = () => {
        api.delete(`matching/track/snep/list/${listId}/${rowId}/spotify`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.unmatch.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.unmatch.error",
                        error.message
                    )
                );
            });
    };

    const onSelectChange = (opt, action) => {
        if (action.action === "select-option") {
            onMatch(opt.value);
        } else {
            onUnmatch();
        }
    };

    return (
        <SpotifyTrackMatcher
            defaultSearch={defaultSearch}
            loadOptions={loadOptions}
            onChange={onSelectChange}
            {...props}
        />
    );
};

export default EnhancedSpotifyTrackMatcher;
