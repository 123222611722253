import React from "react";
import { useLocation } from "react-router-dom";
import useIntParams from "shared/hooks/useIntParams";
import Picture from "./Picture";

const EnhancedPicture = (props) => {
    const location = useLocation();
    const { artistId } = useIntParams();

    return <Picture {...props} artistId={artistId} location={location} />;
};

export default EnhancedPicture;
