import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import YearSelect from "./YearSelect";
import CNMTitleList from "./CNMTitleList";
import Search from "./Search";
import PlatformTitleList from "./PlatformTitleList";
import CreateButton from "./CreateButton";
import { Helmet } from "react-helmet";
import "./CNMAggregation.css";

const CNMAggregation = ({
    cnmTitles,
    search,
    platformTitles,
    similarPlatformTitles,
    defaultSearch,
    selectedPlatformTitles,
    onMoveLeft,
    onMoveRight,
    onCreate,
    onCheck,
    onDiscard,
    location,
}) => {
    return (
        <div className="content-wrap cnm-aggregation">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.aggregation"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb d-flex align-items-center">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.title" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.aggregation" />
                    </li>
                    <li className="breadcrumb-item active d-flex align-items-center">
                        <YearSelect />
                    </li>
                </ol>

                <CNMTitleList
                    {...cnmTitles}
                    onCheck={onCheck}
                    onDiscard={onDiscard}
                />
                <Search {...search} defaultSearch={defaultSearch} />

                <Widget
                    title="cnm.aggregation.platformTitleList.head"
                    className="grid-bottom mb-0"
                    isLoading={
                        platformTitles.isLoading ||
                        similarPlatformTitles.isLoading
                    }
                    actions={
                        <CreateButton
                            selectedPlatformTitles={selectedPlatformTitles}
                            onSuccess={onCreate}
                        />
                    }
                >
                    <Widget.Body className="widget-table-overflow">
                        <div className="row">
                            <div
                                className="col-6 overflow-auto pr-0"
                                style={{ maxHeight: "250px" }}
                            >
                                <PlatformTitleList
                                    {...platformTitles}
                                    onMoveRight={onMoveRight}
                                />
                            </div>
                            <div
                                className="col-6 overflow-auto border-left pl-0"
                                style={{ maxHeight: "250px" }}
                            >
                                <PlatformTitleList
                                    {...similarPlatformTitles}
                                    onMoveLeft={onMoveLeft}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                </Widget>
            </main>
        </div>
    );
};

export default CNMAggregation;
