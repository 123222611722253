import React, { useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import Tracks from "./Tracks";
import { toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeInteger,
    normalizeISRC,
    normalizeTitle,
} from "shared/functions/normalize";
import {
    DiscographyContext,
    TRACKS_LOAD_START,
    TRACKS_SET,
} from "pages/ArtistManagement/Discography";

const EnhancedTracks = (props) => {
    const { dispatchDiscography, tracksLoading, tracks } =
        useContext(DiscographyContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { api } = useAPI();
    const { selectedTab, artistId, albumId, releaseId, trackId, locale } =
        useParams();

    const getTracks = useCallback(() => {
        if (releaseId) {
            dispatchDiscography({ type: TRACKS_LOAD_START });
            api.get(`release/${releaseId}/tracks`, { locale })
                .then((response) => {
                    dispatchDiscography({ type: TRACKS_SET, data: response });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    dispatchDiscography({ type: TRACKS_SET, data: [] });
                });
        } else {
            dispatchDiscography({ type: TRACKS_SET, data: [] });
        }
    }, [releaseId, dispatchDiscography]);

    useEffect(getTracks, [getTracks]);

    const columns = [
        {
            dataField: "no_cd",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.no_cd.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.tracks.no_cd.title"
                ),
            searchable: false,
            title: true,
            headerStyle: { width: "2em" },
        },
        {
            dataField: "no_piste",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.no_piste.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.tracks.no_piste.title"
                ),
            sortValue: (cell, row) => ({
                disc: row.no_cd,
                track: row.no_piste,
            }),
            sortFunc: (a, b, order, dataField) =>
                a.disc === b.disc
                    ? (order === "asc" ? 1 : -1) * (a.track - b.track)
                    : (order === "asc" ? 1 : -1) * (a.disc - b.disc),
            sort: true,
            title: true,
            searchable: false,
            headerStyle: { width: "3em" },
        },
        {
            dataField: "id_recording",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.id"
            ),
            sort: true,
            title: true,
            headerStyle: { width: "5.3em" },
            filterValue: normalizeInteger,
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.isrc"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "70%" },
            filterValue: normalizeISRC,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.title"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "100%" },
            filterValue: normalizeTitle,
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.subtitle.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.tracks.subtitle.title"
                ),
            searchable: false,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "60%" },
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.tracks.duration.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.tracks.duration.title"
                ),
            searchable: false,
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            headerStyle: { width: "5em" },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}/track/${row.id_track}/recording/${row.id_recording}`
                );
            }
        },
        selected: trackId ? [trackId] : [],
    };

    const defaultSorted = [
        {
            dataField: "no_piste",
            order: "asc",
        },
    ];

    return (
        <Tracks
            {...props}
            tracks={tracks}
            columns={columns}
            isLoading={tracksLoading}
            selectRow={selectRow}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
        />
    );
};

export default EnhancedTracks;
