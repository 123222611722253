import React, { useState, useEffect, useCallback } from "react";
import Stats from "./Stats";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedStats = ({ startDate, endDate, ...props }) => {
    const [stats, setStats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { api } = useAPI();

    const exportName = `${startDate}-${endDate}_${I18n.getTranslation(
        location,
        "data_external.universal_artist.breadcrumb"
    )}_${I18n.getTranslation(
        location,
        "data_external.universal_artist.stats.nav"
    )}`;

    const getStats = useCallback(() => {
        setIsLoading(true);
        api.get(`data/external/universal/artist/stats/${startDate}/${endDate}`)
            .then((response) => {
                setStats(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    useEffect(getStats, [getStats]);

    const columns = [
        {
            dataField: "date",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.date"
            ),
        },
        {
            dataField: "total",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.total"
            ),
        },
        {
            dataField: "matched_total",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.matched_total"
            ),
        },
        {
            dataField: "matched_simple",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.matched_simple"
            ),
        },
        {
            dataField: "matched_creation",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.matched_creation"
            ),
        },
        {
            dataField: "discarded",
            text: I18n.getTranslation(
                location,
                "data_external.universal_artist.stats.discarded"
            ),
        },
    ];

    return (
        <Stats
            {...props}
            stats={stats}
            columns={columns}
            isLoading={isLoading}
            exportName={exportName}
        />
    );
};

export default EnhancedStats;
