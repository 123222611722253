import React from "react";
import clsx from "clsx";

const RecordingLink = ({
    recordingId,
    content,
    part,
    locale,
    className,
    blank,
}) => {
    if (!recordingId) {
        return content;
    }

    const href =
        "/" +
        [locale, "recording", recordingId, "view", part]
            .filter(Boolean)
            .join("/");

    if (blank) {
        return (
            <a
                href={href}
                className={clsx(className)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {content}
            </a>
        );
    } else {
        return (
            <a href={href} className={clsx(className)}>
                {content}
            </a>
        );
    }
};

export default RecordingLink;
