import React from "react";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";
import "./AgeType.css";

const AgeType = ({ type, change, location }) => {
    return (
        <InputText
            value={
                type
                    ? I18n.getTranslation(
                          location,
                          `cnm.report.consolidate.edit.ageType.${type}`
                      )
                    : ""
            }
            readOnly
            className={change !== null ? "age-type-warning" : undefined}
            title={
                change !== null
                    ? I18n.getTranslation(
                          location,
                          "cnm.report.consolidate.edit.ageType.warning",
                          {
                              from: I18n.getTranslation(
                                  location,
                                  `cnm.report.consolidate.edit.ageType.${change.from}`
                              ),
                              to: I18n.getTranslation(
                                  location,
                                  `cnm.report.consolidate.edit.ageType.${change.to}`
                              ),
                              date: change.date.format("YYYY-MM-DD"),
                          }
                      )
                    : undefined
            }
        />
    );
};

export default AgeType;
