import React, { useState } from "react";
import ArtistCreate from "./ArtistCreate";
import { useLocation } from "react-router";

const EnhancedArtistCreate = (props) => {
    const [state, setState] = useState({
        artists: [],
        isLoading: false,
        hasSearch: false,
    });
    const location = useLocation();

    return (
        <ArtistCreate
            {...props}
            {...state}
            setState={setState}
            location={location}
        />
    );
};

export default EnhancedArtistCreate;
