import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import InputSwitch from "shared/components/Form/InputSwitch";
import { CHARTS_MODE } from "shared/constants/Charts";
import { getFilteredCharts, getFilterOptions } from "./ChartsList.selector";
import ChartsList from "./ChartsList";

const EnhancedChartsList = ({
    categoryId,
    chartId,
    language,
    onSelect,
    mode,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [{ isLoading, charts }, setState] = useState({
        isLoading: true,
        charts: [],
    });
    const [filter, setFilter] = useState({
        year: null,
        month: null,
    });

    const getCharts = useCallback(() => {
        if (categoryId === undefined) {
            setState({ isLoading: false, charts: [] });
            return;
        }

        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/top/list/${categoryId}`, {
            locale,
            language,
        })
            .then((response) => {
                setState({ isLoading: false, charts: response });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState({ isLoading: false, charts: [] });
            });
    }, [categoryId, locale]);

    useEffect(getCharts, [getCharts]);

    const onChangeUserData = (id, value) => {
        const apiRequest = value
            ? api.post(`matching/top/user/data/${id}`, { locale })
            : api.delete(`matching/top/user/data/${id}`, { locale });

        apiRequest
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    charts: prev.charts.map((row) =>
                        row.id === id ? { ...row, userData: value } : row
                    ),
                }));
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const columns = [
        {
            dataField: "startDate",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.startDate"
            ),
        },
        {
            dataField: "endDate",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.endDate"
            ),
        },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "dataMatched",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.dataMatched"
            ),
            formatter: (cell, { treatment }) => `${cell} / ${treatment}`,
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.DISPLAY) && {
            dataField: "matched",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.matched"
            ),
            formatter: (cell, { total }) => `${cell} / ${total}`,
        },
        mode === CHARTS_MODE.TRANSLATION && {
            dataField: "translated",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.translated"
            ),
            formatter: (cell, { total }) => `${cell} / ${total}`,
        },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "userData",
            text: I18n.getTranslation(
                location,
                "common.charts.chartsList.userData"
            ),
            formatter: (cell, { id }) => (
                <InputSwitch
                    name="user_data"
                    onChange={(e) => onChangeUserData(id, e.target.checked)}
                    checked={cell}
                />
            ),
        },
    ].filter(Boolean);

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                onSelect(row.id);
            }
        },
        selected: [chartId],
    };

    return (
        <ChartsList
            isLoading={isLoading}
            columns={columns}
            selectRow={selectRow}
            filterOptions={getFilterOptions({ charts })}
            filter={filter}
            setFilter={setFilter}
            charts={getFilteredCharts({ charts, filter })}
            {...props}
        />
    );
};

export default EnhancedChartsList;
