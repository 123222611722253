import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const Loader = ({ className, size, error }) => {
    return (
        <div className={clsx("row", className)} style={{ margin: "0" }}>
            <div className="col-md-12 text-md-center">
                <FontAwesomeIcon
                    icon={error ? faExclamationCircle : faSpinner}
                    pulse={!error}
                    size={size ?? "2x"}
                />
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Loader;
