import { XmlToJson } from "shared/functions/XmlToJson";
import moment from "moment";

export const formatDuration = (duration) => {
    if (duration !== undefined && duration !== null) {
        const momentDuration = moment.duration(duration, "seconds");
        return `${String(momentDuration.minutes()).padStart(2, "0")}:${String(
            momentDuration.seconds()
        ).padStart(2, "0")}`;
    }
    return "-";
};

export const convertToSeconds = (duration) => {
    switch (String(duration).split(":").length) {
        case 1:
            return duration;
        case 2:
            return moment
                .duration({
                    seconds: String(duration).split(":").pop(),
                    minutes: String(duration).split(":").shift(),
                })
                .asSeconds();
        case 3:
            return moment
                .duration({
                    seconds: String(duration).split(":")[2],
                    minutes: String(duration).split(":")[1],
                    hours: String(duration).split(":")[0],
                })
                .asSeconds();
        default:
            return 0;
    }
};

export const convertLyrics = (lyric) => {
    const jsonLyric = new XmlToJson(lyric).tt;

    if (jsonLyric?.body && jsonLyric.body?.div) {
        const divLyric = jsonLyric.body.div;
        let arrayLyric = [];
        let lineNumber = 1;

        if (Array.isArray(divLyric)) {
            divLyric.map(({ p }, divKey) => {
                if (Array.isArray(p)) {
                    p.map((pRow, pKey) => {
                        if (typeof pRow === "object") {
                            if (Array.isArray(pRow.span)) {
                                arrayLyric.push({
                                    id: `${divKey}-${pKey}`,
                                    line: lineNumber,
                                    timing: pRow.begin !== undefined && {
                                        begin: convertToSeconds(
                                            String(pRow.begin)
                                                .split(".")
                                                .shift()
                                        ),
                                        end: convertToSeconds(
                                            String(pRow.end).split(".").shift()
                                        ),
                                    },
                                    text: pRow.span
                                        .map(({ text }) => text)
                                        .join(" "),
                                });
                            } else if (typeof pRow.span === "object") {
                                arrayLyric.push({
                                    id: `${divKey}-${pKey}`,
                                    line: lineNumber,
                                    timing: pRow.begin !== undefined && {
                                        begin: convertToSeconds(
                                            String(pRow.begin)
                                                .split(".")
                                                .shift()
                                        ),
                                        end: convertToSeconds(
                                            String(pRow.end).split(".").shift()
                                        ),
                                    },
                                    text: pRow.span.text,
                                });
                            } else {
                                arrayLyric.push({
                                    id: `${divKey}-${pKey}`,
                                    line: lineNumber,
                                    timing: pRow.begin !== undefined && {
                                        begin: convertToSeconds(
                                            String(pRow.begin)
                                                .split(".")
                                                .shift()
                                        ),
                                        end: convertToSeconds(
                                            String(pRow.end).split(".").shift()
                                        ),
                                    },
                                    text: pRow.text,
                                });
                            }
                        } else if (pRow) {
                            arrayLyric.push({
                                id: `${divKey}-${pKey}`,
                                line: lineNumber,
                                timing: null,
                                text: pRow,
                            });
                        }

                        lineNumber++;
                    });
                    if (divLyric.length - 1 > divKey) {
                        arrayLyric.push({
                            id: `${divKey}-divider`,
                            line: null,
                            timing: null,
                            text: null,
                        });
                    }
                } else if (typeof p === "object") {
                    if (Array.isArray(p.span)) {
                        arrayLyric.push({
                            id: `${divKey}`,
                            line: lineNumber,
                            timing: p.begin !== undefined && {
                                begin: convertToSeconds(
                                    String(p.begin).split(".").shift()
                                ),
                                end: convertToSeconds(
                                    String(p.end).split(".").shift()
                                ),
                            },
                            text: p.span.map(({ text }) => text).join(" "),
                        });
                    } else if (typeof p.span === "object") {
                        arrayLyric.push({
                            id: `${divKey}`,
                            line: lineNumber,
                            timing: p.begin !== undefined && {
                                begin: convertToSeconds(
                                    String(p.begin).split(".").shift()
                                ),
                                end: convertToSeconds(
                                    String(p.end).split(".").shift()
                                ),
                            },
                            text: p.span.text,
                        });
                    } else {
                        arrayLyric.push({
                            id: `${divKey}`,
                            line: lineNumber,
                            timing: p.begin !== undefined && {
                                begin: convertToSeconds(
                                    String(p.begin).split(".").shift()
                                ),
                                end: convertToSeconds(
                                    String(p.end).split(".").shift()
                                ),
                            },
                            text: p.text,
                        });
                    }
                    lineNumber++;
                    if (divLyric.length - 1 > divKey) {
                        arrayLyric.push({
                            id: `${divKey}-divider`,
                            line: null,
                            timing: null,
                            text: null,
                        });
                    }
                } else if (p) {
                    arrayLyric.push({
                        id: `${divKey}`,
                        line: lineNumber,
                        timing: null,
                        text: p,
                    });
                    lineNumber++;
                    if (divLyric.length - 1 > divKey) {
                        arrayLyric.push({
                            id: `${divKey}-divider`,
                            line: null,
                            timing: null,
                            text: null,
                        });
                    }
                }
            });
        } else if (typeof divLyric === "object") {
            if (Array.isArray(divLyric.p)) {
                divLyric.p.map((p, divKey) => {
                    if (Array.isArray(p)) {
                        p.map((pRow, pKey) => {
                            arrayLyric.push({
                                id: `${divKey}-${pKey}`,
                                line: lineNumber,
                                timing: pRow.begin !== undefined && {
                                    begin: convertToSeconds(
                                        String(pRow.begin).split(".").shift()
                                    ),
                                    end: convertToSeconds(
                                        String(pRow.end).split(".").shift()
                                    ),
                                },
                                text: pRow.text,
                            });
                            lineNumber++;
                        });
                    } else if (typeof p === "object") {
                        arrayLyric.push({
                            id: `${divKey}`,
                            line: lineNumber,
                            timing: p.begin !== undefined && {
                                begin: convertToSeconds(
                                    String(p.begin).split(".").shift()
                                ),
                                end: convertToSeconds(
                                    String(p.end).split(".").shift()
                                ),
                            },
                            text: p.text,
                        });
                        lineNumber++;
                    } else if (p) {
                        arrayLyric.push({
                            id: `${divKey}`,
                            line: lineNumber,
                            timing: null,
                            text: p,
                        });
                        lineNumber++;
                    }
                });
            } else if (typeof divLyric.p === "object") {
                arrayLyric.push({
                    id: `0`,
                    line: lineNumber,
                    timing: null,
                    text: divLyric.p.text,
                });
            } else if (divLyric.p) {
                arrayLyric.push({
                    id: `0`,
                    line: lineNumber,
                    timing: null,
                    text: divLyric.p,
                });
            }
        } else {
            divLyric.p.map((text, pKey) => {
                arrayLyric.push({
                    id: `${pKey}`,
                    line: lineNumber,
                    timing: null,
                    text: text,
                });
                lineNumber++;
            });
        }

        return arrayLyric;
    }

    return [];
};
