import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import DiscardButton from "./DiscardButton";

const EnhancedDiscardButton = ({
    entryId,
    rowId,
    locale,
    onChange,
    ...props
}) => {
    const { api } = useAPI();

    const onRecover = () => {
        api.delete(`matching/top/discarded/${entryId}`, { locale })
            .then((response) => {
                onChange({ discarded: false, discardedComment: null });
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onDiscard = (comment) => {
        api.post(
            `matching/top/discarded/${entryId}`,
            { locale },
            { rowId, comment }
        )
            .then((response) => {
                onChange({ discarded: true, discardedComment: comment });
                toast.success(response.message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <DiscardButton onRecover={onRecover} onDiscard={onDiscard} {...props} />
    );
};

export default EnhancedDiscardButton;
