import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import InputSelect from "shared/components/Form/InputSelect";
import Loader from "shared/components/Loader";
import { Controller } from "react-hook-form";
import I18n from "shared/lib/I18n";

const CopyMatchingsButton = ({
    show,
    onShow,
    onHide,
    errors,
    yearSrc,
    yearDst,
    yearSrcOptions,
    comparisonIsLoading,
    deletedGenres,
    newGenres,
    modifiedMatchings,
    control,
    onSubmit,
    isSubmitting,
    location,
}) => {
    return (
        <>
            <Button onClick={onShow} disabled={yearSrcOptions.length == 0}>
                <I18n t="cnm.repositories.cnm_genres.matchings.copyMatchings.button" />
            </Button>
            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.cnm_genres.matchings.copyMatchings.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        <Controller
                            control={control}
                            name="yearSrc"
                            render={({
                                field: { onChange, value, name, ref },
                            }) => (
                                <InputSelect
                                    innerRef={ref}
                                    name={name}
                                    options={yearSrcOptions}
                                    value={
                                        value !== null
                                            ? yearSrcOptions.find(
                                                  (opt) => opt.value == value
                                              )
                                            : null
                                    }
                                    onChange={(opt, action) =>
                                        onChange(
                                            action.action === "select-option"
                                                ? opt.value
                                                : null
                                        )
                                    }
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "cnm.repositories.cnm_genres.matchings.copyMatchings.genrePlaceholder"
                                    )}
                                    enablePortal
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    isClearable={false}
                                    error={
                                        errors.yearSrc && [
                                            I18n.getTranslation(
                                                location,
                                                errors.yearSrc.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                        {comparisonIsLoading ? (
                            <Loader className="mt-3" />
                        ) : (
                            (deletedGenres.length > 0 ||
                                newGenres.length > 0 ||
                                modifiedMatchings.length > 0) && (
                                <div className="alert alert-warning mt-3 mb-0">
                                    {deletedGenres.length > 0 && (
                                        <>
                                            <I18n
                                                t="cnm.repositories.cnm_genres.matchings.copyMatchings.deletedGenres"
                                                args={yearSrc}
                                            />
                                            <ul className="mb-0">
                                                {deletedGenres.map(
                                                    ({ id, name }) => (
                                                        <li key={id}>{name}</li>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {newGenres.length > 0 && (
                                        <>
                                            <I18n
                                                t="cnm.repositories.cnm_genres.matchings.copyMatchings.newGenres"
                                                args={yearDst}
                                            />
                                            <ul className="mb-0">
                                                {newGenres.map(
                                                    ({ id, name }) => (
                                                        <li key={id}>{name}</li>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {modifiedMatchings.length > 0 && (
                                        <>
                                            <I18n
                                                t="cnm.repositories.cnm_genres.matchings.copyMatchings.modifiedMatchings.title"
                                                args={{ yearSrc, yearDst }}
                                            />
                                            <ul className="mb-0">
                                                {modifiedMatchings.map(
                                                    ({
                                                        msId,
                                                        msName,
                                                        dstCNMName,
                                                        srcCNMName,
                                                    }) => (
                                                        <li key={msId}>
                                                            <I18n
                                                                t="cnm.repositories.cnm_genres.matchings.copyMatchings.modifiedMatchings.item"
                                                                args={{
                                                                    yearSrc,
                                                                    yearDst,
                                                                    msName,
                                                                    dstCNMName,
                                                                    srcCNMName,
                                                                }}
                                                            />
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            )
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting}
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        <I18n t="cnm.repositories.cnm_genres.matchings.copyMatchings.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CopyMatchingsButton;
