import React from "react";
import { useLocation, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import CNMReport from "./CNMReport";

const EnhancedCNMReport = (props) => {
    const { chartType } = useParams();
    const { year } = useIntParams();
    const location = useLocation();

    return (
        <CNMReport
            year={year}
            chartType={chartType}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCNMReport;
