import React, { useState, useEffect, useCallback } from "react";
import Role from "./Role";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";

const schema = yup.object().shape({
    role: yup
        .array()
        .of(
            yup.object().shape({
                id_role: yup.number().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedRole = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            role: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "role",
    });

    const getRole = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/role`)
            .then((response) => {
                if (!cancelled) {
                    reset({ role: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getRole, [getRole]);

    const [roleOptions, setRoleOptions] = useState([]);
    const getRoleOptions = useCallback(() => {
        api.get(`role/${locale}`)
            .then((response) => {
                setRoleOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getRoleOptions, [getRoleOptions]);

    const onSubmit = (data) => {
        return api
            .post(`artist/${artistId}/role`, { locale }, data.role)
            .then((response) => {
                reset({ role: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.role.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.role.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Role
            {...props}
            getRole={getRole}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
            roleOptions={roleOptions}
        />
    );
};

export default EnhancedRole;
