import React, { useState, useEffect, useCallback } from "react";
import Log from "./Log";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const defaultState = {
    isLoading: false,
    logs: [],
};

const EnhancedLog = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const location = useLocation();

    const getLog = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/tracking/log`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        logs: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getLog, [getLog]);

    const columns = [
        {
            dataField: "created_at",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingLog.date"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
            headerStyle: { width: "13rem" },
        },
        {
            dataField: "username",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingLog.username"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
            headerStyle: { width: "25rem" },
        },
        {
            dataField: "action",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingLog.action"
            ),
            sort: true,
            formatter: (cell) => (
                <I18n
                    t={`artist.management.trackingLog.${cell.replaceAll(
                        ".",
                        "_"
                    )}`}
                />
            ),
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return <Log {...props} {...state} columns={columns} options={options} />;
};

export default EnhancedLog;
