import React, { useState, useEffect, useCallback } from "react";
import BiographyGallery from "./BiographyGallery";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const defaultState = {
    biography: [],
    isLoading: true,
};

const EnhancedBiographyGallery = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);

    const getBiographyGallery = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`artist/${artistId}/biography`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    biography: response,
                }));
                if (response.length === 0) {
                    setNoData("biographyGallery");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getBiographyGallery()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getBiographyGallery]);

    return <BiographyGallery {...props} {...state} locale={locale} />;
};

export default EnhancedBiographyGallery;
