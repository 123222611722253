import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import CNMGenres from "./CNMGenres";

const EnhancedCNMGenres = ({ year, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: true,
        cnmGenres: [],
        msMainGenres: [],
        matchings: [],
        yearStatus: null,
        filters: {
            msMainGenre: null,
            cnmGenre: null,
        },
    });

    const editable =
        user.hasRight("cnm.repository.cnm_genres.manage") &&
        state.yearStatus === "IN_PROGRESS";

    const setFilters = (filters) => {
        setState((state) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }));
    };

    const fetchGenres = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            cnmGenres: [],
            msMainGenres: [],
            matchings: [],
            yearStatus: null,
            filters: {
                msMainGenre: null,
                cnmGenre: null,
            },
        });
        api.get(`cnm/repository/cnm_genres/${year}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        cnmGenres: response.cnmGenres,
                        msMainGenres: response.msMainGenres,
                        matchings: response.matchings,
                        yearStatus: response.yearStatus,
                        filters: {
                            msMainGenre: null,
                            cnmGenre: null,
                        },
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        cnmGenres: [],
                        msMainGenres: [],
                        matchings: [],
                        yearStatus: null,
                        filters: {
                            msMainGenre: null,
                            cnmGenre: null,
                        },
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year]);

    useEffect(fetchGenres, [fetchGenres]);

    const setMatchings = (msIds, cnmId) => {
        const request =
            cnmId === null
                ? api.delete(`cnm/repository/cnm_genres/matching/${year}`, {
                      locale,
                      ms_ids: msIds.join(","),
                  })
                : api.post(`cnm/repository/cnm_genres/matching/${cnmId}`, {
                      locale,
                      ms_ids: msIds.join(","),
                  });

        return request
            .then((response) => {
                const cnmName = state.cnmGenres.find(
                    ({ id }) => id == cnmId
                )?.name;

                setState(({ matchings, ...state }) => ({
                    ...state,
                    matchings: matchings.map((matching) =>
                        msIds.includes(matching.msId)
                            ? { ...matching, cnmId, cnmName }
                            : matching
                    ),
                }));
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.cnm_genres.matchingSuccess"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CNMGenres
            {...state}
            fetchGenres={fetchGenres}
            setMatchings={setMatchings}
            setFilters={setFilters}
            year={year}
            editable={editable}
            {...props}
        />
    );
};

export default EnhancedCNMGenres;
