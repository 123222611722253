import React, { useState, useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import Releases from "./Releases";
import { toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeInteger,
    normalizeBarcode,
} from "shared/functions/normalize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
    DiscographyContext,
    RELEASES_SET,
} from "pages/ArtistManagement/Discography";

const EnhancedReleases = (props) => {
    const { dispatchDiscography, releases } = useContext(DiscographyContext);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { api } = useAPI();
    const { selectedTab, artistId, albumId, releaseId, locale } = useParams();

    const getReleases = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}/releases`)
                .then((response) => {
                    dispatchDiscography({ type: RELEASES_SET, data: response });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else {
            dispatchDiscography({ type: RELEASES_SET, data: [] });
        }
    }, [albumId, dispatchDiscography]);

    useEffect(getReleases, [getReleases]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.releases.id"
            ),
            sort: true,
            title: true,
            headerStyle: { width: "5em" },
            filterValue: normalizeInteger,
        },
        {
            dataField: "barcode",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.releases.barcode.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.releases.barcode.title"
                ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "7.5em" },
            filterValue: normalizeBarcode,
        },
        {
            dataField: "commentary",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.releases.commentary"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
            sort: true,
            title: true,
            headerStyle: { width: "5em" },
        },
        {
            dataField: "support",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.releases.support"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
            sort: true,
            title: true,
            headerStyle: { width: "7em" },
        },
        {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.releases.release_date.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.releases.release_date.title"
                ),
            searchable: false,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "6em" },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${row.id}`
                );
            }
        },
        selected: releaseId ? [parseInt(releaseId, 10)] : [],
    };

    const defaultSorted = [
        {
            dataField: "main",
            order: "desc",
        },
    ];

    return (
        <Releases
            {...props}
            releases={releases}
            columns={columns}
            isLoading={isLoading}
            selectRow={selectRow}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
        />
    );
};

export default EnhancedReleases;
