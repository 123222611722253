import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import I18n from "shared/lib/I18n";
import {
    prefill,
    getDefaultLeadGender,
    getDefaultCountries,
} from "./Edit.pure";
import Edit from "./Edit";

const schema = yup.object().shape({
    title: yup.object().shape({
        title: yup.string().ensure().trim().required("utils.form.required"),
        publicationDate: yup
            .string()
            .trim()
            .transform((value) => (value !== "" ? value : null))
            .nullable()
            .test(
                "is-not-0",
                "utils.form.date_zero",
                (value) => value !== "0000-00-00"
            )
            .matches("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "utils.form.date_format"),
        discardReason: yup
            .string()
            .nullable()
            .when("discarded", {
                is: true,
                then: (schema) =>
                    schema.required(
                        "cnm.report.consolidate.edit.discardReason.required"
                    ),
            }),
    }),
});

const validatedSchema = yup.object().shape({
    artist: yup.object().shape({
        cnmArtist: yup.object().nullable().required("utils.form.required"),
        leadGender: yup.string().nullable().required("utils.form.required"),
        countries: yup.array().ensure().min(1, "utils.form.required"),
    }),
    title: yup.object().shape({
        title: yup.string().ensure().trim().required("utils.form.required"),
        isrcs: yup.array().ensure().min(1, "utils.form.required"),
        genre: yup.object().nullable().required("utils.form.required"),
        voice: yup.string().nullable().required("utils.form.required"),
        publicationDate: yup
            .string()
            .ensure()
            .trim()
            .required("utils.form.required")
            .test(
                "is-not-0",
                "utils.form.date_zero",
                (value) => value !== "0000-00-00"
            )
            .matches("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "utils.form.date_format"),
        label: yup.object().nullable().required("utils.form.required"),
        distributor: yup.object().nullable().required("utils.form.required"),
        language: yup.string().nullable().required("utils.form.required"),
        reported: yup
            .boolean()
            .test(
                "is-false",
                "cnm.report.consolidate.edit.report.shouldBeFalse",
                (value) => !value
            ),
        discarded: yup
            .boolean()
            .test(
                "is-false",
                "cnm.report.consolidate.edit.report.shouldBeFalse",
                (value) => !value
            ),
    }),
});

const EnhancedEdit = ({ year, chartType, titleId, onSuccess, editable }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        reference: {},
    });

    const defaultValues = {
        artist: {
            cnmArtist: null,
            csaArtist: null,
            leadGender: null,
            countries: [],
        },
        title: {
            title: null,
            isrcs: [],
            genre: null,
            voice: null,
            publicationDate: null,
            label: null,
            distributor: null,
            language: null,
            validated: false,
            reported: false,
            discarded: false,
            reportReason: null,
            discardReason: null,
        },
    };
    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        trigger,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: async (data, context, options) => {
            const resolver = yupResolver(
                data.title.validated ? validatedSchema : schema
            );
            return await resolver(data, context, options);
        },
        defaultValues,
    });

    const [cnmArtist, validated, reported, discarded] = useWatch({
        control,
        name: [
            "artist.cnmArtist",
            "title.validated",
            "title.reported",
            "title.discarded",
        ],
        defaultValue: [null, false, false, false],
    });

    // Trigger the form's validation when the validation switch is clicked
    useEffect(() => {
        trigger();
    }, [validated]);
    useEffect(() => {
        trigger("title.discardReason");
    }, [discarded]);

    const fetchData = useCallback(() => {
        if (titleId === undefined) {
            reset(defaultValues);
            setState({
                isLoading: false,
                reference: {},
            });
            return;
        }

        let cancelled = false;
        setState({
            isLoading: true,
            reference: {},
        });
        api.get(`cnm/consolidation/title/${year}/${chartType}/${titleId}`, {
            locale,
        })
            .then(({ consolidation, reference }) => {
                if (!cancelled) {
                    reset(prefill(consolidation, reference));
                    setState({
                        isLoading: false,
                        reference,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    reset(defaultValues);
                    setState({
                        isLoading: false,
                        reference: {},
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [year, titleId]);

    useEffect(fetchData, [fetchData]);

    const onSubmit = (data) =>
        api
            .post(
                `cnm/consolidation/${year}/${chartType}/${titleId}`,
                { locale },
                data
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.success"
                    )
                );
                reset(data);
                onSuccess(titleId, {
                    artist:
                        data.artist.cnmArtist?.name ??
                        state.reference.artistName,
                    title: data.title.title,
                    reported: data.title.reported,
                    discarded: data.title.discarded,
                    validated: data.title.validated,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.error"
                    )
                );
            });

    const onCnmArtistChange = (cnmArtist) => {
        const reference = {
            ...state.reference,
            msArtists: cnmArtist?.msArtists ?? [],
        };
        setState((prev) => ({
            ...prev,
            reference,
        }));

        if (cnmArtist === null) {
            setValue("artist.csaArtist", null);
            setValue("artist.leadGender", null);
            setValue("artist.countries", []);
            setState((prev) => ({
                ...prev,
                reference: { ...prev.reference, msArtists: null },
            }));
        } else {
            setValue("artist.csaArtist", cnmArtist.csaArtist);
            setValue(
                "artist.leadGender",
                cnmArtist.leadGender ?? getDefaultLeadGender(reference)
            );
            setValue(
                "artist.countries",
                cnmArtist.countries.length > 0
                    ? cnmArtist.countries
                    : getDefaultCountries(reference)
            );
        }
    };

    return (
        <Edit
            {...state}
            year={year}
            chartType={chartType}
            titleId={titleId}
            errors={
                state.isLoading || titleId === undefined || !editable
                    ? {}
                    : errors
            }
            register={register}
            control={control}
            setValue={setValue}
            isSubmitting={isSubmitting}
            onReset={fetchData}
            onSubmit={handleSubmit(onSubmit)}
            location={location}
            locale={locale}
            disabled={state.isLoading || titleId === undefined || !editable}
            artistDisabled={
                state.isLoading ||
                titleId === undefined ||
                !editable ||
                cnmArtist === null
            }
            onCnmArtistChange={onCnmArtistChange}
            showAggregationLink={
                !state.isLoading &&
                titleId !== undefined &&
                editable &&
                user.hasRight("cnm.aggregation.manage")
            }
            reported={reported}
            discarded={discarded}
        />
    );
};

export default EnhancedEdit;
