import React from "react";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import PlanningsTab from "./PlanningsTab";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";
import LandingStatistics from "pages/LandingStatistics";

const PlanningsProduction = ({ plannings, isLoading }) => {
    let planningList = <LandingStatistics />;

    if (plannings.length !== 0) {
        planningList = (
            <PlanningsTab
                plannings={plannings.filter(
                    (planning) =>
                        planning.partner_id === PLANNING_PARTNER.EDITORIAL
                )}
            />
        );
    }

    return (
        <main
            id="content"
            className="content"
            role="main"
            style={
                plannings.length === 0
                    ? { height: "100% !important" }
                    : undefined
            }
        >
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <I18n t="dashboard" />
                </li>
            </ol>
            {isLoading ? <Loader /> : planningList}
        </main>
    );
};

export default PlanningsProduction;
