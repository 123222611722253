export const platform_color = (id) => {
    const colors = [
        "purple",
        "blue",
        "orange",
        "green",
        "dark",
        "red",
        "pink",
        "teal",
        "yellow",
        "indigo",
        "cyan",
    ];
    return colors[id % colors.length];
};

export const prefill = (data, reference) => {
    // Select all ISRCs by default
    const defaultIsrcs = [...new Set(reference.isrcs.map(({ isrc }) => isrc))];

    // Select CNM genre of the matched MS recordings if it is unique
    const cnmGenres = reference.genres
        .filter(({ cnmId }) => cnmId !== null)
        .map(({ cnmId, cnmName }) => ({ id: cnmId, name: cnmName }));
    const defaultGenre = allIdsEqual(cnmGenres) ? cnmGenres[0] : null;

    // Select oldest publication date from MS, or oldest publication date from
    // the platforms if the title is not matched with MS
    const defaultPublicationDate =
        reference.publicationDates.length > 0
            ? [...reference.publicationDates].sort(
                  (a, b) =>
                      (b.platform === 0) - (a.platform === 0) ||
                      new Date(a.date) - new Date(b.date)
              )[0].date
            : null;

    // Select most common CNM label
    const cnmLabelsFromMs = reference.labels
        .filter(({ platform, cnmId }) => platform === 0 && cnmId !== null)
        .map(({ cnmId, cnmName, cnmType }) => ({
            id: cnmId,
            name: cnmName,
            type: cnmType,
        }));
    const cnmLabels = reference.labels
        .filter(({ cnmId }) => cnmId !== null)
        .map(({ cnmId, cnmName, cnmType }) => ({
            id: cnmId,
            name: cnmName,
            type: cnmType,
        }));
    const defaultLabel =
        mostCommonById(cnmLabelsFromMs) ?? mostCommonById(cnmLabels);

    // Select most common CNM distributor
    const cnmDistributors = reference.distributors
        .filter(({ cnmId }) => cnmId !== null)
        .map(({ cnmId, cnmName, cnmType }) => ({
            id: cnmId,
            name: cnmName,
            type: cnmType,
        }));
    const defaultDistributor = mostCommonById(cnmDistributors);

    const defaultLanguage =
        reference.lyricfindLanguages === null ||
        reference.lyricfindLanguages.length != 1
            ? null
            : reference.lyricfindLanguages[0] === "instrumental"
            ? "INSTRUMENTAL"
            : reference.lyricfindLanguages[0] === "fr"
            ? "FRENCH"
            : "INTERNATIONAL";

    const defaultVoice =
        defaultLanguage === "INSTRUMENTAL" ? "INSTRUMENTAL" : null;

    return {
        ...data,
        artist: {
            ...data.artist,
            leadGender:
                data.artist?.leadGender ?? getDefaultLeadGender(reference),
            countries:
                data.artist?.countries && data.artist?.countries.length > 0
                    ? data.artist?.countries
                    : getDefaultCountries(reference),
        },
        title: {
            ...data.title,
            isrcs:
                data.title.isrcs.length > 0 ? data.title.isrcs : defaultIsrcs,
            genre: data.title.genre ?? defaultGenre,
            publicationDate:
                data.title.publicationDate ?? defaultPublicationDate,
            label: data.title.label ?? defaultLabel,
            distributor: data.title.distributor ?? defaultDistributor,
            language: data.title.language ?? defaultLanguage,
            voice: data.title.voice ?? defaultVoice,
        },
    };
};

export const getDefaultLeadGender = (reference) =>
    reference.msArtists.length === 1 ? reference.msArtists[0].gender : null;

export const getDefaultCountries = (reference) => {
    const defaultCountries = [
        ...new Set(
            reference.msArtists
                .map(({ country }) => country)
                .filter((country) => country !== null)
        ),
    ];

    return defaultCountries.length > 0 ? defaultCountries : reference.countries;
};

const allIdsEqual = (array) =>
    array.length > 0 && array.every(({ id }) => id === array[0].id);

const mostCommonById = (array) => {
    let mostCommon = null;
    let mostCommonCount = 0;
    const counts = {};

    for (const item of array) {
        counts[item.id] = (counts[item.id] ?? 0) + 1;
        if (counts[item.id] > mostCommonCount) {
            mostCommon = item;
            mostCommonCount = counts[item.id];
        }
    }

    return mostCommon;
};
