import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputRange from "shared/components/Form/InputRange";
import "./Volume.css";

const Volume = ({ volume, getVolumeIconClasses, onScrub, onEndScrubbing }) => {
    return (
        <div className="apple__player__volume">
            <FontAwesomeIcon
                icon={getVolumeIconClasses()}
                size="lg"
                fixedWidth
            />
            <InputRange
                value={volume}
                onChange={onScrub}
                onMouseUp={onEndScrubbing}
                onTouchEnd={onEndScrubbing}
                min={0}
                max={1}
                step={0.01}
                className="ml-2"
            />
        </div>
    );
};

export default Volume;
