import React, { useEffect, useContext } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    SidebarNotificationContext,
    LABEL_PROMO_DISABLE_DECREMENT,
    LABEL_PROMO_DISABLE_INCREMENT,
} from "layout/SidebarNotification";

const schema = yup.object().shape({
    name: yup.string().required("utils.form.required"),
});

const EnhancedCreateOrUpdateModal = ({
    show,
    handleClose,
    label,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const {
        labelPromoDisable: { dispatch },
    } = useContext(SidebarNotificationContext);

    useEffect(() => {
        reset({
            name: "",
            enabled: true,
            ...label,
        });
    }, [reset, label, show]);

    const onSubmit = (data) => {
        let apiRequest;
        if (label?.id) {
            apiRequest = api.put(`label-promo/${label.id}`, { locale }, data);
        } else {
            apiRequest = api.post(`label-promo`, {}, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `labelPromo.manage.${
                            label?.id ? "update" : "create"
                        }.success`
                    )
                );
                dispatch({
                    type: data.enabled
                        ? LABEL_PROMO_DISABLE_DECREMENT
                        : LABEL_PROMO_DISABLE_INCREMENT,
                });
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `labelPromo.manage.${
                            label?.id ? "update" : "create"
                        }.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <CreateOrUpdateModal
            {...props}
            show={show}
            label={label}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
