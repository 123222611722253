import React from "react";
import MatchingDelete from "./MatchingDelete";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";

const EnhancedMatchingDelete = ({
    partner,
    id_partner,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const { artistId, albumId, releaseId, recordingId, trackId } = useParams();

    const handleMatchingDelete = () => {
        api.delete(
            `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}/matching/${partner}/${id_partner}`,
            { locale }
        )
            .then((response) => {
                onSuccess(partner, id_partner);
                toast.success(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.matching.delete.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.matching.delete.error",
                        error.message
                    )
                );
            });
    };

    const onClick = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.disco.objects.track.matching.delete.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.disco.objects.track.matching.delete.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleMatchingDelete();
        });
    };

    return <MatchingDelete {...props} onClick={onClick} location={location} />;
};

export default EnhancedMatchingDelete;
