import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";
import Error from "shared/components/Form/Error";

const SubTasks = ({
    subtasks,
    assignementOptions,
    tasksOptions,
    errors,
    addSubtask,
    removeSubtask,
    editSubtask,
}) => {
    const subtaskList = subtasks.map((subtask) => (
        <div key={subtask.key}>
            <div className="form-row align-items-center mb-2">
                <div className="col">
                    <InputSelect
                        value={
                            subtask.type !== null
                                ? {
                                      value: subtask.type,
                                      label: subtask.type,
                                  }
                                : null
                        }
                        options={tasksOptions}
                        placeholder={
                            <I18n t="plannings.tasks.subtasks.typePlaceholder" />
                        }
                        onChange={(option, action) => {
                            if (action.action === "clear") {
                                editSubtask(subtask.key, {
                                    type: null,
                                });
                            } else if (action.action === "select-option") {
                                editSubtask(subtask.key, {
                                    type: option.value,
                                });
                            }
                        }}
                        hasError={errors[subtask.key]}
                    />
                </div>
                <div className="col">
                    <InputSelect
                        value={
                            subtask.assignement !== null
                                ? {
                                      value: subtask.assignement.id,
                                      label: subtask.assignement.name,
                                  }
                                : null
                        }
                        options={assignementOptions}
                        placeholder={
                            <I18n t="plannings.tasks.subtasks.assignementPlaceholder" />
                        }
                        onChange={(option, action) => {
                            if (action.action === "clear") {
                                editSubtask(subtask.key, {
                                    assignement: null,
                                });
                            } else if (action.action === "select-option") {
                                editSubtask(subtask.key, {
                                    assignement: {
                                        id: option.value,
                                        name: option.label,
                                    },
                                });
                            }
                        }}
                    />
                </div>
                <div className="col-auto">
                    <button
                        type="button"
                        className="btn btn-danger"
                        title={<I18n t="plannings.tasks.subtasks.remove" />}
                        onClick={() => removeSubtask(subtask.key)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </button>
                </div>
            </div>
            {errors[subtask.key] && (
                <Error error={[errors[subtask.key]]} className="mb-3" />
            )}
        </div>
    ));

    return (
        <>
            {subtaskList}
            <div className="form-row mb-1">
                <div className="col">
                    <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={addSubtask}
                    >
                        <I18n t="plannings.tasks.subtasks.new" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default SubTasks;
