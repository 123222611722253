import React, { useState, useEffect, useCallback } from "react";
import SampleDetail from "./SampleDetail";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { NavLink } from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeTitle,
} from "shared/functions/normalize";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import Action from "./Action";
import {
    getLyricsFiltered,
    getSelectCatalogTypeOptions,
    getSelectLanguageOptions,
} from "./SampleDetail.pure";

const defaultState = {
    lyrics: [],
    isLoading: false,
    filter: {
        catalog_type: null,
        nb_line: null,
        language: null,
        assessment: null,
        counter_assessment: null,
    },
    planning: null,
    assignement: null,
};

const EnhancedSampleDetail = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState(defaultState);
    const location = useLocation();
    const { sampleId } = useIntParams();

    const getLyrics = useCallback(() => {
        let cancelled = false;

        if (sampleId) {
            api.get(`apple/sample/${sampleId}`)
                .then((response) => {
                    if (!cancelled) {
                        setState((previousState) => {
                            return {
                                ...previousState,
                                lyrics: response,
                                isLoading: false,
                            };
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    if (!cancelled) {
                        setState(defaultState);
                    }
                });
        } else {
            if (!cancelled) {
                setState(defaultState);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [sampleId]);

    useEffect(() => {
        setState((previousState) => {
            return { ...previousState, isLoading: true };
        });
        getLyrics();
    }, [getLyrics]);

    const columns = [
        {
            dataField: "catalog_type",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.catalog_type"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "apple.sample.management.lyrics.catalog_type"
                ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "provider",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.provider"
            ),
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "user_id",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.user"
            ),
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.artist"
            ),
            title: true,
            formatter: (cell, row) => {
                return cell ? (
                    <NavLink
                        to={`/apple/sample/assessment/${row.id}`}
                        target="__blank"
                    >
                        {cell}
                    </NavLink>
                ) : (
                    "-"
                );
            },
            sort: true,
            filterValue: normalizeTitle,
        },
        {
            dataField: "album",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.album"
            ),
            title: true,
            formatter: (cell, row) => {
                return cell ? (
                    <NavLink
                        to={`/apple/sample/assessment/${row.id}`}
                        target="__blank"
                    >
                        {cell}
                    </NavLink>
                ) : (
                    "-"
                );
            },
            sort: true,
            filterValue: normalizeTitle,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.title"
            ),
            title: true,
            formatter: (cell, row) => {
                return cell ? (
                    <NavLink
                        to={`/apple/sample/assessment/${row.id}`}
                        target="__blank"
                    >
                        {cell}
                    </NavLink>
                ) : (
                    "-"
                );
            },
            sort: true,
            filterValue: normalizeTitle,
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.isrc"
            ),
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "genre",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.genre"
            ),
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "language",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.language"
            ),
            title: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
        },
        {
            dataField: "has_assessment",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.assessment"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "apple.sample.management.lyrics.assessment"
                ),
            align: "center",
            sort: true,
            formatter: (cell, row, rowIndex, data) => {
                return (
                    <Action
                        type="ASSESSMENT"
                        task={row}
                        planning={data.state.planning}
                        assignement={data.state.assignement}
                        onSuccess={() => {
                            getLyrics();
                            setState((prev) => ({
                                ...prev,
                                filter: {
                                    ...prev.filter,
                                    nb_line:
                                        prev.filter.nb_line > 0
                                            ? prev.filter.nb_line - 1
                                            : 0,
                                },
                            }));
                        }}
                    />
                );
            },
            formatExtraData: {
                state,
            },
        },
        {
            dataField: "has_counter_assessment",
            text: I18n.getTranslation(
                location,
                "apple.sample.management.lyrics.counter_assessment"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "apple.sample.management.lyrics.counter_assessment"
                ),
            align: "center",
            sort: true,
            formatter: (cell, row, rowIndex, data) => {
                return (
                    <Action
                        type="COUNTER_ASSESSMENT"
                        task={row}
                        planning={data.state.planning}
                        assignement={data.state.assignement}
                        onSuccess={() => {
                            getLyrics();
                            setState((prev) => ({
                                ...prev,
                                filter: {
                                    ...prev.filter,
                                    nb_line:
                                        prev.filter.nb_line > 0
                                            ? prev.filter.nb_line - 1
                                            : 0,
                                },
                            }));
                        }}
                    />
                );
            },
            formatExtraData: {
                state,
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "catalog_type",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = (row) => {
        let classes = null;

        if (row.has_error) {
            classes = "table-danger";
        }

        return classes;
    };

    return (
        <SampleDetail
            {...props}
            lyrics={getLyricsFiltered(state)}
            isLoading={state.isLoading}
            setState={setState}
            columns={columns}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
            pagination={pagination}
            location={location}
            catalogTypeOptions={getSelectCatalogTypeOptions(state)}
            languageOptions={getSelectLanguageOptions(state)}
            rowClasses={rowClasses}
        />
    );
};

export default EnhancedSampleDetail;
