import React from "react";
import Loader from "shared/components/Loader/Loader";
import Tracks from "./Tracks";
import I18n from "shared/lib/I18n";

const ResultsList = ({ isLoading, results }) => {
    if (isLoading) {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Loader />
                </div>
            </div>
        );
    }

    if (results.tracks.length !== 0) {
        return <Tracks tracks={results.tracks} />;
    }

    return (
        <h2 className="text-center">
            <I18n t="recommendation.empty" />
        </h2>
    );
};

export default ResultsList;
