import React, { useState, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import useAPI from "shared/hooks/useApi";
import Reset from "./Reset";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required")
        .matches(/^(?=.*[A-Z]).+$/, "utils.form.must_contain_uppercase")
        .matches(/^(?=.*[a-z]).+$/, "utils.form.must_contain_lowercase")
        .matches(/^.{8,}$/, "utils.form.must_contain_8_characters")
        .matches(/^(?=.*\d).+$/, "utils.form.must_contain_digit")
        .matches(/^(?=.*[a-zA-Z]).+$/, "utils.form.must_contain_letter"),
});

const defaultValues = {
    password: null,
};

const EnhancedReset = (props) => {
    const { api } = useAPI();
    const { token } = useParams();
    const location = useLocation();
    const [state, setState] = useState({
        isSend: false,
        globalError: null,
    });

    const {
        handleSubmit,
        register,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const handleReset = async (data) => {
        try {
            await api.reset(token, data);
            setState((state) => ({ ...state, isSend: true }));
        } catch (e) {
            setState((state) => ({ ...state, globalError: e.message }));
        }
    };

    return (
        <Reset
            {...state}
            handleReset={handleSubmit(handleReset)}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            location={location}
            {...props}
        />
    );
};

export default EnhancedReset;
