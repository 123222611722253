import React from "react";
import TransferForm from "./TransferForm";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";

const EnhancedTransferForm = ({ albumId, onSuccess, ...props }) => {
    const { locale } = useParams();
    const location = useLocation();
    const { api } = useAPI();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        defaultValues: {
            artist: null,
        },
    });

    const onSubmit = (data) => {
        api.put(
            `album/${albumId}/transfer`,
            { locale },
            { artist: data.artist.id }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.basic.transfer.success`,
                        { artist: response.artist, album: response.album }
                    )
                );
                onSuccess?.(data.artist.id);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.basic.transfer.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <TransferForm
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedTransferForm;
