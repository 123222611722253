import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import ArtistLink from "shared/components/ArtistLink";

const Related = ({ related, successor, influence, isLoading }) => {
    return (
        <Widget
            title="artist.view.universRelated.head"
            isLoading={isLoading}
            style={{ height: "40rem", overflow: "auto" }}
            headerClassName="sticky-top bg-white"
            headerStyle={{ top: "-15px" }}
        >
            <Widget.Body>
                <hr className="my-2 sticky-top" style={{ top: "37px" }} />
                <div className="row">
                    <div className="col border-right">
                        <strong>
                            <I18n t="artist.view.universRelated.related" />
                        </strong>
                        <hr className="my-2" />
                        {related.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    <ArtistLink
                                        artistId={id}
                                        content={name}
                                        view
                                    />
                                </p>
                            );
                        })}
                    </div>
                    <div className="col border-right">
                        <strong>
                            <I18n t="artist.view.universRelated.successor" />
                        </strong>
                        <hr className="my-2" />
                        {successor.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    <ArtistLink
                                        artistId={id}
                                        content={name}
                                        view
                                    />
                                </p>
                            );
                        })}
                    </div>
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.universRelated.influence" />
                        </strong>
                        <hr className="my-2" />
                        {influence.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    <ArtistLink
                                        artistId={id}
                                        content={name}
                                        view
                                    />
                                </p>
                            );
                        })}
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Related;
