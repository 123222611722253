import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
    CSVExport,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Widget from "shared/components/Widget";
import { useLocation } from "react-router";

const List = ({ title, exportName, data, columns, options }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const location = useLocation();
    return (
        <ToolkitProvider
            keyField="id_top_row"
            data={data}
            columns={columns}
            exportCSV={{
                fileName: `${exportName
                    .toLowerCase()
                    .replaceAll(" ", "_")}.csv`,
                onlyExportFiltered: true,
                exportAll: false,
            }}
            search
        >
            {(toolkitprops) => (
                <Widget
                    title={title}
                    actions={
                        <ExportCSVButton
                            {...toolkitprops.csvProps}
                            disabled={!data.length}
                        >
                            <I18n t="utils.button.export" />
                        </ExportCSVButton>
                    }
                    enableCollapse
                >
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-12">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "data_external.internationals_charts.coverage.search"
                                    )}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body className="widget-table-overflow">
                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            hover
                            noDataIndication={
                                <I18n t="data_external.internationals_charts.coverage.empty" />
                            }
                            classes="mb-3"
                            pagination={paginationFactory(options)}
                        />
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default List;
