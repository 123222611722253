import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";

const EditMatchingsTable = ({ candidates, columns, rowClasses }) => {
    return (
        <BootstrapTable
            keyField="id"
            data={candidates}
            columns={columns}
            rowClasses={rowClasses}
            classes="table-header-fixed mb-0"
            bootstrap4
            hover
            bordered={false}
            noDataIndication={<I18n t="common.editMatchingsTable.empty" />}
        />
    );
};

export default EditMatchingsTable;
