import React from "react";
import I18n from "shared/lib/I18n";

const Translation = ({ album }) => {
    return (
        <>
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`album.view.profileInformation.translations`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div
                className="row"
                style={{ maxHeight: "5rem", overflow: "auto" }}
            >
                <div className="col">
                    <div>
                        {album.translations.map(({ language, title }) => {
                            return (
                                <p key={language} className="mb-1">
                                    {title} - {language}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Translation;
