import React from "react";
import { NavLink } from "shared/lib/I18n";

const ReleaseLink = ({ id_artist, id_album, id_release, title, className }) => {
    return (
        <NavLink
            to={`/artist/${id_artist}/edit/discography/album/${id_album}/release/${id_release}`}
            target="_blank"
            className={`${className}`}
        >
            {title}
        </NavLink>
    );
};

export default ReleaseLink;
