import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import DateList from "./DateList";
import useAPI from "shared/hooks/useApi";

const EnhancedDateList = ({ partner, selectedDate, ...props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dates, setDates] = useState([]);
    const { api } = useAPI();

    const fetchDates = useCallback(() => {
        let cancelled = false;
        setIsLoading(true);
        api.get(`partner_tops/${partner}`)
            .then((response) => {
                if (!cancelled) {
                    setDates(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setDates([]);
                    setIsLoading(false);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            // This will be called by useEffect before the next call to
            // fetchDates. This "cancels" the current fetchDates call, to make
            // sure that it does not trigger a rerendering of the component
            // with stale data.
            cancelled = true;
        };
    }, [partner]);

    useEffect(() => {
        fetchDates();
    }, [fetchDates, partner]);

    return (
        <DateList
            isLoading={isLoading}
            dates={dates}
            partner={partner}
            selectedDate={selectedDate}
            {...props}
        />
    );
};

export default EnhancedDateList;
