import React, { useState } from "react";
import Tasks from "./Tasks";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useParams, useLocation } from "react-router";
import swal from "sweetalert";
import useAPI from "shared/hooks/useApi";

const EnhancedTasks = ({ handleTasksChange, tasks, isLoading, ...props }) => {
    const [filteredTasks, setFilteredTasks] = useState(tasks);
    const { locale, planningId } = useParams();
    const location = useLocation();
    const { api } = useAPI();

    const handleStatusChanged = (subTask, status) => {
        swal({
            title: I18n.getTranslation(
                location,
                `plannings.view.tasks.subtasks.confirm_change_status.${status}.title`
            ),
            text: I18n.getTranslation(
                location,
                `plannings.view.tasks.subtasks.confirm_change_status.${status}.text`
            ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                if (["TO_BE_CORRECTED", "ERROR"].includes(status)) {
                    const addComment = () => {
                        var textarea = document.createElement("textarea");
                        textarea.rows = 6;
                        textarea.className = "swal-content__textarea";

                        textarea.onkeyup = function () {
                            swal.setActionValue({
                                confirm: this.value,
                            });
                        };
                        swal({
                            title: I18n.getTranslation(
                                location,
                                `plannings.view.tasks.subtasks.confirm_change_status.${status}.comment.title`
                            ),
                            dangerMode: true,
                            content: textarea,
                            buttons: {
                                cancel: {
                                    text: I18n.getTranslation(
                                        location,
                                        "utils.button.cancel"
                                    ),
                                    visible: true,
                                    closeModal: true,
                                },
                                confirm: {
                                    text: I18n.getTranslation(
                                        location,
                                        "utils.button.validate"
                                    ),
                                },
                            },
                        }).then((comment) => {
                            const newComment =
                                comment && comment !== true && comment !== ""
                                    ? comment
                                    : null;
                            const isRequired = ["TO_BE_CORRECTED"].includes(
                                status
                            );

                            if (isRequired && !newComment) {
                                swal(
                                    I18n.getTranslation(
                                        location,
                                        `plannings.view.tasks.subtasks.confirm_change_status.${status}.comment.error`
                                    ),
                                    {
                                        buttons: false,
                                        icon: "error",
                                        timer: 2000,
                                    }
                                ).then(addComment);
                            } else {
                                api.put(
                                    `plannings/tasks/subtasks/${subTask.id}/status`,
                                    {
                                        locale,
                                    },
                                    {
                                        new_status: status,
                                        comment: newComment,
                                    }
                                )
                                    .then((response) => {
                                        handleTasksChange();
                                        toast.success(response.message);
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        toast.error(error.message);
                                    });
                                swal.close();
                            }
                        });
                    };
                    addComment();
                } else {
                    api.put(
                        `plannings/tasks/subtasks/${subTask.id}/status`,
                        {
                            locale,
                        },
                        {
                            new_status: status,
                            comment: subTask.comment,
                        }
                    )
                        .then((response) => {
                            handleTasksChange();
                            toast.success(response.message);
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(error.message);
                        });
                }
            }
        });
    };

    const handleDeleted = (taskId) => {
        swal({
            title: I18n.getTranslation(
                location,
                "plannings.view.tasks.action.deleted.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "plannings.view.tasks.action.deleted.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                api.delete(`plannings/${planningId}/tasks/${taskId}`, {
                    locale,
                })
                    .then((response) => {
                        handleTasksChange();
                        toast.success(response.message);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message);
                    });
            }
        });
    };

    return (
        <Tasks
            {...props}
            tasks={tasks}
            filteredTasks={filteredTasks}
            setFilteredTasks={setFilteredTasks}
            isLoading={isLoading}
            handleStatusChanged={handleStatusChanged}
            handleDeleted={handleDeleted}
            handleTasksChange={handleTasksChange}
        />
    );
};

export default EnhancedTasks;
