import React from "react";
import useAPI from "shared/hooks/useApi";
import Basic from "./Basic";
import { useParams, useNavigate } from "react-router";

const EnhancedBasic = (props) => {
    const navigate = useNavigate();
    const { api } = useAPI();
    const { locale, albumId } = useParams();

    const redirectToView = () => {
        navigate(`/${locale}/album/${albumId}/view`);
    };

    return (
        <Basic albumId={albumId} {...props} redirectToView={redirectToView} />
    );
};

export default EnhancedBasic;
