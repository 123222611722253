import React, { useState, useEffect } from "react";
import ImageCard from "./ImageCard";
import { useLocation } from "react-router";

const EnhancedImageCard = ({ url, ...props }) => {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const location = useLocation();

    useEffect(() => {
        if (url) {
            const fillDimensions = function () {
                setWidth(this.naturalWidth);
                setHeight(this.naturalHeight);
            };

            let img = new Image();

            img.addEventListener("load", fillDimensions);
            img.src = url;

            return () => {
                img.removeEventListener("load", fillDimensions);
            };
        }
    }, [url]);

    return (
        <ImageCard
            url={url}
            width={width}
            height={height}
            location={location}
            {...props}
        />
    );
};

export default EnhancedImageCard;
