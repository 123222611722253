import React from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import ArtistManagement from "./ArtistManagement";
import { ArtistManagementProvider } from "./ArtistManagement.provider";
import useUser from "shared/hooks/useUser";

const EnhancedArtistManagement = (props) => {
    const { locale, selectedTab } = useParams();
    const { artistId } = useIntParams();
    const navigate = useNavigate();
    const { user } = useUser();
    const location = useLocation();

    const onSelectTab = (k) => {
        navigate(`/${locale}/artist/${artistId}/edit/${k}`);
    };

    return (
        <ArtistManagementProvider>
            <ArtistManagement
                {...props}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                user={user}
                location={location}
            />
        </ArtistManagementProvider>
    );
};

export default EnhancedArtistManagement;
