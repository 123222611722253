import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import CreateOrUpdateButton from "./CreateOrUpdateButton";

const schema = yup.object().shape({
    name: yup.string().ensure().trim().required("utils.form.required"),
    type: yup.string().ensure().trim().required("utils.form.required"),
});

const EnhancedCreateOrUpdateButton = ({
    year,
    onSuccess,
    distributor,
    ...props
}) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            type: null,
        },
    });

    const onShow = () => {
        reset({
            name: distributor?.name ?? "",
            type: distributor?.type ?? null,
        });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const typeOptions = [
        {
            value: "Top Distributeur",
            label: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_distributors.createOrUpdate.type.topDistributor"
            ),
        },
        {
            value: "Distributeur",
            label: I18n.getTranslation(
                location,
                "cnm.repositories.cnm_distributors.createOrUpdate.type.distributor"
            ),
        },
    ];

    const onSubmit = ({ name, type }) => {
        if (distributor?.id === undefined || distributor?.id === null) {
            return api
                .post(
                    `cnm/repository/cnm_distributors/${year}/distributor`,
                    { locale },
                    { name, type }
                )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "cnm.repositories.cnm_distributors.add.success"
                        )
                    );
                    onSuccess(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                })
                .then(onHide);
        } else {
            return api
                .post(
                    `cnm/repository/cnm_distributors/${year}/distributor/${distributor.id}`,
                    { locale },
                    { name, type }
                )
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "cnm.repositories.cnm_distributors.edit.success"
                        )
                    );
                    onSuccess({
                        id: distributor.id,
                        cnmName: distributor.cnmName,
                        name,
                        type,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                })
                .then(onHide);
        }
    };

    return (
        <CreateOrUpdateButton
            show={show}
            cnmName={distributor?.cnmName ?? null}
            action={
                distributor?.id === undefined || distributor?.id === null
                    ? "create"
                    : "update"
            }
            typeOptions={typeOptions}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onShow={onShow}
            onHide={onHide}
            onSubmit={handleSubmit(onSubmit)}
            {...props}
        />
    );
};

export default EnhancedCreateOrUpdateButton;
