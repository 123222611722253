import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import AlbumSelect from "./AlbumSelect";

const EnhancedAlbumSelect = ({
    id_artist,
    onMatch,
    onUnmatch,
    locale,
    ...props
}) => {
    const { api } = useAPI();

    const search = useDebouncedCallback((term, callback) => {
        if (!id_artist) {
            callback([]);
            return;
        }
        api.get(`artist/${id_artist}/albums`, { locale, search: term })
            .then((response) => {
                callback(
                    response.map(({ id, title }) => {
                        return {
                            value: { id, title },
                            label: `${title} (${id})`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            if (onMatch !== undefined) {
                onMatch(opt.value.id, opt.value.title);
            }
        } else {
            if (onUnmatch !== undefined) {
                onUnmatch();
            }
        }
    };

    return <AlbumSelect loadOptions={search} onChange={onChange} {...props} />;
};

export default EnhancedAlbumSelect;
