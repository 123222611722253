import React, { useState } from "react";
import Create from "./Create";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    url: yup
        .string()
        .nullable()
        .trim()
        .required("utils.form.required")
        .url("utils.form.url"),
});

const EnhancedCreate = ({ onSuccess, ...props }) => {
    const location = useLocation();
    const { albumId, locale } = useParams();
    const [show, setShow] = useState(false);
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            url: null,
        },
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = (data) => {
        api.post(
            `album/${albumId}/cover`,
            { locale },
            { url: data.url, partnerId: 183 }
        )
            .then(({ url }) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.create.success`
                    )
                );
                onSuccess?.(url, "Musicstory");
                handleClose();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.create.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Create
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            {...props}
        />
    );
};

export default EnhancedCreate;
