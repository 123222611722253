import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { ProgressBar, Badge } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import simbalsIcon from "assets/static/images/simbals.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./AudioDescription.css";

const AudioDescription = ({ data, isLoading, location }) => {
    return (
        <>
            <Widget
                title="album.view.universAudioDescription.head"
                isLoading={isLoading}
                className="album-view-univers-audio-description"
                actions={
                    <div className="d-flex align-items-center justify-content-end my-2">
                        <div className="mr-4 text-right">
                            <p className="mb-0">
                                <strong>
                                    <I18n t="album.view.universAudioDescription.licence" />
                                </strong>
                            </p>
                        </div>
                        <a
                            href="https://www.simbals.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={simbalsIcon} width="40px" alt="Simbals" />
                        </a>
                    </div>
                }
            >
                <Widget.Body>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="album.view.universAudioDescription.moods.title" />
                            </strong>
                            <span className="ml-1">
                                (
                                <I18n t="album.view.universAudioDescription.moods.subtitle" />
                                )
                            </span>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "album.view.universAudioDescription.moods.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.moods?.map(({ name, value }) => (
                                    <h4 key={name} className="mr-1">
                                        <Badge variant="secondary">
                                            {name} - {value} %
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="album.view.universAudioDescription.themes.title" />
                            </strong>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "album.view.universAudioDescription.themes.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.themes?.map((theme) => (
                                    <h4 key={theme} className="mr-1">
                                        <Badge variant="secondary">
                                            {theme}
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <strong>
                                <I18n t="album.view.universAudioDescription.timbres.title" />
                            </strong>
                            <Tippy
                                content={I18n.getTranslation(
                                    location,
                                    "album.view.universAudioDescription.timbres.description"
                                )}
                                trigger="mouseenter"
                            >
                                <span className="ml-1">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                    />
                                </span>
                            </Tippy>
                            <div className="d-flex flex-wrap">
                                {data.timbres?.map((timbre) => (
                                    <h4 key={timbre} className="mr-1">
                                        <Badge variant="secondary">
                                            {timbre}
                                        </Badge>
                                    </h4>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row mb-2">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.arousal.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.arousal.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.arousal} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.arousal}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.valence.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.valence.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.valence} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.valence}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.vocal_instrumental.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.vocal_instrumental.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.vocal_instrumental} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.vocal_instrumental}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.music_speech.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.music_speech.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.music_speech} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.music_speech}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.electric_acoustic.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.electric_acoustic.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.electric_acoustic} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.electric_acoustic}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.danceability.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.danceability.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.danceability} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.danceability}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.studio_live.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.studio_live.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.studio_live} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.studio_live}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.melodicity.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.melodicity.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.melodicity} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.melodicity}
                            />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>
                                        <I18n t="album.view.universAudioDescription.dissonance.title" />
                                    </strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.universAudioDescription.dissonance.description"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span className="ml-1">
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                fixedWidth
                                            />
                                        </span>
                                    </Tippy>
                                </div>
                                <span>{data?.dissonance} %</span>
                            </div>
                            <ProgressBar
                                variant="purple-ms"
                                now={data?.dissonance}
                            />
                        </div>
                    </div>
                </Widget.Body>
            </Widget>
        </>
    );
};

export default AudioDescription;
