import React from "react";
import { useLocation, useParams } from "react-router";
import CNMRepositories from "./CNMRepositories";

const EnhancedCNMRepositories = (props) => {
    const { repository, year } = useParams();
    const location = useLocation();

    return (
        <CNMRepositories
            repository={repository}
            year={
                year !== null && year !== undefined
                    ? parseInt(year, 10)
                    : undefined
            }
            location={location}
            {...props}
        />
    );
};

export default EnhancedCNMRepositories;
