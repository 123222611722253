import React from "react";
import { Modal } from "react-bootstrap";
import EditMatchingsForm from "shared/components/EditMatchingsForm";

const EditMatchingsModal = ({
    artistId,
    artistName,
    show,
    onHide,
    onChange,
    fetchCandidates,
    locale,
}) => {
    return (
        <Modal show={show} onHide={onHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{artistName}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <EditMatchingsForm
                    artistId={artistId}
                    onChange={onChange}
                    fetchCandidates={fetchCandidates}
                    locale={locale}
                />
            </Modal.Body>
        </Modal>
    );
};

export default EditMatchingsModal;
