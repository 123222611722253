import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { Link } from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faMinus,
    faPlus,
    faCompactDisc,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSquareMinus,
    faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { addToClipboard } from "shared/functions/AddToClipboard";
import ArtistLink from "shared/components/ArtistLink";
import { CHARTS_MODE } from "shared/constants/Charts";
import Charts from "./Charts";
import ArtistMatcher from "./ArtistMatcher";
import AlbumMatcher from "./AlbumMatcher";
import TrackMatcher from "./TrackMatcher";
import DiscardButton from "./DiscardButton";
import MatchedSwitch from "./MatchedSwitch";
import TranslatedSwitch from "./TranslatedSwitch";
import CreationSwitch from "./CreationSwitch";
import AddToPlanningButton from "./AddToPlanningButton";
import AlbumTranslationsForm from "./AlbumTranslationsForm";
import TrackTranslationsForm from "./TrackTranslationsForm";
import { getFilteredRows } from "./Charts.selector";
import BadgeGroup from "shared/components/BadgeGroup";

const defaultState = {
    isLoading: false,
    chart: {
        id: null,
        type: null,
        title: null,
        country: null,
        treatment: null,
        startDate: null,
        endDate: null,
    },
    rows: [],
    expanded: [],
};

const EnhancedCharts = ({ categoryId, chartId, language, mode, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const location = useLocation();
    const { locale } = useParams();
    const [{ isLoading, chart, rows, expanded }, setState] =
        useState(defaultState);

    const [filter, setFilter] = useState({
        matched: null,
        newEntry: null,
        toBeTreated: null,
        discarded: null,
    });

    const isMatched = (chartType, row) =>
        row.msArtist !== null &&
        ((chartType == "ALBUM" && row.msAlbum !== null) ||
            (chartType == "TRACK" && row.msRecordings.length > 0));

    const defaultExpanded = (chartType, row) =>
        isMatched(chartType, row) && !row.translated;

    const getRows = useCallback(() => {
        if (chartId === undefined) {
            setState(defaultState);
            return;
        }

        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/top/get/${chartId}`, {
            locale,
            language,
        })
            .then(({ chart, rows }) => {
                setState({
                    isLoading: false,
                    chart: chart,
                    rows: rows,
                    expanded: rows
                        .filter((row) => defaultExpanded(chart.type, row))
                        .map(({ id }) => id),
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultState);
            });
    }, [chartId, locale, language]);

    useEffect(getRows, [getRows]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            rows: prev.rows.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const updateEntry = (entryId, data) => {
        setState((prev) => {
            const rows = prev.rows.map((row) =>
                row.entryId === entryId ? { ...row, ...data } : row
            );

            return {
                ...prev,
                rows,
                expanded: rows
                    .filter((row) =>
                        row.entryId === entryId
                            ? defaultExpanded(chart.type, row)
                            : prev.expanded.includes(row.id)
                    )
                    .map(({ id }) => id),
            };
        });
    };

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(location, "common.charts.charts.rank"),
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "artistName",
            text: I18n.getTranslation(location, "common.charts.charts.artist"),
            formatter: (cell, row, rowIndex, data) =>
                data.mode === CHARTS_MODE.MATCHING ||
                data.mode === CHARTS_MODE.TRANSLATION ? (
                    <>
                        {cell}
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => addToClipboard(cell, data.locale)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                    </>
                ) : row.msArtist !== null ? (
                    <ArtistLink
                        artistId={row.msArtist.id}
                        content={row.msArtist.name}
                        blank
                    />
                ) : (
                    cell || "-"
                ),
            formatExtraData: { locale, mode },
            headerStyle: {
                width: "25%",
            },
        },
        (chart.type == "ALBUM" || mode === CHARTS_MODE.DISPLAY) && {
            dataField: "albumTitle",
            text: I18n.getTranslation(location, "common.charts.charts.album"),
            formatter: (cell, row, rowIndex, data) => (
                <>
                    {cell || "-"}
                    {(data.mode === CHARTS_MODE.MATCHING ||
                        data.mode === CHARTS_MODE.TRANSLATION) && (
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => addToClipboard(cell, data.locale)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                    )}
                </>
            ),
            formatExtraData: { locale, mode },
            headerStyle: {
                width: "25%",
            },
        },
        (chart.type == "TRACK" || mode === CHARTS_MODE.DISPLAY) && {
            dataField: "trackTitle",
            text: I18n.getTranslation(location, "common.charts.charts.track"),
            formatter: (cell, row, rowIndex, data) => (
                <>
                    {cell || "-"}
                    {(data.mode === CHARTS_MODE.MATCHING ||
                        data.mode === CHARTS_MODE.TRANSLATION) && (
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => addToClipboard(cell, data.locale)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                    )}
                </>
            ),
            formatExtraData: { locale, mode },
            headerStyle: {
                width: "25%",
            },
        },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "previousRank",
            text: I18n.getTranslation(
                location,
                "common.charts.charts.previousRank"
            ),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "7em",
            },
        },
        mode === CHARTS_MODE.DISPLAY && {
            dataField: "dateIn",
            text: I18n.getTranslation(location, "common.charts.charts.dateIn"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "7em",
            },
        },
        mode === CHARTS_MODE.DISPLAY && {
            dataField: "dateOut",
            text: I18n.getTranslation(location, "common.charts.charts.dateOut"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "7em",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.DISPLAY) && {
            dataField: "nbWeek",
            text: I18n.getTranslation(location, "common.charts.charts.nbWeek"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "6em",
            },
        },
        mode === CHARTS_MODE.DISPLAY && {
            dataField: "rankIn",
            text: I18n.getTranslation(location, "common.charts.charts.rankIn"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "6em",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.DISPLAY) && {
            dataField: "maxRank",
            text: I18n.getTranslation(location, "common.charts.charts.maxRank"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "4.5em",
            },
        },
        mode === CHARTS_MODE.DISPLAY && {
            dataField: "rankOut",
            text: I18n.getTranslation(location, "common.charts.charts.rankOut"),
            formatter: (cell) => cell ?? "-",
            headerStyle: {
                width: "7em",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.TRANSLATION) && {
            dataField: "msArtist",
            text: I18n.getTranslation(
                location,
                "common.charts.charts.msArtist"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <ArtistMatcher
                    entryId={row.entryId}
                    msArtist={cell}
                    onChange={(data) => updateEntry(row.entryId, data)}
                    {...data}
                />
            ),
            formatExtraData: { locale, language },
            headerStyle: {
                width: "25%",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.TRANSLATION) && {
            dataField: "discoLink",
            isDummyField: true,
            text: "",
            formatter: (cell, row) =>
                row.msArtist !== null ? (
                    <Link
                        to={`/artist/${row.msArtist.id}/edit/discography`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faCompactDisc} fixedWidth />
                    </Link>
                ) : undefined,
            headerStyle: {
                width: "2.35rem",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.TRANSLATION) &&
            chart.type == "ALBUM" && {
                dataField: "msAlbum",
                text: I18n.getTranslation(
                    location,
                    "common.charts.charts.msAlbum"
                ),
                formatter: (cell, row, rowIndex, data) => (
                    <AlbumMatcher
                        entryId={row.entryId}
                        msArtist={row.msArtist}
                        msAlbum={cell}
                        onChange={(data) => updateEntry(row.entryId, data)}
                        {...data}
                    />
                ),
                formatExtraData: { locale, language },
                headerStyle: {
                    width: "25%",
                },
            },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.TRANSLATION) &&
            chart.type == "TRACK" && {
                dataField: "dummyMsRecordings",
                isDummyField: true,
                text: I18n.getTranslation(
                    location,
                    "common.charts.charts.msRecordings"
                ),
                formatter: (cell, row, rowIndex, data) => (
                    <TrackMatcher
                        entryId={row.entryId}
                        msArtist={row.msArtist}
                        msRecordings={row.msRecordings}
                        onChange={(data) => updateEntry(row.entryId, data)}
                        {...data}
                    />
                ),
                formatExtraData: { locale, language },
                headerStyle: {
                    width: "40%",
                },
            },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "msArtist.informations",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "common.charts.charts.informations"
            ),
            formatter: (cell, row, rowIndex, data) => {
                const informations = [
                    row.msArtist !== null && {
                        badges: [
                            {
                                label: (row.msArtist.nbRelated ?? 0)
                                    .toString(10)
                                    .padStart(2, "0"),
                                color:
                                    (row.msArtist.nbRelated ?? 0) >= 10
                                        ? "success"
                                        : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.nbRelated"
                                ),
                            },
                        ],
                    },
                    row.msArtist !== null && {
                        badges: [
                            {
                                label:
                                    row.msArtist.albumsTotal !== 0
                                        ? `${
                                              row.msArtist.albumsWithoutGenre
                                          } / ${
                                              row.msArtist.albumsTotal
                                          } (${Math.round(
                                              (100 *
                                                  row.msArtist
                                                      .albumsWithoutGenre) /
                                                  row.msArtist.albumsTotal
                                          )} %)`
                                        : "0 / 0 (0 %)",
                                color:
                                    row.msArtist.albumsWithoutGenre === 0
                                        ? "success"
                                        : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.albumsWithoutGenre"
                                ),
                            },
                        ],
                    },
                    row.msArtist !== null && {
                        badges: [
                            {
                                label: "FR",
                                color: row.msArtist.hasBioFR
                                    ? row.msArtist.isBioFrReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioFR"
                                ),
                            },
                            {
                                label: "EN",
                                color: row.msArtist.hasBioEN
                                    ? row.msArtist.isBioEnReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioEN"
                                ),
                            },
                            {
                                label: "DE",
                                color: row.msArtist.hasBioDE
                                    ? row.msArtist.isBioDeReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioDE"
                                ),
                            },
                            {
                                label: "JA",
                                color: row.msArtist.hasBioJA
                                    ? row.msArtist.isBioJaReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioJA"
                                ),
                            },
                            {
                                label: "ES",
                                color: row.msArtist.hasBioES
                                    ? row.msArtist.isBioEsReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioES"
                                ),
                            },
                            {
                                label: "PT",
                                color: row.msArtist.hasBioPT
                                    ? row.msArtist.isBioPtReference
                                        ? "success-auto"
                                        : "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasBioPT"
                                ),
                            },
                        ],
                    },
                    row.msAlbum !== null && {
                        badges: [
                            {
                                label: "Review",
                                color: row.msAlbum.hasReview
                                    ? "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.hasReview"
                                ),
                            },
                        ],
                    },
                    row.msArtist !== null && {
                        badges: [
                            {
                                label: "Picture",
                                color: row.msArtist.hasPictures
                                    ? "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasPictures"
                                ),
                            },
                            {
                                label: "Curation",
                                color: row.msArtist.hasCuration
                                    ? "success"
                                    : "secondary",
                                tooltip: I18n.getTranslation(
                                    data.location,
                                    "common.charts.charts.hasCuration"
                                ),
                            },
                        ],
                    },
                    row.msArtist !== null && {
                        badges: [
                            {
                                label: row.msArtist.lastUpdated,
                                color: "info",
                                tooltip: I18n.getTranslation(
                                    location,
                                    "common.charts.charts.lastUpdated"
                                ),
                            },
                        ],
                    },
                ].filter(Boolean);

                return informations.length !== 0 ? (
                    <BadgeGroup datas={informations} />
                ) : (
                    "-"
                );
            },
            formatExtraData: { location },
            headerStyle: {
                width: "6rem",
            },
        },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "discarded",
            text: I18n.getTranslation(
                location,
                "common.charts.charts.discarded"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <DiscardButton
                    discarded={cell}
                    discardedComment={row.discardedComment}
                    entryId={row.entryId}
                    rowId={row.id}
                    disabled={isMatched(chart.type, row)}
                    onChange={(data) => updateEntry(row.entryId, data)}
                    {...data}
                />
            ),
            formatExtraData: { locale, location },
            headerStyle: {
                width: "7rem",
            },
        },
        mode === CHARTS_MODE.MATCHING && {
            dataField: "matched",
            text: I18n.getTranslation(location, "common.charts.charts.matched"),
            formatter: (cell, row, rowIndex, data) => (
                <MatchedSwitch
                    checked={cell}
                    disabled={!isMatched(chart.type, row)}
                    entryId={row.entryId}
                    rowId={row.id}
                    onChange={(data) => updateEntry(row.entryId, data)}
                    {...data}
                />
            ),
            formatExtraData: { locale },
            headerStyle: {
                width: "6rem",
            },
        },
        mode === CHARTS_MODE.TRANSLATION && {
            dataField: "translated",
            text: I18n.getTranslation(
                location,
                "common.charts.charts.translated"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <TranslatedSwitch
                    checked={cell}
                    disabled={!isMatched(chart.type, row)}
                    entryId={row.entryId}
                    rowId={row.id}
                    onChange={(data) => updateEntry(row.entryId, data)}
                    {...data}
                />
            ),
            formatExtraData: { locale, language },
            headerStyle: {
                width: "8rem",
            },
        },
        (mode === CHARTS_MODE.MATCHING || mode === CHARTS_MODE.TRANSLATION) && {
            dataField: "creation",
            text: I18n.getTranslation(
                location,
                "common.charts.charts.creation"
            ),
            formatter: (cell, row, rowIndex, data) => (
                <CreationSwitch
                    checked={cell}
                    rowId={row.id}
                    msArtist={row.msArtist}
                    onChange={(data) => updateRow(row.id, data)}
                    {...data}
                />
            ),
            formatExtraData: { locale },
            headerStyle: {
                width: "6.5rem",
            },
        },
        mode === CHARTS_MODE.MATCHING &&
            user.hasRight("customer.manage.matching.edito") && {
                dataField: "dummyAddToPlanning",
                isDummyField: true,
                text: "",
                formatter: (cell, row, rowIndex, data) => (
                    <AddToPlanningButton
                        chart={chart}
                        msArtist={row.msArtist}
                        albumTitle={row.albumTitle}
                        trackTitle={row.trackTitle}
                        rank={row.rank}
                        {...data}
                    />
                ),
                formatExtraData: { locale, location },
                headerStyle: {
                    width: "3.5rem",
                },
            },
    ].filter(Boolean);

    const nonExpandable = useMemo(
        () =>
            rows
                .filter((row) => !isMatched(chart.type, row))
                .map(({ id }) => id),
        [chart.type, rows]
    );

    const expandRow =
        mode === CHARTS_MODE.TRANSLATION
            ? {
                  className: "charts-translation-form",
                  renderer:
                      chart.type === "ALBUM"
                          ? (row) => (
                                <AlbumTranslationsForm
                                    entryId={row.entryId}
                                    artistNames={row.translations.artistNames}
                                    albumTitle={row.translations.albumTitle}
                                    onSuccess={(data) =>
                                        updateEntry(row.entryId, data)
                                    }
                                />
                            )
                          : (row) => (
                                <TrackTranslationsForm
                                    entryId={row.entryId}
                                    artistNames={row.translations.artistNames}
                                    recordingsAlbums={
                                        row.translations.recordingsAlbums
                                    }
                                    recordings={row.translations.recordings}
                                    onSuccess={(data) =>
                                        updateEntry(row.entryId, data)
                                    }
                                />
                            ),
                  showExpandColumn: true,
                  expandByColumnOnly: true,
                  expandHeaderColumnRenderer: () => undefined,
                  expandHeaderColumnRenderer: ({ isAnyExpands }) => (
                      <FontAwesomeIcon
                          icon={isAnyExpands ? faSquareMinus : faSquarePlus}
                          className="cursor-pointer"
                          fixedWidth
                      />
                  ),
                  expandColumnRenderer: ({ expandable, expanded }) =>
                      expandable ? (
                          <FontAwesomeIcon
                              icon={expanded ? faMinus : faPlus}
                              className="cursor-pointer"
                              fixedWidth
                          />
                      ) : undefined,
                  expanded: expanded,
                  nonExpandable: nonExpandable,
                  onExpand: (row, isExpand) => {
                      setState((prev) => ({
                          ...prev,
                          expanded: isExpand
                              ? [...prev.expanded, row.id]
                              : prev.expanded.filter((id) => id != row.id),
                      }));
                  },
                  onExpandAll: (isExpandAll) => {
                      setState((prev) => ({
                          ...prev,
                          expanded: isExpandAll ? rows.map(({ id }) => id) : [],
                      }));
                  },
              }
            : undefined;

    const rowClasses =
        mode === CHARTS_MODE.TRANSLATION
            ? (row) => (row.translated ? "table-success" : undefined)
            : mode === CHARTS_MODE.MATCHING
            ? (row) =>
                  row.discarded
                      ? "table-danger"
                      : isMatched(chart.type, row)
                      ? "table-success"
                      : undefined
            : undefined;

    return (
        <Charts
            isLoading={isLoading}
            columns={columns}
            rows={getFilteredRows({
                rows,
                filter,
                chartType: chart.type,
                treatment: chart.treatment,
            })}
            expandRow={expandRow}
            rowClasses={rowClasses}
            filter={filter}
            setFilter={setFilter}
            mode={mode}
            {...props}
        />
    );
};

export default EnhancedCharts;
