import React, { useState, useEffect, useCallback } from "react";
import Planning from "./Planning";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";

const EnhancedPlanning = (props) => {
    const [plannings, setPlannings] = useState([]);
    const [filteredPlannings, setFilteredPlannings] = useState(plannings);
    const [isLoading, setIsLoading] = useState(true);
    const { locale } = useParams();
    const { api } = useAPI();

    const getPlannings = useCallback(() => {
        api.get("plannings", { locale, mode: "picture" })
            .then((response) => {
                setPlannings(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        getPlannings();
    }, [getPlannings]);

    return (
        <Planning
            {...props}
            plannings={plannings}
            isLoading={isLoading}
            filteredPlannings={filteredPlannings}
            setFilteredPlannings={setFilteredPlannings}
        />
    );
};

export default EnhancedPlanning;
