import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Picture from "./Picture";
import EditWizard from "shared/components/EditWizard";
import "./Curation.css";

const Curation = ({
    isLoading,
    selectedPicture,
    setSelectedPicture,
    currentTab,
    setCurrentTab,
    onEdit,
    onAdd,
    pictures,
    getPictures,
    isEdit,
    miscEntityId,
}) => {
    return (
        <div className="misc-entity-management-curation">
            <EditWizard
                pictureId={selectedPicture.pictureId}
                versionId={selectedPicture.versionId}
                setSelectedPicture={setSelectedPicture}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                onSuccess={getPictures}
                creationParams={{ id_misc_entity: miscEntityId }}
                isEdit={isEdit}
                isShows={true}
            />
            <div className="row mb-3">
                <div className="col">
                    <Button variant="primary" onClick={onAdd} size="lg">
                        <FontAwesomeIcon icon={faImage} className="mr-2" />
                        <I18n t="common.picture.action.add" />
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Widget
                        title={`misc_entity.management.imageCuration.head`}
                        isLoading={isLoading}
                        enableCollapse
                    >
                        <Widget.Body className="border-top">
                            {pictures.length === 0 ? (
                                <h3 className="text-center mt-4">
                                    <I18n t="misc_entity.management.imageCuration.empty" />
                                </h3>
                            ) : (
                                <div className="grid mt-4">
                                    {pictures.map((picture) => (
                                        <Picture
                                            key={`${picture.id_picture}-${picture.id_version}`}
                                            picture={picture}
                                            onEdit={onEdit}
                                            getPictures={getPictures}
                                        />
                                    ))}
                                </div>
                            )}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default Curation;
