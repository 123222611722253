import { createSelector } from "reselect";

export const getFirstLetter = (artist) =>
    artist.match(/^[a-zA-Z]/)?.[0].toUpperCase() ?? "#";

export const getFilteredRows = createSelector(
    [(args) => args.chart, (args) => args.filter, (args) => args.titleId],
    (rows, filter, titleId) => {
        let filteredRows = rows;

        if (filter.status !== null) {
            filteredRows = filteredRows.filter(
                ({ id, validated, discarded, reported }) =>
                    id === titleId ||
                    (filter.status === "NONE" &&
                        !validated &&
                        !discarded &&
                        !reported) ||
                    (filter.status === "VALIDATED" && validated) ||
                    (filter.status === "DISCARDED" && discarded) ||
                    (filter.status === "REPORTED" && reported)
            );
        }

        if (filter.firstLetter !== null) {
            filteredRows = filteredRows.filter(
                ({ id, artist }) =>
                    id === titleId ||
                    getFirstLetter(artist) === filter.firstLetter
            );
        }

        return filteredRows;
    }
);
