import React, { useState, useEffect, useCallback } from "react";
import Wikipedia from "./Wikipedia";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";
import useToggle from "shared/hooks/useToggle";

const schema = yup.object().shape({
    wikipedia: yup
        .array()
        .of(
            yup.object().shape({
                url: yup
                    .string()
                    .trim()
                    .url()
                    .required("utils.form.required")
                    .transform((value) => {
                        if (!value.includes("http")) {
                            return `https://${value}`;
                        }
                        return value.replace("http://", "https://");
                    }),
            })
        )
        .ensure(),
});

const EnhancedWikipedia = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [names, setNames] = useState([]);
    const [show, toggleShow] = useToggle();
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            wikipedia: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "wikipedia",
    });

    const getWikipedia = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/wikipedia`)
            .then((response) => {
                if (!cancelled) {
                    reset({ wikipedia: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getWikipedia, [getWikipedia]);

    const onSubmit = (data) => {
        return api
            .post(`artist/${artistId}/wikipedia`, { locale }, data.wikipedia)
            .then(({ wikipedia, names }) => {
                reset({ wikipedia });

                if (names.length > 0) {
                    setNames(names);
                    toggleShow();
                }

                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikipedia.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikipedia.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Wikipedia
            {...props}
            names={names}
            show={show}
            toggleShow={toggleShow}
            getWikipedia={getWikipedia}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
        />
    );
};

export default EnhancedWikipedia;
