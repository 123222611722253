import React, { useEffect } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    description: yup.string().nullable().required("utils.form.required"),
    genre_fr: yup.string().required("utils.form.required"),
    genre_en: yup.string().required("utils.form.required"),
    genre_ja: yup.string().required("utils.form.required"),
    genre_de: yup.string().required("utils.form.required"),
    genre_ko: yup.string().required("utils.form.required"),
    related: yup
        .array()
        .of(
            yup.object().shape({
                id_related_genre: yup
                    .number()
                    .nullable()
                    .required("utils.form.required"),
                weight: yup.number().nullable().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedCreateOrUpdateModal = ({
    show,
    handleClose,
    genre,
    onSuccess,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "related",
    });

    useEffect(() => {
        reset({
            parent_genre: genre?.id_parent,
            description: genre?.description,
            genre_fr: genre?.langs.find(({ lang }) => lang === "FR")?.name,
            genre_en: genre?.langs.find(({ lang }) => lang === "EN")?.name,
            genre_ja: genre?.langs.find(({ lang }) => lang === "JA")?.name,
            genre_de: genre?.langs.find(({ lang }) => lang === "DE")?.name,
            genre_ko: genre?.langs.find(({ lang }) => lang === "KO")?.name,
            genre_pt: genre?.langs.find(({ lang }) => lang === "PT")?.name,
            genre_es: genre?.langs.find(({ lang }) => lang === "ES")?.name,
            genre_us: genre?.langs.find(({ lang }) => lang === "US")?.name,
            related: genre?.related ?? [],
        });
    }, [reset, genre, show]);

    const onSubmit = (data) => {
        let apiRequest;
        if (genre?.id) {
            apiRequest = api.put(`genre/${genre.id}`, { locale }, data);
        } else {
            apiRequest = api.post(`genre`, { locale }, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `genre.manage.${
                            genre?.id ? "update" : "create"
                        }.success`
                    )
                );
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `genre.manage.${genre?.id ? "update" : "create"}.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <CreateOrUpdateModal
            {...props}
            show={show}
            genre={genre}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            control={control}
            fields={fields}
            append={append}
            remove={remove}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
