import React from "react";
import EditButton from "./EditButton";
import useToggle from "shared/hooks/useToggle";
import { useLocation } from "react-router-dom";

const EnhancedEditButton = ({ onSuccess, ...props }) => {
    const location = useLocation();
    const [showInformation, toggleInformation] = useToggle(false);
    const onInformationSuccess = () => {
        toggleInformation();
        onSuccess?.();
    };

    return (
        <EditButton
            {...props}
            showInformation={showInformation}
            toggleInformation={toggleInformation}
            onInformationSuccess={onInformationSuccess}
            location={location}
        />
    );
};

export default EnhancedEditButton;
