import { MISC_ENTITY_SET } from "./MiscEntityManagement.constants";

export const MiscEntityManagementReducer = (state, action) => {
    switch (action.type) {
        case MISC_ENTITY_SET:
            return {
                ...state,
                miscEntity: action.data,
            };
    }
};
