import React from "react";
import { toast } from "react-toastify";
import AuthorSelect from "./AuthorSelect";
import useAPI from "shared/hooks/useApi";

const EnhancedAuthorSelect = ({ onMatch, onUnmatch, ...props }) => {
    const { api } = useAPI();

    const search = (term, callback) => {
        api.get(`author/search`, { term })
            .then((response) => {
                callback(
                    response.map(({ id, firstname, lastname, signature }) => {
                        return {
                            value: id,
                            label: firstname
                                ? `${firstname} ${lastname} - ${signature}`
                                : `${lastname} - ${signature}`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            onMatch?.(opt.value, opt.label);
        } else if (action.action === "create-option") {
            onMatch?.(null, opt.label);
        } else {
            onUnmatch?.();
        }
    };

    return <AuthorSelect loadOptions={search} onChange={onChange} {...props} />;
};

export default EnhancedAuthorSelect;
