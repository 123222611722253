import React, { useState, useEffect, useCallback } from "react";
import User from "./User";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import ChangePassword from "./Button/ChangePassword";
import Promote from "./Button/Promote";
import Unpromote from "./Button/Unpromote";
import Lock from "./Button/Lock";
import Unlock from "./Button/Unlock";

const EnhancedUser = (props) => {
    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const getUser = useCallback(() => {
        api.get("users")
            .then((response) => {
                setUser(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(getUser, [getUser]);

    const [rolesOptions, setRolesOptions] = useState([]);
    useEffect(() => {
        api.get(`users/roles`)
            .then((response) => {
                setRolesOptions(
                    response.map(({ id, libelle }) => {
                        return { label: libelle, value: id };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale, setRolesOptions]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "user.manage.user.id"),
            sort: true,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "username",
            text: I18n.getTranslation(location, "user.manage.user.username"),
            sort: true,
            formatter: (cell, { is_admin }) => {
                if (is_admin) {
                    return (
                        <>
                            <span className="badge badge-info mr-1">
                                <FontAwesomeIcon icon={faStar} />
                            </span>
                            <span className="align-middle">{cell}</span>
                        </>
                    );
                }

                return cell ?? "-";
            },
        },
        {
            dataField: "email",
            text: I18n.getTranslation(location, "user.manage.user.email"),
            sort: true,
        },
        {
            dataField: "user_roles",
            text: I18n.getTranslation(location, "user.manage.user.user_roles"),
            sort: true,
            formatter: (cell) => {
                if (cell.length > 0) {
                    return cell.map(({ id, libelle }) => {
                        return (
                            <span
                                key={id}
                                className="badge badge-secondary mr-1"
                            >
                                {libelle}
                            </span>
                        );
                    });
                }

                return "-";
            },
            sortValue: (cell) => cell[0]?.libelle,
        },
        {
            dataField: "last_login",
            text: I18n.getTranslation(location, "user.manage.user.last_login"),
            sort: true,
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "expires_at",
            text: I18n.getTranslation(location, "user.manage.user.expires_at"),
            sort: true,
            formatter: (cell, { is_expired }) => {
                if (is_expired) {
                    return (
                        <>
                            <span className="align-middle">{cell}</span>
                            <span className="badge badge-info ml-1">
                                <I18n t="user.manage.user.expires" />
                            </span>
                        </>
                    );
                }

                return cell ?? "-";
            },
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            text: I18n.getTranslation(location, "user.manage.user.action"),
            formatter: (cell, row) => (
                <ButtonGroup>
                    <Update
                        user={row}
                        onSuccess={getUser}
                        rolesOptions={rolesOptions}
                    />
                    <ChangePassword user={row} onSuccess={getUser} />
                    {row.is_admin ? (
                        <Unpromote user={row} onSuccess={getUser} />
                    ) : (
                        <Promote user={row} onSuccess={getUser} />
                    )}
                    {row.enabled ? (
                        <Lock user={row} onSuccess={getUser} />
                    ) : (
                        <Unlock user={row} onSuccess={getUser} />
                    )}
                </ButtonGroup>
            ),
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    const defaultSorted = [
        {
            dataField: "id",
            order: "desc",
        },
    ];

    const rowClasses = (row) => {
        let classes = null;

        if (!row.enabled) {
            classes = "table-danger";
        }

        return classes;
    };

    return (
        <User
            {...props}
            user={user}
            columns={columns}
            isLoading={isLoading}
            options={options}
            getUser={getUser}
            defaultSorted={defaultSorted}
            rolesOptions={rolesOptions}
            rowClasses={rowClasses}
        />
    );
};

export default EnhancedUser;
