import React, { useCallback, useEffect, useState } from "react";
import CreateModal from "./CreateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    name: yup.string().trim().required("utils.form.required"),
    type: yup.string().required("utils.form.required"),
    description: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable(),
    date: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .nullable()
        .test(
            "is-not-0",
            "utils.form.date_zero",
            (value) => value !== "0000-00-00"
        )
        .matches("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "utils.form.date_format"),
});

const EnhancedCreateModal = ({ show, handleClose, onSuccess, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const [state, setState] = useState({ typeOptions: [], isLoading: false });
    const {
        handleSubmit,
        register,
        control,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: null,
            type: "show",
            description: null,
            date: null,
        },
    });

    const onSubmit = (data) => {
        api.post(`miscentity`, {}, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `misc_entity.manage.create.success`
                    )
                );
                handleClose();
                onSuccess?.(response.id_miscentity);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `misc_entity.manage.create.error`,
                        error.message
                    )
                );
            });
    };

    const getTypeOptions = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({ ...prev, isLoading: true }));
        api.get("miscentity/types", { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({ typeOptions: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState({ typeOptions: [], isLoading: false });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [locale]);

    useEffect(getTypeOptions, [getTypeOptions]);

    return (
        <CreateModal
            {...props}
            show={show}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            control={control}
            errors={errors}
            isSubmitting={isSubmitting}
            typeOptions={state.typeOptions}
        />
    );
};

export default EnhancedCreateModal;
