import React from "react";
import { toast } from "react-toastify";
import GenreSelect from "./GenreSelect";
import useAPI from "shared/hooks/useApi";

const EnhancedGenreSelect = ({ onMatch, onUnmatch, locale, ...props }) => {
    const { api } = useAPI();

    const search = (term, callback) => {
        api.get(`genre/search`, { term, locale })
            .then((response) => {
                callback(
                    response.map(({ id, name }) => {
                        return {
                            value: id,
                            label: name,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            onMatch?.(opt.value, opt.label);
        } else {
            onUnmatch?.();
        }
    };

    return <GenreSelect loadOptions={search} onChange={onChange} {...props} />;
};

export default EnhancedGenreSelect;
