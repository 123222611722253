import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import LabelInformationModal from "shared/components/LabelInformationModal";

const MetaUpdate = ({
    pictureId,
    showInformation,
    toggleInformation,
    onInformationSuccess,
}) => {
    return (
        <>
            <div
                className="h-100 w-100 d-flex"
                onClick={toggleInformation}
                style={{ cursor: "pointer" }}
            >
                <FontAwesomeIcon
                    icon={faListAlt}
                    size="2x"
                    className="mx-auto my-auto"
                />
            </div>

            <LabelInformationModal
                id_picture={pictureId}
                show={showInformation}
                handleHide={toggleInformation}
                onSuccess={onInformationSuccess}
            />
        </>
    );
};

export default MetaUpdate;
