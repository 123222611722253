import React from "react";
import PicturesLabelGallery from "./PicturesLabelGallery";
import { useLocation } from "react-router";

const EnhancedPicturesLabelGallery = (props) => {
    const location = useLocation();
    return <PicturesLabelGallery {...props} location={location} />;
};

export default EnhancedPicturesLabelGallery;
