import React from "react";
import { Controller } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faLink } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import InputSelect from "shared/components/Form/InputSelect";
import InputText from "shared/components/Form/InputText";
import ArtistSelect from "shared/components/ArtistSelect";

const Collaboration = ({
    getCollaboration,
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    fields,
    append,
    remove,
    location,
    locale,
    roleOptions,
}) => {
    return (
        <Form
            onSubmit={!isSubmitting && !isLoading && isDirty ? onSubmit : null}
        >
            <Widget
                title={`artist.management.collaboration.head`}
                actions={
                    <Button
                        variant="primary"
                        onClick={() =>
                            append({
                                artist: null,
                                start_date: null,
                                end_date: null,
                                id_role: null,
                            })
                        }
                    >
                        <I18n t={`utils.button.add`} />
                    </Button>
                }
                enableCollapse
            >
                <Widget.Body
                    className="widget-table-overflow mb-0 border-top border-bottom"
                    style={{ overflowY: "auto", maxHeight: "250px" }}
                >
                    {isLoading ? (
                        <Loader className="m-2" />
                    ) : (
                        <table
                            className="table table-striped table-sm table-header-fixed mb-0"
                            style={{ tableLayout: "fixed" }}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        <I18n t="artist.management.collaboration.artist" />
                                    </th>
                                    <th
                                        style={{
                                            width: "calc(1em + 1rem)",
                                        }}
                                    ></th>
                                    <th
                                        style={{
                                            width: "13rem",
                                        }}
                                    >
                                        <I18n t="artist.management.collaboration.start_date" />
                                    </th>
                                    <th
                                        style={{
                                            width: "13rem",
                                        }}
                                    >
                                        <I18n t="artist.management.collaboration.end_date" />
                                    </th>
                                    <th
                                        style={{
                                            width: "13rem",
                                        }}
                                    >
                                        <I18n t="artist.management.collaboration.role" />
                                    </th>
                                    <th
                                        style={{
                                            width: "calc(1.25em + 2px + 2.6rem)",
                                        }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.length > 0 ? (
                                    fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`collaboration.${index}.artist`}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <ArtistSelect
                                                            innerRef={ref}
                                                            id={value?.id}
                                                            name={value?.name}
                                                            onMatch={(
                                                                id,
                                                                name
                                                            ) => {
                                                                onChange({
                                                                    id: id,
                                                                    name: name,
                                                                });
                                                            }}
                                                            error={
                                                                errors
                                                                    ?.collaboration?.[
                                                                    index
                                                                ]?.artist && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .collaboration[
                                                                            index
                                                                        ].artist
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                            isClearable={false}
                                                            locale={locale}
                                                            showError={false}
                                                            enablePortal
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {item.artist?.id && (
                                                    <a
                                                        href={`/${locale}/artist/${item.artist.id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            fixedWidth
                                                        />
                                                    </a>
                                                )}
                                            </td>
                                            <td>
                                                <InputText
                                                    {...register(
                                                        `collaboration.${index}.start_date`
                                                    )}
                                                    defaultValue={
                                                        item.start_date
                                                    }
                                                    showError={false}
                                                    error={
                                                        errors?.collaboration?.[
                                                            index
                                                        ]?.start_date && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .collaboration[
                                                                    index
                                                                ].start_date
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <InputText
                                                    {...register(
                                                        `collaboration.${index}.end_date`
                                                    )}
                                                    defaultValue={item.end_date}
                                                    showError={false}
                                                    error={
                                                        errors?.collaboration?.[
                                                            index
                                                        ]?.end_date && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .collaboration[
                                                                    index
                                                                ].end_date
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`collaboration.${index}.id_role`}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputSelect
                                                            ref={ref}
                                                            value={
                                                                value !== null
                                                                    ? {
                                                                          value,
                                                                          label: roleOptions.find(
                                                                              ({
                                                                                  id,
                                                                              }) =>
                                                                                  String(
                                                                                      id
                                                                                  ) ===
                                                                                  String(
                                                                                      value
                                                                                  )
                                                                          )
                                                                              ?.nom,
                                                                      }
                                                                    : null
                                                            }
                                                            options={roleOptions.map(
                                                                ({
                                                                    id,
                                                                    nom,
                                                                }) => {
                                                                    return {
                                                                        value: id,
                                                                        label: nom,
                                                                    };
                                                                }
                                                            )}
                                                            onChange={(opt) => {
                                                                onChange(
                                                                    opt
                                                                        ? opt.value
                                                                        : null
                                                                );
                                                            }}
                                                            isClearable={false}
                                                            showError={false}
                                                            error={
                                                                errors
                                                                    ?.collaboration?.[
                                                                    index
                                                                ]?.id_role && [
                                                                    I18n.getTranslation(
                                                                        location,
                                                                        errors
                                                                            .collaboration[
                                                                            index
                                                                        ]
                                                                            .id_role
                                                                            .message
                                                                    ),
                                                                ]
                                                            }
                                                            enablePortal
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                        remove(index)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        fixedWidth
                                                    />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <I18n t="artist.management.collaboration.empty" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </Widget.Body>
                <Widget.Body className="mt-3">
                    <div className="d-flex flex-row-reverse">
                        <Button
                            variant="secondary ml-2"
                            disabled={isSubmitting || isLoading}
                            onClick={
                                !isSubmitting && !isLoading
                                    ? getCollaboration
                                    : null
                            }
                            title={I18n.getTranslation(
                                location,
                                "artist.management.collaboration.reset.title"
                            )}
                        >
                            <I18n t="utils.button.reset" />
                        </Button>
                        <Button
                            variant="success"
                            disabled={isSubmitting || isLoading || !isDirty}
                            onClick={
                                !isSubmitting && !isLoading && isDirty
                                    ? handleSubmit(onSubmit)
                                    : null
                            }
                            title={I18n.getTranslation(
                                location,
                                `artist.management.collaboration.update.title`
                            )}
                        >
                            {isSubmitting ? (
                                <Loader size="1x" />
                            ) : (
                                <I18n t={`utils.button.edit`} />
                            )}
                        </Button>
                    </div>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default Collaboration;
