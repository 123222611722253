import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    name: yup.string().ensure().trim().required("utils.form.required"),
});

export const useCreateOrUpdateModal = ({ onCreate, onUpdate }) => {
    const [state, setState] = useState({
        show: false,
        id: null,
        enabled: null,
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
        },
    });

    const showCreateModal = () => {
        reset({ name: "" });
        setState({
            show: true,
            id: null,
            enabled: true,
        });
    };
    const showUpdateModal = ({ id, name, enabled }) => {
        reset({ name });
        setState({
            show: true,
            id,
            enabled,
        });
    };
    const onHide = () =>
        setState((state) => ({
            ...state,
            show: false,
        }));

    const onSubmit = ({ name }) =>
        (state.id === null
            ? onCreate({ name })
            : onUpdate({
                  id: state.id,
                  name,
                  enabled: state.enabled,
              })
        ).then(onHide);

    return {
        showCreateModal,
        showUpdateModal,
        createOrUpdateModalProps: {
            ...state,
            action: state.id === null ? "create" : "update",
            errors,
            register,
            isSubmitting,
            isDirty,
            onHide,
            onSubmit: handleSubmit(onSubmit),
        },
    };
};
