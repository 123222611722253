import React from "react";
import { NavLink } from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";

const RepositoryList = ({ repositories, repository }) => {
    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="cnm.repositories.repositories.empty" />
            </td>
        </tr>
    );
    if (repositories.length !== 0) {
        rows = repositories.map((rowRepository) => (
            <tr
                key={rowRepository}
                className={
                    rowRepository === repository ? "table-info" : undefined
                }
            >
                <td>
                    <NavLink to={`/cnm/repositories/${rowRepository}`}>
                        <I18n t={`cnm.repositories.${rowRepository}.title`} />
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <Widget
            title="cnm.repositories.repositories.title"
            className="align-self-start pb-0"
        >
            <Widget.Body
                className="widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                <table
                    className="table table-striped"
                    style={{ marginBottom: "0px" }}
                >
                    <tbody>{rows}</tbody>
                </table>
            </Widget.Body>
        </Widget>
    );
};

export default RepositoryList;
