import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import "./Tracks.css";

const Tracks = ({ tracks, columns, options, defaultSorted }) => {
    const { SearchBar } = Search;
    return (
        <ToolkitProvider
            keyField="ms_track_ids"
            data={tracks}
            columns={columns}
            search
        >
            {(toolkitprops) => (
                <Widget title="recommendation.results">
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-12">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "recommendation.search"
                                    )}
                                />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body className="widget-table-overflow pb-1">
                        <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            hover
                            classes="mb-1"
                            pagination={paginationFactory(options)}
                            defaultSorted={defaultSorted}
                        />
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Tracks;
