import React from "react";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";
import { InputGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { addToClipboard } from "shared/functions/AddToClipboard";

const ApiToken = ({ token, locale }) => {
    return (
        <InputGroup>
            <InputGroup.Prepend>
                <Button variant="secondary" type="button" disabled>
                    <I18n t="api.monitoring.token" />
                </Button>
            </InputGroup.Prepend>
            <InputText defaultValue={token} disabled />
            <InputGroup.Append>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => addToClipboard(token, locale)}
                >
                    <FontAwesomeIcon icon={faCopy} fixedWidth />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
};

export default ApiToken;
