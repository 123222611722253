import React from "react";
import MoveButton from "./MoveButton";
import useToggle from "shared/hooks/useToggle";
import { useLocation } from "react-router-dom";

const EnhancedMoveButton = ({ onSuccess, ...props }) => {
    const location = useLocation();
    const [showMove, toggleMove] = useToggle(false);
    const onMoveSuccess = () => {
        toggleMove();
        onSuccess?.();
    };

    return (
        <MoveButton
            {...props}
            showMove={showMove}
            toggleMove={toggleMove}
            onMoveSuccess={onMoveSuccess}
            location={location}
        />
    );
};

export default EnhancedMoveButton;
