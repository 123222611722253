import React from "react";
import InputSwitch from "shared/components/Form/InputSwitch";

const ExportSwitch = ({ checked, disabled, onChange }) => {
    return (
        <InputSwitch
            checked={checked}
            disabled={disabled}
            onChange={onChange}
        />
    );
};

export default ExportSwitch;
