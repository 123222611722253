import React from "react";
import Picture from "./Picture";

const Pictures = ({ pictures, getGallery }) => {
    return (
        <div className="d-flex flex-wrap">
            {pictures.map((picture) => (
                <Picture
                    key={picture.id_picture_version}
                    picture={picture}
                    getGallery={getGallery}
                />
            ))}
        </div>
    );
};

export default Pictures;
