import React from "react";
import Picture from "./Picture";
import { useLocation } from "react-router";

const EnhancedPicture = (props) => {
    const location = useLocation();
    return <Picture {...props} location={location} />;
};

export default EnhancedPicture;
