import React from "react";
import Widget from "shared/components/Widget";
import InputSelect from "shared/components/Form/InputSelect";
import InputDate from "shared/components/Form/InputDate";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import moment from "moment";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./Charts.css";

const Charts = ({
    isLoading,
    charts,
    columns,
    options,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
}) => {
    return (
        <div className="artist-management-charts">
            <div className="row">
                <div className="col">
                    <Widget
                        title={`artist.management.chartsList.head`}
                        enableCollapse
                    >
                        <Widget.Body>
                            <div className="row mb-4">
                                <div className="col">
                                    <InputSelect
                                        onChange={(opt) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                source: opt ? opt.value : null,
                                            });
                                        }}
                                        placeholder={
                                            <I18n t="artist.management.chartsList.source" />
                                        }
                                        options={filterOptions.source}
                                        enablePortal
                                    />
                                </div>
                                <div className="col">
                                    <InputSelect
                                        onChange={(opt) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                area: opt ? opt.value : null,
                                            });
                                        }}
                                        placeholder={
                                            <I18n t="artist.management.chartsList.area" />
                                        }
                                        options={filterOptions.area}
                                        enablePortal
                                    />
                                </div>
                                <div className="col">
                                    <InputSelect
                                        onChange={(opt) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                format: opt ? opt.value : null,
                                            });
                                        }}
                                        placeholder={
                                            <I18n t="artist.management.chartsList.format" />
                                        }
                                        options={filterOptions.format}
                                        enablePortal
                                    />
                                </div>
                                <div className="col">
                                    <InputSelect
                                        onChange={(opt) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                country: opt ? opt.value : null,
                                            });
                                        }}
                                        placeholder={
                                            <I18n t="artist.management.chartsList.country" />
                                        }
                                        options={filterOptions.country}
                                        enablePortal
                                    />
                                </div>
                                <div className="col">
                                    <InputSelect
                                        onChange={(opt) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                origin: opt ? opt.value : null,
                                            });
                                        }}
                                        placeholder={
                                            <I18n t="artist.management.chartsList.origin" />
                                        }
                                        options={filterOptions.origin}
                                        enablePortal
                                    />
                                </div>
                                <div className="col">
                                    <InputDate
                                        selected={
                                            selectedFilterOptions.date_out
                                                ? new Date(
                                                      selectedFilterOptions.date_out
                                                  )
                                                : null
                                        }
                                        onChange={(date) => {
                                            setSelectedFilterOptions({
                                                ...selectedFilterOptions,
                                                date_out: date
                                                    ? moment(date).format(
                                                          "YYYY-MM-DD"
                                                      )
                                                    : null,
                                            });
                                        }}
                                        placeholder="artist.management.chartsList.date_out"
                                        portalId="root-portal-datepicker"
                                        isClearable
                                    />
                                </div>
                            </div>
                        </Widget.Body>
                        <Widget.Body className="widget-table-overflow">
                            {isLoading ? (
                                <Loader className="mb-4" />
                            ) : (
                                <BootstrapTable
                                    keyField="id"
                                    data={charts}
                                    columns={columns}
                                    bootstrap4
                                    striped
                                    hover
                                    noDataIndication={
                                        <I18n t="artist.management.chartsList.empty" />
                                    }
                                    pagination={paginationFactory(options)}
                                    classes="mb-0"
                                />
                            )}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default Charts;
