import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import InputSwitch from "shared/components/Form/InputSwitch";
import useModalState from "shared/hooks/useModalState";
import ExportSwitch from "./ExportSwitch";
import CleanButton from "./CleanButton";
import DeleteButton from "./DeleteButton";
import FileList from "./FileList";

const EnhancedFileList = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [state, setState] = useState({
        files: [],
        isLoading: true,
    });
    const uploadModal = useModalState();
    const importModal = useModalState({ fileId: null, fileName: null });

    const getFiles = useCallback(() => {
        return api
            .get(`snep/file`)
            .then((response) => {
                setState((prev) => ({ ...prev, files: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getFiles()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getFiles]);

    const onUpload = (file) => {
        setState((prev) => ({
            ...prev,
            files: [file, ...prev.files],
        }));
        importModal.onShow({ fileId: file.id, fileName: file.name });
    };

    const updateFile = (newFile) => {
        setState((prev) => ({
            ...prev,
            files: prev.files.map((file) =>
                file.id === newFile.id ? newFile : file
            ),
        }));
    };

    const deleteFile = (fileId) => {
        setState((prev) => ({
            ...prev,
            files: prev.files.filter((file) => file.id !== fileId),
        }));
    };

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "snep.file.fileList.id"),
        },
        {
            dataField: "chart.name",
            text: I18n.getTranslation(location, "snep.file.fileList.name"),
            formatter: (cell, row) => cell ?? row.name,
        },
        {
            dataField: "period",
            text: I18n.getTranslation(location, "snep.file.fileList.period"),
            formatter: (cell) =>
                cell ? `${cell.startDate} - ${cell.endDate}` : "-",
        },
        {
            dataField: "date",
            text: I18n.getTranslation(location, "snep.file.fileList.date"),
        },
        {
            dataField: "imported",
            text: I18n.getTranslation(location, "snep.file.fileList.imported"),
            formatter: (cell, row) => (
                <InputSwitch
                    checked={cell}
                    disabled={cell}
                    onChange={() =>
                        importModal.onShow({
                            fileId: row.id,
                            fileName: row.name,
                        })
                    }
                />
            ),
            searchable: false,
            classes: "text-center",
            headerClasses: "text-center",
        },
        {
            dataField: "exportedSnep",
            text: I18n.getTranslation(
                location,
                "snep.file.fileList.exportedSnep"
            ),
            formatter: (cell, row) =>
                row.chart?.exportSnep ? (
                    <ExportSwitch
                        fileId={row.id}
                        type="snep"
                        checked={cell}
                        disabled={!row.imported || cell}
                        onSuccess={updateFile}
                    />
                ) : (
                    "-"
                ),
            searchable: false,
            classes: "text-center",
            headerClasses: "text-center",
        },
        {
            dataField: "exportedYadeck",
            text: I18n.getTranslation(
                location,
                "snep.file.fileList.exportedYadeck"
            ),
            formatter: (cell, row) =>
                row.chart?.exportYadeck ? (
                    <ExportSwitch
                        fileId={row.id}
                        type="yadeck"
                        checked={cell}
                        disabled={!row.imported || cell}
                        onSuccess={updateFile}
                    />
                ) : (
                    "-"
                ),
            searchable: false,
            classes: "text-center",
            headerClasses: "text-center",
        },
        {
            dataField: "actions",
            isDummyField: true,
            text: "",
            formatter: (cell, row) => (
                <>
                    <CleanButton
                        fileId={row.id}
                        disabled={!row.exportedSnep && !row.exportedYadeck}
                        onSuccess={updateFile}
                    />
                    <DeleteButton
                        fileId={row.id}
                        onSuccess={() => deleteFile(row.id)}
                    />
                </>
            ),
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = (row) =>
        !row.imported ? "text-muted font-italic" : null;

    return (
        <FileList
            {...props}
            {...state}
            columns={columns}
            pagination={pagination}
            rowClasses={rowClasses}
            uploadModal={uploadModal}
            onUpload={onUpload}
            importModal={importModal}
            updateFile={updateFile}
            location={location}
        />
    );
};

export default EnhancedFileList;
