import React from "react";
import Widget from "shared/components/Widget";
import I18n, { NavLink } from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";

const Albums = ({ albums, isLoading, limit, columns, pagination, query }) => {
    return (
        <ToolkitProvider keyField="id" data={albums} columns={columns}>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title="search.albumsData.head"
                            isLoading={isLoading}
                            actions={
                                limit && (
                                    <NavLink
                                        to={`/search/albums?${query.toString()}`}
                                    >
                                        <I18n t="search.more" />
                                    </NavLink>
                                )
                            }
                        >
                            <Widget.Body
                                className="widget-table-overflow border-bottom border-top"
                                style={{
                                    margin: "15px -20px 0px -20px",
                                }}
                            >
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    bootstrap4
                                    hover
                                    striped
                                    bordered={false}
                                    noDataIndication={
                                        <I18n t="artist.search.empty" />
                                    }
                                    classes="table-layout-auto mb-0"
                                />
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-3">
                                    <>
                                        <div className="col">
                                            <PaginationTotalStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                        <div className="col">
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    </>
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default Albums;
