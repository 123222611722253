import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

const Panel = ({ title, hidePanel, right, setRight, children }) => {
    return (
        <div
            className="chat-sidebar"
            style={{
                right,
                width: "350px",
                zIndex: "10",
                overflow: "hidden auto",
            }}
        >
            <div className="text-white">
                <header
                    className="chat-sidebar-header d-flex"
                    style={{ width: "100%" }}
                >
                    <span
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            right === "0px"
                                ? setRight("-315px")
                                : setRight("0px");
                        }}
                    >
                        <FontAwesomeIcon icon={faBars} size="lg" />
                    </span>
                    <h5 className="chat-sidebar-title flex-grow-1 text-center">
                        {title}
                    </h5>
                    <span
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => hidePanel()}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </span>
                </header>
                <div className="chat-sidebar-panel">{children}</div>
            </div>
        </div>
    );
};

export default Panel;
