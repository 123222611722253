import React from "react";
import { useParams } from "react-router";
import ChartPopover from "./ChartPopover";
import { useLocation } from "react-router-dom";

const EnhancedChartPopover = React.forwardRef((props, ref) => {
    const { locale } = useParams();
    const location = useLocation();

    return (
        <ChartPopover
            {...props}
            ref={ref}
            locale={locale}
            location={location}
        />
    );
});

export default EnhancedChartPopover;
