import React, { useEffect, useState, useCallback } from "react";
import PictureInformationModalGetty from "./PictureInformationModalGetty";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedPictureInformationModalGetty = ({
    pictureId,
    versionId,
    show,
    handleHide,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });
    const [pictureInformation, setPictureInformation] = useState();

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(`picture/${pictureId}`, versionId && { versionId })
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, pictureId]);

    useEffect(getPicture, [getPicture]);

    const getPictureInformation = useCallback(() => {
        let cancelled = false;

        if (pictureId && show) {
            setIsLoading(true);
            api.get(`picture/getty/${pictureId}/information`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        setPictureInformation(response);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                });
        }

        return () => {
            cancelled = true;
        };
    }, [pictureId, show, locale]);

    useEffect(getPictureInformation, [getPictureInformation]);

    const onNext = () => {
        onSuccess?.();
    };

    return (
        <PictureInformationModalGetty
            {...props}
            {...state}
            show={show}
            onNext={onNext}
            locale={locale}
            handleHide={handleHide}
            location={location}
            isLoading={isLoading}
            pictureInformation={pictureInformation}
        />
    );
};

export default EnhancedPictureInformationModalGetty;
