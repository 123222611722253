import React from "react";
import I18n from "shared/lib/I18n";
import Row from "./Row";
import Loader from "shared/components/Loader";

const List = ({ isLoading, plannings }) => {
    let planningList = (
        <tr>
            <td colSpan="42" style={{ textAlign: "center" }}>
                <I18n t="plannings.picture.planning.empty" />
            </td>
        </tr>
    );

    if (plannings.length !== 0) {
        planningList = plannings.map((planning) => (
            <Row key={planning.id} planning={planning} />
        ));
    }

    return (
        <div
            className="widget-body widget-table-overflow"
            style={{
                maxHeight: "200px",
                overflow: "auto",
            }}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <table
                    className="table table-striped table-header-fixed"
                    style={{ marginBottom: "0px" }}
                >
                    <thead>
                        <tr>
                            <th>
                                <I18n t="plannings.picture.planning.status" />
                            </th>
                            <th>
                                <I18n t="plannings.picture.planning.title" />
                            </th>
                            <th>
                                <I18n t="plannings.picture.planning.startDate" />
                            </th>
                            <th>
                                <I18n t="plannings.picture.planning.endDate" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{planningList}</tbody>
                </table>
            )}
        </div>
    );
};

export default List;
