import React from "react";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ArtistSelect from "shared/components/ArtistSelect";
import AlbumSelect from "shared/components/AlbumSelect";
import I18n from "shared/lib/I18n";

const TransferForm = ({
    selectedArtist,
    handleSubmit,
    errors,
    control,
    setValue,
    artistIsLoading,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    locale,
}) => {
    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <Form.Group>
                <Form.Label>
                    <I18n t="artist.management.disco.objects.release.basic.transfer.artist" />
                </Form.Label>
                <Controller
                    control={control}
                    name="artist"
                    render={({ field: { onChange, value, ref } }) => (
                        <ArtistSelect
                            innerRef={ref}
                            id={value?.id}
                            name={value?.name}
                            isLoading={artistIsLoading}
                            onMatch={(id, name) => {
                                onChange({
                                    id: id,
                                    name: name,
                                });
                                setValue("album", null);
                            }}
                            onUnmatch={() => {
                                onChange(null);
                                setValue("album", null);
                            }}
                            error={
                                errors.artist && [
                                    I18n.getTranslation(
                                        location,
                                        errors.artist.message
                                    ),
                                ]
                            }
                            isClearable={false}
                            locale={locale}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <I18n t="artist.management.disco.objects.release.basic.transfer.album" />
                </Form.Label>
                <Controller
                    control={control}
                    name="album"
                    render={({ field: { onChange, value, ref } }) => (
                        <AlbumSelect
                            id_artist={selectedArtist}
                            innerRef={ref}
                            id={value?.id}
                            title={value?.title}
                            onMatch={(id, title) => onChange({ id, title })}
                            onUnmatch={() => onChange(null)}
                            error={
                                errors.album && [
                                    I18n.getTranslation(
                                        location,
                                        errors.album.message
                                    ),
                                ]
                            }
                            isClearable={false}
                            locale={locale}
                            isDisabled={!selectedArtist}
                        />
                    )}
                />
            </Form.Group>
            <div className="d-flex flex-row-reverse pb-3">
                <Button
                    variant="success"
                    disabled={isSubmitting || !isDirty}
                    onClick={
                        !isSubmitting && isDirty ? handleSubmit(onSubmit) : null
                    }
                    title={I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.release.basic.transfer.title`
                    )}
                >
                    <I18n t={`utils.button.transfer`} />
                </Button>
            </div>
        </Form>
    );
};

export default TransferForm;
