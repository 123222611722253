import React from "react";
import RatingForm from "./RatingForm";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";

const schema = yup.object().shape({
    criteria: yup.string().nullable().required("utils.form.required"),
    errorType: yup.string().nullable().required("utils.form.required"),
    comment: yup.string().trim().required("utils.form.required"),
});

const EnhancedRatingForm = ({ line, onSuccess, ...props }) => {
    const { api } = useAPI();
    const { lyricSampleId, locale } = useParams();
    const location = useLocation();
    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            criteria: null,
            errorType: null,
            comment: null,
        },
    });
    const criteria = watch("criteria");
    const errorType = watch("errorType");

    const onSubmit = (data) => {
        api.post(
            `apple/sample/assessment/ratings`,
            { locale },
            {
                lineNumber: line,
                lyricSampleId: parseInt(lyricSampleId, 10),
                assessmentError: parseInt(data.errorType, 10),
                assessmentComment: data.comment,
            }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `apple.sample.assessment.lyric.rating.create.success`
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `apple.sample.assessment.lyric.rating.create.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <RatingForm
            criteria={criteria}
            errorType={errorType}
            setValue={setValue}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            location={location}
            {...props}
        />
    );
};

export default EnhancedRatingForm;
