import React from "react";
import { Link } from "shared/lib/I18n";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import InputFile from "shared/components/Form/InputFile";
import InputSelect from "shared/components/Form/InputSelect";

const UploadButton = ({
    repository,
    yearOptions,
    show,
    onShow,
    onHide,
    conflicts,
    errors,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
}) => {
    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.upload.modal.title" />
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={!isSubmitting ? onSubmit : null}>
                    <Modal.Body>
                        <div className="alert alert-primary">
                            <I18n
                                t={`cnm.repositories.${repository}.upload_help`}
                            />
                        </div>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.upload.modal.file" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="file"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputFile
                                        name={name}
                                        ref={ref}
                                        dataBrowse={I18n.getTranslation(
                                            location,
                                            "cnm.repositories.upload.modal.browse"
                                        )}
                                        onChange={(e) =>
                                            onChange(e.target.files[0])
                                        }
                                        label={
                                            value !== null
                                                ? value.name
                                                : I18n.getTranslation(
                                                      location,
                                                      repository ===
                                                          "cypok_languages"
                                                          ? "cnm.repositories.upload.modal.file_placeholder.json"
                                                          : "cnm.repositories.upload.modal.file_placeholder.csv"
                                                  )
                                        }
                                        error={
                                            errors.file && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.file.message
                                                ),
                                            ]
                                        }
                                        accept={
                                            repository === "cypok_languages"
                                                ? "application/jsonl,application/json-lines,.jsonl,application/json,.json"
                                                : "text/csv,.csv"
                                        }
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.upload.modal.year" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="year"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        name={name}
                                        value={
                                            value !== null
                                                ? {
                                                      value: value,
                                                      label: value,
                                                  }
                                                : null
                                        }
                                        options={yearOptions.map((year) => ({
                                            value: year,
                                            label: year,
                                        }))}
                                        onChange={(opt) => {
                                            onChange(opt.value);
                                        }}
                                        error={
                                            errors.year && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.year.message
                                                ),
                                            ]
                                        }
                                        enablePortal
                                        isClearable={false}
                                    />
                                )}
                            />
                        </Form.Group>
                        {conflicts.length !== 0 && (
                            <div className="alert alert-danger mb-0">
                                <p>
                                    <I18n
                                        t={`cnm.repositories.${repository}.conflict`}
                                    />
                                </p>
                                <ul className="mb-0">
                                    {conflicts.map(
                                        (
                                            {
                                                name,
                                                year,
                                                titleId,
                                                artist,
                                                title,
                                            },
                                            index
                                        ) => (
                                            <li key={index}>
                                                <I18n
                                                    t="cnm.repositories.upload.conflict.usedIn"
                                                    args={{ name }}
                                                />
                                                <Link
                                                    to={`/cnm/report/${year}/${titleId}/consolidate`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <I18n
                                                        t="cnm.repositories.upload.conflict.titleLink"
                                                        args={{ artist, title }}
                                                    />
                                                </Link>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            disabled={isSubmitting || !isDirty}
                            type="submit"
                        >
                            {isSubmitting ? (
                                <>
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSpinner}
                                        pulse
                                    />
                                    <I18n t="cnm.repositories.upload.modal.submitting" />
                                </>
                            ) : (
                                <I18n t="cnm.repositories.upload.modal.submit" />
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Button variant="success" onClick={onShow}>
                <I18n t="cnm.repositories.upload.button.title" />
            </Button>
        </>
    );
};

export default UploadButton;
