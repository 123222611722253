import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const LyricfindLanguageReference = ({
    languages,
    language,
    selectedLanguage,
    onSelect,
    location,
}) => {
    return (
        <div className="input-group">
            {language !== null && (
                <div className="input-group-prepend reference-list">
                    <Button
                        variant={
                            (language == selectedLanguage ? "" : "outline-") +
                            "secondary"
                        }
                        title={I18n.getTranslation(
                            location,
                            language == selectedLanguage
                                ? "cnm.report.consolidate.edit.language.unselect"
                                : "cnm.report.consolidate.edit.language.select"
                        )}
                        onClick={() => {
                            onSelect(
                                language == selectedLanguage ? null : language
                            );
                        }}
                    >
                        <I18n
                            t={`cnm.report.consolidate.edit.language.${language}`}
                        />
                    </Button>
                </div>
            )}
            <div className="form-control reference-list">
                {languages !== null &&
                    [...languages.sort()].map((lang) => (
                        <Button key={lang} variant="outline-secondary" disabled>
                            {lang}
                        </Button>
                    ))}
            </div>
        </div>
    );
};

export default LyricfindLanguageReference;
