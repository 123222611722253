import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";

const DeleteButton = ({ handleDelete, location }) => {
    return (
        <Tippy
            content={I18n.getTranslation(
                location,
                "common.picture.action.del.title"
            )}
            trigger="mouseenter"
        >
            <button
                className={clsx("btn bg-transparent border-0 text-danger")}
                onClick={handleDelete}
            >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
        </Tippy>
    );
};

export default DeleteButton;
