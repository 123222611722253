import React from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import RecordingSelect from "./RecordingSelect";
import useAPI from "shared/hooks/useApi";

const EnhancedRecordingSelect = ({
    onMatch,
    onUnmatch,
    artistIds,
    locale,
    ...props
}) => {
    const { api } = useAPI();

    const search = useDebouncedCallback((term, callback) => {
        api.get(
            `searchengine/recording`,
            artistIds && artistIds.length > 0
                ? {
                      term,
                      idArtists: artistIds.join(","),
                  }
                : { term }
        )
            .then((response) => {
                callback(
                    response.map((row) => {
                        const name = [
                            row.title,
                            row.subtitle,
                            row.duration,
                            row.artists.map(({ name }) => name).join(", "),
                            row.album_name,
                            row.album_format,
                            row.album_type,
                        ];
                        return {
                            value: { id: row.id, title: row.title },
                            label: name.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            onMatch?.(opt.value.id, opt.value.title);
        } else {
            onUnmatch?.();
        }
    };

    return (
        <RecordingSelect loadOptions={search} onChange={onChange} {...props} />
    );
};

export default EnhancedRecordingSelect;
