import React from "react";
import Widget from "shared/components/Widget";
import CreateOrUpdate from "pages/ArtistManagement/Discography/Forms/Release/CreateOrUpdate";
import Delete from "pages/ArtistManagement/Discography/Actions/Release/Delete";
import Transfer from "pages/ArtistManagement/Discography/Actions/Release/Transfer";

const Basic = ({ artistId, albumId, releaseId }) => {
    if (releaseId === null || typeof releaseId === "undefined") {
        return null;
    }

    return (
        <Widget
            title="artist.management.disco.objects.release.basic.head"
            actions={
                <>
                    <Transfer />
                    <Delete />
                </>
            }
            enableCollapse
        >
            <Widget.Body className="pt-3 border-top">
                <CreateOrUpdate
                    artistId={artistId}
                    albumId={albumId}
                    releaseId={releaseId}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Basic;
