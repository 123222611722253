import React from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import RecordingMatcher from "./RecordingMatcher";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedRecordingMatcher = ({
    listId,
    rowId,
    locale,
    recording,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();

    const onMatch = (recordingId) => {
        api.post(
            `matching/track/snep/list/${listId}/${rowId}/track/${recordingId}`
        )
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.match.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.match.error",
                        error.message
                    )
                );
            });
    };

    const onUnmatch = () => {
        if (recording) {
            api.delete(`matching/track/snep/list/${listId}/${rowId}/track`)
                .then((response) => {
                    onChange(response);

                    toast.success(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.unmatch.success"
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "matching.track.track.unmatch.error",
                            error.message
                        )
                    );
                });
        }
    };

    return (
        <RecordingMatcher
            onMatch={onMatch}
            onUnmatch={onUnmatch}
            locale={locale}
            recording={recording}
            {...props}
        />
    );
};

export default EnhancedRecordingMatcher;
