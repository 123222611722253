import React, { useState, useEffect, useCallback } from "react";
import Social from "./Social";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";

const schema = yup.object().shape({
    social: yup
        .array()
        .of(
            yup.object().shape({
                url: yup
                    .string()
                    .nullable()
                    .trim()
                    .url()
                    .transform((value) => value || null),
            })
        )
        .ensure(),
});

const EnhancedSocial = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors, dirtyFields },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            social: [],
        },
    });
    const { fields } = useFieldArray({
        control,
        name: "social",
    });

    const getSocial = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/social`)
            .then((response) => {
                if (!cancelled) {
                    reset({ social: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getSocial, [getSocial]);

    const onSubmit = (data) => {
        const expandedData = data.social.map((social, key) => {
            return {
                ...social,
                is_dirty:
                    dirtyFields.social.hasOwnProperty(key) &&
                    Object.entries(dirtyFields.social[key]).some(
                        ([key, value]) => value
                    ),
            };
        });

        return api
            .post(`artist/${artistId}/social`, { locale }, expandedData)
            .then((response) => {
                reset({ social: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.social.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.social.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Social
            {...props}
            getSocial={getSocial}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            location={location}
        />
    );
};

export default EnhancedSocial;
