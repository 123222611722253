import React, { useEffect } from "react";
import I18n from "shared/lib/I18n";
import Select from "react-select";
import { useLocation } from "react-router";
import Error from "shared/components/Form/Error";

const InputSelect = React.forwardRef(
    (
        {
            error,
            hasError,
            options,
            value,
            defaultValue,
            onChange,
            onLoad,
            placeholder,
            styles,
            hideSelectedOptions,
            isMulti,
            closeMenuOnSelect,
            isClearable,
            enablePortal,
            innerRef,
            showError = true,
            ...props
        },
        ref
    ) => {
        const location = useLocation();
        const defaultHideSelectedOptions = hideSelectedOptions ?? false;
        const defaultIsMulti = isMulti ?? false;
        const defaultIsClearable = isClearable ?? true;
        const defaultCloseMenuOnSelect = closeMenuOnSelect ?? true;
        const defaultPlaceholder =
            placeholder ??
            I18n.getTranslation(location, "utils.select.placeholder");
        const defaultStyle = {
            option: (provided, state) => ({
                ...provided,
                color: state.isDisabled
                    ? "#6c757d"
                    : state.isSelected
                    ? "#fff"
                    : "#000",
                cursor: state.isDisabled ? "default" : "pointer",
            }),
            control: (provided, state) => ({
                ...provided,
                borderColor:
                    hasError || (error && error.length !== 0)
                        ? "#dc3545"
                        : provided.borderColor,
                borderRadius: ".25em",
                cursor: state.isDisabled ? "default" : "pointer",
            }),
            clearIndicator: (provided) => ({
                ...provided,
                color:
                    hasError || (error && error.length !== 0)
                        ? "#dc3545"
                        : provided.color,
                padding: "0 4px",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                padding: "0 4px",
            }),
            loadingIndicator: (provided) => ({
                ...provided,
                padding: "0 4px",
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                margin: "5px 0",
            }),
            valueContainer: (provided) => ({
                ...provided,
                padding: "0 11px",
                lineHeight: "1.5",
                fontSize: "1rem",
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 999,
            }),
            menuPortal: (provided) => ({
                ...provided,
                zIndex: 1999,
            }),
            ...styles,
        };

        useEffect(() => {
            onLoad?.();
        }, []);

        return (
            <>
                <Select
                    isClearable={defaultIsClearable}
                    menuPortalTarget={enablePortal && document.body}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    styles={defaultStyle}
                    placeholder={defaultPlaceholder}
                    noOptionsMessage={() => <I18n t="utils.select.noOptions" />}
                    options={options}
                    hideSelectedOptions={defaultHideSelectedOptions}
                    isMulti={defaultIsMulti}
                    closeMenuOnSelect={defaultCloseMenuOnSelect}
                    theme={(provided) => {
                        return {
                            ...provided,
                            spacing: {
                                baseUnit: 3,
                                controlHeight: 29,
                                menuGutter: 10,
                            },
                        };
                    }}
                    innerRef={innerRef}
                    ref={ref}
                    {...props}
                />

                {showError && <Error error={error} className="mt-3" />}
            </>
        );
    }
);

export default InputSelect;
