import React from "react";
import { Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import deezerIcon from "assets/static/images/deezer.png";
import qobuzIcon from "assets/static/images/qobuz.png";
import napsterIcon from "assets/static/images/napster.png";
import appleIcon from "assets/static/images/apple.png";
import spotifyIcon from "assets/static/images/spotify.png";
import musicbrainzIcon from "assets/static/images/musicbrainz.png";
import discogsIcon from "assets/static/images/discogs.png";
import linemusicIcon from "assets/static/images/linemusic.png";
import Tippy from "@tippyjs/react";

const ReleasesLink = ({
    deezer,
    qobuz,
    napster,
    itunes,
    spotify,
    musicbrainz,
    discogs,
    linemusic,
    show,
    handleClose,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="album.view.profileReleases.links" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {deezer.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={deezerIcon} width="25px" alt="Deezer" />
                        <span className="ml-2">Deezer</span>
                        <span className="mx-1">:</span>
                        {deezer.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {qobuz.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={qobuzIcon} width="25px" alt="Qobuz" />
                        <span className="ml-2">Qobuz</span>
                        <span className="mx-1">:</span>
                        {qobuz.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {napster.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={napsterIcon} width="25px" alt="Napster" />
                        <span className="ml-2">Napster</span>
                        <span className="mx-1">:</span>
                        {napster.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {itunes.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={appleIcon} width="25px" alt="Itunes" />
                        <span className="ml-2">Apple Music</span>
                        <span className="mx-1">:</span>
                        {itunes.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {spotify.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={spotifyIcon} width="25px" alt="Spotify" />
                        <span className="ml-2">Spotify</span>
                        <span className="mx-1">:</span>
                        {spotify.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {musicbrainz.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img
                            src={musicbrainzIcon}
                            width="25px"
                            alt="Musicbrainz"
                        />
                        <span className="ml-2">Musicbrainz</span>
                        <span className="mx-1">:</span>
                        {musicbrainz.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {discogs.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={discogsIcon} width="25px" alt="Discogs" />
                        <span className="ml-2">Discogs</span>
                        <span className="mx-1">:</span>
                        {discogs.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {linemusic.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={linemusicIcon} width="25px" alt="LineMusic" />
                        <span className="ml-2">LineMusic</span>
                        <span className="mx-1">:</span>
                        {linemusic.map(({ id, url, id_partner }, index) => (
                            <Tippy
                                key={id}
                                content={url ?? id_partner}
                                trigger="mouseenter"
                            >
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ReleasesLink;
