import React from "react";
import I18n from "shared/lib/I18n";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ArtistDelete = ({
    artist,
    objectLinked,
    columns,
    isDeleting,
    handleDelete,
    location,
}) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "artist.delete.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="artist.delete.breadcrumb" />
                    </li>
                </ol>

                <div className="row">
                    <div className="col-md-12">
                        <div className="jumbotron pt-1 pb-1 mb-0">
                            <h1 className="display-3">{artist?.nom ?? "-"}</h1>
                            <hr className="my-4" />
                            <p>
                                {`${artist?.id ?? "-"} - ${
                                    artist?.type ?? "-"
                                } - ${artist?.country ?? "-"} - ${
                                    artist?.main_genre ?? "-"
                                }`}
                            </p>
                        </div>
                        <BootstrapTable
                            keyField="object"
                            data={objectLinked}
                            columns={columns}
                            bootstrap4
                            striped
                            hover
                            noDataIndication={<I18n t="artist.delete.empty" />}
                            classes="bg-white"
                        />
                    </div>
                </div>

                <div className="d-flex flex-row-reverse">
                    <Button
                        variant="danger"
                        disabled={isDeleting}
                        onClick={handleDelete}
                        type="button"
                    >
                        <I18n t="utils.button.delete" />
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default ArtistDelete;
