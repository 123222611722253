import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import Loader from "shared/components/Loader";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const TopArtist = ({
    isLoading,
    id_country,
    countryOptions,
    location,
    artists,
    columns,
}) => {
    const country = countryOptions.find((c) => id_country === c.id)?.nom;
    return (
        <Widget
            title="landing.industryStats.top_artist"
            titleArgs={country}
            actions={
                <Tippy
                    content={I18n.getTranslation(
                        location,
                        "landing.industryStats.top_artist_description"
                    )}
                    trigger="mouseenter"
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="lg"
                            fixedWidth
                        />
                    </span>
                </Tippy>
            }
        >
            <Widget.Body>
                {isLoading ? (
                    <Loader />
                ) : (
                    <ToolkitProvider
                        keyField="id"
                        data={artists}
                        columns={columns}
                    >
                        {(toolkitprops) => (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                condensed
                                hover
                                bordered={false}
                                classes="table-layout-auto mb-0"
                            />
                        )}
                    </ToolkitProvider>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default TopArtist;
