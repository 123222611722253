import React, { useState, useEffect, useCallback } from "react";
import Stats from "./Stats";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedStats = ({ startDate, endDate, ...props }) => {
    const [stats, setStats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const { api } = useAPI();

    const exportName = `${startDate}-${endDate}_${I18n.getTranslation(
        location,
        "data_external.deezer_top_songs.breadcrumb"
    )}_${I18n.getTranslation(
        location,
        "data_external.deezer_top_songs.stats.nav"
    )}`;

    const getStats = useCallback(() => {
        setIsLoading(true);
        api.get(`data/external/deezer/top/songs/stats/${startDate}/${endDate}`)
            .then((response) => {
                setStats(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
                setIsError(true);
            });
    }, [startDate, endDate]);

    useEffect(getStats, [getStats]);

    const columns = [
        {
            dataField: "date",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.date"
            ),
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.country"
            ),
        },
        {
            dataField: "treatment",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.treatment"
            ),
        },
        {
            dataField: "nb_match_simple_ms",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_simple_ms"
            ),
        },
        {
            dataField: "nb_match_creation_ms",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_creation_ms"
            ),
        },
        {
            dataField: "nb_discard_ms",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_discard_ms"
            ),
        },
        {
            dataField: "nb_match_lf",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_lf"
            ),
        },
        {
            dataField: "nb_request_lf",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_request_lf"
            ),
        },
        {
            dataField: "nb_forgot_lf",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_forgot_lf"
            ),
        },
        {
            dataField: "nb_match_lf_cas1",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_lf_cas1"
            ),
        },
        {
            dataField: "nb_match_lf_cas23_with_request",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_lf_cas23_with_request"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.stats.nb_match_lf_cas23_with_request"
                ),
        },
        {
            dataField: "nb_match_lf_cas23_without_request",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_match_lf_cas23_without_request"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.stats.nb_match_lf_cas23_without_request"
                ),
        },
        {
            dataField: "nb_forgot_ms_lf",
            text: I18n.getTranslation(
                location,
                "data_external.deezer_top_songs.stats.nb_forgot_ms_lf"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "data_external.deezer_top_songs.stats.nb_forgot_ms_lf"
                ),
        },
    ];

    return (
        <Stats
            {...props}
            stats={stats}
            columns={columns}
            isLoading={isLoading}
            isError={isError}
            exportName={exportName}
        />
    );
};

export default EnhancedStats;
