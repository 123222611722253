import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import AlbumTranslationsForm from "./AlbumTranslationsForm";

const schema = yup.object().shape({
    artistName: yup.string().ensure().trim(),
    albumTitle: yup.string().ensure().trim(),
});

const getFormValues = ({ artistNames, albumTitle }) => ({
    artistName: artistNames[0] ?? "",
    albumTitle: albumTitle ?? "",
});

const EnhancedAlbumTranslationsForm = ({
    entryId,
    artistNames,
    albumTitle,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale, language } = useParams();

    const defaultValues = useMemo(
        () => getFormValues({ artistNames, albumTitle }),
        [artistNames, albumTitle]
    );

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    // Reset the form when the matchings are modified
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit = (data) => {
        api.post(
            `matching/top/translation/album/${language}/${entryId}/translation`,
            { locale },
            data
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.charts.charts.albumTranslationsForm.save.success"
                    )
                );
                onSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "common.charts.charts.albumTranslationsForm.save.error",
                        error.message
                    )
                );
            });
    };

    return (
        <AlbumTranslationsForm
            location={location}
            register={register}
            artistNames={artistNames}
            isSubmitting={isSubmitting}
            isDirty={isDirty || artistNames.length > 1}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            onReset={() => reset()}
            {...props}
        />
    );
};

export default EnhancedAlbumTranslationsForm;
