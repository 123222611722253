import React, { useState, useEffect, useCallback } from "react";
import Languages from "./Languages";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const EnhancedLanguages = (props) => {
    const { api } = useAPI();
    const { albumId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        principal: null,
        secondary: [],
        isLoading: true,
    });

    const getData = useCallback(() => {
        return api
            .get(`album/${albumId}/sung-languages`, { locale })
            .then((response) => {
                setState((prev) => ({ ...prev, ...response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getData()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getData]);

    return <Languages {...props} {...state} />;
};

export default EnhancedLanguages;
