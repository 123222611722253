import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";

const Album = ({
    id,
    title,
    release_date,
    format,
    type,
    cover_art,
    genres,
}) => {
    const location = useLocation();

    const year =
        release_date === null
            ? null
            : new Date(release_date).getFullYear() || null;

    return (
        <li
            style={{
                cursor: "auto",
                height: "120px",
                borderTop: "1px solid #fff",
            }}
        >
            <img
                style={{
                    height: "120px",
                    width: "auto",
                    marginRight: "10px",
                }}
                src={cover_art}
                alt={I18n.getTranslation(
                    location,
                    "artistTitle.resultsList.album.picture"
                )}
                height="120"
            />
            <div
                style={{
                    marginLeft: "10px",
                }}
            >
                <h3>{title}</h3>
                <p>
                    <I18n t="artistTitle.resultsList.album.id" args={id} />
                </p>
                <p>
                    {format} {type}
                    {year && ` - ${year}`}
                </p>
                <p>{genres?.map(({ name }) => name).join(" - ")}</p>
            </div>
        </li>
    );
};

export default Album;
