import React, { useState } from "react";
import ArtistSearch from "./ArtistSearch";
import { useLocation } from "react-router";

const EnhancedArtistSearch = (props) => {
    const [state, setState] = useState({
        artists: [],
        isLoading: false,
    });
    const location = useLocation();

    return (
        <ArtistSearch
            {...props}
            {...state}
            setState={setState}
            location={location}
        />
    );
};

export default EnhancedArtistSearch;
