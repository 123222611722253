import {
    LABEL_PROMO_DISABLE_SET,
    LABEL_PROMO_DISABLE_INCREMENT,
    LABEL_PROMO_DISABLE_DECREMENT,
    PICTURES_LABEL_AWAITING_SET,
    PICTURES_LABEL_AWAITING_DECREMENT,
} from "./SidebarNotification.constants";

export const SidebarNotificationReducer = (state, action) => {
    switch (action.type) {
        case LABEL_PROMO_DISABLE_SET:
            return {
                ...state,
                labelPromoDisable: action.data,
            };
        case LABEL_PROMO_DISABLE_INCREMENT:
            return { ...state, labelPromoDisable: state.labelPromoDisable + 1 };
        case LABEL_PROMO_DISABLE_DECREMENT:
            return {
                ...state,
                labelPromoDisable:
                    state.labelPromoDisable && state.labelPromoDisable - 1,
            };
        case PICTURES_LABEL_AWAITING_SET:
            return {
                ...state,
                picturesLabelAwaiting: action.data,
            };
        case PICTURES_LABEL_AWAITING_DECREMENT:
            return {
                ...state,
                picturesLabelAwaiting:
                    state.picturesLabelAwaiting &&
                    state.picturesLabelAwaiting - 1,
            };
    }
};
