import React, { useState, useEffect, useCallback } from "react";
import RecordingCharts from "./RecordingCharts";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import {
    getFilterOptions,
    getFilteredCharts,
    getDefaultSelectedChart,
} from "./RecordingCharts.pure";
import { useParams } from "react-router";

const EnhancedRecordingCharts = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { recordingId } = useIntParams();
    const { locale } = useParams();
    const [{ entries, isLoading }, setState] = useState({
        entries: [],
        isLoading: true,
    });
    const [filter, setFilter] = useState({
        format: [],
        reference: false,
    });
    const [selectedCharts, setSelectedCharts] = useState([]);

    const getEntries = useCallback(() => {
        return api
            .get(`recording/${recordingId}/charts`, { locale })
            .then((response) => {
                setState((prev) => ({ ...prev, entries: response }));
                setSelectedCharts(
                    getDefaultSelectedChart({ entries: response })
                );
                if (response.length === 0) {
                    setNoData("recordingCharts");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [recordingId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getEntries()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getEntries]);

    return (
        <RecordingCharts
            {...props}
            entries={entries}
            isLoading={isLoading}
            filterOptions={getFilterOptions({
                entries,
            })}
            charts={getFilteredCharts({ entries, filter })}
            filter={filter}
            setFilter={setFilter}
            selectedCharts={selectedCharts}
            setSelectedCharts={setSelectedCharts}
        />
    );
};

export default EnhancedRecordingCharts;
