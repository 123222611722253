import React from "react";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import Loader from "shared/components/Loader";

const Featuring = ({ featurings, isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="loader" style={{ zIndex: "9999999" }}>
                    <Loader size="2x" />
                </div>
            )}
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`album.view.profileInformation.featurings`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div
                className="row"
                style={{ maxHeight: "5rem", overflow: "auto" }}
            >
                <div className="col">
                    {featurings.map((artist, index) => (
                        <span key={artist.id}>
                            <ArtistLink
                                artistId={artist.id}
                                content={artist.name}
                                view
                            />
                            {index < featurings.length - 1 && ", "}
                        </span>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Featuring;
