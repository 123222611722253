import React from "react";
import RecordingSelect from "shared/components/RecordingSelect";

const RecordingMatcher = ({
    artist,
    recording,
    locale,
    onMatch,
    onUnmatch,
}) => {
    return (
        <RecordingSelect
            id={recording?.id}
            title={recording?.title}
            artistIds={[artist?.id]}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={onUnmatch}
            menuPlacement="auto"
            enablePortal
        />
    );
};

export default RecordingMatcher;
