import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ArtistSelect from "shared/components/ArtistSelect";
import Transfer from "pages/ArtistManagement/Discography/Actions/Album/Transfer";
import "./Artist.css";

const Artist = ({
    initForm,
    isLoading,
    handleSubmit,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    locale,
}) => {
    return (
        <Widget
            title="artist.management.disco.objects.album.artist.head"
            actions={<Transfer />}
            enableCollapse
        >
            <Widget.Body className="widget-table-overflow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="text-right w-25">
                                        <I18n t="artist.management.disco.objects.album.artist.main" />
                                    </td>
                                    <td className="artist">
                                        <Controller
                                            control={control}
                                            name="artist1"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <ArtistSelect
                                                    innerRef={ref}
                                                    id={value?.id}
                                                    name={value?.name}
                                                    isClearable={false}
                                                    locale={locale}
                                                    isDisabled
                                                />
                                            )}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-right">
                                        <I18n t="artist.management.disco.objects.album.artist.principal" />
                                    </td>
                                    <td className="artist">
                                        <Controller
                                            control={control}
                                            name="artist2"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <ArtistSelect
                                                    innerRef={ref}
                                                    id={value?.id}
                                                    name={value?.name}
                                                    onMatch={(id, label) => {
                                                        onChange({
                                                            id: id,
                                                            name: label,
                                                        });
                                                    }}
                                                    onUnmatch={() => {
                                                        onChange(null);
                                                    }}
                                                    locale={locale}
                                                    enablePortal
                                                />
                                            )}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-right">
                                        <I18n t="artist.management.disco.objects.album.artist.principal" />
                                    </td>
                                    <td className="artist">
                                        <Controller
                                            control={control}
                                            name="artist3"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <ArtistSelect
                                                    innerRef={ref}
                                                    id={value?.id}
                                                    name={value?.name}
                                                    onMatch={(id, label) => {
                                                        onChange({
                                                            id: id,
                                                            name: label,
                                                        });
                                                    }}
                                                    onUnmatch={() => {
                                                        onChange(null);
                                                    }}
                                                    locale={locale}
                                                    enablePortal
                                                />
                                            )}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className="d-flex flex-row-reverse pb-3"
                            style={{ marginRight: "20px" }}
                        >
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? initForm
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.disco.objects.album.artist.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.disco.objects.album.artist.update.title`
                                )}
                            >
                                <I18n t={`utils.button.edit`} />
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Artist;
