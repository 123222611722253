import React from "react";
import { useParams } from "react-router";
import I18n from "shared/lib/I18n";
import BadgeGroup from "shared/components/BadgeGroup";
import PictureCheckButton from "shared/components/PictureCheckButton";

const Task = ({ task, informations, onCheck, showComment }) => {
    const { locale } = useParams();
    const informationStatus = Object.keys(task.information).pop();
    const colorStatus = {
        not_found: task.information?.not_found?.last_check_date
            ? "warning"
            : "danger",
        getty: "success",
        label: "success",
    };

    return (
        <tr>
            <td>
                {task.ms_artist.id ? (
                    <a
                        href={`/${locale}/artist/${task.ms_artist.id}/edit${
                            ["not_found", "getty"].includes(informationStatus)
                                ? "/getty"
                                : ""
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.ms_artist.name}
                    </a>
                ) : (
                    "-"
                )}
            </td>
            <td>{task.album_title ?? "-"}</td>
            <td>{task.track_title ?? "-"}</td>
            <td>{task.latest_added_date ?? "-"}</td>
            <td className={`table-${colorStatus[informationStatus]}`}>
                <I18n t={`plannings.picture.tasks.${informationStatus}`} />
            </td>
            <td>
                <BadgeGroup datas={informations} />
                {informationStatus === "not_found" &&
                task.information.not_found.check_message ? (
                    // eslint-disable-next-line
                    <a
                        href="#"
                        className="badge badge-info"
                        onClick={(e) =>
                            showComment(
                                e,
                                task.information.not_found.check_message
                            )
                        }
                    >
                        <I18n t={`plannings.picture.tasks.comment`} />
                    </a>
                ) : undefined}
            </td>
            <td>
                {informationStatus === "not_found" && (
                    <PictureCheckButton
                        artistId={task.ms_artist.id}
                        comment={task.information.not_found?.check_message}
                        onCheck={(comment) => onCheck()}
                    />
                )}
            </td>
        </tr>
    );
};

export default Task;
