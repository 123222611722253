import React from "react";
import I18n from "shared/lib/I18n";
import YearSelect from "./YearSelect";
import Chart from "./Chart";
import Edit from "./Edit";
import { Helmet } from "react-helmet";
import "./CNMConsolidation.css";

const CNMConsolidation = ({
    year,
    chartType,
    platformId,
    titleId,
    chart,
    isLoading,
    onSuccess,
    editable,
    location,
}) => {
    return (
        <div className="content-wrap cnm-consolidation">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.cnm.consolidation"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb d-flex align-items-center">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.title" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.cnm.consolidation" />
                    </li>
                    <li className="breadcrumb-item active d-flex align-items-center">
                        <YearSelect />
                    </li>
                </ol>

                <Chart
                    year={year}
                    chartType={chartType}
                    platformId={platformId}
                    titleId={titleId}
                    chart={chart}
                    isLoading={isLoading}
                />
                <Edit
                    year={year}
                    chartType={chartType}
                    titleId={titleId}
                    onSuccess={onSuccess}
                    editable={editable}
                />
            </main>
        </div>
    );
};

export default CNMConsolidation;
