import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";

const PlatformsLegend = ({ platforms }) => {
    return [{ id: 0, name: "Music Story" }, ...(platforms ?? [])].map(
        ({ id, name }) => (
            <span key={id} className="text-nowrap ml-3">
                <FontAwesomeIcon
                    icon={faCircle}
                    size="sm"
                    className={`text-${platform_color(id)} mr-1`}
                />
                {name}
            </span>
        )
    );
};

export default PlatformsLegend;
