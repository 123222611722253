import React from "react";
import SkipedFilter from "./SkipedFilter";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const EnhancedSkipedFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.skiped.true"
            ),
            value: true,
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.artist.artist.filter.skiped.false"
            ),
            value: false,
        },
    ];

    return <SkipedFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedSkipedFilter;
