import React, { useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import UpdateModalButton from "./UpdateModalButton";

const schema = yup.object().shape({
    title: yup.string().ensure().trim().required("utils.form.required"),
});

const EnhancedUpdateModalButton = ({
    artist,
    title: { id, title, msRecordings },
    onUpdate,
}) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            title: "",
            msRecordings: [],
        },
    });

    const onShow = () => {
        reset({
            title,
            msRecordings,
        });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = ({ title, msRecordings }) => {
        api.post(
            `cnm/title/${id}`,
            { locale },
            { title, msRecordings: msRecordings.map(({ id }) => id) }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.cnm_artists_titles.titles.edit.success"
                    )
                );
                onUpdate({ id, title, msRecordings });
                onHide();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <UpdateModalButton
            show={show}
            errors={errors}
            register={register}
            control={control}
            artist={artist}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onShow={onShow}
            onHide={onHide}
            onSubmit={handleSubmit(onSubmit)}
            locale={locale}
            location={location}
        />
    );
};

export default EnhancedUpdateModalButton;
