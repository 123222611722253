import React from "react";
import PicturesLabelStatus from "./PicturesLabelStatus";
import { useLocation } from "react-router";

const EnhancedPicturesLabelStatus = (props) => {
    const location = useLocation();
    return <PicturesLabelStatus {...props} location={location} />;
};

export default EnhancedPicturesLabelStatus;
