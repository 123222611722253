import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import Translations from "./Translations";

const schema = yup.object().shape({
    translations: yup
        .array()
        .of(
            yup.object().shape({
                language: yup.object().required("utils.form.required"),
                title: yup
                    .string()
                    .nullable()
                    .trim()
                    .required("utils.form.required"),
                subtitle: yup
                    .string()
                    .nullable()
                    .trim()
                    .transform((value) => value || null),
            })
        )
        .ensure(),
});

const EnhancedTranslations = (props) => {
    const { api } = useAPI();
    const { artistId, albumId, releaseId, trackId, recordingId, locale } =
        useParams();
    const location = useLocation();
    const [languages, setLanguages] = useState({ data: [], isLoading: false });
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, isValid, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            translations: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "translations",
    });

    const fetchLanguages = useCallback(() => {
        let cancelled = false;

        setLanguages({ data: [], isLoading: true });
        api.get("languages", { locale })
            .then((response) => {
                if (!cancelled) {
                    setLanguages({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setLanguages({ data: [], isLoading: false });
                }
            });

        return () => {
            cancelled = true;
        };
    }, [locale]);

    useEffect(fetchLanguages, [fetchLanguages]);

    const fetchTranslations = useCallback(() => {
        let cancelled = false;

        if (recordingId) {
            setIsLoading(true);
            api.get(
                `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}/translations`,
                { locale }
            )
                .then((response) => {
                    if (!cancelled) {
                        reset({ translations: response });
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        reset();
                        setIsLoading(false);
                    }
                });
        } else {
            reset();
            setIsLoading(false);
        }

        return () => {
            cancelled = true;
        };
    }, [artistId, albumId, releaseId, trackId, recordingId]);

    useEffect(fetchTranslations, [fetchTranslations]);

    const onSubmit = (data) => {
        return api
            .post(
                `artist/${artistId}/album/${albumId}/release/${releaseId}/track/${trackId}/recording/${recordingId}/translations`,
                { locale },
                data.translations
            )
            .then((response) => {
                reset(data);
                toast.success(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.translations.update.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.translations.update.error",
                        error.message
                    )
                );
            });
    };

    return (
        <Translations
            fields={fields}
            errors={errors}
            languages={languages.data}
            register={register}
            control={control}
            append={append}
            remove={remove}
            onSubmit={handleSubmit(onSubmit)}
            fetchTranslations={fetchTranslations}
            isLoading={languages.isLoading || isLoading}
            isDirty={isDirty}
            isValid={isValid}
            isSubmitting={isSubmitting}
            location={location}
            {...props}
        />
    );
};

export default EnhancedTranslations;
