import React from "react";
import { useWatch } from "react-hook-form";
import GenreReference from "./GenreReference";

const EnhancedGenreReference = ({ control, name, ...props }) => {
    const selectedGenre = useWatch({
        control,
        name,
    });
    return <GenreReference selectedGenre={selectedGenre} {...props} />;
};

export default EnhancedGenreReference;
