import React, { useReducer, useMemo } from "react";
import { MiscEntityManagementContext } from "./MiscEntityManagement.context";
import { MiscEntityManagementReducer } from "./MiscEntityManagement.reducer";

const initialState = {
    miscEntity: {},
};

export const MiscEntityManagementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        MiscEntityManagementReducer,
        initialState
    );
    const contextValue = useMemo(
        () => ({
            dispatchMiscEntity: dispatch,
            miscEntity: state.miscEntity,
        }),
        [state, dispatch]
    );

    return (
        <MiscEntityManagementContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </MiscEntityManagementContext.Provider>
    );
};
