import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    CSVExport,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Widget from "shared/components/Widget";

const Stats = ({ stats, columns, isLoading, exportName }) => {
    const { ExportCSVButton } = CSVExport;
    return (
        <ToolkitProvider
            keyField="date"
            data={stats}
            columns={columns}
            exportCSV={{
                fileName: `${exportName
                    .toLowerCase()
                    .replaceAll(" ", "_")}.csv`,
            }}
        >
            {(toolkitprops) => (
                <Widget
                    actions={
                        <ExportCSVButton
                            {...toolkitprops.csvProps}
                            disabled={!stats.length}
                            className="mt-3 mb-3"
                        >
                            <I18n t="utils.button.export" />
                        </ExportCSVButton>
                    }
                >
                    <Widget.Body className="widget-table-overflow">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                striped
                                hover
                                noDataIndication={
                                    <I18n t="data_external.universal_artist.stats.empty" />
                                }
                                classes="table-truncate"
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Stats;
