import React from "react";
import LazyLoad from "react-lazyload";
import Loader from "shared/components/Loader";
import clsx from "clsx";
import ImageLoader from "shared/components/ImageLoader";
import Tippy from "@tippyjs/react";
import I18n from "shared/lib/I18n";
import moment from "moment";
import DetailButton from "./Action/DetailButton";
import "./Picture.css";

const Picture = ({ picture, location, onAdd }) => {
    return (
        <div className={clsx("artist-management-bas-card", "card", "border")}>
            <LazyLoad height="100%" placeholder={<Loader />} once>
                <div className="image-container">
                    <ImageLoader
                        src={
                            picture.display_sizes.find(
                                ({ name }) => name === "comp"
                            )?.uri
                        }
                        className="card-img-top"
                    />
                    <div className="image-content-over">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <DetailButton
                                gettyPicture={picture}
                                onAdd={onAdd}
                            />
                        </div>
                    </div>
                </div>
            </LazyLoad>
            <div className="card-body p-2">
                <p className="card-text d-flex justify-content-around align-items-center">
                    <span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "common.picture.getty.bas.width"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.max_dimensions.width}</span>
                        </Tippy>
                        <span className="mx-1">x</span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "common.picture.getty.bas.height"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.max_dimensions.height}</span>
                        </Tippy>
                    </span>
                    <span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "common.picture.getty.bas.created_at"
                            )}
                            trigger="mouseenter"
                        >
                            <span>
                                {picture.date_created
                                    ? moment(picture.date_created).format(
                                          "YYYY-MM-DD"
                                      )
                                    : "-"}
                            </span>
                        </Tippy>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Picture;
