import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrop } from "@fortawesome/free-solid-svg-icons";
import PictureCropModal from "shared/components/PictureCropModal";
import I18n from "shared/lib/I18n";
import clsx from "clsx";
import Tippy from "@tippyjs/react";

const CropButton = ({
    pictureId,
    versionId,
    showCrop,
    toggleCrop,
    onCropSuccess,
    location,
}) => {
    return (
        <>
            <Tippy
                content={I18n.getTranslation(
                    location,
                    "common.picture.action.crop"
                )}
                trigger="mouseenter"
            >
                <button
                    className={clsx("btn bg-transparent border-0")}
                    onClick={toggleCrop}
                >
                    <FontAwesomeIcon icon={faCrop} fixedWidth />
                </button>
            </Tippy>

            <PictureCropModal
                id_picture={pictureId}
                id_version={versionId}
                show={showCrop}
                handleHide={toggleCrop}
                onSuccess={onCropSuccess}
            />
        </>
    );
};

export default CropButton;
