import React, { useState, useCallback, useEffect } from "react";
import Chart from "./Chart";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";

const EnhancedChart = (props) => {
    const { api } = useAPI();
    const { user } = useUser();
    const [{ chart, availablePlatform, yearStatus, isLoading }, setState] =
        useState({
            chart: [],
            availablePlatform: [],
            yearStatus: null,
            isLoading: false,
        });
    const location = useLocation();
    const { id_pf_or_year, id_year: chartType, locale } = useParams();

    const getChart = useCallback(() => {
        let cancelled = false;
        if (id_pf_or_year) {
            setState({
                chart: [],
                availablePlatform: [],
                yearStatus: null,
                isLoading: true,
            });
            api.get(`cnm/charts/aggregate/${id_pf_or_year}/${chartType}`, {
                locale,
            })
                .then(({ chart, platforms, yearStatus }) => {
                    if (!cancelled) {
                        setState({
                            chart: chart,
                            availablePlatform: platforms,
                            yearStatus,
                            isLoading: false,
                        });
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setState({
                            chart: [],
                            availablePlatform: [],
                            yearStatus: null,
                            isLoading: false,
                        });
                    }
                });
        } else {
            if (!cancelled) {
                setState({
                    chart: [],
                    availablePlatform: [],
                    yearStatus: null,
                    isLoading: false,
                });
            }
        }

        return () => {
            cancelled = true;
        };
    }, [id_pf_or_year, chartType]);

    useEffect(getChart, [getChart]);

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.rank"
            ),
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.artist"
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.title"
            ),
        },
        {
            dataField: "isrcs",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.isrcs"
            ),
            formatter: (cell) =>
                cell && cell.length > 0
                    ? cell.map((isrc) => (
                          <span
                              key={isrc}
                              className="badge badge-secondary ml-1 mt-1"
                          >
                              {isrc}
                          </span>
                      ))
                    : "-",
        },
        {
            dataField: "platforms",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.platforms"
            ),
            formatter: (cell) => (
                <>
                    {cell.map(({ id, name, matched }) => (
                        <span
                            key={id}
                            className={`badge badge-${
                                matched ? "success" : "secondary"
                            } mr-1`}
                        >
                            {name}
                        </span>
                    ))}
                </>
            ),
            searchable: false,
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.charts.aggregate.chart.streams"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "cnm.charts.aggregate.chart.streams"
                ),
            classes: "text-right",
            headerStyle: { width: "7em" },
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
        },
    ];

    const rowClasses = ({ platforms }) => {
        let classes = "table-warning";

        if (platforms.every(({ matched }) => matched)) {
            classes = "table-success";
        }

        return classes;
    };

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 1000,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Chart
            chart={chart}
            year={id_pf_or_year}
            chartType={chartType}
            availablePlatform={availablePlatform}
            columns={columns}
            isLoading={isLoading}
            rowClasses={rowClasses}
            editable={
                user.hasRight("cnm.charts.aggregate.manage") &&
                yearStatus === "IN_PROGRESS"
            }
            pagination={pagination}
            {...props}
        />
    );
};

export default EnhancedChart;
