import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import useIntParams from "shared/hooks/useIntParams";

const defaultHasData = {
    releases: true,
    isrcs: true,
};

const EnhancedProfile = (props) => {
    const { albumId } = useIntParams();
    const [hasData, setHasData] = useState(defaultHasData);
    const setNoData = (key) =>
        setHasData((prev) => ({ ...prev, [key]: false }));
    useEffect(() => {
        setHasData(defaultHasData);
    }, [albumId]);

    return <Profile {...props} hasData={hasData} setNoData={setNoData} />;
};

export default EnhancedProfile;
