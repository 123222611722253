import React from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import MiscEntityManagement from "./MiscEntityManagement";
import { MiscEntityManagementProvider } from "./MiscEntityManagement.provider";

const EnhancedMiscEntityManagement = (props) => {
    const { locale, selectedTab } = useParams();
    const { miscEntityId } = useIntParams();
    const navigate = useNavigate();
    const location = useLocation();

    const onSelectTab = (k) => {
        navigate(`/${locale}/misc-entity/${miscEntityId}/edit/${k}`);
    };

    return (
        <MiscEntityManagementProvider>
            <MiscEntityManagement
                {...props}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                location={location}
            />
        </MiscEntityManagementProvider>
    );
};

export default EnhancedMiscEntityManagement;
