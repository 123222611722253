import React, { useState, useEffect, useCallback, useContext } from "react";
import Tracklist from "./Tracklist";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import RecordingLink from "shared/components/RecordingLink";
import {
    AlbumViewContext,
    RELEASE_HIGHLIGHT_SET,
    ISRC_HIGHLIGHT_SET,
} from "pages/AlbumView";
import Explicit from "shared/components/Explicit";

const defaultState = {
    tracklist: [],
    isLoading: true,
};

const EnhancedTracklist = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const [{ tracklist, isLoading }, setState] = useState(defaultState);
    const { dispatchAlbum, profileSelection } = useContext(AlbumViewContext);
    const isReleaseSelected = !!profileSelection.releaseId;
    const isIsrcSelected = !!profileSelection.isrc;
    const title = `album.view.profileTracklist.${
        isReleaseSelected
            ? "release_head"
            : isIsrcSelected
            ? "isrc_head"
            : "head"
    }`;
    const titleArgs = isReleaseSelected
        ? profileSelection.releaseId
        : isIsrcSelected
        ? profileSelection.isrc
        : null;

    const getTracklist = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));

        return api
            .get(
                `album/tracklist`,
                isReleaseSelected
                    ? { releaseId: profileSelection.releaseId }
                    : isIsrcSelected
                    ? { isrc: profileSelection.isrc }
                    : {}
            )
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    tracklist: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [profileSelection.releaseId, profileSelection.isrc]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getTracklist()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getTracklist]);

    useEffect(() => {
        if (tracklist.length !== 0) {
            if (isReleaseSelected) {
                dispatchAlbum({
                    type: ISRC_HIGHLIGHT_SET,
                    data: tracklist.map(({ isrc }) => isrc),
                });
            }
            if (isIsrcSelected) {
                dispatchAlbum({
                    type: RELEASE_HIGHLIGHT_SET,
                    data: tracklist.map(({ id_release }) => id_release),
                });
            }
        }
    }, [tracklist]);

    const columns = [
        {
            dataField: "id_track",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.id_track"
            ),
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "no_cd",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.no_cd"
            ),
        },
        {
            dataField: "no_piste",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.no_piste"
            ),
            formatter: (cell) => cell ?? "-",
            classes: "border-right",
        },
        {
            dataField: "id_recording",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.id_recording"
            ),
            formatter: (cell, { rn_recording }) => {
                return rn_recording === 1 ? (
                    cell ? (
                        <RecordingLink recordingId={cell} content={cell} />
                    ) : (
                        "-"
                    )
                ) : (
                    ""
                );
            },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.title"
            ),
            formatter: (cell, { id_recording, rn_recording }) => {
                if (rn_recording === 1) {
                    return cell ? (
                        <RecordingLink
                            recordingId={id_recording}
                            content={cell}
                        />
                    ) : (
                        "-"
                    );
                } else {
                    return "";
                }
            },
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.subtitle"
            ),
            formatter: (cell, { rn_recording }) => {
                if (rn_recording === 1) {
                    return cell ?? "-";
                } else {
                    return "";
                }
            },
        },
        {
            dataField: "isrc",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.isrc"
            ),
            formatter: (
                cell,
                { id_recording, rn_recording, recording_parental_advisory }
            ) => {
                if (rn_recording === 1) {
                    return cell ? (
                        <>
                            <RecordingLink
                                recordingId={id_recording}
                                content={cell}
                            />
                            <Explicit
                                className="ml-1"
                                explicit={recording_parental_advisory}
                            />
                        </>
                    ) : (
                        "-"
                    );
                } else {
                    return "";
                }
            },
            classes: "nowrap",
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.duration"
            ),
            formatter: (cell, { rn_recording }) => {
                if (rn_recording === 1) {
                    return cell ?? "-";
                } else {
                    return "";
                }
            },
        },
        {
            dataField: "hit",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.hit"
            ),
            formatter: (cell, { rn_recording }) => {
                if (rn_recording === 1) {
                    return cell ? (
                        <div style={{ width: "60px" }}>
                            <ProgressBar
                                now={(parseInt(cell, 10) / 5) * 100}
                                variant="purple-ms"
                                style={{
                                    height: "8px",
                                }}
                            />
                        </div>
                    ) : (
                        "-"
                    );
                } else {
                    return "";
                }
            },
        },
        {
            dataField: "editorial_pick",
            text: I18n.getTranslation(
                location,
                "album.view.profileTracklist.editorial_pick"
            ),
            formatter: (cell, { rn_recording }) => {
                if (rn_recording === 1) {
                    return cell ? (
                        <div style={{ width: "60px" }}>
                            <ProgressBar
                                now={(parseInt(cell, 10) / 5) * 100}
                                variant="purple-ms"
                                style={{
                                    height: "8px",
                                }}
                            />
                        </div>
                    ) : (
                        "-"
                    );
                } else {
                    return "";
                }
            },
        },
        {
            dataField: "complementary",
            text: I18n.getTranslation(
                location,
                "album.view.profileIsrcs.complementary"
            ),
            formatter: (
                cell,
                { id_recording, has_credits, has_lyric, has_audio }
            ) => (
                <>
                    <RecordingLink
                        recordingId={id_recording}
                        content={
                            <span
                                className={`badge badge-${
                                    has_credits ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_credits" />
                            </span>
                        }
                        part="credits"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id_recording}
                        content={
                            <span
                                className={`badge badge-${
                                    has_lyric ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_lyric" />
                            </span>
                        }
                        part="lyrics"
                        className="mr-1"
                    />
                    <RecordingLink
                        recordingId={id_recording}
                        content={
                            <span
                                className={`badge badge-${
                                    has_audio ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="album.view.profileIsrcs.has_audio" />
                            </span>
                        }
                        part="audio_description"
                    />
                </>
            ),
            isDummyField: true,
        },
    ];

    return (
        <Tracklist
            {...props}
            tracklist={tracklist}
            isLoading={isLoading}
            columns={columns}
            title={title}
            titleArgs={titleArgs}
        />
    );
};

export default EnhancedTracklist;
