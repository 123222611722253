import React, { useState, useEffect, useCallback } from "react";
import Contribution from "./Contribution";
import { useLocation, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { orderByStartDate, groupByRole } from "./Contribution.pure";

const EnhancedContribution = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const location = useLocation();
    const [state, setState] = useState({
        contribution: [],
        roleOptions: [],
        isLoading: true,
    });

    const getContribution = useCallback(() => {
        return api
            .get(`artist/${artistId}/contribution`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    contribution: orderByStartDate(groupByRole(response)),
                }));
                if (response.length === 0) {
                    setNoData("contribution");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getRole = useCallback(() => {
        return api
            .get(`role/${locale}`)
            .then((response) => {
                setState((prev) => ({ ...prev, roleOptions: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getContribution(), getRole()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getContribution, getRole]);

    return <Contribution {...props} {...state} location={location} />;
};

export default EnhancedContribution;
