import React, { useState } from "react";
import DataExternalUniversalArtist from "./DataExternalUniversalArtist";
import useWeekPeriod from "shared/hooks/useWeekPeriod";
import { useLocation } from "react-router";

const EnhancedDataExternalUniversalArtist = (props) => {
    const [selected, setSelected] = useState("stats");
    const [startDate, endDate, previous, next, hasNext] = useWeekPeriod();
    const location = useLocation();

    return (
        <DataExternalUniversalArtist
            selected={selected}
            setSelected={setSelected}
            startDate={startDate}
            endDate={endDate}
            previous={previous}
            next={next}
            hasNext={hasNext}
            location={location}
            {...props}
        />
    );
};

export default EnhancedDataExternalUniversalArtist;
