import React from "react";
import RoleManagement from "./RoleManagement";
import { useLocation } from "react-router";

const EnhancedRoleManagement = (props) => {
    const location = useLocation();
    return <RoleManagement {...props} location={location} />;
};

export default EnhancedRoleManagement;
