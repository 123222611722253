import { useEffect } from "react";

const useOnScroll = (callback) => {
    useEffect(() => {
        const handleScroll = (event) => {
            callback?.(event);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
};

export default useOnScroll;
