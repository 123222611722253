import React, { useState, useEffect, useCallback } from "react";
import Planning from "./Planning";
import { toast } from "react-toastify";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    SUBTASK_STATUS_COLOR,
    SUBTASK_STATUS_ICON,
} from "shared/constants/Planning/SubTask";

const defaultState = {
    isLoading: false,
    plannings: [],
};

const EnhancedPlanning = (props) => {
    const { api } = useApi();
    const { artistId } = useIntParams();
    const [state, setState] = useState(defaultState);
    const location = useLocation();

    const getPlanning = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/tracking/planning`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        plannings: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getPlanning, [getPlanning]);

    const columns = [
        {
            dataField: "start_date",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.date"
            ),
            sort: true,
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "track_title",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.title"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "album_title",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.album"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
            headerStyle: { width: "13rem" },
        },
        {
            dataField: "category",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.category"
            ),
            sort: true,
            formatter: (cell) =>
                cell ? <span className="badge badge-info">{cell}</span> : "-",
            headerStyle: { width: "10rem" },
        },
        {
            dataField: "reason",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.reason"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "classification",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.classification"
            ),
            sort: true,
            formatter: (cell) =>
                cell ? <span className="badge badge-info">{cell}</span> : "-",
            headerStyle: { width: "13rem" },
        },
        {
            dataField: "subtasks",
            text: I18n.getTranslation(
                location,
                "artist.management.trackingPlanning.tasks"
            ),
            formatter: (cell) => {
                return cell.map(({ type, assignment, status }) => (
                    <span
                        key={type + assignment}
                        className={`badge badge-${SUBTASK_STATUS_COLOR[status]} mr-2`}
                        title={I18n.getTranslation(
                            location,
                            `artist.management.trackingPlanning.status.${status}`
                        )}
                    >
                        <FontAwesomeIcon
                            icon={SUBTASK_STATUS_ICON[status]}
                            fixedWidth
                        />
                        {type} - {assignment}
                    </span>
                ));
            },
            headerStyle: { width: "45rem" },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return (
        <Planning {...props} {...state} columns={columns} options={options} />
    );
};

export default EnhancedPlanning;
