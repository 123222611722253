import React, { useState, useEffect, useCallback } from "react";
import Role from "./Role";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { ButtonGroup } from "react-bootstrap";
import Update from "./Button/Update";
import Delete from "./Button/Delete";
import useAPI from "shared/hooks/useApi";

const defaultState = {
    role: [],
    rightsOptions: [],
    isLoading: true,
};

const EnhancedRole = (props) => {
    const { api } = useAPI();
    const [{ role, rightsOptions, isLoading }, setState] =
        useState(defaultState);
    const location = useLocation();

    const getRole = useCallback(() => {
        return api
            .get("users/roles")
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    role: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultState);
            });
    }, []);

    const getRightOptions = useCallback(() => {
        return api
            .get(`users/rights`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    rightsOptions: response.map(({ id, libelle }) => ({
                        label: libelle,
                        value: id,
                    })),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getRole(), getRightOptions()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getRightOptions, getRole]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "user.role.manage.role.id"),
            sort: true,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "libelle",
            text: I18n.getTranslation(
                location,
                "user.role.manage.role.libelle"
            ),
            sort: true,
            headerStyle: {
                width: "100rem",
            },
        },
        {
            dataField: "action",
            isDummyField: true,
            searchable: false,
            text: I18n.getTranslation(location, "user.role.manage.role.action"),
            formatter: (cell, row, rowIndex, { rightsOptions }) => {
                return (
                    <ButtonGroup>
                        <Update
                            role={row}
                            onSuccess={getRole}
                            rightsOptions={rightsOptions}
                        />
                        <Delete id={row.id} onSuccess={getRole} />
                    </ButtonGroup>
                );
            },
            formatExtraData: {
                rightsOptions,
            },
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    const defaultSorted = [
        {
            dataField: "id",
            order: "desc",
        },
    ];

    return (
        <Role
            {...props}
            role={role}
            rightsOptions={rightsOptions}
            isLoading={isLoading}
            columns={columns}
            options={options}
            getRole={getRole}
            defaultSorted={defaultSorted}
            location={location}
        />
    );
};

export default EnhancedRole;
