import React from "react";
import { useParams } from "react-router";
import Basic from "./Basic";

const EnhancedBasic = (props) => {
    const { artistId, albumId, releaseId } = useParams();
    return (
        <Basic
            artistId={artistId}
            albumId={albumId}
            releaseId={releaseId}
            {...props}
        />
    );
};

export default EnhancedBasic;
