import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faCopy } from "@fortawesome/free-solid-svg-icons";
import { addToClipboard } from "shared/functions/AddToClipboard";
import I18n from "shared/lib/I18n";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";

const ISRCReference = ({
    isrcs,
    selectedISRCs,
    unselectedISRCs,
    onSelect,
    location,
    locale,
    disabled,
}) => {
    return (
        <div className="input-group">
            <div className="form-control reference-list">
                {isrcs?.map(({ isrc, platform }, index) => (
                    <ButtonGroup key={index}>
                        <Button
                            variant={
                                selectedISRCs.includes(isrc)
                                    ? platform_color(platform)
                                    : `outline-${platform_color(platform)}`
                            }
                            title={I18n.getTranslation(
                                location,
                                selectedISRCs.includes(isrc)
                                    ? "cnm.report.consolidate.edit.isrcs.unselect"
                                    : "cnm.report.consolidate.edit.isrcs.select"
                            )}
                            onClick={() => {
                                onSelect(
                                    selectedISRCs.includes(isrc)
                                        ? selectedISRCs.filter(
                                              (value) => value !== isrc
                                          )
                                        : [...selectedISRCs, isrc]
                                );
                            }}
                            disabled={disabled}
                        >
                            {isrc}
                        </Button>
                        {!disabled && (
                            <Button
                                variant={`outline-${platform_color(platform)}`}
                                onClick={() => addToClipboard(isrc, locale)}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        )}
                    </ButtonGroup>
                ))}
            </div>
            <div className="input-group-append">
                <Button
                    variant="primary"
                    title={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.isrcs.selectAll"
                    )}
                    disabled={disabled || unselectedISRCs.length === 0}
                    onClick={(e) => {
                        onSelect([...selectedISRCs, ...unselectedISRCs]);
                        e.preventDefault();
                    }}
                >
                    <FontAwesomeIcon icon={faCheckDouble} fixedWidth />
                </Button>
            </div>
        </div>
    );
};

export default ISRCReference;
