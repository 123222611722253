import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router";
import { NavLink } from "shared/lib/I18n";
import Related from "./Related";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";

const EnhancedRelated = (props) => {
    const [state, setState] = useState({
        related: [],
        isLoading: false,
    });
    const [weightTypeOptions, setWeightTypeOptions] = useState([]);
    const location = useLocation();
    const { locale } = useParams();
    const { id_genre } = useIntParams();
    const { api } = useAPI();

    const getRelated = useCallback(async () => {
        if (id_genre) {
            setState((previousState) => ({
                ...previousState,
                isLoading: true,
            }));
            await api
                .get(`genre/related/weight-type/${locale}`)
                .then((response) => {
                    setWeightTypeOptions(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
            await api
                .get(`genre/${id_genre}/related`, { locale })
                .then((response) => {
                    setState((previousState) => ({
                        ...previousState,
                        related: response,
                        isLoading: false,
                    }));
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState((previousState) => ({
                        ...previousState,
                        isLoading: false,
                    }));
                });
        } else {
            setState({
                related: [],
                isLoading: false,
            });
        }
    }, [id_genre, locale]);

    useEffect(() => {
        getRelated();
    }, [getRelated]);

    const columns = [
        {
            dataField: "name",
            text: I18n.getTranslation(location, "genre.related.name"),
            formatter: (cell, { id_related_genre }) => (
                <NavLink to={`/genre/view/${id_related_genre}`}>{cell}</NavLink>
            ),
            sort: true,
        },
        {
            dataField: "weight",
            text: I18n.getTranslation(location, "genre.related.weight"),
            formatter: (cell, row, rowIndex, data) => `${cell} -
                ${
                    data.weightTypeOptions.find(({ id }) => id === String(cell))
                        ?.name
                }`,
            formatExtraData: {
                weightTypeOptions,
            },
            sort: true,
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "asc",
        },
    ];

    return (
        <Related
            {...props}
            {...state}
            columns={columns}
            defaultSorted={defaultSorted}
        />
    );
};

export default EnhancedRelated;
