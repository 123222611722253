import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import ArtistLink from "shared/components/ArtistLink";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Collaboration = ({
    collaboration,
    roleOptions,
    isLoading,
    locale,
    location,
}) => {
    return (
        <Widget
            title="artist.view.creditsCollaboration.head"
            isLoading={isLoading}
            actions={
                <Tippy
                    content={I18n.getTranslation(
                        location,
                        "artist.view.creditsCollaboration.description"
                    )}
                    trigger="mouseenter"
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="2x"
                            fixedWidth
                        />
                    </span>
                </Tippy>
            }
        >
            <Widget.Body>
                <hr className="my-2" />
                <div className="row">
                    <div className="col">
                        {collaboration.map(
                            ({ id, artist, start_date, end_date, roles }) => {
                                const data = [
                                    ...new Set(
                                        [start_date, end_date].filter(Boolean)
                                    ),
                                ];
                                return (
                                    <p key={id} className="mb-1">
                                        <ArtistLink
                                            artistId={artist.id}
                                            content={artist.name}
                                            view
                                        />
                                        {data.length !== 0 &&
                                            ` - ${data.join(" - ")}`}
                                        {roles.length !== 0 &&
                                            roles.map((r) => (
                                                <span key={r}>
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                    {roleOptions.find(
                                                        ({ id }) => id === r
                                                    )?.nom ?? r}
                                                </span>
                                            ))}
                                    </p>
                                );
                            }
                        )}
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Collaboration;
