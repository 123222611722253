import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import GenreLink from "shared/components/GenreLink";

const Genres = ({
    main,
    principal,
    secondary,
    influence,
    isLoading,
    locale,
    user,
}) => {
    return (
        <Widget
            title="artist.view.universGenres.head"
            isLoading={isLoading}
            style={{ height: "40rem", overflow: "auto" }}
            headerClassName="sticky-top bg-white"
            headerStyle={{ top: "-15px" }}
        >
            <Widget.Body>
                <hr className="my-2 sticky-top" style={{ top: "37px" }} />
                <div className="row">
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.universGenres.main" />
                        </strong>
                        <span className="mx-1">:</span>
                        {main &&
                            (user.hasRight("genre.show") ? (
                                <GenreLink
                                    id={main.id}
                                    name={main.name}
                                    locale={locale}
                                />
                            ) : (
                                main.name
                            ))}
                    </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                    <div className="col border-right">
                        <strong>
                            <I18n t="artist.view.universGenres.principal" />
                        </strong>
                        <hr className="my-2" />
                        {principal.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    {user.hasRight("genre.show") ? (
                                        <GenreLink
                                            id={id}
                                            name={name}
                                            locale={locale}
                                        />
                                    ) : (
                                        name
                                    )}
                                </p>
                            );
                        })}
                    </div>
                    <div className="col border-right">
                        <strong>
                            <I18n t="artist.view.universGenres.secondary" />
                        </strong>
                        <hr className="my-2" />
                        {secondary.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    {user.hasRight("genre.show") ? (
                                        <GenreLink
                                            id={id}
                                            name={name}
                                            locale={locale}
                                        />
                                    ) : (
                                        name
                                    )}
                                </p>
                            );
                        })}
                    </div>
                    <div className="col">
                        <strong>
                            <I18n t="artist.view.universGenres.influence" />
                        </strong>
                        <hr className="my-2" />
                        {influence.map(({ id, name }) => {
                            return (
                                <p key={id} className="mb-1">
                                    {user.hasRight("genre.show") ? (
                                        <GenreLink
                                            id={id}
                                            name={name}
                                            locale={locale}
                                        />
                                    ) : (
                                        name
                                    )}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Genres;
