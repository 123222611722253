import React from "react";
import "./Lyric.css";

const Lyric = ({ lyric, lrc }) => {
    return lrc ? (
        <div>
            <table>
                {lrc.map(({ line, lrc_timestamp }, index) => {
                    if (line === "") {
                        return (
                            <tr key={index}>
                                <td colSpan={2}>&nbsp;</td>
                            </tr>
                        );
                    }

                    return (
                        <tr key={index}>
                            <td>{lrc_timestamp}</td>
                            <td className="pl-2">{line}</td>
                        </tr>
                    );
                })}
            </table>
        </div>
    ) : (
        <div
            style={{
                whiteSpace: "pre",
            }}
        >
            {lyric}
        </div>
    );
};

export default Lyric;
