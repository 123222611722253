import React from "react";
import clsx from "clsx";

const Error = ({ error, className }) => {
    if (!Array.isArray(error) || !error.length) {
        return null;
    }

    return (
        <div className={clsx("invalid-feedback d-block", className)}>
            {error.map((e, index) => (
                <div key={index} className="alert alert-danger" role="alert">
                    {e}
                </div>
            ))}
        </div>
    );
};

export default Error;
