import React, { useState, useEffect } from "react";
import Action from "./Action";
import { useMK } from "shared/hooks/useMK";
import Mousetrap from "mousetrap";

const EnhancedAction = ({ setMainState, disable, ...props }) => {
    const [state, setState] = useState({
        isPlaying: false,
    });
    const mk = useMK({
        playbackTime: window.MusicKit.Events.playbackTimeDidChange,
    });

    useEffect(() => {
        if (
            mk.playbackTime &&
            mk.playbackTime.currentPlaybackTimeRemaining === 0
        ) {
            setState({ ...state, isPlaying: false });
        }
    }, [mk.playbackTime]);

    useEffect(() => {
        if (!disable) {
            Mousetrap.bind(
                "space",
                (e) => {
                    e.preventDefault();
                },
                "keydown"
            );

            Mousetrap.bind("x", previous, "keyup");
            Mousetrap.bind("c", back10, "keyup");
            Mousetrap.bind("space", togglePlayback, "keyup");
        } else {
            Mousetrap.reset();
        }
    }, [disable]);

    const play = () => {
        mk.instance.play().then(
            (result) => {
                return;
            },
            (rejected) => {
                if (rejected.errorCode === "CONTENT_RESTRICTED") {
                    pause();
                    setMainState((curr) => ({
                        ...curr,
                        playerError:
                            "apple.sample.assessment.player.playerError.restricted",
                    }));
                }

                console.error({ rejected });
            }
        );
        setState({ ...state, isPlaying: true });
    };

    const pause = () => {
        mk.instance.pause();
        setState({ ...state, isPlaying: false });
    };

    const togglePlayback = () => {
        if (mk.instance.isPlaying) {
            pause();
        } else {
            play();
        }
    };

    const previous = () => {
        mk.instance.seekToTime(0);
    };

    const back10 = () => {
        const newTime =
            mk.instance.currentPlaybackTime - 10 < 0
                ? 0
                : mk.instance.currentPlaybackTime - 10;

        mk.instance.seekToTime(newTime);
    };

    return (
        <Action
            {...state}
            play={play}
            pause={pause}
            previous={previous}
            back10={back10}
            {...props}
        />
    );
};

export default EnhancedAction;
