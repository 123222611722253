import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const Filters = ({ chartsOptions, filters, setFilters, disabled }) => {
    return (
        <>
            <div className="col-2">
                <InputSelect
                    onChange={(opt) => {
                        setFilters((filters) => ({
                            ...filters,
                            charts: opt ? opt.value : null,
                        }));
                    }}
                    placeholder={
                        <I18n t="cnm.charts.platform.matching.filters.charts.placeholder" />
                    }
                    options={chartsOptions}
                    value={
                        filters.charts !== null
                            ? chartsOptions.find(
                                  ({ value }) => value == filters.charts
                              )
                            : null
                    }
                    isDisabled={disabled}
                />
            </div>
        </>
    );
};

export default Filters;
