import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const BadgeGroup = ({ datas }) => {
    return (
        <>
            {datas.map(({ badges }, index) => {
                return (
                    <React.Fragment key={index}>
                        {badges.map(({ label, color, tooltip }, index2) => (
                            <Tippy
                                key={index2}
                                content={tooltip}
                                trigger={tooltip ? "mouseenter focus" : ""}
                            >
                                <span
                                    className={`badge badge-${
                                        color || "secondary"
                                    } mr-1`}
                                >
                                    {label}
                                </span>
                            </Tippy>
                        ))}
                        {datas.length - 1 !== index && (
                            <span className="mr-1">|</span>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default BadgeGroup;
