export const ALBUMS_SET = "albums.set";
export const ALBUM_ADD = "album.add";
export const ALBUM_UPD = "album.upd";
export const ALBUM_DEL = "album.del";
export const ALBUM_HAS_REVIEW = "album.has.review";
export const ALBUM_COVER_UP_TO_DATE = "album.cover.up.to.date";
export const RELEASES_SET = "releases.set";
export const RELEASE_ADD = "release.add";
export const RELEASE_UPD = "release.upd";
export const RELEASE_DEL = "release.del";
export const RELEASE_MATCHING_UP_TO_DATE = "release.matching.up.to.date";
export const TRACKS_LOAD_START = "tracks.load_start";
export const TRACKS_SET = "tracks.set";
export const TRACK_ADD = "track.add";
export const TRACK_UPD = "track.upd";
export const TRACK_DEL = "track.del";
export const TRACK_BASIC_UP_TO_DATE = "track.basic.up.to.date";
export const SANDBOX_ARTIST_ALBUMS_SET = "sandbox.artist_albums.set";
export const SANDBOX_SEARCH_RESULTS_SET = "sandbox.search_results.set";
export const SANDBOX_ALBUM_STATUS_UPD = "sandbox.album.status.upd";
