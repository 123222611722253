import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const Translation = ({ translations, isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="loader">
                    <Loader size="2x" />
                </div>
            )}
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`recording.view.profileTranslation.head`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div
                className="row"
                style={{ maxHeight: "5rem", overflow: "auto" }}
            >
                <div className="col">
                    {translations.map(({ language, title }) => {
                        return (
                            <p key={language.id} className="mb-1">
                                {`${title} - ${language.name}`}
                            </p>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Translation;
