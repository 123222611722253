import { createSelector } from "reselect";

export const getFilteredData = createSelector(
    [(args) => args.data, (args) => args.filter],
    (data, filter) => {
        let filteredData = data;

        if (filter.matched !== null) {
            filteredData = filteredData.filter(
                (p) => !!(p.album && p.artist) === filter.matched.value
            );
        }

        return filteredData;
    }
);
