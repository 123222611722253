import React from "react";
import TagsVersionButton from "./TagsVersionButton";
import useToggle from "shared/hooks/useToggle";
import { useLocation } from "react-router-dom";

const EnhancedTagsVersionButton = ({ onSuccess, ...props }) => {
    const location = useLocation();
    const [showTags, toggleTags] = useToggle(false);
    const onTagsSuccess = () => {
        toggleTags();
        onSuccess?.();
    };

    return (
        <TagsVersionButton
            {...props}
            showTags={showTags}
            toggleTags={toggleTags}
            onTagsSuccess={onTagsSuccess}
            location={location}
        />
    );
};

export default EnhancedTagsVersionButton;
