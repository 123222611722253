import React from "react";
import I18n from "shared/lib/I18n";
import { NavLink } from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Related from "./Related";
import Artists from "./Artists";
import { Helmet } from "react-helmet";
import "./GenreView.css";

const GenreView = ({
    genres,
    isLoading,
    columns,
    location,
    user,
    onColumnMatch,
    defaultSorted,
    selectRow,
}) => {
    const { SearchBar } = Search;
    return (
        <div className="content-wrap genre-view">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "genre.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="genre.breadcrumb" />
                    </li>
                </ol>
                <ToolkitProvider
                    keyField="id"
                    data={genres}
                    columns={columns}
                    search={{ onColumnMatch }}
                >
                    {(toolkitprops) => (
                        <>
                            <div className="row search-management">
                                <div className="col">
                                    <SearchBar
                                        {...toolkitprops.searchProps}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "genre.search"
                                        )}
                                    />
                                </div>
                                {user.hasRight("genre.manage") && (
                                    <div className="col-2 align-self-center pl-0">
                                        <NavLink
                                            to={`/genre/manage`}
                                            className={`btn btn-primary btn-block`}
                                        >
                                            <I18n t="genre.button" />
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                            <Widget
                                title={`genre.genre.head`}
                                className="vertical-shrink mb-0 pb-0 genre"
                                isLoading={isLoading}
                            >
                                <Widget.Body
                                    className="widget-table-overflow overflow-auto"
                                    style={{
                                        margin: "10px -20px 0px -20px",
                                    }}
                                >
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        bootstrap4
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={
                                            <I18n t="genre.empty" />
                                        }
                                        classes="table-bordered-header-fixed table-selectable mb-0"
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                    />
                                </Widget.Body>
                            </Widget>
                            <Related />
                            <Artists />
                        </>
                    )}
                </ToolkitProvider>
            </main>
        </div>
    );
};

export default GenreView;
