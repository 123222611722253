import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import CountrySelect from "./CountrySelect";

const EnhancedCountrySelect = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({ isLoading: false, options: [] });

    const getOptions = useCallback(() => {
        let cancelled = false;
        setState({
            isLoading: true,
            countries: [],
        });
        api.get(`country/${locale}`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        options: response.map(({ id, nom }) => ({
                            value: id,
                            label: nom,
                        })),
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        options: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [locale]);
    useEffect(getOptions, [getOptions]);

    return <CountrySelect {...props} {...state} />;
};

export default EnhancedCountrySelect;
