import React from "react";
import InputDate from "shared/components/Form/InputDate";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";
import moment from "moment";
import { useLocation } from "react-router";

const Filter = ({
    selectedFilterOptions,
    setSelectedFilterOptions,
    filterOptions,
}) => {
    const location = useLocation();
    return (
        <div className="widget-body">
            <div className="row mb-2">
                <div className="col-md-2">
                    <InputSelect
                        onChange={(opt) => {
                            setSelectedFilterOptions({
                                ...selectedFilterOptions,
                                status: opt ? opt.value : null,
                            });
                        }}
                        placeholder={
                            <I18n t="plannings.picture.planning.status" />
                        }
                        options={filterOptions.status}
                    />
                </div>
                <div className="col-md-2">
                    <InputDate
                        selected={
                            selectedFilterOptions.startDate
                                ? new Date(selectedFilterOptions.startDate)
                                : null
                        }
                        onChange={(date) => {
                            setSelectedFilterOptions({
                                ...selectedFilterOptions,
                                startDate: date
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                            });
                        }}
                        placeholderText={`${I18n.getTranslation(
                            location,
                            `plannings.picture.planning.startDate`
                        )}`}
                        isClearable
                    />
                </div>
                <div className="col-md-2">
                    <InputDate
                        selected={
                            selectedFilterOptions.endDate
                                ? new Date(selectedFilterOptions.endDate)
                                : null
                        }
                        onChange={(date) => {
                            setSelectedFilterOptions({
                                ...selectedFilterOptions,
                                endDate: date
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                            });
                        }}
                        placeholderText={`${I18n.getTranslation(
                            location,
                            `plannings.picture.planning.endDate`
                        )}`}
                        isClearable
                    />
                </div>
            </div>
        </div>
    );
};

export default Filter;
