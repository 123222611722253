import React, { useEffect } from "react";
import { Popover, Table } from "react-bootstrap";
import clsx from "clsx";
import styles from "./ChartPopover.module.css";
import I18n from "shared/lib/I18n";

const ChartPopover = React.forwardRef(
    (
        { sexe_repartition, popper, className, locale, location, ...props },
        ref
    ) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [sexe_repartition, popper]);

        const getSexeTrad = (sexe) => {
            if (sexe === "Homme") {
                return "landing.industryStats.man";
            } else if (sexe === "Femme") {
                return "landing.industryStats.woman";
            } else if (sexe === "Autre") {
                return "landing.industryStats.other";
            }
            return "landing.industryStats.band";
        };

        return (
            <Popover
                ref={ref}
                className={clsx(className, styles.popover)}
                {...props}
            >
                <Popover.Title as="h3" className="text-capitalize">
                    <div className={clsx(styles.container)}>
                        <span>
                            {I18n.getTranslation(
                                location,
                                getSexeTrad(sexe_repartition.sexe)
                            )}
                        </span>
                        <span className={clsx(styles.nombre)}>
                            {I18n.getTranslation(
                                location,
                                "landing.industryStats.total"
                            )}
                            {sexe_repartition.count}
                        </span>
                    </div>
                </Popover.Title>
                <Popover.Content className={clsx(styles.content)}>
                    {sexe_repartition.sexe != "Autre" && (
                        <>
                            <span className={clsx(styles.text)}>
                                {I18n.getTranslation(
                                    location,
                                    "landing.industryStats.top_3"
                                )}
                            </span>
                            <Table className={clsx(styles.table)}>
                                <tbody>
                                    {sexe_repartition.top_artists.map(
                                        (artist, index) => (
                                            <tr key={artist.id}>
                                                <th scope="row">
                                                    #{index + 1}
                                                </th>
                                                <td>{artist.name}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Popover.Content>
            </Popover>
        );
    }
);

export default ChartPopover;
