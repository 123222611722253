import React from "react";
import Data from "./Data";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";
import styles from "./MultiSearch.module.css";
import { Form, InputGroup, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const MultiSearch = ({
    q,
    inputGroupRef,
    handleReset,
    handleFocus,
    register,
    location,
    isSubmitting,
    handleSubmit,
    larger,
    shortcut,
    withExplanation,
    ...props
}) => {
    return (
        <Form
            onSubmit={!isSubmitting ? handleSubmit : null}
            className={styles.form}
        >
            {withExplanation && (
                <div className={clsx(styles.explanation)}>
                    <I18n t="common.multiSearch.explanation" />
                </div>
            )}
            <InputGroup ref={inputGroupRef}>
                <InputGroup.Prepend>
                    <Button variant="secondary" type="submit" disabled={!q}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
                    </Button>
                </InputGroup.Prepend>
                <InputText
                    {...register("q")}
                    className={clsx(
                        "mousetrap",
                        styles.input,
                        larger && styles.input__larger
                    )}
                    placeholder={I18n.getTranslation(
                        location,
                        "common.multiSearch.placeholder"
                    )}
                    onClick={handleFocus}
                    autoComplete="off"
                />
                {q && (
                    <FontAwesomeIcon
                        className={clsx(
                            styles.reset,
                            larger && styles.reset__larger,
                            shortcut && styles.reset__shortcut
                        )}
                        onClick={handleReset}
                        icon={faSquareXmark}
                        title={I18n.getTranslation(
                            location,
                            "utils.button.reset"
                        )}
                        fixedWidth
                    />
                )}
                {shortcut && (
                    <InputGroup.Append>
                        <Button variant="secondary" type="button" disabled>
                            <Badge
                                variant="light"
                                className={clsx("mr-1", styles.badge)}
                            >
                                CTRL
                            </Badge>
                            <span className={clsx("mr-1")}>+</span>
                            <Badge
                                variant="light"
                                className={clsx(styles.badge)}
                            >
                                K
                            </Badge>
                        </Button>
                    </InputGroup.Append>
                )}
            </InputGroup>
            <Data {...props} q={q} handleReset={handleReset} />
        </Form>
    );
};

export default MultiSearch;
