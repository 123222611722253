import React from "react";
import EditWizard from "./EditWizard";

const EnhancedEditWizard = ({
    setSelectedPicture,
    setCurrentTab,
    onSuccess,
    ...props
}) => {
    const onUploadSuccess = (pictureId) => {
        setSelectedPicture({
            pictureId: pictureId,
            versionId: null,
        });
        setCurrentTab("Legal");
        onSuccess?.();
    };

    const onInformationSuccess = () => {
        setCurrentTab("Cropping");
    };

    const onCropSuccess = (versionId, direction) => {
        setSelectedPicture((prev) => ({
            pictureId: prev.pictureId,
            versionId: versionId,
        }));
        direction == "next" ? setCurrentTab("Tagging") : setCurrentTab("Legal");
        onSuccess?.();
    };

    const onTagsVersionSuccess = (direction) => {
        direction == "next" ? setCurrentTab(null) : setCurrentTab("Cropping");
        onSuccess?.();
    };

    const returnToInformation = () => {
        setCurrentTab("Legal");
    };

    const returnToCrop = () => {
        setCurrentTab("Cropping");
    };

    const returnToTagging = () => {
        setCurrentTab("Tagging");
    };
    return (
        <EditWizard
            {...props}
            setSelectedPicture={setSelectedPicture}
            setCurrentTab={setCurrentTab}
            onUploadSuccess={onUploadSuccess}
            onInformationSuccess={onInformationSuccess}
            onCropSuccess={onCropSuccess}
            onTagsVersionSuccess={onTagsVersionSuccess}
            returnToInformation={returnToInformation}
            returnToCrop={returnToCrop}
            returnToTagging={returnToTagging}
        />
    );
};

export default EnhancedEditWizard;
