import React, { useEffect } from "react";
import { Popover, Table } from "react-bootstrap";
import clsx from "clsx";
import styles from "./ChartPopover.module.css";

const ChartPopover = React.forwardRef(
    ({ language_repartition, popper, className, locale, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [language_repartition, popper]);

        return (
            <Popover
                ref={ref}
                className={clsx(className, styles.popover)}
                {...props}
            >
                <Popover.Title as="h3" className="text-capitalize">
                    {language_repartition.name}
                </Popover.Title>
                <Popover.Content>{language_repartition.count}</Popover.Content>
            </Popover>
        );
    }
);

export default ChartPopover;
