import React, { useState, useEffect, useCallback, useRef } from "react";
import ImageCropModal from "./ImageCropModal";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useApi from "shared/hooks/useApi";
import "./ImageCropModal.css";

const EnhancedImageCropModal = ({
    pictureId,
    versionId,
    show,
    handleHide,
    onSuccess,
    ...props
}) => {
    const { api } = useApi();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const cropperRef = useRef(null);
    const [cropData, setCropData] = useState({
        width: 800,
        height: 800,
    });
    const [isDisabled, setIsDisabled] = useState(false);
    const [abscisse, setAbscisse] = useState(0);
    const [ordonnee, setOrdonnee] = useState(0);
    const [size, setSize] = useState(800);
    const [direction, setDirection] = useState("");

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(`picture/${pictureId}`, versionId && { versionId })
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, pictureId]);

    useEffect(getPicture, [getPicture]);

    useEffect(() => {
        if (isSubmitLoading) {
            const cropper = cropperRef?.current?.cropper;

            api.put(
                `picture/curation/${pictureId}/crop`,
                versionId ? { locale, versionId } : { locale },
                {
                    cropData: cropper.getData(true),
                }
            )
                .then(({ id_version }) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "common.imageCropModal.success"
                        )
                    );
                    setSubmitLoading(false);
                    onSuccess?.(id_version, direction);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setSubmitLoading(false);
                });
        }
    }, [pictureId, isSubmitLoading]);

    const handleSubmit = (direction) => {
        setDirection(direction);
        setSubmitLoading(true);
    };

    const onCrop = () => {
        const cropper = cropperRef?.current?.cropper;
        const data = cropper.getData(true);
        setCropData(data);
        if (data.width < 800 || data.height < 800) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const getPredictionDefaultCrop = useCallback(() => {
        if (show) {
            api.get(`picture/${pictureId}/predict/defaultCrop`)
                .then((response) => {
                    setAbscisse(response.x);
                    setOrdonnee(response.y);
                    setSize(response.size);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, pictureId]);

    useEffect(getPredictionDefaultCrop, [getPredictionDefaultCrop]);

    const onReady = useCallback(() => {
        const cropper = cropperRef?.current?.cropper;

        if (cropper) {
            cropper.enable();
            cropper.crop();
            cropper.setAspectRatio(1);
            cropper.setData(
                state.picture?.crop_data || {
                    x: abscisse,
                    y: ordonnee,
                    width: size,
                    height: size,
                }
            );
        }
    }, [state.picture, abscisse, ordonnee, size]);

    useEffect(() => {
        onReady();
    }, [onReady]);

    const rotateLeft = () => {
        const cropper = cropperRef?.current?.cropper;
        cropper.rotate(-90);
    };

    const rotateRight = () => {
        const cropper = cropperRef?.current?.cropper;
        cropper.rotate(90);
    };

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.imageCropModal.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.imageCropModal.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleHide();
        });
    };

    return (
        <ImageCropModal
            {...props}
            {...state}
            show={show}
            handleSubmit={handleSubmit}
            handleHide={handleHide}
            handleDelete={handleDelete}
            isSubmitLoading={isSubmitLoading}
            cropperRef={cropperRef}
            onCrop={onCrop}
            onReady={onReady}
            rotateLeft={rotateLeft}
            rotateRight={rotateRight}
            cropData={cropData}
            isDisabled={isDisabled}
            location={location}
        />
    );
};

export default EnhancedImageCropModal;
