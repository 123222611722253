import React from "react";
import DiscardedFilter from "./DiscardedFilter";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";

const EnhancedDiscardedFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.track.track.filter.discarded.true"
            ),
            value: true,
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.track.track.filter.discarded.false"
            ),
            value: false,
        },
    ];

    return <DiscardedFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedDiscardedFilter;
