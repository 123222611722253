import normalizeAccentsMap from "shared/constants/normalize_accents_map";

/*
 * By default, react-bootstrap-table uses the filterValue parameter to
 * normalize values when searching. However, filterValue is only applied to the
 * values of the table, it is not applied to the search term. This prevents
 * filterValue from being used to implement a search that is insensitive to
 * accents or spaces for example.
 *
 * onColumnMatchNormalized is a custom match function that applies filterValue
 * to the search term.
 *
 *   <ToolkitProvider
 *       keyField="id"
 *       data={...}
 *       columns={[
 *          {
 *              dataField: "id",
 *              text: "ID",
 *              searchable: true,
 *              filterValue: normalizeInteger,  // The normalization function
 *          }
 *       ]}
 *       search={{ onColumnMatch: onColumnMatchNormalized }}
 *   >
 *   ...
 *   </ToolkitProvider
 */
export const onColumnMatchNormalized = ({ searchText, value, column }) => {
    if (value === null || typeof value === "undefined") {
        return false;
    }

    if (
        column.filterValue !== null &&
        typeof column.filterValue !== "undefined"
    ) {
        searchText = column.filterValue(searchText).toString().toLowerCase();
    }

    value = value.toString().toLowerCase();
    return value.indexOf(searchText) > -1;
};

export const normalizeInteger = (value) => value?.toString().replace(/^0+/, "");

export const normalizeBarcode = (value) =>
    value?.toString().toLowerCase().replace(/\s+/g, "").replace(/^0+/, "");

export const normalizeISRC = (value) =>
    value?.toString().toLowerCase().replace(/[\s-]/g, "");

export const normalizeTitle = (value) =>
    value
        ?.toString()
        .toLowerCase()
        .replace(
            /['"«»“”‘’‹›<>`´\[\]\(\)\{\},;:!?¡¿\.\/*+=\\\-_~^#%&@\s…]+/g,
            " "
        )
        .replace(/[^A-Za-z0-9]/g, (c) => normalizeAccentsMap[c] || c);
