import React, { useState, useEffect, useCallback } from "react";
import Stats from "./Stats";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const EnhancedStats = ({ startDate, endDate, ...props }) => {
    const [stats, setStats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const exportName = `${startDate}-${endDate}_${I18n.getTranslation(
        location,
        "data_external.internationals_charts.breadcrumb"
    )}_${I18n.getTranslation(
        location,
        "data_external.internationals_charts.stats.nav"
    )}`;

    const getStats = useCallback(() => {
        setIsLoading(true);
        api.get(
            `data/external/internationals/charts/stats/${startDate}/${endDate}`,
            { locale }
        )
            .then((response) => {
                setStats(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [locale, startDate, endDate]);

    useEffect(getStats, [getStats]);

    const columns = [
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.title"
            ),
            formatter: (cell, { id_type, id_top }) => {
                return (
                    <a
                        href={`/${locale}/matching/tops/${id_type}/${id_top}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {cell}
                    </a>
                );
            },
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.country"
            ),
        },
        {
            dataField: "start_date",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.start_date"
            ),
        },
        {
            dataField: "treatment",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.treatment"
            ),
            formatter: (cell, { matched, treatment }) =>
                `${matched} / ${treatment}`,
            searchable: false,
        },
        {
            dataField: "matched_simple",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.matched_simple"
            ),
            searchable: false,
        },
        {
            dataField: "creation",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.creation"
            ),
            searchable: false,
        },
        {
            dataField: "nb_discarded",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.nb_discarded"
            ),
            searchable: false,
        },
        {
            dataField: "nb_forgot",
            text: I18n.getTranslation(
                location,
                "data_external.internationals_charts.stats.nb_forgot"
            ),
            searchable: false,
        },
    ];

    return (
        <Stats
            {...props}
            stats={stats}
            columns={columns}
            isLoading={isLoading}
            exportName={exportName}
        />
    );
};

export default EnhancedStats;
