import React from "react";
import { Alert } from "react-bootstrap";

const Summary = ({ has_global_error, variant, summary }) => {
    return (
        <Alert variant={variant}>
            <div className="d-flex justify-content-around">
                {summary
                    .filter(({ is_global }) => !is_global)
                    .map(({ id, name, value }) => (
                        <div key={id}>
                            <strong>{name} :</strong>{" "}
                            {has_global_error ? "-" : value}
                        </div>
                    ))}
            </div>
        </Alert>
    );
};

export default Summary;
