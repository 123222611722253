import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Isrcs = ({ isrc, alternates }) => {
    if (alternates.length === 0) {
        return <span className="badge badge-secondary">{isrc}</span>;
    }

    return (
        <OverlayTrigger
            placement="left"
            overlay={
                <Tooltip>
                    <div>
                        {alternates.map((row) => (
                            <div key={row}>{row}</div>
                        ))}
                    </div>
                </Tooltip>
            }
        >
            <span className="badge badge-secondary">{isrc}</span>
        </OverlayTrigger>
    );
};

export default Isrcs;
