import React from "react";
import { NavLink } from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";

const YearList = ({ year, chartType, isLoading, years }) => {
    let rows = (
        <tr>
            <td style={{ textAlign: "center" }}>
                <I18n t="cnm.report.years.empty" />
            </td>
        </tr>
    );
    if (years.length !== 0) {
        rows = years.map((row) => (
            <tr
                key={row.path}
                className={
                    row.year === year && row.type.toLowerCase() === chartType
                        ? "table-info"
                        : undefined
                }
            >
                <td>
                    <NavLink to={`/cnm/report/${row.path}`}>
                        {row.year} (
                        <I18n t={`cnm.report.years.type.${row.type}`} />)
                    </NavLink>
                </td>
            </tr>
        ));
    }

    return (
        <Widget
            title="cnm.report.years.title"
            className="align-self-start pb-0"
        >
            <Widget.Body
                className="widget-table-overflow"
                style={{
                    margin: "10px -20px 0px -20px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped"
                        style={{ marginBottom: "0px" }}
                    >
                        <tbody>{rows}</tbody>
                    </table>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default YearList;
