import React from "react";
import Chart from "./Chart";
import I18n from "shared/lib/I18n";

const EnhancedChart = React.memo(
    ({ recordings_creation, location, ...props }) => {
        const data = {
            labels: recordings_creation.map(({ report_date }) => report_date),
            datasets: [
                {
                    label: I18n.getTranslation(
                        location,
                        "landing.msStats.recordings.unique_recording"
                    ),
                    data: recordings_creation.map(({ total }) => total),
                    tension: 0.1,
                    cubicInterpolationMode: "monotone",
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            animation: false,
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                    labels: {
                        usePointStyle: true,
                    },
                },
                colors: {
                    forceOverride: true,
                },
                tooltip: {
                    usePointStyle: true,
                },
            },
        };

        return <Chart {...props} data={data} options={options} />;
    }
);

export default EnhancedChart;
