import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const Chart = ({ data, columns, isLoading, location, pagination }) => {
    return (
        <ToolkitProvider keyField="id" data={data} columns={columns} search>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title="cnm.charts.deduplicated.chart.head"
                            actions={
                                !isLoading && (
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                )
                            }
                        >
                            <Widget.Body>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <Search.SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder={I18n.getTranslation(
                                                location,
                                                "cnm.charts.deduplicated.chart.search"
                                            )}
                                        />
                                    </div>
                                </div>
                            </Widget.Body>
                            <Widget.Body className="widget-table-overflow mb-3">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        noDataIndication={
                                            <I18n t="cnm.charts.deduplicated.chart.empty" />
                                        }
                                        classes="table-bordered-header-fixed"
                                    />
                                )}
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center">
                                    {!isLoading && (
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                    dataSize={data.length}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default Chart;
