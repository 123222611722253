import React from "react";

const Body = ({ className, style, hidden = false, children }) => {
    return (
        <div
            className={`widget-body ${className} ${hidden && "d-none"}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Body;
