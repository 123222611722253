import React from "react";
import useUser from "shared/hooks/useUser";
import RecordingListAction from "./RecordingListAction";

const EnhancedRecordingListAction = (props) => {
    const { user } = useUser();

    return <RecordingListAction {...props} user={user} />;
};

export default EnhancedRecordingListAction;
