import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { formatIswc, formatIPI } from "shared/functions/String";

const WorkData = ({ data, isLoading }) => {
    return (
        <>
            <Widget
                headerCenter={data.title ? <h2>{data.title}</h2> : undefined}
                isLoading={isLoading}
                className="recording-view-work-work-data"
            >
                <Widget.Body>
                    {data.title && <hr className="my-2" />}
                    <div className="row">
                        <div className="col border-right">
                            <strong>
                                <I18n t="recording.view.workData.iswc" />
                            </strong>
                            <hr className="my-2" />
                            <div>{formatIswc(data.iswc)}</div>
                        </div>
                        <div className="col">
                            <strong>
                                <I18n t="recording.view.workData.hfa" />
                            </strong>
                            <hr className="my-2" />
                            <div>{data.hfa_code}</div>
                        </div>
                    </div>
                    <hr className="mt-2 mb-5" />
                    <h4>
                        <I18n t="recording.view.workData.publisher_credit" />
                    </h4>
                    <hr className="mt-2" />

                    {data.publisher_credit?.map((publisher_credit) => (
                        <div className="row" key={publisher_credit}>
                            <div className="col">{publisher_credit}</div>
                        </div>
                    ))}
                    <h4 className="mt-5">
                        <I18n t="recording.view.workData.share.head" />
                    </h4>
                    <hr className="mt-3 mb-2" />

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col border-right">
                                    <strong>
                                        <I18n t="recording.view.workData.share.ipi" />
                                    </strong>
                                </div>
                                <div className="col-3 border-right">
                                    <strong>
                                        <I18n t="recording.view.workData.share.party" />
                                    </strong>
                                </div>
                                <div className="col-2 border-right">
                                    <strong>
                                        <I18n t="recording.view.workData.share.role" />
                                    </strong>
                                </div>
                                <div className="col border-right">
                                    <strong>
                                        <I18n t="recording.view.workData.share.parent_ipi" />
                                    </strong>
                                </div>
                                <div className="col-3 border-right">
                                    <strong>
                                        <I18n t="recording.view.workData.share.parent" />
                                    </strong>
                                </div>
                                <div className="col-1">
                                    <strong>
                                        <I18n t="recording.view.workData.share.share" />
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                    {data.shares?.map((share) => (
                        <div className="row" key={share.id}>
                            <div className="col">
                                <div className="row">
                                    <div className="col border-right">
                                        <div className="font-feature-settings-tnum">
                                            {formatIPI(share.ipi)}
                                        </div>
                                    </div>
                                    <div className="col-3 border-right">
                                        <div>{share.name}</div>
                                    </div>
                                    <div className="col-2 border-right">
                                        <div>{share.role}</div>
                                    </div>
                                    <div className="col border-right">
                                        <div className="row">
                                            <div className="col">
                                                {share.parent?.map((parent) => (
                                                    <div className="font-feature-settings-tnum">
                                                        {formatIPI(parent.ipi)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 border-right">
                                        <div className="row">
                                            <div className="col">
                                                {share.parent?.map((parent) => (
                                                    <div>{parent.name}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <div className="float-right">
                                            {share.share !== null &&
                                                `${share.share}%`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Widget.Body>
            </Widget>
        </>
    );
};

export default WorkData;
