import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useAPI from "shared/hooks/useApi";
import Login from "./Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";

const schema = yup.object().shape({
    username: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
    password: yup
        .string()
        .trim()
        .transform((value) => (value !== "" ? value : null))
        .required("utils.form.required"),
});

const defaultValues = {
    username: null,
    password: null,
};

const EnhancedLogin = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { user } = useUser();
    const { locale, token } = useParams();
    const navigate = useNavigate();
    const [globalError, setGlobalError] = useState();
    const locationPath = location.hash.startsWith("#/")
        ? location.hash.slice(2)
        : `/${locale}`;

    const {
        handleSubmit,
        register,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const handleLogin = async (data) => {
        try {
            await api.authenticate(data);
            navigate(locationPath);
        } catch (e) {
            setGlobalError(e.message);
        }
    };

    const handleValidate = async (token) => {
        try {
            await api.validate(token);
            toast.success(
                I18n.getTranslation(location, `security.validate.success`)
            );
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (user.authenticated) {
            navigate(locationPath);
        }
    }, [user]);

    useEffect(() => {
        if (token) {
            handleValidate(token);
        }
    }, [token]);

    return (
        <Login
            handleLogin={handleSubmit(handleLogin)}
            globalError={globalError}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            location={location}
            {...props}
        />
    );
};

export default EnhancedLogin;
