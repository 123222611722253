import { createSelector } from "reselect";

export const getFilteredData = createSelector(
    [(args) => args.data, (args) => args.filter],
    (data, filter) => {
        let filteredData = data;

        if (filter.matched !== null) {
            filteredData = filteredData.filter(
                (p) => !!p.artist === filter.matched.value
            );
        }

        if (filter.completed !== null) {
            filteredData = filteredData.filter(
                (p) => p.completed === filter.completed.value
            );
        }

        if (filter.checked !== null) {
            filteredData = filteredData.filter(
                (p) => !!p.checked_at === filter.checked.value
            );
        }

        if (filter.skiped !== null) {
            filteredData = filteredData.filter(
                (p) => p.skiped === filter.skiped.value
            );
        }

        return filteredData;
    }
);
