import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import {
    STATUSES,
    STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
    STATUS_MATCHED_IN_OTHER_DISCOGRAPHY,
    STATUS_NOT_MATCHED_INCOMPLETE,
} from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";

const StatusFilter = ({
    disabled,
    statusFilter,
    total,
    selected,
    onStatusChange,
    setAllFilters,
    isCollapsed,
    setIsCollapsed,
}) => {
    const legendClass = {
        [STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY]: "legend-success",
        [STATUS_MATCHED_IN_OTHER_DISCOGRAPHY]: "legend-danger",
        [STATUS_NOT_MATCHED_INCOMPLETE]: "legend-warning",
    };

    return (
        <>
            <div className="form-row">
                <div className="col text-nowrap">
                    <h5
                        className="cursor-pointer align-baseline"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <FontAwesomeIcon
                            icon={isCollapsed ? faChevronRight : faChevronDown}
                            size="xs"
                            fixedWidth
                            className="mr-1"
                        />
                        <I18n t="artist.management.disco.sandbox.filters.status" />
                        <span className="badge badge-primary align-top ml-1">
                            {selected} / {total}
                        </span>
                    </h5>
                </div>
                <div className="col flex-grow-0 text-nowrap">
                    <button
                        className="btn btn-link p-0 align-middle mr-1"
                        onClick={() => setAllFilters(true)}
                        disabled={disabled}
                    >
                        <I18n t="utils.filters.all" />
                    </button>
                    <span className="align-middle">/</span>
                    <button
                        className="btn btn-link p-0 align-middle ml-1"
                        onClick={() => setAllFilters(false)}
                        disabled={disabled}
                    >
                        <I18n t="utils.filters.none" />
                    </button>
                </div>
            </div>
            <div className={`form-row mb-3 ${isCollapsed && "d-none"}`}>
                {STATUSES.map((status) => (
                    <div key={status} className="col-12 text-nowrap">
                        <input
                            className="align-middle"
                            type="checkbox"
                            id={`filter-status-${status}`}
                            name={status}
                            checked={statusFilter[status]}
                            onChange={onStatusChange}
                            disabled={disabled}
                        />
                        <label
                            className="align-middle ml-1"
                            htmlFor={`filter-status-${status}`}
                        >
                            <I18n
                                t={`artist.management.disco.sandbox.status.${status}`}
                            />
                            <div
                                className={`ml-2 align-middle legend-dot ${legendClass[status]}`}
                            ></div>
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default StatusFilter;
