import React from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import CreateOrUpdateModal from "./CreateOrUpdateModal";

const Platforms = ({
    columns,
    rowClasses,
    editable,
    createOrUpdateModalProps,
    showCreateModal,
    isLoading,
    platforms,
    location,
}) => {
    return (
        <>
            <CreateOrUpdateModal {...createOrUpdateModalProps} />
            <Widget
                title="cnm.repositories.platforms.title"
                className="grid-bottom vertical-shrink mb-0 pb-0"
                actions={
                    editable && (
                        <Button
                            variant="success"
                            onClick={showCreateModal}
                            title={I18n.getTranslation(
                                location,
                                "cnm.repositories.platforms.add.tooltip"
                            )}
                        >
                            <I18n t="cnm.repositories.platforms.add.button" />
                        </Button>
                    )
                }
            >
                <Widget.Body
                    className="widget-table-overflow overflow-auto"
                    style={{
                        margin: "10px -20px 0px -20px",
                    }}
                >
                    {isLoading ? (
                        <Loader
                            className="h-100 align-items-center"
                            size="3x"
                        />
                    ) : (
                        <BootstrapTable
                            keyField="id"
                            data={platforms}
                            columns={columns}
                            rowClasses={rowClasses}
                            bootstrap4
                            striped
                            hover
                            bordered={false}
                            noDataIndication={
                                <I18n t="cnm.repositories.platforms.empty" />
                            }
                            classes="table-header-fixed table-truncate"
                        />
                    )}
                </Widget.Body>
            </Widget>
        </>
    );
};

export default Platforms;
