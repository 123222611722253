import { UPLOAD_MAX_SIZE } from "shared/constants/file";

export default {
    dashboard: "Metadaten Monitor",
    logo: "Metadaten Monitor",
    error_boundary: {
        message: "Ups! Da ist etwas schiefgegangen.",
        submessage: "Wir arbeiten daran.",
        back: "Zurück zur Homepage",
    },
    security: {
        login: {
            title: "Einloggen",
            username: "E-Mail",
            password: "Passwort",
            noAccount: "Haben Sie kein Konto?",
            create: "Registrieren",
            forgot: "Passwort vergessen?",
        },
        request: {
            title: "Anfrage",
            username: "E-Mail-Adresse",
            message:
                "Eine Mail liegt in Ihrem Postfach. Darin finden Sie einen Link. Diesen bitte anklicken, um Ihr Passwort zurückzusetzen.\n\nKeine Mail erhalten? Prüfen Sie Ihren Spam-Ordner oder versuchen Sie es bitte noch einmal.",
            button: "Passwort zurücksetzen",
            login: "Zurück zum Login",
        },
        reset: {
            title: "Passwort ändern",
            password: "Neues Passwort",
            success: "Das Passwort ist erfolgreich zurückgesetzt worden.",
            error: "Passwort kann nicht zurückgesetzt werden.",
            button: "Passwort ändern",
            invalid_password: "Ungültiges Passwort",
            login: "Zurück zum Login",
        },
        register: {
            title: "Konto registrieren",
            subtitle: {
                trial: "Möchten Sie unsere Daten ausprobieren? Starten Sie noch heute eine kostenlose 2-wöchige Testphase.",
                client: "Als Kunde haben Sie bereits Zugriff auf den Metadaten-Monitor. Ihr Konto wird überprüft, um Ihnen entsprechenden Zugang zu gewähren.",
            },
            is_client: "Schon Kunde?",
            email: "E-Mail",
            firstname: "Vorname",
            lastname: "Nachname",
            password: "Passwort",
            account_type: {
                title: "Kontotyp",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            role: {
                title: "Rolle",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Beschreiben",
            language: "Sprache",
            label: "Label",
            company: "Unternehmen",
            success:
                "Ihr Konto wurde erfolgreich erstellt. Bitte bestätigen Sie Ihre E-Mail-Adresse, um Ihre Registrierung abzuschließen und auf den Metadaten-Monitor zuzugreifen.",
            error: "Konto kann nicht erstellt werden.",
            errorAllreadyAccount: () => (
                <>
                    Sie können sich nicht registrieren, weil dieser
                    E-Mail-Adresse bereits ein Konto zugeordnet ist. Versuchen
                    Sie, sich direkt{" "}
                    <a href="https://metadata.music-story.com/fr/login">
                        anzumelden
                    </a>{" "}
                    oder Ihr{" "}
                    <a href="https://metadata.music-story.com/fr/resetting/request">
                        Passwort zurückzusetzen
                    </a>
                    .
                </>
            ),
            button: "Loslegen",
            allreadyAccount: "Hast du bereits ein Konto ?",
            agreeBefore:
                "Durch die Registrierung stimmen Sie der Music-Story zu",
            termsOfService: "Nutzungsbedingungen",
            agreeAnd: "und",
            privacyPolicy: "Datenschutzrichtlinie",
            invalid_password: "Ungültiges Passwort",
        },
        access: {
            denied: "Ungültige Anmeldedaten. Versuchen Sie sich mit Ihrer E-Mail anzumelden.",
            expired: "Konto abgelaufen",
            lock: "Konto gesperrt",
        },
        validate: {
            success:
                "Ihre Registrierung wurde erfolgreich abgeschlossen. Sie können sich jetzt anmelden.",
            error: "Konto kann nicht validiert werden",
        },
    },
    user: {
        breadcrumb: "Nutzer",
        manage: {
            breadcrumb: "Verwaltung",
            user: {
                head: "Nutzer",
                empty: "Keine Ergebnisse",
                id: "ID",
                username: "Name",
                is_client: "Schon Kunde?",
                firstname: "Vorname",
                lastname: "Nachname",
                email: "E-Mail",
                user_roles: "Rechte",
                label: "Zugehöriges Label",
                api_limit_per_day: "Limit für API-Anfragen pro Tag",
                api_limit_per_second: "Limit für API-Anfragen pro Sekunde",
                image_server: "Bilderserver",
                author: "Autor",
                account_type: {
                    title: "Kontotyp",
                    music_service: "Music service",
                    cmo: "CMO",
                    label_distributor: "Label / Distributor",
                    artist_manager: "Artist / Manager",
                },
                role: {
                    title: "Rolle",
                    music_editor: "Music editor",
                    marketing: "Marketing",
                    catalog_management: "Catalog Management",
                    product_management: "Product Management",
                    tech: "Tech",
                    executive: "Executive",
                    other: "Other",
                    promotion: "Promotion",
                    business_development: "Business development",
                    a_and_r: "A&R",
                    supply_chain: "Supply chain",
                    analytics: "Analytics",
                },
                describe: "Beschreiben",
                language: "Sprache",
                company: "Unternehmen",
                last_login: "Letzte Verbindung",
                expires_at: "Ablaufdatum",
                token: "Token",
                expires: "Abgelaufen",
                action: "Aktion",
                search: "Suche...",
            },
            create: {
                title: "Nutzer erstellen",
                success: "Nutzer erstellt",
                error: (message) =>
                    `Fehler bei der Erstellung dieses Nutzers: ${message}`,
            },
            update: {
                title: "Nutzer aktualisieren",
                success: "Nutzer aktualisiert",
                error: (message) =>
                    `Fehler bei der Aktualisierung dieses Nutzers: ${message}`,
            },
            change_password: {
                title: "Passwort ändern",
                password: "Passwort",
                comfirm: "Passwort ",
                success: "Passwort aktualisiert",
                error: (message) =>
                    `Fehler bei der Aktualisierung des Passworts: ${message}`,
            },
            promote: {
                title: "Nutzer anmelden",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Nutzer anmelden?",
                },
                success: "Nutzer angemeldet",
                error: (message) =>
                    `Fehler bei der Anmeldung dieses Nutzers: ${message}`,
            },
            unpromote: {
                title: "Nutzer abmelden",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Nutzer abmelden?",
                },
                success: "Nutzer abgemeldet",
                error: (message) =>
                    `Fehler bei der Abmeldung dieses Nutzers: ${message}`,
            },
            lock: {
                title: "Nutzer sperren",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Nutzer sperren?",
                },
                success: "Nutzer gesperrt",
                error: (message) =>
                    `Fehler bei der Sperrung dieses Nutzers: ${message}`,
            },
            unlock: {
                title: "Nutzer entsperren",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Nutzer entsperren?",
                },
                success: "Nutzer entsperrt",
                error: (message) =>
                    `Fehler bei der Entsperrung dieses Nutzers: ${message}`,
            },
            generate_token: {
                success: "Token erstellt",
                error: (message) =>
                    `Fehler bei der Erstellung dieses Tokens: ${message}`,
            },
        },
        role: {
            breadcrumb: "Rollen",
            manage: {
                breadcrumb: "Verwaltung",
                role: {
                    head: "Rollen",
                    empty: "Keine Ergebnisse",
                    id: "ID",
                    libelle: "Label",
                    rights: "Rechte",
                    action: "Aktion",
                    search: "Suche...",
                },
                create: {
                    title: "Rolle erstellen",
                    success: "Rolle erstellt",
                    error: (message) =>
                        `Fehler bei der Erstellung dieser Rolle: ${message}`,
                },
                update: {
                    title: "Rolle aktualisieren",
                    success: "Rolle aktualisiert",
                    error: (message) =>
                        `Fehler bei der Aktualisierung dieser Rolle: ${message}`,
                },
                delete: {
                    title: "Rolle löschen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie diese Rolle löschen?",
                    },
                    success: "Rolle gelöscht",
                    error: (message) =>
                        `Fehler bei der Löschung dieser Rolle: ${message}`,
                },
            },
        },
        right: {
            breadcrumb: "Rechte",
            manage: {
                breadcrumb: "Verwaltung",
                right: {
                    head: "Rechte",
                    empty: "Keine Ergebnisse",
                    id: "ID",
                    reference: "Referenz",
                    libelle: "Label",
                    action: "Aktion",
                    search: "Suche...",
                },
                create: {
                    title: "Recht erstellen",
                    success: "Recht erstellt",
                    error: (message) =>
                        `Fehler bei der Erstellung dieses Rechts: ${message}`,
                },
                update: {
                    title: "Recht aktualisieren",
                    success: "Recht aktualisiert",
                    error: (message) =>
                        `Fehler bei der Aktualisierung dieses Rechts: ${message}`,
                },
                delete: {
                    title: "Recht löschen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie dieses Recht löschen?",
                    },
                    success: "Recht gelöscht",
                    error: (message) =>
                        `Fehler bei der Löschung dieses Rechts: ${message}`,
                },
            },
        },
    },
    author: {
        breadcrumb: "Autoren",
        manage: {
            breadcrumb: "Verwaltung",
            author: {
                head: "Autoren",
                empty: "Keine Ergebnisse",
                id: "ID",
                firstname: "Vorname",
                lastname: "Nachname",
                signature: "Unterschrift",
                action: "Aktion",
                search: "Suche...",
            },
            create: {
                title: "Autor erstellen",
                success: "Autor erstellt",
                error: (message) =>
                    `Fehler bei der Erstellung dieses Autors: ${message}`,
            },
            update: {
                title: "Autor aktualisieren",
                success: "Autor aktualisiert",
                error: (message) =>
                    `Fehler bei der Aktualisierung dieses Autors: ${message}`,
            },
            delete: {
                title: "Autor löschen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Autor löschen?",
                },
                success: "Autor gelöscht",
                error: (message) =>
                    `Fehler beim Löschen dieses Autors: ${message}`,
            },
        },
    },
    artist: {
        search: {
            breadcrumb: "Künstlerliste",
            placeholder:
                "ID / ISNI / Name / Bürgerlicher Name / Übersetzter Name / Alias / Spitzname",
            empty: "Keine Ergebnisse",
            init: "Starten Sie bitte eine Suche",
            add: "Künstler hinzufügen",
            id: "ID",
            name: "Name",
            complement: "Zusatz",
            genre: "Kategorie",
            actions: "Aktionen",
            mainGenre: "Hauptkategorie",
            principalGenre: "Oberkategorie",
            secondaryGenre: "Unterkategorie",
            country: "Land",
            type: "Art",
            gender: "Geschlecht",
            role: "Rolle",
            reset: "Suche zurücksetzen",
            error: (message) => `Fehler bei der Künstlersuche: ${message}`,
            majorGenreGroup: "Hauptkategorien",
            otherGenreGroup: "Andere Kategorien",
            filters: {
                bio_en: {
                    title: "Biografie (EN)",
                    yes: "Mit Biografie (EN)",
                    no: "Ohne Biografie (EN)",
                },
                bio_fr: {
                    title: "Biografie (FR)",
                    yes: "Mit Biografie (FR)",
                    no: "Ohne Biografie (FR)",
                },
                bio_de: {
                    title: "Biografie (DE)",
                    yes: "Mit Biografie (DE)",
                    no: "Ohne Biografie (DE)",
                },
                bio_pt: {
                    title: "Biografie (PT)",
                    yes: "Mit Biografie (PT)",
                    no: "Ohne Biografie (PT)",
                },
                bio_es: {
                    title: "Biografie (ES)",
                    yes: "Mit Biografie (ES)",
                    no: "Ohne Biografie (ES)",
                },
                curation: {
                    title: "Kuratierung",
                    yes: "Mit kuratierten Fotos",
                    no: "Ohne kuratierte Fotos",
                },
                getty: {
                    title: "Getty",
                    yes: "Mit Getty-Fotos",
                    no: "Ohne Getty-Fotos",
                },
                label: {
                    title: "Label",
                    yes: "Mit Label-Fotos",
                    no: "Ohne Label-Fotos",
                },
                editorialized: {
                    title: "Redaktionelle Bearbeitung",
                    yes: "Redaktionell bearbeitet",
                    no: "Nicht redaktionell bearbeitet",
                },
            },
        },
        view: {
            breadcrumb: "Künstlervorstellung",
            id: "Music-Story ID",
            complementary: "Zusatz",
            type: "Typ",
            mainGenre: "Hauptgenre",
            country: "Land",
            profile: "Profil",
            univers: "Bereich",
            credits: "Erwähnungen",
            charts: "Top-Songs",
            disco: "Diskografie",
            images: "Bilder",
            biography: "Biografie",
            popularity: "Bekanntheit",
            noData: {
                univers: "Kein Bereich für diesen Künstler verfügbar",
                images: "Keine Bilder für diesen Künstler verfügbar",
                disco: "Kein Diskografie für diesen Künstler verfügbar",
                credits: "Keine Erwähnungen für diesen Künstler verfügbar",
                charts: "Keine Top-Songs für diesen Künstler verfügbar",
                biography: "Keine Biografie für diesen Künstler verfügbar",
                popularity: "Keine Bekanntheit für diesen Künstler verfügbar",
            },
            profileInformation: {
                head: "Zusätzliche Informationen",
                role: "Rolle",
                gender: "Geschlecht",
                Personne: {
                    born: "Geburtsdateum",
                    birth_country: "Geburtsort (Land)",
                    birth_area: "Geburtsort (Region)",
                    birth_city: "Geburtsort (Stadt)",
                    died: "Todestag",
                    death_country: "Sterbeort (Land)",
                    death_area: "Sterbeort (Region)",
                    death_city: "Sterbeort (Stadt)",
                },
                Groupe: {
                    born: "Gründungsdatum",
                    birth_country: "Gründungsort (Land)",
                    birth_area: "Gründungsort (Region)",
                    birth_city: "Gründungsort (Stadt)",
                    died: "Auflösung",
                    death_country: "Ort der Auflösung (Land)",
                    death_area: "Ort der Auflösung (Region)",
                    death_city: "Ort der Auflösung (Stadt)",
                },
                famousFor: "Berühmt für",
                awards: "Auszeichnungen",
            },
            profileNames: {
                head: "Namen",
                realname: "Bürgerlicher Name",
                alias: "Aliasname",
                names: "Andere Sprachen",
            },
            profileMembers: {
                head: "Mitglieder",
                current: "Aktuelle Mitglieder",
                old: "Frühere Mitglieder",
            },
            profileGroup: {
                head: "Bands",
                current: "Aktuelle Bands",
                old: "Frühere Bands",
            },
            profileExternalLink: {
                head: "Weiterführende Links",
                isni: "ISNI",
                site: "Webseiten",
            },
            profilePartnerLink: {
                head: "Partner-Links",
            },
            universGenres: {
                head: "Genre",
                main: "Hauptsächlich",
                principal: "1. Kategorie",
                secondary: "2. Kategorie",
                influence: "Einflüsse",
            },
            universRelated: {
                head: "Ähnliche Künstler",
                related: "Beeinflusst von",
                successor: "Haben beeinflusst",
                influence: "Einflussfaktoren",
            },
            universLanguages: {
                head: "Sprachen",
                principal: "Hauptsprache",
                secondary: "Andere Sprachen",
            },
            creditsCoPerformer: {
                head: "Mitwirkungen",
            },
            creditsContribution: {
                head: "Beiträge",
                description:
                    "Künstler, mit denen der aktuelle Künstler zusammengearbeitet hat (z. B.: Mitwirkung an Songs anderer Künstlers, Komponist für andere Künstler, Spielen eines Instruments andere Künstler...)",
            },
            creditsCollaboration: {
                head: "Zusammenarbeiten",
                description:
                    "Künstler, die mit dem aktuellen Künstler zusammengearbeitet haben (z. B. als Komponist, als Feature-Künstler, …)",
            },
            chartsRecordingCharts: {
                mainCharts: "Offizielle Charts",
                allCharts: "Alle Charts",
                cover: "Cover",
                title: "Titel",
                star: "Punktzahl",
                peak_rank: "Beste Platzierung",
                chart: "Hitparade",
                country: "Land",
                entry_date: "Einstiegsdatum",
                trend: "Trend",
                year: "Jahr",
                multi_charts: "Mehrere Charts",
                multi_country: "Mehrere Länder",
                resetCharts: "Filter zurücksetzen / Sortierung",
                resetEditorsPick: "Sortierung zurücksetzen",
                hit: {
                    title: "Hits",
                    description:
                        "Eine von unseren Experten getroffene Auswahl der beliebtesten Songs des Künstlers",
                },
                editorial_pick: {
                    title: "Redaktionell ausgewählt",
                    description:
                        "Eine Auswahl bemerkenswerter Songs aus unserer Musikredaktion",
                },
                empty: "Keine Ergebnisse",
            },
            discoAlbums: {
                head: "Alben",
                id: "Id",
                cover: "Cover",
                title: "Titel",
                type: {
                    head: "Art",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Kompilation",
                    karaoke: "Karaoke",
                    ring: "Zyklus",
                    other: "Anderes",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                year: "Jahr",
                genre: "Genre",
                release_date: "Erscheinungsdatum",
                reference: "Bewertung",
                has_review: "Rezension",
                has_credits: "Erwähnungen",
                has_chart: "Hitparade",
                complementary: "Verwandter Inhalt",
                empty: "Keine Ergebnisse",
                reset: "Filter zurücksetzen / Sortierung",
            },
            imagesGallery: {
                head: "Galerie",
                tags: "Nach Tags filtern",
                empty: "Keine Bilder in der Galerie",
                detail: {
                    head: "Ausschnitt",
                    show: "Originalbild anzeigen",
                    source: "Quelle",
                    copyright: "Copyright",
                    tags: "Tags",
                    metas: "Metadaten",
                },
            },
            biographyGallery: {
                writer: "Autor",
                updated_at: "Aktualisiert am",
                words: "Wörter",
                signs: "Zeichen",
                biography: "Biografie",
                empty: "Keine Biografie",
                licence: {
                    title: "Mit freundlicher Genehmigung des CD Journal",
                    subtitle:
                        "Dieser Inhalt darf nicht ohne eine Lizensierung durch das CD Journal genutzt werden",
                },
            },
            popularityOverview: {
                head: "Überblick",
                global: {
                    title: "Weltweit",
                    recordings: "Titel in den Charts:",
                    description:
                        "Der Beliebtheitsgrad eines Künstlers basiert auf den Daten der Charts. Der Wert ist das Ergebnis der Popularität der Titel des Künstlers und wird in zwei Formen dargestellt: Rang des Künstlers (innerhalb unserer Rangliste der populären Künstler) und Popularitätsrate (in %, relativ zum höchsten Popularitätswert).",
                },
                gold: {
                    title: "Katalog",
                    recordings: "Titel in den Katalog-Charts:",
                    description:
                        "Die Katalog-Popularität eines Künstlers basiert auf seiner Präsenz in den Charts vor mehr als 18 Monaten.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                },
                current: {
                    title: "Aktuell",
                    recordings: "Titel in den aktuellen Charts:",
                    description:
                        "Die aktuelle Popularität eines Künstlers basiert auf seiner Präsenz in den Charts in den vergangenen 18 Monaten.",
                },
                type: {
                    label: "Art:",
                    gold: "Katalog",
                    current: "Aktuell",
                },
                peak: "Spitzenwert:",
            },
            popularityHistogram: {
                head: "Popularität über die Zeit",
                tabs: {
                    gold: {
                        title: "Katalog",
                        description:
                            "Die Katalog-Popularität eines Künstlers basiert auf seiner Präsenz in den Charts vor mehr als 18 Monaten.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                        header: "Die Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                    },
                    current: {
                        title: "Aktuell",
                        description:
                            "Die aktuelle Popularität eines Künstlers basiert auf seiner Präsenz in den Charts in den vergangenen 18 Monaten.",
                    },
                },
                gold: "Katalog-Popularität",
                current: "Aktuelle Popularität",
                popover: {
                    noData: "Keine Daten",
                    popularity: "Künstler-Popularität:",
                    mostPopularTitle: "Beliebteste Titel:",
                    bestRank: "Beste Platzierung:",
                    bestCountry: "Bestes Land:",
                    charts: (n) => `Basierend auf ${n} Charts`,
                },
            },
            popularityMap: {
                head: "Popularität nach Land",
                tabs: {
                    gold: {
                        title: "Katalog",
                        description:
                            "Die Katalog-Popularität eines Künstlers basiert auf seiner Präsenz in den Charts vor mehr als 18 Monaten.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                    },
                    current: {
                        title: "Aktuell",
                        description:
                            "Die aktuelle Popularität eines Künstlers basiert auf seiner Präsenz in den Charts in den vergangenen 18 Monaten.",
                    },
                },
                popover: {
                    noData: "Keine Daten",
                    popularity: "Künstler-Popularität:",
                    mostPopularTitle: "Beliebteste Titel:",
                    bestRank: "Beste Platzierung:",
                    bestMonth: "Monat (Beste Platzierung):",
                    weeksInCharts: "Wochen in den Charts:",
                    charts: (n) => `Basierend auf ${n} Charts`,
                },
            },
        },
        create: {
            breadcrumb: "Erstellen",
            placeholder:
                "ID / ISNI / Name / Bürgerlicher Name / Übersetzte Namen / Alias / Spitzname",
            empty: "Keine Ergebnisse",
            init: "Bitte suchen",
            source: "Quelle",
            name: "Name",
            complement: "Zusatz",
            type: "Art",
            gender: "Geschlecht",
            country: "Land",
            real_name: "Bürgerlicher NN-VN",
            alias: "Alias",
            member: "Mitglieder",
            band: "Bands",
            site: "Webseiten",
            social: "Social Media",
            actions: "Aktionen",
            create: {
                title: "Künstler erstellen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Künstler erstellen?",
                },
                success: "Künstler erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieses Künstlers: ${message}`,
            },
        },
        management: {
            breadcrumb: "Künstler ändern",
            profile: "Profil",
            editorial: "Redaktion",
            biography: "Biografie",
            discography: "Diskografie",
            curation: "Kuratierung",
            getty: "Getty",
            labelPicture: "Label-Fotos",
            matching: "Matching",
            charts: "Charts",
            tracking: "Tracking",
            action: {
                complete: {
                    message: "Künstler-Erstellung im Gange",
                    button: "Erstellung abschließen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie die Erstellung dieses Künstlers abschließen?",
                    },
                    success: "Erstellung abgeschlossen",
                    error: (message) =>
                        `Fehler beim Erstellen dieses Künstlers: ${message}`,
                },
            },
            profileInformation: {
                head: "Hauptinformationen",
                type: "Art",
                name: "Name",
                complement: "Zusatz",
                realname: "Bürgerlicher Name",
                gender: "Geschlecht",
                country: "Land",
                isni: "ISNI",
                isniSearch: {
                    tooltip: "ISNI-Suche",
                    head: "ISNI-Suche",
                    placeholder: "Nach Name oder ISNI suchen",
                    error: (message) => `Fehler bei der Suche: ${message}`,
                    isni: "ISNI",
                    name: "Name",
                    select: "ISNI auswählen",
                    empty: "Keine Ergebnisse",
                },
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Hauptinformationen aktualisieren",
                    success: "Informationen aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren dieser Information: ${message}`,
                },
            },
            names: {
                head: "Andere Sprachen",
                empty: "Keine Sprachen",
                name: "Name",
                language: "Sprache",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Andere Sprachen aktualisieren",
                    success: "Andere Sprachen aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren dieser anderen Sprache: ${message}`,
                },
            },
            alias: {
                head: "Alias",
                empty: "Kein alias",
                alias: "Alias",
                type: "Art",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Alias aktualisieren",
                    success: "Alias aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren dieses Alias: ${message}`,
                },
            },
            member: {
                head: "Mitglieder",
                empty: "Keine Mitglieder",
                artist: "Name",
                start_date: "Start",
                end_date: "Ende",
                role: "Rolle",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Mitglieder aktualisieren",
                    success: "Mitglieder aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Mitglieder: ${message}`,
                },
            },
            group: {
                head: "Bands",
                empty: "Keine Bands",
                artist: "Name",
                start_date: "Start",
                end_date: "Ende",
                role: "Rolle",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Bands aktualisieren",
                    success: "Bands aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Bands: ${message}`,
                },
            },
            genre: {
                head: "Genre",
                empty: "Kein Genre",
                genre: "Name",
                type: "Art",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Genres aktualisieren",
                    success: "Genres aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Genres: ${message}`,
                },
            },
            role: {
                head: "Rolle",
                empty: "Keine Rolle",
                role: "Rolle",
                main: "Hauptsächlich",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Rollen aktualisieren",
                    success: "Rollen aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Rollen: ${message}`,
                },
            },
            social: {
                head: "Social Media",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Social-Media-Eintrag aktualisieren",
                    success: "Social-Media-Eintrag aktualisieren",
                    error: (message) =>
                        `Fehler beim Aktualisieren des Social-Media-Eintrags: ${message}`,
                },
                duplicates: {
                    tooltip:
                        "Es gibt andere Künstler mit derselben Social-Media-URL",
                    head: "Doppelte Social-Media-Einträge",
                    body: "Folgende Künstler haben dieselbe Social-Media-URL:",
                },
            },
            site: {
                head: "Webseiten",
                empty: "Keine Webseite",
                url: "URL",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Webseiten aktualisieren",
                    success: "Webseiten aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Webseiten: ${message}`,
                },
            },
            editorialInformation: {
                head: "Hauptinformationen",
                Personne: {
                    born: "Geburtsdatum",
                    birth_country: "Geburtsort (Land)",
                    birth_area: "Geburtsort (Region)",
                    birth_city: "Geburtsort (Stadt)",
                    died: "Todesdatum",
                    death_country: "Sterbeort (Land)",
                    death_area: "Sterbeort (Region)",
                    death_city: "Sterbeort (Stadt)",
                },
                Groupe: {
                    born: "Gründungsdatum",
                    debut_date: "Startdatum",
                    birth_country: "Gründungsort (Land)",
                    birth_area: "Gründungsort (Region)",
                    birth_city: "Gründungsort (Stadt)",
                    activity_period: "Zeitraum der Aktivität",
                    activity_period_start: "Startdatum",
                    activity_period_end: "Enddatum",
                    died: "Auflösung",
                    death_country: "Ort der Auflösung (Land)",
                    death_area: "Ort der Auflösung (Region)",
                    death_city: "Ort der Auflösung (Stadt)",
                },
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Hauptinformationen aktualisieren",
                    success: "Informationen aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Informationen: ${message}`,
                },
            },
            highlights: {
                head: "Höhepunkte",
                famous_for: "Berühmt für",
                awards: "Auszeichnungen",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Höhepunkte aktualisieren",
                    success: "Höhepunkte aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Höhepunkte: ${message}`,
                },
            },
            collaboration: {
                head: "Zusammenarbeiten",
                empty: "Keine Zusammenarbeiten",
                artist: "Name",
                start_date: "Start",
                end_date: "Ende",
                role: "Rolle",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Zusammenarbeiten aktualisieren",
                    success: "Zusammenarbeiten aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Zusammenarbeiten: ${message}`,
                },
            },
            contribution: {
                head: "Beiträge",
                empty: "Keine Beiträge",
                artist: "Name",
                start_date: "Start",
                end_date: "End",
                role: "Rolle",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Zusammenarbeiten aktualisieren",
                    success: "Zusammenarbeiten aktualisieren",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Zusammenarbeiten: ${message}`,
                },
            },
            related: {
                head: "Verwandte Künstler",
                empty: "Keine verwandten Künstler",
                artist: "Name",
                type: "Art",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Verwandte Künstler aktualisieren",
                    success: "Verwandte Künstler aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der verwandten Künstler: ${message}`,
                },
            },
            bio: {
                head: {
                    FR: "Music Story FR",
                    EN: "Music Story EN",
                    DE: "Music Story DE",
                    PT: "Music Story PT",
                    JA: "Music Story JA",
                    ES: "Music Story ES",
                },
                is_reference: "Referenz-Biographie",
                to_translate: () => (
                    <>
                        Laufender Prozess.
                        <br />
                        Diese Biografie wird automatisch aus der entsprechenden
                        Referenzbiografie übersetzt und aktualisiert. Keine
                        Aktion erforderlich.
                    </>
                ),
                author: "Autor",
                archive: "Archiv",
                cd_journal: "CD Journal",
                reset: {
                    title: "Biografie zurücksetzen",
                },
                update: {
                    title: "Biografie aktualisieren",
                    success: "Biografie aktualisiert",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Sie sind im Begriff, eine Biografie zu ändern, die bereits übersetzt wurde und nach der Änderung nicht mehr übersetzt wird.",
                    },
                    error: (message) =>
                        `Fehler beim Aktualisieren der Biografie: ${message}`,
                },
                translation: {
                    placeholder: "Bitte wählen Sie eine Sprache",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie diese Biografie ersetzen?",
                    },
                },
            },
            wikipedia: {
                head: "Wikipedia",
                empty: "Keine Wikipedia-Einträge",
                url: "URL",
                reset: {
                    title: "Eingabe zurücksetzen",
                },
                update: {
                    title: "Wikpedia-Einträge aktualisieren",
                    success: "Wikipedia-Einträge aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren der Wikipedia-Einträge: ${message}`,
                },
                names: {
                    head: "Wikipedia-Einträge in „Anderen Sprachen“ hinzufügen",
                    lang: "Sprache",
                    name: "Name",
                },
            },
            disco: {
                albums: {
                    head: "Alben",
                    search: "ID / Titel",
                    empty: "Keine Ergebnisse",
                    id: "Id",
                    title: "Titel",
                    type: {
                        head: "Art",
                        live: "Live",
                        remix: "Remix",
                        compilation: "Kompilation",
                        karaoke: "Karaoke",
                        ring: "Zyklus",
                        other: "Anderes",
                        original: "Original",
                    },
                    format: {
                        head: "For.",
                        title: "Format",
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    genre: "Genre",
                    release_date: {
                        head: "Datum",
                        title: "Erscheinungsdatum",
                    },
                    cover: {
                        head: "Cov.",
                        title: "Cover Art",
                    },
                },
                releases: {
                    head: "Veröffentlichungen",
                    search: "ID / Strichcode",
                    empty: "Keine Ergebnisse",
                    id: "Id",
                    barcode: {
                        head: "Strichcode",
                        title: "Strichcode",
                    },
                    commentary: "Kommentar",
                    support: "Unterstützung",
                    release_date: {
                        head: "Datum",
                        title: "Erscheinungsdatum",
                    },
                },
                sandbox: {
                    head: "Testumgebung",
                    search: {
                        input: "ID / Titel / Strichcode",
                        title: "Suche in allen Diskografien",
                        result: (query) => `Suchergebnisse für "${query}"`,
                        reset: "Suche zurücksetzen",
                    },
                    empty: "Keine Ergebnisse",
                    partner: "Partner",
                    id: "Id",
                    barcode: "Strichcode",
                    title: "Titel",
                    nb_track: "Anzahl Tracks",
                    actions: "Aktionen",
                    view_product: "Produkt anschauen",
                    compare_tracklists: "Tracklisten vergleichen",
                    view_matchings: "Matching(s) anschauen",
                    popover: {
                        id: "ID:",
                        title: "Titel:",
                        artist: "Künstler:",
                        barcode: "Strichcode:",
                        label: "Label:",
                        release_date: "Erscheinungsdatum:",
                        cover_art_error: "Cover Art kann nicht geladen werden",
                    },
                    matchings: {
                        head: "Matching",
                        body: "Für dieses Produkt gibt es Matchings in folgenden Diskografien:",
                        artists: "Künstler",
                        album: "Album",
                        release: "Veröffentlichung",
                        close: "Schließen",
                    },
                    tracklistComparison: {
                        head: "Tracklist-Vergleich",
                        tracks: {
                            head: "MusicStory Tracklist",
                            empty: "Keine Ergebnisse",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Titel",
                            subtitle: "Untertitel",
                            duration: "Lg.",
                            hit: "Hit",
                            discovery: {
                                head: "EP",
                                title: "Redaktionelle Auswahl",
                            },
                        },
                        partnerTracks: {
                            head: "Partner-Tracklist",
                            empty: "Keine Ergebnisse",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Titel",
                            duration: "Lg.",
                        },
                        replace: {
                            title: "MusicStory-Tracklist ersetzen",
                            alert: {
                                title: "Sind Sie sicher?",
                                text: "Sie möchten die Tracklist dieser Veröffentlichung ersetzen?",
                            },
                            success: "Tracklist wurde ersetzt",
                            error: (message) =>
                                `Fehler beim Ersetzen der Tracklist: ${message}`,
                            warningIsrc: {
                                title: "Sind Sie sicher?",
                                text: "Beim Versuch, die Tracklist dieser Veröffentlichung zu ersetzen, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                            },
                        },
                    },
                    copyProduct: {
                        title: "Produkt hinzufügen",
                        head: "Produkt hinzufügen",
                        copyAsAlbum: {
                            title: "Als neues Album hinzufügen",
                            created: "Produkt als neues Album hinzugefügt",
                            error: (message) =>
                                `Fehler beim Hinzufügen des Produkts als ein neues Album: ${message}`,
                            warningBarcode: {
                                title: "Sind Sie sicher?",
                                text: (message) =>
                                    `Beim Versuch dieses Album hinzuzufügen, erscheint folgende Warnung: ${message}`,
                            },
                            warningIsrc: {
                                title: "Sind Sie sicher?",
                                text: "Beim Versuch dieses Album hinzuzufügen, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                            },
                        },
                        copyAsRelease: {
                            title: "Aktuelles Album als eine Neuveröffentlichung hinzufügen",
                            created:
                                "Produkt als eine Neuveröffentlichung hinzugefügt",
                            matched:
                                "Produkt mit einer bestehenden Veröffentlichung gematched",
                            error: (message) =>
                                `Fehler beim Hinzufügen oder Matchen des Produkts als eine Veröffentlichung: ${message}`,
                            warningBarcode: {
                                title: "Sind Sie sicher?",
                                text: (message) =>
                                    `Beim Versuch diese Veröffentlichung hinzuzufügen, erscheint folgende Warnung: ${message}`,
                            },
                            warningIsrc: {
                                title: "Sind Sie sicher?",
                                text: "Beim Versuch diese Veröffentlichung hinzuzufügen, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                            },
                        },
                    },
                    pagination: ({ from, to, size }) => `${size} products`,
                    filters: {
                        partners: "Partner",
                        status: "Status",
                    },
                    status: {
                        MATCHED_IN_CURRENT_DISCOGRAPHY:
                            "In aktuellen Diskografien gematched",
                        MATCHED_IN_OTHER_DISCOGRAPHY:
                            "In anderer Diskografie gematched",
                        NOT_MATCHED_COMPLETE: "Nicht gematched, vollständig",
                        NOT_MATCHED_INCOMPLETE:
                            "Nicht gematched, unvollständig",
                    },
                },
                tracks: {
                    head: "Tracks",
                    search: "ID / Isrc / Titel",
                    empty: "Keine Ergebnisse",
                    no_cd: {
                        head: "D",
                        title: "Disc-Nummer",
                    },
                    no_piste: {
                        head: "T",
                        title: "Track-Nummer",
                    },
                    id: "Id ",
                    isrc: "Isrc",
                    title: "Titel",
                    subtitle: {
                        head: "Untertitel",
                        title: "Untertitel",
                    },
                    duration: {
                        head: "Lg.",
                        title: "Länge",
                    },
                },
                matching: {
                    partner: "Affiliates",
                    id: "Id",
                    actions: "Aktionen",
                    view: "Track ansehen",
                    delete: "Löschen",
                },
                objects: {
                    album: {
                        tabTitle: "Album",
                        basic: {
                            head: "Grunddaten",
                            artist: "Künstler",
                            title: "Titel",
                            subtitle: "Untertitel",
                            type: "Art",
                            format: "Format",
                            label: "Label",
                            publisher: "Verleger",
                            release_date: "Erscheinungsdatum",
                            genre: "Genre",
                            reference: "Bewertung",
                            typeOptions: {
                                live: "Live",
                                remix: "Remix",
                                compilation: "Kompilation",
                                karaoke: "Karaoke",
                                ring: "Zyklus",
                                other: "Anderes",
                                original: "Original",
                            },
                            formatOptions: {
                                album: "Album",
                                ep: "EP",
                                single: "Single",
                                mixtape: "Mixtape",
                                mcd: "Maxi",
                            },
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            create: {
                                title: "Album erstellen",
                                success: "Album erstellt",
                                error: (message) =>
                                    `Fehler beim Erstellen dieses Albums: ${message}`,
                            },
                            update: {
                                title: "Album aktualisieren",
                                success: "Album aktualisieren",
                                error: (message) =>
                                    `Fehler beim Aktualisieren dieses Albums: ${message}`,
                            },
                            delete: {
                                title: "Album löschen",
                                hasReleases:
                                    "Es gibt noch eine oder mehrere Ausgabe(n) des Albums",
                                hasReview:
                                    "Es gibt noch eine Überprüfung des Albums",
                                alert: {
                                    title: "Sind Sie sicher?",
                                    text: "Möchten Sie dieses Album löschen?",
                                },
                                warning: {
                                    title: "Sind Sie sicher?",
                                    text: (messages) =>
                                        `Warnung beim Löschen dieses Albums: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Album gelöscht",
                                error: (message) =>
                                    `Fehler beim Löschen dieses Albums: ${message}`,
                            },
                            transfer: {
                                title: "Album verschieben",
                                success: ({ artist, album }) =>
                                    `Album "${album}" dem Künstler "${artist}" erfolgreich zugeordnet`,
                                error: (message) =>
                                    `Fehler beim Verschieben dieses Albums: ${message}`,
                            },
                        },
                        artist: {
                            head: "Künstler",
                            main: "Hauptsächlich",
                            principal: "1. Kategorie",
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            update: {
                                title: "Künstler aktualisieren",
                                success: "Künstler aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren dieses Künstlers: ${message}`,
                            },
                        },
                        cover: {
                            head: "Cover",
                            musicstory: "Music-Story",
                            partner: "Partner",
                            url: "Cover-URL",
                            ean: "Strichcode",
                            create: {
                                title: "Cover anlegen",
                                success: "Cover angelegt",
                                error: (message) =>
                                    `Fehler beim Anlegen dieses Covers: ${message}`,
                            },
                            update: {
                                title: "Cover aktualisieren",
                                success: "Cover aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren des Covers: ${message}`,
                            },
                            automatic: {
                                success:
                                    "Das Cover wurde automatisch hinzugefügt.",
                                error: (message) =>
                                    `Fehler beim automatischen Hinzufügen das Cover: ${message}`,
                            },
                        },
                        review: {
                            head: "Überprüfung",
                            author: "Redakteur",
                            reset: {
                                title: "Überprüfung zurücksetzen",
                            },
                            update: {
                                title: "Überprüfung aktualisieren",
                                success: "Überprüfung aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren der Überprüfung: ${message}`,
                            },
                        },
                        translations: {
                            head: "Übersetzungen",
                            language: "Sprache",
                            title: "Titel",
                            subtitle: "Untertitel",
                            languagePlaceholder: "Sprache hinzufügen",
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            update: {
                                title: "Übersetzung aktualisieren",
                                success: "Übersetzung aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren der Übersetzung: ${message}`,
                            },
                        },
                    },
                    release: {
                        tabTitle: "Veröffentlichung",
                        basic: {
                            head: "Grunddaten",
                            barcode: "Strichcode",
                            release_date: "Erscheinungsdatum",
                            editor: "Redakteur",
                            support: "Unterstützung",
                            commentary: "Kommentar",
                            reference: "Bezug",
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            create: {
                                title: "Veröffentlichung erstellen",
                                success: "Veröffentlichung erstellt",
                                error: (message) =>
                                    `Fehler beim Erstellen dieser Veröffentlichung: ${message}`,
                                warning: {
                                    title: "Sind Sie sicher?",
                                    text: (message) =>
                                        `Beim Versuch diese Veröffentlichung zu erstellen, erscheint folgende Warnung: ${message}`,
                                },
                            },
                            update: {
                                title: "Veröffentlichung aktualisieren",
                                success: "Veröffentlichung aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren dieser Veröffentlichung: ${message}`,
                            },
                            delete: {
                                title: "Veröffentlichung löschen",
                                alert: {
                                    title: "Sind Sie sicher?",
                                    text: "Möchten Sie diese Veröffentlichung löschen?",
                                },
                                warning: {
                                    title: "Sind Sie sicher?",
                                    text: (messages) =>
                                        `Beim Versuch diese Veröffentlichung zu löschen, erscheint folgende Warnung: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Veröffentlichung gelöscht",
                                error: (message) =>
                                    `Fehler beim Löschen dieser Veröffentlichung: ${message}`,
                            },
                            transfer: {
                                title: "Veröffentlichung verschieben",
                                artist: "Künstler",
                                album: "Album",
                                success: ({ artist, album, releaseId }) =>
                                    `Veröffentlichung "${releaseId}" des Albums "${album}" des Künstlers "${artist}" erfolgreich verschoben`,
                                error: (message) =>
                                    `Fehler beim Verschieben dieses Albums: ${message}`,
                                same_album_error:
                                    "Das Album sollte nicht jenes sein, welches aktuell mit dieser Veröffentlichung verknüpft ist",
                            },
                        },
                        matching: {
                            head: "Partner",
                            empty: "Keine Ergebnisse",
                            delete: {
                                title: "Matching löschen",
                                alert: {
                                    title: "Sind Sie sicher?",
                                    text: "Möchten Sie dieses Matching löschen?",
                                },
                                success: "Matching gelöscht",
                                error: (message) =>
                                    `Fehler beim Löschen des Matchings: ${message}`,
                            },
                        },
                        tracklist: {
                            head: "Tracklist",
                            tracks: {
                                empty: "Keine Ergebnisse",
                                no_cd: "D",
                                no_piste: "T",
                                isrc: "Isrc",
                                title: "Titel",
                                subtitle: "Subtitel",
                                duration: "Lg.",
                                hit: "Hit",
                                genre: "Genre",
                                discovery: {
                                    head: "EP",
                                    title: "Redaktionelle Auswahl",
                                },
                                validation: {
                                    no_cd: "Die Disc-Nummer sollte eine positive ganze Zahl sein",
                                    no_piste:
                                        "Die Track-Nummer sollte eine positive ganze Zahl sein",
                                    title: "Titel kann nicht leer bleiben",
                                    duration:
                                        "Länge im Format HH:MM:SS eingeben",
                                    duplicatePosition:
                                        "Es gibt einen anderen Track mit derselben Disc- und Track-Nummer",
                                    duplicateIsrc:
                                        "Es gibt noch einen anderen Track mit demselben ISRC",
                                },
                            },
                            reset: {
                                title: "Tracks zurücksetzen",
                            },
                            update: {
                                title: "Tracks aktualisieren",
                                success: "Die Tracks wurden geändert",
                                error: (message) =>
                                    `Fehler beim Ändern der Tracks: ${message}`,
                                warningIsrc: {
                                    title: "Sind Sie sicher?",
                                    text: "Beim Versuch diese Tracklist zu aktualisieren, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                                },
                            },
                        },
                    },
                    track: {
                        tabTitle: "Track",
                        basic: {
                            head: "Grunddaten",
                            title: "Titel",
                            subtitle: "Untertitel",
                            isrc: "ISRC",
                            duration: "Länge",
                            no_cd: "CD-Nr.",
                            no_piste: "Track-Nr.",
                            hit: "Hit",
                            discovery: "Redaktionelle Auswahl",
                            language: "Gesungene Sprache",
                            dialect: "Dialekt",
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            create: {
                                title: "Track hinzufügen",
                                success: "Track hinzugefügt",
                                error: (message) =>
                                    `Fehler beim Hinzufügen dieses Tracks: ${message}`,
                                warningIsrc: {
                                    title: "Sind Sie sicher?",
                                    text: "Beim Versuch diesen Track hinzuzufügen, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                                },
                            },
                            update: {
                                title: "Track aktualisieren",
                                success: "Track aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren des Tracks: ${message}`,
                                warningIsrc: {
                                    title: "Sind Sie sicher?",
                                    text: "Beim Versuch diesen Track zu aktualisieren, erscheint folgende Warnung: Es wurden Aufnahmen mit demselben ISRC gefunden",
                                },
                            },
                            delete: {
                                title: "Track löschen",
                                alert: {
                                    title: "Sind Sie sicher?",
                                    text: "Möchten Sie diesen Track entfernen?",
                                },
                                success: "Track gelöscht",
                                error: (message) =>
                                    `Fehler beim Löschen des Tracks: ${message}`,
                            },
                        },
                        translations: {
                            head: "Übersetzungen",
                            language: "Sprache",
                            title: "Titel",
                            subtitle: "Untertitel",
                            languagePlaceholder: "Sprache hinzufügen",
                            reset: {
                                title: "Eingabe zurücksetzen",
                            },
                            update: {
                                title: "Übersetzung aktualisieren",
                                success: "Übersetzung aktualisiert",
                                error: (message) =>
                                    `Fehler beim Aktualisieren der Übersetzung: ${message}`,
                            },
                        },
                        matching: {
                            head: "Partner",
                            empty: "Keine Ergebnisse",
                            delete: {
                                title: "Matching löschen",
                                alert: {
                                    title: "Sind Sie sicher?",
                                    text: "Möchten Sie dieses Matching löschen?",
                                },
                                success: "Matching gelöscht",
                                error: (message) =>
                                    `Fehler beim Löschen des Matchings: ${message}`,
                            },
                        },
                    },
                },
            },
            match: {
                empty: "Keine Matchings",
                id: "ID",
                name: "Name",
                type_match: "Art",
                status: "Status",
                pending: "Matching im Gange...",
                success: "Matching vollständig",
            },
            imageCuration: {
                head: "Galerie",
                empty: "Keine Bilder in der Galerie",
                width: "Breite",
                height: "Höhe",
                created_at: "Erstelldatum",
                copyright: "Copyright",
                status: "Status",
                edit: "Bearbeiten",
                edit_image: "Bildinformationen bearbeiten",
                delete: "Löschen",
                delete_image: "Bild löschen",
            },
            imageGetty: {
                gallery: {
                    head: "Galerie",
                    empty: "Keine Bilder in der Galerie",
                    width: "Breite",
                    height: "Höhe",
                    created_at: "Erstelldatum",
                    copyright: "Copyright",
                    status: "Status",
                    edit: "Bearbeiten",
                    edit_image: "Bildinformationen bearbeiten",
                    delete: "Löschen",
                    delete_image: "Bild löschen",
                },
            },
            imageLabel: {
                head: "Galerie",
                empty: "Keine Bilder in der Galerie",
                width: "Breite",
                height: "Höhe",
                created_at: "Erstelldatum",
                copyright: "Copyright",
                status: "Status",
            },
            chartsList: {
                head: "Charts",
                empty: "Keine Ergebnisse",
                source: "Quelle",
                area: "Region",
                format: "Format",
                country: "Land",
                origin: "Herkunft",
                date_out: "Ausgeschieden",
                track_title: "Titel",
                title: "Chartname",
                country: "Land",
                date_in: "Eingestiegen",
                date_out: "Ausgestiegen",
                nb_week: "Wochen-Nr.",
                rank_in: "Einstiegsplatz",
                max_rank: "Höchstplatzierung",
                rank_out: "Letzte Platzierung",
            },
            trackingPlanning: {
                head: "Planungshistorie",
                empty: "Keine Ergebnisse",
                date: "Datum",
                title: "Titel",
                album: "Album",
                category: "Kategorie",
                reason: "Grund",
                classification: "Klassifizierung",
                tasks: "Aufgaben",
                status: {
                    TODO: "Zu erledigen",
                    COMPLETED: "Vollständig",
                    TO_BE_CORRECTED: "Korrigieren",
                    VALIDATED_WITHOUT_CONTROL: "Validiert ohne Überprüfung",
                    VERIFIED_AND_VALIDATED: "Verifiziert und Validiert",
                    ERROR: "Fehlerhaft",
                },
            },
            trackingPicture: {
                head: "Bildbearbeitungshistorie",
                empty: "Keine Ergebnisse",
                picture: "Bild",
                date: "Datum",
                username: "Nutzer",
                action: "Aktion",
                curation_cms_artist_image_add_completed:
                    "Kuratiertes Bild hinzugefügt",
                curation_cms_artist_image_update_completed:
                    "Kuratiertes Bild aktualisiert",
                curation_cms_artist_image_main_completed:
                    "Kuratiertes Bild als Hauptbild festgelegt",
                curation_cms_artist_image_delete_completed:
                    "Kuratiertes Bild gelöscht",
                label_cms_artist_picture_status_change_completed:
                    "Label-Bild-Status geändert",
                getty_cms_artist_picture_add_completed:
                    "Getty-Bild hinzugefügt",
                label_cms_artist_picture_add_completed:
                    "Label-Bild hinzugefügt",
                getty_cms_artist_picture_delete_completed:
                    "Getty-Bild gelöscht",
                label_cms_artist_picture_delete_completed:
                    "Label-Bild gelöscht",
                getty_cms_artist_picture_check_completed: "Bilder geprüft",
                getty_move_picture_completed: "Getty-Bild verschoben",
                label_move_picture_completed: "Label-Bild verschoben",
            },
            trackingLog: {
                head: "Protokolle",
                empty: "Keine Ergebnisse",
                date: "Datum",
                username: "Nutzer",
                action: "Aktion",
                cms_artist_alias_create_completed: "Alias erstellt",
                cms_artist_alias_update_completed: "Alias aktualisiert",
                cms_artist_alias_delete_completed: "Alias gelöscht",
                cms_artist_biography_create_completed: "Biografie erstellt",
                cms_artist_biography_update_completed: "Biografie aktualisiert",
                cms_artist_biography_translate_completed:
                    "Biografie Übersetzte",
                cms_artist_collaboration_create_completed:
                    "Zusammenarbeit erstellt",
                cms_artist_collaboration_delete_completed:
                    "Zusammenarbeit gelöscht",
                cms_artist_collaboration_update_completed:
                    "Zusammenarbeit aktualisiert",
                cms_artist_contribution_create_completed: "Beiträge erstellt",
                cms_artist_contribution_delete_completed: "Beiträge gelöscht",
                cms_artist_contribution_update_completed:
                    "Beiträge aktualisiert",
                cms_artist_create_completed: "Künstler erstellt",
                cms_artist_update_completed: "Künstler aktualisiert",
                cms_artist_delete_completed: "Künstler gelöscht",
                cms_artist_genre_create_completed: "Genre erstellt",
                cms_artist_genre_delete_completed: "Genre gelöscht",
                cms_artist_genre_update_completed: "Genre aktualisiert",
                cms_artist_group_create_completed: "Gruppe erstellt",
                cms_artist_group_delete_completed: "Gruppe gelöscht",
                cms_artist_group_update_completed: "Gruppe aktualisiert",
                cms_artist_member_create_completed: "Mitglied erstellt",
                cms_artist_member_delete_completed: "Mitglied gelöscht",
                cms_artist_member_update_completed: "Mitglied aktualisiert",
                cms_artist_name_create_completed: "Name erstellt",
                cms_artist_name_update_completed: "Name aktualisiert",
                cms_artist_name_delete_completed: "Name gelöscht",
                cms_artist_related_create_completed:
                    "Verwandte Künstler erstellt",
                cms_artist_related_delete_completed:
                    "Verwandte Künstler gelöscht",
                cms_artist_related_update_completed:
                    "Verwandte Künstler aktualisiert",
                cms_artist_role_update_completed: "Rolle aktualisiert",
                cms_artist_site_create_completed: "Seite erstellt",
                cms_artist_site_delete_completed: "Seite gelöscht",
                cms_artist_site_update_completed: "Seite aktualisiert",
                cms_artist_social_create_completed:
                    "Social-Media-Eintrag erstellt",
                cms_artist_social_delete_completed:
                    "Social-Media-Eintrag gelöscht",
                cms_artist_social_update_completed:
                    "Social-Media-Eintrag aktualisiert",
                cms_artist_wikipedia_create_completed:
                    "Wikipedia-Eintrag erstellt",
                cms_artist_wikipedia_update_completed:
                    "Wikipedia-Eintrag aktualisiert",
            },
        },
        delete: {
            breadcrumb: "Künstler-Löschung",
            empty: "Leer",
            object: "Objekt",
            nb: "Valide",
            url: "Link",
            album: "Album",
            alias: "Alias",
            bio: "Music Story",
            collab: "Zusammenarbeit und Beitrag",
            member_group: "Mitglied und Gruppe",
            picture_curation: "Kuratiertes Foto",
            picture_right: "Foto mit Bildrechten",
            plannings: "Planungen",
            related1: "Hat Verbindungen",
            related2: "Verbunden mit",
            site: "Webseiten",
            social: "Social Media",
            wiki: "Wikipedia",
            delete: {
                title: "Künstler-Löschung",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Achtung: Wichtige Objekte haben immer einen Bezug zum Künstler. Der Löschvorgang kann nicht rückgängig gemacht werden. Möchten Sie wirklich fortfahren?",
                },
                success: "Künstler erfolgreich gelöscht",
                error: (message) =>
                    `Fehler beim Löschen dieses Künstlers: ${message}`,
            },
        },
    },
    album: {
        view: {
            breadcrumb: "Album-Übersicht",
            id: "Music-Story ID",
            title: "Titel",
            subtitle: "Untertitel",
            genre: "Genre",
            profile: "Aufnahme-Explorer",
            univers: "Album",
            cover: "Cover",
            credits: "Erwähnungen",
            review: "Überprüfung",
            charts: "Charts",
            similar: "Ähnliches Album",
            noData: {
                profile: "Keine Tracklist für dieses Album verfügbar",
                credits: "Keine Erwähnungen für dieses Album verfügbar",
            },
            profileInformation: {
                head: "Detaillierte Informationen",
                type: {
                    head: "Art",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Kompilation",
                    karaoke: "Karaoke",
                    ring: "Zyklus",
                    other: "Anderes",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                label: "Label",
                release_date: "Ursprüngliches Erscheinungsdatum",
                performers: "Künstler",
                featurings: "Feature-Künstler",
                translations: "Übersetzung",
                reference: "Bewertung",
            },
            profileReleases: {
                head: "Veröffentlichungen",
                subtitle:
                    "Klicken Sie auf eine Veröffentlichungen, um die zugehörigen ISRCs anzuzeigen",
                description:
                    "Veröffentlichungsliste dieses Albums: Klicken Sie auf eine Veröffentlichung, um die zugehörigen ISRCs dieser Veröffentlichung anzeigen zu lassen. Die ausgewählte Veröffentlichungen ist blau markiert und die zugehörigen ISRCs sind gelb hervorgehoben.",
                ean: "Strichcode",
                id: "Id",
                reference: "Referenz",
                nb_cd: "CD-Nr.",
                nb_track: "Track-Nr.",
                release_date: "Erscheinungsdatum",
                commentary: "Kommentar",
                support: {
                    head: "Unterstützung",
                    ep: "EP",
                    num: "Digital",
                    dvd: "DVD",
                    lp: "LP",
                    "2cd": "2CD",
                    k7: "MC",
                    cd: "CD",
                },
                cline: "CLINE",
                pline: "PLINE",
                links: "Links",
                empty: "Keine Ergebnisse",
            },
            profileIsrcs: {
                head: "ISRCs",
                subtitle:
                    "Klicken Sie auf einen ISRC, um die zugehörigen Veröffentlichungen anzuzeigen",
                description:
                    "Liste der zum Album gehörenden Aufnahmen. Klicken Sie auf einen ISRC, um die zugehörigen Veröffentlichungen anzeigen zu lassen. Die ausgewählte ISRC ist blau hervorgehoben und die zugehörigen Veröffentlichungen sind gelb hervorgehoben.",
                isrc: "ISRC",
                id: "Id",
                title: "Titel",
                duration: "Länge",
                complementary: "Verwandter Inhalt",
                has_credits: "Erwähnungen",
                has_lyric: "Lyrics",
                has_audio: "Audiodeskription",
                links: "Links",
                empty: "Keine Ergebnisse",
            },
            profileTracklist: {
                head: "Tracklist",
                release_head: (id) =>
                    `Tracklist entsprechend der ausgewählten "${id}" Veröffentlichung`,
                isrc_head: (isrc) =>
                    `Tracks entsprechend des ausgewählten "${isrc}" ISRC`,
                id_release: "ID der Veröffentlichung",
                ean: "Strichcode",
                id_track: "Track-ID",
                no_cd: "Disc-Nummer",
                no_piste: "Track-Number",
                id_recording: "Aufnahme-ID",
                title: "Titel",
                subtitle: "Untertitel",
                isrc: "ISRC",
                duration: "Länge",
                hit: "Hit score",
                editorial_pick: "Redaktionell ausgewählte Partitur",
                empty: "Keine Ergebnisse",
            },
            universGenres: {
                head: "Genre",
                principal: "1. Kategorie",
                secondary: "2. Kategorie",
            },
            universLanguages: {
                head: "Sprachen",
                principal: "Hauptsprache",
                secondary: "Andere Sprache",
            },
            universInstruments: {
                head: "Instrumente",
            },
            universAudioDescription: {
                head: "Audiodeskriptionen",
                licence: "Deskriptionen mit Simbals Technology erstellt",
                moods: {
                    title: "Stimmungen des Albums",
                    subtitle: "Nach Wichtigkeit sortiert",
                    description: () => (
                        <>
                            Detaillierte Stimmungen der Aufnahme und ihre
                            entsprechenden Gewichtungen. <br />
                            <strong>Gesamtliste:</strong> glücklich, traurig,
                            ruhig, energisch, aufgeregt, erstaunt/erregt,
                            angespannt/alarmiert/ängstlich, wütend, verzweifelt,
                            erfreut, überzeugt, ungeduldig, frustriert,
                            unzufrieden, erfreut, interessiert, neutral,
                            misstrauisch, elend/enttäuscht/erschrocken ,
                            gelassen/zufrieden, selbstbewusst, besorgt,
                            unbehaglich, traurig/deprimiert/düster,
                            entspannt/zufrieden, nachdenklich, müde/schläfrig,
                            schlaff, gelangweilt/ängstlich
                        </>
                    ),
                },
                themes: {
                    title: "Dieses Album enthält Tracks mit folgenden Themen",
                    description:
                        "Detaillierte Themen der Aufnahme im Zusammenhang mit den Hörkontexten. Umfasst beispielsweise Folgendes: „Workout“, „Abendstimmung“, „Weihnachten“, „Party“, „Videospiele“, „Sommer“ usw.",
                },
                timbres: {
                    title: "Enthält Titel mit folgenden Klangfarben",
                    description:
                        "Tags, die zur Klangfarbe der Aufnahme passen.",
                },
                arousal: {
                    title: "Erregung",
                    description: () => (
                        <>
                            Erregung beschreibt das Wahrnehmungsmaß der Aufnahme
                            bezogen auf Intensität, Energie und Aktivität.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    üblicherweise mit energiegeladenden Tracks
                                    (Hard Rock, Metal).
                                </li>
                                <li>
                                    <strong>Niedrige Worte</strong> sind ein
                                    Zeichen für ruhige Tracks (langsam,
                                    Ballade).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Wertigkeit",
                    description: () => (
                        <>
                            Wertigkeit beschreibt ein Wahrnehmungsmaß für
                            Stimmungen, bezogen auf positive oder negative
                            Gefühle, z.B. Freude/Trauer.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit Tracks mit positiven Gefühlen.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich auf traurige Tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalität",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track Gesang
                            enthält oder nicht.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> deuten darauf
                                    hin, dass der Track wahrscheinlich keinen
                                    Gesang enthält (Instrumental).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong>{" "}
                                    korrespondieren wahrscheinlich mit Tracks
                                    mit Gesang (z. B. Gesungene Worte, Rap, Slam
                                    oder Sprache ).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Wortanteil",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track Sprache
                            enthält.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> beziehen sich
                                    darauf, ob gesprochene Worte vorhanden sind
                                    (Interview, Hörbuch, Gedicht, Kommentar).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deutet
                                    wahrscheinlich auf Musik hin, eventuell mit
                                    Gesang (Gesungene Lieder), aber ohne
                                    gesprochene Worte.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Akustik-Indikator",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob Lieder akustische
                            Anteile enthalten.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit akustischen Tracks
                                    (Akustik-Gitarre/Piano, Stimme, Klassische
                                    Musik).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deuten auf
                                    Lieder mit elektrischen Anteilen hin
                                    (Electronic, Distortion, AudioEffekte).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Tanzbarkeit",
                    description: () => (
                        <>
                            Tanzbarkeit steht für ein Lied, welche zum Tanzen
                            komponiert worden ist (Tempo- und
                            Rhythmus-Wahrnehmung, Stabilität und
                            Regelmäßigkeit).
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit sehr tanzbaren Liedern.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich auf Lieder ohne einen wahrnehmbaren
                                    Rhythmus.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Live-Anteil",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Lied live
                            gespielt worden ist.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondiert
                                    mit Live-Tracks.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deuten
                                    wahrscheinlich auf Studio-Aufnahmen hin.
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodizität",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track eine
                            deutlich hörbare Melodie enthält oder nicht.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit angenehmen, harmonischen Liedern, bei
                                    denen man mitsingen möchte.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich mehr auf Geräusche, Klangformen oder
                                    Lieder mit komplexen Harmonien oder Melodien
                                    (Klassik, Jazz, etc.).
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonanz",
                    description: () => (
                        <>
                            Diese Deskription zeigt den Grad der Dissonanz eines
                            Tracks an.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit Liedern ohne klare Harmonie, z. B. laute
                                    Klangfarbe, oder mit sehr komplexen
                                    Harmonien (dissonante Intervalle wie
                                    Tritonus, Sekunden, etc.).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong>{" "}
                                    korrespondieren mit Tracks mit einfachen
                                    Harmonien, klarer Melodie, konsonante
                                    Harmonien (z. B. einfache Piano-Melodien).
                                </li>
                            </ul>
                        </>
                    ),
                },
            },
            creditsCreditsData: {
                licence: "Mit freundlicher Genehmigung von Muso.Ai",
                musician: "Musiker",
                songwriter: "Songwriter",
                management: "Management",
                engineer: "Ingenieur",
                producers: "Produzenten",
                organization: "Organisation",
                other: "Anderes",
                visual: "Bildmaterial",
            },
        },
    },
    recording: {
        view: {
            breadcrumb: "Aufnahme-Übersicht",
            id: "Music-Story ID",
            title: "Titel",
            subtitle: "Untertitel",
            isrc: "ISRC",
            genre: "Genre",
            profile: "Profil",
            credits: "Erwähnungen",
            popularity: "Bekanntheit",
            charts: "Charts",
            lyrics: "Lyrics",
            audio_description: "Audiodeskription",
            similar: "Ähnliche Aufnahmen",
            work: "Werk",
            noData: {
                lyrics: "Keine Lyrics für diese Aufnahme verfügbar",
                credits: "Keine Erwähnung für diese Aufnahme verfügbar",
                charts: "Keine Charts für diese Aufnahme verfügbar",
                audio: "Keine Audiodeskription für diese Aufnahme verfügbar",
                popularity:
                    "Keine Bekanntheitsdaten für diese Aufnahme  verfügbar.",
                work: "Keine Werkdaten sind für diese Aufnahme verfügbar",
            },
            profileFeaturing: {
                head: "Feature-Künstler",
            },
            profileInstrument: {
                head: "Instrumente",
            },
            profileDuration: {
                head: "Länge",
            },
            profileIsrc: {
                head: "ISRC",
            },
            profileRelatedIsrc: {
                head: "Zugehörige ISRCs",
                has_credits: "Erwähnungen",
                has_lyric: "Lyrics",
                has_audio: "Audiodeskription",
            },
            profileTranslation: {
                head: "Übersetzte Titel",
            },
            profileAlbum: {
                head: "Alben",
                cover: "Cover",
                title: "Titel",
                artists: "Künstler",
                type: {
                    head: "Art",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Kompilation",
                    karaoke: "Karaoke",
                    ring: "Zyklus",
                    other: "Anderes",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                release_date: "Erscheinungsdatum",
                links: "Links",
                empty: "Keine Ergebnisse",
            },
            chartsRecordingCharts: {
                charts: {
                    head: "Charts",
                    title: "Titel",
                    empty: "Keine Ergebnisse",
                    search: "Suche...",
                },
                filter: {
                    format: {
                        label: "Art",
                    },
                    reference: {
                        label: "Offiziell",
                        description: "Offizielles Verkaufsranking des Landes",
                    },
                },
                trend: {
                    head: "Trend",
                    x: "Datum",
                    y: "Rang",
                    dynamicRank: "Leeren Rang entfernen",
                    dynamicDate: "Leeres Datum entfernen",
                },
            },
            lyricsInformation: {
                head: "Information",
                licence: {
                    title: "Mit freundlicher Genehmigung von LyricFind",
                    subtitle:
                        "Dieser Inhalt darf nicht ohne eine spezielle Lizenz von LyricFind verwendet werden",
                },
                lfid: "Lyricfind-ID",
                hfa_code: "HFA-Code",
                publisher_credit: "Verlagserwähnung",
                territories: "Gebiete",
                lyric: "Originaltext",
            },
            audioAudioDescription: {
                head: "Audiodeskription",
                licence: "Descriptionen mit Simbals technology erstellt",
                moods: {
                    title: "Stimmungen der Aufnahme",
                    subtitle: "Nach Wichtigkeit sortiert",
                    description: () => (
                        <>
                            Detaillierte Stimmungen der Aufnahme und ihre
                            entsprechenden Gewichtungen. <br />
                            <strong>Gesamtliste:</strong> glücklich, traurig,
                            ruhig, energisch, aufgeregt, erstaunt/erregt,
                            angespannt/alarmiert/ängstlich, wütend, verzweifelt,
                            erfreut, überzeugt, ungeduldig, frustriert,
                            unzufrieden, erfreut, interessiert, neutral,
                            misstrauisch, elend/enttäuscht/erschrocken ,
                            gelassen/zufrieden, selbstbewusst, besorgt,
                            unbehaglich, traurig/deprimiert/düster,
                            entspannt/zufrieden, nachdenklich, müde/schläfrig,
                            schlaff, gelangweilt/ängstlich
                        </>
                    ),
                },
                themes: {
                    title: "Themen",
                    description:
                        "Detaillierte Themen der Aufnahme im Zusammenhang mit den Hörkontexten. Umfasst beispielsweise Folgendes: „Workout“, „Abendstimmung“, „Weihnachten“, „Party“, „Videospiele“, „Sommer“ usw.",
                },
                timbres: {
                    title: "Klangfarbe",
                    description:
                        "Tags, die zur Klangfarbe der Aufnahme passen.",
                },
                arousal: {
                    title: "Erregung",
                    description: () => (
                        <>
                            Erregung beschreibt das Wahrnehmungsmaß der Aufnahme
                            bezogen auf Intensität, Energie und Aktivität.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    üblicherweise mit energiegeladenden Tracks
                                    (Hard Rock, Metal).
                                </li>
                                <li>
                                    <strong>Niedrige Worte</strong> sind ein
                                    Zeichen für ruhige Tracks (langsam,
                                    Ballade).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Wertigkeit",
                    description: () => (
                        <>
                            Wertigkeit beschreibt ein Wahrnehmungsmaß für
                            Stimmungen, bezogen auf positive oder negative
                            Gefühle, z.B. Freude/Trauer.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit Tracks mit positiven Gefühlen.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich auf traurige Tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalität",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track Gesang
                            enthält oder nicht.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> deuten darauf
                                    hin, dass der Track wahrscheinlich keinen
                                    Gesang enthält (Instrumental).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong>{" "}
                                    korrespondieren wahrscheinlich mit Tracks
                                    mit Gesang (z. B. Gesungene Worte, Rap, Slam
                                    oder Sprache).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Wortanteil",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track Sprache
                            enthält.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> beziehen sich
                                    darauf, ob gesprochene Worte vorhanden sind
                                    (Interview, Hörbuch, Gedicht, Kommentar).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deutet
                                    wahrscheinlich auf Musik hin, eventuell mit
                                    Gesang (Gesungene Lieder), aber ohne
                                    gesprochene Worte.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Akustik-Indikator",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob Lieder akustische
                            Anteile enthalten.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit akustischen Tracks
                                    (Akustik-Gitarre/Piano, Stimme, Klassische
                                    Musik).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deuten auf
                                    Lieder mit elektrischen Anteilen hin
                                    (Electronic, Distortion, AudioEffekte).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Tanzbarkeit",
                    description: () => (
                        <>
                            Tanzbarkeit steht für ein Lied, welche zum Tanzen
                            komponiert worden ist (Tempo- und
                            Rhythmus-Wahrnehmung, Stabilität und
                            Regelmäßigkeit).
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit sehr tanzbaren Liedern.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich auf Lieder ohne einen wahrnehmbaren
                                    Rhythmus.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Live-Anteil",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Lied live
                            gespielt worden ist.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondiert
                                    mit Live-Tracks.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> deuten
                                    wahrscheinlich auf Studio-Aufnahmen hin.
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodizität",
                    description: () => (
                        <>
                            Diese Deskription zeigt an, ob ein Track eine
                            deutlich hörbare Melodie enthält oder nicht.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit angenehmen, harmonischen Liedern, bei
                                    denen man mitsingen möchte.
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong> beziehen
                                    sich mehr auf Geräusche, Klangformen oder
                                    Lieder mit komplexen Harmonien oder Melodien
                                    (Klassik, Jazz, etc.).
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonanz",
                    description: () => (
                        <>
                            Diese Deskription zeigt den Grad der Dissonanz eines
                            Tracks an.
                            <ul>
                                <li>
                                    <strong>Hohe Werte</strong> korrespondieren
                                    mit Liedern ohne klare Harmonie, z. B. laute
                                    Klangfarbe, oder mit sehr komplexen
                                    Harmonien (dissonante Intervalle wie
                                    Tritonus, Sekunden, etc.).
                                </li>
                                <li>
                                    <strong>Niedrige Werte</strong>{" "}
                                    korrespondieren mit Tracks mit einfachen
                                    Harmonien, klarer Melodie, konsonante
                                    Harmonien (z. B. einfache Piano-Melodien).
                                </li>
                            </ul>
                        </>
                    ),
                },
                extra: {
                    rythm: "Rhythmus",
                    timbre: "Klangfarbe",
                    harmony: "Harmonie",
                    energy: "Energie",
                },
                articulation: {
                    title: "Artikulation",
                    description: "Artikulation",
                },
                rhythmic_stability: {
                    title: "Rhythmische Stabilität",
                    description: "Rhythmische Stabilität",
                },
                event_density: {
                    title: "Ereignisdichte",
                    description: "Ereignisdichte",
                },
                pulse_clarity: {
                    title: "Taktschärfe",
                    description: "Taktschärfe",
                },
                bpm: {
                    title: "BPM",
                    description: "BPM",
                },
                complexity: {
                    title: "Komplexität",
                    description: "Komplexität",
                },
                binary: {
                    title: "Binarität",
                    description: "Binarität",
                },
                roll_off: {
                    title: "Abrollen",
                    description: "Abrollen",
                },
                brightness: {
                    title: "Helligkeit",
                    description: "Helligkeit",
                },
                zero_cross_rate: {
                    title: "Zero cross rate",
                    description: "Zero cross rate",
                },
                mfcc: {
                    title: "MFCC",
                    description: "MFCC",
                },
                mfcc01: {
                    title: "Mfcc01",
                },
                mfcc02: {
                    title: "Mfcc02",
                },
                mfcc03: {
                    title: "Mfcc03",
                },
                mfcc04: {
                    title: "Mfcc04",
                },
                mfcc05: {
                    title: "Mfcc05",
                },
                mfcc06: {
                    title: "Mfcc06",
                },
                mfcc07: {
                    title: "Mfcc07",
                },
                mfcc08: {
                    title: "Mfcc08",
                },
                mfcc09: {
                    title: "Mfcc09",
                },
                mfcc10: {
                    title: "Mfcc10",
                },
                mfcc11: {
                    title: "Mfcc11",
                },
                mfcc12: {
                    title: "Mfcc12",
                },
                mfcc13: {
                    title: "Mfcc13",
                },
                chroma: {
                    title: "Chroma (Harmonie, Schlüssel)",
                    description: "Chroma (Harmonie, Schlüssel)",
                },
                chroma01: {
                    title: "Chroma01",
                },
                chroma02: {
                    title: "Chroma02",
                },
                chroma03: {
                    title: "Chroma03",
                },
                chroma04: {
                    title: "Chroma04",
                },
                chroma05: {
                    title: "Chroma05",
                },
                chroma06: {
                    title: "Chroma06",
                },
                chroma07: {
                    title: "Chroma07",
                },
                chroma08: {
                    title: "Chroma08",
                },
                chroma09: {
                    title: "Chroma09",
                },
                chroma10: {
                    title: "Chroma10",
                },
                chroma11: {
                    title: "Chroma11",
                },
                chroma12: {
                    title: "Chroma12",
                },
                complexity_chroma: {
                    title: "Chroma-Komplexität",
                    description: "Chroma-Komplexität",
                },
                intensity: {
                    title: "Intensität",
                    description: "Intensität",
                },
                loudness: {
                    title: "Lautstärke",
                    description: "Lautstärke",
                },
                absolute_loudness: {
                    title: "Lautstärke (absolut)",
                    description: "Lautstärke (absolut)",
                },
                loudness_range: {
                    title: "Lautstärke (Reichweite)",
                    description: "Lautstärke (Reichweite)",
                },
                centroid: {
                    title: "Schwerpunkt",
                    description: "Schwerpunkt",
                },
                spread: {
                    title: "Streuung",
                    description: "Streuung",
                },
                flatness: {
                    title: "Ebenheit",
                    description: "Ebenheit",
                },
            },
            creditsCreditsData: {
                licence: "Mit freundlicher Genehmigung von Muso.Ai",
                musician: "Musiker",
                songwriter: "Songwriter",
                management: "Management",
                engineer: "Ingenieur",
                producers: "Produzenten",
                organization: "Organisation",
                other: "Anderes",
                visual: "Bildmaterial",
            },
            popularityOverview: {
                head: "Überblick",
                global: {
                    title: "Weltweit",
                    weeks: "Wochen in Charts:",
                    description:
                        "Die Popularität eines Titels wird auf der Grundlage seiner Präsenz und Position in den von uns beobachteten Charts berechnet. Es gibt zwei Formen: Rang (die Position des Titels in unserer Rangliste der beliebten Titel) und Beliebtheitsgrad (in %, relativ zum höchsten Beliebtheitswert in unserer Rangliste).",
                },
                gold: {
                    title: "Katalog",
                    weeks: "Wochen in Katalog-Charts:",
                    description:
                        "Die Katalogpopularität einer Aufnahme basiert auf ihrer Präsenz in den offiziellen Charts, die länger als 18 Monate zurückliegen.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                },
                current: {
                    title: "Aktuell",
                    weeks: "Wochen in den aktuellen Charts:",
                    description:
                        "Die aktuelle Beliebtheit eines Titels basiert auf seiner Präsenz in den Charts der letzten 18 Monate.",
                },
                type: {
                    label: "Art:",
                    gold: "Katalog",
                    current: "Aktuell",
                },
                peak: "Spitzenwert:",
            },
            popularityHistogram: {
                head: "Popularität über die Zeit",
                tabs: {
                    gold: {
                        title: "Katalog",
                        description:
                            "Die Katalog-Popularität eines Künstlers basiert auf seiner Präsenz in den Charts vor mehr als 18 Monaten.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                        header: "Die Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                    },
                    current: {
                        title: "Aktuell",
                        description:
                            "Die aktuelle Popularität eines Künstlers basiert auf seiner Präsenz in den Charts in den vergangenen 18 Monaten.",
                    },
                },
                gold: "Katalog-Popularität",
                current: "Aktuelle Popularität",
                popover: {
                    noData: "Keine Daten",
                    popularity: "Popularität der Aufnahme:",
                    bestRank: "Beste Platzierung:",
                    bestCountry: "Bestes Land:",
                    charts: (n) => `Basierend auf ${n} Charts`,
                },
            },
            popularityMap: {
                head: "Popularität nach Land",
                tabs: {
                    gold: {
                        title: "Katalog",
                        description:
                            "Die Katalog-Popularität eines Künstlers basiert auf seiner Präsenz in den Charts vor mehr als 18 Monaten.\nDie Katalog-Popularität basiert ausschließlich auf den Daten der offiziellen US, UK, FR und DE Charts. Weitere Länder werden integriert.",
                    },
                    current: {
                        title: "Aktuell",
                        description:
                            "Die aktuelle Popularität eines Künstlers basiert auf seiner Präsenz in den Charts in den vergangenen 18 Monaten.",
                    },
                },
                popover: {
                    noData: "Keine Daten",
                    popularity: "Popularität der Aufnahme:",
                    bestRank: "Beste Platzierung:",
                    bestMonth: "Monat (Beste Platzierung):",
                    weeksInCharts: "Wochen in den Charts:",
                    charts: (n) => `Basierend auf ${n} Charts`,
                },
            },
            workData: {
                iswc: "ISWC",
                hfa: "HFA-Code",
                publisher_credit: "Verlagserwähnung (Lyricfind)",
                share: {
                    head: "Anteile US (MLC)",
                    party: "Partei",
                    ipi: "IPI",
                    role: "Rolle",
                    parent: "Mutter Partei",
                    parent_ipi: "Mutter IPI",
                    share: "Anteil",
                },
            },
        },
    },
    genre: {
        breadcrumb: "Genres",
        genre: {
            head: "Genres",
            parent: "Übergeordneter Name",
            name: "Name",
        },
        related: {
            head: "Zugehörig",
            name: "Name",
            weight: "Art",
        },
        artist: {
            head: "Top-Künstler",
            position: "Nr.",
            name: "Name",
            complement: "Zusatz",
            actions: "Aktionen",
            country: "Land",
            type: "Art",
            role: "Rolle",
        },
        button: "Genreverwaltung",
        search: "Suche...",
        empty: "Keine Ergebnisse",
        manage: {
            breadcrumb: "Verwaltung",
            genre: {
                head: "Genre",
                empty: "Keine Ergebnisse",
                id: "Id",
                name: "Name",
                description: "Beschreibung",
                related: "RelatedZugehörig",
                genre: "Genre",
                type: "Art",
                genre_fr: "Genre FR",
                genre_en: "Genre EN",
                other_translations: "Andere Übersetzungen",
                parent_genre: "Übergeordnetes Genre",
                parent_genre_placeholder:
                    "Um ein übergeordnetes Genre zu erstellen, nichts auswählen",
                created_at: "Erstellt am",
                updated_at: "Aktualisiert am",
                action: "Aktion",
                search: "Suche...",
            },
            create: {
                title: "Genre erstellen",
                success: "Genre erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieses Genres: ${message}`,
            },
            update: {
                title: "Genre aktualisieren",
                success: "Genre aktualisiert",
                error: (message) =>
                    `Fehler beim Aktualisieren dieses Genres: ${message}`,
            },
            delete: {
                title: "Genre löschen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie dieses Genre löschen?",
                },
                success: "Genre gelöscht",
                error: (message) =>
                    `Fehler beim Löschen dieses Genres: ${message}`,
            },
        },
    },
    country: {
        breadcrumb: "Land",
        head: "Land",
        button: "Länderverwaltung",
        code: "Code",
        country_fr: "Land FR",
        country_en: "Land EN",
        country_ja: "Land JA",
        country_de: "Land DE",
        country_ko: "Land KO",
        search: "Suche...",
        empty: "Kein Land zum Anzeigen",
        manage: {
            breadcrumb: "Verwaltung",
            country: {
                head: "Land",
                empty: "Keine Ergebnisse",
                id: "Id",
                code: "Code",
                country_fr: "Land FR",
                country_en: "Land EN",
                other_translations: "Andere Übersetzungen",
                created_at: "Erstellt am",
                updated_at: "Aktualisiert am",
                action: "Aktion",
                search: "Suche...",
            },
            create: {
                title: "Land erstellen",
                success: "Land erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieses Landes: ${message}`,
            },
            update: {
                title: "Land aktualisieren",
                success: "Land aktualisiert",
                error: (message) =>
                    `Fehler beim Aktualisieren dieses Landes: ${message}`,
            },
            delete: {
                title: "Land löschen",
                alert: {
                    title: "Sind Sie sicher",
                    text: "Möchten Sie dieses Land löschen?",
                },
                success: "Land gelöscht",
                error: (message) =>
                    `Fehler beim Löschen dieses Landes: ${message}`,
            },
        },
    },
    misc_entity: {
        breadcrumb: "Show",
        head: "Show",
        button: "Sonstiges-Verwaltung",
        search: "Suche...",
        empty: "Keine Show anzeigen",
        id: "Id",
        name: "Name",
        type: "Art",
        description: "Beschreibung",
        date: "Datum",
        management: {
            breadcrumb: "Verwaltung",
            profile: "Profil",
            curation: "Kuratierung",
            getty: "Getty",
            main: "Hauptinformationen",
            misc_entity: {
                id: "ID",
                name: "Name",
                type: "Art",
                description: "Beschreibung",
                date: "Datum",
                created_at: "Erstellt am",
                updated_at: "Aktualisiert am",
                action: "Aktion",
                search: "Suche...",
            },
            imageCuration: {
                head: "Galerie",
                empty: "Keine Bilder in der Galerie",
                width: "Breite",
                height: "Höhe",
                created_at: "Erstelldatum",
                copyright: "Copyright",
                status: "Status",
                edit: "Bearbeiten",
                edit_image: "Bildinformationen bearbeiten",
                delete: "Löschen",
                delete_image: "Bild löschen",
            },
            imageGetty: {
                gallery: {
                    head: "Galerie",
                    empty: "Keine Bilder in der Galerie",
                    width: "Breite",
                    height: "Höhe",
                    created_at: "Erstelldatum",
                    copyright: "Copyright",
                    status: "Status",
                    edit: "Bearbeiten",
                    edit_image: "Bildinformationen bearbeiten",
                    delete: "Löschen",
                    delete_image: "Bild löschen",
                },
            },
            create: {
                title: "Erstelle Sonstiges-Eintrag",
                success: "Sonstiges-Eintrag erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieses Sonstiges-Eintrags: ${message}`,
            },
            update: {
                title: "Sonstiges-Eintrag aktualisieren",
            },
            delete: {
                title: "Sonstiges-Eintrag löschen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Sonstiges-Eintrag löschen?",
                },
                success: "Misc deleted",
                error: (message) =>
                    `Fehler beim Löschen dieses Sonstiges-Eintrags: ${message}`,
            },
        },
    },
    role: {
        breadcrumb: "Rolle",
        head: "Rolle",
        button: "Rollenverwaltung",
        role_fr: "Rolle FR",
        role_en: "Rolle EN",
        role_ja: "Rolle JA",
        role_de: "Rolle DE",
        role_ko: "Rolle KO",
        search: "Suche...",
        empty: "Keine Rollen zum Anzeigen",
        manage: {
            breadcrumb: "Verwaltung",
            role: {
                head: "Rolle",
                empty: "Keine Ergebnisse",
                id: "Id",
                role_fr: "Rolle FR",
                role_en: "Rolle EN",
                other_translations: "Andere Übersetzungen",
                created_at: "Erstellt am",
                updated_at: "Aktualisiert am",
                action: "Aktion",
                search: "Suche...",
            },
            create: {
                title: "Rolle erstellen",
                success: "Rolle erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieser Rolle: ${message}`,
            },
            update: {
                title: "Rolle aktualisiert",
                success: "Rolle aktualisiert",
                error: (message) =>
                    `Fehler beim Aktualisieren dieser Rolle: ${message}`,
            },
            delete: {
                title: "Rolle löschen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diese Rolle löschen?",
                },
                success: "Rolle gelöscht",
                error: (message) =>
                    `Fehler beim Löschen dieser Rolle: ${message}`,
            },
        },
    },
    plannings: {
        breadcrumb: "Planungen",
        manage: {
            breadcrumb: "Planungsverwaltung",
            planning: {
                empty: "Keine Ergebnisse",
                building: "Aufbau",
                progress: "Im Gange",
                done: "Archiviert",
                title: "Titel",
                startDate: "Anfangsdatum",
                endDate: "Enddatum",
                stats: "Fortschritt",
                itemsCount: "Ergebnis(se)",
            },
            create: {
                title: "Planungseintrag erstellen",
            },
            update: {
                title: "Planungseintrag bearbeiten",
            },
        },
        view: {
            breadcrumb: "Planungseintrag ansehen",
            partner: {
                all: "Überblick",
            },
            action: {
                validate: {
                    title: "Planungseintrag validieren",
                    swal: {
                        title: "Sind Sie sicher?",
                        notCompleted:
                            "Planungseintrag ist nicht vollständig abgeschlossen",
                        text: "Möchten Sie diesen Planungseintrag abschließen?",
                    },
                },
                deleted: {
                    title: "Planungseintrag löschen",
                    swal: {
                        title: "Sind Sie sicher?",
                        tasksExist: "Planungseintrag ist nicht leer.",
                        text: "Möchten Sie diesen Planungseintrag löschen?",
                    },
                },
            },
            tasks: {
                title: "Aufgaben",
                empty: "Keine Ergebnisse",
                manager: "Manager",
                status: "Status",
                artist: "Künstler",
                album: "Album",
                track: "Track",
                category: "Kategorie",
                assignement: "Zuordnung",
                classification: "Klassifizierung",
                tasks: "Aufgaben",
                reason: "Begründung",
                information: "Information",
                nbRelated: "Zahl zugehöriger",
                albumsWithoutGenre: "Alben ohne Genre",
                hasBioFR: "Hat FR-Bio",
                hasBioEN: "Hat EN-Bio",
                hasBioDE: "Hat DE-Bio",
                hasBioJA: "Hat JA-Bio",
                hasBioES: "Hat ES-Bio",
                hasBioPT: "Hat PT-Bio",
                hasReview: "Hat Überprüfung",
                hasPicture: "Hat Foto",
                hasCuration: "Hat Kuratierung",
                lastUpdated: "Letzter Planungseintrag",
                genre: "Genre",
                language: "Sprache",
                country: "Land",
                action: {
                    topLink: "Top-Zugehörigkeit",
                    lyricsSampleLink: "Zuordnung Lyrics",
                    discoLink: "Diskografie",
                    create: {
                        title: "Aufgabe erstellen",
                    },
                    update: {
                        title: "Aufgabe aktualisieren",
                    },
                    delete: {
                        title: "Aufgabe löschen",
                        alert: {
                            title: "Sind Sie sicher?",
                            text: "Möchten Sie diese Aufgabe löschen?",
                        },
                    },
                },
                subtasks: {
                    change_status: "Status ändern:",
                    confirm_change_status: {
                        TODO: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Zu erledigen“ ändern?",
                        },
                        COMPLETED: {
                            title: "Sind Sie sicher",
                            text: "Sie möchten den Status dieser Aufgabe in „Vollständig“ ändern?",
                        },
                        TO_BE_CORRECTED: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Zu korrigieren“ ändern?",
                            comment: {
                                title: "Kommentar hinzufügen",
                                error: "Kommentar ist notwendig",
                            },
                        },
                        VERIFIED_AND_VALIDATED: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Verifiziert und validiert“ ändern?",
                        },
                        VALIDATED_WITHOUT_CONTROL: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Validiert ohne Überprüfung“ ändern?",
                        },
                        ERROR: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Fehlerhaft“ ändern?",
                            comment: {
                                title: "Kommentar hinzufügen",
                            },
                        },
                    },
                    status: {
                        UNASSIGNED: "Nicht zugeordnet",
                        TODO: "Zu erledigen",
                        COMPLETED: "Vollständig",
                        TO_BE_CORRECTED: "Zu korrigieren",
                        VALIDATED_WITHOUT_CONTROL: "Validiert ohne Überprüfung",
                        VERIFIED_AND_VALIDATED: "Verifiziert und validiert",
                        ERROR: "Fehlerhaft",
                    },
                },
            },
            stats: {
                title: "Statistiken",
                manager: "Manager",
            },
            progress: {
                title: "Fortschritt",
                username: "Benutzername",
            },
            budget: {
                title: "Budget",
                username: "Benutzername",
                task_type: "Aufgabentyp",
            },
            history: {
                title: "Verlauf anzeigen",
                head: "Verlauf",
                empty: "Keine Ergebnisse",
                search: "Künstler suchen",
                action: {
                    created_by: "Erstellt von",
                    deleted_by: "Gelöscht von",
                    modified_by: "Geändert von",
                    release_matched_by: "Album gematched von",
                    release_unmatched_by: "Album-Matching aufgehoben von",
                    track_matched_by: "Track gematched von",
                    track_unmatched_by: "Track-Matching aufgehoben von",
                    status_changed_by: (new_status) =>
                        `Markiert als ${new_status} von`,
                    status_comment: (comment) => `Kommentar: ${comment}`,
                },
            },
        },
        tasks: {
            planning: "Planung",
            artist: "Künstler",
            nbRelated: "Zahl zugehöriger",
            hasBioFR: "Hat FR-Bio",
            hasBioEN: "Hat EN-Bio",
            hasBioDE: "Hat DE-Bio",
            hasBioJA: "Hat JA-Bio",
            hasBioES: "Hat ES-Bio",
            hasBioPT: "Hat PT-Bio",
            hasPicture: "Hat Foto",
            hasCuration: "Hat Kuratierung",
            album: "Album",
            track: "Track",
            category: "Kategorie",
            classification: "Klassifizierung",
            assignement: "Zuordnung",
            reason: "Begründung",
            error: {
                required: "Darf nicht leer bleiben",
                albumTrackRequired:
                    "Album- oder Track-Feld darf nicht leer bleiben",
            },
            subtasks: {
                title: "Aufgabe(n)",
                typePlaceholder: "Aufgabe...",
                assignementPlaceholder: "Betroffen sind...",
                new: "Aufgabe hinzufügen",
                remove: "Aufgabe entfernen",
                typeGroups: {
                    ART: "Künstler",
                    ART_C: "Künstler (Korrektur)",
                    BIO_FR: "Französische Biografie",
                    BIO_EN: "Englische Biografie",
                    BIO_DE: "Deutsche Biografie",
                    BIO_PT: "Portugiesische Biografie",
                    BIO_JP: "Japanische Biografie",
                    BIO_FR_C: "Französische Biografie (Korrektur)",
                    BIO_EN_C: "Englische Biografie (Korrektur)",
                    BIO_DE_C: "Deutsche Biografie (Korrektur)",
                    BIO_JP_C: "Japanische Biografie (Korrektur)",
                    RVW: "Überprüfung",
                    RVW_C: "Überprüfung (Korrektur)",
                    APL_AS: "Lyrics (Beurteilung)",
                    APL_CAS: "Lyrics (zweite Meinung)",
                },
                error: {
                    oneTypePerGroup: (category) =>
                        `Es sollte höchstens eine Aufgabe in der ${category} Kategorie enthalten sein`,
                },
            },
        },
        production: {
            breadcrumb: "Landing Page",
            industry: "Marktstatistiken",
            ms: "Datenbank-Statistiken",
            my_planning: "Meine Planungen",
            tasks: {
                artist: "Künstler",
                album: "Album",
                track: "Track",
                genre: "Genre",
                country: "Land",
                category: "Kategorie-Grund",
                classification: "Klassifizierung",
                tasks: "Aufgaben",
                related_tasks: "Related Tasks",
                subtasks: {
                    change_status: "Status ändern:",
                    match_required: "Album- oder Track-Matching erforderlich",
                    confirm_change_status: {
                        COMPLETED: {
                            title: "Sind Sie sicher",
                            text: "Sie möchten den Status dieser Aufgabe in „Vollständig“ ändern?",
                        },
                        ERROR: {
                            title: "Sind Sie sicher?",
                            text: "Sie möchten den Status dieser Aufgabe in „Fehlerhaft“ ändern?",
                            comment: {
                                title: "Kommentar hinzufügen",
                            },
                        },
                    },
                    status: {
                        TODO: "Zu erledigen",
                        COMPLETED: "Vollständig",
                        TO_BE_CORRECTED: "Zu korrigieren",
                        VALIDATED_WITHOUT_CONTROL: "Validiert ohne Überprüfung",
                        VERIFIED_AND_VALIDATED: "Verifiziert und validiert",
                        ERROR: "Fehlerhaft",
                    },
                },
            },
        },
        client: {
            title: "Music Story Produktionsplanung",
            sub_title: ({ title, start_date, end_date }) =>
                `from ${start_date} to ${end_date} ${
                    title ? `(${title})` : ""
                }`,
            status: ({ status }) => {
                const translatedStatus = {
                    BUILDING: "Status: Aufbau",
                    PROGRESS: "Status: Im Gange",
                    DONE: "Status: Archiviert",
                };
                return translatedStatus[status];
            },
            tasks: {
                artist: "Künstler",
                album: "Album",
                track: "Track",
                category: "Kategorie",
                status_artist: "Künstler-Profil",
                status_bio_fr: "FR-Bio",
                status_bio_en: "EN-Bio",
                status_bio_de: "DE-Bio",
                status_bio_ja: "JA-Bio",
                status_review: "Überprüfung",
                status_picture: "Bild",
                status_curation: "Kuratierung",
                status: {
                    creation: "Erstellung",
                    update: "Aktualisierung",
                    new: "Neu",
                    progress: "Wird erstellt",
                    exist: "Existiert bereits",
                    missing: "Fehlt",
                },
            },
        },
        picture: {
            breadcrumb: "Bildarbeiten",
            planning: {
                head: "Planungen",
                title: "Titel",
                empty: "Keine Ergebnisse",
                status: "Status",
                building: "Aufbau",
                progress: "Im Gange",
                done: "Archiviert",
                startDate: "Anfangsdatum",
                endDate: "Enddatum",
            },
            tasks: {
                title: "Aufgaben",
                empty: "Keine Ergebnisse",
                artist: "Künstler",
                album: "Album",
                track: "Track",
                latest_added_date: "Zuletzt hinzugefügt (Datum)",
                status: "Status",
                information: "Informations",
                label: "Label",
                getty: "Getty",
                not_found: "Nicht gefunden",
                comment: "Kommentar",
                last_label_created_at: "Label-Bild zuletzt hinzugefügt (Datum)",
                last_getty_created_at: "Getty-Bild zuletzt hinzugefügt (Datum)",
                max_getty_date: "Aktuelles Datum Getty-Bilder",
                has_disco: "Diskografie",
                last_check_date: "Datum der letzten Verifizierung",
                itemsCount: (count) => {
                    return `${count} Result${count > 1 ? "s" : ""}`;
                },
            },
        },
        billing: {
            breadcrumb: "Abrechnung",
            empty: "Abrechnung nicht verfügbar",
            month: {
                period: ({ start_date, end_date }) =>
                    `Period from ${start_date} to ${end_date}`,
            },
            subtasks: {
                artist: "Künstler",
                album: "Album",
                track: "Track",
                validated_at: "Datum der Validierung",
            },
        },
        statistic: {
            breadcrumb: "Statistiken",
            tracking_period: "Tracking pro Periode",
            chart_tracking: "Chart-Tracking",
            ressources_tracking: "Quellen-Tracking",
            planning_annual: "Jahresplanung",
            charts_annual_tracking: "Jahresplanung-Tracking",
            resources_annual_tracking: "Jahres-Quellen-Tracking",
        },
    },
    apple: {
        sample: {
            assessment: {
                informations: {
                    head: "Informations",
                    catalogType: "Katalogtyp",
                    month: "Monat",
                    artist: "Künstler",
                    album: "Album",
                    title: "Titel",
                    duration: "Länge",
                    isrc: "ISRC",
                    genre: "Genre",
                    country: "Land",
                    language: "Sprache",
                    status: {
                        TODO: "Zu erledigen",
                        COMPLETED: "Vollständig",
                        TO_BE_CORRECTED: "Zu korrigieren",
                        VALIDATED_WITHOUT_CONTROL: "Validiert ohne Überprüfung",
                        VERIFIED_AND_VALIDATED: "Verifiziert und validiert",
                        ERROR: "Fehlerhaft",
                    },
                    planning: "Planung",
                    manager: "Manager",
                },
                lyric: {
                    head: "Lyric",
                    empty: "Keine Ergebnisse",
                    nbLine: "Zeile",
                    timing: "Timing",
                    text: "Text",
                    rating: {
                        filter: "Bewertung für diese Zeile anschauen",
                        criteria: "Beurteilte Kriterien",
                        errorType: "Fehlertyp",
                        comment: "Kommentar",
                        create: {
                            title: "Bewertung hinzufügen",
                            success: "Bewertung hinzugefügt",
                            error: (message) =>
                                `Fehler beim Hinzufügen dieser Bewertung: ${message}`,
                        },
                    },
                },
                rating: {
                    reset: "Alle Beurteilungen ansehen",
                    line: (line_number) => `Zeile: ${line_number}`,
                    criteria: "Beurteilte Kriterien:",
                    errorType: "Fehlertyp:",
                    comment: "Kommentar:",
                    delete: {
                        tooltip: "Bewertung löschen",
                        alert: {
                            title: "Sind Sie sicher?",
                            text: "Möchten Sie diese Bewertung löschen?",
                        },
                        success: "Bewertung gelöscht",
                    },
                },
                player: {
                    action: {
                        previous: "Zurück zum Anfang (X)",
                        back10: "10 Sekunden zurück (C)",
                        pause: "Pausieren (SPACE)",
                        play: "Starten (SPACE)",
                    },
                    login: "Login",
                    logout: "Logout",
                    playerError: {
                        unavailable: "Kein Player verfügbar",
                        restricted: "Gesperrter Inhalt",
                    },
                },
                ratingType: {
                    assessment: "Beurteilung",
                    counterAssessment: "Zweite Meinung",
                },
            },
            management: {
                sample: {
                    head: "Sample",
                    empty: "Kein Ergebnis",
                    date: "Datum",
                },
                catalog: "Katalog",
                stats: "Statistiken",
                progress: "Fortschritt",
                lyrics: {
                    head: "Lyrics",
                    empty: "Keine Ergebnisse",
                    catalog_type: "Katalog-Typ",
                    provider: "Anbieter",
                    user: "Nutzer",
                    artist: "Künstlet",
                    album: "Album",
                    title: "Titel",
                    isrc: "ISRC",
                    genre: "Genre",
                    language: "Sprache",
                    country: "Land",
                    assessment: "Beurteilung",
                    counter_assessment: "Zweite Meinung",
                    filters: {
                        planning: "Planung",
                        assignement: "Betroffen sind",
                        nb_line: "Reihen-Anzahl",
                        assessment: {
                            yes: "Mit Beurteilung",
                            no: "Ohne Beurteilung",
                        },
                        counter_assessment: {
                            yes: "Mit zweiter Meinung",
                            no: "Ohne zweite Meinung",
                        },
                    },
                    action: {
                        addToPlanning: {
                            title: "Zur Planung hinzufügen",
                            exist: "Bereits in die Planung aufgenommen",
                        },
                    },
                },
            },
            report: {
                sample: {
                    head: "Sample",
                    empty: "Keine Ergebnisse",
                    date: "Datum",
                },
                scorecard_provider: "Anbieter-Wertungsliste",
                scorecard_apple_user: "Apple-Nutzer-Wertungsliste",
                ranking_language_genre: "Rangliste Sprache/Genre",
                explorer: "Explorer",
                error: "Fehlerdetails",
            },
        },
    },
    cnm: {
        repositories: {
            repositories: {
                title: "Repositories",
                empty: "Keine Ergebnisse",
            },
            years: {
                title: "Jahre",
                empty: "Keine Ergebnisse",
            },
            upload: {
                button: {
                    title: "Hochladen",
                },
                modal: {
                    title: "Repository hochladen",
                    file: "Datei",
                    file_placeholder: {
                        csv: "CSV-Datei auswählen",
                        json: "JSON-Datei auswählen",
                    },
                    browse: "Durchsuchen",
                    type: "Art",
                    year: "Jahr",
                    submit: "Hochladen",
                    submitting: "Hochladen",
                },
                conflict: {
                    usedIn: ({ name }) => `${name} wird benutzt in `,
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                },
                success: "Repository hochgeladen",
            },
            csa_artists: {
                title: "CSA-Künstler",
                upload_help:
                    "Die CSV-Datei sollte eine einzelne Spalte mit den Namen der Künstler und keine Kopfzeile enthalten.",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                name: "Name",
                conflict:
                    "Die folgenden CSA-Künstler werden in der obersten Ebene verwendet, sind jedoch nicht in der hochgeladenen Datei vorhanden:",
            },
            cnm_artists_titles: {
                title: "CNM-Künstler/Titel",
                artists: {
                    head: "Künstler",
                    search: "Suche...",
                    id: "ID",
                    name: "Name",
                    msArtists: "MS-Künstler",
                    noArtist: "Titel ohne Künstler",
                    edit: {
                        tooltip: "Künstler bearbeiten",
                        title: "Künstler bearbeiten",
                        msArtistsPlaceholder: "Künstler auswählen",
                        submit: "Bearbeiten",
                        success: "Künstler geändert",
                    },
                    delete: {
                        tooltip: "Künstler löschen",
                        title: "Künstler löschen",
                        text: "Möchten Sie den Künstler wirklich löschen?",
                        submit: "Löschen",
                        success: "Künstler gelöscht",
                    },
                },
                titles: {
                    head: ({ noArtist, artistName }) =>
                        noArtist
                            ? "Titel ohne Künstler"
                            : artistName
                            ? `Titel von ${artistName}`
                            : "Titel",
                    search: "Suche...",
                    id: "ID",
                    artist: "Künstler",
                    title: "Titel",
                    msRecordings: "MS Recordings",
                    artistPlaceholder: "Künstler auswählen",
                    msRecordingsPlaceholder: "Aufnahme wählen",
                    edit: {
                        tooltip: "Titel bearbeiten",
                        title: "Titel bearbeiten",
                        submit: "Bearbeiten",
                        success: "Titel geändert",
                    },
                    delete: {
                        tooltip: "Titel löschen",
                        title: "Titel löschen",
                        text: "Möchten Sie den Titel wirklich löschen?",
                        matchings:
                            "Titel ist auf den folgenden Plattform-Charts gematched und kann nicht gelöscht werden.",
                        position: (positions) => `Platzierung(en) ${positions}`,
                        submit: "Löschen",
                        success: "Titel gelöscht",
                    },
                },
            },
            cnm_genres: {
                title: "CNM-Genres",
                upload_help:
                    "Die CSV-Datei sollte eine einzelne Spalte mit den Namen der Genres und keine Kopfzeile enthalten.",
                genres: {
                    title: "CNM-Genres",
                    empty: "Keine Ergebnisse",
                    search: "Suche...",
                    cnmGenre: "CNM-Genre",
                    msGenres: "MS-Genres",
                },
                matchings: {
                    title: "Matchings",
                    search: "Suche...",
                    empty: "Keine Ergebnisse",
                    filter: {
                        msMainGenre: "MS-Hauptgenre",
                        cnmGenre: "CNM-Genre",
                        notMatched: "Nicht gematched",
                    },
                    msGenre: "MS-Genre",
                    cnmGenre: "CNM-Genre",
                    cnmGenrePlaceholder: "CNM-Genre auswählen",
                    matchSelection: {
                        button: "Match-Auswahl",
                        title: "MS-Genres (ausgewählte Matches)",
                        genrePlaceholder: "CNM-Genre auswählen",
                        modifiedMatchings:
                            "Vorsicht: Folgende Matchings werden ersetzt:",
                        submit: "Match",
                    },
                    copyMatchings: {
                        button: "Matchings kopieren",
                        title: "Matchings aus dem Repository eines anderen Jahres kopieren",
                        genrePlaceholder: "Jahr auswählen",
                        deletedGenres: (yearSrc) =>
                            `Gelöschte Genres aus ${yearSrc}:`,
                        newGenres: (yearDst) => `Neue Genres in ${yearDst}:`,
                        modifiedMatchings: {
                            title: ({ yearSrc, yearDst }) =>
                                `Achtung: Folgende MS-Genres haben unterschiedliche Matchings in den Repositorys ${yearSrc} und ${yearDst}. Matching aus ${yearDst} werden behalten:`,
                            item: ({
                                yearSrc,
                                yearDst,
                                msName,
                                dstCNMName,
                                srcCNMName,
                            }) =>
                                `${msName} (MS) – ${srcCNMName} in ${yearSrc} und ${dstCNMName} in ${yearDst} (CNM)`,
                        },
                        submit: "Kopieren",
                        success: "Matchings kopiert",
                    },
                },
                matchingSuccess: "Matching(s) geändert",
            },
            cnm_labels: {
                title: "CNM-Labels",
                upload_help:
                    "Die CSV-Datei sollte zwei Spalten enthalten, die Label-Namen und -Typ enthalten, und keine Kopfzeile.",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                name: "Name",
                type: "Art",
                add: {
                    button: "Hinzufügen",
                    tooltip: "Label hinzufügen",
                    success: "Label hinzugefügt",
                },
                edit: {
                    tooltip: "Label bearbeiten",
                    success: "Label geändert",
                },
                delete: {
                    tooltip: "Label löschen",
                    title: "Label löschen",
                    text: "Möchten Sie wirklich dieses Label löschen?",
                    matchings:
                        "Label wird in den folgenden Titeln der oberen Ebene benutzt:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "Löschen",
                    success: "Label entfernt",
                },
                createOrUpdate: {
                    create: {
                        title: "Label hinzufügen",
                        submit: "Hinzufügen",
                    },
                    update: {
                        title: "Label bearbeiten",
                        submit: "Bearbeiten",
                    },
                    cnmName: "Original-CNM-Name",
                    name: "Name",
                    type: {
                        label: "Typ",
                        placeholder: "Typ auswählen",
                        major: "Major",
                        indie: "Indie",
                    },
                },
                conflict:
                    "Folgende CNM-Labels werden auf der oberen Ebene verwendet, sind jedoch nicht in der hochgeladenen Datei vorhanden:",
            },
            cnm_distributors: {
                title: "CNM Distributors",
                upload_help:
                    "Die CSV-Datei sollte zwei Spalten enthalten, die Händler-Namen und -Typ enthalten, und keine Kopfzeile.",
                empty: "Keine Eergebnisse",
                search: "Suche...",
                name: "Name",
                type: "Art",
                add: {
                    button: "Hinzufügen",
                    tooltip: "Händler hinzufügen",
                    success: "Händler hinzugefügt",
                },
                edit: {
                    tooltip: "Händler bearbeiten",
                    success: "Händler geändert",
                },
                delete: {
                    tooltip: "Händler löschen",
                    title: "Händler löschen",
                    text: "Möchten Sie diesen Händler wirklich löschen?",
                    matchings:
                        "Händler wird in den folgenden Titeln der oberen Ebene benutzt:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "Löschen",
                    success: "Händler entfernt",
                },
                createOrUpdate: {
                    create: {
                        title: "Händler hinzufügen",
                        submit: "Hinzufügen",
                    },
                    update: {
                        title: "Händler bearbeiten",
                        submit: "Bearbeiten",
                    },
                    cnmName: "Original-CNM-Name",
                    name: "Name",
                    type: {
                        label: "Art",
                        typePlaceholder: "Art auswählen",
                        distributor: "Händler",
                        topDistributor: "Top-Händler",
                    },
                },
                conflict:
                    "Die unten aufgeführten CNM-Händler werden in der oberen Ebene verwendet, sind jedoch in der hochgeladenen Datei nicht vorhanden:",
            },
            cypok_languages: {
                title: "Cypok-Sprachen",
                upload_help:
                    "Datei sollte das durch Zeilenumbrüche getrennte JSON-Format verwenden",
                empty: "Keine Ergebnisse",
                artist: "Künstler",
                title_column: "Titel",
                languages: "Sprachen",
                search: "Suche...",
            },
            platforms: {
                title: "Plattformen",
                empty: "Keine Plattformen",
                name: "Name",
                add: {
                    button: "Hinzufügen",
                    tooltip: "Plattform",
                    success: "Plattform hinzugefügt",
                },
                edit: {
                    tooltip: "Plattform bearbeiten",
                    success: "Plattform geändert",
                },
                enable: {
                    tooltip: "Plattform aktivieren",
                    success: "Plattform aktiviert",
                },
                disable: {
                    tooltip: "Plattform deaktivieren",
                    success: "Plattform deaktiviert",
                },
                createOrUpdate: {
                    create: {
                        title: "Plattform hinzufügen",
                        submit: "Hinzufügen",
                    },
                    update: {
                        title: "Plattform bearbeiten",
                        submit: "Bearbeiten",
                    },
                    name: "Name",
                },
            },
        },
        aggregation: {
            yearSelect: {
                type: {
                    TEST: "Test",
                    REAL: "Real",
                },
            },
            cnmTitleList: {
                head: "CNM-Titel",
                position: "Rang",
                artist: "Künstler",
                title: "Titel",
                platforms: "Plattformen",
                isrcs: "ISRC",
                duplicate: "Duplikate",
                mixed: "Gemischt",
                streams: "Streams",
                check: "Titel als geprüft markieren",
                uncheck: "Titel als nicht-geprüft markieren",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                filters: {
                    missingPlatforms: "Fehlende Plattform",
                    numberOfMatchedPlatforms: "Anzahl der Plattformen",
                    status: {
                        placeholder: "Status",
                        none: "Keine",
                        checked: "Geprüft",
                        discarded: "Verworfen",
                    },
                },
                discardButton: {
                    discard: "Titel verwerfen",
                    recover: "Titel reaktivieren",
                    commentTitle: "Grund: ",
                    head: "Titel verwerfen",
                    comment: "Grund",
                    submit: "Verwerfen",
                },
            },
            search: {
                head: "Ähnliche CNM-Titel",
                sameIsrc: "Titel haben gemeinsamen ISRC",
                artist: "Künstler",
                title: "Titel",
                platforms: "Plattformen",
                streams: "Streams",
                empty: "Keine Ergebnisse",
                form: {
                    search: "Suche...",
                },
            },
            platformTitleList: {
                head: "Plattform-Titel",
                position: "Rang",
                artist: "Künstler",
                title: "Titel",
                subtitle: "Untertitel",
                isrcs: "ISRC",
                duration: "Länge",
                streams: "Streams",
                empty: "Keine Ergebnisse",
                move: "Titel bewegen",
            },
            createButton: {
                head: "CNM-Titel aus Auswahl erstellen",
                artist: "Künstler",
                title: "Titel",
                success: "Erstellter Titel",
            },
        },
        charts: {
            platform: {
                nav: "Charts - Plattform",
                year: {
                    head: "Jahr",
                    empty: "Keine Ergebnisse",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                pf: {
                    head: "Plattform",
                    empty: "Keine Ergebnisse",
                    name: "Name",
                    show_disabled: "Deaktivierte Plattformen anzeigen",
                },
                upload: {
                    title: "Chart-Upload",
                    file: "Detai",
                    file_placeholder: "CSV-Datei auswählen",
                    browse: "Durchsuchen",
                    year: "Jahr",
                    type: {
                        label: "Art",
                        TEST: "Test",
                        REAL: "Real",
                    },
                    submit: "Hochladen",
                    submitting: "Hochladen",
                    success: "Chart hochgeladen",
                    confirm: {
                        head: "Möchten Sie wirklich diese obere Ebene hochladen? Folgende Felder werden verändert:",
                        difference: ({ track, modifiedFields }) =>
                            `${track.artist_display} – ${track.title} (ID ${
                                track.track_id
                            }, rank ${track.position}): ${modifiedFields.join(
                                ", "
                            )}`,
                    },
                },
                matching: {
                    head: "Matching",
                    empty: "Keine Ergebnisse",
                    search: "Suche...",
                    position: "Nr.",
                    artist: "Künstler",
                    title: "Titel",
                    isrc: "ISRC",
                    cnm_artist: "CNM-Künstler",
                    cnm_title: "CNM-Titel",
                    streams: "Streams",
                    filters: {
                        charts: {
                            placeholder: "Charts",
                            initial: "Initialen",
                            complement: "Ergänzung",
                        },
                    },
                },
            },
            deduplicated: {
                nav: "Charts - Dedupliziert",
                year: {
                    head: "Jahr",
                    empty: "Keine Ergebnisse",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                pf: {
                    head: "Plattform",
                    empty: "Keine Ergebnisse",
                    name: "Name",
                    show_disabled: "Deaktivierte Plattformen anzeigen",
                },
                chart: {
                    head: "Chart",
                    empty: "Keine Ergebnisse",
                    search: "Suche...",
                    position: "Nr.",
                    artist: "Künstler",
                    title: "Titel",
                    isrc: "ISRC",
                    streams: "Streams",
                },
            },
            aggregate: {
                nav: "Charts - Gesamt",
                year: {
                    head: "Jahr",
                    empty: "Keine Ergebnisse",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                chart: {
                    head: "Chart",
                    empty: "Keine Ergebnisse",
                    search: "Suche...",
                    rank: "Rang",
                    artist: "Künstler",
                    title: "Titel",
                    isrcs: "ISRC(s)",
                    platforms: "Plattformen",
                    streams: "Streams",
                    export: {
                        head: "Exportieren",
                        requestForComplement: "Anfrage um Ergänzung",
                        requestForConsolidationData:
                            "Anfrage um Datenkonsolidierung",
                        cypok: "Cypok",
                    },
                },
            },
        },
        report: {
            types: {
                title: "Arten",
                empty: "Keine Arten",
            },
            years: {
                title: "Jahre",
                empty: "Keine Jahre",
                type: {
                    TEST: "test",
                    REAL: "real",
                },
            },
            progress: {
                title: "Fortschritt",
                aggregate: "Top ()",
                upload: "Hochladen",
                matching: "Matching",
                building: "Aufbau der Top 1.000",
                coverage: "Reichweite Top (Gesamt)",
                consolidation: "Konsolidierung der Top 1.000",
                logs: {
                    title: "Protokolle",
                    uploadChart: "Hochgeladene Top",
                    uploadCypok: "Hochgeladene Cypok-Daten",
                    requestForComplement: "Exportierte Anfrage um Ergänzung",
                    requestForCypok: "Exportierte Anfrage nach Cypok-Daten",
                },
                lock: {
                    title: "Indikatoren speren",
                    disabled:
                        "Die Indikatoren können nicht aus der Testcharge gesperrt werden.",
                    success: "Indikatoren gesperrt",
                },
                unlock: {
                    title: "Indikatoren entsperren",
                    disabled:
                        "Die Indikatoren können nicht aus der Testcharge entsperrt werden.",
                    success: "Indikatoren entsperrt",
                },
            },
            consolidate: {
                yearSelect: {
                    type: {
                        TEST: "Test",
                        REAL: "Real",
                    },
                },
                chart: {
                    head: "Chart",
                    search: "Suche...",
                    empty: "Keine Ergebnisse",
                    rank: "Nr.",
                    artist: "Künstler",
                    title: "Titel",
                    platformSelect: {
                        aggregate: "Gesamt",
                        global: "Weltweit",
                    },
                    filter: {
                        firstLetter: "Anfangsbuchstabe",
                        status: {
                            placeholder: "Status",
                            none: "Keine",
                            validated: "Validiert",
                            discarded: "Verworfen",
                            reported: "Gemeldet",
                        },
                    },
                },
                edit: {
                    head: "Titel",
                    header: {
                        platforms: "Plattformen",
                        openInAggregation:
                            "Öffnen Sie diesen Titel in der Aggregationsschnittstelle",
                        artist: "Künstler",
                        artist_warning:
                            "Diese Informationen werden bei allen Titeln des Künstlers geändert.",
                        title: "Titel",
                        vocals: "Gesang",
                        problems: "Probleme",
                    },
                    title: {
                        label: "Titel",
                    },
                    msArtists: {
                        label: "MS-Künstler",
                    },
                    isrcs: {
                        label: "ISRCs",
                        placeholder: "ISRCs auswählen",
                        reference: "ISRCs (MS / Plattformen)",
                        select: "ISRC auswählen",
                        unselect: "ISRC-Auswahl aufheben",
                        selectAll: "Alle ISRCs auswählen",
                    },
                    genre: {
                        label: "Genre",
                        placeholder: "Genre auswählen",
                        reference: "Genres (MS / Plattformen)",
                        select: "Genre auswählen",
                        unselect: "Genre-Auswahl aufheben",
                    },
                    leadGender: {
                        label: "Geschlecht führender Kopf (künstlerische Einheit)",
                        placeholder: "Geschlecht auswählen",
                        reference: "Geschlecht führender Kopf (MS)",
                        select: "Geschlecht auswählen",
                        unselect: "Geschlechter-Auswahl aufheben",
                        F: "Frau",
                        M: "Mann",
                        MIXED: "Divers",
                    },
                    countries: {
                        label: "Nationalität",
                        placeholder: "Wählen Sie die Länder des Künstlers",
                    },
                    voice: {
                        label: "Geschlecht führender Kopf (Stimme)",
                        placeholder: "Stimme auswählen",
                        F: "Frau",
                        M: "Mann",
                        MIXED: "Divers",
                        INSTRUMENTAL: "Instrumental",
                    },
                    publicationDate: {
                        label: "Veröffentlichungsdatum",
                        reference: "Veröffentlichungsdatum (MS / Plattformen)",
                        select: "Datum auswählen",
                        unselect: "Datum-Auswahl aufheben",
                    },
                    ageType: {
                        label: "Art",
                        new: "Neu",
                        recurring: "Wiederkehrend",
                        gold: "Gold",
                        warning: ({ from, to, date }) =>
                            `Art hat sich verändert von ${from} zu ${to} am ${date}`,
                    },
                    label: {
                        label: "Label",
                        placeholder: "Label auswählen",
                        create: "Label erstellen",
                        reference: "Label (MS / Plattformen)",
                        copy: "Label kopieren",
                    },
                    distributor: {
                        label: "Händler",
                        placeholder: "Händler auswählen",
                        create: "Händler erstellen",
                        reference: "Händler (Plattformen)",
                        copy: "Händler kopieren",
                    },
                    cnmArtist: {
                        label: "CNM-Künstler",
                        placeholder: "CNM-Künstler auswählen",
                        name: "Name",
                        create: {
                            title: "Künstler erstellen",
                            success: "Künstler erstellt",
                        },
                        update: {
                            title: "Künstler bearbeiten",
                            success: "Künstler geändert",
                        },
                    },
                    csaArtist: {
                        label: "CSA-Künstler",
                        placeholder: "CSA-Künstler auswählen",
                    },
                    levelOfDevelopment: {
                        label: "Entwicklungsstand",
                        confirmed: "Bestätigt",
                        new: "Neues Talent",
                    },
                    language: {
                        label: "Sprache",
                        placeholder: "Sprache auswählen",
                        lyricfindReference: "Sprache (Lyricfind)",
                        select: "Sprache auswählen",
                        unselect: "Sprachauswahl aufheben",
                        FRENCH: "Französisch",
                        INTERNATIONAL: "International",
                        INSTRUMENTAL: "Instrumental",
                    },
                    platformTitles: {
                        platform: "Plattform",
                        artist: "Künstler",
                        title: "Titel",
                        isrcs: "ISRC",
                        duration: "Länge",
                        streams: "Streams",
                        empty: "Keine Ergebnisse",
                    },
                    validate: "Title validieren",
                    discard: {
                        head: "Titel verwerfen",
                        shouldBeFalse:
                            "Ein verworfener Titel kann nicht validiert werden.",
                    },
                    report: {
                        head: "Titel melden",
                        shouldBeFalse:
                            "Ein gemeldeter Titel kann nicht validiert werden.",
                    },
                    discardReason: {
                        head: "Grund",
                        placeholder: "Grund auswählen",
                        required:
                            "Titel kann nicht ohne Grund verworfen werden.",
                    },
                    reportReason: "Grund",
                    success: "Titel geändert",
                    error: "Beim Speichern der Information ist ein Fehler aufgetreten.",
                },
            },
            indicators: {
                title: "Indikatoren",
                top_titles: "Top-Titel",
                top_artists: "Top-Künstler",
                indicators: "Indikatoren",
            },
        },
    },
    charts: {
        breadcrumb: "Charts",
        display: {
            breadcrumb: "Anzeige",
        },
        translation: {
            breadcrumb: "Übersetzung",
        },
    },
    partner_tops: {
        partners: {
            title: "Partner",
            empty: "Keine Partner",
        },
        dates: {
            title: "Daten",
            empty: "Keine Daten",
        },
        tops: {
            title: "Tops",
            empty: "Keine Tops",
        },
        artists: {
            title: "Künstler",
            empty: "Keine Künstler",
            rank: "Rang",
            name: "Name",
            matchings: "Matching",
            editMatchings: "Matchings bearbeiten",
            status: "Status",
            biographies: "Biografien",
            biographies_fr: "Französisch",
            biographies_en: "Englisch",
            pictures: "Bilder",
            discarded: ({ date, user, comment }) =>
                `Verworfen am ${date} von ${user} - "${comment}"`,
            filters: {
                max_rank: {
                    title: "Rang",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "Gematched",
                    no: "Nicht gematched",
                },
                discarded: {
                    title: "Verworfen",
                    yes: "Verworfen",
                    no: "Nicht verworfen",
                },
                bio_en: {
                    title: "EN-Biografie",
                    yes: "With EN-Biografie",
                    no: "Without EN-Biografie",
                },
                bio_fr: {
                    title: "FR-Biografie",
                    yes: "With FR-Biografie",
                    no: "Without FR-Biografie",
                },
                pictures: {
                    title: "Bilder",
                    valid: "Gültige Bilderpictures",
                    improve: "Zu verbessernde Bilder",
                    missing: "Fehlende Bilder",
                },
            },
            matching: {
                create: {
                    success: "Matching erstellt",
                    duplicate: "Matching besteht bereits",
                    error: (message) =>
                        `Fehler beim Erstellen des Matchings: ${message}`,
                },
                update: {
                    success: "Matching bearbeitet",
                    not_found:
                        "Fehler beim Bearbeiten des Matchings: Matching existiert nicht mehr",
                    duplicate:
                        "Fehler beim Bearbeiten des Matchings: Matching besteht bereits",
                    error: (message) =>
                        `Fehler beim Bearbeiten des Matchings: ${message}`,
                },
                delete: {
                    success: "Matching gelöscht",
                    not_found:
                        "Fehler beim Löschen des Matchings: Matching existiert nicht mehr",
                    error: (message) =>
                        `Fehler beim Löschen des Matchings: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Verwerfen",
                },
                modal: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Künstler verwerfen?",
                    placeholder: "Kommentar",
                },
                success: "Künstler verworfen",
                duplicate:
                    "Fehler beim Verwerfen des Künstlers: Künstler bereits verworfen",
                error: (message) =>
                    `Fehler beim Verwerfen des Künstlers: ${message}`,
            },
            recover: {
                button: {
                    title: "Wiederherstellen",
                },
                modal: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Künstler wiederherstellen?",
                },
                success: "Künstler wiederhergestellt",
                not_found:
                    "Fehler beim Wiederherstellen des Künstlers: Künstler war nicht verworfen",
                error: (message) =>
                    `Fehler beim Wiederherstellen des Künstlers: ${message}`,
            },
        },
        artistsV3: {
            title: "Künstler",
            empty: "Keine Künstler",
            rank: "Rang",
            artist: "Künstler",
            ms_artist: "MS-Künstler",
            discography_score: "Alben gemachted",
            biography_en: "EN-Bio",
            biography_fr: "FR-Bio",
            curation: "Kuratierung",
            pictures: "Bilder",
            discarded_on: (date) => `Verworfen am ${date}`,
            filters: {
                max_rank: {
                    title: "Rang",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "Mit Match",
                    no: "Ohne Match",
                },
                discarded: {
                    title: "Verworfen",
                    yes: "Verworfen",
                    no: "Nicht verworfen",
                },
                bio_en: {
                    title: "EN-Biografie",
                    yes: "Mit EN-Biografie",
                    no: "Ohne EN-Biografie",
                },
                bio_fr: {
                    title: "FR-Biografie",
                    yes: "Mit FR-Biografie",
                    no: "Ohne FR-Biografie",
                },
                curation: {
                    title: "Kuratierung",
                    yes: "Mit Kuratierung",
                    no: "Ohne Kuratierung",
                },
                pictures: {
                    title: "Bilder",
                    yes: "Mit Bildern",
                    no: "Ohne Bilder",
                },
            },
            deezer: {
                market: "Markt",
                musicbrainz: "MusicBrainz",
                facebook: "Facebook",
                filters: {
                    market: {
                        title: "Markt",
                    },
                },
                pictures: {
                    head: "Deezer",
                    backstage: "Backstage",
                    other: "Anderes",
                    none: "Keine",
                },
            },
            napster: {
                country: "Land",
                filters: {
                    country: {
                        title: "Land",
                    },
                },
            },
            matching: {
                create: {
                    success: "Matching erstellt",
                    duplicate: "Matching besteht bereits",
                    error: (message) =>
                        `Fehler beim Erstellen des Matchings: ${message}`,
                },
                update: {
                    success: "Matching bearbeitet",
                    not_found:
                        "Fehler beim Bearbeiten des Matchings: Matching existiert nicht mehr",
                    duplicate:
                        "Fehler beim Bearbeiten des Matchings: Matching besteht bereits",
                    error: (message) =>
                        `Fehler beim Bearbeiten des Matchings: ${message}`,
                },
                delete: {
                    success: "Matching gelöscht",
                    not_found:
                        "Fehler beim Löschen des Matchings: Matching existiert nicht mehr",
                    error: (message) =>
                        `Fehler beim Löschen des Matchings: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Verwerfen",
                },
                modal: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Künstler verwerfen?",
                    placeholder: "Kommentar",
                },
                success: "Künstler verworfen",
                duplicate:
                    "Fehler beim Verwerfen des Künstlers: Künstler bereits verworfen",
                error: (message) =>
                    `Fehler beim Verwerfen des Künstlers: ${message}`,
            },
            recover: {
                button: {
                    title: "Wiederherstellen",
                },
                modal: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie diesen Künstler wiederherstellen?",
                },
                success: "Künstler wiederhergestellt",
                not_found:
                    "Fehler beim Wiederherstellen des Künstlers: Künstler war nicht verworfen",
                error: (message) =>
                    `Fehler beim Wiederherstellen des Künstlers: ${message}`,
            },
        },
        pictures: {
            none: "Keine",
            getty: "Getty",
            curation: "Kuratierung",
            label: "Label",
            backstage: "Deezer - Backstage",
            other: "Deezer - Anderes",
            last_label_creation: "Zuletzt hinzugefügtes Label-Bild (Datum)",
            last_getty_creation: "Zuletzt hinzugefügtes Getty-Bild (Datum)",
            last_getty_date:
                "Datum, an dem das letzte Getty-Bild aufgenommen wurde",
            last_check_date: "Datum der letzten Überprüfung",
        },
    },
    pictures: {
        breadcrumb: "Bilder",
        label: {
            gallery: {
                breadcrumb: "Verfügbar für Kunden",
                empty: "Keine Ergebnisse",
                head: (label) => `Label-Bild: ${label}`,
                search: "Künstler suchen",
                artist: "Künstler",
                action: "Aktion",
                expired: "Abgelaufenes Bild",
                takedownLabel: "Datum der Deaktivierung",
                add: "Bild hinzufügen",
            },
            status: {
                breadcrumb: "Ausstehend / Tracking",
                empty: "Keine Ergebnisse",
                head: (label) => `Label-Bild: ${label}`,
                search: "Künstler suchen",
                picture: "Bild",
                artist: "Künstler",
                status: "Status",
                filterStatusLabel: "Tracking zeigen",
                historic: "Historisch",
                statusChange: "Statuswechsel",
                createdAt: "Abgabetermin",
                formatedStatus: (status) => status.replaceAll("_", " "),
                definition: {
                    AWAITING:
                        "Das Bild wurde berücksichtigt und wird in Kürze bearbeitet",
                    AWAITING_FOR_PROCESSING:
                        "Das Bild wurde angenommen und wartet auf Dokumentierung",
                    DOCUMENTED:
                        "Das Bild hat den Validierungsprozess durchlaufen und ist nun verfügbar",
                    REJECTED:
                        "Das Bild entspricht nicht den Music Story-Kriterien und kann daher nicht validiert werden",
                },
            },
            validation: {
                breadcrumb: "Zu verarbeitende Bilder",
                empty: "Keine Ergebnisse",
                validationHead: "Validierung",
                processingHead: "In Bearbeitung",
                search: "Künstler oder Label suchen",
                picture: "Bild",
                artist: "Künstler",
                label: "Label",
                title: "Titel",
                copyright: "Copyright",
                date_takedown: "Datum der Deaktivierung",
                action: "Aktion",
                validate: {
                    title: "Validieren",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie dieses Bild validieren?",
                    },
                    success: "Bild validiert",
                    error: (message) =>
                        `Fehler beim Validieren diese Bildes: ${message}`,
                },
                reject: {
                    title: "Ablehnen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie dieses Bild ablehnen?",
                        commentTitle: "Kommentar hinzufügen",
                    },
                    success: "Bild abgelehnt",
                    error: (message) =>
                        `Fehler beim Ablehnen dieses Bildes: ${message}`,
                },
            },
            rejected: {
                breadcrumb: "Abgelehnte Bilder",
                empty: "Keine Ergebnisse",
                head: "Abgelehnte Bilder",
                search: "Künstler oder Label suchen",
                picture: "Bilder",
                artist: "Künstler",
                label: "Label",
                title: "Titel",
                copyright: "Copyright",
                date_takedown: "Datum der Deaktivierung",
                comment: "Kommentar",
                action: "Aktion",
                retrieve: {
                    title: "Abrufen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie dieses Bild abrufen?",
                    },
                    success: "Bild abgerufen",
                    error: (message) =>
                        `Fehler beim Abrufen dieses Bildes: ${message}`,
                },
                edit: {
                    title: "Kommentar aktualisieren",
                    success: "Kommentar aktualisiert",
                    error: (message) =>
                        `Fehler beim Aktualisieren dieses Kommentars: ${message}`,
                },
            },
        },
    },
    labelPromo: {
        breadcrumb: "Label",
        manage: {
            breadcrumb: "Verwaltung",
            label: {
                head: "Label",
                empty: "Keine Ergebnisse",
                id: "Id",
                name: "Name",
                created_at: "Erstellt am",
                updated_at: "Aktualisiert am",
                status: "Status",
                action: "Aktion",
                search: "Suche...",
            },
            show: {
                title: "Nutzer-Überblick",
                empty: "Keine zugeordneten Nutzer",
            },
            create: {
                title: "Label erstellen",
                success: "Label erstellt",
                error: (message) =>
                    `Fehler beim Erstellen dieses Labels: ${message}`,
            },
            update: {
                title: "Label aktualisieren",
                success: "Label aktualisiert",
                error: (message) =>
                    `Fehler beim Aktualisieren dieses Labels: ${message}`,
            },
            delete: {
                title: "Label löschen",
                alert: {
                    title: "Sind Sie sicher?",
                    text: "Möchten Sie dieses Label löschen?",
                },
                success: "Label gelöscht",
                error: ({ message, artists, users }) => {
                    return (
                        <>
                            <span>
                                {`Fehler beim Löschen dieses Labels: ${message}`}
                            </span>
                            <br />
                            {artists && (
                                <ul>
                                    {artists.map(
                                        ({ id_artist, name_artist }) => {
                                            return (
                                                <li key={id_artist}>
                                                    <a
                                                        href={`/en/artist/${id_artist}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {name_artist}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            )}
                            {users && (
                                <ul>
                                    {users.map(({ id, username }) => {
                                        return <li key={id}>{username}</li>;
                                    })}
                                </ul>
                            )}
                        </>
                    );
                },
            },
        },
    },
    artistTitle: {
        breadcrumb: "Künstler-Titel",
        searchForm: {
            artist: "Künstler",
            title: "Titel",
            search: "Suche",
        },
        resultsList: {
            count: (count) => `${count} Ergebnisse`,
            isrc: (isrc) => (isrc ? `ISRC : ${isrc}` : "kein ISRC"),
            recordingId: (id) => `Aufnahme-ID : ${id}`,
            artist: {
                head: "Künstler",
                picture: "Künstlerbild",
                id: (id) => `Künstler-ID: ${id}`,
            },
            album: {
                head: "Album",
                picture: "Albumcover",
                id: (id) => `Album-ID: ${id}`,
            },
        },
    },
    recommendation: {
        form: {
            positive: "Das Feld muss positiv sein",
            min: "Das Feld darf nicht kleiner als 0 sein",
            max: "Das Feld darf nicht größer als 100 sein",
            select_options: {
                low: "Niedrig (0-0.3333)",
                medium: "Mittel (0.3333-0.6666)",
                high: "Hoch (0.6666-1)",
            },
            select_options_bpm: {
                low: "Niedrig (0-76)",
                medium: "Mittel (76-160)",
                high: "Hoch (160+)",
            },
        },
        breadcrumb: "Erweiterte Empfehlung",
        results: "Ergebnisse",
        empty: "Keine Ergebnisse",
        search: "Suche...",
        ms_track_ids: "MS Track-IDs",
        ms_track_id: "MS Track-ID",
        ms_artist_ids: "MS Künstler-IDs",
        min_accuracy: "Minimale Genauigkeit",
        fa_bpm: "Bpm",
        fa_arousal: "Erregung",
        fa_moods: "Stimmungen",
        fa_valence: "Valenz",
        fa_intensity: "Intensität",
        year_range: "Jahresbereich",
        fa_descriptor: "Deskriptoren",
        name: "Name",
        title: "Titel",
        subtitle: "Untertitel",
        genre: "Genre",
        original_album: "Album",
        original_release_date: "Veröffentlichungsdatum",
        artists: "Künstler",
        mbid: "Mbid",
        match: "Übereinstimmung",
        popularity: "Beliebtheit",
    },
    data_external: {
        breadcrumb: "Externe Daten",
        internationals_charts: {
            breadcrumb: "Internationale Charts",
            charts: {
                nav: "Charts",
                empty: "Keine Ergebnisse",
                id: "Id",
                name: "Name",
                positions: "Anzahl der Platzierungen",
                treatments: "Reihe von Anwendungen",
                search: "Suche...",
            },
            stats: {
                nav: "Statistik",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                title: "Titel",
                country: "Land",
                start_date: "Datum",
                treatment: "Gematched",
                matched_simple: "Einfach",
                creation: "Erstellung",
                nb_discarded: "Verworfen",
                nb_forgot: "Vergessen",
            },
            coverage: {
                nav: "Abdeckungsinformationen",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                simple: "Einfache Matches",
                creation: "Erstellübersicht",
                discarded: "Verworfen - Geprüft, noch nicht verarbeitet",
                forgot: "Verworfen - Vergessne",
                title: "Titel",
                country: "Land",
                area: "Region",
                date: "Datum",
                rank: "Rang",
                artist: "Künstler",
                album_track: "Album/Track",
                ms_artist: "MS-Künstlert",
                comment: "Kommentar",
                user: "Nutzer",
            },
        },
        deezer_top_songs: {
            breadcrumb: "Deezer - Top-Songs",
            stats: {
                nav: "Statistiken",
                empty: "Keine Ergebnisse",
                date: "Datum",
                country: "Land",
                treatment: "Behandlung",
                nb_match_simple_ms: "Einfache MS",
                nb_match_creation_ms: "MS-Erstellung",
                nb_discard_ms: "MS verwerfen",
                nb_match_lf: "Match LF",
                nb_request_lf: "LF-Anfrage",
                nb_forgot_lf: "LF vergessen",
                nb_match_lf_cas1: "Match Cas 1",
                nb_match_lf_cas23_with_request: "Anfrage Cas 2/3",
                nb_match_lf_cas23_without_request: "Keine Anfrage Cas 2/3",
                nb_forgot_ms_lf: "MS + LF vergessen",
            },
            coverage: {
                nav: "Abdeckungsinformationen",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                simple: "Einfache Matches",
                creation: "Erstellübersicht",
                new_lyrics: "Lyrics-Matches",
                discarded_full: "Verworfen",
                forgot_full: "Vollständig vergessen",
                forgot_lf: "LF vergessen",
                forgot_2_3: "2/3 vergessen",
                date: "Datum",
                country: "Land",
                id_deezer: "Deezer-ID",
                artist: "Künstler",
                album: "Album",
                song: "Song",
                ms_artist: "MS-Künstler",
                ms_album: "MS-Album",
                lf_composition: "Komposition",
                comment: "Kommentar",
                user: "Nutzer",
            },
        },
        universal_artist: {
            breadcrumb: "Universal",
            stats: {
                nav: "Statistiken",
                empty: "Keine Ergebnisse",
                date: "Datum",
                total: "Weltweit",
                matched_total: "Wöchentlich",
                matched_simple: "Einfach",
                matched_creation: "Erstellung",
                discarded: "Verworfen",
            },
            coverage: {
                nav: "Abdeckungsinformationen",
                empty: "Keine Ergebnisse",
                search: "Suche...",
                simple: "Einfach",
                creation: "Erstellung",
                discarded: "Verworfen",
                id: "Id",
                name: "Name",
                ms_artist: "MS-Künstler",
                comment: "Kommentar",
                user: "Nutzer",
            },
        },
    },
    api: {
        breadcrumb: "API",
        monitoring: {
            breadcrumb: "Überwachung",
            token: "API-Token",
            status: {
                head: "Status",
                remaining: "Verbleibend",
                limit: "Limit",
                retry: "Erneut versuchen nach",
                retry_description:
                    "Das Rücksetzungsdatum wird nur angezeigt, wenn Sie 0 verbleibende Abfragen haben. Es gibt das Datum an, an dem Sie neue Abfragen machen können.",
                status: "Status",
            },
            historic: {
                head: "Historisch",
                date: "Datum",
                requests: "Anfragen",
            },
        },
    },
    moodmedia: {
        breadcrumb: "Moodmedia",
        search: {
            breadcrumb: "Suche",
            results: "Ergebnisse",
            empty: "Keine Ergebnisse",
            title: "Titel",
            artist: "Künstler",
            id: "Id",
            original_release_date: "Erscheinungsdatum",
            ms_album_title: "Albumtitel",
            name: "Name",
            subtitle: "Untertitel",
            artists: "Künstler",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularität",
            link: "WS2",
        },
        recommendation: {
            form: {
                error: "MS- und MM-Felder können nicht gleichzeitig eingegeben werden",
                positive: "Feld muss positiv sein",
                min: "Das Feld darf nicht kleiner als 0 sein",
                max: "Das Feld darf nicht größer als 100 sein",
            },
            breadcrumb: "Empfehlung",
            results: "Ergebnisse",
            empty: "Keine Ergebnisse",
            search: "Suche...",
            ms_track_ids: "MS-Track-Ids",
            ms_track_id: "MS-Track-Id",
            ms_artist_ids: "MS-Künstler-Ids",
            mm_track_id: "MM-Track-Id",
            filter_no: "Kein Filter",
            catalog: "Katalog",
            min_accuracy: "Minimale Genauigkeit",
            name: "Name",
            title: "Titel",
            subtitle: "Untertitel",
            genre: "Genre",
            original_album: "Album",
            original_release_date: "Erscheinungsdatum",
            artists: "Künstler",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularität",
        },
    },
    snep: {
        file: {
            breadcrumb: "Verwaltung der SNEP-Dateien",
            fileList: {
                head: "Dateien",
                search: "ID / Art / Daten",
                empty: "Keine Dateien",
                id: "ID",
                name: "Charttyp",
                period: "Periode",
                date: "Datum",
                imported: "Importiert",
                exportedSnep: "Exportierte SNEP",
                exportedYadeck: "Exportierte Yadeck",
                upload: {
                    button: "Hinzufügen",
                    head: "SNEP-Datei hinzufügen",
                    file: "Klicken Sie, um eine Datei auf Ihrem Computer auszuwählen",
                    success: "Datei hinzugefügt",
                },
                import: {
                    head: "Importieren einer SNEP-Datei",
                    name: "Datei",
                    startDate: "Start",
                    endDate: "Ende",
                    chart: {
                        label: "Chart",
                        placeholder: "Chart auswählen",
                        album: "Album",
                        track: "Track",
                    },
                    success: "Datei importiert",
                },
                export: {
                    pending: "Dateiexport...",
                    success: "Datei exportiert",
                },
                clean: {
                    title: "Exporte löschen",
                    success: "Exporte gelöscht",
                },
                delete: {
                    title: "Datei löschen",
                    success: "Datei gelöscht",
                },
            },
        },
    },
    matching: {
        tops: {
            breadcrumb: "Matching",
        },
        artist: {
            partner: {
                head: "Partner",
                name: "Name",
            },
            list: {
                head: "Auflistungen",
                empty: "Keine Auflistungen",
                date: "#",
                name: "Name",
                matched: "Matched",
                completed: "Abgeschlossen",
            },
            artist: {
                head: "Künstler",
                empty: "Keine Künstler",
                id: "#",
                name: "Name",
                firstname: "Vorname",
                lastname: "Nachname",
                complementary: "Ergänzung",
                discography: "Diskografie",
                spotify: "Spotify",
                musicbrainz: "MusicBrainz",
                max_created_at: "Datum",
                complementary: "Ergänzung",
                type: "Art",
                getty_picture: "Getty-Bilder",
                picture: (nb) => `${nb} picture${nb > 1 ? "s" : ""}`,
                artist: "Music-Story-Künstler",
                action: "Aktion",
                checked: "Geprüft",
                edit_matchings: "Matchings bearbeiten",
                match: {
                    success: "Matching erstellt",
                    error: (message) =>
                        `Fehlber beim Erstellen des Matchings: ${message}`,
                },
                unmatch: {
                    success: "Matching gelöscht",
                    error: (message) =>
                        `Fehler beim Löschen des Matchings: ${message}`,
                },
                mark: {
                    button: {
                        title: "Als „Übersprungen“ markieren",
                    },
                    modal: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie diesen Künstler überspringen?",
                        placeholder: "Kommentar",
                    },
                    success: "Künstler übersprungen",
                    error: (message) =>
                        `Fehler beim Überspringen des Künstlers: ${message}`,
                },
                unmark: {
                    button: {
                        title: "Als „Nicht übersprungen“ markieren",
                    },
                    success: "Künstler wiederhergestellt",
                    error: (message) =>
                        `Fehler beim Wiederherstellen des Künstlers: ${message}`,
                },
                check: {
                    button: {
                        title: "Als „Geprüft“ markieren",
                    },
                    success: "Künstler geprüft",
                    error: (message) =>
                        `Fehler beim Überprüfen des Künstlers: ${message}`,
                },
                artistCreated: {
                    button: {
                        title: "Als „Künstler erstellt“ markieren",
                    },
                    success: "Künstler als erstellt markiert",
                    error: (message) =>
                        `Fehler beim Esrtellen des Künstlers: ${message}`,
                },
                unartistCreated: {
                    button: {
                        title: "Als „Artist nicht erstellt“ markieren",
                    },
                    success: "Künstler als nicht erstellt markiert ",
                    error: (message) =>
                        `Fehler beim Entfernen der Markierung des Künstlers: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Nicht gematched",
                    },
                    completed: {
                        placeholder: "Abgeschlossen",
                        true: "Abgeschlossen",
                        false: "Nicht abgeschlossen",
                    },
                    checked: {
                        placeholder: "Geprüft",
                        true: "Geprüft",
                        false: "Nicht geprüft",
                    },
                    skiped: {
                        placeholder: "Übersprungen",
                        true: "Übersprungen",
                        false: "Nicht übersprungen",
                    },
                },
                legend: {
                    title: "Legende",
                    completed: "Abgeschlossen",
                    matched: "Matched",
                    skiped: "Übersprungen",
                    notAutoMatched:
                        "Nicht übereinstimmend automatisch (Duplikat)",
                    error: "Fehler",
                },
            },
        },
        album: {
            partner: {
                head: "Partner",
                name: "Name",
            },
            list: {
                head: "Auflistungen",
                empty: "Keine Auflistungen",
                date: "#",
                name: "Name",
                matched: "Matched",
            },
            album: {
                head: "Alben",
                empty: "Keine Alben",
                rank: "Rang",
                cover: "Cover",
                id: "#",
                title: "Titel",
                snep_artist: "Snep-Künstler",
                artist: "Music-Story-Künstler",
                album: "Music-Story-Album",
                spotify_album: "Spotify-Album",
                fnac_album: "Fnac-Album",
                match: {
                    success: "Matching erstellt",
                    error: (message) =>
                        `Fehler beim Erstellen des Matchings: ${message}`,
                },
                unmatch: {
                    success: "Matching gelöscht",
                    error: (message) =>
                        `Fehler beim Löschen des Matchings: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Nicht gematched",
                    },
                },
                legend: {
                    title: "Legende",
                    matched: "Matched",
                    partially_matched: "Teilweise gematched",
                },
            },
        },
        track: {
            partner: {
                head: "Partner",
                name: "Name",
            },
            list: {
                head: "Auflistungen",
                empty: "Keine Auflistungen",
                date: "#",
                name: "Name",
                matched: "Matched",
                progress: "Fortschritt",
            },
            track: {
                head: "Tracks",
                empty: "Keine tracks",
                rank: "Rang",
                cover: "Cover",
                id: "#",
                title: "Titel",
                snep_artist: "Snep-Künstler",
                artist: "Music-Story-Künstler",
                recording: "Music-Story-Aufnahmen",
                duration: "Länge",
                artist_album: "Künstler - Album",
                case: "Fall",
                lyricfind: "Lyricfind-Track",
                actions: "Aktionen",
                spotify_track: "Spotify-Track",
                fnac_album: "Fnac-Album",
                linemusic_artist: "Line-Music-Künstler",
                edit_matchings: "Matchings bearbeiten",
                match: {
                    success: "Matching erstellt",
                    error: (message) =>
                        `Fehler beim Esrtellen des Matchings: ${message}`,
                },
                unmatch: {
                    success: "Matching gelöscht",
                    error: (message) =>
                        `Fehler beim Löschen des Matchings: ${message}`,
                },
                request: {
                    button: {
                        enabled: "Senden Sie die Textanfrage an Lyricfind",
                        disabled: "Anfrage an Lyricfind gesendet",
                    },
                    success: "Anfrage an Lyricfind gesendet",
                    error: (message) =>
                        `Fehler beim Übermitteln der Anfrage: ${message}`,
                },
                discard: {
                    button: {
                        title: "Verwerfen",
                    },
                    modal: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie diesen Künstler verwerfen?",
                        placeholder: "Kommentar",
                    },
                    success: "Titel verworfen",
                    error: (message) =>
                        `Fehler beim Verwerfen des Titels: ${message}`,
                },
                recover: {
                    button: {
                        title: "Wiederherstellen",
                    },
                    success: "Titel wiederhergestellt",
                    error: (message) =>
                        `Fehler beim Wiederherstellen des Titels: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Nicht gematched",
                    },
                    discarded: {
                        placeholder: "Verworfen",
                        true: "Verworfen",
                        false: "Nicht verworfen",
                    },
                },
                legend: {
                    title: "Legende",
                    matched: "Matched",
                    completed: "Abgeschlossen",
                    partially_matched: "Teilweise gematched",
                    discarded: "Verworfen",
                },
            },
        },
    },
    profile: {
        personal_details: {
            title: "Persönliche Informationen",
            breadcrumb: "Profil",
            head: "Hauptinformation",
            account_type: {
                title: "Kontotyp",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            firstname: "Vorname",
            lastname: "Nachname",
            email: "Mail",
            company: "Unternehmen",
            label: "Label",
            artist: "Artist",
            role: {
                title: "Rolle",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Beschreiben",
            language: "Sprache",
            expires_at: "Ablaufdatum",
            token: "API-Token",
            success: "Die Informationen wurden gespeichert",
        },
        change_password: {
            title: "Passwort ändern",
            old_password: "Altes Passwort",
            new_password: "Neues Passwort",
            confirm_password: "Bestätige das Passwort",
            success: "Das Passwort wurde aktualisiert",
        },
    },
    search: {
        breadcrumb: "Suche",
        placeholder: "Künstler / Album / Aufnahme",
        all: "Gesamt",
        artists: "Künstler",
        albums: "Alben",
        recordings: "Aufnahmen",
        empty: "Keine Ergebnisse",
        more: "Mehr",
        artistsData: {
            head: "Künstler",
            cover: "Bild",
            id: "ID",
            name: "Name",
            popularity: "Popularität",
            peak: "Höhepunkt der Popularität",
            complementary: "Ergänzung",
            type: "Art",
            genre: "Genre",
            country: "Land",
            role: "Rolle",
            actions: "Aktionen",
        },
        albumsData: {
            head: "Alben",
            cover: "Cover",
            id: "ID",
            title: "Titel",
            artist: "Künstler",
            type: {
                head: "Art",
                live: "Live",
                remix: "Remix",
                compilation: "Kompilation",
                karaoke: "Karaoke",
                ring: "Zyklus",
                other: "Anderes",
                original: "Original",
            },
            format: {
                head: "Format",
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            genre: "Genre",
            release_date: "Ursprüngliches Veröffentlichungsdatum",
            label: "Label",
            actions: "Aktionen",
        },
        recordingsData: {
            head: "Aufnahmen",
            cover: "Cover",
            id: "ID",
            title: "Titel",
            subtitle: "Untertitel",
            popularity: "Popularität",
            artist: "Künstler",
            duration: "Länge",
            genre: "Genre",
            release_date: "Ursprüngliches Veröffentlichungsdatum",
            complementary: "Zugehöriger Inhalt",
            has_credits: "Erwähnungen",
            has_lyric: "Lyrics",
            has_audio: "Audiodeskription",
            actions: "Aktionen",
        },
    },
    base: {
        user: {
            account: "Profil",
            logout: "Log out",
        },
        nav: {
            home: "Home",
            search: "Suche",
            title: {
                partners: "Partner",
                admin: "Administration",
            },
            apple: {
                title: "Apple",
                sample: {
                    title: "Sample",
                    assessment: "Bewertung",
                    management: "Verwaltung",
                    report: "Bericht",
                    guideline: "Richtlinien",
                },
            },
            documentation: {
                title: "Dokumente",
            },
            data: {
                title: "Daten",
                artists: {
                    title: "Künstler",
                    search: "Erweiterte Suche",
                    create: "Erstellung",
                    followup: "Tracking Erstellung/Löschung",
                },
                roles: "Rollen",
                country: "Land",
                genres: "Genres",
                charts: {
                    title: "Charts",
                    display: "Anzeigen",
                    translation: "Übersetzung",
                },
                labelPromo: "Label",
                data_external: {
                    title: "Externe Daten",
                    internationals_charts: "International Charts",
                    deezer_top_songs: "Deezer – Top-Songs",
                    universal_artist: "Universal",
                },
                misc_entity: "Show",
                api: {
                    title: "API",
                    getting_started: "Einstieg",
                    api_reference: "API-Referenz",
                    monitoring: "Überwachung",
                    status: "Status",
                },
            },
            tools: {
                title: "Extras",
                artistTitle: "Künstler-Titel",
            },
            plannings: {
                title: "Planungen",
                overview: "Überblick",
                manage: "Planungsverwaltung",
                picture: "Bildarbeiten",
                billing: "Abrechnung",
                statistic: "Statistiken",
            },
            pictures: {
                title: "Bilder",

                label: {
                    gallery: "Für Kunden verfügbar",
                    status: "Ausstehend / Tracking",
                    validation: "Zu verarbeitende Bilder",
                    rejected: "Abgelehnte Bilder",
                },
                curation: {
                    followup: "Kuratierung (Nachverfolgung)",
                },
                getty: {
                    followup: "Getty (Nachverfolgung))",
                },
            },
            matching: {
                title: "Matching",
                artist: "Künstler",
                album: "Alben",
                track: "Tracks",
                tops: "Charts",
                lyricsSample: "Lyrics-Sample",
                partner_tops: "Künstler Top-Listen",
            },
            snep: {
                title: "SNEP",
                file: "Top-Listen-Verwaltung",
                chart: "Charts-Verwaltung",
            },
            cnm: {
                title: "CNM",
                repositories: "Repositorys",
                aggregation: "Aufbereitung",
                consolidation: "Konsolidierung",
                charts: "Charts",
                report: "Bericht",
            },
            moodmedia: {
                title: "Moodmedia",
                search: "Suche",
                recommendation: "Empfehlung",
            },
            admin: {
                users: {
                    title: "Nutzer",
                    user: "Nutzerverwaltung",
                    author: "Autorenverwaltung",
                },
                rights: {
                    title: "Rechte",
                    role: "Rollenverwaltung",
                    right: "Rechteverwaltung",
                    group: "Gruppenverwaltung",
                },
            },
        },
    },
    common: {
        explicit: {
            long: "Explicit",
            short: "E",
        },
        multiSearch: {
            placeholder: "Künstler / Album / Aufnahme",
            explanation: () => (
                <>
                    Suchen Sie nach Künstlern, Alben, Aufnahmen, um alle
                    zugehörigen Metadaten, die über API oder Datenfluss
                    verfügbar sind, zu erkunden:
                    <ul className="mb-0">
                        <li>
                            Künstler: ISNI, Biografien, Bilder, Beliebtheit,
                            assoziierte Künstler...
                        </li>
                        <li>
                            Alben: UPC-Metadaten, kanonisches Album, Genre,
                            ursprüngliches Veröffentlichungsdatum...
                        </li>
                        <li>
                            Aufnahmen: ISRC-Metadaten, Credits, Liedtexte,
                            Audio-Beschreibung...
                        </li>
                    </ul>
                </>
            ),
            search: "Music-Story-Suche",
            artist: "Künstler",
            album: "Album",
            type: {
                live: "Live",
                remix: "Remix",
                compilation: "Kompilation",
                karaoke: "Karaoke",
                ring: "Zyklus",
                other: "Anderes",
                original: "Original",
            },
            format: {
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            recording: "Aufnahme",
            has_credits: "Erwähnungen",
            has_lyric: "Lyrics",
            has_audio: "Audiodeskription",
            more: "Mehr",
            empty: "Keine Ergebnisse",
        },
        matching: {
            status: {
                not_found: "Nicht gefunden",
                matched: "Matched",
                mixed: "Gemischte Discografie",
                duplicates: "Duplikate",
                unmatched: "Nicht gematched",
            },
        },
        partners: {
            "7D": "7Digital",
            AZ: "Amazon",
            DG: "Discogs",
            DZ: "Deezer",
            IT: "iTunes",
            MB: "MusicBrainz",
            NA: "Napster",
            PR: "PriceMinister",
            QB: "Qobuz",
            UN: "Universal",
            SP: "Spotify",
            CJ: "CD Journal",
            LM: "LineMusic",
        },
        apiDownloadButton: {
            pending: "Datei erstellen",
            success: "Datei erstellt",
        },
        editMatchingsTable: {
            empty: "Keine Kandidaten",
            id: "ID",
            name: "Name",
            type: {
                title: "Art",
                discarded: "Verworfen",
                valid: "Manuell validiert",
                invalid: "Manuell invalidieren",
                matchedByName: "Gematched nach Name",
                matchedByDiscography: "Gematched nach Diskografie",
            },
            representativity: "Repr.",
            actions: {
                validate: "Matching validieren",
                cancelValidation: "Manuelle Validierung abbrechen",
                invalidate: "Matching ungültig",
                cancelInvalidation: "Manuelle Invalidierung abbrechen",
            },
        },
        editMatchingsModal: {
            status: "Status:",
            matched: "Matched",
            not_found: "Nicht gematched",
        },
        pictureCheckButton: {
            title: "Prüfen",
            confirmModal: {
                title: "Sind Sie sicher?",
                text: "Möchten Sie dieses Künstlerfoto validieren?",
            },
            commentModal: {
                title: "Kommentar hinzufügen",
                success: "Kommentar hinzugefügt",
                error: (message) =>
                    `Fehler beim Hinzufügen des Kommentars: ${message}`,
            },
        },
        pictureTagsModal: {
            title: "Originalbild-Tags aktualisieren",
            titleVersion: "Tags quadratische Bilder aktualisieren",
            metas: "Meta-Daten",
            tags: "Tags",
            description: {
                2: () => (
                    <>
                        <strong>Innen:</strong> Innenaufnahme, die nicht in
                        andere Kategorien passt
                        <br />
                        <strong>Außen:</strong> Außenaufnahme, die nicht in
                        andere Kategorien passt
                        <br />
                        <strong>Bühne:</strong> Bühnen-Foto während eines
                        Auftritts
                    </>
                ),
                7: () => (
                    <>
                        <strong>1:</strong> Image size greater than or equal to
                        2500px.
                        <br />
                        <strong>2:</strong> Image size between 1500px (included)
                        and 2500px (excluded).
                        <br />
                        <strong>3:</strong> Image size between 800px (included)
                        and 1500px (excluded).
                    </>
                ),
                4: () => (
                    <>
                        Wann das Bild aufgenommen worden ist. Es ist schwierig,
                        das genaue Datum exakt zu bestimmen. Es sollte nicht
                        älter als 5 Jahre sein. Ziel ist es, das Bild zeitlich
                        einzuordnen. Dadurch erfahren wir, zu welchem Zeiotpunkt
                        der Karriere das Bild entstanden ist. Ein{" "}
                        <strong>Aufnahmedatum-Tag</strong>
                        ist verfügbar, wenn Sie das exakte Datum der Aufnahme
                        herausfinden. Bei Getty-Bildern schauen Sie in die
                        Metadaten des Fotos, wenn diese einsehbar sind.
                    </>
                ),
                5: () => (
                    <>
                        <strong>Persönlich:</strong> wenn das Bild nur einen
                        Künstler zeigt.
                        <br />
                        <strong>Gruppe:</strong> verschiedene Personen auf dem
                        Bild, allerdings nicht die gesamte Band
                        <br />
                        <strong>Komplette Gruppe:</strong> die gesamte Band ist
                        auf dem Foto zu sehen
                    </>
                ),
                6: () => (
                    <>
                        <strong>Porträt:</strong> Von den Haaren bis zu den
                        Schultern
                        <br />
                        <strong>Porträt (halbe Länge):</strong> Von den Haaren
                        bis zur Hüfte
                        <br />
                        <strong>Portrait (volle Länge):</strong> Gesamter Körper
                        ist dargestellt
                    </>
                ),
            },
            shotDate: "Aufnahmetag",
            msArtists: "MS-Künstler",
            msArtistsPlaceholder: "Künstler auswählen",
            open_tags: {
                title: "Open tags",
                placeholder: "Enter open tags",
            },
            tags_getty: "Tags Getty",
            success: "Tags aktualisiert",
            alert: {
                title: "Sind Sie sich Ihrer Sache sicher?",
                text: "Wenn Sie dieses Fenster schließen, gehen die ausgefüllten Daten verloren.",
            },
            upload: "Upload",
            legal: "Rechtliches",
            cropping: "Beschneiden",
            tagging: "Tagging",
            warning:
                "Die von der künstlichen Intelligenz erstellten Vorhersagen erhielten eine niedrige Punktzahl; bitte überprüfen Sie diese.",
        },
        pictureCropModal: {
            head: "Bildgröße ändern",
            width: "Breite",
            height: "Höhe",
            rotateLeft: "Nach links drehen",
            rotateRight: "Nach rechts drehen",
            success: "Größe erfolgreich geändert",
        },
        pictureMoveModal: {
            title: "Bild bewegen",
            artist: "Künstler",
            success: "Bild bewegt",
        },
        multiRecordingSelect: {
            globalSearch: "In allen Diskografien suchen",
        },
        imageUploadModal: {
            head: "Bild hinzufügen",
            link: "Link hier einfügen",
            browse: "Durchsuchen",
            file: "Klicken, um ein Bild von Ihrem Computer auszuwählen",
            success: "Bild hochgeladen",
            alert: {
                title: "Sind Sie sich Ihrer Sache sicher?",
                text: "Wenn Sie dieses Fenster schließen, gehen die ausgefüllten Daten verloren.",
            },
            upload: "Upload",
            legal: "Rechtliches",
            cropping: "Beschneiden",
            tagging: "Tagging",
            warning_upscale:
                "Das Bild wird beim Hochladen vergrößert. Der Vorgang kann ein paar Sekunden dauern. Bitte überprüfen Sie die Bildqualität im nächsten Schritt erneut.",
            warning_downscale:
                "Das Bild wird beim Hochladen verkleinert. Dies kann ein paar Sekunden dauern. Bitte überprüfen Sie die Bildqualität im nächsten Schritt erneut.",
        },
        pictureInformationModal: {
            head: "Bildinformation",
            source: "Quelle",
            source_url: "URL Quelle",
            author: "Fotograf",
            copyright: "Urheberrechtshinweis",
            year: "Jahr",
            copyright_owner: "Urheberrechtsinhaber",
            license: "Lizenz",
            complementary: "Ergänzend",
            artist_site: "Künstler-Website",
            artist_label: "Künstlerlabel",
            other: "Andere",
            description: {
                source: "Website-Quelle des Bildes, von der Sie das Bild hochladen.",
                source_url: "URL des Bildes auf der Quellwebsite.",
                author: "Autor des Bildes.",
                copyright:
                    "Rechtstext, der von jedem angezeigt werden muss, der unser Bild verwendet.",
                year: "Das Jahr der Veröffentlichung des Bildes.",
                copyright_owner:
                    "Der Inhaber der vermögensrechtlichen Rechte an dem Bild. Nur wenn er sich vom Fotografen unterscheidet.",
                complementary:
                    "Zusätzliche Informationen über das Bild, wie z.B. URL anderer Websites, auf denen das Bild veröffentlicht ist.",
            },
            success: "Bildinformation gespeichert",
            alert: {
                title: "Sind Sie sich Ihrer Sache sicher?",
                text: "Wenn Sie dieses Fenster schließen, gehen die ausgefüllten Daten verloren.",
            },
            upload: "Upload",
            legal: "Rechtliches",
            cropping: "Beschneiden",
            tagging: "Tagging",
            licenseInformation: {
                head: "Lizenzdetails",
                1: {
                    title: "GNU/FDL",
                    body: "Die GNU/FDL (Free Documentation Licence) erlaubt jedem, das Dokument zu ändern, zu kopieren und zu verbreiten, wenn es unter dieser Lizenz erneut veröffentlicht wird. Wir achten darauf, in jedem Fall die Quelle (URL der Seite, auf der das Dokument gefunden wurde) und die Art der Lizenz anzugeben. Tatsächlich war diese Lizenz ursprünglich für die Benutzerhandbücher freier Software gedacht, die unter der GNU/GPL-Lizenz vertrieben werden. Es war immer notwendig, diese Lizenz mit einer Änderungshistorie, Autorennamen usw. zu versehen. Im Laufe der Zeit hat sich diese Lizenz geändert und wurde auf andere Dokumente (Bilder, Töne...) ausgeweitet. Bei denen finden wir nicht immer eine Kopie der Lizenz (es muss die englische Version sein, das ist in derselben Lizenz angegeben!). Wenn wir ein lizenziertes GNU/FDL-Bild verwenden und den Lizenztyp, den Autor und die Quelle hinzufügen, haben wir für uns bereits unser Bestes gegeben.",
                },
                2: {
                    title: "CC-BY",
                    body: "Creative Commons: Vaterschaft (BY): Der Rechteinhaber gestattet jede Verwertung des Werks, auch für kommerzielle Zwecke, sowie die Erstellung abgeleiteter Werke. Die Verbreitung ist ebenfalls uneingeschränkt gestattet, sofern durch Urheberangabe auf den Namen geschlossen werden kann. Diese Lizenz wird für die Verbreitung und maximale Nutzung von Werken empfohlen.",
                },
                3: {
                    title: "CC-BY-SA",
                    body: "Creative Commons: Vaterschaft + Weitergabe unter denselben Bedingungen (BY SA): Der Rechteinhaber gestattet jede Nutzung des Originalwerks (auch für kommerzielle Zwecke) sowie die Erstellung abgeleiteter Werke, sofern diese unter einer identischen Lizenz verbreitet werden, die das Originalwerk regelt. Diese Lizenz wird oft mit Copyleft-Lizenzen für freie Software verglichen. Dies ist die von Wikipedia verwendete Lizenz.",
                },
                4: {
                    title: "CC-BY-ND",
                    body: "Creative Commons: Namensnennung + keine Änderung (BY ND): Der Rechteinhaber genehmigt jede Nutzung des Originalwerks (auch für kommerzielle Zwecke), nicht jedoch die Erstellung abgeleiteter Werke.",
                },
                5: {
                    title: "CC-BY-NC",
                    body: "Creative Commons: Namensnennung + Keine kommerzielle Nutzung (BY NC): Der Rechteinhaber genehmigt die Nutzung des Werks sowie die Erstellung abgeleiteter Werke, sofern es sich nicht um eine kommerzielle Nutzung handelt (kommerzielle Nutzungen unterliegen weiterhin seiner Genehmigung).",
                },
                6: {
                    title: "CC-BY-NC-SA",
                    body: "Creative Commons: Namensnennung + Keine kommerzielle Nutzung + Weitergabe unter den gleichen Bedingungen (BY NC SA): Der Rechteinhaber genehmigt die Nutzung des Originalwerks für andere als kommerzielle Zwecke sowie die Erstellung abgeleiteter Werke, sofern diese unter Verwendung dieser Rechte mit einer Lizenz verbreitet werden, die mit der des Originalwerks identisch ist.",
                },
                7: {
                    title: "CC-BY-NC-ND",
                    body: "Creative Commons: Namensnennung + keine kommerzielle Nutzung + keine Änderung (BY NC ND): Der Rechteinhaber genehmigt die Verwendung des Originalwerks für nichtkommerzielle Zwecke, nicht jedoch die Erstellung abgeleiteter Werke.",
                },
                8: {
                    title: "all-rights-reserved",
                    body: "Alle Rechte vorberhalten.",
                },
                9: {
                    title: "royalty-free",
                    body: "Gebührenfrei",
                },
                10: {
                    title: "Public Domain",
                    body: "Gemeingut ist das Fehlen von Urheberrechten, d. h. das betreffende Werk unterliegt nicht dem Urheberrecht oder anderen rechtlichen Beschränkungen.",
                },
                11: {
                    title: "CC0",
                    body: "CC0 (auch bekannt als CC Zero) ist ein öffentliches Widmungsinstrument, das es Urhebern ermöglicht, ihr Urheberrecht aufzugeben und ihre Werke in die weltweite Public Domain zu stellen. CC0 ermöglicht es Weiterverwendern, das Material in jedem Medium oder Format zu verbreiten, neu zu mischen, anzupassen und darauf aufzubauen, ohne Bedingungen zu stellen.",
                },
            },
        },
        pictureInformationModalGetty: {
            upload: "Upload",
            legal: "Rechtliches",
            cropping: "Beschneiden",
            tagging: "Tagging",
            source: "Quelle",
            source_url: "URL Quelle",
            author: "Fotograf",
            copyright: "Urheberrechtshinweis",
            year: "Jahr",
            copyright_owner: "Urheberrechtsinhaber",
            license: "Lizenz",
            complementary: "Ergänzend",
            description: {
                source: "Website-Quelle des Bildes, von der Sie das Bild hochladen.",
                source_url: "URL des Bildes auf der Quellwebsite.",
                author: "Autor des Bildes.",
                copyright:
                    "Rechtstext, der von jedem angezeigt werden muss, der unser Bild verwendet.",
                year: "Das Jahr der Veröffentlichung des Bildes.",
                copyright_owner:
                    "Der Inhaber der vermögensrechtlichen Rechte an dem Bild. Nur wenn er sich vom Fotografen unterscheidet.",
                complementary:
                    "Zusätzliche Informationen über das Bild, wie z.B.details zur Sammlung und erlaubte Nutzung",
            },
        },
        imageCropModal: {
            head: "Bildgröße ändern",
            width: "Breite",
            height: "Höhe",
            rotateLeft: "Nach links drehen",
            rotateRight: "Nach rechts drehen",
            success: "Größe erfolgreich geändert",
            alert: {
                title: "Sind Sie sich Ihrer Sache sicher?",
                text: "Wenn Sie dieses Fenster schließen, gehen die ausgefüllten Daten verloren.",
            },
            upload: "Upload",
            legal: "Rechtliches",
            cropping: "Beschneiden",
            tagging: "Tagging",
        },
        labelUploadModal: {
            head: "Künstlerfoto hinzufügen",
            link: "Link hier einfügen",
            browse: "Durchsuchen",
            file: "Klicken, um ein Bild von Ihrem Computer auszuwählen",
            title: "Titel",
            copyright: "Copyright",
            takedown: "Datum der Deaktivierung",
            label: "Label",
            success: "Bild hochgeladen",
        },
        labelInformationModal: {
            head: "Bildinformation",
            title: "Titel",
            copyright: "Copyright",
            takedown: "Datum der Deaktivierung",
            success: "Bildinformation gespeichert",
        },
        charts: {
            categoryList: {
                empty: "Keine Ergebnisse",
                head: "Kategorie",
                title: "Titel",
                source: "Quelle",
                area: "Region",
                country: "Land",
                main: "Haupt",
                format: "Format",
                link: "Link",
            },
            chartsList: {
                empty: "Keine Ergebnisse",
                head: "Chartsliste",
                year: "Jahr",
                month: "Monat",
                startDate: "Startdatum",
                endDate: "Enddatum",
                matched: "Matched",
                dataMatched: "Daten gematched",
                translated: "übersetzt",
                userData: "Zu erledigen",
            },
            charts: {
                empty: "Keine Ergebnisse",
                head: "Charts",
                rank: "Rang",
                artist: "Künstler",
                album: "Album",
                track: "Track",
                previousRank: "Vorheriger Rang",
                dateIn: "Einstiegsdatum",
                dateOut: "Ausstiegsdatum",
                nbWeek: "Wochenanzahl",
                rankIn: "Einstiegsrang",
                maxRank: "Höchste Platzierung",
                rankOut: "Ausstiegsrang",
                msArtist: "MS-Künstler",
                msAlbum: "MS-Album",
                msRecordings: "MS-Aufnahmen",
                informations: "Information",
                hasBioFR: "Hat FR-Bio",
                hasBioEN: "Hat EN-Bio",
                hasBioDE: "Hat DE-Bio",
                hasBioJA: "Hat JA-Bio",
                hasBioES: "Hat ES-Bio",
                hasBioPT: "Hat PT-Bio",
                hasPictures: "Hat Bild",
                hasCuration: "Hat Kuratierung",
                hasReview: "Hat Überprüfung",
                lastUpdated: "Letzter Planungseintrag",
                nbRelated: "Zugehörige Zahl",
                albumsWithoutGenre: "Alben ohne Genre",
                discarded: "Verworfen",
                matched: "Matched",
                translated: "Übersetzt",
                creation: "Erstellung",
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Nicht gematched",
                    },
                    newEntry: {
                        placeholder: "Neuer Eintrag",
                        true: "Neuer Eintrag",
                        false: "Alter Eintrag",
                    },
                    toBeTreated: {
                        placeholder: "Muss bearbeitet werden",
                        true: "Muss bearbeitet werden",
                    },
                    discarded: {
                        placeholder: "Verworfen",
                        true: "Verworfen",
                        false: "Nicht verworfen",
                    },
                },
                albumTranslationsForm: {
                    artist: "Künstler",
                    album: "Album",
                    translatedTitle: "Titel übersetzt",
                    translatedName: "Name übersetzt",
                    multipleNamesWarning:
                        "Das Künstler-Matching hat verschiedene übersetzte Namen ergeben. " +
                        "Die folgenden Übersetzungen werden gelöscht:",
                    save: {
                        success: "Übersetzung gespeichert",
                        error: (message) =>
                            `Fehler beim Speichern der Übersetzung: ${message}`,
                        tooltip: "Übersetzungen speichern",
                    },
                    reset: "Eingaben zurücksetzen",
                },
                trackTranslationsForm: {
                    artist: "Künstler",
                    albums: "Alben",
                    recordings: "Alben",
                    albumFormat: {
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    albumType: {
                        live: "Live",
                        remix: "Remix",
                        compilation: "Kompilation",
                        karaoke: "Karaoke",
                        ring: "Zyklus",
                        other: "Anderes",
                        original: "Original",
                    },
                    translatedTitle: "Titel übersetzt",
                    translatedSubtitle: "Untertitel übersetzt",
                    translatedName: "Name übersetzt",
                    multipleNamesWarning:
                        "Das Künstler-Matching hat verschiedene übersetzte Namen ergeben. " +
                        "Die folgenden Übersetzungen werden gelöscht:",
                    multipleTitlesWarning:
                        "Nicht alle Matching-Aufnahmen haben den gleichen übersetzten Titel. " +
                        "Bitte überprüfen Sie, ob die Übereinstimmungen korrekt sind. " +
                        "Die folgenden Übersetzungen werden gelöscht:",
                    noTitle: "Kein Titel",
                    noSubtitle: "Kein Untertitel",
                    noTranslation: "Keine Übersetzung",
                    save: {
                        success: "Übersetzungen gespeichert",
                        error: (message) =>
                            `Fehler beim Speichern der Übersetzungen: ${message}`,
                        tooltip: "Übersetzungen speichern",
                    },
                    reset: "Eingaben zurücksetzen",
                },
                discardButton: {
                    discardTooltip: "Verwerfen",
                    recoverTooltip: "Wiederherstellen",
                    modalTitle: "Kommentar",
                },
                addToPlanningButton: {
                    title: "Zur Plaung hinzufügen",
                },
            },
        },
        picture: {
            action: {
                add: "Bild hinzufügen",
                edit: "Bildinformation bearbeiten",
                tags: "Originalbild-Tags bearbeiten",
                tagsVersion: "Tags quadratische Bilder bearbeiten",
                crop: "Bildgröße ändern",
                move: "Bild bewegen",
                del: {
                    title: "Bild löschen",
                    alert: {
                        title: "Sind Sie sicher?",
                        text: "Möchten Sie dieses Bild löschen?",
                    },
                    success: "Bild gelöscht",
                    error: (message) =>
                        `Fehler beim Löschen dieses Bilds: ${message}`,
                },
            },
            getty: {
                bas: {
                    head: "Testumgebung",
                    empty: "Keine Bilder in der Testumgebung",
                    width: "Breite",
                    height: "Höhe",
                    created_at: "Erstelldatum",
                    loading: "Lädt...",
                    more: "Mehr",
                    filters: {
                        search_type: {
                            title: "Suchtyp",
                            best_match: "Bessere Matchings",
                            most_popular: "Am beliebtesten",
                        },
                    },
                    action: {
                        detail: {
                            head: "Bilddetail aus der Testumgebung",
                            dimensions: "dimensionen",
                            id: "id",
                            title: "titel",
                            collection_name: "kollektion_name",
                            caption: "untertitel",
                            people: "menschen",
                            date_camera_shot: "datum_kamera_aufnahme",
                            date_created: "datum_erstellt",
                            date_submitted: "datum_eingereicht",
                            success: "Bild hinzugefügt",
                            error: (message) =>
                                `Fehler beim Hinzufügen des Bildes: ${message}`,
                        },
                    },
                },
            },
        },
    },
    utils: {
        select: {
            empty: "Keine Ergebniss",
            placeholder: "Suche...",
            loading: "Lädt...",
            noOptions: "Keine Optionen",
            create: "Erstellen",
        },
        copy: {
            success: "Text in Zwischenablage kopiert",
            error: "Text kann nicht in Zwischenablage kopiert werden",
        },
        pagination: {
            total: ({ size }) => `${size} Ergebnisse`,
            first_page: "Erste Seite",
            last_page: "Letzte Seite",
            previous_page: "Vorherige Seite",
            next_page: "Nächste Seite",
        },
        form: {
            required: "Notwendiges Feld",
            number: "Dieses Feld ist keine Zahl",
            date_placeholder: "JJJJ-MM-TT",
            date_format: "Ungültiges Format (JJJJ-MM-TT)",
            date_zero: "Datum '0000-00-00' ungültig",
            barcode_format:
                "Strichcode sollte eine Zahl aus 12 oder 13 Ziffern sein",
            isrc_format: "ISRC-Format ungültig (AANNN0000000)",
            duration_format: "Längenformat ungültig (hh:mm:ss)",
            duration_placeholder: "hh:mm:ss",
            year_format: "Jahresformat ungültig (JJJJ)",
            end_date_greater_than_start:
                "Das Enddatum muss größer oder gleich dem Startdatum sein",
            url: "URL nicht gültig",
            wysiwyg: {
                placeholder: "Schreiben startet...",
                bold: "Fett",
                italic: "Kursiv",
                underline: "Unterstrichen",
                strikethrough: "Durchgestrichen",
            },
            email: "E-Mail-Adresse nicht gültig",
            isni_format:
                "ISNI sollte ein 16-stelliger Zifferncode sein oder 15-stellig gefolgt von einem X",
            upload_max_size: `Datei ist zu groß. (Max: ${
                UPLOAD_MAX_SIZE / 1000000
            } Mo)`,
            must_contain_uppercase:
                "Muss mindestens einen Großbuchstaben enthalten",
            must_contain_lowercase:
                "Muss mindestens einen Kleinbuchstaben enthalten",
            must_contain_8_characters: "Muss 8 Zeichen oder mehr haben",
            must_contain_digit: "Muss mindestens eine Ziffer enthalten",
            must_contain_letter: "Muss mindestens einen Buchstaben enthalten",
            passwords_must_match: "Passwörter stimmen nicht überein",
            not_hit_discovery_at_same_time:
                "Die Felder 'Hit' und 'Redaktionelle Auswahl' können nicht gleichzeitig ausgefüllt werden.",
        },
        button: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            create: "Erstellen",
            back: "Zurück",
            add: "Hinzufügen",
            edit: "Bearbeiten",
            delete: "Löschen",
            deleteAll: "Alles löschen",
            validate: "Validieren",
            previous: "Vorherige",
            next: "Nächste",
            now: "Jetzt",
            save: "Speichern",
            close: "Schließen",
            transfer: "Verschieben",
            reset: "Zurücksetzen",
            approve: "Genehmigen",
            reject: "Ablehnen",
            export: "Exportieren",
            generate: "Erzeugen",
            upload: "Hochladen",
            search: "Suche",
            merge: "Verbinden",
            continue: "Weiter",
            download: "Download",
            browse: "Durchsuchen",
            login: "Log in",
            translate: "Übersetzen Sie",
        },
        sweetalert: {
            warning: "Warnung",
            error: "Fehler",
            cancel: "Nein, abbrechen",
            close: "Schließen",
            confirm: "Ja, bestätigen",
            ok: "Ok, verstanden",
        },
        filters: {
            all: "alle",
            none: "keine",
        },
        lang: {
            PT: "Portugiesisch",
            JA: "Japanisch",
            DE: "Deutsch",
            EN: "Englisch",
            ES: "Spanisch",
            FR: "Französisch",
            KO: "Koreanisch",
            CD_JOURNAL: "Japanisch",
        },
        nth: (n) => (
            <>
                {n?.toLocaleString("en")}
                <sup>
                    {n % 10 === 1
                        ? "st"
                        : n % 10 === 2
                        ? "nd"
                        : n % 10 === 3
                        ? "rd"
                        : "th"}
                </sup>
            </>
        ),
    },
    landing: {
        breadcrumb: "Landing Page",
        industry: "Marktstatistiken",
        ms: "Datenbank-Statistiken",
        industryStats: {
            chart_title: (top) =>
                `Basierend auf den offiziellen Top ${top ?? ""} - `,
            chart_date: ({ start_date, end_date }) =>
                ` (Vom ${start_date ?? ""} bis ${end_date ?? ""})`,
            top_title: (country) =>
                `Aktuell beliebteste Titel - ${country ?? ""}`,
            top_title_description:
                "Die beliebtesten Lieder im ausgewählten Land für den aktuellen Monat.",
            top_artist: (country) =>
                `Die derzeit beliebtesten Künstler - ${country ?? ""}`,
            top_artist_description:
                "Die beliebtesten Künstler im ausgewählten Land für den aktuellen Monat.",
            top_progression: ({ id_country, country }) =>
                id_country == 33
                    ? `Die besten Einträge des Tages - ${country ?? ""}`
                    : `Die besten Einträge der Woche - ${country ?? ""}`,
            top_progression_description: (id_country) =>
                id_country == 33
                    ? "Top Chart-Einträge."
                    : "Top Chart-Einträge.",
            sexe_repartition: (country) =>
                `Verteilung der Künstler nach Typ - ${country ?? ""}`,
            sexe_repartition_description:
                "Repräsentation von „Gruppen“- und „Solo“-Künstlern (und nach Geschlecht bei Solokünstlern) in den offiziellen Charts des ausgewählten Landes.",
            genre_repartition: (country) =>
                `Vertretene Musikgenres - ${country ?? ""}`,
            genre_repartition_description:
                "Verteilung der Musikgenres, die in den Charts des ausgewählten Landes vertreten sind.",
            language_repartition: (country) =>
                `Gesungene Sprachen - ${country ?? ""}`,
            language_repartition_description:
                "Verteilung der Sprachen, die in der offiziellen Hitparade des ausgewählten Landes gesungen werden.",
            top_emerging: (country) =>
                `Aufstrebende Künstler - ${country ?? ""}`,
            top_emerging_description:
                "Die beliebtesten Künstler, die in den letzten 3 Monaten erstmals in den Charts auftauchen.",
            current_catalog: (country) =>
                `Verteilung der Lieder nach Art des Dienstalters - ${
                    country ?? ""
                }`,
            current_catalog_description:
                "Repräsentativität von „aktuellen“ (Erscheinungsdatum < 18 Monate) und „Katalog“-Titeln (Erscheinungsdatum > 18 Monate) in den offiziellen Charts des ausgewählten Landes.",
            no_data: "Unbekannt",
            other: "Andere",
            gold: "Katalog",
            current: "Aktuell",
            person: "Person",
            band: "Band",
            man: "Mann",
            woman: "Frau",
            top_3: "Top 3:",
            total: "Gesamt: ",
        },
        msStats: {
            artists: {
                title: "Künstler",
                artists_with_photo: "Künstler mit Bild(ern)",
                artists_with_bio: "Künstler mit Biografie(n)",
                biographies_description: "Biografien verfügbar in:",
            },
            albums: {
                title: "Alben",
                canonical_albums: "Kanonische Alben",
                albums_with_cover_art: "Alben mit Titelbild",
                releases: "Veröffentlichungen",
            },
            recordings: {
                title: "Aufnahmen",
                unique_recording: "Einzigartige Aufnahme",
            },
        },
    },
};
