import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import CreateAction from "./CreateAction";

const EnhancedCreateAction = ({
    listId,
    rowId,
    artistId,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const { locale } = useParams();

    const onAddCreated = () => {
        api.post(
            `matching/track/deezer/list/${listId}/${rowId}/artist_created/${artistId}`,
            { locale }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onDelCreated = () => {
        api.delete(
            `matching/track/deezer/list/${listId}/${rowId}/artist_created`
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CreateAction
            {...props}
            artistId={artistId}
            onAddCreated={onAddCreated}
            onDelCreated={onDelCreated}
        />
    );
};

export default EnhancedCreateAction;
