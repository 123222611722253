import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Form, Button, Badge } from "react-bootstrap";
import Loader from "shared/components/Loader";
import InputWysiwyg from "shared/components/Form/InputWysiwyg";
import InputSelect from "shared/components/Form/InputSelect";
import { Controller } from "react-hook-form";
import Archive from "./Archive";
import CDJournal from "./CDJournal";
import Translation from "./Translation";
import FlagIcon from "shared/functions/FlagIcon";
import { LANGS } from "pages/ArtistManagement/Biography/Biography.constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import "./Bio.css";
import clsx from "clsx";

const Bio = ({
    biography,
    setBiography,
    getBiography,
    lang,
    isLoading,
    handleSubmit,
    errors,
    control,
    isSubmitting,
    onSubmit,
    authorOptions,
    location,
    getValues,
    updateBiography,
    biographyLangOptions,
    isReference,
    isOfficial,
    toTranslate,
    setToTranslate,
}) => {
    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <Widget
                title={
                    <div className="d-flex flex-column align-items-center">
                        <div>
                            <FlagIcon code={LANGS[lang]} className="mr-1" />
                            <I18n t={`artist.management.bio.head.${lang}`} />
                        </div>
                        {isReference && (
                            <h6 className="d-inline-block mt-1">
                                <Badge pill variant="light">
                                    <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faMedal}
                                        fixedWidth
                                    />
                                    <I18n t="artist.management.bio.is_reference" />
                                </Badge>
                            </h6>
                        )}
                    </div>
                }
                titleRaw
                actions={
                    <>
                        <Translation
                            lang={lang}
                            updateBiography={updateBiography}
                            isReference={isReference}
                            isOfficial={isOfficial}
                            biographyLangOptions={biographyLangOptions}
                            setToTranslate={setToTranslate}
                        />
                        <Archive value={getValues("archive")} />
                        {lang === "JA" && <CDJournal />}
                    </>
                }
                headerClassName={clsx(isReference && "bg-success-auto")}
                enableCollapse
            >
                <Widget.Body className="widget-table-overflow mb-3 position-relative">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {toTranslate && (
                                <div className="artist-management-biography-to-translate">
                                    <h3 className="text-center">
                                        <I18n t="artist.management.bio.to_translate" />
                                    </h3>
                                </div>
                            )}
                            <InputWysiwyg
                                value={biography}
                                onChange={(newBio) => setBiography(newBio)}
                                location={location}
                                placeholder={I18n.getTranslation(
                                    location,
                                    "utils.form.wysiwyg.placeholder"
                                )}
                                className="border-right-0 border-left-0 rounded-0 artist-management-biography-wysiwyg"
                            />
                        </>
                    )}
                </Widget.Body>
                <Widget.Body>
                    {isLoading || (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="artist.management.bio.author" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="id_author"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            ref={ref}
                                            value={
                                                value !== null
                                                    ? authorOptions.find(
                                                          (option) =>
                                                              String(
                                                                  option.value
                                                              ) ===
                                                              String(value)
                                                      )
                                                    : null
                                            }
                                            options={authorOptions}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                            error={
                                                errors.id_author && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.id_author.message
                                                    ),
                                                ]
                                            }
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <div className="d-flex flex-row-reverse">
                                <Button
                                    variant="secondary ml-2"
                                    disabled={isSubmitting || isLoading}
                                    onClick={
                                        !isSubmitting && !isLoading
                                            ? getBiography
                                            : null
                                    }
                                    title={I18n.getTranslation(
                                        location,
                                        "artist.management.bio.reset.title"
                                    )}
                                >
                                    <I18n t="utils.button.reset" />
                                </Button>
                                <Button
                                    variant="success"
                                    disabled={isSubmitting || isLoading}
                                    onClick={
                                        !isSubmitting && !isLoading
                                            ? handleSubmit(onSubmit)
                                            : null
                                    }
                                    title={I18n.getTranslation(
                                        location,
                                        `artist.management.bio.update.title`
                                    )}
                                >
                                    {isSubmitting ? (
                                        <Loader size="1x" />
                                    ) : (
                                        <I18n t={`utils.button.edit`} />
                                    )}
                                </Button>
                            </div>
                        </>
                    )}
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default Bio;
