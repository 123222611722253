import React from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import InputPassword from "shared/components/Form/InputPassword";
import { Form, Button } from "react-bootstrap";
import Layout from "layout/Auth/Layout";
import clsx from "clsx";
import Error from "shared/components/Form/Error";
import SidePanel from "layout/Auth/Layout/SidePanel";

const Reset = ({
    handleReset,
    isSend,
    globalError,
    errors,
    register,
    isSubmitting,
    isDirty,
    location,
}) => {
    return (
        <Layout>
            <div
                className="row justify-content-center"
                style={{ minHeight: "610px" }}
            >
                <div className="col-lg-6">
                    <SidePanel withoutSlider />
                </div>
                <div className="col-lg-6">
                    <div className="p-lg-5 p-4">
                        <div>
                            <h3 className="text-secondary">
                                <I18n t={`security.reset.title`} />
                            </h3>
                        </div>

                        <hr />

                        <div className="mt-4">
                            {isSend ? (
                                <>
                                    <p>
                                        <I18n t={`security.reset.success`} />
                                    </p>
                                    <NavLink
                                        to={`/login`}
                                        className="text-primary text-decoration-underline float-right"
                                    >
                                        <I18n t={`security.reset.login`} />
                                    </NavLink>
                                </>
                            ) : (
                                <Form
                                    onSubmit={
                                        !isSubmitting ? handleReset : null
                                    }
                                >
                                    {globalError && (
                                        <Error
                                            error={[
                                                I18n.getTranslation(
                                                    location,
                                                    globalError
                                                ),
                                            ]}
                                        />
                                    )}
                                    <Form.Group>
                                        <Form.Label>
                                            <I18n
                                                t={`security.reset.password`}
                                            />
                                        </Form.Label>
                                        <InputPassword
                                            {...register("password")}
                                            className={clsx("form-control")}
                                            error={
                                                errors.password && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.password.message
                                                    ),
                                                ]
                                            }
                                            showEye
                                        />
                                    </Form.Group>

                                    <div className="mt-4">
                                        <Button
                                            variant="inverse"
                                            disabled={isSubmitting || !isDirty}
                                            type="submit"
                                            size="lg"
                                            block
                                        >
                                            <I18n t={`security.reset.button`} />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Reset;
