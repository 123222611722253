import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";

const PlatformTitleList = ({ rows, columns, selectRow }) => {
    return (
        <BootstrapTable
            keyField="id"
            columns={columns}
            data={rows}
            selectRow={selectRow}
            classes="table-header-fixed table-layout-auto table-selectable mb-0"
            bordered={false}
            bootstrap4
            striped
            hover
            condensed
            noDataIndication={
                <I18n t="cnm.aggregation.platformTitleList.empty" />
            }
        />
    );
};

export default PlatformTitleList;
