import React from "react";
import { useParams } from "react-router";
import Data from "./Data";

const EnhancedData = (props) => {
    const { locale } = useParams();
    return <Data {...props} locale={locale} />;
};

export default EnhancedData;
