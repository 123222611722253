import React, { useState, useEffect, useCallback } from "react";
import Translation from "./Translation";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedTranslation = (props) => {
    const { api } = useAPI();
    const { recordingId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState({
        translations: [],
        isLoading: true,
    });

    const getTranslation = useCallback(() => {
        return api
            .get(`recording/${recordingId}/translations`, { locale })
            .then((response) => {
                setState((prev) => ({ ...prev, translations: response }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [recordingId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getTranslation()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getTranslation]);

    return <Translation {...props} {...state} />;
};

export default EnhancedTranslation;
