import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useQuery from "shared/hooks/useQuery";

const schema = yup.object().shape(
    {
        msTrackIds: yup
            .string()
            .trim()
            .when(["msArtistIds", "mmTrackIds"], {
                is: (val1, val2) => val1 === "" && val2 === "",
                then: () => yup.string().required("utils.form.required"),
            }),
        msArtistIds: yup.string().trim(),
        mmTrackIds: yup
            .string()
            .trim()
            .when("msTrackIds", {
                is: (val1) => val1 !== "",
                then: () =>
                    yup
                        .string()
                        .length(0, "moodmedia.recommendation.form.error"),
            })
            .when("msArtistIds", {
                is: (val1) => val1 !== "",
                then: () =>
                    yup
                        .string()
                        .length(0, "moodmedia.recommendation.form.error"),
            }),
        minAccuracy: yup
            .number()
            .typeError("utils.form.required")
            .min(0, "moodmedia.recommendation.form.min")
            .max(100, "moodmedia.recommendation.form.max"),
    },
    [
        ["msTrackIds", "mmTrackIds"],
        ["msArtistIds", "mmTrackIds"],
    ]
);

const EnhancedSearchForm = ({ onSearch, ...props }) => {
    const query = useQuery();
    const msTrackIds = query.get("msTrackIds");
    const msArtistIds = query.get("msArtistIds");
    const mmTrackIds = query.get("mmTrackIds");
    const filterNo = query.get("filterNo");
    const catalog = query.get("catalog");
    const minAccuracy = query.get("minAccuracy");

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            msTrackIds: msTrackIds || "",
            msArtistIds: msArtistIds || "",
            mmTrackIds: mmTrackIds || "",
            filterNo: filterNo || "",
            catalog: catalog || "",
            minAccuracy: minAccuracy || 32,
        },
    });

    const onSubmit = handleSubmit((data) =>
        onSearch(
            data.msTrackIds,
            data.msArtistIds,
            data.mmTrackIds,
            data.filterNo,
            data.catalog,
            data.minAccuracy
        )
    );

    useEffect(() => {
        if (msTrackIds || msArtistIds || mmTrackIds || catalog) onSubmit();
    }, [msTrackIds, msArtistIds, mmTrackIds, catalog]);

    const catalogOptions = [
        {
            value: "db1",
            label: "db1",
        },
        {
            value: "db2",
            label: "db2",
        },
        {
            value: "db3",
            label: "db3",
        },
    ];

    return (
        <SearchForm
            onSubmit={onSubmit}
            register={register}
            control={control}
            errors={errors}
            catalogOptions={catalogOptions}
            {...props}
        />
    );
};

export default EnhancedSearchForm;
