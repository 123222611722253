import React from "react";
import { useParams } from "react-router";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import PartnerList from "./PartnerList";
import DateList from "./DateList";
import TopList from "./TopList";
import ArtistList from "./ArtistList";
import { Helmet } from "react-helmet";

const PartnerTops = ({ location }) => {
    const partners = ["DZ", "QB"];
    const { partner, date, top } = useParams();

    const hasMultipleTops = {
        qb: false,
        dz: true,
    };

    const showDates = partner !== undefined;
    const showTops = date !== undefined && hasMultipleTops[partner];
    const showArtists =
        partner !== undefined &&
        date !== undefined &&
        (top !== undefined || !hasMultipleTops[partner]);

    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.partner_tops"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.matching.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/matching/partner_tops">
                            <I18n t="base.nav.matching.partner_tops" />
                        </NavLink>
                    </li>
                </ol>

                <div className="row">
                    <div className="col-md-4">
                        <PartnerList
                            partners={partners}
                            selectedPartner={partner}
                        />
                    </div>
                    {showDates && (
                        <div
                            className={
                                hasMultipleTops[partner]
                                    ? "col-md-4"
                                    : "col-md-8"
                            }
                        >
                            <DateList partner={partner} selectedDate={date} />
                        </div>
                    )}
                    {showTops && (
                        <div className="col-md-4">
                            <TopList
                                partner={partner}
                                date={date}
                                selectedTop={top}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-md-12">
                        {showArtists && <ArtistList />}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PartnerTops;
