import React from "react";
import I18n from "shared/lib/I18n";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputNumber from "shared/components/Form/InputNumber";
import InputSelect from "shared/components/Form/InputSelect";
import { useLocation } from "react-router";

const SearchForm = ({
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    selectOptions,
    selectOptionsBpm,
    moodOptions,
}) => {
    const location = useLocation();
    return (
        <div className="row">
            <div className="col-md-12">
                <section className="widget">
                    <div className="widget-body">
                        <form onSubmit={onSubmit}>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.ms_track_ids" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("msTrackIds")}
                                            error={
                                                errors.msTrackIds && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.msTrackIds
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.ms_artist_ids" />
                                            </span>
                                        </div>
                                        <InputText
                                            {...register("msArtistIds")}
                                            error={
                                                errors.msArtistIds && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.msArtistIds
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-2">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.fa_bpm" />
                                            </span>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="faBpm"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    innerRef={ref}
                                                    className="flex-grow-1"
                                                    options={selectOptionsBpm}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    error={
                                                        errors.faBpm && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.faBpm
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            )}
                                        />
                                    </label>
                                </div>
                                <div className="col-md-2">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.fa_arousal" />
                                            </span>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="faArousal"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    innerRef={ref}
                                                    className="flex-grow-1"
                                                    options={selectOptions}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    error={
                                                        errors.faArousal && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.faArousal
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.min_accuracy" />
                                            </span>
                                        </div>
                                        <InputNumber
                                            {...register("minAccuracy")}
                                            error={
                                                errors.minAccuracy && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.minAccuracy
                                                            .message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.fa_moods" />
                                            </span>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="faMoods"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    isMulti
                                                    innerRef={ref}
                                                    className="flex-grow-1"
                                                    options={moodOptions}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.map(
                                                                      ({
                                                                          value,
                                                                      }) =>
                                                                          value
                                                                  )
                                                                : []
                                                        );
                                                    }}
                                                    error={
                                                        errors.faMoods && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.faMoods
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            )}
                                        />
                                    </label>
                                </div>
                                <div className="col-md-2">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.fa_valence" />
                                            </span>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="faValence"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    innerRef={ref}
                                                    className="flex-grow-1"
                                                    options={selectOptions}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    error={
                                                        errors.faValence && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.faValence
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            )}
                                        />
                                    </label>
                                </div>
                                <div className="col-md-2">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.fa_intensity" />
                                            </span>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="faIntensity"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    innerRef={ref}
                                                    className="flex-grow-1"
                                                    options={selectOptions}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    error={
                                                        errors.faIntensity && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    .faIntensity
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            )}
                                        />
                                    </label>
                                </div>

                                <div className="col-md-2">
                                    <label className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <I18n t="recommendation.year_range" />
                                            </span>
                                        </div>
                                        <InputNumber
                                            {...register("yearRange")}
                                            error={
                                                errors.yearRange && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.yearRange.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-block btn-info"
                                            disabled={isLoading}
                                        >
                                            <I18n t="utils.button.search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SearchForm;
