import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import InputText from "shared/components/Form/InputText";
import MultiArtistSelect from "shared/components/MultiArtistSelect";
import I18n from "shared/lib/I18n";

const UpdateModalButton = ({
    show,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onShow,
    onHide,
    onSubmit,
    locale,
    location,
}) => {
    return (
        <>
            <Button
                variant="primary"
                className="ml-2"
                onClick={(e) => onShow()}
                title={I18n.getTranslation(
                    location,
                    "cnm.repositories.cnm_artists_titles.artists.edit.tooltip"
                )}
            >
                <FontAwesomeIcon icon={faPen} />
            </Button>

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.cnm_artists_titles.artists.edit.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.cnm_artists_titles.artists.name" />
                            </Form.Label>
                            <InputText
                                {...register("name")}
                                error={
                                    errors.name && [
                                        I18n.getTranslation(
                                            location,
                                            errors.name.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="cnm.repositories.cnm_artists_titles.artists.msArtists" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="msArtists"
                                render={({
                                    field: { onChange, value, name, ref },
                                }) => (
                                    <MultiArtistSelect
                                        innerRef={ref}
                                        name={name}
                                        selected={value}
                                        locale={locale}
                                        onMatch={(id, name) =>
                                            onChange([...value, { id, name }])
                                        }
                                        onUnmatch={(id) =>
                                            onChange(
                                                value.filter(
                                                    (artist) => artist.id !== id
                                                )
                                            )
                                        }
                                        onClear={() => onChange([])}
                                        placeholder={I18n.getTranslation(
                                            location,
                                            "cnm.repositories.cnm_artists_titles.artists.edit.msArtistsPlaceholder"
                                        )}
                                        enablePortal
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        <I18n t="cnm.repositories.cnm_artists_titles.artists.edit.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UpdateModalButton;
