import React, { useState } from "react";
import Tooltip from "./Tooltip";

const EnhancedTooltip = (props) => {
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    return <Tooltip {...props} visible={visible} show={show} hide={hide} />;
};

export default EnhancedTooltip;
