import React, { useState, useEffect, useCallback } from "react";
import Lyric from "./Lyric";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import { convertLyrics, convertToSeconds, formatDuration } from "./Lyric.pure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLongArrowAltRight,
    faPlus,
    faMinus,
    faComment,
} from "@fortawesome/free-solid-svg-icons";
import { useMK } from "shared/hooks/useMK";
import RatingForm from "./RatingForm";
import clsx from "clsx";
import useUser from "shared/hooks/useUser";

const EnhancedLyric = ({
    lyric,
    assessments,
    getAssessments,
    setLineFilter,
    activeLines,
    setActiveLines,
    ...props
}) => {
    const { api } = useAPI();
    const { user } = useUser();
    const [lyrics, setLyrics] = useState({
        hasSyncTag: false,
        lyrics: [],
    });
    const location = useLocation();
    const mk = useMK({
        playbackTime: window.MusicKit.Events.playbackTimeDidChange,
    });
    const [expanded, setExpanded] = useState([]);
    const [criteriaOptions, setCriteriaOptions] = useState([]);
    const [errorTypeOptions, setErrorTypeOptions] = useState([]);

    useEffect(() => {
        if (lyric.lyrics) {
            const convertedLyrics = convertLyrics(lyric.lyrics);
            setLyrics({
                hasSyncTag: !convertedLyrics.every(({ timing }) => !timing),
                lyrics: convertedLyrics,
            });
        }
    }, [lyric.lyrics]);

    useEffect(() => {
        if (mk.playbackTime) {
            let lines = [];
            lyrics.lyrics.forEach(({ line, timing }) => {
                if (
                    timing &&
                    timing.begin <= mk.playbackTime.currentPlaybackTime &&
                    timing.end >= mk.playbackTime.currentPlaybackTime
                ) {
                    lines.push(line);
                }
            });
            setActiveLines(lines);
        }
    }, [mk.playbackTime]);

    const getCriteriaOptions = useCallback(() => {
        let cancelled = false;
        if (lyric.catalogType) {
            api.get(`apple/sample/assessment/criterias/${lyric.catalogType}`)
                .then((response) => {
                    if (!cancelled) {
                        setCriteriaOptions(response);
                    }
                })
                .catch((error) => {
                    if (!cancelled) {
                        console.error(error);
                        toast.error(error.message);
                        setCriteriaOptions([]);
                    }
                });
        }

        return () => {
            cancelled = true;
        };
    }, [lyric]);

    useEffect(getCriteriaOptions, [getCriteriaOptions]);

    const getErrorTypeOptions = useCallback(() => {
        let cancelled = false;
        api.get(`apple/sample/assessment/error_types`)
            .then((response) => {
                if (!cancelled) {
                    setErrorTypeOptions(response);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setErrorTypeOptions([]);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(getErrorTypeOptions, [getErrorTypeOptions]);

    const scrubToTime = async (e) => {
        const time = e.target.textContent;
        if (mk.instance.playbackState !== 0) {
            await mk.instance.seekToTime(convertToSeconds(time));
        }
    };

    const columns = [
        {
            dataField: "line",
            text: I18n.getTranslation(
                location,
                "apple.sample.assessment.lyric.nbLine"
            ),
            headerStyle: { width: "5em" },
        },
        lyrics.hasSyncTag && {
            dataField: "timing",
            text: I18n.getTranslation(
                location,
                "apple.sample.assessment.lyric.timing"
            ),
            formatter: (cell, row, rowIndex, data) => {
                return (
                    cell && (
                        <div className="apple__sample__assessment__lyric__timing">
                            <span
                                className="cursor-pointer btn-link text-center"
                                onClick={data.scrubToTime}
                            >
                                {data.formatDuration(cell.begin)}
                            </span>
                            <FontAwesomeIcon
                                icon={faLongArrowAltRight}
                                className="align-self-center"
                            />
                            <span
                                className="cursor-pointer btn-link text-center"
                                onClick={data.scrubToTime}
                            >
                                {data.formatDuration(cell.end)}
                            </span>
                        </div>
                    )
                );
            },
            formatExtraData: {
                scrubToTime,
                formatDuration,
            },
            headerStyle: { width: "10em" },
        },
        {
            dataField: "text",
            text: I18n.getTranslation(
                location,
                "apple.sample.assessment.lyric.text"
            ),
            formatter: (cell, row, rowIndex, data) => {
                return (
                    <div className="apple__sample__assessment__lyric__text__container">
                        {cell}
                        {data.assessments.some(
                            ({ line_number }) => line_number === row.line
                        ) && (
                            <span
                                className="apple__sample__assessment__lyric__text__comment"
                                title={I18n.getTranslation(
                                    location,
                                    "apple.sample.assessment.lyric.rating.filter"
                                )}
                                onClick={() => setLineFilter(row.line)}
                            >
                                <FontAwesomeIcon icon={faComment} fixedWidth />
                            </span>
                        )}
                    </div>
                );
            },
            formatExtraData: {
                assessments,
            },
            classes: "pl-3",
        },
    ].filter(Boolean);

    const expandRow = {
        className: "apple__sample__assessment__lyric__expanded__row",
        onlyOneExpanding: true,
        showExpandColumn: user.hasRight("apple.sample.assessment.manage"),
        expandByColumnOnly: true,
        expandColumnPosition: "right",
        nonExpandable: lyrics.lyrics
            .filter(({ id }) => id.includes("divider"))
            .map(({ id }) => id),
        expandHeaderColumnRenderer: () => {
            return;
        },
        expandColumnRenderer: ({ expanded, rowKey }) => {
            if (rowKey.includes("divider")) {
                return;
            }
            if (expanded) {
                return (
                    <FontAwesomeIcon
                        icon={faMinus}
                        className="cursor-pointer"
                        fixedWidth
                    />
                );
            }
            return (
                <FontAwesomeIcon
                    icon={faPlus}
                    className="cursor-pointer"
                    fixedWidth
                />
            );
        },
        expanded,
        onExpand: (row, isExpand) => {
            setExpanded(isExpand ? [row.id] : []);
        },
        onExpandAll: () => {
            setExpanded([]);
        },
        renderer: ({ line }) => (
            <RatingForm
                line={line}
                criteriaOptions={criteriaOptions}
                errorTypeOptions={errorTypeOptions}
                onSuccess={() => {
                    getAssessments();
                    setExpanded([]);
                }}
            />
        ),
    };

    const rowClasses = (row) => {
        const classNames = [
            "apple__sample__assessment__lyric__row",
            lyrics.hasSyncTag ? "with__sync__tag" : "without__sync__tag",
        ];
        if (activeLines.includes(row.line)) {
            return clsx(
                ...classNames,
                "apple__sample__assessment__lyric__current__row"
            );
        }

        if (row.id.includes("divider")) {
            return clsx(
                ...classNames,
                "apple__sample__assessment__lyric__empty__row"
            );
        }

        return clsx(...classNames);
    };

    return (
        <Lyric
            lyrics={lyrics.lyrics}
            columns={columns}
            rowClasses={rowClasses}
            expandRow={expandRow}
            {...props}
        />
    );
};

export default EnhancedLyric;
