import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import List from "./List";
import { useLocation, useNavigate, useParams } from "react-router";
import I18n from "shared/lib/I18n";

const defaultState = {
    list: [],
    isLoading: false,
};

const EnhancedList = (props) => {
    const { api } = useAPI();
    const navigate = useNavigate();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [state, setState] = useState(defaultState);

    const fetchList = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/track/${partnerId}/list`)
            .then((response) => {
                if (!cancelled) {
                    setState({ list: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [partnerId]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const columns = [
        {
            dataField: "date",
            text: I18n.getTranslation(location, "matching.track.list.date"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "matching.track.list.name"),
        },
        {
            dataField: "matched",
            text: I18n.getTranslation(location, "matching.track.list.progress"),
            formatter: (cell, { total }) =>
                cell !== null && total !== null ? `${cell} / ${total}` : "-",
        },
    ];

    const defaultSorted = [
        {
            dataField: "date",
            order: "desc",
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(`/${locale}/matching/track/${partnerId}/${row.id}`);
            }
        },
        selected: [listId],
    };

    return (
        <List
            {...props}
            {...state}
            columns={columns}
            defaultSorted={defaultSorted}
            selectRow={selectRow}
        />
    );
};

export default EnhancedList;
