import React from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import ExportSwitch from "./ExportSwitch";

const EnhancedExportSwitch = ({ fileId, type, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();

    const onChange = () => {
        toast.promise(
            api
                .post(`snep/file/${fileId}/export/${type}`, { locale })
                .then((response) => {
                    onSuccess(response);
                }),
            {
                pending: {
                    render() {
                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    pulse={true}
                                />
                                <span className="ml-2">
                                    <I18n t="snep.file.fileList.export.pending" />
                                </span>
                            </>
                        );
                    },
                },
                success: {
                    render() {
                        return I18n.getTranslation(
                            location,
                            "snep.file.fileList.export.success"
                        );
                    },
                },
                error: {
                    render({ data }) {
                        console.error(data);
                        return data.message;
                    },
                },
            }
        );
    };

    return <ExportSwitch {...props} onChange={onChange} />;
};

export default EnhancedExportSwitch;
