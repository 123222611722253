import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const MatchSelectionButton = ({
    show,
    control,
    selectedMatchingIds,
    modifiedMatchings,
    onShow,
    onHide,
    genreOptions,
    location,
    onSubmit,
    isSubmitting,
    isDirty,
}) => {
    return (
        <>
            <Button
                onClick={() => onShow(selectedMatchingIds)}
                disabled={selectedMatchingIds.length == 0}
            >
                <I18n t="cnm.repositories.cnm_genres.matchings.matchSelection.button" />
            </Button>
            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="cnm.repositories.cnm_genres.matchings.matchSelection.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        <Controller
                            control={control}
                            name="genre"
                            render={({
                                field: { onChange, value, name, ref },
                            }) => (
                                <InputSelect
                                    innerRef={ref}
                                    name={name}
                                    options={genreOptions}
                                    value={
                                        value !== null
                                            ? genreOptions.find(
                                                  (opt) => opt.value == value
                                              )
                                            : null
                                    }
                                    onChange={(opt, action) =>
                                        onChange(
                                            action.action === "select-option"
                                                ? opt.value
                                                : null
                                        )
                                    }
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "cnm.repositories.cnm_genres.matchings.matchSelection.genrePlaceholder"
                                    )}
                                    enablePortal
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                            )}
                        />
                        {modifiedMatchings.length > 0 && (
                            <div className="alert alert-warning mt-3 mb-0">
                                <I18n t="cnm.repositories.cnm_genres.matchings.matchSelection.modifiedMatchings" />
                                <ul className="mb-0">
                                    {modifiedMatchings.map(
                                        ({ msId, msName, cnmName }) => (
                                            <li key={msId}>
                                                {`${msName} (MS) – ${cnmName} (CNM)`}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        <I18n t="cnm.repositories.cnm_genres.matchings.matchSelection.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MatchSelectionButton;
