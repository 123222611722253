import React from "react";
import PlanningsStatistic from "./PlanningsStatistic";
import { useLocation } from "react-router";

const EnhancedPlanningsStatistic = (props) => {
    const location = useLocation();
    return <PlanningsStatistic {...props} location={location} />;
};

export default EnhancedPlanningsStatistic;
