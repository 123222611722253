import React, { useState, useEffect, useCallback } from "react";
import Albums from "./Albums";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const defaultValues = {
    isLoading: false,
    albums_creation: [],
    albums_with_cover_art: [],
    releases_creation: [],
};

const EnhancedAlbums = (props) => {
    const { api } = useAPI();
    const [state, setState] = useState(defaultValues);
    const location = useLocation();

    const getAlbumsStats = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`statistics/ms/albums-stats`)
            .then((response) => {
                setState({
                    isLoading: false,
                    ...response,
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultValues);
            });
    }, []);

    useEffect(getAlbumsStats, [getAlbumsStats]);

    return <Albums {...props} {...state} location={location} />;
};

export default EnhancedAlbums;
