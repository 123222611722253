import React from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import FlagIcon from "shared/functions/FlagIcon";

const CreateOrUpdateModal = ({
    role,
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    errors,
    isSubmitting,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`role.manage.${role ? "update" : "create"}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="fr" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_fr")}
                                error={
                                    errors.role_fr && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_fr.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="gb" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_en")}
                                error={
                                    errors.role_en && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_en.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="jp" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_ja")}
                                error={
                                    errors.role_ja && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_ja.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="de" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_de")}
                                error={
                                    errors.role_de && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_de.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="kr" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_ko")}
                                error={
                                    errors.role_ko && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_ko.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="pt" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_pt")}
                                error={
                                    errors.role_pt && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_pt.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="es" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_es")}
                                error={
                                    errors.role_es && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_es.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FlagIcon code="us" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputText
                                {...register("role_us")}
                                error={
                                    errors.role_us && [
                                        I18n.getTranslation(
                                            location,
                                            errors.role_us.message
                                        ),
                                    ]
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
