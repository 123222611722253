import React from "react";
import { toast } from "react-toastify";
import PartnerSelect from "./PartnerSelect";
import useAPI from "shared/hooks/useApi";

const EnhancedPartnerSelect = ({ partner, onSelect, ...props }) => {
    const { api } = useAPI();

    const search = (term, callback) => {
        api.get(`matching/${partner}/search`, { term })
            .then((response) => {
                callback(
                    response.map(({ id, name }) => {
                        return {
                            value: id,
                            label: `${id} - ${name}`,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onChange = (opt, action) => {
        if (action.action === "select-option") {
            onSelect?.(opt.value);
        }
    };

    return (
        <PartnerSelect {...props} loadOptions={search} onChange={onChange} />
    );
};

export default EnhancedPartnerSelect;
