import React, { useMemo } from "react";
import SearchResult from "./SearchResult";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import ArtistListAction from "shared/components/ArtistListAction";
import useQuery from "shared/hooks/useQuery";

const EnhancedSearchResult = ({ artists, ...props }) => {
    const location = useLocation();
    const query = useQuery();

    const hasSearch = useMemo(() => {
        let bool = false;
        for (let p of query.entries()) {
            bool = true;
        }

        return bool;
    }, [query]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "artist.search.id"),
            formatter: (cell) => {
                return cell ? <strong>{cell}</strong> : "-";
            },
            headerStyle: { width: "7em" },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "artist.search.name"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
        },
        {
            dataField: "complement",
            text: I18n.getTranslation(location, "artist.search.complement"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "21em" },
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "artist.search.type"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "6em" },
        },
        {
            dataField: "genre",
            text: I18n.getTranslation(location, "artist.search.genre"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "16em" },
        },
        {
            dataField: "country",
            text: I18n.getTranslation(location, "artist.search.country"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "14em" },
        },
        {
            dataField: "role",
            text: I18n.getTranslation(location, "artist.search.role"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            title: true,
            headerStyle: { width: "6em" },
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(location, "artist.search.actions"),
            formatter: (cell, row) => {
                return (
                    <ArtistListAction
                        msArtist={{ id: row.id, name: row.name }}
                    />
                );
            },
            align: "center",
            headerStyle: { width: "14em" },
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 100,
        totalSize: artists.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = (row) => (row.completed ? undefined : "table-warning");

    return (
        <SearchResult
            {...props}
            artists={artists}
            columns={columns}
            pagination={pagination}
            location={location}
            rowClasses={rowClasses}
            hasSearch={hasSearch}
        />
    );
};

export default EnhancedSearchResult;
