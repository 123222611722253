import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

const DnDColumns = ({ items, id }) => {
    return (
        <Droppable droppableId={id}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    <div className="items-container">
                        {items.map((item, index) => (
                            <Draggable
                                draggableId={item.id}
                                index={index}
                                key={item.id}
                            >
                                {(provided) => (
                                    <div
                                        className="item-container"
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                    >
                                        <div
                                            style={{
                                                boxShadow:
                                                    "0px 2px 20px 0px rgba(0, 0, 0, 0.2)",
                                            }}
                                        >
                                            {item.widget}
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    );
};

export default DnDColumns;
