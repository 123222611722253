import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import CNMPlatformIcon from "shared/components/CNMPlatformIcon";
import PlatformTitleList from "./PlatformTitleList";

const EnhancedPlatformTitleList = ({
    selected,
    onSelect,
    onMoveLeft,
    onMoveRight,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();

    const columns = [
        {
            dataField: "platform",
            text: "",
            formatter: (cell) => (
                <span title={cell}>
                    <CNMPlatformIcon name={cell} size="lg" />
                </span>
            ),
        },
        {
            dataField: "position",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.position"
            ),
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.artist"
            ),
            formatter: (cell, row) => (
                <>
                    {cell}
                    {row.otherArtists ? ` (${row.otherArtists})` : ""}
                </>
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.title"
            ),
            formatter: (cell, row) => (
                <>
                    {cell}
                    {row.subtitle ? (
                        <span className="font-italic ml-2">{row.subtitle}</span>
                    ) : (
                        ""
                    )}
                </>
            ),
        },
        {
            dataField: "isrcs",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.isrcs"
            ),
            formatter: (cell) => (
                <>
                    {cell.map((isrc, index) => (
                        <React.Fragment key={isrc}>
                            {index !== 0 ? ", " : ""}
                            <a
                                href={`https://isrcsearch.ifpi.org/?tab="code"&isrcCode="${isrc}"`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {isrc}
                            </a>
                        </React.Fragment>
                    ))}
                </>
            ),
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.duration"
            ),
            formatter: (cell) => cell ?? "-",
            searchable: false,
        },
        {
            dataField: "streams",
            text: I18n.getTranslation(
                location,
                "cnm.aggregation.platformTitleList.streams"
            ),
            formatter: (cell, row, rowIndex, data) =>
                cell.toLocaleString(data.locale),
            formatExtraData: {
                locale,
            },
            searchable: false,
            sort: true,
            headerStyle: {
                width: "8em",
            },
        },
        {
            dataField: "url",
            text: "",
            formatter: (cell) =>
                cell ? (
                    <a
                        className="btn btn-link"
                        href={cell}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <FontAwesomeIcon icon={faPlay} fixedWidth />
                    </a>
                ) : (
                    ""
                ),
            classes: () => "py-0",
            searchable: false,
        },
        {
            dataField: "actions",
            isDummyField: true,
            text: "",
            formatter: (cell, row, rowIndex, data) => (
                <>
                    {onMoveLeft !== undefined && (
                        <Button
                            variant="link"
                            title={I18n.getTranslation(
                                data.location,
                                "cnm.aggregation.platformTitleList.move"
                            )}
                            onClick={(e) => {
                                e.stopPropagation();
                                onMoveLeft(row);
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                        </Button>
                    )}
                    {onMoveRight !== undefined && (
                        <Button
                            variant="link"
                            title={I18n.getTranslation(
                                data.location,
                                "cnm.aggregation.platformTitleList.move"
                            )}
                            onClick={(e) => {
                                e.stopPropagation();
                                onMoveRight(row);
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                        </Button>
                    )}
                </>
            ),
            formatExtraData: {
                location,
            },
            headerStyle: {
                width: "4em",
            },
            classes: () => "py-0",
        },
    ];

    const selectRow = useMemo(
        () => ({
            mode: "checkbox",
            clickToSelect: true,
            hideSelectAll: true,
            onSelect,
            selected: selected.map(({ id }) => id),
        }),
        [selected]
    );

    return (
        <PlatformTitleList columns={columns} selectRow={selectRow} {...props} />
    );
};

export default EnhancedPlatformTitleList;
