import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import { addToClipboard } from "shared/functions/AddToClipboard";
import { platform_color } from "pages/CNMConsolidation/Edit/Edit.pure";

const LabelReference = ({ labels, location, locale, disabled }) => {
    return (
        <div className="form-control reference-list">
            {labels?.map(({ label, platform }, index) => (
                <Button
                    key={index}
                    variant={`outline-${platform_color(platform)}`}
                    title={I18n.getTranslation(
                        location,
                        "cnm.report.consolidate.edit.label.copy"
                    )}
                    onClick={() => addToClipboard(label, locale)}
                    disabled={disabled}
                >
                    {label}
                    {!disabled && (
                        <FontAwesomeIcon icon={faCopy} className="ml-1" />
                    )}
                </Button>
            ))}
        </div>
    );
};

export default LabelReference;
