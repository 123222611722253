import React, { useState, useRef, useEffect, useCallback } from "react";
import LanguageRepartition from "./LanguageRepartition";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedLanguageRepartition = ({ id_country, locale, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        language_repartition: [],
    });

    const popoverPointerRef = useRef(null);
    const containerRef = useRef(null);
    const [popover, setPopover] = useState({
        show: false,
        left: 0,
        top: 0,
        language_repartition: null,
    });

    const getLanguageRepartition = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_country) {
            api.get(`statistics/industry/${id_country}/language-repartition`, {
                locale,
            })
                .then((response) => {
                    setState({
                        isLoading: false,
                        language_repartition: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        language_repartition: [],
                    });
                });
        }
    }, [id_country, locale]);

    useEffect(getLanguageRepartition, [getLanguageRepartition]);

    return (
        <LanguageRepartition
            {...props}
            {...state}
            popoverPointerRef={popoverPointerRef}
            containerRef={containerRef}
            popover={popover}
            setPopover={setPopover}
            id_country={id_country}
        />
    );
};

export default EnhancedLanguageRepartition;
