import React, { useState, useEffect, useCallback } from "react";
import DetailModal from "./DetailModal";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";

const EnhancedDetailModal = ({
    id_picture,
    id_picture_version,
    show,
    ...props
}) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });
    const [more, setMore] = useState(false);

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(
                `picture/${id_picture}`,
                id_picture_version
                    ? {
                          locale,
                          versionId: id_picture_version,
                      }
                    : { locale }
            )
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, id_picture, locale]);

    useEffect(getPicture, [getPicture]);

    const [tags, setTags] = useState([]);
    const getTags = useCallback(() => {
        if (show) {
            api.get("picture/tags", { locale })
                .then((response) => {
                    setTags(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, locale]);

    useEffect(getTags, [getTags]);

    return (
        <DetailModal
            {...props}
            {...state}
            show={show}
            tags={tags}
            more={more}
            setMore={setMore}
        />
    );
};

export default EnhancedDetailModal;
