import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputFile from "shared/components/Form/InputFile";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";

const UploadModal = ({
    show,
    onHide,
    control,
    errors,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="snep.file.fileList.upload.head" />
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={!isSubmitting ? onSubmit : null}>
                <Modal.Body>
                    <Form.Group>
                        <Controller
                            control={control}
                            name="file"
                            render={({
                                field: { onChange, value, name, ref },
                            }) => (
                                <InputFile
                                    name={name}
                                    ref={ref}
                                    dataBrowse={I18n.getTranslation(
                                        location,
                                        "utils.button.browse"
                                    )}
                                    onChange={(e) =>
                                        onChange(e.target.files[0])
                                    }
                                    label={
                                        value !== null
                                            ? value.name
                                            : I18n.getTranslation(
                                                  location,
                                                  "snep.file.fileList.upload.file"
                                              )
                                    }
                                    error={
                                        errors.file && [
                                            I18n.getTranslation(
                                                location,
                                                errors.file.message
                                            ),
                                        ]
                                    }
                                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        <I18n t="utils.button.close" />
                    </Button>
                    <Button
                        variant="success"
                        type="submit"
                        disabled={isSubmitting || !isDirty}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t={`utils.button.upload`} />
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UploadModal;
