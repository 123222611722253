import React from "react";
import ReleasesLink from "./ReleasesLink";

const EnhancedReleasesLink = ({ links, ...props }) => {
    return (
        <ReleasesLink
            {...props}
            deezer={links.filter((r) => r.partner === "DZ")}
            qobuz={links.filter((r) => r.partner === "QB")}
            napster={links.filter((r) => r.partner === "NA")}
            itunes={links.filter((r) => r.partner === "IT")}
            spotify={links.filter((r) => r.partner === "SP")}
            musicbrainz={links.filter((r) => r.partner === "MB")}
            discogs={links.filter((r) => r.partner === "DG")}
            linemusic={links.filter((r) => r.partner === "LM")}
        />
    );
};

export default EnhancedReleasesLink;
