import React from "react";
import LabelPromoManagement from "./LabelPromoManagement";
import { useLocation } from "react-router";

const EnhancedLabelPromoManagement = (props) => {
    const location = useLocation();
    return <LabelPromoManagement {...props} location={location} />;
};

export default EnhancedLabelPromoManagement;
