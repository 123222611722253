import React from "react";
import I18n from "shared/lib/I18n";
import Status from "pages/PicturesLabelStatus/Gallery/Status";

const Historic = ({ createdAt, historic }) => {
    return (
        <>
            <div>
                <I18n t="pictures.label.status.createdAt" /> : {createdAt}
            </div>
            {historic.length !== 0 && (
                <>
                    <div>
                        <I18n t="pictures.label.status.statusChange" /> :
                    </div>
                    <ul>
                        {historic.map(({ date, from, to, comment }, key) => {
                            return (
                                <li key={key}>
                                    <span>
                                        {`${date} - `}
                                        <Status status={from} />
                                        {` -> `}
                                        <Status status={to} />
                                        {comment ? (
                                            <>
                                                <br />
                                                <span
                                                    className="blockquote-footer pre-wrap"
                                                    style={{
                                                        fontSize: "larger",
                                                    }}
                                                >
                                                    {comment}
                                                </span>
                                            </>
                                        ) : undefined}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </>
    );
};

export default Historic;
