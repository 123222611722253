import React, { useState } from "react";
import { STATUSES } from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";
import StatusFilter from "./StatusFilter";

const EnhancedStatusFilter = ({ statusFilter, setStatusFilter, ...props }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const onStatusChange = (event) => {
        const status = event.target.name;

        setStatusFilter((statusFilter) => ({
            ...statusFilter,
            [status]: !statusFilter[status],
        }));
    };

    const setAllFilters = (val) =>
        setStatusFilter(
            STATUSES.reduce(
                (statusFilter, status) => ({
                    ...statusFilter,
                    [status]: val,
                }),
                {}
            )
        );

    const total = STATUSES.length;
    const selected = STATUSES.filter((status) => statusFilter[status]).length;

    return (
        <StatusFilter
            statusFilter={statusFilter}
            total={total}
            selected={selected}
            onStatusChange={onStatusChange}
            setAllFilters={setAllFilters}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            {...props}
        />
    );
};

export default EnhancedStatusFilter;
