import React, { useState } from "react";
import InputPassword from "./InputPassword";

const EnhancedInputPassword = React.forwardRef((props, ref) => {
    const [type, setType] = useState("password");

    const switchType = () => {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    };

    return (
        <InputPassword
            ref={ref}
            type={type}
            switchType={switchType}
            {...props}
        />
    );
});

export default EnhancedInputPassword;
