import React from "react";
import I18n from "shared/lib/I18n";
import Informations from "./Informations";
import ApplePlayer from "./ApplePlayer";
import Assessment from "./Assessment";
import Loader from "shared/components/Loader";
import { Helmet } from "react-helmet";
import "./AppleSampleAssessment.css";

const AppleSampleAssessment = ({ isInit, location, ...props }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.sample.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.apple.sample.assessment"
                )}`}</title>
            </Helmet>
            <main
                id="content"
                className="content apple__sample__assessment__container"
                role="main"
            >
                <ol className="breadcrumb apple__sample__assessment__breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.apple.title" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.apple.sample.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="base.nav.apple.sample.assessment" />
                    </li>
                </ol>
                {!isInit ? (
                    <Loader className="apple__sample__assessment__loader" />
                ) : (
                    <>
                        <Informations {...props} />
                        <Assessment {...props} />
                        <ApplePlayer {...props} />
                    </>
                )}
            </main>
        </div>
    );
};

export default AppleSampleAssessment;
