import React from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import Tracks from "./Tracks";
import PartnerTracks from "pages/ArtistManagement/Discography/Sandbox/PartnerTracks";
import "./TracklistComparisonModal.css";

const TracklistComparisonModal = ({
    show,
    isLoading,
    isSubmittingReplace,
    tracks,
    partnerTracks,
    onReplace,
    onClose,
}) => {
    return (
        <Modal size="xl" show={show} onHide={onClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.disco.sandbox.tracklistComparison.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {isLoading ? (
                    <Loader className="h-100 align-items-center" size="3x" />
                ) : (
                    <div className="d-flex align-items-stretch">
                        <div>
                            <h5 className="tracklist-title">
                                <I18n t="artist.management.disco.sandbox.tracklistComparison.tracks.head" />
                            </h5>
                            <Tracks tracks={tracks} />
                        </div>
                        <div className="border-left">
                            <h5 className="tracklist-title">
                                <I18n t="artist.management.disco.sandbox.tracklistComparison.partnerTracks.head" />
                            </h5>
                            <PartnerTracks tracks={partnerTracks} />
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onReplace}
                    disabled={isLoading || isSubmittingReplace}
                >
                    {isSubmittingReplace ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t="artist.management.disco.sandbox.tracklistComparison.replace.title" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TracklistComparisonModal;
