import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import CreateOrUpdateButton from "./CreateOrUpdateButton";

const schema = yup.object().shape({
    name: yup.string().ensure().trim().required("utils.form.required"),
});

const EnhancedCreateOrUpdateButton = ({
    onSuccess,
    artist,
    defaultName,
    ...props
}) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            type: null,
        },
    });

    const onShow = () => {
        reset({ name: artist ? artist.name : defaultName });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const action = artist ? "update" : "create";

    const onSubmit = ({ name }) => {
        return api
            .post(
                action === "update" ? `cnm/artist/${artist.id}` : "cnm/artist",
                { locale },
                { name }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `cnm.report.consolidate.edit.cnmArtist.${action}.success`
                    )
                );
                onSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            })
            .then(onHide);
    };

    return (
        <CreateOrUpdateButton
            show={show}
            action={action}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            onShow={onShow}
            onHide={onHide}
            onSubmit={handleSubmit(onSubmit)}
            {...props}
        />
    );
};

export default EnhancedCreateOrUpdateButton;
