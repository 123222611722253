import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import BaS from "./BaS";

const defaultState = {
    total: 0,
    pictures: [],
    isLoading: false,
    page: 1,
    moreLoading: false,
};

const EnhancedBaS = ({ defaultSearch, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const [state, setState] = useState(defaultState);

    const [searchParams, setSearchParams] = useState({
        search: null,
        search_type: "best_match",
    });

    useEffect(() => {
        setSearchParams((prev) => ({ ...prev, search: defaultSearch }));
    }, [defaultSearch]);

    const search = () => {
        setState((previousState) => {
            return { ...previousState, isLoading: true };
        });
        api.get(`getty/sandbox`, { ...searchParams, page: 1 })
            .then((response) => {
                setState((previousState) => {
                    return {
                        ...previousState,
                        total: response.result_count,
                        pictures: response.images,
                        isLoading: false,
                        page: 1,
                    };
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const loadMore = () => {
        const newPage = state.page + 1;

        setState((previousState) => {
            return { ...previousState, moreLoading: true };
        });
        api.get(`getty/sandbox`, { ...searchParams, page: newPage })
            .then((response) => {
                setState((previousState) => {
                    return {
                        ...previousState,
                        pictures: [
                            ...previousState.pictures,
                            ...response.images,
                        ],
                        page: newPage,
                        moreLoading: false,
                    };
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    useEffect(() => {
        if (searchParams.search === null) {
            setState(defaultState);
        } else {
            search();
        }
    }, [searchParams]);

    return (
        <BaS
            {...props}
            {...state}
            setState={setState}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            loadMore={loadMore}
            location={location}
        />
    );
};

export default EnhancedBaS;
