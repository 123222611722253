import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Form, Button } from "react-bootstrap";
import Loader from "shared/components/Loader";
import InputWysiwyg from "shared/components/Form/InputWysiwyg";
import InputSelect from "shared/components/Form/InputSelect";
import "./Review.css";

const Review = ({
    review,
    setReview,
    author,
    setAuthor,
    authorOptions,
    errors,
    initForm,
    isLoading,
    isSubmitting,
    onSubmit,
    location,
}) => {
    return (
        <Widget
            title="artist.management.disco.objects.album.review.head"
            enableCollapse
        >
            <Widget.Body className="widget-table-overflow mb-3">
                {isLoading ? (
                    <Loader />
                ) : (
                    <InputWysiwyg
                        value={review}
                        onChange={(newReview) => setReview(newReview)}
                        location={location}
                        placeholder={I18n.getTranslation(
                            location,
                            "utils.form.wysiwyg.placeholder"
                        )}
                        className="border-right-0 border-left-0 rounded-0"
                    />
                )}
            </Widget.Body>
            <Widget.Body>
                {isLoading || (
                    <>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="artist.management.disco.objects.album.review.author" />
                            </Form.Label>
                            <InputSelect
                                value={
                                    author !== null
                                        ? authorOptions.find(
                                              ({ value }) => value === author
                                          )
                                        : null
                                }
                                options={authorOptions}
                                onChange={(opt) =>
                                    setAuthor(opt ? opt.value : null)
                                }
                                isClearable={false}
                                error={
                                    errors.author && [
                                        I18n.getTranslation(
                                            location,
                                            errors.author.message
                                        ),
                                    ]
                                }
                                enablePortal
                            />
                        </Form.Group>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? initForm
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.disco.objects.album.review.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? onSubmit
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.disco.objects.album.review.update.title`
                                )}
                            >
                                <I18n t={`utils.button.edit`} />
                            </Button>
                        </div>
                    </>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default Review;
