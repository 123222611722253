import React from "react";
import I18n from "shared/lib/I18n";
import Contribution from "./Contribution";
import Collaboration from "./Collaboration";
import CoPerformer from "./CoPerformer";

const Credits = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="artist.view.noData.credits" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <CoPerformer setNoData={setNoData} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Collaboration setNoData={setNoData} />
                </div>
                <div className="col">
                    <Contribution setNoData={setNoData} />
                </div>
            </div>
        </>
    );
};

export default Credits;
