import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import TaskForm from "pages/PlanningsManagement/Form/TaskForm";

const Update = ({
    planningId,
    partnerId,
    taskId,
    show,
    handleClose,
    handleShow,
    onSuccess,
    location,
    isPlanningLock,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                disabled={isPlanningLock}
                title={I18n.getTranslation(
                    location,
                    "plannings.view.tasks.action.update.title"
                )}
            >
                <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t={`plannings.view.tasks.action.update.title`} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskForm
                        partnerId={partnerId}
                        planningId={planningId}
                        taskId={taskId}
                        onSubmitSuccess={() => {
                            handleClose();
                            onSuccess?.();
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Update;
