import React, { useState, useEffect, useCallback } from "react";
import Albums from "./Albums";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import ImageLoader from "shared/components/ImageLoader";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ArtistLink from "shared/components/ArtistLink";
import AlbumLink from "shared/components/AlbumLink";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import RatingStar from "shared/components/RatingStar";
import {
    getFilterOptions,
    getFilteredAlbums,
    orderByReleaseDate,
} from "./Albums.pure";
import { displayAsString } from "shared/functions/Date";

const defaultState = {
    albums: [],
    isLoading: true,
};

const defaultFilter = {
    year: null,
    mode: "=",
    type: null,
    format: null,
};

const defaultSort = {
    dataField: "rn",
    order: "asc",
};

const EnhancedAlbums = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);
    const [selectedFilterOptions, setSelectedFilterOptions] =
        useState(defaultFilter);
    const [sortOption, setSortOption] = useState(defaultSort);
    const filterOptions = getFilterOptions({ ...state, location });

    const reset = () => {
        setSelectedFilterOptions(defaultFilter);
        setSortOption(defaultSort);
    };

    const getAlbums = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`artist/${artistId}/discography`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    albums: orderByReleaseDate(response),
                }));
                if (response.length === 0) {
                    setNoData("albums");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getAlbums()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getAlbums]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "artist.view.discoAlbums.id"),
        },
        {
            dataField: "cover",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.cover"
            ),
            headerAlign: "center",
            headerClasses: "nowrap",
            align: "center",
            formatter: (cell) =>
                cell ? (
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Popover id={`popover-positioned-top`}>
                                <Popover.Content className="bg-black rounded-sm">
                                    <ImageLoader
                                        src={cell}
                                        style={{
                                            width: "400px",
                                            height: "400px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span>
                            <ImageLoader src={cell} className="img-40" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    "-"
                ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.title"
            ),
            formatter: (cell, { id, artists }) => (
                <>
                    <AlbumLink albumId={id} content={cell} />
                    {artists.map((artist) => (
                        <React.Fragment key={artist.id}>
                            <span className="mx-1">-</span>
                            <ArtistLink
                                artistId={artist.id}
                                content={artist.name}
                                view
                            />
                        </React.Fragment>
                    ))}
                </>
            ),
        },
        {
            dataField: "type",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.type.head"
            ),
            formatter: (cell) => (
                <I18n
                    t={`artist.view.discoAlbums.type.${cell?.toLowerCase()}`}
                />
            ),
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            sort: true,
        },
        {
            dataField: "format",
            headerClasses: "nowrap",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.format.head"
            ),
            formatter: (cell) => (
                <I18n
                    t={`artist.view.discoAlbums.format.${cell?.toLowerCase()}`}
                />
            ),
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            sort: true,
        },
        {
            dataField: "genre",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.genre"
            ),
            formatter: (cell) => cell ?? "-",
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            sort: true,
        },
        {
            dataField: "release_date",
            headerClasses: "nowrap",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.release_date"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                if (cell) {
                    return displayAsString(cell, locale);
                } else {
                    return "-";
                }
            },
            formatExtraData: {
                locale,
            },
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            sort: true,
        },
        {
            dataField: "reference",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.reference"
            ),
            formatter: (cell) =>
                cell && cell !== "0" ? (
                    <RatingStar
                        maxStars={5}
                        value={parseFloat(cell.replace(",", "."))}
                    />
                ) : (
                    "-"
                ),
            onSort: (field, order) => {
                setSortOption({
                    dataField: field,
                    order,
                });
            },
            sort: true,
        },
        {
            dataField: "complementary",
            classes: "nowrap",
            text: I18n.getTranslation(
                location,
                "artist.view.discoAlbums.complementary"
            ),
            formatter: (cell, { id, has_review, has_credits, has_chart }) => (
                <>
                    <span
                        className={`badge badge-${
                            has_review ? "success" : "secondary"
                        } mr-1`}
                    >
                        <I18n t="artist.view.discoAlbums.has_review" />
                    </span>
                    <AlbumLink
                        albumId={id}
                        content={
                            <span
                                className={`badge badge-${
                                    has_credits ? "success" : "secondary"
                                }`}
                            >
                                <I18n t="artist.view.discoAlbums.has_credits" />
                            </span>
                        }
                        part="credits"
                        className="mr-1"
                    />
                    <span
                        className={`badge badge-${
                            has_chart ? "success" : "secondary"
                        }`}
                    >
                        <I18n t="artist.view.discoAlbums.has_chart" />
                    </span>
                </>
            ),
            isDummyField: true,
        },
    ];

    const rowStyle = { height: "50px" };

    return (
        <Albums
            {...props}
            isLoading={state.isLoading}
            albums={getFilteredAlbums({
                ...state,
                selectedFilterOptions,
            })}
            columns={columns}
            rowStyle={rowStyle}
            sortOption={sortOption}
            location={location}
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            reset={reset}
        />
    );
};

export default EnhancedAlbums;
