import React, { useState, useCallback, useEffect } from "react";
import Assessment from "./Assessment";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const EnhancedAssessment = (props) => {
    const { api } = useAPI();
    const defaultAssessments = {
        assessment: {
            assessor: null,
            summary: [],
            lines: [],
        },
        counter_assessment: {
            assessor: null,
            summary: [],
            lines: [],
        },
    };
    const [assessments, setAssessments] = useState(defaultAssessments);
    const [lineFilter, setLineFilter] = useState(null);
    const [activeLines, setActiveLines] = useState([]);
    const { lyricSampleId } = useParams();

    useEffect(() => {
        if (activeLines.length > 0) {
            const line = document.getElementsByClassName(
                "apple__sample__assessment__lyric__current__row"
            );
            line[0].scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
    }, [activeLines]);

    const getAssessments = useCallback(() => {
        let cancelled = false;
        api.get(`apple/sample/assessment/${lyricSampleId}/ratings`)
            .then((response) => {
                if (!cancelled) {
                    setAssessments(response);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setAssessments(defaultAssessments);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [lyricSampleId]);

    useEffect(getAssessments, [getAssessments]);

    return (
        <Assessment
            assessments={assessments}
            getAssessments={getAssessments}
            lineFilter={lineFilter}
            setLineFilter={setLineFilter}
            activeLines={activeLines}
            setActiveLines={setActiveLines}
            {...props}
        />
    );
};

export default EnhancedAssessment;
