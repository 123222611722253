import React from "react";
import MetaUpdate from "./MetaUpdate";
import useToggle from "shared/hooks/useToggle";

const EnhancedMetaUpdate = ({ onSuccess, ...props }) => {
    const [showInformation, toggleInformation] = useToggle(false);
    const onInformationSuccess = () => {
        toggleInformation();
        onSuccess?.();
    };

    return (
        <MetaUpdate
            {...props}
            showInformation={showInformation}
            toggleInformation={toggleInformation}
            onInformationSuccess={onInformationSuccess}
        />
    );
};

export default EnhancedMetaUpdate;
