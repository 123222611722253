import React from "react";
import { useParams } from "react-router";
import RecordingLink from "./RecordingLink";

const EnhancedRecordingLink = (props) => {
    const { locale } = useParams();
    return <RecordingLink {...props} locale={locale} />;
};

export default EnhancedRecordingLink;
