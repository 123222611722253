import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";

const AlbumTranslationsForm = ({
    location,
    artistNames,
    register,
    isSubmitting,
    isDirty,
    errors,
    onSubmit,
    onReset,
}) => {
    return (
        <Form className="m-3" onSubmit={onSubmit}>
            <Row className="mb-3">
                <Col>
                    <h5>
                        <I18n t="common.charts.charts.albumTranslationsForm.artist" />
                    </h5>
                    <InputText
                        {...register("artistName")}
                        placeholder={I18n.getTranslation(
                            location,
                            "common.charts.charts.albumTranslationsForm.translatedName"
                        )}
                        error={
                            errors.artistName && [
                                I18n.getTranslation(
                                    location,
                                    errors.artistName.message
                                ),
                            ]
                        }
                    />
                    {artistNames.length > 1 ? (
                        <Form.Text className="text-danger">
                            <I18n t="common.charts.charts.albumTranslationsForm.multipleNamesWarning" />
                            <ul className="mb-0">
                                {artistNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>
                        </Form.Text>
                    ) : undefined}
                </Col>
                <Col className="border-left">
                    <h5>
                        <I18n t="common.charts.charts.albumTranslationsForm.album" />
                    </h5>
                    <InputText
                        {...register("albumTitle")}
                        placeholder={I18n.getTranslation(
                            location,
                            "common.charts.charts.albumTranslationsForm.translatedTitle"
                        )}
                        error={
                            errors.albumTitle && [
                                I18n.getTranslation(
                                    location,
                                    errors.albumTitle.message
                                ),
                            ]
                        }
                    />
                </Col>
            </Row>
            <Row className="justify-content-end">
                <Col className="col-auto">
                    <Button
                        variant="secondary mr-2"
                        disabled={isSubmitting || !isDirty}
                        onClick={onReset}
                        title={I18n.getTranslation(
                            location,
                            "common.charts.charts.albumTranslationsForm.reset"
                        )}
                    >
                        <I18n t="utils.button.reset" />
                    </Button>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        type="submit"
                        title={I18n.getTranslation(
                            location,
                            "common.charts.charts.albumTranslationsForm.save.tooltip"
                        )}
                    >
                        <I18n t="utils.button.save" />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AlbumTranslationsForm;
