import React, { useReducer, useMemo } from "react";
import { ProfileContext } from "./Profile.context";
import { ProfileReducer } from "./Profile.reducer";

const initialState = {
    artistType: null,
};

export const ProfileProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ProfileReducer, initialState);
    const contextValue = useMemo(
        () => ({
            dispatchProfile: dispatch,
            artistType: state.artistType,
        }),
        [state, dispatch]
    );

    return (
        <ProfileContext.Provider value={contextValue}>
            <React.Fragment>{children}</React.Fragment>
        </ProfileContext.Provider>
    );
};
