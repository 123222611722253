import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import { Button, Form, InputGroup } from "react-bootstrap";

const SearchForm = ({ register, isSubmitting, handleSubmit, location }) => {
    return (
        <Form onSubmit={!isSubmitting ? handleSubmit : null}>
            <Widget className="mb-4">
                <Widget.Body className="mt-0">
                    <InputGroup>
                        <InputText
                            {...register("q")}
                            placeholder={I18n.getTranslation(
                                location,
                                "search.placeholder"
                            )}
                        />
                        <InputGroup.Append>
                            <Button
                                variant="primary"
                                disabled={isSubmitting}
                                type="submit"
                            >
                                <I18n t="utils.button.search" />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Widget.Body>
            </Widget>
        </Form>
    );
};

export default SearchForm;
