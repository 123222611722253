import React from "react";
import RatingCard from "./RatingCard";
import { Button, Alert } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import "./Ratings.css";
import clsx from "clsx";

const Ratings = ({
    title,
    variant,
    assessor,
    assessments,
    lineFilter,
    setLineFilter,
    gridPosition,
    user,
    ...props
}) => {
    return (
        <div
            className={clsx(
                "vertical-shrink",
                `apple__sample__assessment__ratings__${gridPosition}`
            )}
        >
            <Button
                variant="outline-secondary"
                className="btn-block mb-3"
                size="lg"
                disabled={!lineFilter}
                onClick={() => setLineFilter(null)}
            >
                <I18n t={`apple.sample.assessment.rating.reset`} />
            </Button>
            <Alert variant={variant} className="text-center">
                <strong>
                    {title}
                    {user.hasRight("apple.sample.assessment.edito") &&
                        ` : ${assessor}`}
                </strong>
            </Alert>
            <div className="apple__sample__assessment__ratings__container">
                {assessments
                    .filter(({ line_number }) => {
                        if (lineFilter) {
                            return line_number === lineFilter;
                        }
                        return true;
                    })
                    .map((assessment) => (
                        <RatingCard
                            key={assessment.line_number}
                            {...assessment}
                            {...props}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Ratings;
