export const appendScript = async function (scriptPath) {
    return new Promise((resolve) => {
        let exist = false;

        const scripts = document.getElementsByTagName("script");
        [...scripts].map((script) => {
            if (script.getAttribute("src") === scriptPath) {
                exist = true;
            }
        });

        if (!exist) {
            const newScript = document.createElement("script");
            newScript.src = scriptPath;
            newScript.async = true;
            newScript.onload = () => {
                resolve();
            };
            document.body.appendChild(newScript);
        } else {
            resolve();
        }
    });
};
