import React from "react";
import I18n from "shared/lib/I18n";
import Genres from "./Genres";
import Languages from "./Languages";
import Related from "./Related";

const Univers = ({ hasData, setNoData }) => {
    if (Object.values(hasData).every((e) => !e)) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h1 className="mt-4">
                        <I18n t="artist.view.noData.univers" />
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-4">
                <Genres setNoData={setNoData} />
            </div>
            <div className="col-5">
                <Related setNoData={setNoData} />
            </div>
            <div className="col-3">
                <Languages setNoData={setNoData} />
            </div>
        </div>
    );
};

export default Univers;
