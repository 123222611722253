import React from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import DiscardAction from "./DiscardAction";

const EnhancedDiscardAction = ({ listId, rowId, onChange, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();

    const onDiscard = (comment) => {
        api.post(`matching/track/deezer/list/${listId}/${rowId}/discard`, {
            comment,
        })
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.discard.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.discard.error",
                        error.message
                    )
                );
            });
    };

    const onRecover = () => {
        api.delete(`matching/track/deezer/list/${listId}/${rowId}/discard`)
            .then((response) => {
                onChange(response);

                toast.success(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.recover.success"
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        "matching.track.track.recover.error",
                        error.message
                    )
                );
            });
    };

    return (
        <DiscardAction
            {...props}
            onDiscard={onDiscard}
            onRecover={onRecover}
            location={location}
        />
    );
};

export default EnhancedDiscardAction;
