import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import CopyMatchingsButton from "./CopyMatchingsButton";

const schema = yup.object().shape({
    yearSrc: yup
        .string()
        .trim()
        .required("utils.form.required")
        .matches("^[0-9]{4}$", "utils.form.year_format"),
});

const EnhancedCopyMatchingsButton = ({ yearDst, onSuccess, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [show, setShow] = useState(false);
    const [yearSrcOptions, setYearSrcOptions] = useState([]);

    const fetchYearSrcOptions = useCallback(() => {
        let cancelled = false;
        api.get(`cnm/repository/cnm_genres`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setYearSrcOptions(
                        response
                            .filter((year) => year != yearDst)
                            .map((year) => ({
                                value: year,
                                label: year,
                            }))
                    );
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setYearSrcOptions([]);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [yearDst]);
    useEffect(fetchYearSrcOptions, [fetchYearSrcOptions]);

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValue: {
            yearSrc: null,
        },
    });
    const yearSrc = watch("yearSrc");

    const [comparison, setComparison] = useState({
        comparisonIsLoading: false,
        deletedGenres: [],
        newGenres: [],
        modifiedMatchings: [],
    });

    const fetchComparison = useCallback(() => {
        if (yearSrc === null || yearSrc === undefined) {
            setComparison({
                comparisonIsLoading: false,
                deletedGenres: [],
                newGenres: [],
                modifiedMatchings: [],
            });
            return;
        }

        let cancelled = false;
        setComparison({
            comparisonIsLoading: true,
            deletedGenres: [],
            newGenres: [],
            modifiedMatchings: [],
        });
        api.get(
            `cnm/repository/cnm_genres/matching/compare/${yearSrc}/${yearDst}`,
            { locale }
        )
            .then((response) => {
                if (!cancelled) {
                    setComparison({
                        comparisonIsLoading: false,
                        deletedGenres: response.deletedGenres,
                        newGenres: response.newGenres,
                        modifiedMatchings: response.modifiedMatchings,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setComparison({
                        comparisonIsLoading: false,
                        deletedGenres: [],
                        newGenres: [],
                        modifiedMatchings: [],
                    });
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [yearSrc, yearDst]);
    useEffect(fetchComparison, [fetchComparison]);

    const onShow = () => {
        fetchComparison();
        reset({
            yearSrc: yearSrcOptions.length > 0 ? yearSrcOptions[0].value : null,
        });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = ({ yearSrc }) => {
        api.post(
            `cnm/repository/cnm_genres/matching/copy/${yearSrc}/${yearDst}`,
            { locale }
        )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "cnm.repositories.cnm_genres.matchings.copyMatchings.success"
                    )
                );
                onHide();
                onSuccess();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CopyMatchingsButton
            show={show}
            onShow={onShow}
            onHide={onHide}
            yearSrc={yearSrc}
            yearDst={yearDst}
            yearSrcOptions={yearSrcOptions}
            control={control}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            location={location}
            {...comparison}
            {...props}
        />
    );
};

export default EnhancedCopyMatchingsButton;
