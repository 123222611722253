import {
    ALBUMS_SET,
    ALBUM_ADD,
    ALBUM_UPD,
    ALBUM_DEL,
    ALBUM_HAS_REVIEW,
    ALBUM_COVER_UP_TO_DATE,
    RELEASES_SET,
    RELEASE_ADD,
    RELEASE_UPD,
    RELEASE_DEL,
    RELEASE_MATCHING_UP_TO_DATE,
    TRACKS_LOAD_START,
    TRACKS_SET,
    TRACK_ADD,
    TRACK_UPD,
    TRACK_DEL,
    TRACK_BASIC_UP_TO_DATE,
    SANDBOX_ARTIST_ALBUMS_SET,
    SANDBOX_SEARCH_RESULTS_SET,
    SANDBOX_ALBUM_STATUS_UPD,
} from "./Discography.constants";

export const DiscographyReducer = (state, action) => {
    switch (action.type) {
        case ALBUMS_SET:
            return {
                ...state,
                albums: action.data,
            };
        case ALBUM_ADD:
            return {
                ...state,
                albums: [...state.albums, action.data],
            };
        case ALBUM_UPD:
            return {
                ...state,
                albums: state.albums.map((row) => {
                    if (row.id === action.data.id) {
                        return { ...row, ...action.data };
                    }
                    return row;
                }),
            };
        case ALBUM_DEL:
            return {
                ...state,
                albums: state.albums.filter(({ id }) => id !== action.data),
            };
        case ALBUM_HAS_REVIEW:
            return {
                ...state,
                albumHasReview: action.data,
            };
        case ALBUM_COVER_UP_TO_DATE:
            return {
                ...state,
                albumCoverUpToDate: action.data,
            };
        case RELEASES_SET:
            return {
                ...state,
                releases: action.data,
            };
        case RELEASE_ADD:
            return {
                ...state,
                releases: [...state.releases, action.data],
            };
        case RELEASE_UPD:
            return {
                ...state,
                releases: state.releases.map((row) => {
                    if (row.id === action.data.id) {
                        return { ...row, ...action.data };
                    }
                    return row;
                }),
            };
        case RELEASE_DEL:
            return {
                ...state,
                releases: state.releases.filter(({ id }) => id !== action.data),
            };
        case RELEASE_MATCHING_UP_TO_DATE:
            return {
                ...state,
                releaseMatchingUpToDate: action.data,
            };
        case TRACKS_LOAD_START:
            return {
                ...state,
                tracksLoading: true,
            };
        case TRACKS_SET:
            return {
                ...state,
                tracks: action.data,
                tracksLoading: false,
            };
        case TRACK_ADD:
            return {
                ...state,
                tracks: [...state.tracks, action.data],
            };
        case TRACK_UPD:
            return {
                ...state,
                tracks: state.tracks.map((row) => {
                    if (row.id_track === action.data.id_track) {
                        return { ...row, ...action.data };
                    }
                    return row;
                }),
            };
        case TRACK_DEL:
            return {
                ...state,
                tracks: state.tracks.filter(
                    ({ id_track }) => id_track !== action.data
                ),
            };
        case TRACK_BASIC_UP_TO_DATE:
            return {
                ...state,
                trackBasicUpToDate: action.data,
            };
        case SANDBOX_ARTIST_ALBUMS_SET:
            return {
                ...state,
                sandbox: {
                    ...state.sandbox,
                    artistAlbums: action.data,
                },
            };
        case SANDBOX_SEARCH_RESULTS_SET:
            return {
                ...state,
                sandbox: {
                    ...state.sandbox,
                    searchResults: action.data,
                },
            };
        case SANDBOX_ALBUM_STATUS_UPD:
            return {
                ...state,
                sandbox: {
                    ...state.sandbox,
                    artistAlbums: state.sandbox.artistAlbums.map((album) =>
                        album.partnerAbbreviatedName ===
                            action.data.partnerAbbreviatedName &&
                        album.id === action.data.id
                            ? { ...album, status: action.data.status }
                            : album
                    ),
                    searchResults: state.sandbox.searchResults.map((album) =>
                        album.partnerAbbreviatedName ===
                            action.data.partnerAbbreviatedName &&
                        album.id === action.data.id
                            ? { ...album, status: action.data.status }
                            : album
                    ),
                },
            };
    }
};
