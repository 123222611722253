import React from "react";
import ArtistSelect from "shared/components/ArtistSelect";

const ArtistMatcher = ({ msArtist, locale, onMatch }) => {
    return (
        <ArtistSelect
            id={msArtist?.id}
            name={msArtist?.name}
            locale={locale}
            onMatch={onMatch}
            onUnmatch={() => onMatch(null)}
            menuPlacement="auto"
        />
    );
};

export default ArtistMatcher;
